import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: 20,
    top: 20,
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
  headerText: {
    textAlign: 'center',
  },
  dialogContent: {
    height: '70vh',
  },
  boxImageUpload: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(25),
    paddingBottom: theme.spacing(25),
  },
  titleUploadImage: {
    ...CommonFonts.darkFont24,
    fontWeight: '600',
  },
  dropZone: {
    marginTop: theme.spacing(5),
    borderRadius: theme.spacing(1),
    width: '470px',
    height: '160px',
    backgroundColor: '#e7f1fe',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleDrop: { ...CommonFonts.darkFont18 },
  titleBrowse: {
    ...CommonFonts.darkFont18,
    color: '#1174F2',
    marginLeft: theme.spacing(1),
    fontWeight: '600',
    cursor: 'pointer',
  },
  progressBar: {
    marginTop: theme.spacing(4),
    '& .MuiLinearProgress-root': {
      width: theme.spacing(75),
      height: '5px',
    },
  },
  imagePreview: {
    maxWidth: '825px',
  },
  boxImagePreview: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(9),
  },
}));

export default useStyles;
