/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  Button,
  IconButton,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Typography,
  TextField,
  withStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Colors, CommonFonts } from '@/theme';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/signupModal/components/preLoader';
import { useSnackbar } from 'notistack';

const UpdateUserTeam = ({
  isVisible,
  setUpdateUserTeamModal,
  userDetail,
  teams,
  onChange,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [selectTeam, setSelectTeam] = useState([]);
  const [initialTeam, setInitialTeam] = useState([]);

  useEffect(() => {
    if (userDetail?.teams?.length > 0)
      setSelectTeam(userDetail?.teams?.map((item) => item.name));
    setInitialTeam(userDetail?.teams?.map((item) => item.name));
  }, [userDetail]);

  const handleCloseEditModal = () => setUpdateUserTeamModal(false);

  const mapTeamNameToId = (name) =>
    teams?.find((item) => item.name === name)?.id;

  const onUpdate = async () => {
    try {
      setIsLoading(true);
      const inputRequest = [
        ...initialTeam.map((item) => ({
          user_id: userDetail?.id,
          team_id: mapTeamNameToId(item),
          flag: 'delete',
        })),
        ...selectTeam.map((item) => ({
          user_id: userDetail?.id,
          team_id: mapTeamNameToId(item),
        })),
      ];
      await onChange(inputRequest);
      handleCloseEditModal();
      enqueueSnackbar(t('userManagement.users.user_team_updated'));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      onClose={isLoading ? () => {} : handleCloseEditModal}
      aria-labelledby={`{update-user-team-dialog-title}`}
      open={isVisible}
      disableBackdropClick
    >
      <DialogTitle
        id={`{update-user-team-dialog-title}`}
        onClose={handleCloseEditModal}
      >
        {t('userManagement.users.select_team')}
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          freeSolo
          multiple
          value={selectTeam}
          id="free-solo-2-demo"
          options={teams?.map((item) => item.name)}
          onChange={(event, value) => setSelectTeam(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              variant="standard"
            />
          )}
          getOptionLabel={(option) => option}
          renderOption={(option, { inputValue }) => {
            const matches = match(option, inputValue);
            const parts = parse(option, matches);

            return (
              <Box paddingX={2} paddingY={2}>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 600 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </Box>
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseEditModal}>
          {t('settings.cancel')}
        </Button>
        <Button onClick={onUpdate} type="submit" color="primary">
          {t('settings.update')}
        </Button>
      </DialogActions>
      {isLoading && <PreLoader size={30} />}
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default UpdateUserTeam;
