import React, { useState } from 'react';
import useStyles from './styles';
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Paper,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';
import { gql, useQuery } from '@apollo/client';
import { GET_WORKFLOWS } from '@/services/orderTypesWorkflowsService';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import AddNewWorkflows from './addNewWorkflows';

const workflowsQueries = gql`
  ${GET_WORKFLOWS}
`;

const WorkflowsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    loading: loadingWorkflows,
    data,
    refetch,
  } = useQuery(workflowsQueries, { fetchPolicy: 'no-cache' });
  const workflows = data?.workflows;

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <Box>
        <div className={classes.boxWorkflows}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.title}>
              {t('orderTypeWorkflows.workflows')}
            </Typography>
            <AddNewWorkflows />
          </Box>

          <Paper elevation={0} style={{ overflowX: 'auto' }}>
            <Table className={classes.boxTable}>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    className={classes.headerTableText}
                    style={{ width: '70%' }}
                  >
                    {t('orderTypeWorkflows.name')}
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.headerTableText}
                    style={{ width: '15%' }}
                  />
                  <StyledTableCell
                    className={classes.headerTableText}
                    style={{ width: '15%' }}
                  />
                </TableRow>
              </TableHead>

              {loadingWorkflows || isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: 'none' }}
                      colSpan={6}
                    >
                      <Box
                        marginTop={10}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={35} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {workflows?.map((row) => (
                    <TableRow hover key={row.id}>
                      <StyledTableCell>
                        <div className={classes.boxFullName}>
                          <Typography className={classes.tableText}>
                            {row.name}
                          </Typography>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography className={classes.actionLink}>
                          {t('orderTypeWorkflows.configure')}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography className={classes.actionLink}>
                          {t('orderTypeWorkflows.archive')}
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {!loadingWorkflows && _.isEmpty(workflows) && (
              <Box
                marginTop={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.noDataFound}>
                  {t('orderTypeWorkflows.no_workflows_found')}
                </Typography>
              </Box>
            )}
          </Paper>
        </div>
      </Box>
    </ErrorBoundary>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F3F5F7',
    borderBottom: 'none',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default WorkflowsPage;
