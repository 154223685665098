import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce, find } from 'lodash';
import { Grid, MenuItem, Typography, InputLabel, Checkbox, Select, withStyles } from '@material-ui/core';
import { Search, ExpandMore } from '@material-ui/icons';
import TextFiled from '@/components/textField';
import { Colors } from '@/theme';
import useStyles from './styles';

const FormHeader = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    categories,
    onChangeCategories,
    onChangeSearch,
    disabled = false
  } = props;

  categories.unshift(t('workboard.manage_store.touchpoints.all_categories'));
  const [category, setCategory] = useState(categories[0]);
  return (
    <Grid item xs={12}>
      <Grid
        container
        alignItems="center"
        spacing={2}
        className={classes.paperContent}
      >
        <Grid item xs={12} sm={4} style={{ padding: '4px 32px' }}>
          <Select
            disableUnderline
            IconComponent={ExpandMore}
            displayEmpty
            fullWidth
            className={classes.filterInput}
            value={category}
            onChange={(event) => {
             if(event.target.value === t('workboard.manage_store.touchpoints.all_categories'))  {
               onChangeCategories('');
             } else {
               onChangeCategories(event.target.value);
             }
               setCategory(event.target.value);
            }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {categories.map((item, id) => (
              <MenuItem key={`products_categories-${id}`} value={item}>
                <Typography className={classes.titleText}>
                  {item}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextFiled
            disabled={disabled}
            placeholder={t('workboard.manage_store.touchpoints.search_description')}
            className={classes.searchInput}
            onChange={debounce(
              (e) => onChangeSearch(e.target.value),
              1000
            )}
            startAdornment={
              <Search
                style={{ color: Colors.GRAY_BORDER1 }}
                width={18}
                height={18}
              />
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormHeader;
