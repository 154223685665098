import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      minHeight: 244,
    },
    titleText: {
      ...CommonFonts.grayBoldFont20,
      color: Colors.DIM_GREY,
      fontWeight: 500
    },
    layoutSelectContainer: {
      marginRight: isMobile ? 0 : theme.spacing(10),
    },
    layoutsWrapper: {
      border: `1px solid ${Colors.GRAY_BORDER1}`,
      borderRadius: 4,
    },
    contentContainer: {
      marginTop: theme.spacing(4)
    },
    menuIconContainer: {
      width: 70,
      padding: theme.spacing(.5, 0),
      cursor: 'pointer',
    },
  })
)

export default useStyles
