import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT
    },
    boxLink: {
      padding: theme.spacing(4)
    },
    boxLinkActive: {
      padding: theme.spacing(4),
      backgroundColor: '#e7f1fe',
      fontWeight: '600'
    },
    breadcrumbsText: {
      fontSize: 16
    },
    linkText: {
      ...CommonFonts.darkFont18
    },
    boxModules: {
      backgroundColor: Colors.WHITE,
      padding: theme.spacing(5),
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(7)
    },
    title: {
      ...CommonFonts.darkFont16,
      fontWeight: '600',
      marginTop: theme.spacing(6)
    },
    root: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    gridItem: {
      paddingBottom: theme.spacing(10)
    },
    tabsWrapper: {
      borderBottom: '1px solid #e8e8e8'
    },
    tab: {
      textTransform: 'none',
      minWidth: 72
    },
    loading: {
      textAlign: 'center'
    },
    globalInformationWrapper: {
      paddingBottom: theme.spacing(4)
    },
    localRideWrapper: {
      paddingBottom: theme.spacing(4)
    },
    partnerWrapper: {
      padding: theme.spacing(7),
      border: '1px solid #e8e8e8',
      marginBottom: '3vw'
    },
    partnerLogo: {
      maxWidth: 125,
      paddingBottom: theme.spacing(4)
    },
    adornedEnd: {
      backgroundColor: '#ccc',
      height: '2.4rem',
      maxHeight: '3rem'
    },
    openDelivery: {
      marginTop: 25
    }
  })
);


export default useStyles;
