import React from 'react';
import AppLayout from '@/components/appLayout';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import StoreOverView from './components/StoreOverView';
import Applications from './components/Applications';
import Manage from './components/Manage';
import useStyles from './styles';

export default function BusinessManager() {
  const classes = useStyles();
  const { selectedStore } = useSelector((state) => state.store);
  const { currentMerchant } = useSelector((state) => state.store);

  return (
    <AppLayout
      className={classes.appLayout}
      withFooter
      header
      withServiceDropdown={true}
    >
      <div className={classes.root}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <StoreOverView
              selectedStore={selectedStore}
              currentMerchant={currentMerchant}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Applications />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Manage />
          </Grid>
        </Grid>
      </div>
    </AppLayout>
  );
}
