import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import useStyles from './styles';
import Select from '../../../components/selector';

const LocationItem = ({ location, shelves, updateLocation }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const getLocationLabel = (item) =>
    `${t('add_product.aisle')} ${item.aisle}, ${t(
      'add_product.shelf'
    )} ${item.shelf}`;

  return (
    <Box className={classes.boxLocation} marginY={5}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Select
            name="floor"
            title={t('add_product.aisle_and_shelf')}
            emptyLabel={t('settings.select')}
            items={shelves}
            getItemLabel={(item) => getLocationLabel(item)}
            selectedItem={
              shelves?.find((item) =>
                _.isEqual(item?.id, location?.id)
              ) || {}
            }
            onChangeValue={(value) => updateLocation(value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LocationItem;
