import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { TableRow, TableCell, withStyles, IconButton } from '@material-ui/core';
import { Colors, CommonFonts } from '@/theme';
import SingleSwitchInput from '@/components/singleSwitch';
import { Edit } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { selectSelectedStore } from '@/store/modules/store';
import { useSnackbar } from 'notistack';

const Row = ({ item, index, onEdit,disabled, updateSlotSwitch }) => {
  const { t } = useTranslation();
  const [switchValue, setSwitchValue] = useState(!item.disabled);

  const hoursEdit = () => {
    return (
      <div style={{ minWidth: 100 }}>
        <label> {item.max_orders} </label>
        <IconButton size='small' style={{ margin: 6 }} onClick={() => onEdit(index)}> <Edit
          fontSize="small" />
        </IconButton>
      </div>
    );
  };

  const handleChangeSwitch = () => {
    setSwitchValue(!switchValue);
    updateSlotSwitch(switchValue, index)
  };

  const rowCells = [
    {
      id: 'delivery-slot',
      label: `${item.start} - ${item.end}`
    },
    {
      id: 'max-orders',
      label: hoursEdit()
    },
    {
      id: 'delivering',
      label: <SingleSwitchInput value={switchValue} onChange={() => handleChangeSwitch()}
                                disabled={disabled}
                                notificationMessage={t('workboard.manage_store.delivery_slots.delivering')}
                                style={{ marginTop: 0, paddingBottom: 0 }} />
    }
  ];

  return (
    <TableRow hover>
      {rowCells.map((rowCell) => (
        <StyledTableCell key={rowCell.id}>
          {rowCell.label}
        </StyledTableCell>
      ))}
    </TableRow>
  );
};

const StyledTableCell = withStyles(() => ({
  root: {
    ...CommonFonts.darkFont16,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25
  },
  head: {
    backgroundColor: '#F3F5F7'
  },
  body: {
    ...CommonFonts.darkFont16
  }
}))(TableCell);

Row.propTypes = {
  item: PropTypes.object
};

export default Row;
