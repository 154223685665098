import React from 'react';
import useStyles from './styles';
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Paper,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';
import _ from 'lodash';
import { capitalizeFirstLetter } from '@/utils/stringUtil';

const PlanItem = ({ plan }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const getNumberOfUserOrStore = (priceType) => {
    const planType = plan.packages[0].type;
    return (
      plan?.subscription?.items?.filter(
        (item) =>
          _.isEqual(item.item_type, planType) &&
          _.isEqual(item.type, priceType)
      )?.length || 0
    );
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <Box>
        <div className={classes.boxPlanItem}>
          <Typography className={classes.title}>
            {plan?.name}
          </Typography>
          {!_.isEmpty(plan?.description) && (
            <Typography className={classes.description}>
              {plan?.description}
            </Typography>
          )}
          <Paper elevation={0} style={{ overflowX: 'auto' }}>
            <Table className={classes.boxTable}>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{ width: '35%' }}
                    className={classes.headerTableText}
                  >
                    {t('subscriptions.payment_options')}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '35%' }}
                    className={classes.headerTableText}
                  >
                    {t('subscriptions.cost')}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '15%' }}
                    className={classes.headerTableText}
                  >
                    {t('subscriptions.no_of_user_store', {
                      type: `${plan.packages[0]?.type}s`,
                    })}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '15%' }} />
                </TableRow>
              </TableHead>

              <TableBody>
                {plan.packages[0]?.prices?.map((row, index) => (
                  <TableRow
                    hover
                    key={`${row.gross_amount}-${index}`}
                  >
                    <StyledTableCell>
                      <div className={classes.boxFullName}>
                        <Typography className={classes.tableText}>
                          {capitalizeFirstLetter(row.type)}
                        </Typography>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.boxFullName}>
                        <Typography className={classes.tableText}>
                          {`${plan.currency}${
                            row.gross_amount
                          } / ${capitalizeFirstLetter(
                            plan.packages[0]?.type
                          )}`}
                        </Typography>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.boxFullName}>
                        <Typography className={classes.tableText}>
                          {getNumberOfUserOrStore(row.type)}
                        </Typography>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.boxFullName}>
                        <Typography className={classes.manage}>
                          {t('subscriptions.manage')}
                        </Typography>
                      </div>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </Box>
    </ErrorBoundary>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F3F5F7',
    borderBottom: 'none',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default PlanItem;
