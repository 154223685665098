import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import {
  Box,
  Grid,
  Typography,
  TextField
} from '@material-ui/core'

import Input from '../../components/input'

import useStyles from '../styles'

const NameDescription = (props) => {
  const {
    groupData,
    updateGroupData,
    handleBlur,
    errors,
    touched,
    setFieldValue,
  } = props

  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    setFieldValue('group', groupData.group)
  }, [groupData.group])

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Input
            name='group'
            title={t('addons.group_name')}
            emptyLabel={t('addons.add')}
            value={groupData.group}
            onChange={(value) => {
              updateGroupData('group', value)
            }}
            onBlur={handleBlur}
            error={touched.group && !!errors.group}
            helperText={touched.group && errors.group}
          />
        </Grid>
      </Grid>

      <Box style={{ marginTop: 40 }}>
        <Typography
          className={
            classNames(classes.titleText, {
              [classes.errorTitleText]: touched.description && !!errors.description
            })
          }
        >
          {t('addons.short_description')}
        </Typography>
        <Typography className={classes.characterLeftText}>
          {`${200 - (groupData.description?.length || 0)} ${t('addons.character_left')}`}
        </Typography>
        <TextField
          name='description'
          className={classes.descriptionField}
          fullWidth
          multiline
          value={groupData.description}
          onChange={(e) => updateGroupData('description', e.target.value)}
          inputProps={{ maxLength: 200 }}
          onBlur={handleBlur}
          error={touched.description && !!errors.description}
          helperText={touched.description && errors.description}
        />
      </Box>
    </Box>
  )
}

export default NameDescription