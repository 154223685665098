import DateFnsUtils from '@date-io/date-fns'

const dateAdapter = new DateFnsUtils()

export const localFormatDate = dateString => {
  return dateAdapter.format(new Date(dateString), 'dd/MM/yyyy, HH:mm')
}
export const getOrderPaymeentDue = order => {
  const total = order?.total_price.gross_amount || order?.total_price.amount || 0

  return (total && order.payments.reduce((acc, el) => acc - el.amount, total)).toFixed(2)
}
export const formatOrderDate = dateString => {
  return dateAdapter.format(new Date(dateString), 'HH:mm dd MMMM yyyy')
}

export const getItemPrice = (item, amount) => {
  if (!item && !amount) return 0
  if (!item.addons) return amount
  const addonsPrice = item.addons.reduce((acc, addon) => {
    const price = addon.price?.amount || 0
    const quantity = addon.price?.quantity || 1

    return acc + price * quantity
  }, 0)
  return addonsPrice + (amount || 0)
}

export const getUserName = user => {
  return (
    user &&
    ([user.first_name, user.last_name].filter(name => !!name).join(' ') ||
      user.email ||
      user.mobile)
  )
}
