import React, { useState } from 'react';
import useStyles from './styles';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Menu,
  MenuItem,
  Paper,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import SingleCheckboxInput from '@/components/singleCheckbox';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';
import { gql, useQuery, useMutation } from '@apollo/client';
import { GET_ORDER_TYPE } from '@/services/orderTypesWorkflowsService';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import AddNewOrderTypes from './addNewOrderTypes';
import UpdateOrderTypes from './updateOrderTypes';
import { capitalizeEachLetter } from '@/utils/stringUtil';
import { MUTATE_ORDER_TYPE } from '@/services/orderTypesWorkflowsService';

const orderTypeQueries = gql`
  ${GET_ORDER_TYPE}
`;

const OrderTypesPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [currentOrderType, setCurrentOrderType] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    loading: loadingOrderType,
    data,
    refetch,
  } = useQuery(orderTypeQueries, { fetchPolicy: 'no-cache' });
  const orderTypes = data?.orderTypes;

  const handleCloseUpdateOrderTypeModal = () => setIsVisible(false);

  const [mutateOrderType] = useMutation(
    gql`
      ${MUTATE_ORDER_TYPE}
    `
  );

  const onDelete = () => {
    setIsLoading(true);
    mutateOrderType({
      variables: {
        input: {
          id: currentOrderType?.id,
          flag: 'delete',
        },
      },
    })
      .then(async () => {
        await refetch?.();
        setAnchorMenu(null);
        setIsLoading(false);
        enqueueSnackbar(t('orderTypeWorkflows.order_type_removed'));
      })
      .catch((error) => {
        setAnchorMenu(null);
        setIsLoading(false);
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  const onUpdateCompleteUponPayment = (orderType) => {
    setIsLoading(true);
    mutateOrderType({
      variables: {
        input: {
          id: orderType?.id,
          complete_on_paid: !orderType.complete_on_paid,
        },
      },
    })
      .then(async () => {
        await refetch?.();
        setAnchorMenu(null);
        setIsLoading(false);
        enqueueSnackbar(t('orderTypeWorkflows.order_type_updated'));
      })
      .catch((error) => {
        setAnchorMenu(null);
        setIsLoading(false);
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <Box>
        <div className={classes.boxOrderTypes}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.title}>
              {t('orderTypeWorkflows.order_type')}
            </Typography>
            <AddNewOrderTypes
              onFetchOrderTypes={refetch}
              orderTypesData={orderTypes}
            />
          </Box>

          <Paper elevation={0} style={{ overflowX: 'auto' }}>
            <Table className={classes.boxTable}>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    className={classes.headerTableText}
                    style={{ width: '17%' }}
                  >
                    {t('orderTypeWorkflows.name')}
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.headerTableText}
                    style={{ width: '17%' }}
                  >
                    {t('orderTypeWorkflows.type')}
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.headerTableText}
                    style={{ width: '17%' }}
                  >
                    {t('orderTypeWorkflows.price_type')}
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.headerTableText}
                    style={{ width: '17%' }}
                  >
                    {t('orderTypeWorkflows.workflow')}
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.headerTableText}
                    style={{ width: '17%' }}
                  >
                    {t('orderTypeWorkflows.complete_upon_payment')}
                  </StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              </TableHead>

              {loadingOrderType || isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: 'none' }}
                      colSpan={6}
                    >
                      <Box
                        marginTop={10}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={35} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {orderTypes?.map((row) => (
                    <TableRow hover key={row.id}>
                      <StyledTableCell>
                        <div className={classes.boxFullName}>
                          <Typography className={classes.tableText}>
                            {row.name}
                          </Typography>
                        </div>
                      </StyledTableCell>

                      <StyledTableCell>
                        <div className={classes.boxFullName}>
                          <Typography className={classes.tableText}>
                            {capitalizeEachLetter(row.type)}
                          </Typography>
                        </div>
                      </StyledTableCell>

                      <StyledTableCell>
                        <div className={classes.boxFullName}>
                          <Typography className={classes.tableText}>
                            {capitalizeEachLetter(row.pricing_type)}
                          </Typography>
                        </div>
                      </StyledTableCell>

                      <StyledTableCell>
                        <div className={classes.boxFullName}>
                          <Typography className={classes.tableText}>
                            {capitalizeEachLetter(row.workflow?.name)}
                          </Typography>
                        </div>
                      </StyledTableCell>

                      <StyledTableCell>
                        <SingleCheckboxInput
                          style={{ paddingBottom: 0 }}
                          isShowToast={false}
                          value={row.complete_on_paid}
                          label={''}
                          labelClass={classes.checkBoxLabel}
                          onChange={(value) =>
                            onUpdateCompleteUponPayment(row)
                          }
                        />
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <IconButton
                          className={classes.moreIcon}
                          onClick={(event) => {
                            setAnchorMenu(event.currentTarget);
                            setCurrentOrderType(row);
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          id="menu-options-order-types"
                          className={classes.menuOption}
                          anchorEl={anchorMenu}
                          keepMounted
                          open={
                            Boolean(anchorMenu) &&
                            _.isEqual(currentOrderType, row)
                          }
                          onClose={() => setAnchorMenu(null)}
                        >
                          <MenuItem
                            className={classes.menuItem}
                            onClick={() => {
                              setAnchorMenu(null);
                              setIsVisible(true);
                            }}
                          >
                            <Typography
                              className={classes.menuItemText}
                            >
                              {t('orderTypeWorkflows.update')}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            className={classes.menuItem}
                            onClick={onDelete}
                          >
                            <Typography
                              className={classes.menuItemText}
                            >
                              {t('orderTypeWorkflows.remove')}
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {!loadingOrderType && _.isEmpty(orderTypes) && (
              <Box
                marginTop={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.noDataFound}>
                  {t('orderTypeWorkflows.no_order_type_found')}
                </Typography>
              </Box>
            )}
          </Paper>
        </div>
        <UpdateOrderTypes
          key={currentOrderType?.id}
          orderTypeDetail={currentOrderType}
          isVisible={isVisible}
          onCloseModal={() => handleCloseUpdateOrderTypeModal()}
          onFetchOrderTypes={refetch}
        />
      </Box>
    </ErrorBoundary>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F3F5F7',
    borderBottom: 'none',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default OrderTypesPage;
