import React from 'react'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'
import PropTypes from 'prop-types'

import {
  TableRow,
  TableCell,
  withStyles,
  Typography,
  Box
} from '@material-ui/core'
import ActionMenu from '@/components/actionMenu'

import { Colors, CommonFonts } from '@/theme'
import { STYLE_OPTION_FIELD_DISPLAY, STYLE_OPTION_FIELD_TYPE } from '@/constants/styles'

import useStyles from './styles'

const Row = ({ item, fields, onSelectUpdate, onSelectDelete }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const mainField = find(fields, { display: STYLE_OPTION_FIELD_DISPLAY.MAIN })
  const otherFields = fields.filter(field => field.display !== STYLE_OPTION_FIELD_DISPLAY.MAIN)
  const newFields = mainField ? [mainField, ...otherFields] : [...otherFields]

  const rowCells = newFields.map(field => ({
    id: field.name,
    label: find(item.values, { name: field.name })?.value,
    type: field.type
  }))

  const actionMenus = [
    {
      name: t('settings.inventory.update'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        onSelectUpdate(data)
      },
    },
    {
      name: t('settings.inventory.delete'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        onSelectDelete(data)
      },
    },
  ]

  const renderRowCell = (rowCell) => {
    if (rowCell.type === STYLE_OPTION_FIELD_TYPE.COLOR) {
      return (
        <Box display='flex' alignItems='center'>
          <Box
            style={{
              width: 30,
              height: 30,
              backgroundColor: rowCell.label,
              borderRadius: 5
            }}
          />
          <Typography className={classes.configureText}>
            {rowCell.label}
          </Typography>
        </Box>
      )
    }
    return rowCell.label
  }

  return (
    <TableRow hover style={{ position: 'relative' }}>
      {rowCells.map(rowCell => (
        <StyledTableCell key={rowCell.id}>
          {renderRowCell(rowCell)}
        </StyledTableCell>
      ))}
      <StyledTableCell align='right'>
        <ActionMenu
          data={item}
          actionMenus={actionMenus}
        />
      </StyledTableCell>
    </TableRow>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    ...CommonFonts.darkFont16,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont16
  },
}))(TableCell);

Row.propTypes = {
  item: PropTypes.object,
}

export default Row