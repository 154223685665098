import React from 'react';

import { Paper, Table, TableBody } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import TableHeader from './tableHeader';
import TableRow from './tableRow';

import useStyles from './styles';

const TableComponent = (props) => {
  const classes = useStyles();
  const {
    customers,
    isLoadingCustomers,
    currentPage,
    onViewInfo,
    onArchive,
  } = props;

  return (
    <Table>
      {isLoadingCustomers && currentPage === 1 ? (
        <div>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </div>
      ) : (
        <>
          <TableHeader />
          <TableBody>
            {customers.map((item) => (
              <TableRow
                key={item.name}
                item={item}
                onViewInfo={onViewInfo}
                onArchive={onArchive}
              />
            ))}
          </TableBody>
        </>
      )}
    </Table>
  );
};

export default TableComponent;
