import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(17, 0, 80, 0),
    },
    findText: {
      ...CommonFonts.darkFont16,
      fontWeight: 500,
      textAlign: 'center',
    },
    inputContainer: {
      maxWidth: 790,
      margin: 'auto',
      marginTop: theme.spacing(13)
    },
    searchInput: {
      '& .MuiInput-root': {
        padding: '11px 26px',
        ...CommonFonts.darkFont18,
      },
    },
    noMatchingText: {
      ...CommonFonts.grayFont18,
      textAlign: 'center',
    },
    addonsLenText: {
      ...CommonFonts.grayFont14,
    },
    searchIcon: {
      position: 'absolute',
      left: 0,
      top: 16,
      color: Colors.GRAY_BORDER3
    }
  })
)

export default useStyles
