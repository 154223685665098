import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Grid } from '@material-ui/core'
import Button from '../../../components/button'

import { hideEmail } from '../../../utils'
import useStyles from './styles'

const EmailLayout = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    onLogin,
    emailFormData,
    onResend,
    onChangeEmail
  } = props

  return (
    <div>
      <div className={classes.verificationText}>
        {t('signup.need_verification')}
      </div>
      <div className={classes.checkEmailText}>
        {t('signup.check_your_email', { email: hideEmail(emailFormData.email) })}
      </div>
      <Grid container justify="center" className={classes.buttonWrapper}>
        <Button
          className={classes.loginButton}
          variant='contained'
          color="primary"
          onClick={onLogin}
        >
          {t('signup.login')}
        </Button>
      </Grid>
      <Grid container justify='center'>
        <span
          className={classes.blueText}
          onClick={() => onResend(emailFormData)}
        >
          {t('signup.resend_email')}
        </span>
      </Grid>
      <Grid container justify='center' className={classes.changeWrapper}>
        <span
          className={classes.blueText}
          onClick={onChangeEmail}
        >
          {t('signup.change_email')}
        </span>
      </Grid>
    </div>
  )
}

EmailLayout.propTypes = {
  onLogin: PropTypes.func,
  emailFormData: PropTypes.object,
  onResend: PropTypes.func,
  onChangeEmail: PropTypes.func,
}

export default EmailLayout