import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: '100%',
      backgroundColor: Colors.WHITE,
      padding: 0,
      borderBottom: `1px solid ${Colors.PALE_BLUE_50}`,
      alignItems: 'center',
      justifyContent: 'center',
    },
    wrapper: {
      paddingLeft: theme.spacing(2),
      overflow: 'hidden',
    },
    selectStorePopoverContainer: {
      marginTop: 20,
      '&>div.MuiPaper-root.MuiPopover-paper': {
        overflowY: 'hidden',
        width: 576,
        // maxHeight: 601,
      },
    },
    searchStore: {
      width: 220,

      paddingLeft: 50,
    },
    leftGrid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(4),
    },
    inputUnderLine: {
      '& .MuiInput-underline:before': {
        borderBottom: '2px solid #1174F2',
      },
      '& .MuiInput-underline:hover:before': {
        borderBottom: '2px solid #1174F2',
      },
      '& .MuiInput-underline:after': {
        borderBottom: '2px solid #1174F2',
        transition: 'none',
      },
    },
    icon: {
      fill: '#939DA8',
      marginTop: '-5px',
    },
    inputColor: {
      paddingBottom: 10,
      paddingTop: 10,

      color: Colors.DIM_GREY,
    },
    searchInput: {},
  })
);

export default useStyles;
