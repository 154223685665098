import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SettingAccordion from '.';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import {
  gql,
  useMutation,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import {
  MUTATE_TOUCHPOINT_POS_SETTING,
  PROMOTION_LIST,
  TOUCHPOINT_SETTING,
} from '@/services/storeService';
import PreLoader from '@/components/preLoader';
import SettingInput from '../input';
import ImagePicker from '@/components/imagePicker';
import { useSelector } from 'react-redux';
import { transformFloat } from '@/utils/localisationUtil';
import SettingSwitch from '@/components/settingSwitch';
import PosArrangeCategory from '../posArrangeCategory';
import PosTypesPicker from '../posTypesPicker';
import PosPrintPromotion from '../posPrintPromotion';
import { selectLocalisation } from '@/store/modules/store/selectors';
import PosAddressType from '../posAddressType';

const touchpointPosQuery = gql`
  ${TOUCHPOINT_SETTING}
`;

const promotionListQuery = gql`
  ${PROMOTION_LIST}
`;

const TouchpointPOS = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { selectedStore } = useSelector((state) => state.store);
  const { id: storeId } = selectedStore;
  const localisation = useSelector(selectLocalisation);
  const [isExpand, setIsExpand] = useState(false);

  const onExpand = (value) => setIsExpand(value);

  const {
    loading,
    error,
    data: touchPointData,
    refetch: refetchTouchpointData,
  } = useQuery(touchpointPosQuery, {
    fetchPolicy: 'no-cache',
  });

  const [getPromotion, { data: promotionsData }] = useLazyQuery(
    promotionListQuery
  );

  useEffect(() => {
    if (isExpand) {
      // getTouchpointPOS();
      getPromotion();
    }
  }, [isExpand]);

  const [mutateStore, { loading: isUpdatingStore }] = useMutation(
    gql`
      ${MUTATE_TOUCHPOINT_POS_SETTING}
    `,
    {
      onCompleted: () => {
        refetchTouchpointData();
      },
    }
  );

  const getPromotionNames = (values) =>
    values?.map(
      (item) =>
        promotionsData?.promotions.find((pro) => pro.id === item)
          ?.promo_name
    ) + '' || '';

  const posSettings =
    touchPointData?.store?.settings?.touchpoint_settings || {};
  const paymentTypes = touchPointData?.store?.payment_types || [];

  const updateGeneralSettings = (key, value) => {
    const payload = {
      variables: {
        store: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              pos: {
                [key]: value,
              },
            },
          },
        },
      },
      onError: (error) => {
        throw new Error(error);
      },
    };
    return mutateStore(payload);
  };

  const updateImageBanner = (value) => {
    const payload = {
      variables: {
        store: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              pos: {
                banner: value,
              },
            },
          },
        },
      },
      onError: (error) => {
        throw new Error(error);
      },
    };
    return mutateStore(payload);
  };

  const updateProductSettings = (key, value) => {
    const payload = {
      variables: {
        store: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              pos: {
                product_view: {
                  [key]: value,
                },
              },
            },
          },
        },
      },

      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateStore: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              pos: {
                product_view: {
                  [key]: value,
                  __typename: 'ProductViewSettings',
                },
                __typename: 'PosSettings',
              },
              __typename: 'StoreDeviceSettings',
            },
            __typename: 'StoreSettings',
          },
          __typename: 'Store',
        },
      },
    };
    return mutateStore(payload);
  };

  const updateReceiptSettings = (key, value) => {
    const payload = {
      variables: {
        store: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              pos: {
                receipts: {
                  [key]: value,
                },
              },
            },
          },
        },
      },
      update: (store, { data: { updateStore } }) => {
        const cache = store.readQuery({ query: touchpointPosQuery });

        const updatedData = {
          ...cache,
          store: {
            ...cache.store,
            settings: {
              ...cache.store.settings,
              touchpoint_settings: {
                ...cache.store.settings.touchpoint_settings,
                pos: {
                  ...cache.store.settings.touchpoint_settings.pos,
                  receipts: {
                    ...cache.store.settings.touchpoint_settings.pos
                      .receipts,
                    [key]: value,
                  },
                },
              },
            },
          },
        };
        store.writeQuery({
          query: touchpointPosQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateStore: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              pos: {
                receipts: {
                  [key]: value,
                  __typename: 'ReceiptsSettings',
                },
                __typename: 'PosSettings',
              },
              __typename: 'StoreDeviceSettings',
            },
            __typename: 'StoreSettings',
          },
          __typename: 'Store',
        },
      },
    };
    return mutateStore(payload);
  };

  const updateCustomerImageDisplay = (value, currentUrl) => {
    const { id } = value || {};
    const otherImages =
      posSettings?.pos?.customer_display_images?.filter(
        (item) => item.url !== currentUrl
      );
    const customerImageDisplays = [
      ...otherImages.map((item) =>
        item.id ? { id: item.id } : { url: item.url }
      ),
      { id: id },
    ];

    const payload = {
      variables: {
        store: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              pos: {
                customer_display_images: customerImageDisplays,
              },
            },
          },
        },
      },
      refetchQueries: [
        {
          query: touchpointPosQuery,
        },
      ],
      onError: (error) => {
        throw new Error(error);
      },
    };
    return mutateStore(payload);
  };

  return (
    <SettingAccordion
      id="touchpointPOS"
      title={t('settings.touchpoint_pos.title')}
      onExpand={onExpand}
    >
      {(loading || isUpdatingStore) && <PreLoader size={30} />}
      <Grid container>
        {/* General Settings Section */}
        <Typography className={classes.subTitle}>
          {t('settings.touchpoint_pos.general_settings')}
        </Typography>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="receiptPrinting"
              label={t(
                'settings.touchpoint_pos.automatic_receipt_printing'
              )}
              description={t(
                'settings.touchpoint_pos.automatic_receipt_printing_description'
              )}
              value={posSettings?.pos?.auto_receipt_print}
              onChange={(value) =>
                updateGeneralSettings('auto_receipt_print', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="chefnotePrinting"
              label={t(
                'settings.touchpoint_pos.automatic_chefnote_printing'
              )}
              description={t(
                'settings.touchpoint_pos.automatic_chefnote_printing_description'
              )}
              value={posSettings?.pos?.auto_chef_print}
              onChange={(value) =>
                updateGeneralSettings('auto_chef_print', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="expenseReceiptPrinting"
              label={t(
                'settings.touchpoint_pos.automatic_expense_receipt_printing'
              )}
              description={t(
                'settings.touchpoint_pos.automatic_expense_receipt_printing_description'
              )}
              value={posSettings?.pos?.auto_expense_print}
              onChange={(value) =>
                updateGeneralSettings('auto_expense_print', value)
              }
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posFloat"
              label={t('settings.touchpoint_pos.float')}
              description={t(
                'settings.touchpoint_pos.float_description'
              )}
              value={posSettings?.pos?.float?.status}
              optionsText={transformFloat(
                posSettings?.pos?.float?.amount,
                localisation,
                { showCurrency: true }
              )}
              optionsValue={transformFloat(
                posSettings?.pos?.float?.amount,
                localisation,
                { showCurrency: false }
              )}
              optionsName="floatAmount"
              optionsLabel={t('settings.touchpoint_pos.float_amount')}
              onChangeOptions={(value) =>
                updateGeneralSettings('float', {
                  status: posSettings?.pos?.float?.status,
                  amount: parseFloat(value),
                })
              }
              onChange={(value) =>
                updateGeneralSettings('float', {
                  status: value,
                  amount: posSettings?.pos?.float?.amount,
                })
              }
              disableNotification
              placeholder={transformFloat(500, localisation, {
                showCurrency: false,
              })}
              isCurrency
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posManualAmount"
              label={t(
                'settings.touchpoint_pos.manual_amount_authorisation'
              )}
              description={t(
                'settings.touchpoint_pos.manual_amount_authorisation_description'
              )}
              value={posSettings?.pos?.manual_amount?.status}
              optionsText={transformFloat(
                posSettings?.pos?.manual_amount?.amount,
                localisation,
                { showCurrency: true }
              )}
              optionsName="manualAmount"
              optionsLabel={t('settings.touchpoint_pos.amount')}
              optionsValue={transformFloat(
                posSettings?.pos?.manual_amount?.amount,
                localisation,
                { showCurrency: false }
              )}
              onChangeOptions={(value) =>
                updateGeneralSettings('manual_amount', {
                  status: posSettings?.pos?.manual_amount?.status,
                  amount: parseFloat(value),
                })
              }
              onChange={(value) =>
                updateGeneralSettings('manual_amount', {
                  status: value,
                  amount: posSettings?.pos?.manual_amount?.amount,
                })
              }
              disableNotification
              placeholder={transformFloat(500, localisation, {
                showCurrency: false,
              })}
              isCurrency
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posAutoLock"
              label={t('settings.touchpoint_pos.auto_lock')}
              description={t(
                'settings.touchpoint_pos.auto_lock_description'
              )}
              value={posSettings?.pos?.scren_lock?.status}
              optionsText={`${posSettings?.pos?.scren_lock?.time} ${t(
                'settings.touchpoint_pos.seconds'
              )}`}
              optionsValue={posSettings?.pos?.scren_lock?.time}
              optionsName="lockTime"
              optionsLabel={t('settings.touchpoint_pos.lock_time')}
              onChangeOptions={(value) =>
                updateGeneralSettings('scren_lock', {
                  status: posSettings?.pos?.scren_lock?.status,
                  time: parseInt(value),
                })
              }
              onChange={(value) =>
                updateGeneralSettings('scren_lock', {
                  status: value,
                  time: posSettings?.pos?.scren_lock?.time,
                })
              }
              disableNotification
              isNumber
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posCashDrawer"
              label={t('settings.touchpoint_pos.cash_drawer_alarm')}
              description={t(
                'settings.touchpoint_pos.cash_drawer_alarm_desciption'
              )}
              value={posSettings?.pos?.cashdrawer_alarm?.status}
              optionsText={`${
                posSettings?.pos?.cashdrawer_alarm?.time
              } ${t('settings.touchpoint_pos.seconds')}`}
              optionsValue={posSettings?.pos?.cashdrawer_alarm?.time}
              optionsName="cashDrawerTimer"
              optionsLabel={t(
                'settings.touchpoint_pos.cash_drawer_alarm_timer'
              )}
              onChangeOptions={(value) =>
                updateGeneralSettings('cashdrawer_alarm', {
                  status: posSettings?.pos?.cashdrawer_alarm?.status,
                  time: parseInt(value),
                })
              }
              onChange={(value) =>
                updateGeneralSettings('cashdrawer_alarm', {
                  status: value,
                  time: posSettings?.pos?.cashdrawer_alarm?.time,
                })
              }
              disableNotification
              isNumber
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posGroupItemInCart"
              label={t(
                'settings.touchpoint_pos.group_same_items_in_cart'
              )}
              description={t(
                'settings.touchpoint_pos.group_same_items_in_cart_desctiption'
              )}
              value={posSettings?.pos?.cart?.group_by_items}
              onChange={(value) =>
                updateGeneralSettings('cart', {
                  group_by_items: value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <PosTypesPicker
              name="posPaymentTyoeAccepted"
              title={t(
                'settings.touchpoint_pos.update_payment_types_accepted'
              )}
              value={posSettings?.pos?.payment_types}
              options={paymentTypes}
              name="paymentTypes"
              label={t('settings.touchpoint_pos.payment_types')}
              description={t(
                'settings.touchpoint_pos.payment_types_desciption'
              )}
              onChange={(value) =>
                updateGeneralSettings('payment_types', value)
              }
            />
          </Grid>
        </Grid>

        {/* Images Section */}
        <Typography className={classes.subTitle}>
          {t('settings.touchpoint_pos.images')}
        </Typography>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.titleText}>
              {t('settings.touchpoint_pos.general_settings')}
            </Typography>
            <Typography className={classes.descriptionText}>
              {t('settings.touchpoint_pos.banners_description')}
            </Typography>
            <div className={classes.boxImage}>
              <ImagePicker
                id={posSettings?.pos?.banner?.id}
                widthCrop={428}
                heightCrop={250}
                imageSizeRequired
                name="posBanner"
                title={t('settings.touchpoint_pos.update_banner')}
                thumbnail={posSettings?.pos?.banner?.url || ''}
                onChange={(value) =>
                  updateImageBanner({
                    id: value.id,
                  })
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.titleText}>
              {t('settings.touchpoint_pos.customer_display_images')}
            </Typography>
            <Typography className={classes.descriptionText}>
              {t(
                'settings.touchpoint_pos.customer_display_images_description'
              )}
            </Typography>
            <div className={classes.boxImage}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} xl={3}>
                  <ImagePicker
                    id={
                      posSettings?.pos?.customer_display_images[0]?.id
                    }
                    widthCrop={428}
                    heightCrop={250}
                    imageSizeRequired
                    name="customerDisplay1"
                    title={t(
                      'settings.touchpoint_pos.update_customer_display'
                    )}
                    thumbnail={
                      posSettings?.pos?.customer_display_images[0]
                        ?.url || ''
                    }
                    onChange={(value) =>
                      updateCustomerImageDisplay(
                        value,
                        posSettings?.pos?.customer_display_images[0]
                          ?.url || ''
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} xl={3}>
                  <ImagePicker
                    id={
                      posSettings?.pos?.customer_display_images[1]?.id
                    }
                    widthCrop={428}
                    heightCrop={250}
                    imageSizeRequired
                    name="customerDisplay2"
                    title={t(
                      'settings.touchpoint_pos.update_customer_display'
                    )}
                    thumbnail={
                      posSettings?.pos?.customer_display_images[1]
                        ?.url || ''
                    }
                    onChange={(value) =>
                      updateCustomerImageDisplay(
                        value,
                        posSettings?.pos?.customer_display_images[1]
                          ?.url || ''
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} xl={3}>
                  <ImagePicker
                    id={
                      posSettings?.pos?.customer_display_images[2]?.id
                    }
                    widthCrop={428}
                    heightCrop={250}
                    imageSizeRequired
                    name="customerDisplay3"
                    title={t(
                      'settings.touchpoint_pos.update_customer_display'
                    )}
                    thumbnail={
                      posSettings?.pos?.customer_display_images[2]
                        ?.url || ''
                    }
                    onChange={(value) =>
                      updateCustomerImageDisplay(
                        value,
                        posSettings?.pos?.customer_display_images[2]
                          ?.url || ''
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} xl={3}>
                  <ImagePicker
                    id={
                      posSettings?.pos?.customer_display_images[3]?.id
                    }
                    widthCrop={428}
                    heightCrop={250}
                    imageSizeRequired
                    name="customerDisplay4"
                    title={t(
                      'settings.touchpoint_pos.update_customer_display'
                    )}
                    thumbnail={
                      posSettings?.pos?.customer_display_images[3]
                        ?.url || ''
                    }
                    onChange={(value) =>
                      updateCustomerImageDisplay(
                        value,
                        posSettings?.pos?.customer_display_images[3]
                          ?.url || ''
                      )
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        {/* Product Arrangement Section */}
        <Typography className={classes.subTitle}>
          {t('settings.touchpoint_pos.product_arrangement')}
        </Typography>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posSortByAlphabetical"
              label={t(
                'settings.touchpoint_pos.sort_by_alphabetical_order'
              )}
              description={t(
                'settings.touchpoint_pos.sort_by_alphabetical_order_description'
              )}
              value={posSettings?.pos?.product_view?.sort_by_name}
              onChange={(value) =>
                updateProductSettings('sort_by_name', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <PosArrangeCategory
              isSortByName={
                posSettings?.pos?.product_view?.sort_by_name
              }
              value={posSettings?.pos?.product_view?.categories || []}
              label={t(
                'settings.touchpoint_pos.category_display_position'
              )}
              description={t(
                'settings.touchpoint_pos.category_display_position_description'
              )}
              onChange={(value) =>
                updateProductSettings('categories', value)
              }
            />
          </Grid>
        </Grid>

        {/* Receipt Settings Section */}
        <Typography className={classes.subTitle}>
          {t('settings.touchpoint_pos.receipt_settings')}
        </Typography>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posLogo"
              label={t('settings.touchpoint_pos.logo')}
              description={t(
                'settings.touchpoint_pos.logo_description'
              )}
              value={posSettings?.pos?.receipts?.logo}
              onChange={(value) =>
                updateReceiptSettings('logo', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posTaxSummary"
              label={t('settings.touchpoint_pos.tax_summary')}
              description={t(
                'settings.touchpoint_pos.tax_summary_description'
              )}
              value={posSettings?.pos?.receipts?.tax_summary}
              onChange={(value) =>
                updateReceiptSettings('tax_summary', value)
              }
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posPhoneNumber"
              label={t('settings.touchpoint_pos.phone_number')}
              description={t(
                'settings.touchpoint_pos.phone_numner_desctiption'
              )}
              value={posSettings?.pos?.receipts?.phone}
              onChange={(value) =>
                updateReceiptSettings('phone', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posCashierName"
              label={t('settings.touchpoint_pos.cashier_name')}
              description={t(
                'settings.touchpoint_pos.cashier_name_description'
              )}
              value={posSettings?.pos?.receipts?.cashier_name}
              onChange={(value) =>
                updateReceiptSettings('cashier_name', value)
              }
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posRegistrationName"
              label={t('settings.touchpoint_pos.registration_number')}
              description={t(
                'settings.touchpoint_pos.registration_number_description'
              )}
              value={posSettings?.pos?.receipts?.registration_number}
              onChange={(value) =>
                updateReceiptSettings('registration_number', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posWebsiteAddress"
              label={t('settings.touchpoint_pos.website_address')}
              description={t(
                'settings.touchpoint_pos.website_address_description'
              )}
              value={posSettings?.pos?.receipts?.website}
              onChange={(value) =>
                updateReceiptSettings('website', value)
              }
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="posQrCode"
              label={t('settings.touchpoint_pos.qr_code')}
              description={t(
                'settings.touchpoint_pos.qr_code_description'
              )}
              value={posSettings?.pos?.receipts?.barcode}
              onChange={(value) =>
                updateReceiptSettings('barcode', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <PosAddressType
              isFetch={isExpand}
              name="posAddressType"
              value={posSettings?.pos?.receipts?.address_type}
              name="addressType"
              label={t('settings.touchpoint_pos.address_type')}
              description={t(
                'settings.touchpoint_pos.address_type_description'
              )}
              onChange={(value) =>
                updateReceiptSettings('address_type', value)
              }
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingInput
              value={posSettings?.pos?.receipts?.custom_message}
              name="posCustomMessage"
              label={t('settings.touchpoint_pos.custom_message')}
              description={
                posSettings?.pos?.receipts?.custom_message
                  ? t(
                      'settings.touchpoint_pos.custom_message_description',
                      {
                        message:
                          posSettings?.pos?.receipts?.custom_message,
                      }
                    )
                  : t(
                      'settings.touchpoint_pos.custom_message_description_empty'
                    )
              }
              descriptionType
              onChange={(value) =>
                updateReceiptSettings('custom_message', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <PosPrintPromotion
              value={posSettings?.pos?.receipts?.promotions || ''}
              name="posPrintPromotion"
              label={t('settings.touchpoint_pos.print_promotion')}
              description={
                posSettings?.pos?.receipts?.promotions?.length > 0
                  ? t(
                      'settings.touchpoint_pos.print_promotion_not_empty',
                      {
                        promotions: getPromotionNames(
                          posSettings?.pos?.receipts?.promotions
                        ),
                      }
                    )
                  : t(
                      'settings.touchpoint_pos.print_promotion_description'
                    )
              }
              actionText={
                posSettings?.pos?.receipts?.promotions?.length > 0
                  ? t('settings.update')
                  : t('settings.select')
              }
              onChange={(value) =>
                updateReceiptSettings('promotions', [
                  ...posSettings?.pos?.receipts?.promotions,
                  value,
                ])
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </SettingAccordion>
  );
};

export default TouchpointPOS;
