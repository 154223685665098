import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, sumBy } from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  TableRow,
  TableCell,
  withStyles,
  Box,
  Typography,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import Input from '../../../components/input'
import Selector from '../../../components/selector'
import ItemSelector from '../../../components/itemSelector'

import { selectLocalisation } from '@/store/modules/store/selectors'

import { Colors, CommonFonts } from '@/theme'

import {
  getLocalisationVal,
  transformInput,
} from '@/utils/localisationUtil';
import { calcGrossPriceFromPrice, calcPriceFromGrossPrice } from '@/utils/priceUtil'
import { getTaxName } from '@/utils/taxUtil'
import { EXPENSE_TAX_STATUS } from '../../../helper'
import useStyles from './styles'

const Row = ({
  taxStatus,
  item,
  taxes,
  accounts,
  onUpdateItem,
  onVoidItem,
  provided,
  style,
  onAddNewProduct
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const localisation = useSelector(selectLocalisation);

  const getTotalOfItem = () => {
    const quantity = parseInt(item.quantity) || 0
    let amount = taxStatus === EXPENSE_TAX_STATUS.INCLUSIVE_TAX ? item.grossAmount : item.amount
    amount = parseFloat(amount) || 0
    return quantity * amount
  }

  return (
    <TableRow
      hover
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={style}
      className={classes.row}
    >
      <StyledTableCell>
        <Input
          title=''
          emptyLabel={t('add_product.add')}
          value={item.product_code}
          onChange={(product_code) => onUpdateItem({ ...item, product_code })}
          onBlur={() => { }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <ItemSelector
          defaultProductName={item.product_name}
          onSelectInventory={(inventory) => {
            onUpdateItem({
              ...item,
              inventory_id: inventory.id,
              bar_code: inventory.bar_code,
              product_code: inventory.product_code,
              product_name: inventory.name
            })
          }}
          onAddNewProduct={onAddNewProduct}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Selector
          emptyLabel={t('add_product.select')}
          items={accounts}
          getItemLabel={(account) => account.name}
          selectedItem={item.account || {}}
          onChangeValue={(account) => onUpdateItem({ ...item, account })}
          onBlur={() => { }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Input
          type='number'
          title=''
          emptyLabel={t('add_product.add')}
          value={item.quantity}
          onChange={(quantity) => onUpdateItem({ ...item, quantity })}
          onBlur={() => { }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Input
          isCurrencyInput={true}
          localisation={localisation}
          title=''
          emptyLabel={t('add_product.add')}
          value={taxStatus === EXPENSE_TAX_STATUS.INCLUSIVE_TAX
            ? getLocalisationVal(localisation, item.grossAmount || '0')
            : getLocalisationVal(localisation, item.amount || '0')
          }
          onChange={(value) => {
            if (taxStatus === EXPENSE_TAX_STATUS.INCLUSIVE_TAX) {
              const grossAmount = transformInput(localisation, value)
              const amount = calcPriceFromGrossPrice(+grossAmount, item.taxes)
              onUpdateItem({ ...item, grossAmount, amount })
            } else {
              const amount = transformInput(localisation, value)
              const grossAmount = calcGrossPriceFromPrice(+amount, item.taxes)
              onUpdateItem({ ...item, grossAmount, amount })
            }
          }}
          onBlur={() => { }}
        />
      </StyledTableCell>
      {taxStatus !== EXPENSE_TAX_STATUS.NO_TAX &&
        <StyledTableCell>
          <Selector
            emptyLabel={t('add_product.select')}
            items={taxes}
            getItemLabel={(tax) => getTaxName(tax)}
            selectedItem={item.taxes?.[0] || {}}
            onChangeValue={(tax) => {
              let grossAmount = +item.grossAmount || 0
              let amount = +item.amount || 0
              const taxes = [tax]
              if (taxStatus === EXPENSE_TAX_STATUS.INCLUSIVE_TAX) {
                amount = calcPriceFromGrossPrice(grossAmount, taxes)
                onUpdateItem({ ...item, taxes, amount })
              } else {
                grossAmount = calcGrossPriceFromPrice(amount, taxes)
                onUpdateItem({ ...item, taxes, grossAmount })
              }

            }}
            onBlur={() => { }}
          />
        </StyledTableCell>
      }
      <StyledTableCell>
        <Typography className={classes.label}>
          {getLocalisationVal(localisation, getTotalOfItem())}
        </Typography>
      </StyledTableCell>
      <StyledTableCell align='right'>
        <IconButton
          onClick={() => onVoidItem(item)}
          className={classes.closeButton}
        >
          <CloseIcon style={{ fontSize: 14 }} />
        </IconButton>
      </StyledTableCell>
    </TableRow>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 10,
    paddingLeft: 10,
    ...CommonFonts.darkFont16,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont16
  },
}))(TableCell);

Row.propTypes = {
  item: PropTypes.object,
}

export default Row