import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import {
  Typography,
  Box,
  Grid,
  TextField,
  FormHelperText,
  Button
} from '@material-ui/core'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import useStyles from './styles'

const filter = createFilterOptions();

const Selector = (props) => {
  const classes = useStyles()
  const { t } = useTranslation();

  const {
    name,
    title,
    emptyLabel,
    items,
    getItemLabel,
    selectedItem,
    onChangeValue,
    onAddNewValue,
    onBlur = () => { },
    error,
    helperText,
  } = props

  const [open, setOpen] = useState(false)

  return (
    <Grid container justify="space-between">
      <Grid item xs={12}>
        <Box>
          <Typography
            className={
              classNames(classes.titleText, {
                [classes.errorTitleText]: error
              })
            }
          >
            {title}
          </Typography>

          <Box className={classes.selectContainer}>
            {!open &&
              <Box
                style={{ position: 'relative' }}
                onClick={() => setOpen(true)}
              >
                <Typography
                  className={
                    classNames(classes.label, {
                      [classes.emptyLabel]: isEmpty(selectedItem)
                    })
                  }
                >
                  {isEmpty(selectedItem) ? emptyLabel : getItemLabel(selectedItem)}

                  {!isEmpty(selectedItem) &&
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => setOpen(true)}
                    >
                      <CreateIcon fontSize='small' />
                    </IconButton>
                  }
                </Typography>
              </Box>
            }

            {open &&
              <Autocomplete
                freeSolo
                id={name}
                disableClearable
                options={items}
                openOnFocus
                blurOnSelect
                onChange={(event, value) => onChangeValue(value)}
                onBlur={(e) => {
                  setOpen(false)
                  onBlur(e)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                  />
                )}
                getOptionLabel={(option) => getItemLabel(option)}
                renderOption={(option, { inputValue }) => {
                  const matches = match(option.name, inputValue);
                  const parts = parse(option.name, matches);

                  if (!option.isNewButton) {
                    return (
                      <Box paddingX={2} paddingY={3}>
                        {inputValue?.length > 2 ? (
                          <>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight
                                    ? 600
                                    : 400,
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </>
                        ) : (
                          <span>{option.name}</span>
                        )}
                      </Box>
                    );
                  }
                  return (
                    <div style={{ marginTop: 40 }}>
                      <Box
                        className={classes.addButtonWrapper}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        paddingY={3}
                      >
                        <span
                          style={{
                            fontWeight: 600,
                            marginLeft: 16,
                          }}
                        >
                          {inputValue}
                        </span>
                        <Button
                          color="primary"
                          className={classes.addButton}
                          onClick={() => onAddNewValue(inputValue)}
                        >
                          {`+ ${t('button_group.add_new')}`}
                        </Button>
                      </Box>
                    </div>
                  );
                }}
                filterOptions={(options, params) => {
                  if (params.inputValue?.length > 2) {
                    const filtered = filter(options, params);
                    if (
                      params.inputValue !== '' &&
                      !filtered.includes(params.inputValue)
                    ) {
                      filtered.push({
                        name: params.inputValue,
                        isNewButton: true,
                      });
                    }
                    return filtered;
                  }
                  return options;
                }}
              />
            }
            {error &&
              <FormHelperText error>
                {helperText}
              </FormHelperText>
            }
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}


Selector.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  emptyLabel: PropTypes.string,
  items: PropTypes.array,
  getItemLabel: PropTypes.func,
  selectedItem: PropTypes.any,
  onChangeValue: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string
}

export default Selector