import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import {
  GET_TAXES,
  EDIT_CATEGORY,
  GET_CATEGORY_BY_ID,
  GET_CATEGORIES,
} from '@/services/inventoryService';
import ImagePicker from '@/components/imagePicker';
import SettingSwitch from '@/components/settingSwitch';
import { useTranslation } from 'react-i18next';
import RangeTimePickerSwitch from '@/components/rangeTimePickerSwitch';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useSnackbar } from 'notistack';
import CategoriesAutocomplete from './addNewCategory/CategoriesAutocomplete';
import ParentAutocomplete from './addNewCategory/ParentAutocomplete';
import TaxesAutocomplete from './addNewCategory/TaxesAutocomplete';
import useStyles from './styles';
import * as Yup from 'yup';

export default function ResponsiveDialog({
  categoryId,
  open,
  handleClose,
  allCategories,
  emptyCategories,
  setRefetchCategories,
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [categoryInitialValues, setcategoryInitialValues] = useState(
    {}
  );

  const [taxes, settaxes] = useState();
  const [categoryTaxes, setCategoryTaxes] = useState([]);
  const [initialTaxes, setInitialTaxes] = useState();
  const [taxesObjLength, settaxesObjLength] = useState();
  const [mainCategorySelected, setMainCategorySelected] = useState();
  const [parentCategoryId, setParentCategoryId] = useState(null);
  const [ageSelected, setAgeSelected] = useState();
  const [ageRestriction, setAgeRestriction] = useState(false);
  const [comissionSales, setComissionSales] = useState(false);
  const [chefNotes, setChefNotes] = useState(false);
  const [soldHours, setSoldHours] = useState(false);
  const [sellTimeRange, setsellTimeRange] = useState();
  const [defaultParentCategory, setDefaultParentCategory] =
    useState();

  const [defaultCategoryName, setDefaultCategoryName] = useState();

  const [loadingUpdateCategory, setLoadingUpdate] = useState(false);

  const [imageUrl, setImageUrl] = useState(null);
  const [imageId, setImageId] = useState(null);

  const { loading } = useQuery(GET_TAXES, {
    onCompleted: (data) => {
      const grouped = _.groupBy(data.store.taxes, 'name');
      settaxesObjLength(Object.keys(grouped).length);
      settaxes(grouped);
      setInitialTaxes(grouped);
    },
    fetchPolicy: 'network-only',
  });

  const [getCategoryById, { loading: loadingCateogryDetails }] =
    useLazyQuery(GET_CATEGORY_BY_ID, {
      variables: {
        id: categoryId,
      },
      context: {
        headers: { storeId: '-1' },
      },
      onCompleted: (data) => {
        const categoryDetails = data.categories[0];
        setcategoryInitialValues(categoryDetails);
        setChefNotes(categoryDetails.kitchen_print);
        setAgeRestriction(categoryDetails.age_restrict);
        setComissionSales(categoryDetails.commission);
        setDefaultParentCategory(categoryDetails.parent);
        setParentCategoryId(categoryDetails.parent);
        setAgeSelected(categoryDetails.age_restriction);
        setSoldHours(categoryDetails.sell_time_restrict);
        setsellTimeRange([
          categoryDetails.sell_time_from,
          categoryDetails.sell_time_until,
        ]);
        setDefaultCategoryName({
          id: categoryId,
          name: categoryDetails.name,
        });
        setImageUrl(categoryDetails?.image?.thumbnail);
        setImageId(categoryDetails?.image?.id);

        setCategoryTaxes(
          categoryDetails?.taxes.filter((tax) => !!tax)
        );
      },
      onError: (error) => {
        console.log('🔥🔥 ~ error', error);
      },
      fetchPolicy: 'no-cache',
    });

  // get category details from API
  useEffect(() => {
    getCategoryById();
  }, [categoryId]);

  useEffect(() => {
    // if (!mainCategorySelected?.isNewButton) {
    //   const grouped = _.groupBy(mainCategorySelected?.taxes, 'name');
    //   settaxes(grouped);
    // } else {
    //   settaxes(initialTaxes);
    // }
  }, [mainCategorySelected]);

  const [EditCategory] = useMutation(EDIT_CATEGORY, {
    onCompleted: (response) => {
      setLoadingUpdate(false);
      enqueueSnackbar(t('categories.category_updated_successfully'));
      handleClose();
      setRefetchCategories();
    },
    onError: (error) => {
      console.log('error', error);
    },
    refetchQueries: [{ query: GET_CATEGORIES }],
  });

  // Save new category created
  const editCategory = () => {
    setLoadingUpdate(true);
    const payload = {
      variables: {
        input: {
          id: categoryId,
          name: mainCategorySelected?.name,
          tax_id: categoryTaxes.map((tax) => tax.id).join(','),
          parent: parentCategoryId,
          age_restrict: ageRestriction,
          age_restriction: ageSelected && parseInt(ageSelected, 10),
          sell_time_restrict: soldHours,
          sell_time_from: sellTimeRange && sellTimeRange[0],
          sell_time_until: sellTimeRange && sellTimeRange[1],
          commission: comissionSales,
          kitchen_print: chefNotes,
          image: imageId && {
            id: imageId,
          },
        },
      },
    };
    return EditCategory(payload);
  };

  const ageSchema = Yup.object().shape({
    age: Yup.string().required(t('settings.categories.age_required')),
  });

  return (
    <div>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          onClose={() => console.log('ok')}
        >
          <Grid
            container
            alignItems="center"
            justify="space-between"
            alignContent="center"
          >
            <Grid item xs={1} />
            <Grid className={classes.headerText} item xs={10}>
                {t('categories.edit_category_title')}
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {(loadingCateogryDetails || loadingUpdateCategory) && (
            <PreLoader size={25} />
          )}
          <div className={classes.rootDialog}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  spacing={5}
                  justify={
                    taxesObjLength &&
                    taxesObjLength === 2 &&
                    taxesObjLength === 1
                      ? 'space-between'
                      : 'flex-start'
                  }
                >
                  <Grid item sm={12} xs={12}>
                    <span className={classes.blockTitle}>
                      {t('categories.general_information')}
                    </span>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <div style={{ paddingRight: 15 }}>
                      <CategoriesAutocomplete
                        setMainCategorySelected={(value) =>
                          setMainCategorySelected(value)
                        }
                        defaultValue={categoryId}
                        defaultCategoryName={defaultCategoryName}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <ParentAutocomplete
                      allCategories={allCategories}
                      emptyCategories={emptyCategories}
                      setParentCategoryId={(value) =>
                        setParentCategoryId(value)
                      }
                      defaultValue={defaultParentCategory}
                    />
                  </Grid>
                  {taxes &&
                    Object.keys(taxes).map((taxName, index) => (
                      <Grid key={index} item sm={3} xs={12}>
                        <TaxesAutocomplete
                          taxName={taxName}
                          taxes={taxes[taxName]}
                          selectedTax={categoryTaxes.find(
                            (tax) => tax.name === taxName
                          )}
                          onChangeTax={(tax) => {
                            if (
                              categoryTaxes.find(
                                (item) => item.name === tax.name
                              )
                            ) {
                              setCategoryTaxes(
                                categoryTaxes.map((item) =>
                                  item.name === tax.name ? tax : item
                                )
                              );
                            } else {
                              setCategoryTaxes([
                                ...categoryTaxes,
                                tax,
                              ]);
                            }
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  className={classes.categoryImage}
                  spacing={5}
                >
                  <Grid item xs={12}>
                    <span className={classes.blockTitle}>
                      {t('categories.category_image')}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <ImagePicker
                      key={imageUrl}
                      widthCrop={450}
                      heightCrop={300}
                      id={imageId}
                      thumbnail={imageUrl}
                      onChange={(value, imageProperty) => {
                        setImageId(value.id);
                        setImageUrl(imageProperty);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  className={classes.rules}
                  spacing={5}
                  justify="space-between"
                >
                  <Grid item sm={12} xs={12}>
                    <span className={classes.blockTitle}>
                      {t('categories.rules')}
                    </span>
                  </Grid>

                  <Grid item sm={5} xs={12}>
                    <SettingSwitch
                      name="age"
                      label={t('categories.age_restriction')}
                      description={t(
                        'categories.age_restriction_description'
                      )}
                      value={ageRestriction}
                      optionsText={ageSelected}
                      optionsValue={ageSelected}
                      optionsName="age"
                      optionsLabel={t('categories.enter_minimum_age')}
                      onChangeOptions={(value) =>
                        setAgeSelected(value)
                      }
                      onChange={(value) => setAgeRestriction(value)}
                      disableNotification
                      validationSchema={ageSchema}
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <RangeTimePickerSwitch
                      label={t('categories.sold_hours')}
                      description={t(
                        'categories.sold_hours_description'
                      )}
                      value={soldHours}
                      onChange={(value) => setSoldHours(value)}
                      onChangeOptions={(value) =>
                        setsellTimeRange(value)
                      }
                      optionsText={sellTimeRange}
                      optionsValue={sellTimeRange}
                      optionsName="timeRangwe"
                      disableNotification
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <SettingSwitch
                      label={t('categories.commission_sales')}
                      description={t(
                        'categories.commission_sales_description'
                      )}
                      value={comissionSales}
                      onChange={(value) => setComissionSales(value)}
                      disableNotification
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <SettingSwitch
                      label={t('categories.chef_note')}
                      description={t(
                        'categories.chef_note_description'
                      )}
                      value={chefNotes}
                      onChange={(value) => setChefNotes(value)}
                      disableNotification
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: 30 }}>
          <Button
            color="primary"
            onClick={handleClose}
            style={{
              font: 'normal normal normal 16px/24px Montserrat',
              letterSpacing: '0.11px',
              color: '#939DA8',
              opacity: '1',
            }}
          >
            {t('categories.cancel')}
          </Button>
          <Button color="primary" onClick={() => editCategory()}>
            {t('categories.update_category')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
