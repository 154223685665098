import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import {
  Typography,
  Box,
  Grid,
  TextField,
  FormHelperText
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import useStyles from './styles'

const Selector = (props) => {
  const classes = useStyles()

  const {
    name,
    title,
    emptyLabel,
    items,
    getItemLabel,
    selectedItem,
    onChangeValue,
    onBlur = () => { },
    error,
    helperText,
  } = props

  const [open, setOpen] = useState(false)

  return (
    <Grid container justify="space-between">
      <Grid item xs={12}>
        <Box>
          <Typography
            className={
              classNames(classes.titleText, {
                [classes.errorTitleText]: error
              })
            }
          >
            {title}
          </Typography>

          <Box className={classes.selectContainer}>
            {!open &&
              <Box
                style={{ position: 'relative' }}
                onClick={() => setOpen(true)}
              >
                <Typography
                  className={
                    classNames(classes.label, {
                      [classes.emptyLabel]: isEmpty(selectedItem)
                    })
                  }
                >
                  {isEmpty(selectedItem)
                    ? emptyLabel
                    : getItemLabel(selectedItem).split(' ')?.[0]?.charAt(0) === '#'
                      ?
                      <>
                        <span
                          style={{
                            width: 15,
                            height: 15,
                            display: 'inline-flex',
                            backgroundColor: getItemLabel(selectedItem).split(' ')?.[0],
                            marginRight: 5
                          }}
                        />
                        <span>
                          {getItemLabel(selectedItem)}
                        </span>
                      </>
                      :
                      getItemLabel(selectedItem)
                  }

                  {!isEmpty(selectedItem) &&
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => setOpen(true)}
                    >
                      <CreateIcon fontSize='small' />
                    </IconButton>
                  }
                </Typography>
              </Box>
            }

            {open &&
              <Autocomplete
                freeSolo
                id={name}
                disableClearable
                options={items}
                openOnFocus
                blurOnSelect
                onChange={(event, value) => onChangeValue(value)}
                onBlur={(e) => {
                  setOpen(false)
                  onBlur(e)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                  />
                )}
                getOptionLabel={(option) => getItemLabel(option)}
                renderOption={(option, { inputValue }) => {
                  const matches = match(getItemLabel(option), inputValue);
                  const parts = parse(getItemLabel(option), matches);

                  return (
                    <Box paddingX={2} paddingY={3}>
                      {inputValue?.length > 2 ? (
                        <Box display='flex' alignItems='center'>
                          {getItemLabel(option).split(' ')?.[0]?.charAt(0) === '#' &&
                            <Box
                              style={{
                                width: 15,
                                height: 15,
                                marginRight: 5,
                                backgroundColor: getItemLabel(option).split(' ')?.[0]
                              }}
                            />
                          }
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight
                                  ? 600
                                  : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </Box>
                      ) : (
                        <Box display='flex' alignItems='center'>
                          {getItemLabel(option).split(' ')?.[0]?.charAt(0) === '#' &&
                            <Box
                              style={{
                                width: 15,
                                height: 15,
                                marginRight: 5,
                                backgroundColor: getItemLabel(option).split(' ')?.[0]
                              }}
                            />
                          }
                          <span>{getItemLabel(option)}
                          </span>
                        </Box>
                      )}
                    </Box>
                  );
                }}
              />
            }
            {error &&
              <FormHelperText error>
                {helperText}
              </FormHelperText>
            }
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}


Selector.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  emptyLabel: PropTypes.string,
  items: PropTypes.array,
  getItemLabel: PropTypes.func,
  selectedItem: PropTypes.any,
  onChangeValue: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string
}

export default Selector