import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash'

import {
  DialogContent,
  DialogTitle,
  Box,
  Typography
} from '@material-ui/core'

import ProductSelect from './ProductSelect'
import Main from './Main'

import useStyles from '../styles'

const ReducedPrice = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    defaultPromotion,
    promotion,
    onChangePromotion,
    onAddUpdatePromotion,
    handleClose
  } = props

  return (
    <Box>
      {isEmpty(promotion.options[0].products)
        ?
        <ProductSelect
          promotion={promotion}
          onChangePromotion={onChangePromotion}
        />
        :
        <Main
          defaultPromotion={defaultPromotion}
          promotion={promotion}
          onChangePromotion={onChangePromotion}
          onAddUpdatePromotion={onAddUpdatePromotion}
          handleClose={handleClose}
        />
      }
    </Box>
  )
}

export default ReducedPrice