import I18n from '@/i18n'
import moment from 'moment'

export const DATE_FILTERS = {
  TODAY: I18n.t('workboard.today'),
  YESTERDAY: I18n.t('workboard.yesterday'),
  WEEK_TO_DATE: I18n.t('workboard.week_to_date'),
  MONTH_TO_DATE: I18n.t('workboard.month_to_date'),
  SELECT_DATES: I18n.t('workboard.select_dates'),
}

export const getStartTime = (dateFilter, dateRange) => {
  if (dateFilter === DATE_FILTERS.TODAY) {
    return moment().startOf('day').utc().format()
  }
  else if (dateFilter === DATE_FILTERS.YESTERDAY) {
    return moment().subtract(1, 'day').startOf('day').utc().format()
  }
  else if (dateFilter === DATE_FILTERS.WEEK_TO_DATE) {
    return moment().startOf('week').utc().format()
  }  
  else if (dateFilter === DATE_FILTERS.MONTH_TO_DATE) {
    return moment().startOf('month').utc().format()
  }
  else if (dateFilter === DATE_FILTERS.SELECT_DATES) {
    return moment(dateRange[0]).startOf('day').utc().format()
  }
}

export const getEndTime = (dateFilter, dateRange) => {
  if (dateFilter === DATE_FILTERS.TODAY || dateFilter === DATE_FILTERS.WEEK_TO_DATE || dateFilter === DATE_FILTERS.MONTH_TO_DATE) {
    return moment().endOf('hour').utc().format()
  }
  else if (dateFilter === DATE_FILTERS.YESTERDAY) {
    return moment().subtract(1, 'day').endOf('day').utc().format()
  }
  else if (dateFilter === DATE_FILTERS.SELECT_DATES) {
    return moment(dateRange[1]).endOf('day').utc().format()
  }
}