import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import images from '@/assets/images';

const ErrorPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Box
      height={'100vh'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box style={{ textAlign: 'center' }}>
        <img src={images.logo} width={125} height={50} alt="logo" />
        <Typography>{t('error.title')}</Typography>
        <Link
          component="button"
          variant="body2"
          onClick={() => history.push('/')}
        >
          {t('error.back_to_home')}
        </Link>
      </Box>
    </Box>
  );
};

export default ErrorPage;
