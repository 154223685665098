import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Search } from '@material-ui/icons';
import TextFiled from '@/components/textField';
import ListStores from './ListStores';
import SelectMerchant from './SelectMerchantTitle';
import ListMerchants from './ListMerchants';

import { Colors } from '@/theme';
import { contains } from '@/utils/stringUtil'
import useStyles from './styles';

export default function SelectStores({
  currentMerchant,
  allMerchants,
  withAllStoresOption,
  handleCloseStorePopover,
  onOpenAddWarehouse
}) {
  const classes = useStyles();
  const { t } = useTranslation()

  const [header, setheader] = useState('stores');
  const [inputSearch, setInputSearch] = useState('');
  const [filteredMerchants, setfilteredMerchants] = useState(
    allMerchants
  );

  const [selectedMerchant, setselectedMerchant] = useState(
    currentMerchant
  );

  const [filteredStores, setFilteredStores] = useState(
    selectedMerchant.stores
  );

  useEffect(() => {
    //updates merchants by merchant name, store name, store region name
    if (inputSearch !== '' && header === 'merchants') {
      let filterResult = allMerchants.filter(
        (merchant) =>
          contains(merchant.tname, inputSearch) ||
          contains(merchant.lname, inputSearch) ||
          merchant.stores.some(
            (store) =>
              contains(store?.region?.region_name, inputSearch) ||
              contains(store?.name, inputSearch)
          )
      );
      setfilteredMerchants(filterResult);
    } else {
      setfilteredMerchants(allMerchants);
    }

    //filter stores by search input value
    if (header === 'stores') {
      let filterResult = selectedMerchant?.stores?.filter(
        (store) =>
          contains(store?.name, inputSearch) ||
          contains(store?.region?.region_name, inputSearch)
      );
      setFilteredStores(filterResult);
    }
  }, [inputSearch]);

  useEffect(() => {
    setInputSearch('');
    setFilteredStores(selectedMerchant.stores);
  }, [header]);

  const onOpenAddMerchantPage = () => {
    window.open(`${window.location.protocol}//${window.location.host}/store/add-store`, '_blank')
  }

  return (
    <div className={classes.container}>
      <Grid
        spacing={1}
        container
        style={{
          marginTop: 5,
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <div className={classes.titleContainer}>
          {header === 'stores' ? (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              alignContent="center"
              style={{ height: '100%' }}
            >
              <Grid item>
                <span className={classes.title}>
                  {selectedMerchant.lname}
                </span>
              </Grid>
              <Grid item>
                <Link
                  className={classes.changeButton}
                  component="button"
                  variant="body2"
                  onClick={() => {
                    setheader('merchants');
                  }}
                >
                  {t('header.change')}
                </Link>
              </Grid>
            </Grid>
          ) : (
            <SelectMerchant
              changeHeader={() => setheader('stores')}
            />
          )}
        </div>

        <Grid
          item
          xs={12}
        >
          <TextFiled
            placeholder={header === 'stores'
              ? t('header.enter_store_region_warehouse')
              : t('header.enter_merchant_name')
            }
            inputRef={(input) =>
              inputSearch && input && input !== null && input.focus()
            }
            className={classes.searchInput}
            value={inputSearch}
            autoFocus={false}
            onChange={(e) => setInputSearch(e.target.value)}
            startAdornment={
              <Search
                style={{ color: Colors.GRAY_BORDER1 }}
                width={18}
                height={18}
              />
            }
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{
          marginTop: 24,
          paddingLeft: 30,
          paddingRight: 16,
        }}
      >
        {header === 'merchants' &&
          <span style={{ fontWeight: 500 }}>
            {t('header.merchants')}
          </span>
        }
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="center"
        style={{
          paddingLeft: 20,
          paddingRight: 16,
        }}
        className={classes.list}
      >
        {header === 'stores' ? (
          <ListStores
            stores={filteredStores}
            handleCloseStorePopover={handleCloseStorePopover}
            selectedMerchant={selectedMerchant}
            withAllStoresOption={withAllStoresOption}
            onOpenAddWarehouse={onOpenAddWarehouse}
          />
        ) : (
          <ListMerchants
            allMerchants={filteredMerchants}
            changeHeader={() => setheader('stores')}
            setselectedMerchant={(merchant) =>
              setselectedMerchant(merchant)
            }
          />
        )}
      </Grid>
      <Box paddingX={1}>
        <Box className={classes.addMerchantContainer}>
          <Box display='flex' justifyContent='center'>
            <Button
              color="primary"
              className={classes.addMerchantButton}
              onClick={() => {
                handleCloseStorePopover()
                onOpenAddMerchantPage()
              }}
            >
              {t('header.add_merchant')}
            </Button>
          </Box>
          <Box
            paddingX={15}
            marginTop={2}
            fontSize={14}
            textAlign='center'
          >
            <Trans
              i18nKey='header.visit_stores_page'
              components={{
                tag: <Link
                  component={RouterLink}
                  color='primary'
                  to='/stores'
                  underline='none'
                />
              }}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

SelectStores.propTypes = {
  allMerchants: PropTypes.array,
  currentMerchant: PropTypes.object,
  withAllStoresOption: PropTypes.bool,
  handleCloseStorePopover: PropTypes.func,
};
