import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SettingAccordion from '.';
import SettingInput from '../input';
import useStyles from './styles';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import {
  MUTATE_REGISTER_NAME,
  MUTATE_TRADING_NAME,
  MUTATE_REGISTRATION_NUMBER,
  MUTATE_REGISTRATION_TYPE,
  MUTATE_VAT_NUMBER,
  MUTATE_REGISTRATION_TAX,
  MUTATE_ADDRESS,
  STORE_DETAIL,
} from '@/services/storeService';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import RegistrationTypePicker from '../registrationTypePicker';
import SettingBusinessType from '../autocomplete/businessType';
import * as Yup from 'yup';
import RegistrationTaxPicker from '../registrationTaxesPicker';
import RegisteredAddressPicker from '../registeredAddressPicker';

const storeDetailQuery = gql`
  ${STORE_DETAIL}
`;

const BusinessAndFinance = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState(false);

  const onExpand = (value) => setIsExpand(value);

  const registeredNameSchema = Yup.object().shape({
    lname: Yup.string().required(
      t('settings.business_and_finance.registered_name_required')
    ),
  });

  const tradingNameSchema = Yup.object().shape({
    tname: Yup.string().required(
      t('settings.business_and_finance.trading_name_required')
    ),
  });

  const registrationNumberSchema = Yup.object().shape({
    reg_no: Yup.string().required(
      t('settings.business_and_finance.registration_number_required')
    ),
  });

  useEffect(() => {
    if (isExpand) {
      getStoreDetail();
    }
  }, [isExpand]);

  const [getStoreDetail, { loading, error, data, previousData }] =
    useLazyQuery(storeDetailQuery);
  const storeDetail = data?.merchant || previousData?.merchant;

  const updateStoreDetail = (key, value) => {
    const payload = {
      variables: {
        merchant: {
          id: storeDetail?.id,
          [key]: value,
        },
      },
      update: (store, { data: { updateMerchant } }) => {
        const cache = store.readQuery({ query: storeDetailQuery });
        const currentValue = {
          updateMerchant: {
            id: storeDetail?.id,
            [key]: value,
            __typename: 'Merchant',
          },
        };
        const updatedData = {
          ...cache,
          merchant: {
            ...cache.merchant,
            ...currentValue,
          },
        };
        store.writeQuery({
          query: storeDetailQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateMerchant: {
          id: storeDetail?.id,
          [key]: value,
          __typename: 'Merchant',
        },
      },
    };
    switch (key) {
      case 'lname':
        return updateRegisterName(payload);
      case 'tname':
        return updateTradingName(payload);
      default:
        throw Error('Unknown store property', key);
    }
  };

  const [updateRegisterName] = useMutation(
    gql`
      ${MUTATE_REGISTER_NAME}
    `
  );
  const [updateTradingName] = useMutation(
    gql`
      ${MUTATE_TRADING_NAME}
    `
  );
  const [updateRegistrationNumber] = useMutation(
    gql`
      ${MUTATE_REGISTRATION_NUMBER}
    `
  );
  const [updateRegistrationType] = useMutation(
    gql`
      ${MUTATE_REGISTRATION_TYPE}
    `
  );
  const [updateVatRegistration] = useMutation(
    gql`
      ${MUTATE_REGISTRATION_TAX}
    `
  );
  const [updateVatNumber] = useMutation(
    gql`
      ${MUTATE_VAT_NUMBER}
    `
  );
  const [updateAddress] = useMutation(
    gql`
      ${MUTATE_ADDRESS}
    `
  );

  const mutateRegistrationType = (value) => {
    const { id, name, currentId } = value;
    const payload = {
      variables: {
        merchant: {
          id: storeDetail?.id,
          registrations: [
            {
              id: currentId,
              flag: 'delete',
            },
            {
              id: id,
            },
          ],
        },
      },
      update: (store, { data: { updateMerchant } }) => {
        const cache = store.readQuery({ query: storeDetailQuery });

        const updatedData = {
          ...cache,
          merchant: {
            ...cache.merchant,
            registrations: {
              ...cache.merchant.registrations,
              entities: [
                {
                  id: id,
                  name: name,
                },
              ],
            },
          },
        };
        store.writeQuery({
          query: storeDetailQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
    };
    return updateRegistrationType(payload);
  };

  const mutateRegistrationNumber = (value) => {
    const payload = {
      variables: {
        merchant: {
          id: storeDetail?.id,
          registrations: [
            {
              id: storeDetail?.registrations?.entities[0].id,
              reg_number: value,
            },
          ],
        },
      },
      update: (store, { data: { updateMerchant } }) => {
        const cache = store.readQuery({ query: storeDetailQuery });

        const updatedData = {
          ...cache,
          merchant: {
            ...cache.merchant,
            registrations: {
              ...cache.merchant.registrations,
              entities: [
                {
                  ...cache.merchant.registrations.entities[0],
                  reg_number: value,
                },
              ],
            },
          },
        };
        store.writeQuery({
          query: storeDetailQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateMerchant: {
          id: storeDetail?.id,
          registrations: {
            entities: [
              {
                ...storeDetail?.registrations?.entities[0],
                reg_number: value,
                __typename: 'Registration',
              },
            ],
            taxes: [
              {
                ...storeDetail?.registrations?.taxes[0],
                __typename: 'Registration',
              },
            ],
            __typename: 'Registrations',
          },
          __typename: 'Merchant',
        },
      },
    };
    return updateRegistrationNumber(payload);
  };

  const mutateVatRegistration = (value) => {
    const { id, name, currentId } = value;
    const payload = {
      variables: {
        merchant: {
          id: storeDetail?.id,
          registrations: [
            {
              id: currentId,
              flag: 'delete',
            },
            {
              id: id,
            },
          ],
        },
      },
      update: (store, { data: { updateMerchant } }) => {
        const cache = store.readQuery({ query: storeDetailQuery });
        const currentValue = {
          taxes: {
            id: id,
            name: name,
          },
        };
        const updatedData = {
          ...cache,
          merchant: {
            ...cache.merchant,
            registrations: {
              ...cache.merchant.registrations.taxes,
              ...currentValue,
            },
          },
        };
        store.writeQuery({
          query: storeDetailQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
    };
    return updateVatRegistration(payload);
  };

  const mutateVatNumber = (value) => {
    const payload = {
      variables: {
        merchant: {
          id: storeDetail?.id,
          registrations: [
            {
              id: storeDetail?.registrations?.taxes[0].id,
              reg_number: value,
            },
          ],
        },
      },
      update: (store, { data: { updateMerchant } }) => {
        const cache = store.readQuery({ query: storeDetailQuery });

        const updatedData = {
          ...cache,
          merchant: {
            ...cache.merchant,
            registrations: {
              ...cache.merchant.registrations,
              taxes: [
                {
                  id: storeDetail?.registrations?.taxes[0].id,
                  reg_number: value,
                },
              ],
            },
          },
        };
        store.writeQuery({
          query: storeDetailQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateMerchant: {
          id: storeDetail?.id,
          registrations: {
            entities: [
              {
                ...storeDetail?.registrations?.entities[0],
                __typename: 'Registration',
              },
            ],
            taxes: [
              {
                ...storeDetail?.registrations?.taxes[0],
                reg_number: value,
                __typename: 'Registration',
              },
            ],
            __typename: 'Registrations',
          },
          __typename: 'Merchant',
        },
      },
    };
    return updateVatNumber(payload);
  };

  const mutateAddress = (value) => {
    const {
      building_name,
      door_number,
      line1,
      line2,
      city_town,
      postcode,
      country,
      floor,
      unit
    } = value;
    const payload = {
      variables: {
        merchant: {
          id: storeDetail?.id,
          address: {
            building_name: building_name,
            door_number: door_number,
            line1: line1,
            line2: line2,
            city_town: city_town,
            postcode: postcode,
            country: country,
            floor,
            unit
          },
        },
      },
      update: (store, { data: { updateMerchant } }) => {
        const cache = store.readQuery({ query: storeDetailQuery });

        const updatedData = {
          ...cache,
          merchant: {
            ...cache?.merchant,
            address: {
              building_name: building_name,
              door_number: door_number,
              line1: line1,
              line2: line2,
              city_town: city_town,
              postcode: postcode,
              country: country,
            },
          },
        };
        store.writeQuery({
          query: storeDetailQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateMerchant: {
          id: storeDetail?.id,
          address: {
            building_name: building_name,
            door_number: door_number,
            line1: line1,
            line2: line2,
            city_town: city_town,
            postcode: postcode,
            floor,
            unit,
            __typename: 'Address',
          },
          __typename: 'Merchant',
        },
      },
    };
    return updateAddress(payload);
  };

  const shouldShowRegNumber =
    storeDetail?.registrations?.entities[0]?.reg_required;

  return (
    <SettingAccordion
      id="businessAndFinance"
      title={t('settings.business_and_finance.title')}
      onExpand={onExpand}
    >
      {loading ? (
        <PreLoader size={30} />
      ) : (
        <Grid container>
          {/* Section 1 */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <SettingInput
                name="lname"
                label={t(
                  'settings.business_and_finance.input.registered_name'
                )}
                value={storeDetail?.lname}
                validationSchema={registeredNameSchema}
                onChange={(value) =>
                  updateStoreDetail('lname', value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SettingInput
                name="tname"
                label={t(
                  'settings.business_and_finance.input.trading_name'
                )}
                value={storeDetail?.tname}
                validationSchema={tradingNameSchema}
                onChange={(value) =>
                  updateStoreDetail('tname', value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <RegisteredAddressPicker
                subTitle={t(
                  'settings.business_and_finance.registered_address_sub_title'
                )}
                value={storeDetail?.address}
                label={t(
                  'settings.business_and_finance.input.registered_address'
                )}
                onChange={(value) => mutateAddress(value)}
              />
            </Grid>
          </Grid>
          {/* Section 2 */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <SettingBusinessType
                defaultTypeId={storeDetail?.business_type_id}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <RegistrationTypePicker
                isFetch={isExpand}
                value={storeDetail?.registrations?.entities[0] || {}}
                onChange={(value) => mutateRegistrationType(value)}
              />
            </Grid>
            {shouldShowRegNumber && (
              <Grid item xs={12} sm={4}>
                <SettingInput
                  name="reg_no"
                  label={t(
                    'settings.business_and_finance.input.registration_number'
                  )}
                  value={
                    storeDetail?.registrations?.entities[0]
                      .reg_number || ''
                  }
                  validationSchema={registrationNumberSchema}
                  onChange={(value) =>
                    mutateRegistrationNumber(value)
                  }
                />
              </Grid>
            )}
          </Grid>
          {/* Section 3 */}
          <Typography className={classes.subTitle}>
            {t('settings.business_and_finance.sub_title')}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <RegistrationTaxPicker
                isFetch={isExpand}
                value={storeDetail?.registrations?.taxes || []}
                onChange={(value) => mutateVatRegistration(value)}
                onChangeNumber={(value) => mutateVatNumber(value)}
              />
            </Grid>
          </Grid>
          {!data && <PreLoader size={30} />}
        </Grid>
      )}
    </SettingAccordion>
  );
};

export default BusinessAndFinance;
