import React, { useState } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';

const UserFilter = ({ name, options, emptyLabel, onChange }) => {
  const [selectValue, setSelectValue] = useState('');
  const classes = useStyles();

  return (
    <Select
      disableUnderline
      IconComponent={ExpandMoreIcon}
      displayEmpty
      fullWidth
      id={name}
      name={name}
      className={classes.filterInput}
      value={selectValue}
      onChange={async (event) => {
        const value = event.target.value;
        await onChange(value);
        setSelectValue(value);
      }}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {emptyLabel && (
        <MenuItem key="defaultItem" value="">
          {emptyLabel}
        </MenuItem>
      )}
      {(options || []).map((option, index) => {
        return (
          <MenuItem key={`${option}-${index}`} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default UserFilter;
