import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ListMerchants = ({
  allMerchants,
  changeHeader,
  setselectedMerchant,
}) => {
  const classes = useStyles();

  const handleListItemClick = (merchant) => {
    setselectedMerchant(merchant);
    changeHeader();
  };

  return (
    <List
      component="nav"
      aria-label="contacts"
      className={classes.listItem}
    >
      {allMerchants.map((merchant) => (
        <ListItem
          key={merchant.id}
          button
          style={{
            paddingTop: 24,
            paddingBottom: 24,
          }}
          onClick={() => handleListItemClick(merchant)}
        >
          <ListItemIcon>
            <LocationOnOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={merchant.lname} />
        </ListItem>
      ))}
    </List>
  );
};

ListMerchants.propTypes = {
  allMerchants: PropTypes.array,
};

export default ListMerchants;
