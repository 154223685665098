import React, { useState } from 'react'
import { isEmpty, find } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Button, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import FilePicker from './filePicker'
import FilePreview from './filePreview'

import useStyles from './styles'

const AddFiles = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { expense, onUpdateFiles } = props
  const attachments = expense?.attachments || []

  const [openFilePicker, setOpenFilePicker] = useState(false)
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [selectedAttachment, setSelectedAttachment] = useState(null)

  const onAddFile = (attachment) => {
    const newAttachments = [
      ...attachments,
      {
        ...attachment,
        flag: 'add'
      }
    ]
    onUpdateFiles(newAttachments)
  }

  const onRemoveFile = (attachment) => {
    const oldAttachment = find(attachments, { id: attachment.id })
    let newAttachments
    if (oldAttachment?.flag === 'add') {
      newAttachments = attachments.filter(item => item.id !== attachment.id)
    } else {
      newAttachments = attachments.map(item => item.id === attachment.id
        ? { ...item, flag: 'delete' }
        : item
      )
    }
    onUpdateFiles(newAttachments)
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.titleText}>
        {t('expenses.files')}
      </Typography>
      <Typography className={classes.labelText}>
        {t('expenses.upload_images_pdf')}
      </Typography>
      <Box>
        {attachments.map((attachment, id) => (
          <Box
            key={`attachment-${id}`}
            className={classes.attachmentContainer}
          >
            <Typography
              className={classes.fileNameText}
              onClick={() => {
                setOpenFilePreview(true)
                setSelectedAttachment(attachment)
              }}
            >
              {attachment.name}
            </Typography>
            <IconButton
              onClick={() => onRemoveFile(attachment)}
              className={classes.closeButton}
            >
              <CloseIcon style={{ fontSize: 14 }} />
            </IconButton>
          </Box>
        ))}
      </Box>
      <Box display='flex' justifyContent='center' marginTop={5}>
        <Button
          color='primary'
          onClick={() => setOpenFilePicker(true)}
          className={classes.addButton}
        >
          {isEmpty(attachments)
            ? t('expenses.upload_file')
            : t('expenses.upload_more')
          }
        </Button>
      </Box>

      {openFilePicker &&
        <FilePicker
          open={openFilePicker}
          handleClose={() => setOpenFilePicker(false)}
          onSuccess={onAddFile}
        />
      }

      {openFilePreview && selectedAttachment &&
        <FilePreview
          open={openFilePreview}
          handleClose={() => setOpenFilePreview(false)}
          attachement={selectedAttachment}
        />
      }
    </Box>
  )
}

export default AddFiles