import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: 70,
      padding: theme.spacing(.5, 0),
      cursor: 'pointer',
    },
    selectedRoot: {
      backgroundColor: Colors.GRAY_BORDER1,
    },
  })
)

export default useStyles
