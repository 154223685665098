import I18n from '@/i18n'
import moment from 'moment'
import { isEmpty } from 'lodash'

import { transformFloat } from '@/utils/localisationUtil'

const hasAccounts = (childrenType) => {
  return !isEmpty((childrenType.accounts || []).filter(account => account.display_in_report))
}

export const getCSVData = (store, reportTime, accountTypes, localisation) => {
  let data = [
    ["", "", "", I18n.t('workboard.run_reports.business_overview'), "", "", ""],
    [],
    ["", "", "", store.name, "", "", ""],
    [],
    ["", "", "", moment(reportTime.start).format('DD/MM/YYYY HH:mm:ss') + ' - ' + moment(reportTime.end).format('DD/MM/YYYY HH:mm:ss'), "", "", ""],
    [],
    []
  ]

  accountTypes.forEach(accountType => {
    data.push([
      accountType.name,
      "",
      "",
      "",
      "",
      "",
      transformFloat(accountType.performance.performance, localisation)
    ]);
    accountType.children_types
      .filter(childrenType => hasAccounts(childrenType))
      .forEach(childrenType => {
        data.push([
          childrenType.name,
          "",
          "",
          "",
          "",
          transformFloat(childrenType.performance.performance, localisation),
          ""
        ]);
        childrenType.accounts
          .filter(account => account.display_in_report)
          .forEach(account => {
            data.push([
              "",
              account.name,
              "",
              "",
              transformFloat(account.performance.performance, localisation),
              "",
              ""
            ]);
          })
      });
    data.push([])
  })

  return data
}