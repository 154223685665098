import React, { useEffect, useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useFormik } from 'formik';
import { Alert, AlertTitle } from '@material-ui/lab';
import Popper from '@material-ui/core/Popper';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

const SettingInput = ({
  name,
  label,
  value,
  onChange,
  validationSchema,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setopen] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lowerCaseTitle = label.toLowerCase();

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  //dispay popover with nickname description
  const onMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
    setopen(true);
  };

  //hide popover with nickname description
  const onMouseLeave = () => {
    setAnchorEl(null);
    setopen(false);
  };

  // const open = true;
  const id = open ? 'simple-popper' : undefined;

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    setFieldError,
    setFieldValue,
    touched,
    values,
    isValid,
  } = useFormik({
    initialValues: {
      [name]: value,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await onChange(values[name]);
        handleCloseEditModal();
      } catch (error) {
        console.log('🔥🔥 ~ onSubmit: ~ error', error);
        setFieldError(name, t('settings.error.update'));
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    setFieldValue(name, value)
  }, [value])
  
  return (
    <>
      <Box className={classes.box}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          {value ? (
            <Typography className={classes.storeTitle}>
              {value}
            </Typography>
          ) : (
            <Typography
              onClick={handleOpenEditModal}
              className={classes.addAction}
            >
              {t('settings.add')}
            </Typography>
          )}
          <IconButton
            aria-label={`edit-${lowerCaseTitle}`}
            className={classes.editIcon}
            onClick={handleOpenEditModal}
          >
            <CreateIcon fontSize='small' />
          </IconButton>
          <HelpOutlineIcon
            className={classes.nickname}
            onMouseEnter={(e) => onMouseEnter(e)}
            onMouseLeave={onMouseLeave}
          />
          {/* <span
            aria-describedby={id}
            onClick={handleClick}
            className={classes.nickname}
          >
            ({t('businessManager.nick_name')})
          </span> */}

          <div>
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              placement="right-end"
              style={{ maxWidth: 364 }}
            >
              <Alert icon={false} severity="info">
                <AlertTitle>
                  {t('businessManager.nickname')}
                </AlertTitle>
                {t('businessManager.nickname_description')}
              </Alert>
            </Popper>
          </div>
        </Box>
      </Box>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        onClose={isLoading ? () => {} : handleCloseEditModal}
        aria-labelledby={`{edit-${lowerCaseTitle}-dialog-title}`}
        open={openEditModal}
      >
        <DialogTitle
          id={`{edit-${lowerCaseTitle}-dialog-title}`}
          onClose={handleCloseEditModal}
        >
          {`Update ${lowerCaseTitle}`}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              id={name}
              name={name}
              fullWidth
              value={values[name]}
              onChange={handleChange}
              error={touched[name] && Boolean(errors[name])}
              helperText={touched[name] && errors[name]}
              autoFocus
              required
              onBlur={handleBlur}
              InputProps={{
                className: classes.input,
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={!isValid}
            onClick={handleSubmit}
            type="submit"
            color="primary"
          >
            {t('settings.save')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

SettingInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  validationSchema: PropTypes.object,
};

export default SettingInput;
