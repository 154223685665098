import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { isEmpty } from 'lodash'

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font
} from '@react-pdf/renderer';
import Montserrat from '../fonts/Montserrat-Light.ttf'
import MontserratBold from '../fonts/Montserrat-Medium.ttf'

import { transformFloat } from '@/utils/localisationUtil'

Font.register({
  family: "Montserrat",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    {
      src: Montserrat,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  root: {
    padding: 15
  },
  titleText: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: 'black'
  },
  storeNameText: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Montserrat',
    color: 'black'
  },
  accountTypeContainer: {
    marginTop: 20
  },
  childrenTypeContainer: {
    marginTop: 15,
  },
  accountWrapper: {
    marginTop: 10,
    paddingLeft: 15,
    paddingRight: 100
  },
  rowBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rowCenter: {
    flexDirection: 'row',
    justifyContent: 'center'
  }
});

const PDFDocument = (props) => {
  const { t } = useTranslation()

  const {
    store,
    reportTime,
    accountTypes,
    localisation
  } = props

  const hasAccounts = (childrenType) => {
    return !isEmpty((childrenType.accounts || []).filter(account => account.display_in_report))
  }

  return (
    <Document>
      <Page size='A4'>
        <View style={styles.root}>
          <View style={styles.rowCenter}>
            <Text style={styles.titleText}>
              {t('workboard.run_reports.business_overview')}
            </Text>
          </View>
          <View style={{ marginTop: 15, ...styles.rowCenter }}>
            <Text style={styles.storeNameText}>
              {store.name}
            </Text>
          </View>
          <View style={{ marginTop: 15, ...styles.rowCenter }}>
            <Text style={styles.storeNameText}>
              {moment(reportTime.start).format('DD/MM/YYYY HH:mm:ss') + ' - ' + moment(reportTime.end).format('DD/MM/YYYY HH:mm:ss')}
            </Text>
          </View>

          {accountTypes.map((accountType, id1) => (
            <View
              key={`account-type-${id1}`}
              style={styles.accountTypeContainer}
            >
              <View style={styles.rowBetween}>
                <Text style={styles.titleText}>{accountType.name}</Text>
                <Text style={styles.titleText}>
                  {transformFloat(accountType.performance.performance, localisation)}
                </Text>
              </View>
              {accountType.children_types
                .filter(childrenType => hasAccounts(childrenType))
                .map((childrenType, id2) => (
                  <View
                    key={`children-type-${id1}-${id2}`}
                    style={styles.childrenTypeContainer}
                  >
                    <View style={{...styles.rowBetween, paddingRight: 50}}>
                      <Text style={styles.storeNameText}>
                        {childrenType.name}
                      </Text>
                      <Text style={styles.storeNameText}>
                        {transformFloat(childrenType.performance.performance, localisation)}
                      </Text>
                    </View>
                    {childrenType.accounts
                      .filter(account => account.display_in_report)
                      .map((account, id3) => (
                        <View
                          key={`account-${id1}-${id2}-${id3}`}
                          style={{ ...styles.rowBetween, ...styles.accountWrapper }}
                        >
                          <Text style={styles.storeNameText}>
                            {account.name}
                          </Text>
                          <Text style={styles.storeNameText}>
                            {transformFloat(account.performance.performance, localisation)}
                          </Text>
                        </View>
                      ))}
                  </View>
                ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )
}

export default PDFDocument