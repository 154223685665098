import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import DayPicker from '@/components/dayPicker';
import { useTranslation } from 'react-i18next';

const ReportWeekCommencingDay = forwardRef(
  ({ value, onChange }, ref) => {
    const { t } = useTranslation();
    const dayPickerRef = useRef();

    useImperativeHandle(ref, () => ({
      handleOpenEditModal: () =>
        dayPickerRef?.current?.handleOpenEditModal(),
    }));

    return (
      <DayPicker
        ref={dayPickerRef}
        name="reportWeekCommencingDay"
        label={t('settings.reports.week_commencing_day')}
        value={value}
        onChange={onChange}
      />
    );
  }
);

export default ReportWeekCommencingDay;
