import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(7),
  },
  searchText: {
    ...CommonFonts.darkFont14,
    fontWeight: 500,
    textAlign: 'center'
  },
  groupSearchWrapper: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing(7),
  },
  addonWrapper: {
    padding: theme.spacing(3, 0),
    borderBottom: `1px solid #939da833`,
  },
  titleText: {
    ...CommonFonts.darkFont14,
  },
  removeButton: {
    ...CommonFonts.blueFont14,
    textTransform: 'none'
  },
  groupsContainer: {
    marginTop: theme.spacing(10),
  },
  groupsWrapper: {
    height: '30vh',
    overflow: 'overlay',
    paddingRight: theme.spacing(3)
  }
}));

export default useStyles;
