import { gql } from '@apollo/client'

export const GET_SHOPPERS = gql`
  query getShopper {
    shoppers {
      id
      email
      first_name
      last_name
      dob
      gender
      mobile
      orders {
        id
        order_no
        order_series
        is_shopper
        cashback
        shopper {
          id
          first_name
          last_name
          mobile
        }
        completion_time
        creation {
          time
          user_id
        }
        notes {
          id
          creation {
            time
            user_id
          }
          content
        }
        state
        order_type {
          id
          type
          name
        }
        status {
          id
          order
          name
        }
        table {
          table_id
          table_no
          no_of_guests
        }
        delivery {
          address {
            type
            door_number
            line1
            line2
            city_town
            postcode
            lng
            lat
          }
        }
        items {
          product_id
          bar_code
          category {
            id
            name
          }
          is_manual
          name
          notes {
            id
            creation {
              time
              user_id
            }
            content
          }
          price {
            quantity
            refunded
            rejected
            item_measure {
              id
              type
              name
            }
            amount
            gross_amount
            tax_amount
            total_gross_amount
            taxes {
              id
              rate
              name
              amount
            }
          }
          addons {
            id
            name
            addon_id
            group {
              id
              name
            }
            price {
              quantity
              amount
              gross_amount
              tax_amount
              total_gross_amount
              taxes {
                id
                rate
                name
                amount
              }
            }
          }
          promotions {
            id
            type
            name
            amount
            taxes {
              id
              rate
              name
              amount
            }
          }
        }
        refunded_items {
          product_id
          bar_code
          category {
            id
            name
          }
          is_manual
          name
          price {
            refunded
            quantity
            item_measure {
              id
              type
              name
            }
            amount
            gross_amount
            tax_amount
            total_gross_amount
            taxes {
              id
              rate
              name
              amount
            }
          }
          addons {
            id
            name
            addon_id
            group {
              id
              name
            }
            price {
              quantity
              amount
              gross_amount
              tax_amount
              total_gross_amount
              taxes {
                id
                rate
                name
                amount
              }
            }
          }
          promotions {
            id
            type
            name
            amount
            taxes {
              id
              rate
              name
              amount
            }
          }
        }
        rejected_items {
          product_id
          bar_code
          category {
            id
            name
          }
          is_manual
          name
          price {
            refunded
            quantity
            item_measure {
              id
              type
              name
            }
            amount
            gross_amount
            tax_amount
            total_gross_amount
            taxes {
              id
              rate
              name
              amount
            }
          }
          addons {
            id
            name
            addon_id
            group {
              id
              name
            }
            price {
              quantity
              amount
              gross_amount
              tax_amount
              total_gross_amount
              taxes {
                id
                rate
                name
                amount
              }
            }
          }
          promotions {
            id
            type
            name
            amount
            taxes {
              id
              rate
              name
              amount
            }
          }
        }
        total_price {
          amount
          tax_amount
          gross_amount
          taxes {
            id
            name
            rate
            amount
          }
        }
        payments {
          status
          id
          amount
          change_due
          creation {
            time
          }
          payment_type {
            id
            name
            type
          }
        }
      }
      outstanding
    }
  }
`
