import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Paper,
  Table,
  TableBody,
  Box,
  Button
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';

import RootRef from '@material-ui/core/RootRef';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';

import TableHeader from './tableHeader'
import TableRow from './tableRow'

import useStyles from './styles'

const TableComponent = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    taxStatus,
    items,
    accounts,
    taxes,
    onUpdateItem,
    onAddItems,
    onVoidItem,
    onDragEnd,
    onAddNewProduct
  } = props

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      backgroundColor: '#F3F5F7',
    }),
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <RootRef rootRef={provided.innerRef}>
            <Table>
              <TableHeader taxStatus={taxStatus} />
              <TableBody>
                {items
                  .sort((first, second) => first.order - second.order)
                  .map((item, index) => (
                    <Draggable
                      key={item.item_id}
                      draggableId={item.item_id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <TableRow
                          provided={provided}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          key={item.item_id}
                          taxStatus={taxStatus}
                          item={item}
                          taxes={taxes}
                          accounts={accounts}
                          onUpdateItem={onUpdateItem}
                          onVoidItem={onVoidItem}
                          onAddNewProduct={onAddNewProduct}
                        />
                      )}
                    </Draggable>
                  ))
                }
              </TableBody>
            </Table>
            <Box
              marginTop={10}
              display='flex'
            >
              <Button
                color="primary"
                className={classes.addButton}
                onClick={() => onAddItems(1)}
              >
                {t('expenses.add_line')}
              </Button>
              <Button
                color="primary"
                className={classes.addButton}
                onClick={() => onAddItems(5)}
              >
                {t('expenses.add_5_lines')}
              </Button>
              <Button
                color="primary"
                className={classes.addButton}
                onClick={() => onAddItems(10)}
              >
                {t('expenses.add_10_lines')}
              </Button>
            </Box>
          </RootRef>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default TableComponent