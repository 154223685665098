import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import storeConfig from './storeConfig';

import {
  reducer as apiLoopStateReducer
} from './modules/apiLoopState';

import {
  reducer as authReducer,
  saga as authSaga,
} from './modules/auth';

import {
  reducer as storeReducer,
  saga as storeSaga
} from './modules/store';

import {
  reducer as businessManagerReducer
} from './modules/businessManager';

import {
  reducer as tutorialReducer
} from './modules/tutorial';

export default () => {
  const appReducer = combineReducers({
    auth: authReducer,
    apiLoopState: apiLoopStateReducer,
    store: storeReducer,
    businessManager: businessManagerReducer,
    tutorial: tutorialReducer,
  });

  return storeConfig(appReducer, function* rootSaga() {
    yield all([authSaga(), storeSaga()]);
  });
};
