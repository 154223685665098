import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    addCard: {
      marginTop: theme.spacing(5),
      padding: theme.spacing(3, 0),
    },
    title: {
      ...CommonFonts.darkFont18,
      fontWeight: '600',
    },
  })
);

export default useStyles;
