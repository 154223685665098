import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { find, groupBy, isEmpty } from 'lodash';
import { FieldArray } from 'formik';

import {
  Box,
  Typography,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Selector from '../components/selector';
import Input from '../components/input';
import SingleCheckboxInput from '@/components/singleCheckbox';
import ProductSelector from '../components/productSelector';

import {
  selectNetPrice,
  selectLocalisation,
} from '@/store/modules/store/selectors';

import { MEASURES } from '@/constants/measure';
import { capitalizeFirstLetter } from '@/utils/stringUtil';
import { calcPriceFromGrossPrice } from '@/utils/priceUtil';
import { getTaxName } from '@/utils/taxUtil';
import {
  getLocalisationVal,
  transformInput,
} from '@/utils/localisationUtil';
import useStyles from './styles';

const BasicInformation = (props) => {
  const {
    merchantSettings,
    productData,
    taxes,
    categories,
    measures,
    variants,
    updateProductData,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    barcodeErrorText,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const netPrice = useSelector(selectNetPrice);
  const localisation = useSelector(selectLocalisation);

  const groupedTaxes = groupBy(taxes, 'name');

  useEffect(() => {
    setFieldValue('price_infos', productData.price_infos);
  }, [productData.price_infos]);

  useEffect(() => {
    setFieldValue('bar_code', productData.bar_code);
  }, [productData.bar_code]);

  useEffect(() => {
    setFieldValue('product_name', productData.name);
  }, [productData.name]);

  useEffect(() => {
    setFieldValue('category', productData.category);
  }, [productData.category]);

  useEffect(() => {
    setFieldValue('multi_pack', productData.multi_pack);
  }, [productData.multi_pack]);

  const onChangePrice = (grossPrice, priceIndex) => {
    const newPriceInfos = productData.price_infos.map(
      (priceInfo, index) => {
        if (index !== priceIndex) {
          return priceInfo;
        } else {
          return {
            ...priceInfo,
            grossPrice,
            price: netPrice
              ? parseFloat(grossPrice)
              : calcPriceFromGrossPrice(grossPrice, priceInfo.taxes),
          };
        }
      }
    );
    updateProductData('price_infos', newPriceInfos);
  };

  const onChangeTax = (tax, priceIndex) => {
    if (!productData.price_infos[priceIndex].grossPrice) return;

    let taxes = productData.price_infos[priceIndex].taxes;
    if (find(taxes, { name: tax.name })) {
      taxes = taxes.map((item) =>
        item.name === tax.name ? tax : item
      );
    } else {
      taxes = [...taxes, tax];
    }

    const newPriceInfos = productData.price_infos.map(
      (priceInfo, index) => {
        if (index !== priceIndex) {
          return priceInfo;
        } else {
          return {
            ...priceInfo,
            taxes,
            price: netPrice
              ? priceInfo.price
              : calcPriceFromGrossPrice(priceInfo.grossPrice, taxes),
          };
        }
      }
    );
    updateProductData('price_infos', newPriceInfos);
  };

  return (
    <Box>
      <Box className={classes.boldLabelContainer}>
        <Typography className={classes.boldLabel}>
          {t('add_product.measures')}
        </Typography>
      </Box>

      <RadioGroup
        className={classes.measuresGroup}
        aria-label="role-group"
        name="role-group"
        onChange={(e) => {
          updateProductData(
            'measure',
            find(measures, { id: e.target.value })
          );
        }}
      >
        {measures.map((item, index) => (
          <FormControlLabel
            style={{ marginRight: 40 }}
            key={`${item.id}-${index}`}
            control={
              <CustomRadio
                checked={item.id === productData.measure?.id}
                value={item.id}
                name="radio-button-role"
              />
            }
            label={t(`measures.${item.type}`)}
          />
        ))}
      </RadioGroup>

      <Box className={classes.boldLabelContainer}>
        <Typography className={classes.boldLabel}>
          {t('add_product.general_information')}
        </Typography>
      </Box>

      <Grid container spacing={10}>
        <Grid item xs={12} sm={6} md={4}>
          <Input
            name="bar_code"
            title={t('add_product.barcode')}
            emptyLabel={t('add_product.add')}
            value={productData.bar_code}
            onChange={(value) => {
              updateProductData('bar_code', value);
            }}
            onBlur={handleBlur}
            error={touched.bar_code && !!errors.bar_code}
            helperText={touched.bar_code && errors.bar_code}
          />
          {!!barcodeErrorText && (
            <FormHelperText error>{barcodeErrorText}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Input
            name="product_name"
            title={t('add_product.product_name')}
            emptyLabel={t('add_product.add')}
            value={productData.name}
            onChange={(value) => {
              updateProductData('name', value);
            }}
            onBlur={handleBlur}
            error={touched.product_name && !!errors.product_name}
            helperText={touched.product_name && errors.product_name}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Selector
            name="category"
            title={t('add_product.category')}
            emptyLabel={t('add_product.select')}
            items={categories}
            getItemLabel={(item) => item.name}
            selectedItem={productData.category || {}}
            onChangeValue={(value) => {
              updateProductData('category', value);
            }}
            onBlur={handleBlur}
            error={touched.category && !!errors.category}
            helperText={errors.category}
          />
        </Grid>
      </Grid>
      {!!merchantSettings?.products?.style && (
        <Grid container spacing={10}>
          <Grid item xs={12} sm={6} md={4}>
            <Selector
              title={t('add_product.style_variant')}
              emptyLabel={t('add_product.select')}
              items={variants}
              getItemLabel={(item) => item.name}
              selectedItem={productData.variant || {}}
              onChangeValue={(value) => {
                updateProductData('variant', value);
              }}
              onBlur={() => {}}
            />
          </Grid>
        </Grid>
      )}

      <Box className={classes.boldLabelContainer}>
        <Typography className={classes.boldLabel}>
          {t('add_product.pack')}
        </Typography>
      </Box>

      <Grid container spacing={10}>
        <Grid item xs={12} sm={6} md={3}>
          <SingleCheckboxInput
            style={{ paddingBottom: 0 }}
            isShowToast={false}
            value={productData.multi_pack.checked}
            label={t('add_product.multi_pack')}
            onChange={(value) => {
              if (productData.weighed_item.checked) return;
              updateProductData('multi_pack', {
                ...productData.multi_pack,
                checked: value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {productData.multi_pack.checked && (
            <Input
              type="number"
              name="multi_pack.inner_items_num"
              title={t('add_product.inner_item_num')}
              emptyLabel={t('add_product.add')}
              value={productData.multi_pack.inner_items_num}
              onChange={(value) => {
                updateProductData('multi_pack', {
                  ...productData.multi_pack,
                  inner_items_num: value,
                });
              }}
              onBlur={handleBlur}
              error={
                touched.multi_pack &&
                touched.multi_pack.inner_items_num &&
                !!errors.multi_pack &&
                !!errors.multi_pack.inner_items_num
              }
              helperText={
                touched.multi_pack &&
                touched.multi_pack.inner_items_num &&
                errors.multi_pack &&
                errors.multi_pack.inner_items_num
              }
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {productData.multi_pack.checked && (
            <ProductSelector
              name="multi_pack.inner_item"
              product={productData.multi_pack.inner_item}
              onSelectProduct={(product) => {
                updateProductData('multi_pack', {
                  ...productData.multi_pack,
                  inner_item: product,
                });
              }}
              onRemoveProduct={() => {
                updateProductData('multi_pack', {
                  ...productData.multi_pack,
                  inner_item: null,
                });
              }}
              onBlur={handleBlur}
              error={
                touched.multi_pack &&
                touched.multi_pack.inner_item &&
                !!errors.multi_pack &&
                !!errors.multi_pack.inner_item
              }
              helperText={
                touched.multi_pack &&
                touched.multi_pack.inner_item &&
                errors.multi_pack &&
                errors.multi_pack.inner_item
              }
            />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={10}>
        <Grid item xs={12} sm={6} md={3}>
          <SingleCheckboxInput
            style={{ paddingBottom: 0 }}
            isShowToast={false}
            value={productData.weighed_item.checked}
            label={t('add_product.weighed_item')}
            onChange={(value) => {
              if (productData.multi_pack.checked) return;
              updateProductData('weighed_item', {
                ...productData.weighed_item,
                checked: value,
              });
            }}
          />
        </Grid>
      </Grid>

      <Box className={classes.boldLabelContainer}>
        <Box display="flex" alignItems="center">
          <Typography className={classes.boldLabel}>
            {t('add_product.price')}
            {productData.measure &&
              (productData.measure.type === 'qty'
                ? ' / unit'
                : ` / ${capitalizeFirstLetter(
                    productData.measure.type
                  )}`)}
          </Typography>
        </Box>
      </Box>
      <FieldArray
        name="price_infos"
        render={() =>
          productData.price_infos.map((price_info, index) => (
            <Grid
              key={`price_info_${index}`}
              container
              className={classes.priceInfoContainer}
              spacing={10}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Input
                  isCurrencyInput={localisation.currency_decimal}
                  localisation={localisation}
                  name={`price_infos.${index}.price`}
                  title={`${capitalizeFirstLetter(
                    price_info.price_type.name
                  )} ${t('add_product.price')}`}
                  emptyLabel={t('add_product.add')}
                  value={
                    netPrice
                      ? price_info.price
                        ? getLocalisationVal(
                            localisation,
                            price_info.price
                          )
                        : price_info.price
                      : price_info.grossPrice
                      ? getLocalisationVal(
                          localisation,
                          price_info.grossPrice
                        )
                      : price_info.grossPrice
                  }
                  onChange={(value) => {
                    onChangePrice(
                      transformInput(localisation, value),
                      index
                    );
                  }}
                  onBlur={handleBlur}
                  error={
                    touched.price_infos &&
                    touched.price_infos[index] &&
                    touched.price_infos[index].price &&
                    !!errors.price_infos &&
                    !!errors.price_infos[index] &&
                    !!errors.price_infos[index].price
                  }
                  helperText={
                    capitalizeFirstLetter(
                      price_info.price_type.name
                    ) +
                    ' ' +
                    errors?.price_infos?.[index]?.price
                  }
                />
              </Grid>
              {Object.keys(groupedTaxes).map((taxName) => (
                <Grid
                  key={`${index}_${taxName}`}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Selector
                    name={`price_infos.${index}.taxes.${taxName}`}
                    title={taxName}
                    emptyLabel={t('add_product.select')}
                    items={groupedTaxes[taxName]}
                    getItemLabel={(tax) => getTaxName(tax)}
                    selectedItem={
                      find(price_info.taxes, { name: taxName }) || {}
                    }
                    onChangeValue={(tax) => {
                      onChangeTax(tax, index);
                    }}
                    onBlur={() =>
                      setFieldTouched(
                        `price_infos.${index}.taxes.${taxName}`
                      )
                    }
                    error={
                      touched.price_infos &&
                      touched.price_infos[index] &&
                      touched.price_infos[index].taxes &&
                      touched.price_infos[index].taxes[taxName] &&
                      isEmpty(price_info.taxes)
                    }
                    helperText={t('add_product.tax_required')}
                  />
                </Grid>
              ))}
            </Grid>
          ))
        }
      />
    </Box>
  );
};
BasicInformation.propTypes = {
  productData: PropTypes.object,
  taxes: PropTypes.array,
  categories: PropTypes.array,
  measures: PropTypes.array,
  updateProductData: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  barcodeErrorText: PropTypes.string,
};

export default BasicInformation;

const CustomRadio = withStyles({
  root: {
    color: '#939DA8',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
