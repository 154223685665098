import { useQuery } from '@apollo/client'
import { GET_STORE_PAYMENT_TYPES } from '@/services/storeService'
import { useState } from 'react'

export default function useStorePaymentTypes() {
  const [paymentTypes, setPaymentTypes] = useState([])

  useQuery(GET_STORE_PAYMENT_TYPES, {
    onCompleted: store => {
      if (store.store.payment_types) {
        const paymentTypes = store.store.payment_types?.filter(el => el.type !== 'account') || []
        setPaymentTypes(paymentTypes)
      }
    },
    onError: () => {
      setPaymentTypes([])
    },
    fetchPolicy: 'cache-and-network',
  })

  return paymentTypes
}
