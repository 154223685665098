import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors, CommonFonts } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    titleText: {
      ...CommonFonts.darkFont14,
    },
    errorTitleText: {
      color: Colors.RED
    },
    labelContainer: {
      position: 'relative',
    },
    emptyLabel: {
      ...CommonFonts.blueFont18,
      padding: theme.spacing(1, 0),
      // cursor: 'pointer',
      borderBottom: '1px solid rgba(0, 0, 0, 0)',
    },
    label: {
      ...CommonFonts.darkFont18,
      padding: theme.spacing(1, 0),
      // cursor: 'pointer',
      borderBottom: '1px solid rgba(0, 0, 0, 0)',
    },
    iconButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      padding: theme.spacing(1),
    },
    textField: {
      '& .MuiInput-underline:before': {
        borderBottomWidth: 0,
      },
    },
    currencyInput: {
      borderBottomWidth: 0,
    },
  })
)

export default useStyles
