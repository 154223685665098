import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    boxLink: {
      padding: theme.spacing(4),
    },
    boxLinkActive: {
      padding: theme.spacing(4),
      backgroundColor: '#e7f1fe',
      fontWeight: '600',
    },
    breadcrumbsText: {
      fontSize: 16,
    },
    linkText: {
      ...CommonFonts.darkFont18,
    },
    boxModules: {
      backgroundColor: Colors.WHITE,
      padding: theme.spacing(5),
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(7),
    },
    boxTable: {
      marginTop: theme.spacing(7),
    },
    title: {
      ...CommonFonts.darkFont16,
      fontWeight: '600',
      marginTop: theme.spacing(2),
    },
    tableText: {
      ...CommonFonts.darkFont18,
    },
    noDataFound: {
      ...CommonFonts.darkFont16,
    },
    headerTableText: {
      ...CommonFonts.darkFont14,
    },
    boxFullName: {
      wordBreak: 'break-word',
      padding: theme.spacing(2.5, 0),
    },
    actionLink: {
      color: '#1174F2',
      ...CommonFonts.blueFont18,
      textAlign: 'center',
      opacity: 0.5
    },
    addButton: {
      padding: theme.spacing(2.5, 0),
      background: '#1174F2 0% 0% no-repeat padding-box',
      textTransform: 'Uppercase',
      ...CommonFonts.whiteFont14,
      width: 150
    },
  })
);

export default useStyles;
