import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useLazyQuery, useMutation } from '@apollo/client';
import Hidden from '@material-ui/core/Hidden';
import {
  GET_PRODUCTS,
  DELETE_PRODUCT,
} from '@/services/inventoryService';
import images from '@/assets/images';
import { Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';

import InfiniteScroll from 'react-infinite-scroll-component';
import {
  selectLocalisation,
  selectNetPrice,
  selectSelectedStore,
  selectMerchantSettings,
} from '@/store/modules/store/selectors';
import {
  getLocalisationVal,
  getCurrencySymbol,
} from '@/utils/localisationUtil';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styles';
// eslint-disable-next-line import/no-cycle
import NoResults from './components/NoResults';
import ActionMenu from './components/ActionMenu';
import AddUpdateProduct from './AddUpdateProduct';
import PreLoader from '@/components/preLoader';
import { Colors } from '@/theme';
import moment from 'moment';

// sort tax columns by id
const sortArrayById = (array) =>
  array &&
  array
    .slice()
    .sort((a, b) => (a?.price_type?.id > b?.price_type?.id ? 1 : -1));

const GreenCheckbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function ProductsList(props) {
  const { category, inputSearch } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const localisation = useSelector(selectLocalisation);
  const netPrice = useSelector(selectNetPrice);
  const selectedStore = useSelector(selectSelectedStore);
  const merchantSettings = useSelector(selectMerchantSettings);

  const [filteredProducts, setFilteredProducts] = useState([]);

  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);

  const [openProductModal, setOpenProductModal] = useState(false);
  const [tabIdForProductUpdate, setTabIdForProductUpdate] =
    useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [dynamicCols, setdynamicCols] = useState([]);
  const [dynamicColsLength, setdynamicColsLength] = useState(0);

  const [isAllProductsLoaded, setIsAllProductsLoaded] =
    useState(false);

  // get products from api
  const [getProducts, { loading }] = useLazyQuery(GET_PRODUCTS, {
    onCompleted: (data) => {
      // sort dynamic columns by id so they have the same order always
      const dynamicColumns =
        sortArrayById(data.products[0]?.prices[0]?.price_infos) || [];
      setdynamicCols(dynamicColumns);
      setdynamicColsLength(dynamicColumns.length || 0);

      // if response give us less then 10 results, set all product loaded
      if (data.products.length < 10) {
        setIsAllProductsLoaded(true);
      } else {
        setIsAllProductsLoaded(false);
      }

      // update the array holding products
      setFilteredProducts([...filteredProducts, ...data.products]);
      setAllProducts([...allProducts, ...data.products]);
    },
    onError: () => {
      // setIsAllProductsLoaded(true);
    },
    fetchPolicy: 'no-cache',
  });

  // delete product
  const [deleteProductMutation, { loading: isDeletingProduct }] =
    useMutation(DELETE_PRODUCT, {
      onCompleted: () => {
        setFilteredProducts(
          filteredProducts.filter(
            (product) => product.id !== selectedProduct?.id
          )
        );
        enqueueSnackbar(
          `${selectedProduct.name} ${t('categories.deleted')}`
        );
      },
      onError: (error) => {
        console.log('Delete product error', error);
      },
    });

  // get products from API
  useEffect(() => {
    getProducts({
      variables: {
        filter: {
          count: true,
          page: currentPage,
          limit: 10,
          name: `%${inputSearch.toLowerCase()}%`,
          category_id: category.id,
        },
      },
    });
  }, [currentPage, inputSearch]);

  // render the text for Taxes column
  const renderTax = (taxes) => {
    let fullText;
    let { price } = taxes;

    if (taxes.taxes.length > 0) {
      fullText = taxes.taxes
        .map((tax) => {
          if (tax.rate < 0) {
            return 'VAT Exempt';
          }
          if (!netPrice) {
            price = (tax.rate / 100) * price + price;
          }
          return `${tax.rate}% ${tax.name} `;
        })
        .join(', ');
    }

    return `${getLocalisationVal(localisation, price)} / ${fullText}`;
  };

  // change current page when user reaches bottom
  const handleLoadMore = () => {
    if (isEmpty(filteredProducts)) return;
    setcurrentPage(currentPage + 1);
  };

  useEffect(() => {
    setFilteredProducts([]);
    setAllProducts([]);
    setcurrentPage(1);
  }, [inputSearch, category]);

  const onSuccessUpdate = (updatedProduct) => {
    setFilteredProducts(
      filteredProducts.map((product) =>
        product.id === updatedProduct?.id ? updatedProduct : product
      )
    );
  };

  const productActionMenus = [
    {
      name: t('categories.update_general_info'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        setSelectedProduct(data);
        setTabIdForProductUpdate(0);
        setOpenProductModal(true);
      },
      enabled: true,
    },
    {
      name: t('categories.update_stock_info'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        setSelectedProduct(data);
        setTabIdForProductUpdate(1);
        setOpenProductModal(true);
      },
      enabled: true,
    },
    {
      name: t('categories.update_image_description'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        setSelectedProduct(data);
        setTabIdForProductUpdate(2);
        setOpenProductModal(true);
      },
      enabled: true,
    },
    {
      name: t('categories.update_allergy'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        setSelectedProduct(data);
        setTabIdForProductUpdate(3);
        setOpenProductModal(true);
      },
      enabled: !!merchantSettings?.products?.allergy,
    },
    {
      name: t('categories.update_addons'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        let startIndex = 3;
        if (!!merchantSettings?.products?.allergy) startIndex++;
        setSelectedProduct(data);
        setTabIdForProductUpdate(startIndex);
        setOpenProductModal(true);
      },
      enabled: !!merchantSettings?.products?.addon,
    },
    {
      name: t('categories.update_product_builder'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        let startIndex = 3;
        if (!!merchantSettings?.products?.allergy) startIndex++;
        if (!!merchantSettings?.products?.addon) startIndex++;
        setSelectedProduct(data);
        setTabIdForProductUpdate(startIndex);
        setOpenProductModal(true);
      },
      enabled: !!merchantSettings?.products?.recipe,
    },
    {
      name: t('categories.delete'),
      color: Colors.RED,
      onClick: (data) => {
        setSelectedProduct(data);
        deleteProductMutation({
          variables: {
            input: {
              ids: [data.id],
              store_id: selectedStore.id,
            },
          },
        });
      },
      enabled: true,
    },
  ];

  return (
    <div className={classes.productsRoot}>
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Paper className={classes.emptyProductsContainer}>
            {!isEmpty(filteredProducts) || loading ? (
              <InfiniteScroll
                dataLength={allProducts.length}
                next={handleLoadMore}
                hasMore={!isAllProductsLoaded}
                loader={
                  loading &&
                  currentPage !== 1 && (
                    <div className={classes.root}>
                      <Skeleton height={80} />
                      <Skeleton height={80} />
                      <Skeleton height={80} />
                    </div>
                  )
                }
              >
                <List className={classes.root}>
                  {loading && currentPage === 1 ? (
                    <div className={classes.root}>
                      <Skeleton height={80} />
                      <Skeleton height={80} />
                      <Skeleton height={80} />
                    </div>
                  ) : (
                    <>
                      <Hidden only="xs">
                        <ListItem className={classes.listHeader}>
                          <Grid container>
                            <Grid item xs={11}>
                              <Grid
                                container
                                justify={
                                  dynamicColsLength < 3
                                    ? 'space-between'
                                    : 'flex-start'
                                }
                                alignItems="center"
                                alignContent="center"
                              >
                                <Grid item xs={3}>
                                  <Grid
                                    container
                                    justify="flex-start"
                                    spacing={10}
                                    alignItems="center"
                                  >
                                    <Grid item xs={2}>
                                      <GreenCheckbox
                                        name="checkedB"
                                        color="primary"
                                      />
                                    </Grid>
                                    <Grid item>
                                      {t('categories.product')}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Hidden smDown>
                                  <Grid item xs={2}>
                                    {t('categories.ean_upc_mpc')}
                                  </Grid>
                                </Hidden>
                                <Grid item xs={1}>
                                  {t('categories.current_stock')}
                                </Grid>
                                {dynamicCols &&
                                  dynamicCols.length > 0 &&
                                  dynamicCols.map((col, index) => (
                                    <Grid
                                      key={`${col.price_type.name}-${index}`}
                                      item
                                      xs={2}
                                    >
                                      {`${col.price_type.name} ${t(
                                        'categories.price_tax_inc'
                                      )}`}
                                    </Grid>
                                  ))}
                              </Grid>
                            </Grid>
                            <Grid item xs={1} />
                          </Grid>
                        </ListItem>
                      </Hidden>

                      {filteredProducts.length > 0 &&
                        filteredProducts.map((item) => (
                          <ListItem
                            key={item.id}
                            button
                            className={classes.listItem}
                          >
                            <Grid container>
                              <Grid
                                item
                                xs={11}
                                onClick={() => {
                                  setSelectedProduct(item);
                                  setTabIdForProductUpdate(0);
                                  setOpenProductModal(true);
                                }}
                              >
                                <Grid
                                  container
                                  justify={
                                    dynamicColsLength < 3
                                      ? 'space-between'
                                      : 'flex-start'
                                  }
                                  alignItems="center"
                                  alignContent="center"
                                >
                                  <Grid item xs={3}>
                                    <Grid
                                      container
                                      justify="flex-start"
                                      spacing={10}
                                      alignItems="center"
                                    >
                                      <Grid item xs={2}>
                                        <GreenCheckbox
                                          name="checkedB"
                                          color="primary"
                                        />
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Grid
                                          container
                                          justify="flex-start"
                                          spacing={8}
                                          alignItems="center"
                                          wrap="nowrap"
                                        >
                                          <Grid item xs={3}>
                                            <Avatar
                                              src={
                                                item?.images?.sort(
                                                  (a, b) =>
                                                    moment(
                                                      a?.created_at
                                                    ) -
                                                    moment(
                                                      b?.created_at
                                                    )
                                                )[0]?.thumbnail
                                                  ? item?.images?.sort(
                                                    (a, b) =>
                                                      moment(
                                                        a?.created_at
                                                      ) -
                                                      moment(
                                                        b?.created_at
                                                      )
                                                  )[0]?.thumbnail
                                                  : images.noImage
                                              }
                                              variant="square"
                                              className={
                                                classes.avatarSize
                                              }
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={9}
                                            zeroMinWidth
                                          >
                                            <Typography noWrap>
                                              {item.name}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Hidden smDown>
                                    <Grid item xs={2}>
                                      <Typography noWrap>
                                        {item.bar_code}
                                      </Typography>
                                    </Grid>
                                  </Hidden>
                                  <Grid item xs={1}>
                                    <Typography noWrap>
                                      {!!item.stocked
                                        ? getLocalisationVal({ ...localisation, currency_decimal: false }, +item?.stocks?.[0]?.current_stock)
                                        : t(
                                          'categories.made_to_order'
                                        )}
                                    </Typography>
                                  </Grid>

                                  {sortArrayById(
                                    item?.prices[0].price_infos
                                  ).map((taxInfo, index) => (
                                    <Grid
                                      key={`${taxInfo.price}-${index}`}
                                      item
                                      xs={2}
                                    >
                                      <span style={{ fontSize: 12 }}>
                                        {getCurrencySymbol(
                                          localisation
                                        )}
                                      </span>
                                      {renderTax(taxInfo)}
                                    </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                className={classes.dotsIcon}
                              >
                                <ActionMenu
                                  data={item}
                                  actionMenus={productActionMenus.filter(
                                    (actionMenu) => actionMenu.enabled
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </ListItem>
                        ))}
                    </>
                  )}
                </List>
              </InfiniteScroll>
            ) : (
              <NoResults
                showAddProduct={!inputSearch}
                title={
                  inputSearch
                    ? t('categories.no_matching_products_found')
                    : t('categories.no_products_found')
                }
                onAddProduct={() => setOpenProductModal(true)}
              />
            )}
          </Paper>
        </Grid>
      </Grid>

      {isDeletingProduct && <PreLoader size={40} />}

      {openProductModal && (
        <AddUpdateProduct
          open={openProductModal}
          handleClose={() => setOpenProductModal(false)}
          isUpdate={!isEmpty(allProducts)}
          product={selectedProduct}
          defaultCategory={isEmpty(allProducts) && category}
          defaultTabId={tabIdForProductUpdate}
          onSuccess={onSuccessUpdate}
        />
      )}
    </div>
  );
}

const ProductsListWrapper = (props) => {
  return (
    <div>
      <ProductsList {...props} />
    </div>
  );
};

export default ProductsListWrapper;
