import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'

import { Typography, Box, Select, MenuItem } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { selectCurrentMerchant } from '@/store/modules/store/selectors'

import useStyles from './styles'

const SelectCompareStore = ({ selectedStoreId, compareStore, onChangeCompareStore }) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const currentMerchant = useSelector(selectCurrentMerchant)
  const stores = currentMerchant.stores

  const groupedByRegion = _.groupBy(
    stores,
    (store) => store?.region?.id
  );

  const menuItems = useMemo(() => {
    let items = [{
      id: '-1',
      name: t('header.all_stores'),
      hasPaddingLeft: false
    }]
    Object.keys(groupedByRegion).forEach(regionId => {
      if (regionId !== 'undefined') {
        items.push({
          id: regionId,
          name: groupedByRegion[regionId][0]?.region?.region_name,
          hasPaddingLeft: false
        })
      }
      groupedByRegion[regionId].forEach(store => {
        items.push({
          id: store.id,
          name: store.name,
          hasPaddingLeft: regionId !== 'undefined'
        })
      })
    })
    return items
  }, [currentMerchant])

  return (
    <Box>
      <Typography className={classes.titleText}>
        {t('workboard.performance_overview.compare')}
      </Typography>

      <Box className={classes.selectContainer}>
        <Select
          disableUnderline
          IconComponent={ExpandMoreIcon}
          displayEmpty
          renderValue={() => compareStore?.name || t('workboard.performance_overview.select_store')}
          fullWidth
          className={classes.filterInput}
          value={compareStore?.id || ''}
          onChange={(event) => onChangeCompareStore(find(menuItems, { id: event.target.value }))}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {menuItems.filter(item => item.id !== selectedStoreId).map(menuItem => (
            <MenuItem key={menuItem.id} value={menuItem.id}>
              {menuItem.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  )
}

export default SelectCompareStore