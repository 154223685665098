import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { find } from 'lodash'

import {
  Box,
  Typography,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

import Selector from '../../components/selector'
import Input from '../../components/input'
import useStyles from './styles'

const MainLayout = (props) => {
  const {
    ingredientData,
    categories,
    measures,
    updateIngredientData,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    barcodeErrorText
  } = props

  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    setFieldValue('bar_code', ingredientData.bar_code)
  }, [ingredientData.bar_code])

  useEffect(() => {
    setFieldValue('name', ingredientData.name)
  }, [ingredientData.name])

  return (
    <Box>
      <Box className={classes.boldLabelContainer}>
        <Typography className={classes.boldLabel}>
          {t('add_product.measures')}
        </Typography>
      </Box>

      <RadioGroup
        className={classes.measuresGroup}
        aria-label="role-group"
        name="role-group"
        onChange={(e) => {
          updateIngredientData('measure', find(measures, { id: e.target.value }))
        }}
      >
        {measures.map((item, index) => (
          <FormControlLabel
            style={{ marginRight: 40 }}
            key={`${item.id}-${index}`}
            control={
              <CustomRadio
                checked={item.id === ingredientData.measure?.id}
                value={item.id}
                name="radio-button-role"
              />
            }
            label={t(`measures.${item.type}`)}
          />
        ))}
      </RadioGroup>

      <Box className={classes.boldLabelContainer}>
        <Typography className={classes.boldLabel}>
          {t('add_product.general_information')}
        </Typography>
      </Box>

      <Grid container spacing={10}>
        <Grid item xs={12} sm={6} md={4}>
          <Input
            name='bar_code'
            title={t('add_product.barcode')}
            emptyLabel={t('add_product.add')}
            value={ingredientData.bar_code}
            onChange={(value) => {
              updateIngredientData('bar_code', value)
            }}
            onBlur={handleBlur}
            error={touched.bar_code && !!errors.bar_code}
            helperText={touched.bar_code && errors.bar_code}
          />
          {!!barcodeErrorText &&
            <FormHelperText error>
              {barcodeErrorText}
            </FormHelperText>
          }
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Input
            name='name'
            title={t('ingredients.ingredient_name')}
            emptyLabel={t('add_product.add')}
            value={ingredientData.name}
            onChange={(value) => {
              updateIngredientData('name', value)
            }}
            onBlur={handleBlur}
            error={touched.name && !!errors.name}
            helperText={touched.name && errors.name}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4}>
          <Selector
            name='category'
            title={t('add_product.category')}
            emptyLabel={t('add_product.select')}
            items={categories}
            getItemLabel={(item) => item.name}
            selectedItem={ingredientData.category || {}}
            onChangeValue={(value) => {
              updateIngredientData('category', value)
            }}
            onBlur={handleBlur}
          />
        </Grid> */}
      </Grid>
    </Box>
  )
}
MainLayout.propTypes = {
  ingredientData: PropTypes.object,
  categories: PropTypes.array,
  measures: PropTypes.array,
  updateIngredientData: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func,
  barcodeErrorText: PropTypes.string
}

export default MainLayout

const CustomRadio = withStyles({
  root: {
    color: '#939DA8',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
