import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Typography, Grid } from '@material-ui/core'
import Button from '@/components/button'

import useStyles from '../styles'

const Success = ({ onLogin }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box>
      <Typography className={classes.changePasswordSuccessText}>
        {t('reset_password_page.change_password_success')}
      </Typography>

      <Grid container justify="center" className={classes.loginButtonWrapper}>
        <Button
          className={classes.button}
          variant='contained'
          type="submit"
          color="primary"
          onClick={onLogin}
        >
          {t('button_group.login')}
        </Button>
      </Grid>
    </Box>
  )
}

export default Success
