import {
  Box,
  Breadcrumbs,
  Link,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Paper,
  withStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { Link as RouterLink } from 'react-router-dom';
import AppLayout from '@/components/appLayout';
import Input from './components/input'
import Button from '@/components/button';
import AddNewStore from './addNewStore'

import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { UPDATE_STORE } from '@/services/storeService';
import * as Yup from 'yup';

import MerchantActions from '@/store/modules/store/actions';
import AuthActions from '@/store/modules/auth/actions';
import { selectCurrentMerchant } from '@/store/modules/store/selectors'
import { getFullAddressOfStore } from '@/utils/stringUtil'
import { useSnackbar } from 'notistack';

const StoresPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const nickNameSchema = Yup.object().shape({
    name: Yup.string().required(
      t('stores.nickname_required')
    ),
  });

  const dispatch = useDispatch()
  const currentMerchant = useSelector(selectCurrentMerchant)
  const stores = currentMerchant.stores?.filter(store => store.type === 'store')

  const [openAddStoreModal, setOpenAddStoreModal] = useState(false)

  const updateStoreDetail = (store, key, value) => {
    const payload = {
      variables: {
        store: {
          id: store?.id,
          [key]: value,
        },
      },
    };

    return updateStore(payload);
  };

  const [updateStore] = useMutation(
    gql`
      ${UPDATE_STORE}
    `,
    {
      onCompleted: (response) => {
        dispatch(
          MerchantActions.updateSelectedStore(response.updateStore)
        );
        dispatch(AuthActions.updateStore(response.updateStore));
      },
    }
  );

  return (
    <AppLayout className={classes.appLayout} withFooter header>
      <Box marginY={10}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className={classes.breadcrumbsText}
            color="primary"
            component={RouterLink}
            to="/business-manager/store-profile"
          >
            {t('userManagement.users.business_manager')}
          </Link>
          <Link
            className={classes.breadcrumbsText}
            color="textPrimary"
            component={RouterLink}
            to="/stores"
          >
            {t('menu.stores')}
          </Link>
        </Breadcrumbs>
      </Box>
      <div className={classes.boxModules}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography className={classes.title}>
            {t('menu.stores')}
          </Typography>
          <Button
            className={classes.addButton}
            color="primary"
            variant="contained"
            onClick={() => setOpenAddStoreModal(true)}
          >
            {t('button_group.add_new')}
          </Button>
        </Box>

        <Paper elevation={0} style={{ overflowX: 'auto' }}>
          <Table className={classes.boxTable}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  className={classes.headerTableText}
                  style={{ width: '50%' }}
                >
                  {t('stores.store_nick_name')}
                </StyledTableCell>
                <StyledTableCell
                  className={classes.headerTableText}
                  style={{ width: '30%' }}
                >
                  {t('stores.address')}
                </StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>


            <TableBody>
              {stores?.map(store => (
                <TableRow hover key={store.id}>
                  <StyledTableCell>
                    <Box style={{ maxWidth: 200 }}>
                      <Input
                        name="name"
                        label=''
                        value={store?.name}
                        validationSchema={nickNameSchema}
                        onChange={async (value) => {
                          await updateStoreDetail(store, 'name', value)
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className={classes.boxFullName}>
                      <Typography className={classes.tableText}>
                        {getFullAddressOfStore({ ...store.address, country: '' })}
                      </Typography>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      onClick={() => onChangeStatusModule(row)}
                      className={classes.actionLink}
                    >
                      {t('stores.view_storefront')}
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isEmpty(stores) && (
            <Box
              marginTop={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography className={classes.noDataFound}>
                {t('stores.no_stores')}
              </Typography>
            </Box>
          )}
        </Paper>
      </div>

      {openAddStoreModal &&
        <AddNewStore
          open={openAddStoreModal}
          handleClose={() => setOpenAddStoreModal(false)}
        />
      }
    </AppLayout>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F3F5F7',
    borderBottom: 'none',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default StoresPage;
