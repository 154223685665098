import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Colors } from '@/components/signupModal/constants/colors'
import {
  getOrderPaymeentDue,
  localFormatDate,
} from '@/pages/workboard/DebtsWidget/modals/PayDebtModal/utils'
import moment from 'moment/moment'
import Button from '@/components/button'
import React from 'react'

export default function SelectOrderStep({
  setOrder,
  currentCustomer,
  storeName,
  storeAddress,
  onClose,
  classes,
}) {
  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle classes={classes} style={{ paddingRight: 48 }} className={'titleText'}>
        Debts
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box style={{ textAlign: 'center', marginBottom: '16px' }}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: Colors.DIM_GREY }}>
            {storeName}
          </Typography>
          <Typography variant="body2">{storeAddress}</Typography>
          <Typography variant="body2">
            <span style={{ fontWeight: 'bold', color: Colors.DIM_GREY }}>Customer:</span>{' '}
            {currentCustomer.first_name} {currentCustomer.last_name}
          </Typography>
        </Box>

        <Table size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: Colors.GRAY_LIGHT }}>
              <TableCell>Order No</TableCell>
              <TableCell>Date & Time</TableCell>
              <TableCell>Payment Due</TableCell>
              <TableCell align="right">Pay</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentCustomer.orders.map(order => {
              return (
                <TableRow
                  style={{
                    borderBottomWidth: 0,
                    borderBottom: 'none',
                  }}
                  key={order.order_no}
                >
                  <TableCell
                    style={{
                      borderBottomWidth: 0,
                      borderBottom: 'none',
                    }}
                  >
                    {order.order_no}
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottomWidth: 0,
                      borderBottom: 'none',
                    }}
                  >
                    {localFormatDate(moment(order.creationTime))}
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottomWidth: 0,
                      borderBottom: 'none',
                    }}
                  >
                    {getOrderPaymeentDue(order)}
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottomWidth: 0,
                      borderBottom: 'none',
                    }}
                  >
                    <Button onClick={() => setOrder(order)}>Pay</Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  )
}
