import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(4)
    },
    titleText: {
      ...CommonFonts.grayBoldFont20,
      color: Colors.DIM_GREY,
      fontWeight: 500
    },
    sliderContainer: {
      marginTop: theme.spacing(10),
    },
  })
)

export default useStyles
