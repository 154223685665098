import React from 'react';
import useStyles from './styles';
import {
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PdfPreview from '../filePicker/pdfPreview';

const FilePreview = (props) => {
  const {
    open,
    handleClose,
    attachement
  } = props

  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          alignContent="center"
        >
          <Grid item xs={1} />
          <Grid className={classes.headerText} item xs={10}>
            <span style={{ fontSize: 18 }}>
              {attachement.name}
            </span>
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            <IconButton
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div>
          {attachement.content_type === 'application/pdf'
            ?
            <div>
              <PdfPreview file={attachement.url} />
            </div>
            :
            <div className={classes.boxImagePreview}>
              <img
                className={classes.imagePreview}
                src={attachement.url}
              />
            </div>
          }
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default FilePreview

