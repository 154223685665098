import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Link,
  FormHelperText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import images from '@/assets/images';
import PreLoader from '@/components/signupModal/components/preLoader';
import { useParams, useHistory } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  MUTATE_DECLINE_INVITATION,
  CHECK_INVITATION,
} from '@/services/invitationService';

const checkInvitationQuery = gql`
  ${CHECK_INVITATION}
`;

const AccessMerchantDecline = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { inviteId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState('');

  const { loading: isCheckingInvitation, data } = useQuery(
    checkInvitationQuery,
    {
      variables: {
        id: inviteId,
      },
      onError: (e) => {
        history.push('/invitation/access-merchant/expired');
      },
      onCompleted: async (res) => {
        await onDeline();
      },
    }
  );

  const [declineInvitation] = useMutation(
    gql`
      ${MUTATE_DECLINE_INVITATION}
    `,
    {
      variables: {
        id: inviteId,
      },
    }
  );

  const onDeline = async () => {
    try {
      setIsErrorMessage('');
      setIsLoading(true);
      await declineInvitation();
    } catch (error) {
      const message =
        error || error?.message || error?.errors[0]?.message;
      setIsErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {!isCheckingInvitation ? (
        <>
          <Box
            className={classes.boxOutter}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <div className={classes.boxInner}>
              <Grid
                container
                justify="center"
                className={classes.logo}
              >
                <img
                  src={images.logo}
                  width={125}
                  height={50}
                  alt="logo"
                />
              </Grid>

              <Grid container justify="center">
                <Typography className={classes.description}>
                  {t(
                    'invitation.accessMerchant.decline.invitation_declined'
                  )}
                </Typography>
              </Grid>

              <Grid container justify="center">
                <FormHelperText error={isErrorMessage !== ''}>
                  {isErrorMessage}
                </FormHelperText>
              </Grid>

              <div className={classes.bottomContainer}>
                <span className={classes.bottomText}>
                  {t(
                    'invitation.accessMerchant.decline.email_received'
                  )}
                </span>
                <Link
                  href="https://krlmedia.com/terms"
                  underline="none"
                  target="_blank"
                >
                  <span className={classes.bottomBlueText}>
                    {t(
                      'invitation.accessMerchant.decline.contact_us'
                    )}
                  </span>
                </Link>
                <span className={classes.bottomText}>
                  {t(
                    'invitation.accessMerchant.decline.privacy_seriously'
                  )}
                </span>
              </div>
            </div>
            <Box className={classes.boxFooter}>
              <Typography className={classes.textFooter}>
                {t('invitation.accessMerchant.textFooter', {
                  year: new Date().getFullYear(),
                })}
              </Typography>
            </Box>
            {isLoading && <PreLoader size={30} />}
          </Box>
        </>
      ) : (
        <PreLoader size={30} />
      )}
    </>
  );
};

export default AccessMerchantDecline;
