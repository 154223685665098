import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors, CommonFonts } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(255, 255, 255, 0)'
      }
    },
    paper: {
      boxShadow: 'none',
      border: '1px solid #939DA8',
      borderRadius: 10
    },
    paperWidthSm: {
      borderRadius: 0,
    },
    closeButton: {
      position: 'absolute',
      right: 20,
      top: 20,
      backgroundColor: Colors.GRAY_LIGHT,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      }
    },
    mainContainer: {
      padding: theme.spacing(15, 25),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(15, 2),
      },
    },
    titleText: {
      ...CommonFonts.darkBoldFont30,
      marginTop: theme.spacing(8),
      textAlign: 'center'
    },
    descriptionText: {
      ...CommonFonts.darkFont16,
      marginTop: theme.spacing(5),
      textAlign: 'center'
    },
    filtersContainer: {
      marginTop: theme.spacing(15)
    },  
    searchWrapper: {
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0),
      },
    },
    searchInput: {
      '& .MuiInput-root': {
        padding: theme.spacing(3, 4),
        ...CommonFonts.darkFont18,
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: Colors.GRAY_BORDER1
      },
    },
    selectWrapper: {
      padding: theme.spacing(0, 4),
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        marginTop: theme.spacing(4)
      },
    },
    select: {
      '& .MuiSelect-root': {
        padding: theme.spacing(4.5, 6),
        ...CommonFonts.darkFont18,
      },
    }
  })
)

export default useStyles
