import { Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import SettingAccordion from '.';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import ReportFinancialYearEnd from '../reportFinancialYearEnd';
import ReportWeekCommencingDay from '../reportWeekCommencingDay';
import ReportDayEndTime from '../reportDayEndTime';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import PreLoader from '@/components/preLoader';
import {
  STORE_DETAIL,
  MUTATE_WEEK_COMMENCING_DAY,
  MUTATE_DAY_END_TIME,
  MUTATE_FINANCIAL_YEAR_END,
} from '@/services/storeService';

const storeDetailQuery = gql`
  ${STORE_DETAIL}
`;

const Reports = () => {
  const { t } = useTranslation();
  const reportFinancialYearEndRef = useRef();
  const reportWeekCommencingDayRef = useRef();
  const reportDayEndTimeRef = useRef();

  const [isExpand, setIsExpand] = useState(false);

  const onExpand = (value) => setIsExpand(value);

  const [getStoreDetail, { loading, error, data }] =
    useLazyQuery(storeDetailQuery);

  const storeDetail = data?.merchant;

  useEffect(() => {
    if (isExpand) {
      getStoreDetail();
    }
  }, [isExpand]);

  const updateStoreDetail = (key, value) => {
    const payload = {
      variables: {
        merchant: {
          id: storeDetail?.id,
          [key]: value,
        },
      },
      update: (store, { data: { updateMerchant } }) => {
        const cache = store.readQuery({ query: storeDetailQuery });
        const currentValue = {
          id: storeDetail?.id,
          [key]: value,
          __typename: 'Merchant',
        };
        const updatedData = {
          ...cache,
          merchant: {
            ...cache.merchant,
            ...currentValue,
          },
        };
        store.writeQuery({
          query: storeDetailQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateMerchant: {
          id: storeDetail?.id,
          [key]: value,
          __typename: 'Merchant',
        },
      },
    };
    switch (key) {
      case 'financial_year_end':
        return updateFinancialYearEnd(payload);
      case 'week_start_day':
        return updateWeekCommencingDay(payload);
      case 'day_end_time':
        return updateDayEndTime(payload);
      default:
        throw Error('Unknown store property', key);
    }
  };

  const [updateFinancialYearEnd] = useMutation(
    gql`
      ${MUTATE_FINANCIAL_YEAR_END}
    `
  );
  const [updateWeekCommencingDay] = useMutation(
    gql`
      ${MUTATE_WEEK_COMMENCING_DAY}
    `
  );
  const [updateDayEndTime] = useMutation(
    gql`
      ${MUTATE_DAY_END_TIME}
    `
  );

  const classes = useStyles();
  return (
    <SettingAccordion
      id="branding"
      title={t('settings.reports.title')}
      onExpand={onExpand}
    >
      {loading ? (
        <PreLoader size={30} />
      ) : (
        <Grid container>
          {/* Finance year end */}
          <Grid container spacing={10}>
            <Grid item xs={12} sm={3}>
              <ReportFinancialYearEnd
                ref={reportFinancialYearEndRef}
                value={storeDetail?.financial_year_end}
                onChange={(value) =>
                  updateStoreDetail('financial_year_end', value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <p className={classes.boxText}>
                <span
                  display="inline"
                  className={classes.descriptionText}
                >
                  {t(
                    'settings.reports.financial_year_end_description'
                  )}
                </span>
                <span
                  onClick={() =>
                    reportFinancialYearEndRef?.current?.handleOpenEditModal()
                  }
                  display="inline"
                  className={classes.actionText}
                >
                  {t('settings.reports.click_here')}
                </span>
                <span
                  display="inline"
                  className={classes.descriptionText}
                >
                  {t('settings.reports.to_update')}
                </span>
              </p>
            </Grid>
          </Grid>
          {/* Week commencing day */}
          <Grid container spacing={10}>
            <Grid item xs={12} sm={3}>
              <ReportWeekCommencingDay
                ref={reportWeekCommencingDayRef}
                value={storeDetail?.week_start_day}
                onChange={(value) =>
                  updateStoreDetail('week_start_day', value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <p className={classes.boxText}>
                <span
                  display="inline"
                  className={classes.descriptionText}
                >
                  {t(
                    'settings.reports.week_commencing_day_description'
                  )}
                </span>
                <span
                  onClick={() =>
                    reportWeekCommencingDayRef?.current?.handleOpenEditModal()
                  }
                  display="inline"
                  className={classes.actionText}
                >
                  {t('settings.reports.click_here')}
                </span>
                <span
                  display="inline"
                  className={classes.descriptionText}
                >
                  {t('settings.reports.to_update')}
                </span>
              </p>
            </Grid>
          </Grid>
          {/* Day end time */}
          <Grid container spacing={10}>
            <Grid item xs={12} sm={3}>
              <ReportDayEndTime
                ref={reportDayEndTimeRef}
                value={storeDetail?.day_end_time}
                onChange={(value) =>
                  updateStoreDetail('day_end_time', value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <p className={classes.boxText}>
                <span
                  display="inline"
                  className={classes.descriptionText}
                >
                  {t('settings.reports.day_end_time_description')}
                </span>
                <span
                  onClick={() =>
                    reportDayEndTimeRef?.current?.handleOpenEditModal()
                  }
                  display="inline"
                  className={classes.actionText}
                >
                  {t('settings.reports.click_here')}
                </span>
                <span
                  display="inline"
                  className={classes.descriptionText}
                >
                  {t('settings.reports.to_update')}
                </span>
              </p>
            </Grid>
          </Grid>
        </Grid>
      )}
    </SettingAccordion>
  );
};

export default Reports;
