import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDyra677IXVetmO6iL-Ngiphk_6NxrPWYk',
  authDomain: 'myda-smart-app.firebaseapp.com',
  databaseURL: 'https://myda-smart-app.firebaseio.com',
  projectId: 'myda-smart-app',
  storageBucket: 'myda-smart-app.appspot.com',
  messagingSenderId: '1055215335920',
  appId: '1:1055215335920:web:a75ac675b457f437090535',
  measurementId: 'G-316FEP2G8S',
};

firebase.initializeApp(firebaseConfig);

let messaging;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    messaging
      ?.getToken({
        vapidKey:
          'BFIBK26yHAlAxMQwZQNROAk6TdyuO41huF9CUquQdWPeCG-WgQxAapfN6Stv_lJkCM_BzI1Mpcu8YdRvWevb6eA',
      })
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging?.onMessage((payload) => {
      resolve(payload);
    });
  });
