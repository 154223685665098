import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';

import CardHeader from '@material-ui/core/CardHeader';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { getFullAddressOfStore } from '@/utils/stringUtil';
import SettingsInput from './input';
import ImagePicker from '@/components/imagePicker';
import { gql, useMutation } from '@apollo/client';

import {
  UPDATE_STORE,
  MUTATE_MERCHANT_LOGO,
} from '@/services/storeService';
import * as Yup from 'yup';
import MerchantActions from '@/store/modules/store/actions';
import AuthActions from '@/store/modules/auth/actions';

import { useDispatch } from 'react-redux';
import images from '@/assets/images';

import { getImageAsBase64 } from '@/utils/stringUtil';
import { Link } from '@material-ui/core';

export default function StoreOverview({
  selectedStore,
  currentMerchant,
}) {
  const classes = useStyles();
  const matchesSmallScreen = useMediaQuery('(max-width:1260px)');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logo } = currentMerchant;

  const [updatedMerchantLogo, setUpdatedMerchantLogo] =
    useState(null);

  const storeNameSchema = Yup.object().shape({
    name: Yup.string().required(
      t('businessManager.store_name_required')
    ),
  });

  const { address, name, settings } = selectedStore;

  const fullAdress = getFullAddressOfStore(address);

  const updateStoreDetail = (key, value) => {
    const payload = {
      variables: {
        store: {
          id: selectedStore?.id,
          [key]: value,
        },
      },
      onError: (error) => {
        throw new Error(error);
      },
    };

    return updateStore(payload);
  };

  const [updateStore] = useMutation(
    gql`
      ${UPDATE_STORE}
    `,
    {
      onCompleted: (response) => {
        dispatch(
          MerchantActions.updateSelectedStore(response.updateStore)
        );
        dispatch(AuthActions.updateStore(response.updateStore));
      },
    }
  );

  const [updateMerchantLogo] = useMutation(
    gql`
      ${MUTATE_MERCHANT_LOGO}
    `,
    {
      onCompleted: () => {
        dispatch(
          MerchantActions.updateMerchant({
            ...currentMerchant,
            logo: {
              thumbnail: updatedMerchantLogo,
            },
          })
        );
      },
    }
  );

  return (
    <Card className={classes.cardContent}>
      <CardHeader
        title={
          <div className={classes.cardTitle}>
            {t('businessManager.store_overview')}
          </div>
        }
      />
      <CardContent className={classes.storeOverviewCardContent}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {!matchesSmallScreen ? (
            <>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  alignContent="flex-start"
                  spacing={5}
                >
                  <Grid item>
                    <ImagePicker
                      widthCrop={240}
                      heightCrop={240}
                      id={logo.id}
                      thumbnail={
                        !!logo?.thumbnail
                          ? logo.thumbnail
                          : images.empty_merchant_logo
                      }
                      onChange={async (value, imageProperty) => {
                        let image = await getImageAsBase64(
                          imageProperty
                        );
                        setUpdatedMerchantLogo(
                          `data:image/png;base64, ${image}`
                        );
                        updateMerchantLogo({
                          variables: {
                            merchant: {
                              id: currentMerchant.id,
                              logo: { id: value.id },
                            },
                          },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      // alignItems="center"
                      spacing={3}
                    >
                      <Grid item>
                        <SettingsInput
                          name="name"
                          label={t(
                            'settings.business_and_finance.input.registered_name'
                          )}
                          value={name}
                          validationSchema={storeNameSchema}
                          onChange={(value) =>
                            updateStoreDetail('name', value)
                          }
                        />
                      </Grid>
                      <Grid item>{fullAdress}</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Link
                  href={
                    settings?.touchpoint_settings?.digital_front?.url
                  }
                  target="_blank"
                  underline="none"
                >
                  <Button
                    color="primary"
                    className={classes.viewStoresButton}
                  >
                    {t('businessManager.view_and_update_storefront')}
                  </Button>
                </Link>
              </Grid>
            </>
          ) : (
            <>
              {/* <Grid item xs={12} style={{ paddingTop: 16 }}> */}
              <Grid
                container
                direction="row"
                alignItems="center"
                // alignContent="flex-start"
                justify="space-between"
                spacing={4}
                item
                style={{ paddingTop: 16 }}
              >
                <Grid item xs={4}>
                  <ImagePicker
                    widthCrop={240}
                    heightCrop={240}
                    id={logo.id}
                    thumbnail={
                      !!logo.thumbnail
                        ? logo.thumbnail
                        : images.empty_merchant_logo
                    }
                    onChange={async (value, imageProperty) => {
                      let image = await getImageAsBase64(
                        imageProperty
                      );
                      setUpdatedMerchantLogo(
                        `data:image/png;base64, ${image}`
                      );
                      updateMerchantLogo({
                        variables: {
                          merchant: {
                            id: currentMerchant.id,
                            logo: { id: value.id },
                          },
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box display="flex" justifyContent="center">
                    <Link
                      href={
                        settings?.touchpoint_settings?.digital_front
                          ?.url
                      }
                      target="_blank"
                      underline="none"
                    >
                      <Button
                        color="primary"
                        className={classes.viewStoresButton}
                      >
                        {t(
                          'businessManager.view_and_update_storefront'
                        )}
                      </Button>
                    </Link>
                  </Box>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    // alignItems="center"
                    spacing={3}
                  >
                    <Grid item>
                      <SettingsInput
                        name="name"
                        label={t(
                          'settings.business_and_finance.input.registered_name'
                        )}
                        value={name}
                        validationSchema={storeNameSchema}
                        onChange={(value) =>
                          updateStoreDetail('name', value)
                        }
                      />
                    </Grid>
                    <Grid item>{fullAdress}</Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* </Grid> */}
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
