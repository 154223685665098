import React, { useState, useMemo } from 'react';
import { isEmpty } from 'lodash';
import {
  Box,
  Breadcrumbs,
  Link,
  Paper,
  Typography,
  Grid,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Table,
  withStyles,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import AppLayout from '@/components/appLayout';
import { useTranslation } from 'react-i18next';
import Button from '@/components/button';
import { useQuery } from '@apollo/client';
import { GET_SECRETS, GET_MERCHANTS } from '@/services/secretService';
import ConfirmDialog from './confirmDialog';
import { useSnackbar } from 'notistack';
import useStyles from './styles';

const DeveloperPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: secrets,
    loading: loadingSecrets,
    refetch: reFetch,
  } = useQuery(GET_SECRETS, {
    fetchPolicy: 'no-cache',
  });
  const {
    data: merchantAndStores,
    loading: loadingMerchantAndStore,
  } = useQuery(GET_MERCHANTS, {
    fetchPolicy: 'no-cache',
  });

  const [selectedItem, setSelectedItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [modal, setModal] = useState({
    add: false,
    revoke: false,
  });
  const reFetchSecrets = () => {
    setIsLoading(true);
    reFetch().then(() => setIsLoading(false));
  };

  const secretsList = secrets?.clients;
  const storesList = merchantAndStores?.merchant.stores;

  useMemo(() => {
    return storesList?.unshift({
      id: merchantAndStores?.merchant.id,
      name: t('developer.merchant_id'),
    });
  }, [storesList]);

  const onAddSecret = () => {
    setModal({ ...modal, add: true });
  };

  const onRevokeItem = (item) => {
    setSelectedItem(item);
    setModal({ ...modal, revoke: true });
  };

  const onCopy = (item) => {
    navigator.clipboard.writeText(item);
    return enqueueSnackbar(item + ' ' + t('developer.key_copied'));
  };

  return (
    <AppLayout className={classes.appLayout} withFooter header>
      <Box marginY={10}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className={classes.breadcrumbsText}
            color="primary"
            component={RouterLink}
            to="/business-manager/store-profile"
          >
            {t('userManagement.users.business_manager')}
          </Link>
          <Link
            className={classes.breadcrumbsText}
            color="textPrimary"
            component={RouterLink}
            to="/developer"
          >
            {t('developer.title')}
          </Link>
        </Breadcrumbs>
        <div className={classes.boxModules}>
          <Paper elevation={0} style={{ overflowX: 'auto' }}>
            <Grid
              container
              row
              spacing={2}
              className={classes.gridSpacing}
            >
              <Grid item xs={8}>
                <Typography className={classes.title}>
                  {t('developer.secret_keys_title')}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.gridSpacing}>
                <Grid container justify="flex-end">
                  <Button
                    className={classes.addButton}
                    color="primary"
                    onClick={onAddSecret}
                    variant="contained"
                  >
                    {t('developer.add_new_secret')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container row className={classes.gridSpacing}>
              <Typography variant="body2">
                {t('developer.secret_keys_description')}
              </Typography>
            </Grid>
            <Table>
              {loadingSecrets || isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: 'none' }}
                      colSpan={6}
                    >
                      <Box
                        marginTop={10}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={35} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {secretsList?.map((row) => (
                    <>
                      <TableRow
                        key={row.id}
                        className={classes.rootRow}
                      >
                        <StyledTableCell>
                          <div className={classes.boxFullName}>
                            <Typography className={classes.tableText}>
                              {row.name}
                            </Typography>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            align="right"
                            className={classes.dataId}
                          >
                            {row.id}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            onClick={() => onCopy(row.id)}
                            className={classes.actionLink}
                          >
                            {t('developer.copy')}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            onClick={() => onRevokeItem(row)}
                            color="textPrimary"
                            className={classes.link}
                          >
                            {t('developer.revoke')}
                          </Typography>
                        </StyledTableCell>
                      </TableRow>
                      <Grid className={classes.gridSpacing} />
                    </>
                  ))}
                </TableBody>
              )}
            </Table>
            {!loadingSecrets && isEmpty(secretsList) && (
              <Box
                marginTop={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.noDataFound}>
                  {t('developer.no_data_found')}
                </Typography>
              </Box>
            )}
            <Grid
              container
              row
              spacing={2}
              className={classes.gridSpacing}
            >
              <Grid item xs={12}>
                <Typography className={classes.title}>
                  {t('developer.merchant_and_store_title')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container row className={classes.gridSpacing}>
              <Typography variant="body2">
                {t('developer.merchant_and_store_description')}
              </Typography>
            </Grid>
            <Table>
              {loadingMerchantAndStore ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: 'none' }}
                      colSpan={6}
                    >
                      <Box
                        marginTop={10}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={35} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {storesList?.map((row) => (
                    <>
                      <TableRow
                        key={row.id}
                        className={classes.rootRow}
                      >
                        <StyledTableCell>
                          <div className={classes.boxFullName}>
                            <Typography className={classes.tableText}>
                              {row.name}
                            </Typography>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            align="right"
                            className={classes.dataId}
                          >
                            {row.id}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            onClick={() => onCopy(row.id)}
                            className={classes.actionLink}
                          >
                            {t('developer.copy')}
                          </Typography>
                        </StyledTableCell>
                      </TableRow>
                      <Grid className={classes.gridSpacing} />
                    </>
                  ))}
                </TableBody>
              )}
            </Table>
            {/* <Grid conatiner row className={classes.documentation}>
            <Link href="http://docs.mydacloud.com/api/" target="_blank"> {t('developer.click_here')}</Link>
            {t('developer.documentation')}
          </Grid> */}
            {!loadingMerchantAndStore && isEmpty(storesList) && (
              <Box
                marginTop={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.noDataFound}>
                  {t('developer.no_data_found')}
                </Typography>
              </Box>
            )}
          </Paper>
        </div>
      </Box>
      {modal.add && (
        <ConfirmDialog
          open={modal.add}
          secretsList={secretsList}
          handleClose={() => setModal({ ...modal, add: false })}
          setRefetchSelects={reFetchSecrets}
          dialogDescription={t(
            'developer.add_new_secret_description'
          )}
          dialogTitle={t('developer.add_new_secret_title')}
          actionType="add"
        />
      )}
      {modal.revoke && (
        <ConfirmDialog
          open={modal.revoke}
          handleClose={() => setModal({ ...modal, revoke: false })}
          setRefetchSelects={reFetchSecrets}
          dialogTitle={t('developer.revoke_item_title')}
          dialogDescription={t('developer.revoke_item_description')}
          selectedItem={selectedItem}
          actionType="revoke"
        />
      )}
    </AppLayout>
  );
};

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
    padding: 5,
    marginBottom: 10,
  },
}))(TableCell);

export default DeveloperPage;
