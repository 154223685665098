import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    contentDialog: {
      ...CommonFonts.grayFont16,
    },
    titleDialog: {
      ...CommonFonts.grayFont18,
      fontWeight: '500',
    },
    boxRedirect: {
      textAlign: 'center',
    },
    link: {
      textDecoration: 'none',
    },
    linkText: {
      ...CommonFonts.blueFont16,
    },
    cancelButton: {
      ...CommonFonts.grayFont16,
      letterSpacing: '0.11px',
      color: Colors.GRAY_BORDER3,
      opacity: 1,
    },
  })
);

export default useStyles;
