import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  appLayout: {
    backgroundColor: Colors.GRAY_LIGHT,
  },
  paperContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
    },
  },
  root: {
    position: 'relative',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingRight: 30,
    paddingLeft: 30,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  productsRoot: {
    position: 'relative',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingRight: 30,
    paddingLeft: 30,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    marginTop: theme.spacing(2)
  },

  categoriesRoot: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingRight: 20,
    paddingLeft: 20,
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  listHeader: {
    background: '#F3F5F7 0% 0% no-repeat padding-box',
    opacity: '1',
    height: 60,
    cursor: 'default',
    ...CommonFonts.darkFont16,
  },
  listItem: {
    height: 80,
    borderBottom: '1px solid #0000001F',
    ...CommonFonts.darkFont18,
  },
  searchInput: {
    '& .MuiInput-root': {
      padding: theme.spacing(2),
      borderRadius: 5,
      ...CommonFonts.darkFont18,
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
  },
  addButton: {
    padding: '10px 40px',
    background: '#1174F2 0% 0% no-repeat padding-box',
    textTransform: 'Uppercase',
    width: 208,
    ...CommonFonts.whiteFont14,
  },
  nested: {
    paddingLeft: 30,
  },
  avatarSize: {
    width: 40,
    height: 40,
    borderRadius: 0,
  },
  dotsIcon: {
    textAlign: 'right',
    zIndex: 100,
  },
  emptyProductsContainer: {
    paddingTop: theme.spacing(4),
    width: '100%',
  },
  emptyProductsTextL: {
    ...CommonFonts.darkFont18,
  },
  addProductsLink: {
    ...CommonFonts.blueBoldFont18,
    cursor: 'pointer',
    fontWeight: 500,
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    top: 20,
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
  rootDialog: {
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 5)
    },
  },

  headerText: {
    textAlign: 'center',
  },

  generalInfo: {
    padding: '10px 0',
  },
  generalInfoItem: {
    ...CommonFonts.darkFont14,
  },
  blockTitle: {
    ...CommonFonts.darkFont14,
    color: Colors.DIM_GREY,
  },

  selectStyles: {
    paddingTop: '7px',
    color: '#1174F2',
  },
  categoryImage: {
    padding: '15px 0',
  },
  categoryImageTitle: {
    padding: '10px 0',
  },
  mediaImage: {
    width: '150px',
    height: '90px',
    margin: '10px 0',
  },
  rules: {
    padding: '10px 0',
  },
  rulesItem: { maxWidth: '80%', padding: '10px 0' },
  rulesLabel: { fontSize: 18, color: '#20272FDE' },
  rulesDescription: {
    color: Colors.DIM_GREY,
    fontSize: 16,
    letterSpacing: '0.29px',
  },
  updateTextStyle: {
    color: '#1174F2',
  },
  rulesItemHeader: {
    paddingRight: '10px',
  },
  breadcrumbsText: {
    cursor: 'pointer'
  },
  tab: {
    '& .MuiTab-wrapper': {
      textTransform: 'none',
    },
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      borderBottomWidth: 2,
      borderBottomColor: '#E2E2E2',
      borderBottomStyle: 'solid',
    },
  },
  cancelButton: {
    ...CommonFonts.grayFont16,
    letterSpacing: '0.11px',
    color: Colors.GRAY_BORDER3,
    opacity: 1,
  },
  dialogContent: {
    height: '70vh',
  },
  moveProductModal: {
    maxWidth: 800
  },
  moveProductsText: {
    ...CommonFonts.darkFont14,
    padding: theme.spacing(12),
    textAlign: 'center',
  },
  arrowIcon: {
    color: Colors.GRAY_BORDER3
  },
  disableTextTransform: {
    textTransform: 'none',
  },
  boldLabel: {
    ...CommonFonts.darkFont14,
    fontWeight: 600
  },
  boldLabelContainer: {
    margin: theme.spacing(7, 0, 5)
  },
  nextButton: {
    height: 54,
    textTransform: 'none',
    fontSize: 14,
    color: Colors.WHITE,
    '&:active': {
      backgroundColor: Colors.BLUE_DARK,
    },
    '&:hover': {
      backgroundColor: Colors.BLUE,
    },
    '&:disabled': {
      backgroundColor: Colors.DISABLED,
      color: Colors.WHITE,
    },
  },
  selfieContainer: {
    maxWidth: 160,
    maxHeight: 200,
    overflow: 'hidden',
    borderRadius: '50%'
  },
  selfie: {
    width: 200,
    height: 200,
    marginLeft: -20
  },
  card: {
    height: 200,
    maxWidth: 500,
    objectFit: 'contain',
    borderRadius: theme.spacing(2)
  }
}));

export default useStyles;
