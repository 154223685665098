import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.darkFont14,
    },
    selectInput: {
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
  })
);

export default useStyles;
