import I18n from '@/i18n'
import moment from 'moment'

export const PROMOTION_TYPES = [
  {
    type: 'bogof',
    name: I18n.t('promotions.bogof')
  },
  {
    type: 'multi-buy',
    name: I18n.t('promotions.multi_buy')
  },
  {
    type: 'meal-deal',
    name: I18n.t('promotions.meal_deal')
  },
  {
    type: 'manual',
    name: I18n.t('promotions.manual')
  },
]

export const PROMOTION_STATUSES = [
  {
    status: 'active',
    name: I18n.t('promotions.active_promotions')
  },
  {
    status: 'inactive',
    name: I18n.t('promotions.inactive_promotions')
  },
]

export const getExpiryDate = (item) => {
  return item.valid_to
    ? moment(item.valid_to).format('DD MMM YYYY')
    : I18n.t('promotions.until_further_notice')
}