import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Grid } from '@material-ui/core';
import AppLayout from '@/components/appLayout';
import { useSnackbar } from 'notistack';

import images from '@/assets/images';
import useStyles from './styles';
import { useHistory } from 'react-router';
import SearchForStores from './components/SearchStore';
import EnterStoreBusinessType from './components/EnterStoreBusinessType';
import AddStoreManually from './components/AddStoreManually';
import AddFormManually from './components/addStoreManuallySteps/EnterFullAddressManually';

import { gql, useMutation } from '@apollo/client';
import { ADD_MERCHANT } from '@/services/storeService';
import PreLoader from '@/components/preLoader';
import AuthActions from '@/store/modules/auth/actions';
import MerchantActions from '@/store/modules/store/actions';

const StoreSelectPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = useState(0);
  const [selectedStore, setselectedStore] = useState(null);
  const [addressObject, setAddressObject] = useState({});
  const [businessName, setBusinessName] = useState(null);
  const [businessTypeId, setBusinessTypeId] = useState();

  const selectStoreFromPredictions = (values) => {
    setselectedStore(values);
    // setStep(1);
  };

  const [AddMerchantType, { loading }] = useMutation(
    gql`
      ${ADD_MERCHANT}
    `,
    {
      onCompleted: (response) => {
        dispatch(AuthActions.addNewMerchant(response.addMerchant));
        dispatch(MerchantActions.setCurrentMerchant(response.addMerchant));
        dispatch(
          MerchantActions.setSelectedStore(response.addMerchant.stores[0])
        );

        history.push('/workboard');
      },
      onError: (error) => {
        console.log('error', error);
        enqueueSnackbar(`Store could not be created!`);
      },
    }
  );

  //Save new store created
  const addMerchant = (value) => {
    const payload = {
      variables: {
        merchant: {
          tname: businessName,
          lname: businessName,
          business_type_id: businessTypeId,
          address: { ...addressObject },
          stores: [
            {
              name: businessName,
              address: { ...addressObject },
            },
          ],
        },
      },
    };
    return AddMerchantType(payload);
  };

  //render Add Store steps
  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <SearchForStores
            onSelectStore={selectStoreFromPredictions}
            setActiveStep={(value) => setStep(value)}
            setAddressObject={(value) => setAddressObject(value)}
            setBusinessName={(name) => setBusinessName(name)}
          />
        );
      case 0.5:
        return (
          <AddFormManually
            setActiveStep={(value) => setStep(value)}
            setAddressObject={(values) => setAddressObject(values)}
            previousStepValues={addressObject}
            addressObject={addressObject}
          />
        );
      case 1:
        return (
          <EnterStoreBusinessType
            businessName={businessName}
            selectedStore={selectedStore}
            setStep={(stepNumber) => setStep(stepNumber)}
            addMerchant={addMerchant}
            setBusinessTypeId={(id) => setBusinessTypeId(id)}
            businessTypeId={businessTypeId}
          />
        );

      case 2:
        return (
          <AddStoreManually setStep={(stepNumber) => setStep(stepNumber)} />
        );

      default:
        return null;
    }
  };

  return (
    <AppLayout className={classes.appLayout} withFooter>
      <Grid container justify="center" className={classes.logo}>
        <img src={images.logo} width={125} height={50} alt="logo" />
      </Grid>
      <Grid container justify="center" className={classes.selectStoreText}>
        {t('menu.businessManager')}
      </Grid>
      <Grid
        container
        className={step === 0 ? classes.firstStepRoot : classes.root}
      >
        {renderStep()}
      </Grid>
      {loading && <PreLoader size={25} />}
    </AppLayout>
  );
};

export default StoreSelectPage;
