import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommonFonts, Colors } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(12, 12, 18, 12),
      border: `1px solid ${Colors.GRAY_BORDER1}`,
      borderRadius: 15,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(12, 6, 18, 6),
      },
    },
    relative: {
      position: 'relative',
    },
    wrapperBackButton: {
      marginRight: theme.spacing(5),
    },
    titleWrapper: {
      overflowX: 'auto',
      overflowWrap: 'break-word',
    },
    title: {
      ...CommonFonts.darkFont24,
      fontWeight: 500,
      letterSpacing: 0.28,
      [theme.breakpoints.down('sm')]: {
        bottom: 4,
        letterSpacing: 0.4,
        fontSize: 20,
      },
    },
    nextButton: {
      height: 54,
      textTransform: 'none',
      fontSize: 14,
    },
    createText: {
      letterSpacing: 0.3,
      ...CommonFonts.blueFont16,
      cursor: 'pointer',
      fontWeight: 500,
      marginTop: theme.spacing(6),
    },
    signWithText: {
      letterSpacing: 0.3,
      ...CommonFonts.blueFont16,
      cursor: 'pointer',
    },
    backWrapper: {
      paddingRight: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(0.5),
      },
    },
    passwordField: {
      '&>div': {
        marginTop: '7px',
      },
    },
    emailField: {
      // '&>div': {
      //   marginTop: '24px',
      // },
      '&>p': {
        position: 'absolute',
        top: 55,
      },
      [theme.breakpoints.up('lg')]: {
        top: 50,
      },
    },
    form: {
      // [theme.breakpoints.down('sm')]: {
      //   paddingTop: 46,
      // },
    },

    resetPasswordLoginButton: {
      marginTop: 114,

      [theme.breakpoints.down('lg')]: {
        marginTop: 80,
      },
    },
    resetPasswordText: {
      color: '#20272FDE',
      fontSize: 18,
    },
  })
);

export default useStyles;
