import I18n from '@/i18n'

export const MEASURE_STR = {
  qty: I18n.t('addons.qty'),
  g: I18n.t('addons.weight_g'),
  kg: I18n.t('addons.weight_kg'),
  cm: I18n.t('addons.cm'),
  in: I18n.t('addons.inch'),
  m: I18n.t('addons.meter'),
  yd: I18n.t('addons.yard'),
  l: I18n.t('addons.litre'),
  ml: I18n.t('addons.ml'),
}