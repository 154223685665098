import { createReducer } from 'reduxsauce';

import { INITIAL_STATE } from './initialState';
import { BusinessManager } from './actions';

export const setBusinessManagerService = (state, { data }) => {
  return {
    ...state,
    selectedBusinessManagerService: data,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [BusinessManager.SET_BUSINESS_MANAGER_SERVICE]: setBusinessManagerService,
});
