export const getCurrencySymbol = (localisation) => {
  return (localisation?.currency_symbol || '').replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec);
  })
}

export const getToFixed = (decimal, value) => {
  if (decimal) {
    return parseFloat(value).toFixed(2)
  } else {
    return Math.round(value)
  }
}

export const getLocalisationVal = (localisation, value) => {
  return new Intl.NumberFormat(localisation?.digit_separator === ',' ? 'en' : 'id', {
    minimumFractionDigits: localisation?.currency_decimal ? 2 : 0,
  }).format(getToFixed(localisation?.currency_decimal, value))
}

export const transformFloat = (
  value,
  localisation = {},
  { showCurrency = false } = {}
) => {

  const { currency_position } = localisation

  let transformedValue = getLocalisationVal(localisation, value)

  if (showCurrency) {
    const symbol = getCurrencySymbol(localisation)
    if (currency_position === 'prefix') transformedValue = `${symbol} ${transformedValue}`
    else transformedValue = `${transformedValue} ${symbol}`
  }

  return transformedValue
}

export const transformInput = (localisation, value) => {
  let newValue = value
  if (localisation?.digit_separator === '.') {
    newValue = newValue.replace(/\./g, '')
    newValue = newValue.replace(/\,/g, '.')
  } else {
    newValue = newValue.replace(/\,/g, '')
  }

  return newValue
}
