import React, { useState } from 'react';

import {
  Box,
  Link,
  Breadcrumbs,
  Button,
  Typography,
} from '@material-ui/core';
import AppLayout from '@/components/appLayout';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import images from '@/assets/images';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';
import ImportDialog from './importDialog';
import _ from 'lodash';

const ImportPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <AppLayout className={classes.appLayout} withFooter header>
        <Box>
          <Box marginTop={10} marginBottom={10}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                className={classes.breadcrumbsText}
                color="primary"
                component={RouterLink}
                to="/business-manager"
              >
                {t('settings.business_manager')}
              </Link>
              <Link
                className={classes.breadcrumbsText}
                color="textPrimary"
                component={RouterLink}
                to="/import"
              >
                {t('import.import_data')}
              </Link>
            </Breadcrumbs>
          </Box>

          <Box
            marginTop={10}
            marginBottom={10}
            className={classes.rootBox}
          >
            <Box
              className={classes.boxImport}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                style={{ marginTop: 10 }}
                width={40}
                height={40}
                src={images.inventory}
                alt={'...'}
              />
              <Typography className={classes.descriptionText}>
                {t('import.description')}
              </Typography>
              <Button
                onClick={() => setIsOpenImportDialog(true)}
                className={classes.importButton}
                color="primary"
              >
                {t('import.title')}
              </Button>
            </Box>
          </Box>
          <ImportDialog
            isVisible={isOpenImportDialog}
            onCloseDialog={() => setIsOpenImportDialog(false)}
          />
        </Box>
      </AppLayout>
    </ErrorBoundary>
  );
};

export default ImportPage;
