import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/input'
import Selector from '../../../components/selector'

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { STYLE_OPTION_FIELD_DISPLAY, STYLE_OPTION_FIELD_TYPE } from '@/constants/styles'
import { isMobile } from 'react-device-detect';
import { find, isEmpty } from 'lodash';

const AddNewVariant = ({
  open,
  onClose,
  options,
  onAddVariant
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [variant, setVariant] = useState({
    id: uuid(),
    name: '',
    code: '',
    values: {}
  })

  const onChangeValue = (value, option) => {
    setVariant({
      ...variant,
      values: {
        ...variant.values,
        [option.id]: value
      }
    })
  }

  const getItemLabel = (option, value) => {
    const mainField = find(option.fields, { display: STYLE_OPTION_FIELD_DISPLAY.MAIN })
    const afterField = find(option.fields, { display: STYLE_OPTION_FIELD_DISPLAY.AFTER })
    const extraFields = option.fields.filter(field => field.display === STYLE_OPTION_FIELD_DISPLAY.EXTRA)

    const mainFieldValue = find(value.values, { name: mainField.name })?.value
    const afterFieldValue = find(value.values, { name: afterField?.name })?.value
    const extraFieldsValues = extraFields.map(field => (
      find(value.values, { name: field.name })
    ))
    const extraFieldValue = isEmpty(extraFieldsValues)
      ? ''
      : `(${extraFieldsValues.map(value => `${value.name}: ${value.value}`).join(', ')})`

    return [mainFieldValue, afterFieldValue, extraFieldValue].filter(item => !!item).join(' ')
  }

  const getSelectedItem = (option) => {
    return variant.values[option.id] || {}
  }

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      onClose={onClose}
      aria-labelledby={`{update-order-type-dialog-title}`}
      open={open}
      disableBackdropClick
    >
      <DialogTitle
        id={`{update-order-type-dialog-title}`}
        onClose={onClose}
      >
        <Typography className={classes.titleDialog}>
          {t('styles.add_new_variant')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          marginBottom={10}
        >
          <Box marginY={7}>
            <Typography className={classes.contentDialog} align='center'>
              {t('styles.enter_unique_variant')}
            </Typography>
          </Box>
          <Box marginBottom={5}>
            <Input
              title={t('styles.variant_name')}
              emptyLabel={t('add_product.add')}
              value={variant.name}
              onChange={(newValue) => {
                setVariant({ ...variant, name: newValue })
              }}
              onBlur={() => { }}
            />
          </Box>
          <Box marginBottom={5}>
            <Input
              title={t('styles.variant_code')}
              emptyLabel={t('add_product.add')}
              value={variant.code}
              onChange={(newValue) => {
                setVariant({ ...variant, code: newValue })
              }}
              onBlur={() => { }}
            />
          </Box>
          {options.map(option => (
            <Box key={option.id} marginBottom={5}>
              <Selector
                title={option.name}
                emptyLabel={t('add_product.select')}
                items={option.values}
                getItemLabel={(value) => getItemLabel(option, value)}
                selectedItem={getSelectedItem(option)}
                onChangeValue={(value) => {
                  onChangeValue(value, option)
                }}
                onBlur={() => { }}
              />
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions style={{ paddingRight: 30 }}>
        <Button
          color="primary"
          className={classes.cancelButton}
          onClick={onClose}
        >
          {t('button_group.cancel')}
        </Button>
        <Button
          color="primary"
          disabled={!variant.name || !variant.code || Object.keys(variant.values).length !== options.length}
          onClick={() => {
            onAddVariant({
              ...variant,
              values: Object.keys(variant.values).map(key => variant.values[key])
            })
            onClose()
          }}
        >
          {t('button_group.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

export default AddNewVariant;
