import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '@/theme';

const useStyles = makeStyles((theme) => ({
  appLayout: {
    backgroundColor: Colors.GRAY_LIGHT,
  },
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export default useStyles;
