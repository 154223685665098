import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import en from "./en";
import id from "./id";
import vi from './vi'
import zh_CN from './zh_CN'
import sg from "./sg";

const resources = {
  en: {
    translation: en,
  },
  id: {
    translation: id,
  },
  vi: {
    translation: vi
  },
  "zh-CN": {
    translation: zh_CN
  },
  sg: {
    translation: sg,
  }
}

const options = {
  order: ['navigator'],
  lookupQuerystring: 'lng'
}


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources,
    lng: 'en',
    fallbackLng: "en",
    debug: false,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },

    react: {
      wait: true
    }
  });

export default i18n;
