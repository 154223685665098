import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
} from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Colors, CommonFonts } from '@/theme';
import { Hours, Minutes, Seconds } from '@/constants/dateAndTime';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';
import useStyles from './styles';

const TimePicker = forwardRef(
  (
    {
      name,
      label,
      value,
      onChange,
      subTitle,
      openEditModal,
      setOpenEditModal,
      disableNotification,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const lowerCaseTitle = label.toLowerCase();

    // const [openEditModal2, setOpenEditModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const separateTimeStart = (value && value[0].split(':')) || '';
    // const separateTimeEnd = (value && value[1].split(':')) || '';

    const [hourStart, setHourStart] = useState('');

    const [minuteStart, settMinuteStart] = useState('');

    const [hourEnd, setHourEnd] = useState('');
    const [minuteEnd, settMinuteEnd] = useState('');

    useImperativeHandle(ref, () => ({
      handleOpenEditModal: () => setOpenEditModal(true),
    }));

    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    const concatStartTime = () => `${hourStart}:${minuteStart}`;
    const concatEndTime = () => `${hourEnd}:${minuteEnd}`;

    useEffect(() => {
      if (value && value[0] !== null && value[1] !== null) {
        const separateTimeStart = value[0].split(':');
        const separateTimeEnd = value[1].split(':');
        setHourStart(separateTimeStart[0]);
        settMinuteStart(separateTimeStart[1]);
        setHourEnd(separateTimeEnd[0]);
        settMinuteEnd(separateTimeEnd[1]);
      }
    }, [value]);

    const onSave = async () => {
      try {
        setIsLoading(true);
        await onChange([concatStartTime(), concatEndTime()]);
        handleCloseEditModal();
        if (!disableNotification) {
          enqueueSnackbar(
            `${capitalizeEachLetter(label)} ${
              value ? t('settings.updated') : t('settings.added')
            }`
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <>
        <Dialog
          fullWidth
          fullScreen={fullScreen}
          onClose={isLoading ? () => {} : handleCloseEditModal}
          aria-labelledby={`{edit-${lowerCaseTitle}-dialog-title}`}
          open={openEditModal}
        >
          <DialogTitle
            id={`{edit-${lowerCaseTitle}-dialog-title}`}
            onClose={handleCloseEditModal}
          >
            {`Update ${lowerCaseTitle}`}
          </DialogTitle>
          <DialogContent>
            <div>
              <Box>
                <Typography
                  className={classes.subTitleRangeTimePicker}
                >
                  From
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Select
                  style={{
                    marginRight: '25px',
                  }}
                  fullWidth
                  displayEmpty
                  id="day"
                  name="day"
                  value={hourStart}
                  onChange={(event) =>
                    setHourStart(event.target.value)
                  }
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {(Hours || []).map((option, index) => (
                    <MenuItem
                      key={`${option.name}-${index}`}
                      value={option.name}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  style={{
                    marginRight: '25px',
                  }}
                  fullWidth
                  displayEmpty
                  id="month"
                  name="month"
                  value={minuteStart}
                  onChange={(event) =>
                    settMinuteStart(event.target.value)
                  }
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {(Minutes || []).map((option, index) => (
                    <MenuItem
                      key={`${option.name}-${index}`}
                      value={option.name}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </div>
            <div style={{ marginTop: 50, marginBottom: 50 }}>
              <Box>
                <Typography
                  className={classes.subTitleRangeTimePicker}
                >
                  To
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Select
                  style={{
                    marginRight: '25px',
                  }}
                  fullWidth
                  displayEmpty
                  id="day"
                  name="day"
                  value={hourEnd}
                  onChange={(event) => setHourEnd(event.target.value)}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {(Hours || []).map((option, index) => (
                    <MenuItem
                      key={`${option.name}-${index}`}
                      value={option.name}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  style={{
                    marginRight: '25px',
                  }}
                  fullWidth
                  displayEmpty
                  id="month"
                  name="month"
                  value={minuteEnd}
                  onChange={(event) =>
                    settMinuteEnd(event.target.value)
                  }
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {(Minutes || []).map((option, index) => (
                    <MenuItem
                      key={`${option.name}-${index}`}
                      value={option.name}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditModal}>
              {t('settings.cancel')}
            </Button>
            <Button onClick={onSave} type="submit" color="primary">
              {t('settings.save')}
            </Button>
          </DialogActions>
          {isLoading && <PreLoader size={25} />}
        </Dialog>
      </>
    );
  }
);

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default TimePicker;
