import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '@/pages/notFoundPage';
import ReceiptPage from '@/pages/receipt';

const Routes = () => (
  <Switch>
    <Route
      path="/receipt/:merchantId/:storeId/:orderId"
      component={ReceiptPage}
    />
    {/* Show Not Found page when url does not match */}
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
