import React, { useState } from 'react';
import useStyles from './styles';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Menu,
  MenuItem,
  Paper,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';
import TextField from '@/components/textField';
import SearchIcon from '@material-ui/icons/Search';
import { Colors } from '@/theme';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  MUTATE_TEAM,
  GET_TEAMS_FILTER,
} from '@/services/userManagementService';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import useDebounce from '@/hooks/useDebounce';
import AddNewTeam from '../addNewTeam';
import UpdateTeam from '../updateTeam';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

const teamsQueries = gql`
  ${GET_TEAMS_FILTER}
`;

const TeamsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [currentTeam, setCurrentTeam] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const debouncedSearchTerm = useDebounce(searchText, 500);

  const {
    loading: loadingTeams,
    data: teamData,
    refetch,
  } = useQuery(teamsQueries, {
    variables: {
      name: `%${debouncedSearchTerm}%`,
    },
  });
  const teams = teamData?.teams;

  const [updateTeam] = useMutation(
    gql`
      ${MUTATE_TEAM}
    `
  );

  const mutateTeam = async (type, value) => {
    setIsLoading(true);
    const inputRequest =
      type === 'add'
        ? { name: value }
        : type === 'delete'
        ? { id: value, flag: 'delete' }
        : { id: value?.id, name: value?.name };

    const payload = {
      variables: {
        input: inputRequest,
      },
      onError: (error) => {
        throw new Error(error);
      },
    };
    return updateTeam(payload)
      .then(async (res) => {
        if (res?.data) {
          await refetch();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleCloseUpdateTeamModal = () => setIsVisible(false);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <Box>
        <div className={classes.boxFilter}>
          <Grid
            justify="space-between"
            alignItems="center"
            container
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <TextField
                placeholder={t(
                  'userManagement.teams.search_placeholder'
                )}
                id="userSearch"
                name="userSearch"
                fullWidth
                value={searchText}
                onChange={(event) =>
                  setSearchText(event.target.value)
                }
                InputProps={{
                  className: classes.input,
                }}
                startAdornment={
                  <SearchIcon
                    style={{ color: Colors.GRAY_BORDER1 }}
                    width={18}
                    height={18}
                  />
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={2}>
              <div style={{ textAlign: 'center' }}>
                <AddNewTeam
                  onChange={(value) => mutateTeam('add', value)}
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={classes.boxUserManagement}>
          <Typography className={classes.title}>
            {t('userManagement.teams.teams')}
          </Typography>
          <Paper elevation={0} style={{ overflowX: 'auto' }}>
            <Table className={classes.boxTable}>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    className={classes.headerTableText}
                    style={{ width: '90%', borderBottom: 'none' }}
                  >
                    {t('userManagement.teams.name')}
                  </StyledTableCell>
                  <StyledTableCell style={{ borderBottom: 'none' }} />
                </TableRow>
              </TableHead>

              {loadingTeams || isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: 'none' }}
                      colSpan={6}
                    >
                      <Box
                        marginTop={10}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={35} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {teams?.map((row) => (
                    <TableRow hover key={row.id}>
                      <StyledTableCell>
                        <div className={classes.boxFullName}>
                          <Typography className={classes.tableText}>
                            {row.name}
                          </Typography>
                        </div>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <IconButton
                          className={classes.moreIcon}
                          onClick={(event) => {
                            setAnchorMenu(event.currentTarget);
                            setCurrentTeam(row);
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          id="menu-options-teams"
                          className={classes.menuOption}
                          anchorEl={anchorMenu}
                          keepMounted
                          open={
                            Boolean(anchorMenu) &&
                            _.isEqual(currentTeam, row)
                          }
                          onClose={() => setAnchorMenu(null)}
                        >
                          <MenuItem
                            className={classes.menuItem}
                            onClick={() => {
                              setAnchorMenu(null);
                              setIsVisible(true);
                            }}
                          >
                            <Typography
                              className={classes.menuItemText}
                            >
                              {t('userManagement.teams.edit')}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            className={classes.menuItem}
                            onClick={async () => {
                              setAnchorMenu(null);
                              await mutateTeam('delete', row.id);
                              enqueueSnackbar(
                                t('userManagement.teams.team_delete')
                              );
                            }}
                          >
                            <Typography
                              className={classes.menuItemText}
                            >
                              {t('userManagement.teams.delete')}
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {!loadingTeams && teams?.length === 0 && (
              <Box
                marginTop={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.noTeamFound}>
                  {t('userManagement.teams.no_team_found')}
                </Typography>
              </Box>
            )}
          </Paper>
        </div>
        <UpdateTeam
          key={currentTeam}
          value={currentTeam}
          isVisible={isVisible}
          handleCloseUpdateTeamModal={handleCloseUpdateTeamModal}
          onChange={(value) => mutateTeam('update', value)}
        />
      </Box>
    </ErrorBoundary>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default TeamsPage;
