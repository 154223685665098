import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  boxShelf: {
    borderBottomWidth: theme.spacing(0.25),
    borderBottomStyle: 'solid',
    borderBottomColor: Colors.GRAY_BORDER1,
    paddingBottom: theme.spacing(5),
  },
}));

export default useStyles;
