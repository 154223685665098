import React, { useEffect, useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  Chip,
  Grid,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_FLOOR,
  GET_AREA,
  GET_SHELVES,
  MUTATE_FLOOR,
  MUTATE_AREAS,
  MUTATE_SHELVES,
} from '@/services/modulesService';
import AddingFloor from './addingFloorDialog';
import AreaInput from './areaInput';
import { v4 as uuid } from 'uuid';
import ShelfInput from './shelfInput';

const floorQueries = gql`
  ${GET_FLOOR}
`;

const areaQueries = gql`
  ${GET_AREA}
`;

const shelveQueries = gql`
  ${GET_SHELVES}
`;

const FloorAndShelf = ({ isVisible, onCloseModal }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenAddingFloor, setIsOpenAddingFloor] = useState(false);
  const [areas, setAreas] = useState([]);
  const [shelves, setShelves] = useState([]);

  const handleCloseEditModal = () => {
    setAreas([]);
    setShelves([]);
    onCloseModal();
  };

  const [
    getFloors,
    {
      loading: loadingFloors,
      data: floorsData,
      refetch: refetchFloors,
    },
  ] = useLazyQuery(floorQueries, { fetchPolicy: 'no-cache' });
  const floors = floorsData?.floors;

  const [getAreas, { loading: loadingAreas, data: areasData }] =
    useLazyQuery(areaQueries, { fetchPolicy: 'no-cache' });

  const [getShelves, { loading: loadingShelves, data: shelvesData }] =
    useLazyQuery(shelveQueries, { fetchPolicy: 'no-cache' });

  const [mutateFloor] = useMutation(
    gql`
      ${MUTATE_FLOOR}
    `
  );

  const addFloor = (floorName) =>
    mutateFloor({
      variables: {
        input: {
          name: floorName,
        },
      },
    });

  const deleteFloor = async (floor) => {
    setIsLoading(true);
    try {
      await mutateFloor({
        variables: {
          input: {
            id: floor.id,
            name: floor.name,
            flag: 'delete',
          },
        },
      });
      await refetchFloors();
      enqueueSnackbar(t('modules.floor_and_shelf.floor_deleted'));
    } catch (error) {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const addNewArea = () => {
    setAreas([
      ...areas,
      {
        id: uuid(),
        name: '',
        floor: {
          id: '',
          name: '',
        },
      },
    ]);
  };

  const updateArea = (index, item) => {
    const { key, value } = item;
    const list = [...areas];
    list[index][key] = value;
    setAreas(list);
  };

  const addNewLocation = () => {
    setShelves([
      ...shelves,
      {
        id: uuid(),
        area: {
          id: uuid(),
          name: '',
        },
        aisle: '',
        shelf: '',
        customer_access: false,
      },
    ]);
  };

  const updateShelf = (index, item) => {
    const { key, value } = item;
    const list = [...shelves];
    list[index][key] = value;
    setShelves(list);
  };

  const [mutateAreas] = useMutation(
    gql`
      ${MUTATE_AREAS}
    `
  );
  const [mutateShelves] = useMutation(
    gql`
      ${MUTATE_SHELVES}
    `
  );

  const onUpdate = async () => {
    setIsLoading(true);
    try {
      if (!_.isEmpty(areas)) {
        await mutateAreas({
          variables: {
            input: areas?.map((area) => ({
              id: area?.id,
              name: area?.name,
              floor_id: area?.floor?.id,
            })),
          },
        });
      }

      if (!_.isEmpty(shelves)) {
        await mutateShelves({
          variables: {
            input: shelves?.map((shelf) => ({
              aisle: shelf?.aisle,
              area_id: shelf?.area?.id,
              customer_access: shelf?.customer_access,
              id: shelf?.id,
              shelf: shelf?.shelf,
            })),
          },
        });
      }

      onCloseModal();
      enqueueSnackbar(
        t('modules.floor_and_shelf.floor_and_shelves_updated')
      );
    } catch (error) {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      getFloors();
      getAreas();
      getShelves();
    }
  }, [isVisible]);

  useEffect(() => {
    if (!_.isEmpty(areasData)) {
      setAreas(areasData.areas);
    }
    if (!_.isEmpty(shelvesData)) {
      setShelves(shelvesData.shelves);
    }
  }, [areasData, shelvesData]);

  return (
    <>
      <Dialog
        fullWidth
        fullScreen={isMobile}
        maxWidth="lg"
        onClose={isLoading ? () => {} : handleCloseEditModal}
        aria-labelledby={`floor-and-shelf-management`}
        open={isVisible}
        disableBackdropClick
      >
        <DialogTitle
          id={`floor-and-shelf-management`}
          onClose={handleCloseEditModal}
        >
          {t('modules.floor_and_shelf.floor_and_shelf_management')}
        </DialogTitle>
        <DialogContent>
          <Box>
            {/* Floors section */}
            <Box marginTop={10}>
              <Typography className={classes.title}>
                {t('modules.floor_and_shelf.floors')}
              </Typography>
              <Box marginY={4}>
                {loadingFloors ? (
                  <Skeleton height={50} />
                ) : (
                  floors?.map((floor) => (
                    <Chip
                      className={classes.chipFloor}
                      key={floor.id}
                      label={floor.name}
                      onDelete={() => deleteFloor(floor)}
                    />
                  ))
                )}
              </Box>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Typography
                    className={classes.actionLink}
                    onClick={() => setIsOpenAddingFloor(true)}
                  >
                    {t('modules.floor_and_shelf.add_floor')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Areas section */}
            <Box marginTop={10}>
              <Typography className={classes.title}>
                {t('modules.floor_and_shelf.areas')}
              </Typography>
              {loadingAreas ? (
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                  </Grid>
                </Grid>
              ) : (
                areas?.map((area, index) => (
                  <AreaInput
                    key={area.id}
                    area={area}
                    floors={floors}
                    onChangeArea={(value) => updateArea(index, value)}
                  />
                ))
              )}
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Typography
                    className={classes.actionLink}
                    onClick={() => addNewArea()}
                  >
                    {t('modules.floor_and_shelf.add_area')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Shelves section */}
            <Box marginTop={10}>
              <Typography className={classes.title}>
                {t('modules.floor_and_shelf.shelves')}
              </Typography>
              {loadingShelves ? (
                <Box>
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                </Box>
              ) : (
                shelves?.map((shelf, index) => (
                  <ShelfInput
                    key={shelf.id}
                    areas={areas}
                    shelf={shelf}
                    updateShelf={(value) => updateShelf(index, value)}
                  />
                ))
              )}
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Typography
                    className={classes.actionLink}
                    onClick={() => addNewLocation()}
                  >
                    {t('modules.floor_and_shelf.add_new_location')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>
            {t('settings.cancel')}
          </Button>
          <Button onClick={onUpdate} color="primary">
            {t('settings.update')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
      <AddingFloor
        isVisible={isOpenAddingFloor}
        onCloseModal={() => setIsOpenAddingFloor(false)}
        onChange={(value) => addFloor(value)}
        onFetchFloor={refetchFloors}
      />
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default FloorAndShelf;
