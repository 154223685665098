import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { useMutation } from '@apollo/client';
import PhoneInput from 'react-phone-input-2';
import { ADD_NEW_CUSTOMER } from '@/services/customerService';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@/components/textField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import useStyles from './styles';

export default function AddCustomerDialog({
  open,
  handleClose,
  setRefetchCustomers,
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().test(
      'alphabet-validation',
      t('customers.only_letters'),
      function (value) {
        console.log(value, 'value');
        return /^[a-zA-Z]+$/.test(value);
      }
    ),
    last_name: Yup.string().test(
      'alphabet-validation',
      t('customers.only_letters'),
      function (value) {
        return /^[a-zA-Z]+$/.test(value);
      }
    ),
    email: Yup.string().email(t('customers.enter_valid_email')),
  });
  const [mainCustomerInput, setMainCustomerInput] = useState({});
  const [phone, setPhone] = useState({ number: '', country: 'jp' });

  // Detect default country using IP
  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        setPhone({ number: '', country: data.country.toLowerCase() });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [AddNewCustomer, { loading: loadingSaveCustomer }] =
    useMutation(ADD_NEW_CUSTOMER, {
      onCompleted: (response) => {
        enqueueSnackbar(t('customers.customer_created_successfully'));
        handleClose();
        setRefetchCustomers();
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      },
    });

  // Save new customer created
  const addNewCustomer = () => {
    const payload = {
      variables: {
        input: {
          first_name: mainCustomerInput?.first_name,
          last_name: mainCustomerInput?.last_name,
          email: mainCustomerInput.email,
          mobile: phone.number,
          gender: mainCustomerInput.gender,
        },
      },
    };
    return AddNewCustomer(payload);
  };

  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setMainCustomerInput((s) => ({
      ...s,
      [name]: value,
    }));
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="responsive-dialog-title"
      >
        <Formik
          initialValues={{
            first_name: mainCustomerInput?.first_name,
            last_name: mainCustomerInput?.last_name,
            email: mainCustomerInput.email,
            mobile: phone.number,
            gender: mainCustomerInput.gender,
          }}
          validationSchema={validationSchema}
          onSubmit={addNewCustomer}
        >
          {({
            handleBlur,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isValid,
          }) => (
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit}
            >
              <DialogTitle id="alert-dialog-slide-title">
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  alignContent="center"
                >
                  <Grid item xs={1} />
                  <Grid className={classes.headerText} item xs={10}>
                    {t('customers.add_new_customer')}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    <IconButton
                      onClick={handleClose}
                      className={classes.closeButton}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Grid
                  container
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid xs={12} row className={classes.gridItem}>
                    <TextField
                      id="standard-basic"
                      label={t('customers.first_name')}
                      className={classes.addCustomer}
                      name="first_name"
                      fullWidth
                      value={mainCustomerInput.first_name}
                      isEdit={true}
                      onBlur={handleBlur}
                      error={
                        !!(touched.first_name && errors.first_name)
                      }
                      helperText={
                        touched.first_name && errors.first_name
                      }
                      onChange={(e) => {
                        handleChangeFormData(e);
                        setFieldValue('first_name', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid xs={12} row className={classes.gridItem}>
                    <TextField
                      id="standard-basic"
                      label={t('customers.last_name')}
                      className={classes.addCustomer}
                      name="last_name"
                      fullWidth
                      value={mainCustomerInput.last_name}
                      isEdit={true}
                      onBlur={() => setFieldTouched('last_name')}
                      error={
                        !!(touched.last_name && errors.last_name)
                      }
                      helperText={
                        touched.last_name && errors.last_name
                      }
                      onChange={(e) => {
                        handleChangeFormData(e);
                        setFieldValue('last_name', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid xs={12} row className={classes.gridItem}>
                    <TextField
                      id="standard-basic"
                      label={t('customers.email')}
                      className={classes.addCustomer}
                      name="email"
                      type="email"
                      fullWidth
                      value={mainCustomerInput.email}
                      isEdit={true}
                      onBlur={() => setFieldTouched('email')}
                      error={!!(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onChange={(e) => {
                        handleChangeFormData(e);
                        setFieldValue('email', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    alignContent="center"
                    row
                    xs={12}
                    className={classes.gridItem}
                  >
                    <RadioGroup
                      aria-label="gender"
                      name="gender"
                      row
                      onChange={(e) => handleChangeFormData(e)}
                    >
                      <FormControlLabel
                        value="male"
                        control={<Radio color="primary" />}
                        label={t('customers.male')}
                        className={classes.radioButton}
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio color="primary" />}
                        label={t('customers.female')}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid row xs={12} className={classes.gridItem}>
                    <Typography variant="body2">
                      {t('customers.mobile')}
                    </Typography>
                    <PhoneInput
                      country={phone.country}
                      value={phone.number}
                      onChange={(number) => setPhone({ number })}
                      inputClass={classes.inputClass}
                      buttonClass={classes.dropDownClass}
                    />
                  </Grid>
                  <Grid row xs={12} className={classes.gridItem}>
                    <Typography variant="body2" gutterBottom>
                      {t('customers.agreement') +
                        ' ' +
                        t('customers.terms_of_service') +
                        ' ' +
                        t('customers.and') +
                        ' '}
                      <Link
                        href="https://krlmedia.com/terms"
                        underline="none"
                      >
                        <span className={classes.footerText}>
                          {t('customers.privacy_policy')}
                        </span>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ paddingRight: 30 }}>
                <Button
                  color="primary"
                  onClick={handleClose}
                  className={classes.cancelButton}
                >
                  {t('customers.cancel')}
                </Button>
                <Button
                  disabled={
                    !(
                      mainCustomerInput.first_name &&
                      mainCustomerInput.last_name
                    ) || !isValid
                  }
                  color="primary"
                  onClick={addNewCustomer}
                >
                  {t('customers.add')}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
