import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { isMobile } from 'react-device-detect'

import { Box, Grid, Typography } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import PreLoader from '@/components/preLoader'
import NoResults from './noResults'

import useStyles from './styles'
import Table from './table'

import { GET_SHOPPERS } from '@/services/shopperService'
import { useQuery } from '@apollo/client'
import {
  SHOPPERS_SORT_TYPE,
  SORT_DIRECTION,
  SORT_FUNCTION,
} from '@/pages/workboard/DebtsWidget/helper'
import classNames from 'classnames'
import PayDebtModal from '@/pages/workboard/DebtsWidget/modals/PayDebtModal'

const defaultData = { shoppers: [] }

const DebtsWidget = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [sortType, setSortType] = useState(SHOPPERS_SORT_TYPE.outstanding)
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ASCENDING)
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const { data: { shoppers } = defaultData, loading: isShoppersLoading } = useQuery(GET_SHOPPERS)

  const shoppersToRender = useMemo(() => {
    let filteredShoppersMapper = [...shoppers]
      .filter(el => !!el.outstanding)
      .map(el => ({
        no_of_invoices: el.orders?.length || 0,
        outstanding: el.outstanding?.toFixed(2) || 0,
        first_name: el.first_name || '',
        last_name: el.last_name || '',
        mobile: el.mobile || el.email || '',
        customer: el,
      }))
    return filteredShoppersMapper.sort((a, b) => {
      return SORT_FUNCTION[sortDirection](a[sortType], b[sortType])
    })
  }, [shoppers, sortDirection, sortType])

  const onPressPay = useCallback(user => {
    setSelectedCustomer(user.customer)
  }, [])

  return (
    <>
      <PayDebtModal
        isOpen={!!selectedCustomer}
        currentCustomer={selectedCustomer}
        closeModal={() => setSelectedCustomer(null)}
      />
      <Box className={classes.root}>
        <Typography className={classes.titleText}>{t('globalWidgets.debts_widget')}</Typography>

        {isEmpty(shoppersToRender) && !isShoppersLoading && (
          <NoResults title={t('workboard.no_debts')} />
        )}
        {!isEmpty(shoppersToRender) && (
          <React.Fragment>
            <Box
              display="flex"
              justifyContent={isMobile ? 'center' : 'flex-end'}
              className={classes.layoutSelectContainer}
            >
              <Box>
                <Grid container className={classes.layoutsWrapper}>
                  <Grid item>
                    <Box
                      display="flex"
                      justifyContent="center"
                      className={classNames(classes.menuIconContainer)}
                    >
                      <Menu color="disabled" fontSize="large" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box className={classes.contentContainer}>
              <Table
                items={shoppersToRender}
                sortType={sortType}
                sortDirection={sortDirection}
                onPressSort={sortType => {
                  setSortType(sortType)
                  setSortDirection(
                    sortDirection === SORT_DIRECTION.ASCENDING
                      ? SORT_DIRECTION.DESCENDING
                      : SORT_DIRECTION.ASCENDING
                  )
                }}
                onPressPay={onPressPay}
              />
            </Box>
          </React.Fragment>
        )}
        {isShoppersLoading && <PreLoader size={30} />}
      </Box>
    </>
  )
}

export default React.memo(DebtsWidget)
