import React from 'react';

import AppLayout from '@/components/appLayout';
import useStyles from './styles';
import { Box, Breadcrumbs, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import BusinessAndFinance from './accordion/businessAndFinance';
import ChartOfAccounts from './accordion/chartOfAccounts';
import InventoryAndPricing from './accordion/inventoryAndPricing';
import TouchpointPOS from './accordion/touchpointPOS';
import Branding from './accordion/branding';
import TouchpointKiosk from './accordion/touchpointKiosk';
import TouchpointStorefront from './accordion/touchpointStorefront';
import { useTranslation } from 'react-i18next';
import Reports from './accordion/reports';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';

const SettingsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <AppLayout className={classes.appLayout} withFooter header>
        <Box>
          <Box marginTop={10} marginBottom={10}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                className={classes.breadcrumbsText}
                color="primary"
                component={RouterLink}
                to="/business-manager/store-profile"
              >
                {t('settings.business_manager')}
              </Link>
              <Link
                className={classes.breadcrumbsText}
                color="textPrimary"
                component={RouterLink}
                to="/settings"
              >
                {t('settings.settings')}
              </Link>
            </Breadcrumbs>
          </Box>
          <Box marginBottom={8}>
            <BusinessAndFinance />
          </Box>
          <Box marginBottom={8}>
            <Reports />
          </Box>
          <Box marginBottom={8}>
            <ChartOfAccounts />
          </Box>
          <Box marginBottom={8}>
            <InventoryAndPricing />
          </Box>
          <Box marginBottom={8}>
            <Branding />
          </Box>
          <Box marginBottom={8}>
            <TouchpointPOS />
          </Box>
          <Box marginBottom={8}>
            <TouchpointKiosk />
          </Box>
          <Box marginBottom={8}>
            <TouchpointStorefront />
          </Box>
        </Box>
      </AppLayout>
    </ErrorBoundary>
  );
};

export default SettingsPage;
