import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(7),
      paddingTop: theme.spacing(7),
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 0, 7, 0),
        position: 'relative',
        bottom: 16,
      },
    },
    footerText: {
      ...CommonFonts.darkFont16,
    },
    copywrightWrapper: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
      },
    },
    termsWrapper: {
      alignItems: 'baseline',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        gap: 13,
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        gap: 10,
        justifyContent: 'space-between',
      },
    },
  })
);

export default useStyles;
