import {
  formatOrderDate,
  getItemPrice,
} from '@/pages/workboard/DebtsWidget/modals/PayDebtModal/utils'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Input,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Colors } from '@/components/signupModal/constants/colors'
import React, { useState } from 'react'
import DropDown from '@/pages/workboard/DebtsWidget/modals/PayDebtModal/components/PayOrderStep/DropDown'
import { usePaymentButtons } from './usePaymentButtons'

import { getOrderPaymeentDue } from '@/pages/workboard/DebtsWidget/modals/PayDebtModal/utils'

export default function PayOrderStep({
  order,
  setOrder,
  storeName,
  storeAddress,
  classes,
  onClose,
}) {
  const totalAmount = getOrderPaymeentDue(order) ?? '0.00'
  const [amountToPay, setAmountToPay] = useState(totalAmount)

  const formattedDate = formatOrderDate(order?.creation?.time || 0)
  const dialogTitle = `${order.order_no} - ${formattedDate} - ${order.state}`

  const shopperName =
    order.shopper && (order.shopper.first_name || order.shopper.last_name)
      ? `${order.shopper?.first_name} ${order.shopper?.last_name}`.trim()
      : 'N/A'
  const { onPressPay, paymentTypes } = usePaymentButtons(onClose)
  return (
    <Dialog open onClose={() => setOrder(null)} maxWidth="sm" fullWidth>
      <DialogTitle classes={classes} style={{ paddingRight: 48 }} className={'titleText'}>
        {dialogTitle}
        <IconButton
          aria-label="close"
          onClick={() => setOrder(null)}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box style={{ textAlign: 'center', marginBottom: '16px' }}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: Colors.DIM_GREY }}>
            {storeName}
          </Typography>
          <Typography variant="body2">{storeAddress}</Typography>
          <Typography variant="body2">
            <span style={{ fontWeight: 'bold', color: Colors.DIM_GREY }}>Customer:</span>{' '}
            {shopperName}
          </Typography>
        </Box>

        <Table size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: Colors.GRAY_LIGHT }}>
              <TableCell>Qty</TableCell>
              <TableCell>Item</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.items.map((item, idx) => {
              const qty = item.price.quantity
              const amount = item.price.amount
              return (
                <>
                  <TableRow
                    style={{
                      borderBottomWidth: 0,
                      borderBottom: 'none',
                    }}
                    key={idx}
                  >
                    <TableCell
                      style={{
                        borderBottomWidth: 0,
                        borderBottom: 'none',
                      }}
                    >
                      {qty}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottomWidth: 0,
                        borderBottom: 'none',
                      }}
                    >
                      {item.name}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottomWidth: 0,
                        borderBottom: 'none',
                      }}
                      align="right"
                    >
                      {getItemPrice(item, amount).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  {item.addons &&
                    item.addons.map((sub, sIdx) => (
                      <TableRow key={`${sub.name}-sub-${sIdx}`}>
                        <TableCell style={{ borderBottom: 'none' }} />
                        <TableCell style={{ borderBottom: 'none' }}>
                          {sub.price?.quantity}x {sub.name}{' '}
                          {((sub.price?.amount || 0) * (sub.price?.quantity || 1)).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )
            })}
          </TableBody>
        </Table>

        <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
        <Box
          style={{
            marginTop: '16px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
            }}
          >
            <Typography style={{ color: Colors.DIM_GREY }}>
              <span style={{ fontWeight: 'bold', color: Colors.DIM_GREY }}>Payment Due:</span>{' '}
              {getOrderPaymeentDue(order)}
            </Typography>

            {!!order.total_price?.taxes &&
              order.total_price.taxes.map(tax => (
                <Typography style={{ color: Colors.DIM_GREY }}>
                  {tax.name?.toUpperCase()}: {tax?.amount?.toFixed(2) ?? '0.00'}
                </Typography>
              ))}
          </Box>
          <Box
            style={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Input
              value={amountToPay}
              onChange={event => {
                setAmountToPay(event.target.value)
              }}
            />
            <DropDown
              items={paymentTypes}
              selectedItem={'Pay'}
              onChangeItem={item => {
                if (!+amountToPay) retrun
                onPressPay({ order, paymentType: item, paymentValue: +amountToPay })
              }}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
