import React from 'react'

import {
  Paper,
  Table,
  TableBody,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';

import TableHeader from './tableHeader'
import TableRow from './tableRow'

import useStyles from './styles'

const TableComponent = (props) => {
  const classes = useStyles()
  const { 
    expenses, 
    isLoadingExpenses, 
    currentPage, 
    onVoidExpense, 
    onUpdateExpense,
    onAddFiles
  } = props

  return (
    <Table>
      {isLoadingExpenses && currentPage === 1
        ?
        <div>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </div>
        :
        <>
          <TableHeader />
          <TableBody>
            {expenses.map((item) => (
              <TableRow
                key={item.id}
                item={item}
                onVoidExpense={onVoidExpense}
                onUpdateExpense={onUpdateExpense}
                onAddFiles={onAddFiles}
              />
            ))}
          </TableBody>
        </>
      }
    </Table>
  )
}

export default TableComponent