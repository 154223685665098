import React from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash'

import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import { Search, ExpandMore } from '@material-ui/icons';
import TextFiled from '@/components/textField';
import { Colors } from '@/theme';
import Button from '@/components/button';
import Paper from '@material-ui/core/Paper';

import { GENDERS_FOR_STYLE } from '../helper'
import useStyles from './styles';

const FormHeader = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    genders,
    onChangeGenders,
    onChangeSearch,
    onAdd
  } = props


  return (
    <Grid item xs={12}>
      <Paper style={{ width: '100%' }}>
        <Grid
          container
          alignItems='center'
          spacing={2}
          className={classes.paperContent}
        >
          <Grid item xs={12} sm={4} lg={8}>
            <TextFiled
              placeholder={t('styles.enter_style_name')}
              className={classes.searchInput}
              onChange={debounce((e) => onChangeSearch(e.target.value), 1000)}
              startAdornment={
                <Search
                  style={{ color: Colors.GRAY_BORDER1 }}
                  width={18}
                  height={18}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={4} lg={2} style={{ padding: '4px 12px' }}>
            <Select
              multiple
              disableUnderline
              IconComponent={ExpandMore}
              displayEmpty
              renderValue={() => genders.length === GENDERS_FOR_STYLE.length
                ? t('styles.all_genders')
                : genders.join(', ')
              }
              fullWidth
              className={classes.filterInput}
              value={genders}
              onChange={(event) => onChangeGenders(event.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {GENDERS_FOR_STYLE.map((item, id) => (
                <MenuItem key={`order-payment-state-${id}`} value={item}>
                  <GreenCheckbox
                    color='default'
                    checked={genders.includes(item)}
                    size='small'
                  />
                  <Typography className={classes.titleText}>
                    {item}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12} sm={4} lg={2}>
            <Grid container justify="flex-end">
              <Button
                className={classes.addButton}
                color="primary"
                variant="contained"
                onClick={onAdd}
              >
                {t('button_group.add_new')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const GreenCheckbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default FormHeader;
