import { gql } from '@apollo/client';

export const GET_SEGMENTS = gql`
  query getAudiences {
    audiences {
          id
          name
          description
          value
      }
   }
`;

export const ADD_NEW_SEGMENT = gql`
  mutation($input: [AudienceInput!]!) {
    addAudiences(input: $input) {
        id
        name
        description
        value
    }
}
`;

export const UPDATE_SEGMENT = gql`
  mutation($input: [AudienceInput!]!) {
    addAudiences(input: $input) {
        id
        name
        description
        value
    }
}
`;

export const DELETE_SEGMENT = gql`
  mutation($input: [AudienceInput!]!) {
    addAudiences(input: $input) {
        id
        name
    }
}
`;

export const GET_GLOBAL_DATASETS = gql`
 query getGlobalDatasets {
   globalDatasets {
     audience_rules
    } 
  }
`;
