import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.grayFont16,
    },
    value: {
      ...CommonFonts.grayFont18,
    },
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    box: {
      // marginBottom: theme.spacing(10),
      cursor: 'pointer',
      // [theme.breakpoints.up('sm')]: {
      //   marginRight: theme.spacing(25),
      // },
      // [theme.breakpoints.down('sm')]: {
      //   marginBottom: theme.spacing(1),
      // },
    },
    addAction: {
      textTransform: 'capitalize',
      cursor: 'pointer',
      color: '#1174F2',
    },
    editIcon: {
      marginLeft: theme.spacing(2),
    },
    storeTitle: {
      letterSpacing: '0.15px',
      textAlign: 'left',
      color: Colors.GRAY_DARK,
      opacity: '1',
      fontSize: 20,
      fontWeight: 500,
    },
    nickname: {
      fontSize: 20,
      paddingLeft: theme.spacing(1),
      fill: Colors.DIM_GREY,
    },
  })
);

export default useStyles;
