import I18n from '@/i18n'

export const generateNewAddon = (product) => {
  return {
    id: '',
    name: product.name,
    inventory_id: product.id,
    bar_code: product.bar_code,
    measure_amount: 1,
    measure_type: product.measure.type || 'qty',
    measure_id: product.measure.id,
    default: false,
    force_charge: false,
    extra: 1,
    fixed_price: '0.00',
  }
}

export const MEASURE_STR = {
  qty: I18n.t('addons.qty'),
  g: I18n.t('addons.weight_g'),
  kg: I18n.t('addons.weight_kg'),
  cm: I18n.t('addons.cm'),
  in: I18n.t('addons.inch'),
  m: I18n.t('addons.meter'),
  yd: I18n.t('addons.yard'),
  l: I18n.t('addons.litre'),
  ml: I18n.t('addons.ml'),
}