import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ProductSearchInput from '@/components/productSearchInput'

import useStyles from './styles'

const SelectProduct = ({ product, onSelectProduct, onRemoveProduct }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box>
      {product
        ?
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box>
            <Typography className={classes.titleText}>
              {product.name}
            </Typography>
            <Typography className={classes.titleText}>
              {`${t('workboard.product_intelligence.barcode')}: ${product.bar_code}`}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={onRemoveProduct} className={classes.closeButton}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </Box>
        </Box>
        :
        <ProductSearchInput
          onSelectProduct={onSelectProduct}
        />
      }
    </Box>
  )
}

export default SelectProduct