import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { find, isEmpty } from 'lodash'
import { Formik } from 'formik';
import * as Yup from 'yup';
import I18n from '@/i18n';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Box
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import NameDescription from './nameDescription'
import RulesOptions from './rulesOptions'

import { useMutation } from '@apollo/client';

import {
  ADD_ADDONS,
  REMOVE_ADDONS,
  ADD_ADDON_GROUP
} from '@/services/inventoryService'

import useStyles from './styles';

const validationSchema = Yup.object().shape({
  group: Yup.string()
    .required(I18n.t('addons.group_name_required')),
});

export default function ResponsiveDialog({
  open,
  handleClose,
  addonGroup = null,
  defaultTabId = 0,
  onSuccess
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [groupData, setGroupData] = useState({
    id: '',
    group: '',
    description: '',
    mandatory: false,
    default_all: false,
    multi_selection: false,
    allow_free: 0,
    limit_free_options: false,
  })

  const [addons, setAddons] = useState([])

  const [tabId, setTabId] = useState(defaultTabId)

  const [hasAddonsMutationCompleted, setHasAddonsMutationCompleted] = useState(false)
  const [hasRemoveAddonsMutationCompleted, setHasRemoveAddonsMutationCompleted] = useState(false)
  const [hasGroupMutationCompleted, setHasGroupMutationCompleted] = useState(false)

  const tabs = [
    {
      id: 0,
      label: t('addons.name_description'),
    },
    {
      id: 1,
      label: t('addons.rules_options'),
    },
  ]

  const isFirstTab = tabId === 0
  const isLastTab = tabId === tabs.length - 1

  const [addUpdateAddonsMutation, { loading: isAddingUpdatingAddons }] = useMutation(ADD_ADDONS, {
    onCompleted: (data) => {
      setHasAddonsMutationCompleted(true)
    },
    onError: (error) => {

    }
  })

  const [removeAddonsMutation, { loading: isRemovingAddons }] = useMutation(REMOVE_ADDONS, {
    onCompleted: (data) => {
      setHasRemoveAddonsMutationCompleted(true)
    },
    onError: (error) => {

    }
  })

  const [updateAddonGroupMutation, { loading: isUpdatingAddonGroup }] = useMutation(ADD_ADDON_GROUP, {
    onCompleted: (data) => {
      setHasGroupMutationCompleted(true)
    },
    onError: (error) => {

    }
  })

  useEffect(() => {
    if (hasAddonsMutationCompleted && hasGroupMutationCompleted && hasRemoveAddonsMutationCompleted) {
      enqueueSnackbar(addonGroup
        ? t('addons.update_addon_success')
        : t('addons.add_addon_success')
      )
      onSuccess()
      handleClose()
    }
  }, [hasAddonsMutationCompleted, hasGroupMutationCompleted, hasRemoveAddonsMutationCompleted])

  useEffect(() => {
    if (addonGroup) {
      setGroupData({
        id: addonGroup.id,
        group: addonGroup.group,
        description: addonGroup.description,
        mandatory: addonGroup.mandatory,
        default_all: addonGroup.default_all,
        multi_selection: addonGroup.multi_selection,
        allow_free: addonGroup.allow_free,
        limit_free_options: !!addonGroup.allow_free
      })
      setAddons(addonGroup.addons)
    }
  }, [addonGroup])

  const onChangeTab = (event, value) => {
    setTabId(value)
  }

  const updateGroupData = (key, value) => {
    setGroupData({
      ...groupData,
      [key]: value
    })
  }

  const updateAddons = (id, addon) => {
    setAddons(addons.map((item, index) => id === index ? addon : addons[index]))
  }

  const addAddons = (addon) => {
    setAddons([...addons, addon])
  }

  const removeAddons = (id) => {
    setAddons(addons.filter((item, index) => id !== index))
  }

  const onAddUpdateAddon = () => {
    if (isEmpty(addons)) {
      enqueueSnackbar(t('addons.add_one_addon'))
      return
    }

    const groupIdPara = groupData.id ? { id: groupData.id } : {} // if its update then add id parameter.
    const groupParas = {
      ...groupIdPara,
      group: groupData.group,
      allow_free: groupData.limit_free_options ? +groupData.allow_free : 0,
      mandatory: groupData.mandatory,
      multi_selection: groupData.multi_selection,
      default_all: groupData.default_all,
      description: groupData.description
    }

    let removedAddonIDs = [];
    addonGroup?.addons?.forEach((addon) => {
      if (!find(addons, { id: addon.id })) {
        removedAddonIDs.push(addon.id);
      }
    });

    addUpdateAddonsMutation({
      variables: {
        input: addons.map(addon => {
          const addonIdPara = addon.id ? { id: addon.id } : {} // if its update then add id parameter.

          return {
            ...addonIdPara,
            inventory: {
              id: addon.inventory_id,
            },
            measure: {
              id: addon.measure_id,
              amount: +addon.measure_amount,
            },
            group: {
              ...groupParas
            },
            force_charge: addon.force_charge,
            extra: +addon.extra,
            default: addon.default,
            fixed_price: +addon.fixed_price,
            override_price: false
          }
        })
      }
    })

    if (groupData.id) { // update addonGroup
      updateAddonGroupMutation({
        variables: {
          input: {
            ...groupParas
          }
        }
      })
    } else {
      setHasGroupMutationCompleted(true)
    }

    if (!isEmpty(removedAddonIDs)) {
      removeAddonsMutation({
        variables: {
          input: {
            ids: removedAddonIDs
          }
        }
      })
    } else {
      setHasRemoveAddonsMutationCompleted(true)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Formik
        initialValues={{
          group: groupData.group
        }}
        validationSchema={validationSchema}
        onSubmit={onAddUpdateAddon}
      >
        {({
          handleBlur,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
          setFieldTouched
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
          >
            <DialogTitle id="alert-dialog-slide-title">
              <Grid
                container
                alignItems="center"
                justify="space-between"
                alignContent="center"
              >
                <Grid item xs={1} />
                <Grid className={classes.headerText} item xs={10}>
                  <span style={{ fontSize: 18 }}>
                    {groupData.group || t('addons.group_name')}
                  </span>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'right' }}>
                  <IconButton
                    onClick={handleClose}
                    className={classes.closeButton}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
              <div className={classes.rootDialog}>
                <Tabs
                  className={classes.tabs}
                  value={tabId}
                  onChange={onChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {tabs.map((item) => (
                    <Tab
                      className={classes.tab}
                      style={{ fontSize: 18 }}
                      key={item.id}
                      label={item.label}
                    />
                  ))}
                </Tabs>

                <TabPanel value={tabId} index={0}>
                  <NameDescription
                    groupData={groupData}
                    updateGroupData={updateGroupData}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </TabPanel>
                <TabPanel value={tabId} index={1}>
                  <RulesOptions
                    groupData={groupData}
                    addons={addons}
                    updateGroupData={updateGroupData}
                    updateAddons={updateAddons}
                    addAddons={addAddons}
                    removeAddons={removeAddons}
                  />
                </TabPanel>
              </div>
            </DialogContent>

            <DialogActions style={{ paddingRight: 30 }}>
              <Button
                color="primary"
                onClick={handleClose}
                className={classes.cancelButton}
              >
                {t('button_group.cancel')}
              </Button>
              {!isFirstTab &&
                <Button
                  color="primary"
                  onClick={() => setTabId(tabId - 1)}
                >
                  {t('button_group.back')}
                </Button>
              }
              {!isLastTab &&
                <Button
                  color="primary"
                  onClick={() => setTabId(tabId + 1)}
                  disabled={!groupData.group}
                >
                  {t('button_group.next')}
                </Button>
              }
              {isLastTab &&
                <Button type='submit' color="primary">
                  {addonGroup
                    ? t('button_group.update')
                    : t('button_group.add')
                  }
                </Button>
              }
            </DialogActions>
          </form>
        )}
      </Formik>

      {(isAddingUpdatingAddons ||
        isUpdatingAddonGroup ||
        isRemovingAddons) &&
        <PreLoader size={25} />
      }
    </Dialog>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ paddingTop: 40 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};