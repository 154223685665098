import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    boxPaymentMethods: {
      backgroundColor: Colors.WHITE,
      padding: theme.spacing(5),
      borderRadius: theme.spacing(1),
    },
    boxTable: {
      marginTop: theme.spacing(7),
    },
    title: {
      ...CommonFonts.darkFont16,
      fontWeight: '600',
      marginTop: theme.spacing(2),
    },
    tableText: {
      ...CommonFonts.darkFont18,
    },
    moreIcon: {
      padding: theme.spacing(1),
    },
    noPaymentMethodAvailable: {
      ...CommonFonts.darkFont16,
      marginTop: theme.spacing(6),
    },
    headerTableText: {
      ...CommonFonts.darkFont14,
    },
    boxFullName: {
      wordBreak: 'break-word',
      padding: theme.spacing(2.5, 0),
    },
    setDefault: {
      color: '#1174F2',
      ...CommonFonts.blueFont18,
      cursor: 'pointer',
    },
    default: {
      color: '#1174F2',
      ...CommonFonts.blueFont18,
      opacity: 0.5,
      cursor: 'default',
    },
    delete: {
      color: '#1174F2',
      ...CommonFonts.blueFont18,
      cursor: 'pointer',
    },
    addNew: {
      maxWidth: theme.spacing(40),
      padding: theme.spacing(3, 10),
      ...CommonFonts.whiteFont14,
    },
    addPaymentMethod: {
      marginTop: theme.spacing(4),
      ...CommonFonts.darkFont18,
      fontWeight: '500',
      cursor: 'pointer',
      color: '#1174F2',
    },
  })
);

export default useStyles;
