export const BEST_SELLER_PRODUCTS_LIMIT = 50
export const BEST_SELLER_SORT_TYPE = {
  GROSS: 'REVENUE',
  ITEM: 'ITEM',
  NET: 'NET',
  TAX: 'TAX',
}
export const SORT_DIRECTION = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING'
}