import React, { useState } from 'react';
import useStyles from './styles';
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Paper,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import Button from '@/components/button';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';
import images from '@/assets/images';
import { gql, useQuery, useMutation } from '@apollo/client';
import {
  GET_RESOURCE,
  ADD_RESOURCE,
} from '@/services/subscriptionService';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import StripeCheckoutForm from '@/pages/userManagement/stripe/checkoutForm';
import PreLoader from '@/components/preLoader';

const resouceQueries = gql`
  ${GET_RESOURCE}
`;

const PaymentMethodsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isStripeVisible, setStripeVisible] = useState(false);

  const {
    loading: loadingResource,
    data: resourceData,
    refetch,
  } = useQuery(resouceQueries, {
    fetchPolicy: 'no-cache',
  });
  const resources = resourceData?.resources;

  const [mutateResource] = useMutation(
    gql`
      ${ADD_RESOURCE}
    `
  );

  const setDefaultMethod = (resource) => {
    setIsLoading(true);
    mutateResource({
      variables: {
        input: {
          id: resource.id,
          is_default: true,
        },
      },
    })
      .then(async (res) => {
        if (res) {
          await refetch();
          setIsLoading(false);
          enqueueSnackbar(
            t('subscriptions.default_payment_method_changed')
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  const onDelete = (resource) => {
    setIsLoading(true);
    mutateResource({
      variables: {
        input: {
          id: resource.id,
          flag: 'delete',
        },
      },
    })
      .then(async (res) => {
        if (res) {
          await refetch();
          setIsLoading(false);
          enqueueSnackbar(t('subscriptions.payment_method_delete'));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <Box>
        <div className={classes.boxPaymentMethods}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.title}>
              {t('subscriptions.payment_methods_title')}
            </Typography>
            <Button
              className={classes.addNew}
              color="primary"
              onClick={() => setStripeVisible(true)}
            >
              {t('subscriptions.add_new')}
            </Button>
          </Box>
          {!loadingResource && _.isEmpty(resources) ? (
            <Box
              paddingY={50}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <img
                src={images.noPaymentMethod}
                width={151}
                height={151}
              />
              <Typography
                className={classes.noPaymentMethodAvailable}
              >
                {t('subscriptions.no_payment_methods_available')}
              </Typography>
              <Typography
                onClick={() => setStripeVisible(true)}
                className={classes.addPaymentMethod}
              >
                {t('subscriptions.add_payment_method')}
              </Typography>
            </Box>
          ) : (
            <Paper elevation={0} style={{ overflowX: 'auto' }}>
              <Table className={classes.boxTable}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{ width: '35%' }}
                      className={classes.headerTableText}
                    >
                      {t('subscriptions.card_number')}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ width: '35%' }}
                      className={classes.headerTableText}
                    >
                      {t('subscriptions.type')}
                    </StyledTableCell>
                    <StyledTableCell />
                    <StyledTableCell />
                  </TableRow>
                </TableHead>

                {loadingResource ? (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: 'none' }}
                        colSpan={4}
                      >
                        <Box
                          marginTop={10}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CircularProgress size={35} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {resources?.map((row) => (
                      <TableRow hover key={row.id}>
                        <StyledTableCell>
                          <div className={classes.boxFullName}>
                            <Typography className={classes.tableText}>
                              {`XXXX XXXX XXXX ${row?.card?.last4}`}
                            </Typography>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className={classes.boxFullName}>
                            <Typography className={classes.tableText}>
                              {row?.card?.brand}
                            </Typography>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className={classes.boxFullName}>
                            <Typography
                              onClick={() =>
                                !row.is_default
                                  ? setDefaultMethod(row)
                                  : {}
                              }
                              className={
                                row.is_default
                                  ? classes.default
                                  : classes.setDefault
                              }
                            >
                              {row.is_default
                                ? t('subscriptions.default')
                                : t('subscriptions.set_as_default')}
                            </Typography>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className={classes.boxFullName}>
                            <Typography
                              onClick={() => onDelete(row)}
                              className={classes.delete}
                            >
                              {t('subscriptions.delete')}
                            </Typography>
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </Paper>
          )}
        </div>
        {isLoading && <PreLoader size={30} />}
      </Box>
      <StripeCheckoutForm
        isVisible={isStripeVisible}
        onClose={() => setStripeVisible(false)}
        onFetch={refetch}
      />
    </ErrorBoundary>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F3F5F7',
    borderBottom: 'none',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default PaymentMethodsPage;
