import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { isMobile } from 'react-device-detect'
import moment from 'moment'

import { Box, Grid, Typography } from '@material-ui/core'
import { BarChart, Menu } from '@material-ui/icons'
import PreLoader from '@/components/preLoader'
import LayoutSelect from '../productSalesAnalysis/layoutSelect'
import Chart from './chart'
import Table from './table'
import NoResults from '../productSalesAnalysis/noResults'

import { gql, useQuery } from '@apollo/client'
import { GET_TOP_WORKBOARD_INSIGHTS } from '@/services/widgetService'

import { selectSelectedRegion, selectSelectedStore } from '@/store/modules/store/selectors'

import {
  BEST_SELLER_CATEGORIES_LIMIT,
  BEST_SELLER_SORT_TYPE,
  SORT_DIRECTION
} from './helper'
import useStyles from './styles';

const LAYOUT = {
  CHART: 'CHART',
  TABLE: 'TABLE'
}

const CategorySalesAnalysis = (props) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const { startTime, endTime } = props

  const selectedRegion = useSelector(selectSelectedRegion);
  const selectedStore = useSelector(selectSelectedStore)

  const [layout, setLayout] = useState(LAYOUT.CHART)
  const [sortTypeOfCategories, setSortTypeOfCategories] = useState(BEST_SELLER_SORT_TYPE.GROSS)

  const [sortDirectionOfCategories, setSortDirectionOfCategories] = useState({
    [BEST_SELLER_SORT_TYPE.GROSS]: SORT_DIRECTION.DESCENDING,
    [BEST_SELLER_SORT_TYPE.ITEM]: SORT_DIRECTION.DESCENDING,
    [BEST_SELLER_SORT_TYPE.NET]: SORT_DIRECTION.DESCENDING,
    [BEST_SELLER_SORT_TYPE.TAX]: SORT_DIRECTION.DESCENDING,
  })

  const { data, loading: isLoadingWorkboardInsights } = useQuery(gql`${GET_TOP_WORKBOARD_INSIGHTS}`, {
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        storeId: selectedRegion ? selectedRegion.id : selectedStore.id
      },
    },
    variables: {
      start: moment(startTime).format('YYYY-MM-DD'),
      end: moment(endTime).format('YYYY-MM-DD'),
      sortBy: BEST_SELLER_SORT_TYPE.GROSS,
      limit: BEST_SELLER_CATEGORIES_LIMIT
    }
  })

  const categories = data?.insights?.best_sellers?.categories || []

  const bestSellingCategories = useMemo(() => {
    let tempCategories = [...categories]
    tempCategories.sort((a, b) => {
      var diff;
      const aTotalSaleNet = a.total_sale.net + (+a.total_sale.promotion)
      const aTotalSaleTax = a.total_sale.tax + (+a.total_sale.promotion_tax)
      const bTotalSaleNet = b.total_sale.net + (+b.total_sale.promotion)
      const bTotalSaleTax = b.total_sale.tax + (+b.total_sale.promotion_tax)
      if (sortTypeOfCategories === BEST_SELLER_SORT_TYPE.ITEM) {
        diff = a.item_sold - b.item_sold
      } else if (sortTypeOfCategories === BEST_SELLER_SORT_TYPE.NET) {
        diff = aTotalSaleNet - bTotalSaleNet
      } else if (sortTypeOfCategories === BEST_SELLER_SORT_TYPE.TAX) {
        diff = aTotalSaleTax - bTotalSaleTax
      } else {
        diff = (aTotalSaleNet + aTotalSaleTax) - (bTotalSaleNet + bTotalSaleTax)
      }
      if (sortDirectionOfCategories[sortTypeOfCategories] === SORT_DIRECTION.DESCENDING) diff *= -1
      return diff
    })

    return tempCategories
  }, [sortTypeOfCategories, sortDirectionOfCategories, categories])

  const content = {
    [LAYOUT.CHART]: (
      <Chart
        items={bestSellingCategories}
      />
    ),
    [LAYOUT.TABLE]: (
      <Table
        items={bestSellingCategories}
        sortType={sortTypeOfCategories}
        sortDirection={sortDirectionOfCategories}
        onPressSort={(sortType) => {
          setSortTypeOfCategories(sortType)
          setSortDirectionOfCategories({
            ...sortDirectionOfCategories,
            [sortType]: sortDirectionOfCategories[sortType] === SORT_DIRECTION.ASCENDING
              ? SORT_DIRECTION.DESCENDING
              : SORT_DIRECTION.ASCENDING
          })
        }}
      />
    )
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.titleText}>
        {t('globalWidgets.category_sales_analysis')}
      </Typography>

      {isEmpty(bestSellingCategories) && !isLoadingWorkboardInsights &&
        <NoResults title={t('workboard.no_data')} />
      }
      {!isEmpty(bestSellingCategories) &&
        <React.Fragment>
          <Box
            display='flex'
            justifyContent={isMobile ? 'center' : 'flex-end'}
            className={classes.layoutSelectContainer}
          >
            <Box>
              <Grid container className={classes.layoutsWrapper}>
                <Grid item>
                  <LayoutSelect
                    isSelected={layout === LAYOUT.CHART}
                    icon={BarChart}
                    layout={LAYOUT.CHART}
                    onClickLayout={(value) => setLayout(value)}
                  />
                </Grid>
                <Grid item>
                  <LayoutSelect
                    isSelected={layout === LAYOUT.TABLE}
                    icon={Menu}
                    layout={LAYOUT.TABLE}
                    onClickLayout={(value) => setLayout(value)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className={classes.contentContainer}>
            {content[layout]}
          </Box>
        </React.Fragment>
      }
      {(isLoadingWorkboardInsights) && <PreLoader size={30} />}
    </Box>
  )
}

CategorySalesAnalysis.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
}

export default React.memo(CategorySalesAnalysis)