import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '@/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(3.25),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },

    // padding: theme.spacing(6)
  },
  cardContent: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  cardTitle: {
    fontSize: 20,
    letterSpacing: '0.15px',
    color: Colors.DIM_GREY,
    opacity: '1',
    [theme.breakpoints.down('860')]: {
      fontSize: 17,
    },
    fontWeight: 500,
  },
  storeTitle: {
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: Colors.GRAY_DARK,
    opacity: '1',
    fontSize: 20,
    fontWeight: 500,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  viewStoresButton: {
    fontSize: 20,
    textTransform: 'none'
  },
  storeOverviewCardContent: {
    paddingTop: 34,
    paddingRight: 50,
    paddingBottom: '93px !important',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '40px !important',
      paddingRight: 24,
    },
  },
  cameraWrapper: {
    position: 'absolute',
    right: -15,
    bottom: -5,
    width: 38,
    height: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    backgroundColor: Colors.WHITE,
    boxShadow: '0px 3px 6px #7395B229',
    cursor: 'pointer'
  },
  camera: {
    color: '#939da8',
    fontSize: 20
  },
  disabled: {
    opacity: 0.4,
    cursor: 'auto',
  }
}));

export default useStyles;
