import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 300,
  },
  autoCompleteRoot: { top: 'calc(50% - 15px)' },
  popupIndicatorOpen: {
    transform: 'unset',
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(3),
  },
  firstRow: {
    fontSize: 18,
  },
  secondRow: {
    fontSize: 16,
  },
  noOptionsButton: {
    cursor: 'pointer',
    letterSpacing: '0.56px',
    color: '#1174F2',
    opacity: '1',
    fontSize: 18,
  },
  noDisplay: {
    display: 'none'
  },
  display: {
    display: 'inherit'
  }
}));

export default useStyles;
