/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { GET_GLOBAL_CATEGORIES } from '@/services/inventoryService';
import { useQuery } from '@apollo/client';
import { Box, Button } from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import useStyles from '../components/styles';
import { find } from 'lodash';

const filter = createFilterOptions();

export default function FreeSolo({
  setMainCategorySelected,
  defaultValue,
  defaultCategoryName,
  allCategories
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [categories, setcategories] = useState([]);
  const [step, setstep] = useState(0);
  const [valueSelected, setvalueSelected] = useState();

  const { loading } = useQuery(GET_GLOBAL_CATEGORIES, {
    onCompleted: (data) => {
      const globalCategories = data.globalCategory || []

      setcategories(globalCategories.filter(category => !find(allCategories, { id: category.id })));
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (defaultCategoryName) {
      setvalueSelected(defaultCategoryName.name);
      setstep(0);
    }
  }, [defaultCategoryName, categories]);

  return (
    <div style={{ width: '100%' }}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12}>
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            style={{ height: 60 }}
          >
            <Grid item xs={12}>
              <span className={classes.generalInfoItem}>
                {t('categories.category_name')}
              </span>
            </Grid>
            {step === 0 && (
              <Grid
                item
                xs={12}
                style={{ position: 'relative' }}
                onClick={() => !valueSelected
                  ? setstep(1)
                  : {}
                }
              >
                <Typography
                  className={
                    classNames(classes.label, {
                      [classes.emptyLabel]: !valueSelected
                    })
                  }
                  onClick={() => setstep(1)}
                >
                  {!valueSelected ? t('categories.select') : valueSelected}

                  {valueSelected &&
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => setstep(1)}
                    >
                      <CreateIcon fontSize='small' />
                    </IconButton>
                  }
                </Typography>
              </Grid>
            )}
            {step === 1 && (
              <Grid item xs={12} style={{ marginTop: '-10px' }}>
                <Autocomplete
                  freeSolo
                  loading={loading}
                  defaultValue={defaultCategoryName}
                  id="free-solo-2-demo"
                  disableClearable
                  options={categories || []}
                  openOnFocus={defaultValue ? false : true}
                  blurOnSelect
                  onChange={(event, value) => {
                    setMainCategorySelected(value);
                    setvalueSelected(value.name);
                  }}
                  onBlur={() => setstep(0)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus={defaultValue ? false : true}
                      margin="normal"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { inputValue }) => {
                    const matches = match(option.name, inputValue);
                    const parts = parse(option.name, matches);

                    if (!option.isNewButton) {
                      return (
                        <Box paddingX={2} paddingY={3}>
                          {inputValue?.length > 2 ? (
                            <>
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    fontWeight: part.highlight
                                      ? 600
                                      : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </>
                          ) : (
                            <span>{option.name}</span>
                          )}
                        </Box>
                      );
                    }
                    return (
                      <div style={{ marginTop: 40 }}>
                        <Box
                          className={classes.addButtonWrapper}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                          paddingY={3}
                        >
                          <span
                            style={{
                              fontWeight: 600,
                              marginLeft: 16,
                            }}
                          >
                            {inputValue}
                          </span>
                          <Button
                            color="primary"
                            className={classes.addButton}
                            onClick={() => addNewCategory(inputValue)}
                          >
                            {`+ ${t('button_group.add_new')}`}
                          </Button>
                        </Box>
                      </div>
                    );
                  }}
                  filterOptions={(options, params) => {
                    if (params.inputValue?.length > 2) {
                      const filtered = filter(options, params);
                      if (
                        params.inputValue !== '' &&
                        !filtered.includes(params.inputValue)
                      ) {
                        filtered.push({
                          name: params.inputValue,
                          isNewButton: true,
                        });
                      }
                      return filtered;
                    }
                    return options;
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
