import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@/components/textField';
import { UPDATE_MERCHANT_SETTINGS, UPDATE_STORE_ORDER } from '@/services/deliveryService';

import useStyles from './styles';

const UpdateDialog = ({
                        dialogTitle,
                        data = {},
                        open,
                        actionType,
                        handleClose,
                        setRefetchData,
                        dialogDescription
                      }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [confirmedValue, setConfirmedValue] = useState(actionType === 'rate' ?
    data.rate
    : data.store.settings.delivery.hourly_capacity);

  const [UpdateData, { loading: updating }] =
    useMutation(actionType === 'rate' ? UPDATE_MERCHANT_SETTINGS : UPDATE_STORE_ORDER, {
      onCompleted: (response) => {
        setRefetchData();
        enqueueSnackbar(actionType === 'rate' ?
          t('modules.delivery.revenue_updated_successfully') :
          t('modules.delivery.capacity_updated_successfully'));
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    });

  // Save updated data
  const updateRateData = () => {
    const payload = {
      variables: {
        input: {
          delivery: {
            partners: {
              id: data.id,
              rate: Number(confirmedValue)
            }
          }
        }
      }
    };
    handleClose();
    return UpdateData(payload);
  };
  const updateHoursData = () => {
    const payload = {
      variables: {
        store: {
          id: data?.store.id,
          settings: {
            delivery: {
              hourly_capacity: Number(confirmedValue)
            }
          }
        }
      }
    };
    handleClose();
    return UpdateData(payload);
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            alignContent="center"
          >
            <Grid className={classes.headerText} item xs={11}>
              <Typography variant='subtitle2' gutterBottom={'true'} align='center'>
                {dialogTitle}
              </Typography>
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2' gutterBottom={'true'} align='center'>
              {dialogDescription}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" alignContent="center">
            <Grid xs={12} item row className={classes.gridItem}>
              <TextField
                id="standard-basic"
                focused
                name="name"
                fullWidth
                type="number"
                placeholder={confirmedValue}
                onInput={(e) => {
                  e.target.value = Math.max(1, parseInt(e.target.value)).toString().slice(0, 12);
                }}
                onChange={(e) => setConfirmedValue(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: 30 }}>
          <Button
            color="default"
            onClick={handleClose}
            className={classes.cancelButton}
          >
            {t('modules.delivery.cancel')}
          </Button>
          <Button
            disabled={data.base_rate > confirmedValue ||
            confirmedValue === data.rate ||
            confirmedValue === data.store?.settings.delivery.hourly_capacity}
            color="primary"
            onClick={actionType === 'rate' ? updateRateData : updateHoursData}
          >
            {t('modules.delivery.update')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateDialog;