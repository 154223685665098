import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(17, 0, 80, 0),
    },
    findText: {
      ...CommonFonts.darkFont16,
      fontWeight: 500,
      textAlign: 'center',
    },
    inputContainer: {
      maxWidth: 790,
      margin: 'auto',
      marginTop: theme.spacing(13)
    },
    searchInput: {
      '& .MuiInput-root': {
        padding: '0px 10px',
        ...CommonFonts.darkFont18,
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'none'
      }
    },
    noMatchingText: {
      ...CommonFonts.grayFont18,
      textAlign: 'center',
    },
    searchIcon: {
      position: 'absolute',
      left: 0,
      top: 16,
      color: Colors.GRAY_BORDER3
    },
    noOptionsButton: {
      cursor: 'pointer',
      letterSpacing: '0.56px',
      ...CommonFonts.blueFont18
    },
    label: {
      ...CommonFonts.darkFont18,
      padding: theme.spacing(1, 0),
      cursor: 'pointer',
      borderBottom: '1px solid rgba(0, 0, 0, 0)',
      '&:hover': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      },
      '& .MuiIconButton-root': {
        color: '#939DA8'
      },
      '&:hover .MuiIconButton-root': {
        color: '#505C69'
      },
    },
    emptyLabel: {
      ...CommonFonts.blueFont18,
      padding: theme.spacing(1, 0),
      cursor: 'pointer',
      borderBottom: '1px solid rgba(0, 0, 0, 0)',
      '&:hover': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      }
    },
  })
)

export default useStyles
