import {  withStyles } from '@material-ui/core/styles'
import { Colors } from '@/theme'
import TextField from '@material-ui/core/TextField';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: Colors.GRAY_DARK,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: Colors.BLUE,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: Colors.GRAY_BORDER1,
    },
    '& .Mui-error.MuiInput-underline:after': {
      borderBottomColor: Colors.RED,
    },
    '& .Mui-error.MuiInput-underline:before': {
      borderBottomColor: Colors.RED,
    },
    '& .Mui-error.MuiInput-underline:hover:not(.Mui-disabled):after': {
      borderBottom: `2px solid ${Colors.RED}`,
    },
    '& .Mui-error.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${Colors.RED}`,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${Colors.BLUE}`,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
      borderBottom: `2px solid ${Colors.BLUE}`,
    },
    '& > div > input': {
      '&:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 30px white inset !important'
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor:  Colors.BLUE,
      },
      '&:hover fieldset': {
        borderColor:  Colors.BLUE,
      },
      '&.Mui-focused fieldset': {
        borderColor:  Colors.BLUE,
      },
    },
  },
})(TextField);

export default CssTextField
