import { gql } from '@apollo/client';

export const GET_SECRETS = gql`
  query getSecrets {
    clients {
        id
        name
        secret
    }
   }
`;

export const GET_MERCHANTS = gql`
  query getSecrets {
   merchant {
    id
    stores {
      name
      id
    }
   }
  }
`;


export const ADD_NEW_SECRET = gql`
 mutation($input: ClientInput!) {
    addClient(input: $input) {
        id
        name
    }
}
`;

export const REVOKE_SECRET = gql`
 mutation($id: String!) {
    revokeClient(id: $id)
}
`;

