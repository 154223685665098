import React from 'react'
import { find, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next';

import {
  Box,
  Typography,
  Grid,
  IconButton,
  Button
} from '@material-ui/core'

import DateTimePicker from '@/components/dateTimePicker'
import Input from '../components/input'
import TimePicker from '@/components/muiTimePicker'
import SingleCheckboxInput from '@/components/singleCheckbox';
import CategorySearchInput from '@/components/categorySearchInput'
import ProductSearchInput from '@/components/productSearchInput';
import OptionItem from '../components/optionItem'

import { Colors, CommonFonts } from '@/theme';
import useStyles from '../styles'

const Bogof = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    promotion,
    defaultPromotion,
    onChangePromotion,
    onAddUpdatePromotion,
    handleClose
  } = props

  const onChangeCategories = (optionIndex, categories) => {
    onChangePromotion({
      ...promotion,
      options: promotion.options.map((option, index) => index === optionIndex
        ? { ...option, categories }
        : option
      )
    })
  }

  const onChangeProducts = (optionIndex, products) => {
    onChangePromotion({
      ...promotion,
      options: promotion.options.map((option, index) => index === optionIndex
        ? { ...option, products }
        : option
      )
    })
  }

  const onAddCategory = (category) => {
    if (find(promotion.options[0].categories, { id: category.id })) {
      return
    }
    const newCategories = [...promotion.options[0].categories, category]
    onChangeCategories(0, newCategories)
  }

  const onRemoveCategory = (category) => {
    const newCategories = promotion.options[0].categories.filter(item => item.id !== category.id)
    onChangeCategories(0, newCategories)
  }

  const onAddProduct = (product) => {
    if (find(promotion.options[0].products, { id: product.id })) {
      return
    }
    const newProducts = [...promotion.options[0].products, product]
    onChangeProducts(0, newProducts)
  }

  const onRemoveProduct = (product) => {
    const newProducts = promotion.options[0].products.filter(item => item.id !== product.id)
    onChangeProducts(0, newProducts)
  }

  const passValidations = () => {
    if (!promotion.promo_name) {
      return false
    }
    if (+promotion.item_numbers < 2) {
      return false
    }
    if (promotion.options.some(option => isEmpty(option.products) && isEmpty(option.categories))) {
      return false
    }
    return true
  }

  return (
    <Box>
      <Typography className={classes.boldLabel}>
        {t('promotions.general_info')}
      </Typography>
      <Box marginTop={2}>
        <Grid container spacing={5}>
          <Grid item md={6} sm={6}>
            <Input
              title={t('promotions.promotion_name')}
              emptyLabel={t('add_product.add')}
              value={promotion.promo_name}
              onChange={(value) => {
                onChangePromotion({
                  ...promotion,
                  promo_name: value
                })
              }}
              onBlur={() => { }}
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <Input
              type='number'
              title={t('promotions.no_items_to_get_one_free')}
              emptyLabel={t('add_product.add')}
              value={promotion.item_numbers}
              onChange={(value) => {
                onChangePromotion({
                  ...promotion,
                  item_numbers: value
                })
              }}
              onBlur={() => { }}
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <DateTimePicker
              label={t('promotions.valid_from')}
              labelClass={classes.subTitleText}
              ampm={false}
              format='dd MMM yyyy hh:mm'
              value={promotion.valid_from}
              onChange={(value) => {
                onChangePromotion({
                  ...promotion,
                  valid_from: value
                })
              }}
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <Box position={'relative'}>
              <DateTimePicker
                label={t('promotions.valid_to')}
                labelClass={classes.subTitleText}
                ampm={false}
                format='dd MMM yyyy hh:mm'
                value={promotion.valid_to}
                emptyLabel={t('promotions.until_further_notice')}
                onChange={(value) => {
                  onChangePromotion({
                    ...promotion,
                    valid_to: value
                  })
                }}
              />
              {promotion.valid_to &&
                <Box
                  className={classes.furtherNotice}
                  onClick={() => {
                    onChangePromotion({
                      ...promotion,
                      valid_to: null
                    })
                  }}
                >
                  <Typography className={classes.furtherNoticeText}>
                    {t('promotions.until_further_notice')}
                  </Typography>
                </Box>
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={5} display='flex'>
        <Box marginRight={10}>
          <SingleCheckboxInput
            style={{ paddingBottom: 0 }}
            isShowToast={false}
            value={promotion.add_happy_hour}
            label={t('promotions.add_happy_hour')}
            labelClass={classes.label}
            onChange={(value) => {
              onChangePromotion({
                ...promotion,
                add_happy_hour: value
              })
            }}
          />
        </Box>
        <Box>
          <SingleCheckboxInput
            style={{ paddingBottom: 0 }}
            isShowToast={false}
            value={promotion.mix_match}
            label={t('promotions.mix_match')}
            labelClass={classes.label}
            onChange={(value) => {
              onChangePromotion({
                ...promotion,
                mix_match: value
              })
            }}
          />
        </Box>
      </Box>
      {promotion.add_happy_hour &&
        <Box marginTop={3}>
          <Grid container spacing={5}>
            <Grid item md={3} sm={6}>
              <TimePicker
                label={t('promotions.start_time')}
                labelClass={classes.subTitleText}
                ampm={false}
                value={promotion.start_time}
                onChange={(value) => {
                  onChangePromotion({
                    ...promotion,
                    start_time: value
                  })
                }}
              />
            </Grid>
            <Grid item md={3} sm={6}>
              <TimePicker
                label={t('promotions.end_time')}
                labelClass={classes.subTitleText}
                ampm={false}
                value={promotion.end_time}
                onChange={(value) => {
                  onChangePromotion({
                    ...promotion,
                    end_time: value
                  })
                }}
              />
            </Grid>
          </Grid>
        </Box>
      }
      <Box className={classes.optionsContainer}>
        <Box display='flex'>
          <Box
            flex={1}
            borderRight={`1px solid ${Colors.GRAY_BORDER1_40}`}
            paddingX={4}
          >
            <Typography className={classes.boldLabel} align='center'>
              {t('promotions.select_categories')}
            </Typography>
            <Typography
              style={{ marginTop: 12, minHeight: 65 }}
              className={classes.label}
              align='center'
            >
              {t('promotions.promotion_applied_to_categories')}
            </Typography>
            <Box display='flex' justifyContent='center'>
              <Box width='80%'>
                <CategorySearchInput
                  onSelectCategory={onAddCategory}
                />
              </Box>
            </Box>
            <Box marginTop={10}>
              {promotion.options?.[0]?.categories?.map(category => (
                <OptionItem
                  key={category.id}
                  label1={category.name}
                  label2={`${category.productCount} ${t('promotions.items')}`}
                  onRemove={() => onRemoveCategory(category)}
                />
              ))}
            </Box>
          </Box>

          <Box flex={1} paddingX={4}>
            <Typography className={classes.boldLabel} align='center'>
              {t('promotions.select_products')}
            </Typography>
            <Typography
              style={{ marginTop: 12, minHeight: 65 }}
              className={classes.label}
              align='center'
            >
              {t('promotions.promotion_applied_to_items')}
            </Typography>
            <Box display='flex' justifyContent='center'>
              <Box width='80%'>
                <ProductSearchInput
                  onSelectProduct={onAddProduct}
                />
              </Box>
            </Box>
            <Box marginTop={10}>
              {promotion.options?.[0]?.products?.map(product => (
                <OptionItem
                  key={product.id}
                  label1={product.name}
                  label2={product.bar_code}
                  onRemove={() => onRemoveProduct(product)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display='flex' justifyContent='flex-end' marginTop={12}>
        <Box display='flex'>
          <Button onClick={handleClose}>
            {t('button_group.cancel')}
          </Button>
          <Button
            onClick={onAddUpdatePromotion}
            type="submit"
            color="primary"
            disabled={!passValidations()}
          >
            {defaultPromotion ? t('button_group.update') : t('button_group.add')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Bogof