import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import I18n from '@/i18n';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Box,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import Input from '../../components/input'
import Selector from '../../components/selector'
import VariantsTable from './table'
import AddNewVariant from './addNewVariant'

import { GENDERS_FOR_STYLE } from '../../helper'
import { useQuery } from '@apollo/client';
import { GET_STYLE_OPTIONS } from '@/services/styleService'

import useStyles from './styles';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(
    I18n.t('styles.style_name_required')
  ),
  code: Yup.string().required(
    I18n.t('styles.code_required')
  ),
  gender: Yup.string().required(
    I18n.t('styles.gender_required')
  ),
});

export default function ResponsiveDialog({
  open,
  handleClose,
  selectedStyle = null,
  isUpdatingStyle,
  onAddUpdateStyle,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [openAddNewVariant, setOpenAddNewVariant] = useState(false)

  const {
    data: styleOptionsData,
    loading: isLoadingStyleOptions,
  } = useQuery(GET_STYLE_OPTIONS)
  const styleOptions = styleOptionsData?.styleOptions || []

  const [styleData, setStyleData] = useState({
    id: uuid(),
    name: '',
    code: '',
    gender: '',
    variants: []
  })

  useEffect(() => {
    if (selectedStyle) {
      setStyleData({
        id: selectedStyle.id,
        name: selectedStyle.name,
        code: selectedStyle.code,
        gender: selectedStyle.gender,
        variants: selectedStyle.variants
      })
    }
  }, [selectedStyle])

  const updateStyleData = (key, value) => {
    setStyleData({
      ...styleData,
      [key]: value,
    });
  };

  const onDeleteVariant = (variant) => {
    setStyleData({
      ...styleData,
      variants: styleData.variants.filter(item => item.id !== variant.id)
    })
  }

  const onAddVariant = (variant) => {
    setStyleData({
      ...styleData,
      variants: [...styleData.variants, variant]
    })
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Formik
        initialValues={{
          name: styleData.name,
          code: styleData.code,
          gender: styleData.gender,
        }}
        validationSchema={validationSchema}
        onSubmit={() => onAddUpdateStyle(styleData, styleOptions)}
      >
        {({
          handleBlur,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isValid
        }) => (
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit}
          >
            <DialogTitle id="alert-dialog-slide-title">
              <Grid
                container
                alignItems="center"
                justify="space-between"
                alignContent="center"
              >
                <Grid item xs={1} />
                <Grid className={classes.headerText} item xs={10}>
                  <span style={{ fontSize: 18 }}>
                    {selectedStyle
                      ? t('styles.update_style')
                      : t('styles.add_new_style')
                    }
                  </span>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'right' }}>
                  <IconButton
                    onClick={handleClose}
                    className={classes.closeButton}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.rootDialog}>
                <Box marginTop={7} marginBottom={5}>
                  <Typography className={classes.boldLabel}>
                    {t('styles.general_information')}
                  </Typography>
                </Box>
                <Grid container spacing={10}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Input
                      name='name'
                      title={t('styles.style_name')}
                      emptyLabel={t('add_product.add')}
                      value={styleData.name}
                      onChange={(value) => {
                        updateStyleData('name', value)
                        setFieldValue('name', value)
                      }}
                      onBlur={handleBlur}
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Input
                      name='code'
                      title={t('styles.code')}
                      emptyLabel={t('add_product.add')}
                      value={styleData.code}
                      onChange={(value) => {
                        updateStyleData('code', value)
                        setFieldValue('code', value)
                      }}
                      onBlur={handleBlur}
                      error={touched.code && !!errors.code}
                      helperText={touched.code && errors.code}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Selector
                      name='gender'
                      title={t('styles.gender')}
                      emptyLabel={t('add_product.add')}
                      items={GENDERS_FOR_STYLE}
                      getItemLabel={(gender) => gender}
                      selectedItem={styleData.gender || {}}
                      onChangeValue={(gender) => {
                        updateStyleData('gender', gender)
                        setFieldValue('gender', gender)
                      }}
                      onBlur={handleBlur}
                      error={touched.gender && !!errors.gender && !styleData.gender}
                      helperText={touched.gender && errors.gender}
                    />
                  </Grid>
                </Grid>

                <Box marginTop={7} marginBottom={5}>
                  <Typography className={classes.boldLabel}>
                    {t('styles.variants')}
                  </Typography>
                </Box>
                <VariantsTable
                  options={styleOptions}
                  variants={styleData.variants}
                  onDeleteVariant={onDeleteVariant}
                />

                <Box marginTop={10} display='flex' justifyContent='center'>
                  <Button
                    color="primary"
                    style={{ textTransform: 'none' }}
                    onClick={() => setOpenAddNewVariant(true)}
                  >
                    {t('styles.add_new_variant')}
                  </Button>
                </Box>
              </div>
            </DialogContent>
            <DialogActions style={{ paddingRight: 30 }}>
              <Button
                color="primary"
                onClick={handleClose}
                className={classes.cancelButton}
              >
                {t('button_group.cancel')}
              </Button>
              <Button
                type='submit'
                color="primary"
                onClick={() => { }}
                disabled={!isValid}
              >
                {selectedStyle
                  ? t('button_group.update')
                  : t('button_group.add')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>

      {openAddNewVariant &&
        <AddNewVariant
          open={openAddNewVariant}
          onClose={() => setOpenAddNewVariant(false)}
          options={styleOptions}
          onAddVariant={onAddVariant}
        />
      }

      {(isLoadingStyleOptions || isUpdatingStyle) && <PreLoader size={35} />}
    </Dialog>
  )
}