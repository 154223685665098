import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingRight: 30,
    paddingLeft: 30,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  deliveringBlock: {
    background: '#f4f8fe 0% 0% no-repeat padding-box',
    height: 120,
    marginBottom: 30
  },
  delivering: {
    float:'left',
    width: '80%',
    padding: 15
  },
  switch: {
    paddingTop: 55,
    float: 'right'
  }
}));

export default useStyles;
