const images = {
  // Common images
  logo: require('./common/logo.svg'),
  empty_merchant_logo: require('./common/empty_logo.svg'),
  connect: require('./common/connect.svg'),

  //header images
  automationWorkflows: require('./header/automation_workflows.svg'),
  businessManager: require('./header/busines_manager.svg'),
  insights: require('./header/insights.svg'),
  menu: require('./header/menu.svg'),
  notification: require('./header/notification.svg'),
  settings: require('./header/settings.svg'),
  stores: require('./header/stores.svg'),
  store: require('./header/store.svg'),
  subscriptions: require('./header/subscriptions.svg'),
  touchPoints: require('./header/touch_points.svg'),
  userManagement: require('./header/user_management.svg'),
  wareHouses: require('./header/ware_houses.svg'),
  workBoard: require('./header/work_board.svg'),
  import: require('./header/import.svg'),
  orderTypeWorkflows: require('./header/order_type_workflows.svg'),
  modules: require('./header/modules.svg'),
  developer: require('./header/developer.svg'),

  //business manager images
  orders: require('./businessManager/orders.svg'),
  expenses: require('./businessManager/expenses.svg'),
  inventory: require('./businessManager/inventory.svg'),
  promotions: require('./businessManager/promotions.svg'),
  customers: require('./businessManager/customers.svg'),
  runReport: require('./businessManager/runReport.svg'),
  addOrder: require('./businessManager/addOrder.svg'),
  addExpense: require('./businessManager/addExpense.svg'),
  addProduct: require('./businessManager/addProduct.svg'),
  addStock: require('./businessManager/addStock.svg'),
  addPromotion: require('./businessManager/addPromotion.svg'),
  addCustomer: require('./businessManager/addCustomer.svg'),
  workflows: require('./businessManager/workflows.svg'),
  users: require('./businessManager/users.svg'),
  noImage: require('./businessManager/noImage.svg'),
  replenish: require('./businessManager/replenish.svg'),

  //add store
  mobileImage: require('./addStore/mobileImage.svg'),
  locationAddStore: require('./addStore/location.svg'),

  //categories
  noProducts: require('./categories/noProducts.svg'),

  //workboard
  noData: require('./workboard/noData.svg'),

  //notifications
  noNotifications: require('./notifications/noNotifications.svg'),

  //paymentMethod
  noPaymentMethod: require('./paymentMethod/noPaymentMethod.svg'),
};

export default images;
