import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { API_URL } from '@/constants/env';
import ApiLoopStateActions from '@/store/modules/apiLoopState/actions';

export const HttpMethods = {
  POST: 'post',
  GET: 'get',
};

export const toFormData = (object) => {
  const keys = Object.getOwnPropertyNames(object);
  const formData = new FormData();
  keys.map((key) => {
    formData.append(key, object[key]);
  });
  return formData;
};

const getRequestPayload = (
  payload,
  contentType,
  method = HttpMethods.POST
) => {
  let requestPayload;
  if (method === HttpMethods.GET) {
    requestPayload = { params: payload };
  } else if (method === HttpMethods.POST) {
    if (contentType === 'application/x-www-form-urlencoded') {
      requestPayload = { data: toFormData(payload) };
    } else {
      requestPayload = { data: payload };
    }
  }

  return requestPayload;
};

export const getToken = () => {
  const token = localStorage.getItem('token');
  return token ? `Bearer ${token}` : 'Bearer Guest';
};

const getAxiosConfig = (
  requestPayload,
  method,
  contentType,
  merchantId,
  storeId
) => {
  return {
    baseURL: API_URL,
    method,
    headers: {
      'content-type': contentType || 'application/json',
      'x-metadata-merchant': merchantId,
      'x-metadata-store': storeId,
      Authorization: getToken(),
    },
    ...requestPayload,
  };
};

export function* apiCallSaga(data) {
  const {
    payload,
    contentType,
    merchantId,
    storeId,
    actionType,
    onSuccess,
    onFailed,
  } = data;

  const requestPayload = getRequestPayload(payload, contentType);
  const config = getAxiosConfig(
    requestPayload,
    HttpMethods.POST,
    contentType,
    merchantId,
    storeId
  );
  try {
    yield put(ApiLoopStateActions.apiStart(actionType));

    const response = yield call(axios, config);
    if (response?.data?.errors) {
      yield put(ApiLoopStateActions.apiFailed(actionType));
      const messageStr = response.data.errors?.[0]?.message;
      yield onFailed(messageStr);
    } else {
      yield put(ApiLoopStateActions.apiSuccess(actionType));
      yield onSuccess(response.data.data);
      console.log(response.data);
    }
  } catch (e) {
    yield put(ApiLoopStateActions.apiFailed(actionType));
  } finally {
    yield put(ApiLoopStateActions.apiEnd(actionType));
  }
}
