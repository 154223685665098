import React from 'react'

import {
  Table,
  TableBody,
} from '@material-ui/core'

import TableRow from './tableRow'

const TableComponent = (props) => {
  const {
    options,
    variants,
    onDeleteVariant
  } = props

  return (
    <Table>
      <TableBody>
        {variants.map((variant) => (
          <TableRow
            key={variant.id}
            options={options}
            item={variant}
            onDeleteVariant={onDeleteVariant}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default TableComponent