import { createReducer } from 'reduxsauce';

import { INITIAL_STATE } from './initialState';
import { TutorialsTypes } from './actions';

export const setTutorials = (state, { tutorials }) => {
  return {
    ...state,
    tutorials,
  };
};

export const runTrigger = (state, { trigger }) => {
  return {
    ...state,
    [trigger]: true
  };
};

export const initState = (state, { stateName, data }) => {
  return {
    ...state,
    [stateName]: data
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [TutorialsTypes.SET_TUTORIALS]: setTutorials,
  [TutorialsTypes.RUN_TRIGGER]: runTrigger,
  [TutorialsTypes.INIT_STATE]: initState,
});
