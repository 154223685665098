import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import moment from 'moment'

import { Box, Typography } from '@material-ui/core'
import SelectCompareStore from './selectCompareStore'
import Slider from 'react-slick'
import SliderItem from './sliderItem'
import PreLoader from '@/components/preLoader'

import { gql, useQuery, useLazyQuery } from '@apollo/client'
import {
  GET_STORE_PERFORMANCE_FOR_OVERVIEW,
  GET_COMPARE_STORE_PERFORMANCE_FOR_OVERVIEW
} from '@/services/widgetService'

import { selectSelectedRegion, selectSelectedStore } from '@/store/modules/store/selectors'

import { getPerformanceData } from './helper'
import useStyles from './styles';

const PerformanceOverview = (props) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const { startTime, endTime } = props

  const selectedRegion = useSelector(selectSelectedRegion)
  const selectedStore = useSelector(selectSelectedStore)

  const [compareStore, setCompareStore] = useState(null)

  const { data: storePerformanceData, loading: isLoadingStorePerformanceData } = useQuery(gql`${GET_STORE_PERFORMANCE_FOR_OVERVIEW}`, {
    variables: {
      start: moment(startTime).format('YYYY-MM-DD'),
      end: moment(endTime).format('YYYY-MM-DD')
    },
    context: {
      headers: {
        storeId: selectedRegion ? selectedRegion.id : selectedStore.id
      },
    },
    fetchPolicy: 'cache-and-network'
  })

  const [getCompareStorePerformance, {
    data: compareStorePerformanceData,
    loading: isLoadingCompareStorePerformanceData
  }] = useLazyQuery(gql`${GET_COMPARE_STORE_PERFORMANCE_FOR_OVERVIEW}`, {
    fetchPolicy: 'cache-and-network'
  })

  const sliderSettings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
  };

  const dataToDisplay = getPerformanceData(
    storePerformanceData,
    compareStorePerformanceData,
    selectedStore,
    selectedRegion,
    compareStore
  )

  useEffect(() => {
    if (compareStore) {
      getCompareStorePerformance({
        context: {
          headers: {
            storeId: compareStore.id
          },
        },
        variables: {
          start: moment(startTime).format('YYYY-MM-DD'),
          end: moment(endTime).format('YYYY-MM-DD'),
        },
      })
    }
  }, [compareStore, startTime, endTime])

  return (
    <Box className={classes.root}>
      <Typography className={classes.titleText}>
        {t('globalWidgets.performance_overview')}
      </Typography>

      <Box
        display='flex'
        justifyContent={isMobile ? 'center' : 'flex-end'}
        className={classes.compareStoreContainer}
      >
        <SelectCompareStore
          selectedStoreId={selectedRegion ? selectedRegion.id : selectedStore.id}
          compareStore={compareStore}
          onChangeCompareStore={(store) => setCompareStore(store)}
        />
      </Box>
      <Box className={classes.sliderContainer}>
        <Slider {...sliderSettings}>
          {dataToDisplay.map((data, index) => (
            <SliderItem key={index} data={data} />
          ))}
        </Slider>
      </Box>

      {(isLoadingStorePerformanceData || isLoadingCompareStorePerformanceData) && <PreLoader size={30} />}
    </Box>
  )
}

PerformanceOverview.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
}

export default React.memo(PerformanceOverview)