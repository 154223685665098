import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    searchStoreRoot: {
      paddingLeft: 40,
      borderRadius: 5,
    },

    addNewStore: {
      // paddingTop: 51,
      margin: 'auto',
      backgroundColor: Colors.WHITE,
      borderRadius: 5,
    },
    addStoreContainer: {
      width: '100%',
    },
    cantFindStore: {
      fontSize: 18,
      letterSpacing: '0.56px',
      color: Colors.GREY_DARK,
      opacity: '1',
    },
    addStoreLink: {
      cursor: 'pointer',
      letterSpacing: '0.14px',
      color: Colors.BLUE,
      opacity: '1',
    },
    findYourBussinessTitle: {
      fontSize: 14,
      letterSpacing: '0.1px',
      color: Colors.GREY_DARK,
      opacity: '1',
      fontWeight: 550,
    },
    businessName: {
      fontSize: 16,
      fontWeight: 550,
      letterSpacing: '0.14px',
      color: Colors.GREY_DARK,
      opacity: '1',
    },
    fullAddress: {
      fontsize: 16,
    },
    descriptionSecondStep: {
      letterSpacing: '0.25px',
      color: '#20272F',
      opacity: '1',
      fontsize: 14,
      whiteSpace: 'pre-line',
    },
    addStoreButton: {
      fontSize: '14px',
      marginTop: '30px',
      padding: '10px 40px',
      background: '#1174F2 0% 0% no-repeat padding-box',
      color: Colors.WHITE,
      textTransform: 'Uppercase',
      width: 208,
    },
    enterFullAddressButton: {
      fontSize: '9px',
      marginTop: '30px',
      padding: '10px 40px',
      background: '#1174F2 0% 0% no-repeat padding-box',
      color: Colors.WHITE,
      textTransform: 'Uppercase',
      width: 208
    },
    arrowIconStyle: {
      marginLeft: '-15px',
      backgroundColor: Colors.GREY_LIGHT,
      padding: '2px',
      borderRadius: '50%',
      cursor: 'pointer',
      // width: 50,
      // height: 50,
    },
    centerNavigation: {
      margin: '0 auto',
    },
    headerTitle: {
      letterSpacing: '0.15px',
      color: Colors.GREY_DARK,
      opacity: '1',
      fontSize: 20,
      fontWeight: 600,
    },
  })
);

export default useStyles;
