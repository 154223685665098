import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  configureText: {
    ...CommonFonts.blueBoldFont14,
    cursor: 'pointer',
    textAlign: 'center'
  },
  normalText: {
    ...CommonFonts.darkFont18,
  },
  smallText: {
    ...CommonFonts.darkFont14
  }
}));

export default useStyles;
