import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(4),
      },
    },
    label: {
      ...CommonFonts.darkFont12,
      fontWeight: 600,
      marginBottom: theme.spacing(3),
    },
    boxColor: {
      width: theme.spacing(11.5),
      height: theme.spacing(11),
      borderRadius: theme.spacing(1),
      cursor: 'pointer',
      marginRight: theme.spacing(3),
      border: '1.5px #F3F5F7 solid',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    boxInputHex: {
      width: theme.spacing(40),
      height: theme.spacing(11),
      borderRadius: theme.spacing(1),
      border: '1.5px #BAC3C9 solid',
      display: 'flex',
      alignItems: 'center',
    },
    boxHex: {
      width: theme.spacing(11.5),
      height: theme.spacing(11),
      backgroundColor: '#F3F5F7',
      borderRightColor: '#BAC3C9',
      borderRightWidth: theme.spacing(0.37),
      borderRightStyle: 'solid',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    hexText: {
      ...CommonFonts.darkFont14,
    },
    inputText: {
      width: theme.spacing(25),
      padding: theme.spacing(0, 1),
      '& .MuiInputBase-input': {
        ...CommonFonts.darkFont14,
      },
    },
  })
);

export default useStyles;
