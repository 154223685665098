import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  Box,
  Breadcrumbs,
  Link,
  Grid,
  Paper,
  List,
  ListItem,
  Avatar,
  Typography,
  Hidden
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link as RouterLink } from 'react-router-dom';
import AppLayout from '@/components/appLayout';
import SearchHeader from './components/SearchHeader'
import NoIngredients from './components/NoIngredients'
import ActionMenu from './components/ActionMenu'
import AddUpdateIngredient from './addUpdateIngredient'

import PreLoader from '@/components/preLoader';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_INGREDIENTS, REMOVE_INGREDIENT } from '@/services/inventoryService';

import { selectSelectedStore, selectLocalisation } from '@/store/modules/store/selectors'

import { getLocalisationVal } from '@/utils/localisationUtil'
import { Colors } from '@/theme'
import images from '@/assets/images';
import useStyles from './styles';

const Ingredients = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const selectedStore = useSelector(selectSelectedStore)
  const localisation = useSelector(selectLocalisation)

  const [inputSearch, setInputSearch] = useState("");
  const [openIngredientModal, setOpenIngredientModal] = useState(false)
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  const [ingredients, setIngredients] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const [isAllIngredientsLoaded, setIsAllIngredientsLoaded] = useState(false)

  // get products from api
  const [getIngredients, { loading: isLoadingIngredients }] = useLazyQuery(GET_INGREDIENTS, {
    onCompleted: (data) => {
      // if response give us less then 10 results, set all ingredients loaded
      if (data.ingredients.length < 10) {
        setIsAllIngredientsLoaded(true);
      } else {
        setIsAllIngredientsLoaded(false)
      }

      // update the array holding products
      setIngredients([...ingredients, ...data.ingredients]);
    },
    onError: () => {
      setIsAllIngredientsLoaded(true);
    },
    fetchPolicy: 'no-cache',
  });

  const [removeIngredientMutation, { loading: isRemovingIngredient }] = useMutation(REMOVE_INGREDIENT, {
    onCompleted: (data) => {
      enqueueSnackbar(t('ingredients.ingredient_deleted'))
      setIngredients([])
      setCurrentPage(1);
      refetchIngredients()
    },
    onError: (error) => {
    }
  })

  const actionMenus = [
    {
      name: t('ingredients.update_ingredient'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        setSelectedIngredient(data)
        setOpenIngredientModal(true)
      }
    },
    {
      name: t('ingredients.delete'),
      color: Colors.RED,
      onClick: (data) => {
        setSelectedIngredient(data)
        removeIngredientMutation({
          variables: {
            input: {
              ids: [data.id]
            }
          }
        })
      }
    }
  ]

  // change current page when user reaches bottom
  const handleLoadMore = () => {
    if (isEmpty(ingredients)) return
    setCurrentPage(currentPage + 1);
  };

  const refetchIngredients = () => {
    getIngredients({
      variables: {
        filter: {
          count: true,
          page: currentPage,
          limit: 10,
          name: `%${inputSearch.toLowerCase()}%`,
        },
      },
    });
  }

  // get products from API
  useEffect(() => {
    refetchIngredients()
  }, [currentPage, inputSearch]);

  useEffect(() => {
    setIngredients([])
    setCurrentPage(1);
  }, [inputSearch, selectedStore]);

  return (
    <AppLayout
      className={classes.appLayout}
      withFooter
      header
      withServiceDropdown={true}
    >
      <div className={classes.root}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                className={classes.breadcrumbsText}
                color="primary"
                component={RouterLink}
                to="/business-manager"
              >
                {t('settings.business_manager')}
              </Link>
              <Link
                className={classes.breadcrumbsText}
                color={'textPrimary'}
                onClick={() => { }}
              >
                {t('menu.ingredient')}
              </Link>
            </Breadcrumbs>
          </Grid>
          <SearchHeader
            onChangeSearch={setInputSearch}
            onAdd={() => {
              setOpenIngredientModal(true)
              setSelectedIngredient(null)
            }}
          />
          <Grid item xs={12}>
            <Paper className={classes.emptyProductsContainer}>
              {(isEmpty(ingredients) && !isLoadingIngredients)
                ?
                <NoIngredients
                  title={inputSearch
                    ? t('ingredients.no_matching_ingredients_found')
                    : t('ingredients.no_ingredients_found')
                  }
                />
                :
                <InfiniteScroll
                  dataLength={ingredients.length}
                  next={handleLoadMore}
                  hasMore={!isAllIngredientsLoaded}
                  loader={
                    isLoadingIngredients && currentPage !== 1 && (
                      <div className={classes.root}>
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                      </div>
                    )
                  }
                >
                  <List className={classes.root}>
                    <ListItem className={classes.listHeader}>
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        alignContent="center"
                      >
                        <Grid item xs={10}>
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                            alignContent="center"
                          >
                            <Grid item xs={5}>
                              {t('ingredients.ingredient')}
                            </Grid>
                            <Hidden smDown>
                              <Grid item xs={3}>
                                {t('ingredients.ean_upc_mpc')}
                              </Grid>
                            </Hidden>
                            <Grid item xs={2}>
                              {t('ingredients.current_stock')}
                            </Grid>
                            <Grid item xs={2}>
                              {t('ingredients.measure')}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} />
                      </Grid>
                    </ListItem>
                    {isLoadingIngredients && currentPage === 1
                      ?
                      <div className={classes.root}>
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                      </div>
                      :
                      <div>
                        {ingredients.map((ingredient, id) => (
                          <ListItem button key={`ingredient-${id}`} className={classes.listItem}>
                            <Grid
                              container
                              justify="space-between"
                              alignItems="center"
                              alignContent="center"
                            >
                              <Grid
                                item
                                xs={10}
                                onClick={() => {
                                  setSelectedIngredient(ingredient)
                                  setOpenIngredientModal(true)
                                }}
                              >
                                <Grid
                                  container
                                  justify="space-between"
                                  alignItems="center"
                                  alignContent="center"
                                  onClick={() => { }}
                                >
                                  <Grid item xs={5}>
                                    <Grid
                                      container
                                      justify="flex-start"
                                      spacing={8}
                                      alignItems="center"
                                      wrap="nowrap"
                                    >
                                      <Grid item xs={3}>
                                        <Avatar
                                          src={
                                            ingredient?.images[0]?.thumbnail
                                              ? item?.images[0]?.thumbnail
                                              : images.noImage
                                          }
                                          variant="square"
                                          className={
                                            classes.avatarSize
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={9}
                                        zeroMinWidth
                                      >
                                        <Typography noWrap>
                                          {ingredient.name}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Hidden smDown>
                                    <Grid item xs={3}>
                                      {ingredient.bar_code}
                                    </Grid>
                                  </Hidden>
                                  <Grid item xs={2}>
                                    {!!ingredient.stocked
                                      ? getLocalisationVal({ ...localisation, currency_decimal: false }, +ingredient?.stocks?.[0]?.current_stock)
                                      : t(
                                        'ingredients.made_to_order'
                                      )
                                    }
                                  </Grid>
                                  <Grid item xs={2}>
                                    {ingredient.measure.type === 'qty'
                                      ? t('ingredients.units')
                                      : ingredient.measure.name
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className={classes.dotsIcon}
                              >
                                <ActionMenu
                                  data={ingredient}
                                  actionMenus={actionMenus}
                                />
                              </Grid>
                            </Grid>
                          </ListItem>
                        ))}
                      </div>
                    }
                  </List>
                </InfiniteScroll>
              }
            </Paper>
          </Grid>
        </Grid>

        {isRemovingIngredient && <PreLoader size={25} />}
      </div>

      {openIngredientModal &&
        <AddUpdateIngredient
          open={openIngredientModal}
          handleClose={() => setOpenIngredientModal(false)}
          ingredient={selectedIngredient}
          onSuccess={() => {
            setIngredients([])
            setCurrentPage(1);
            refetchIngredients()
          }}
        />
      }
    </AppLayout>
  )
}

export default Ingredients