import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import I18n from '@/i18n';

import { Grid } from '@material-ui/core';
import TextField from '@/components/textField';
import Button from '@/components/button';
import BackButton from '@/components/backButton';

import useStyles from '../styles';

const validationSchema = Yup.object().shape({
  password: Yup.string().required(
    I18n.t('login_page.password_required')
  ),
});

const PasswordStep = ({
  initialEmail,
  loginError,
  onBack,
  onLogin,
  setCurrentStep,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={validationSchema}
      onSubmit={onLogin}
    >
      {({
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justify="flex-start"
                direction="row"
              >
                <Grid item>
                  <div className={classes.backWrapper}>
                    <BackButton onClick={onBack} siz />
                  </div>
                </Grid>
                <Grid item className={classes.titleWrapper}>
                  <span className={classes.title}>
                    {initialEmail}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                isPassword={true}
                label={t('login_page.password')}
                name="password"
                value={values.password}
                onChange={(e) =>
                  setFieldValue('password', e.target.value)
                }
                onBlur={handleBlur}
                error={
                  !!(
                    (touched.password && errors.password) ||
                    loginError
                  )
                }
                helperText={loginError || errors.password}
                className={classes.passwordField}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                justify="center"
                className={classes.buttonWrapper}
              >
                <Grid item xs={11} md={7}>
                  <Button
                    className={classes.nextButton}
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={() => {}}
                  >
                    {t('button_group.login')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 30 }}>
              <span
                className={classes.createText}
                onClick={() =>
                  setCurrentStep('FORGOT_PASSWORD_EMAIL')
                }
              >
                {t('login_page.forgot_password')}
              </span>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

PasswordStep.propTypes = {
  initialEmail: PropTypes.string,
  loginError: PropTypes.string,
  onBack: PropTypes.func,
  onLogin: PropTypes.func,
};

export default memo(PasswordStep);
