import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingRight: 30,
    paddingLeft: 30,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  holidayButton: {
    textTransform: 'none',
    marginTop: '2vh',
    marginBottom: '2vh',
    width: '100%'
  },
  divider: {
    width: '350%'
  }
}));

export default useStyles;
