import React, { useState } from 'react';
import { orderBy, isEmpty } from 'lodash';
import {
  Box,
  Breadcrumbs,
  Link, Paper, Typography, Grid, TableBody, TableRow, TableCell, CircularProgress, Table, withStyles
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import AppLayout from '@/components/appLayout';
import { useTranslation } from 'react-i18next';
import Button from '@/components/button';
import useStyles from './styles';
import { useQuery } from '@apollo/client';
import { GET_SEGMENTS } from '@/services/audienceService';
import ActionDialog from '@/pages/modules/customerManagment/actionDialog';
import ConfirmDialog from '@/pages/modules/customerManagment/confirmDialog';

const CustomerManagementModulePage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data, loading, refetch } = useQuery(GET_SEGMENTS, {
    fetchPolicy: 'no-cache'
  });

  const [selectedSegment, setSelectedSegment] = useState({});
  const [modal, setModal] = useState({
    add: false,
    update: false,
    delete: false
  });
  const [isLoading, setIsLoading] = useState(false);

  const newCustomerId = '699c55f9-4aa7-4f9b-b409-d9734bb1c0fa';
  const segments = data?.audiences;

  const sortedSegment = [segments?.find((item) => item.id === newCustomerId), ...orderBy(segments, [segment => segment.name.toLowerCase()])
    .filter((item) => item.id !== newCustomerId)];

  const onAddSegment = () => {
    setModal({ ...modal, add: true });
  };

  const onUpdateSegment = (segment) => {
    setSelectedSegment(segment);
    setModal({ ...modal, update: true });
  };

  const onDeleteSegment = (segment) => {
    setSelectedSegment(segment);
    setModal({ ...modal, delete: true });
  };
const reFetchSegments = () => {
  setIsLoading(true)
  refetch().then(() => setIsLoading(false))
}
  return (
    <AppLayout className={classes.appLayout} withFooter header>
      <Box marginY={10}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className={classes.breadcrumbsText}
            color="primary"
            component={RouterLink}
            to="/business-manager/store-profile"
          >
            {t('userManagement.users.business_manager')}
          </Link>
          <Link
            className={classes.breadcrumbsText}
            color="primary"
            component={RouterLink}
            to="/modules"
          >
            {t('modules.title')}
          </Link>
          <Link
            className={classes.breadcrumbsText}
            color="textPrimary"
            component={RouterLink}
            to="/modules/customer-behaviour-management"
          >
            {t('modules.customers.customer_behaviour_management')}
          </Link>
        </Breadcrumbs>
        <div className={classes.boxModules}>
          <Grid container row spacing={2}>
            <Grid item xs={8}>
              <Typography className={classes.title}>
                {t('modules.customers.title')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid container justify="flex-end">
                <Button
                  className={classes.addButton}
                  color="primary"
                  onClick={onAddSegment}
                  variant='contained'
                >
                  {t('modules.customers.add_new_segment')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Paper elevation={0} style={{ overflowX: 'auto' }}>
            <Table>
              {loading || isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: 'none' }}
                      colSpan={6}
                    >
                      <Box
                        marginTop={10}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={35} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {sortedSegment?.map((row) => (
                    <TableRow hover key={row.id}>
                      <StyledTableCell>
                        <div className={classes.boxFullName}>
                          <Typography className={classes.tableText}>
                            {row.name}
                          </Typography>
                          <Typography
                            className={classes.headerTableText}
                          >
                            {row.description}
                          </Typography>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.id !== newCustomerId ?
                          <Typography
                            onClick={() => onUpdateSegment(row)}
                            className={classes.actionLink}
                          >
                            {t('modules.customers.update')}
                          </Typography> : ''
                        }
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.id !== newCustomerId ?
                          <Typography
                            onClick={() => onDeleteSegment(row)}
                            color="textPrimary"
                            className={classes.link}
                          >
                            {t('modules.customers.delete')}
                          </Typography> : ''
                        }
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {!loading && isEmpty(data) && (
              <Box
                marginTop={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.noDataFound}>
                  {t('modules.customers.no_segment_found')}
                </Typography>
              </Box>
            )}
          </Paper>
        </div>
      </Box>
      {modal.add && (
        <ActionDialog open={modal.add}
                      handleClose={() => setModal({ ...modal, add: false })}
                      setRefetchSegments={reFetchSegments}
                      dialogTitle={t('modules.customers.add_new_segment_title')}
                      actionType='add'
        />
      )}
      {modal.update && (
        <ActionDialog open={modal.update}
                      handleClose={() => setModal({ ...modal, update: false })}
                      setRefetchSegments={reFetchSegments}
                      dialogTitle={t('modules.customers.update_segment_title')}
                      segment={selectedSegment}
                      actionType='update'
        />
      )}
      {modal.delete && (
        <ConfirmDialog
          open={modal.delete}
          handleClose={() => setModal({ ...modal, delete: false })}
          setRefetchSegments={reFetchSegments}
          dialogTitle={t('modules.customers.delete_segment_title')}
          segment={selectedSegment}
        />
      )}
    </AppLayout>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F3F5F7',
    borderBottom: 'none'
  },
  body: {
    fontSize: 16
  }
}))(TableCell);

export default CustomerManagementModulePage;
