import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import images from '@/assets/images';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const NoAddonGroups = ({ title, showAddButton = false, onAdd }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={10}
      style={{ marginBottom: 40 }}
    >
      <Grid item>
        <img src={images.noProducts} width={151} height={151} />
      </Grid>
      <Grid item>
        <span className={classes.emptyAddonsText}>{title}</span>
      </Grid>
      {showAddButton &&
        <Grid item>
          <Button
            className={classes.disableTextTransform}
            color="primary"
            onClick={onAdd}
          >
            {t('button_group.add_new')}
          </Button>
        </Grid>
      }
    </Grid>
  );
};

export default NoAddonGroups;
