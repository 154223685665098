import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, find } from 'lodash'

import {
  Box,
  Typography
} from '@material-ui/core'
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import RootRef from '@material-ui/core/RootRef';
import AddonGroupSearchInput from '@/components/addonGroupSearchInput'
import AddonGroup from './AddonGroup'
import NoResults from '../../components/NoResults'

import useStyles from './styles'

const AddonOptions = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { productData, updateProductData } = props

  const onSelectGroup = (addonGroup) => {
    if (find(productData.addons, { id: addonGroup.id })) return // ignore group that has been already added to this product.
    
    const newAddonGroup = {
      id: addonGroup.id,
      group: addonGroup.group,
      allow_free: addonGroup.allow_free,
      mandatory: addonGroup.mandatory,
      multi_selection: addonGroup.multi_selection,
      default_all: addonGroup.default_all,
      description: addonGroup.description,
      limit_free_options: !!addonGroup.allow_free,
      position: productData.addons.length + 1
    }
    updateProductData('addons', [...productData.addons, newAddonGroup])
  }

  const onUpdateGroup = (addonGroup) => {
    updateProductData('addons', productData.addons
      .map(item => item.id === addonGroup.id ? addonGroup : item)
    )
  }

  const onRemoveGroup = (addonGroup) => {
    updateProductData('addons', productData.addons
      .filter(item => item.id !== addonGroup.id)
    )
  }

  const onDragEnd = (result) => {
    const currentIndex = result.source.index;
    const targetIndex = result.destination.index;
    if (!result.destination) {
      return;
    }
    updateProductData('addons', productData.addons
      .map((item, index) => ({
        ...item,
        position: index === currentIndex
          ? productData.addons[targetIndex].position
          : index === targetIndex
            ? productData.addons[currentIndex].position
            : item.position,
      }))
    )
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      backgroundColor: '#F3F5F7',
    }),
  });

  return (
    <Box className={classes.root}>
      <Typography className={classes.searchText}>
        {t('add_product.find_addon_group')}
      </Typography>
      <Box className={classes.groupSearchWrapper}>
        <AddonGroupSearchInput onSelectGroup={onSelectGroup} />
      </Box>

      <Box className={classes.groupsContainer}>
        {isEmpty(productData.addons) &&
          <NoResults title={t('add_product.no_addon_groups_added')} />
        }
        {!isEmpty(productData.addons) &&
          <React.Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <RootRef rootRef={provided.innerRef}>
                    <Box className={classes.groupsWrapper}>
                      {productData.addons
                        .sort((first, second) => first.position - second.position)
                        .map((addonGroup, index) => (
                          <Draggable
                            key={`addon-group-${index}`}
                            draggableId={addonGroup.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Box
                                ref={provided.innerRef}
                                key={addonGroup.id}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <AddonGroup
                                  group={addonGroup}
                                  updateGroup={onUpdateGroup}
                                  removeGroup={onRemoveGroup}
                                />
                              </Box>
                            )}
                          </Draggable>
                        ))}
                    </Box>
                  </RootRef>
                )}
              </Droppable>
            </DragDropContext>

            <Typography className={classes.titleText} style={{ marginTop: 40 }}>
              {t('add_product.addon_description')}
            </Typography>
          </React.Fragment>
        }
      </Box>
    </Box>
  )
}

export default AddonOptions