import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { find, isEmpty } from 'lodash';
import moment from 'moment'
import { Formik } from 'formik';
import * as Yup from 'yup';
import I18n from '@/i18n';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Box,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import SingleCheckboxInput from '@/components/singleCheckbox'
import Input from '../components/input'

import { useQuery, useMutation, gql } from '@apollo/client';
import {
  GET_ROLES,
  MUTATE_APPROVE_APPLICATION,
  MUTATE_DENY_APPLICATION
} from '@/services/userManagementService';

import { selectMerchantSettings } from '@/store/modules/store/selectors';
import { calcGrossPriceFromPrice } from '@/utils/priceUtil';
import { getLastAndFirstName, getFullAddressOfStore } from '@/utils/stringUtil'
import Errors from '@/constants/errors';
import useStyles from './styles';

const rolesQueries = gql`
  ${GET_ROLES}
`;

export default function ResponsiveDialog({
  open,
  handleClose,
  application,
  onSuccess
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data: roleData, loading: isLoadingRoles } = useQuery(rolesQueries, {
    fetchPolicy: 'no-cache',
  });
  const roles = roleData?.roles || []

  const [approveMutation, { loading: isApproving }] = useMutation(MUTATE_APPROVE_APPLICATION, {
    onCompleted: () => {
      enqueueSnackbar(t('userManagement.applications.application_approved'))
      handleClose()
      onSuccess(application)
    },
    onError: (error) => {
      const message = error?.message || error?.errors[0].message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  })

  const [denyMutation, { loading: isDenying }] = useMutation(MUTATE_DENY_APPLICATION, {
    onCompleted: () => {
      enqueueSnackbar(t('userManagement.applications.application_denied'))
      handleClose()
      onSuccess(application)
    },
    onError: (error) => {
      const message = error?.message || error?.errors[0].message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  })

  const getRoleName = (id) =>
    roles?.find((item) => item.id === id)?.name || '';

  const onClickApprove = () => {
    approveMutation({
      variables: {
        id: application.id
      }
    })
  }

  const onClickDecline = () => {
    denyMutation({
      variables: {
        id: application.id,
        reason: ''
      }
    })
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          alignContent="center"
        >
          <Grid item xs={1} />
          <Grid className={classes.headerText} item xs={10}>
            <span style={{ fontSize: 18 }}>
              {getLastAndFirstName(application.user.first_name, application.user.last_name)}
            </span>
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            <IconButton
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.boldLabelContainer}>
          <Typography className={classes.boldLabel}>
            {t('userManagement.applications.general_information')}
          </Typography>
        </Box>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              disabled
              title={t('userManagement.applications.first_name')}
              emptyLabel={t('add_product.add')}
              value={application.user?.first_name}
              onChange={(value) => { }}
              onBlur={() => { }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              disabled
              title={t('userManagement.applications.last_name')}
              emptyLabel={t('add_product.add')}
              value={application.user?.last_name}
              onChange={(value) => { }}
              onBlur={() => { }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              disabled
              title={t('userManagement.applications.email')}
              emptyLabel={t('add_product.add')}
              value={application.user?.email}
              onChange={(value) => { }}
              onBlur={() => { }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              disabled
              title={t('userManagement.applications.mobile')}
              emptyLabel={t('add_product.add')}
              value={application.user?.mobile}
              onChange={(value) => { }}
              onBlur={() => { }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              disabled
              title={t('userManagement.applications.date_of_birth')}
              emptyLabel={t('add_product.add')}
              value={moment(application.user.dob).format('DD MMM YYYY')}
              onChange={(value) => { }}
              onBlur={() => { }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              disabled
              title={t('userManagement.applications.role')}
              emptyLabel={t('add_product.add')}
              value={getRoleName(application.role_id)}
              onChange={(value) => { }}
              onBlur={() => { }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Input
              disabled
              title={t('userManagement.applications.home_address')}
              emptyLabel={t('add_product.add')}
              value={getFullAddressOfStore(application.user?.addresses?.[0])}
              onChange={(value) => { }}
              onBlur={() => { }}
            />
          </Grid>
        </Grid>

        <Box className={classes.boldLabelContainer}>
          <Typography className={classes.boldLabel}>
            {t('userManagement.applications.identifications')}
          </Typography>
        </Box>
        <Box marginTop={2}>
          <Typography className={classes.boldLabel}>
            {t('userManagement.applications.selfie')}
          </Typography>
        </Box>

        <Box display='flex'>
          <Box className={classes.selfieContainer}>
            <img src={application.selfie.url} className={classes.selfie} />
          </Box>
        </Box>

        <Box className={classes.boldLabelContainer}>
          <Typography className={classes.boldLabel}>
            {t('userManagement.applications.identity_card_license')}
          </Typography>
        </Box>
        <Box display='flex' flexWrap='wrap'>
          {application.cards.map(card => (
            <Box key={card.id} marginRight={10}>
              <img src={card.url} className={classes.card} />
            </Box>
          ))}
        </Box>

        <Box className={classes.boldLabelContainer}>
          <Typography className={classes.boldLabel}>
            {t('userManagement.applications.identifications')}
          </Typography>
        </Box>
        {application.conditions?.map(condition => (
          <SingleCheckboxInput
            key={`condition-${condition?.id}`}
            style={{ paddingBottom: 0 }}
            isShowToast={false}
            value={condition.value}
            label={condition.description}
            onChange={() => { }}
          />
        ))}

      </DialogContent>

      <DialogActions style={{ paddingRight: 30 }}>
        <Button
          color="primary"
          onClick={onClickDecline}
          className={classes.cancelButton}
        >
          {t('button_group.decline')}
        </Button>
        <Button
          color="primary"
          onClick={onClickApprove}
        >
          {t('button_group.approve')}
        </Button>
      </DialogActions>

      {(isLoadingRoles || isApproving || isDenying) && <PreLoader size={25} />}
    </Dialog >
  )
}