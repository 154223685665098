export const MUTATE_ADD_IMPORT = `
    mutation($input: ImportInput!) {
        addImport(input: $input) {
            done
        }
    }
`;

export const READ_IMPORT = `
    query readImport($id: String!){
        readImport(id: $id)
    }
`;
