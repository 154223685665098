import React, { useState } from 'react';

import useStyles from './styles';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Avatar,
  Paper,
  Popper,
  Menu,
  MenuItem,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';
import TextField from '@/components/textField';
import SearchIcon from '@material-ui/icons/Search';
import { Colors } from '@/theme';
import UserFilter from '../userFilter';
import { gql, useQuery, useMutation } from '@apollo/client';
import {
  GET_ROLES,
  GET_TEAMS,
  MUTATE_USER_DETAIL,
} from '@/services/userManagementService';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import moment from 'moment';
import useDebounce from '@/hooks/useDebounce';
import { Alert, AlertTitle } from '@material-ui/lab';
import AddNewUser from '../addNewUser';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import {
  getFirstLetter,
  capitalizeFirstLetter,
} from '@/utils/stringUtil';
import { useSelector } from 'react-redux';
import { selectCurrentMerchant } from '@/store/modules/store/selectors';
import { useSnackbar } from 'notistack';

const rolesQueries = gql`
  ${GET_ROLES}
`;

const teamsQueries = gql`
  ${GET_TEAMS}
`;

const ArchivedUserPage = () => {
  const currentMerchant = useSelector(selectCurrentMerchant);
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [searchText, setSearchText] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popperId, setPopperId] = useState('');
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    role: '',
    team: '',
  });

  const [updateData, setUpdateData] = useState({
    updateUserTeam: false,
    updateUserAuthorize: false,
    userDetail: {},
    type: '',
  });

  const [updateUserDetail] = useMutation(
    gql`
      ${MUTATE_USER_DETAIL}
    `
  );

  const mutateUserDetail = async (item) => {
    const { key, value } = item;
    const payload = {
      variables: {
        input: {
          id: updateData.userDetail.id,
          [key]: value,
        },
      },
      onError: (error) => {
        throw new Error(error);
      },
    };
    return updateUserDetail(payload).then(async (res) => {
      if (res?.data) {
        await refetch();
      }
    });
  };

  const debouncedSearchTerm = useDebounce(searchText, 500);
  const id = open ? 'simple-popper-teams' : undefined;

  const { loading: loadingRoles, data: roleData } = useQuery(
    rolesQueries,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const roles = roleData?.roles;

  const { loading: loadingTeams, data: teamData } = useQuery(
    teamsQueries,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const teams = teamData?.teams;

  const searchFilter = `{
    __or: {
      first_name: { __eq: "%${debouncedSearchTerm}%" },
      last_name: { __eq: "%${debouncedSearchTerm}%" },
      email: { __eq: "%${debouncedSearchTerm}%" },
      mobile: { __eq: "%${debouncedSearchTerm}%" },
    },
  }`;

  const {
    loading: loadingUsers,
    data: userData,
    refetch,
  } = useQuery(
    gql`{ employee(${
      filter.role !== '' ? `role_id: "${filter.role}"` : ''
    }, ${
      filter.team !== '' ? `team_id: "${filter.team}"` : ''
    }, status: "inactive", filter: ${searchFilter}, store_id: "-1"){
          id
          profile{
              thumbnail
          }
          config{
              background_colour
          }
          first_name
          last_name
          email
          mobile
          roles{
            id
            name
            permissions
            store_id
            merchant_id
            status
          }
          teams{
              id
              name
          }
          last_login
          verified
      }
    }`,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const users = userData?.employee;

  const getRoleName = (id) =>
    roles?.find((item) => item.id === id)?.name || '';

  const getFullName = (firstName, lastName) =>
    firstName && lastName ? `${firstName} ${lastName}` : '';
  const getTeamStr = (value) =>
    value?.length > 2
      ? `${value
          ?.filter((item, index) => index < 2)
          ?.map((item) => item.name)
          ?.join(', ')},...`
      : value?.map((item) => item.name)?.join(', ');

  //dispay popover with teams
  const onMouseEnter = (event, id) => {
    setAnchorEl(event.currentTarget);
    setPopperId(id);
  };

  //hide popover with teams
  const onMouseLeave = () => {
    setAnchorEl(null);
    setPopperId('');
  };

  const onChangeStatus = async (roles) => {
    const roleRequest = roles?.map((item) => ({
      merchant_id: currentMerchant?.id,
      role_id: item.id,
      store_id: item.store_id,
      status: 'active',
    }));

    try {
      setIsLoading(true);
      await mutateUserDetail({
        key: 'roles',
        value: roleRequest,
      });

      const userDisplay =
        getFullName(
          updateData.userDetail.first_name,
          updateData.userDetail.last_name
        ) || updateData.userDetail.email;

      enqueueSnackbar(
        t('userManagement.archivedUsers.activate_user', {
          value: userDisplay,
        })
      );
    } catch (error) {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <Box>
        <div className={classes.boxFilter}>
          <Grid
            justify="space-between"
            alignItems="center"
            container
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <TextField
                placeholder={t(
                  'userManagement.users.search_placeholder'
                )}
                id="userSearch"
                name="userSearch"
                fullWidth
                value={searchText}
                onChange={(event) =>
                  setSearchText(event.target.value)
                }
                InputProps={{
                  className: classes.input,
                }}
                startAdornment={
                  <SearchIcon
                    style={{ color: Colors.GRAY_BORDER1 }}
                    width={18}
                    height={18}
                  />
                }
              />
            </Grid>

            {!isMobile && (
              <Grid item sm={12} md={6} lg={5}>
                <Grid container spacing={10}>
                  <Grid item xs={12} sm={6}>
                    <UserFilter
                      options={roles?.map((item) => item.name) || []}
                      name="roleFilter"
                      emptyLabel={t('userManagement.users.all_role')}
                      onChange={(value) =>
                        setFilter({
                          ...filter,
                          role:
                            roles?.find((item) => item.name === value)
                              ?.id || '',
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <UserFilter
                      options={teams?.map((item) => item.name) || []}
                      name="teamFilter"
                      emptyLabel={t('userManagement.users.all_teams')}
                      onChange={(value) =>
                        setFilter({
                          ...filter,
                          team:
                            teams?.find((item) => item.name === value)
                              ?.id || '',
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={12} lg={2}>
              <div style={{ textAlign: 'center' }}>
                <AddNewUser teams={teams} />
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={classes.boxUserManagement}>
          <Typography className={classes.title}>
            {t('userManagement.users.user_management')}
          </Typography>
          <Paper elevation={0} style={{ overflowX: 'auto' }}>
            <Table className={classes.boxTable}>
              {!isMobile && (
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      className={classes.headerTableText}
                      style={{ width: '25%', borderBottom: 'none' }}
                    >
                      {t('userManagement.users.name')}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.headerTableText}
                      style={{ width: '15%', borderBottom: 'none' }}
                    >
                      {t('userManagement.users.role')}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.headerTableText}
                      style={{ width: '20%', borderBottom: 'none' }}
                    >
                      {t('userManagement.users.teams')}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.headerTableText}
                      style={{ width: '15%', borderBottom: 'none' }}
                    >
                      {t('userManagement.users.status')}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.headerTableText}
                      style={{ width: '15%', borderBottom: 'none' }}
                    >
                      {t('userManagement.users.last_login')}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.headerTableText}
                      style={{ borderBottom: 'none' }}
                    />
                  </TableRow>
                </TableHead>
              )}

              {loadingUsers || isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: 'none' }}
                      colSpan={6}
                    >
                      <Box
                        marginTop={10}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={35} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {users?.map((user) => (
                    <TableRow hover key={user.id}>
                      <StyledTableCell>
                        <div className={classes.boxAvatarName}>
                          <div className={classes.boxAvatar}>
                            {user.profile ? (
                              <Avatar
                                className={classes.boxAvatar}
                                src={user.profile.thumbnail}
                                alt="No Image"
                              />
                            ) : (
                              <Avatar
                                className={classes.boxAvatar}
                                style={{
                                  backgroundColor:
                                    user.config.background_colour,
                                }}
                              >
                                {`${getFirstLetter(
                                  user.first_name
                                )}${getFirstLetter(user.last_name)}`}
                              </Avatar>
                            )}
                          </div>
                          <div className={classes.boxFullName}>
                            <Typography className={classes.tableText}>
                              {getFullName(
                                user.first_name,
                                user.last_name
                              ) || user.email}
                            </Typography>
                          </div>
                        </div>
                      </StyledTableCell>
                      {!isMobile && (
                        <StyledTableCell>
                          <Typography className={classes.tableText}>
                            {getRoleName(user.roles[0]?.id || '')}
                          </Typography>
                        </StyledTableCell>
                      )}

                      {!isMobile && (
                        <StyledTableCell>
                          <Typography
                            onMouseEnter={(e) =>
                              onMouseEnter(e, user.id)
                            }
                            onMouseLeave={onMouseLeave}
                            className={classes.tableText}
                            style={{ cursor: 'pointer' }}
                          >
                            {user.teams.length > 0
                              ? getTeamStr(user.teams)
                              : null}
                          </Typography>
                          <Popper
                            id={`${id}-${user.id}`}
                            open={popperId === user.id}
                            anchorEl={anchorEl}
                            placement="top"
                            style={{ maxWidth: 364 }}
                          >
                            <Alert icon={false} severity="info">
                              <AlertTitle>
                                {t('userManagement.users.teams')}
                              </AlertTitle>
                              {user.teams
                                ?.map((item) => item.name)
                                ?.join(', ')}
                            </Alert>
                          </Popper>
                        </StyledTableCell>
                      )}

                      {!isMobile && (
                        <StyledTableCell>
                          <Typography className={classes.tableText}>
                            {capitalizeFirstLetter(
                              user?.roles[0]?.status
                            )}
                          </Typography>
                        </StyledTableCell>
                      )}
                      {!isMobile && (
                        <StyledTableCell>
                          {user.last_login && user.verified
                            ? moment(user.last_login).format(
                                'DD/MM/YYYY HH:mm'
                              )
                            : null}
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="right">
                        <IconButton
                          className={classes.moreIcon}
                          onClick={(event) => {
                            setAnchorMenu(event.currentTarget);
                            setUpdateData({
                              ...updateData,
                              userDetail: user,
                            });
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          key={user.id}
                          id="menu-options-user-detail"
                          className={classes.menuOption}
                          anchorEl={anchorMenu}
                          keepMounted
                          open={
                            Boolean(anchorMenu) &&
                            _.isEqual(updateData.userDetail, user)
                          }
                          onClose={() => setAnchorMenu(null)}
                        >
                          <MenuItem
                            className={classes.menuItem}
                            onClick={() => {
                              setAnchorMenu(null);
                              onChangeStatus(user.roles);
                            }}
                          >
                            <Typography
                              className={classes.menuItemText}
                            >
                              {t('userManagement.users.activate')}
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {!loadingUsers && users?.length === 0 && (
              <Box
                marginTop={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.noUserFound}>
                  {t('userManagement.users.no_user_found')}
                </Typography>
              </Box>
            )}
          </Paper>
        </div>
      </Box>
    </ErrorBoundary>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default ArchivedUserPage;
