export const hideEmail = (email) => {
  var split = email.split("@");
  var split1 = split[0];
  var avg = split1.length / 2;
  split1 = split1.substring(0, (split1.length - avg));
  var split2 = split[1];
  return split1 + "...@" + split2;
}

export const isValidPhoneNumber = (number) => {
  return (number.match(/\d/g) || []).length >= 10
}

export const passwordValidation = (password, t) => {
  let lowercaseFormat = /[a-z]/
  let uppercaseFormat = /[A-Z]/
  let numberFormat = /\d/

  if (!uppercaseFormat.test(password)) {
    return t('signup.uppercase_required')
  }
  if (!lowercaseFormat.test(password)) {
    return t('signup.lowercase_required')
  }
  if (!numberFormat.test(password)) {
    return t('signup.number_required')
  }
  if (password.length < 8) {
    return t('signup.min_chars')
  }

  return ''
}