import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  shortDescriptionField: {
    '& .MuiInputBase-root': {
      minHeight: 85,
      display: 'block',
      border: `1px solid ${Colors.GRAY_BORDER3}`,
      borderRadius: 4,
      padding: theme.spacing(3),
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
  descriptionField: {
    '& .MuiInputBase-root': {
      minHeight: 175,
      display: 'block',
      border: `1px solid ${Colors.GRAY_BORDER3}`,
      borderRadius: 4,
      padding: theme.spacing(3),
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
  characterLeftText: {
    ...CommonFonts.darkFont14,
    textAlign: 'end',
  },
  titleText: {
    ...CommonFonts.darkFont14,
    fontWeight: '600',
  },
}));

export default useStyles;
