import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Typography, IconButton } from '@material-ui/core';
import AuthActions from '@/store/modules/auth/actions';
import Avatar from '@/components/header/avatar';
import { selectCurrentUser } from '@/store/modules/auth';
import Button from '@/components/button';

import {
  getBase64FromUrl,
  getFirstLetter,
  getImageAsBase64,
} from '@/utils/stringUtil';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { MUTATE_GENERATE_UPLOAD_URL } from '@/services/storeService';
import { MUTATE_USER_DETAIL } from '@/services/userManagementService';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { useMutation, gql } from '@apollo/client';
import PreLoader from '@/components/preLoader';
import { STOREFRONT_USER_PROFILE_URL } from '@/constants';
import ChangePasswordDialog from './changePasswordDialog';

const Profile = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleChangePassword, setIsVisibleChangePassword] =
    useState(false);

  const {
    logout,
    currentUser: {
      id,
      first_name,
      last_name,
      email,
      mobile,
      profile,
      config: { background_colour },
    },
  } = props;

  const user_image = localStorage.getItem('user_image');

  const onLogout = () => {
    logout();
  };

  const setImageToLocalStorage = async (url, isExist = false) => {
    const base64Image = !isExist
      ? await getBase64FromUrl(url)
      : `data:image/jpeg;base64, ${await getImageAsBase64(url)}`;
    localStorage.setItem('user_image', base64Image);
    localStorage.setItem('user_thumbnail', base64Image);
  };

  const [mutateGenerateURL] = useMutation(
    gql`
      ${MUTATE_GENERATE_UPLOAD_URL}
    `
  );

  const [mutateUserDetail] = useMutation(
    gql`
      ${MUTATE_USER_DETAIL}
    `
  );

  const onUpload = async (file) => {
    if (file) {
      setIsLoading(true);
      try {
        const payload = profile?.id
          ? {
              id: profile?.id,
              name: profile?.name,
              content_type: 'image/jpeg',
            }
          : {
              name: file.name,
              content_type: 'image/jpeg',
            };

        const result = await mutateGenerateURL({
          variables: {
            input: payload,
          },
          ignoreResults: false,
        });
        const generateUploadUrlResponse =
          result?.data?.generateUploadUrl[0];
        if (generateUploadUrlResponse) {
          const url = generateUploadUrlResponse?.upload_url;
          const fetchingResponse = await fetch(
            new Request(url, {
              method: 'PUT',
              body: file,
              headers: new Headers({
                'Content-Type': 'image/jpeg',
              }),
            })
          );

          if (fetchingResponse) {
            if (!profile?.id) {
              await mutateUserDetail({
                variables: {
                  input: {
                    id,
                    profile: {
                      id: generateUploadUrlResponse?.id,
                    },
                  },
                },
              }).then(async (response) => {
                if (response) {
                  await setImageToLocalStorage(
                    response?.data?.updateUser?.profile.url
                  );
                  dispatch(
                    actions.setUserProfile({
                      id: generateUploadUrlResponse?.id,
                      name: file.name,
                      content_type: 'image/jpeg',
                      thumbnail:
                        response?.data?.updateUser?.profile.url,
                      url: response?.data?.updateUser?.profile.url,
                    })
                  );
                  enqueueSnackbar(
                    t('button_group.profile_icon_updated')
                  );
                }
              });
            } else {
              await setImageToLocalStorage(
                URL.createObjectURL(file),
                true
              );
              dispatch(
                actions.setUserProfile({
                  id: profile?.id,
                  name: file.name,
                  content_type: 'image/jpeg',
                  thumbnail: '',
                  url: '',
                })
              );
              enqueueSnackbar(t('button_group.profile_icon_updated'));
            }
          }
        }
      } catch (error) {
        const message = error?.message || error?.error[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div>
          <Avatar
            firstLetter={getFirstLetter(first_name)}
            secondLetter={getFirstLetter(last_name)}
            url={user_image}
            width={150}
            height={150}
            backgroundColor={background_colour}
          />
          <input
            accept="image/*"
            className={classes.uploadInput}
            id="icon-button-file"
            type="file"
            onChange={(e) => onUpload(e.target.files[0])}
          />
          <label htmlFor="icon-button-file">
            <IconButton
              className={classes.cameraIcon}
              aria-label="upload picture"
              component="span"
            >
              <CameraAltIcon size="small" />
            </IconButton>
          </label>
        </div>
        <Typography className={classes.name}>
          {first_name && last_name && `${first_name} ${last_name}`}
        </Typography>
        <Typography className={classes.email}>
          {email ? email : mobile}
        </Typography>
        <a
          className={classes.link}
          href={STOREFRONT_USER_PROFILE_URL}
          target="_blank"
        >
          <Button
            className={classNames(
              classes.button,
              classes.whiteButton
            )}
          >
            {t('button_group.profile')}
          </Button>
        </a>
        <Button
          onClick={() => setIsVisibleChangePassword(true)}
          className={classNames(classes.button, classes.whiteButton)}
        >
          {t('button_group.changePassword')}
        </Button>
        <Button
          onClick={onLogout}
          className={classNames(classes.button, classes.greyButton)}
        >
          {t('button_group.logout')}
        </Button>
      </div>
      {isLoading && <PreLoader size={30} />}
      <ChangePasswordDialog
        userId={id}
        isVisible={isVisibleChangePassword}
        onCloseDialog={() => setIsVisibleChangePassword(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});
const actions = { ...AuthActions };

export default connect(mapStateToProps, actions)(Profile);
