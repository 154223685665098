import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      background: '#FFFFFF',
      opacity: '1',
    },
    titleContainer: {
      marginTop: theme.spacing(0.625),
      width: '100%',
      height: '81px',
      background: '#f3f5f7 ',
      borderRadius: '4px',
      paddingLeft: 28,
      paddingRight: 15,
    },
    title: {
      letterSpacing: '0.14px',
      color: '#000000DE',
      opacity: '1 !important',
      fontSize: 16,
    },
    changeButton: {
      fontSize: 18,
    },
    backIcon: {
      cursor: 'pointer',
    },
    searchInput: {
      '& .MuiInput-root': {
        padding: 15,
        borderRadius: 5,
        ...CommonFonts.darkFont18,
      },
      '& .MuiInput-underline:before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      },
    },
    list: {
      padding: 0,
      maxHeight: 400,
      overflowY: 'scroll',
    },
    listItem: {
      width: '100%',
      '& .MuiListItem-gutters ': {
        paddingLeft: 0,
      },
    },
    addMerchantContainer: {
      padding: theme.spacing(5, 0),
      borderTop: '1px solid rgba(0, 0, 0, 0.42)'
    },
    addMerchantButton: {
      textTransform: 'none',
      fontSize: 14
    },
    noWarehouses: {
      ...CommonFonts.darkFont14
    }
  })
);

export default useStyles;
