import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      marginBottom: theme.spacing(8),
    },

    descriptionText: {
      ...CommonFonts.grayFont16,
      color: '#505C69',
    },
    titleText: {
      ...CommonFonts.darkFont18,
    },
    descriptionText: {
      ...CommonFonts.grayFont14,
    },
    switchHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    boxSwitchPOS: {
      paddingBottom: theme.spacing(10),
    },
    optionsSwitchPOS: {
      ...CommonFonts.darkFont14,
      fontWeight: 600,
      paddingRight: theme.spacing(3),
    },
    boxOptions: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(12),
    },
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    addAction: {
      textTransform: 'capitalize',
      cursor: 'pointer',
      color: '#1174F2',
      fontSize: 14,
    },
  })
);

export default useStyles;
