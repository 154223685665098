import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    boxDialog: {
      '& .MuiPaper-root': {
        backgroundColor: '#1174F2',
        borderRadius: theme.spacing(2),
        maxWidth: theme.spacing(116),
        position: 'absolute',
        bottom: 0,
        right: 0,
      },
      '& .MuiDialog-scrollPaper': {
        display: 'block',
      },
    },
    dialogContent: {
      '&.MuiDialogContent-root': {
        padding: theme.spacing(10),
      },
    },
    boxButton: {
      paddingBottom: theme.spacing(7),
    },
    actionButton: {
      ...CommonFonts.whiteFont18,
      fontWeight: '400',
      textTransform: 'none',
      padding: theme.spacing(3, 11),
      color: Colors.WHITE,
      borderColor: Colors.WHITE,
      borderWidth: '2px',
      maxWidth: theme.spacing(40),
      maxHeight: theme.spacing(12),
    },
    title: {
      ...CommonFonts.whiteFont20,
      fontWeight: '500',
      paddingBottom: theme.spacing(5),
    },
    description: {
      ...CommonFonts.whiteFont18,
      fontWeight: '300',
      textAlign: 'center',
      paddingBottom: theme.spacing(12),
    },
    remind: {
      ...CommonFonts.whiteFont14,
      fontWeight: '300',
      cursor: 'pointer',
    },
  })
);

export default useStyles;
