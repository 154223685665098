import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    rootRow: {
      border: '1px solid #e0e0e0'
    },
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT
    },
    boxLink: {
      padding: theme.spacing(4)
    },
    boxLinkActive: {
      padding: theme.spacing(4),
      backgroundColor: '#e7f1fe',
      fontWeight: '600'
    },
    breadcrumbsText: {
      fontSize: 16
    },
    linkText: {
      ...CommonFonts.darkFont18
    },
    boxModules: {
      backgroundColor: Colors.WHITE,
      padding: theme.spacing(5),
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(7)
    },
    boxTable: {
      marginTop: theme.spacing(7)
    },
    title: {
      ...CommonFonts.darkFont16,
      fontWeight: '600',
      marginTop: theme.spacing(2)
    },
    tableText: {
      ...CommonFonts.darkFont18
    },
    noDataFound: {
      ...CommonFonts.darkFont16
    },
    headerTableText: {
      ...CommonFonts.darkFont14
    },
    boxFullName: {
      wordBreak: 'break-word',
      padding: theme.spacing(2.5, 0)
    },
    actionLink: {
      color: '#1174F2',
      ...CommonFonts.blueFont18,
      cursor: 'pointer'
    },
    root: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    headerText: {
      textAlign: 'center'
    },
    gridItem: {
      paddingBottom: theme.spacing(10)
    },
    descriptionLabel: {
      fontSize: '18px',
      textAlign: 'start',
      color: '#505C69'
    },
    descriptionCounter: {
      float: 'right'
    },
    descriptionInput: {
      paddingTop: theme.spacing(4)
    },
    link: {
      cursor: 'pointer'
    },
    gridSpacing: {
      paddingBottom: theme.spacing(4)
    },
    documentation: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(4)
    }
  })
);


export default useStyles;
