import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TableHead,
  TableRow,
  TableCell,
  withStyles,
} from '@material-ui/core';

import { CommonFonts } from '@/theme';

const TableHeader = () => {
  const { t } = useTranslation();

  const headCells = [
    {
      id: 'opening_checkbox',
      label: t('workboard.manage_store.opening_hours_dialog.opening_day'),
      width: '30%',
    },
    {
      id: 'opens_at',
      label: t('workboard.manage_store.opening_hours_dialog.opens_at'),
      width: '35%',
    },
    {
      id: 'closes_at',
      label: t('workboard.manage_store.opening_hours_dialog.closes_at'),
      width: '35%',
    },
    {
      id: 'empty',
      label: '',
      width: '30%',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            style={{
              width: headCell.width,
              borderBottom: 'none',
            }}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
    ...CommonFonts.darkFont14,
    fontWeight: 'normal',
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont14,
  },
}))(TableCell);

export default TableHeader;
