import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Button, CircularProgress } from '@material-ui/core'

import useStyles from './styles'

const CustomButton = (props) => {
  const classes = useStyles()
  
  const {
    color,
    className,
    pendingClassname,
    pending,
    children,
    onClick,
    ...restProps
  } = props

  return (
    <Button
      fullWidth
      className={classNames(className, {
        [classes.primaryButton]: color === 'primary'
      })}
      onClick={() => pending ? {} : onClick()}
      {...restProps}
    >
      {pending
        ?
        <CircularProgress
          className={pendingClassname}
          size={20}
        />
        :
        children
      }
    </Button>
  )
}

CustomButton.propTypes = {
  className: PropTypes.string,
  pendingClassname: PropTypes.string,
  pending: PropTypes.bool,
  children: PropTypes.any,
  onClick: PropTypes.func
}

export default CustomButton