import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@material-ui/core'
import ProductSearchInput from '@/components/productSearchInput'


import useStyles from './styles';

const SearchProductLayout = ({ onSelectProduct }) => {
  const classes = useStyles();
  const { t } = useTranslation()

  return (
    <Box className={classes.root}>
      <Typography className={classes.findText}>
        {t('workboard.product_intelligence.find_product')}
      </Typography>

      <Box className={classes.inputContainer}>
        <ProductSearchInput
          onSelectProduct={onSelectProduct}
        />
      </Box>
    </Box>
  )
}

export default SearchProductLayout