import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%'
    },
    titleText: {
      ...CommonFonts.grayBoldFont20,
      color: Colors.DIM_GREY,
      fontWeight: 500
    },
    compareStoreContainer: {
      marginRight: isMobile ? 0 : theme.spacing(10),
    },
    sliderContainer: {
      marginTop: theme.spacing(10),
    },
  })
)

export default useStyles
