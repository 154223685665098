import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  configureText: {
    ...CommonFonts.darkFont16,
    marginLeft: theme.spacing(4)
  },
}));

export default useStyles;
