import React from "react";
import { useTranslation } from 'react-i18next'

import { Draggable } from "react-beautiful-dnd";
import { Box, Typography } from "@material-ui/core";
import { DragIndicator } from '@material-ui/icons'
import ActionMenu from '../actionMenu'

import { Colors } from '@/theme';
import images from '@/assets/images'
import useStyles from "./styles";

const DragableWidget = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    keyValue,
    draggableId,
    index,
    widget,
    onRemoveWidget,
    children
  } = props

  const actionMenus = [
    {
      name: t('button_group.remove'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        onRemoveWidget(data)
      },
    },
  ]

  return (
    <Draggable key={keyValue} draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <Box
          className={classes.root}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Box
            className={classes.dragButton}
            role="button"
            {...provided.dragHandleProps}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Typography className={classes.dragDropText}>
              {t('workboard.drag_drop')}
            </Typography>
          </Box>
          <Box
            className={classes.actionButton}
          >
            <ActionMenu
              data={widget}
              actionMenus={actionMenus}
            />
          </Box>
          {children}
        </Box>
      )}
    </Draggable>
  )
}

export default DragableWidget