import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setTutorials: ['tutorials'],
  runTrigger: ['trigger'],
  initState: ['stateName', 'data']
});

export const TutorialsTypes = Types;
export default Creators;
