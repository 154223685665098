import React, { useState } from 'react';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@material-ui/core';

import AppLayout from '@/components/appLayout';
import SignupModal from '@/components/signupModal';
import images from '@/assets/images';

import EmailLoginLayout from './emailLoginLayout';
import MobileLoginLayout from './mobileLoginLayout';

import AuthActions, { AuthTypes } from '@/store/modules/auth/actions';
import { selectIsApiProgress } from '@/store/modules/apiLoopState';

import useStyles from './styles';

const LoginPage = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    createUser,
    isCreatingUser
  } = props

  const [emailLogin, setEmailLogin] = useState(true)
  const handleLoginLayout = () => setEmailLogin(!emailLogin)

  const [openSignupModal, setOpenSignupModal] = useState(false);
  const handleSignupModal = () => setOpenSignupModal(!openSignupModal);

  const [hasSignupSucceed, setHasSignupSucceed] = useState(false);
  const [hasSignupFailed, setHasSignupFailed] = useState(false);

  const onEmailSignup = ({ email, password }) => {
    createUser({
      email,
      password,
      redirect_url: '',
      onSuccess: () => setHasSignupSucceed(true),
      onFailed: () => setHasSignupFailed(true),
    });
  };

  return (
    <AppLayout className={classes.appLayout} withFooter>
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6} lg={6} xl={7}>
          <img src={images.logo} width={163} height={65} alt="logo" />
          <div className={classes.loginDescription}>
            {t('login_page.login_description')}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          xl={5}
          className={classes.loginLayout}
        >
          <Box className={classes.loginLayoutWrapper}>
            {emailLogin
              ?
              <EmailLoginLayout
                onChangeLoginLayout={handleLoginLayout}
              />
              :
              <MobileLoginLayout
                onChangeLoginLayout={handleLoginLayout}
              />
            }
            <Typography className={classes.textsContainer}>
              <span className={classes.newMydaText}>{t('login_page.new_myda')}</span>
              <span
                className={classes.createText}
                onClick={handleSignupModal}
              >
                {t('login_page.create_account')}
              </span>
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {openSignupModal && (
        <SignupModal
          open={openSignupModal}
          onClose={handleSignupModal}
          onEmailLogin={handleSignupModal}
          onEmailSignup={onEmailSignup}
          isSignupProgress={isCreatingUser}
          hasSignupSucceed={hasSignupSucceed}
          handleSignupSucceed={() => setHasSignupSucceed(false)}
          hasSignupFailed={hasSignupFailed}
          handleSignupFailed={() => setHasSignupFailed(false)}
        />
      )}
    </AppLayout>
  );
};

const mapStateToProps = (state) => ({
  isCreatingUser: selectIsApiProgress(state, AuthTypes.CREATE_USER),
});

const actions = { ...AuthActions };

export default connect(mapStateToProps, actions)(LoginPage);
