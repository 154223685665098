import React, { useState } from 'react';
import {
  Box,
  Dialog,
  Select,
  Button,
  MenuItem,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  FormHelperText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';
import _ from 'lodash';

const SelectPicker = ({
  name,
  value,
  label,
  labelStyle,
  placeholder,
  currentId,
  options,
  onChange,
  validationSchema,
  description,
  descriptionType = false,
  titleDialog,
  disableNotification = false,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lowerCaseTitle = label.toLowerCase();

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = (isResetForm = true) => {
    setOpenEditModal(false);
    handleReset();
    if (!selectValue) {
      setSelectValue('');
    } else if (selectValue && isResetForm) {
      setSelectValue(value);
    }
  };

  const [selectValue, setSelectValue] = useState(value);

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    errors,
    setFieldError,
    setFieldValue,
    touched,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      [name]: value,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await onChange(values[name]);
        handleCloseEditModal(false);
        if (!disableNotification) {
          enqueueSnackbar(
            `${capitalizeEachLetter(label)} ${
              value ? t('settings.updated') : t('settings.added')
            }`
          );
        }
      } catch (error) {
        setFieldError(name, t('settings.error.update'));
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      {!descriptionType ? (
        <Box style={labelStyle} className={classes.box}>
          <Typography className={classes.title}>{label}</Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {selectValue ? (
              <Typography className={classes.value}>
                {selectValue}
              </Typography>
            ) : (
              <Typography
                onClick={handleOpenEditModal}
                className={classes.selectAction}
              >
                {value ? t('settings.update') : t('settings.add')}
              </Typography>
            )}
            <IconButton
              disabled={disabled}
              className={classes.editIcon}
              aria-label={`edit-${lowerCaseTitle}`}
              onClick={handleOpenEditModal}
            >
              <CreateIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box className={classes.boxWithDescription}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className={classes.titleText}>
              {label}
            </Typography>
            <Typography
              onClick={handleOpenEditModal}
              className={classes.addAction}
            >
              {value ? t('settings.update') : t('settings.add')}
            </Typography>
          </Box>
          <Typography
            style={{ width: '90%' }}
            className={classes.descriptionText}
          >
            {description}
          </Typography>
        </Box>
      )}

      <Dialog
        fullWidth
        fullScreen={fullScreen}
        onClose={isLoading ? () => {} : handleCloseEditModal}
        aria-labelledby={`{edit-${lowerCaseTitle}-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{edit-${lowerCaseTitle}-dialog-title}`}
          onClose={handleCloseEditModal}
        >
          {titleDialog ? titleDialog : `Update ${lowerCaseTitle}`}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Select
              disabled={disabled}
              fullWidth
              displayEmpty
              id={name}
              name={name}
              defaultValue={value}
              value={selectValue}
              onChange={(event) => {
                const value = {
                  ...options.find(
                    (item) => item.name === event.target.value
                  ),
                  ...{ currentId: currentId },
                };
                setSelectValue(event.target.value);
                setFieldValue(name, value);
              }}
              inputProps={{ 'aria-label': 'Without label' }}
              error={touched[name] && Boolean(errors[name])}
              onBlur={handleBlur}
            >
              <MenuItem value="" disabled>
                {placeholder}
              </MenuItem>
              {options?.map((option, index) => {
                return (
                  <MenuItem
                    key={`${option.name}-${index}`}
                    value={option.name}
                  >
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
            {Boolean(errors[name]) && touched[name] && (
              <FormHelperText
                error={Boolean(errors[name]) && touched[name]}
              >
                {errors[name]?.id || errors[name]?.name}
              </FormHelperText>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={!isValid || _.isEmpty(selectValue)}
            onClick={handleSubmit}
            type="submit"
            color="primary"
          >
            {t('settings.save')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default SelectPicker;
