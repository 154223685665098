import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, Button, Box } from '@material-ui/core'
import NumberInput from '@/components/numberInput'
import SingleCheckboxInput from '@/components/singleCheckbox'

import useStyles from './styles'

const AddonGroup = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    group,
    updateGroup,
    removeGroup,
  } = props

  return (
    <Grid container alignItems='center' className={classes.addonWrapper}>
      <Grid item xs={12} md={4} lg={2}>
        <Typography className={classes.titleText}>
          {group.group}
        </Typography>
      </Grid>

      <Grid item xs={12} md={4} lg={2}>
        <SingleCheckboxInput
          style={{ paddingBottom: 0 }}
          isShowToast={false}
          value={group.multi_selection}
          label={t('add_product.multi_selection')}
          onChange={(value) => {
            updateGroup({ ...group, multi_selection: value })
          }}
        />
      </Grid>

      <Grid item xs={12} md={4} lg={2}>
        <SingleCheckboxInput
          style={{ paddingBottom: 0 }}
          isShowToast={false}
          value={group.default_all}
          label={t('add_product.default_all')}
          onChange={(value) => {
            updateGroup({ ...group, default_all: value })
          }}
        />
      </Grid>

      <Grid item xs={12} md={4} lg={2}>
        <SingleCheckboxInput
          style={{ paddingBottom: 0 }}
          isShowToast={false}
          value={group.limit_free_options}
          label={t('add_product.limit_free_options')}
          onChange={(value) => {
            updateGroup({ 
              ...group, 
              limit_free_options: value, 
              allow_free: value ? group.allow_free : 0
            })
          }}
        />
      </Grid>

      <Grid item xs={12} md={4} lg={2}>
        {group.limit_free_options &&
          <Box style={{ width: 200 }}>
            <NumberInput
              title={t('add_product.no_of_free_options')}
              value={group.allow_free}
              onChange={(value) => updateGroup({ ...group, allow_free: value })}
            />
          </Box>
        }
      </Grid>

      <Grid item xs={12} md={4} lg={2}>
        <Box display='flex' justifyContent='flex-end'>
          <Button
            color="primary"
            onClick={() => removeGroup(group)}
            className={classes.removeButton}
          >
            {t('add_product.remove')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default AddonGroup