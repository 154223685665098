import React from 'react'
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogContent,
  Box,
  Typography
} from '@material-ui/core'

import images from '@/assets/images'
import useStyles from './styles'

const WelcomeModal = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    open,
    onClose,
    onStart,
    onSkip
  } = props

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent className={classes.rootDialog}>
        <Box display='flex' justifyContent='center'>
          <img src={images.connect} width={75} height={70} alt="connect" />
        </Box>

        <Typography className={classes.welcomeText}>
          {t('joy_rider.welcome_myda')}
        </Typography>
        <Typography className={classes.startConnectText}>
          {t('joy_rider.start_connect')}
        </Typography>
        <Typography className={classes.simpleStartText}>
          {t('joy_rider.simple_start')}
        </Typography>

        <Box className={classes.buttonWrapper}>
          <Button
            disableRipple
            color="primary"
            onClick={onStart}
            className={classes.startButton}
          >
            {t('joy_rider.quick_guide')}
          </Button>
        </Box>
        <Box className={classes.buttonWrapper}>
          <Button
            disableRipple
            color="primary"
            onClick={onSkip}
            className={classes.skipButton}
          >
            {t('joy_rider.skip')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default WelcomeModal