import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.darkFont14,
      fontWeight: '600',
    },
    actionLink: {
      ...CommonFonts.darkFont14,
      fontWeight: '600',
      color: '#1174F2',
      cursor: 'pointer',
    },
    chipFloor: {
      marginRight: theme.spacing(4),
      backgroundColor: '#F3F5F7',
    },
  })
);

export default useStyles;
