import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames'

import { Grid, Container, Hidden } from '@material-ui/core';
import Menu from '@/components/header/menu';
import UserInfo from '@/components/header/userInfo';
import Modal from '@/components/modal';
import Profile from '@/components/header/profile';
import ChooseStore from '@/components/header/choose-store';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ServicesDropdown from './servicesDropdown';
import AddNewWarehouse from '@/pages/warehouses/addNewStore'

import { selectMerchants } from '@/store/modules/auth/selectors';
import { selectSelectedRegion } from '@/store/modules/store/selectors';
import MerchantActions from '@/store/modules/store/actions';

import { MERCHANT_SETTINGS } from '@/services/storeService';
import { gql, useQuery, useLazyQuery } from '@apollo/client';

import { TUTORIAL_TARGET_CLASSES } from '@/components/joyRider/helper'

import useStyles from './styles';

const Header = ({ withServiceDropdown, withAllStoresOption }) => {
  const classes = useStyles();

  const allMerchants = useSelector(selectMerchants);
  const selectedRegion = useSelector(selectSelectedRegion);
  const { selectedStore, currentMerchant } = useSelector(
    (state) => state.store
  );
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElStore, setAnchorElStore] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogSearchStore, setOpenDialogSearchStore] = useState(false);
  const [arrow, setArrow] = useState('down');

  const [merchantId, setMerchantId] = useState(null)
  const [openNewWarehouseModal, setOpenNewWarehouseModal] = useState(false)

  useQuery(
    gql`
      ${MERCHANT_SETTINGS}
    `,
    {
      onCompleted: (data) => {
        dispatch(
          MerchantActions.setMerchantSettings(data?.merchantSettings)
        );
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    dispatch(MerchantActions.setSelectedRegion(null));
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setOpenDialog(!openDialog);
  }, []);

  const handleOpenSeachStoreDialog = useCallback((event) => {
    setAnchorElStore(event.currentTarget);
    setOpenDialogSearchStore(!openDialogSearchStore);
  }, []);

  // opens the popover for the stores selection
  const openStoresPopover = (event) => {
    setAnchorElStore(event.currentTarget);
    setArrow('up');
  };

  const handleCloseStorePopover = () => {
    setAnchorElStore(null);
    setArrow('down');
  };

  const open = Boolean(anchorElStore);
  const id = open ? 'simple-popover' : undefined;

  const getStoreName = () => {
    if (selectedRegion) {
      return selectedRegion?.name;
    } else {
      return selectedStore.name;
    }
  };

  return (
    <>
      <Container fixed className={classes.container}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.wrapper}
        >
          <Grid item xs={8} lg={9}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              className={classes.wrapper}
              spacing={10}
            >
              {/* <div className={classes.leftGrid}> */}
              <Grid item>
                <Menu />
              </Grid>
              <Hidden smDown>
                <Grid item>
                  <TextField
                    onClick={openStoresPopover}
                    className={classNames(classes.inputUnderLine, TUTORIAL_TARGET_CLASSES.STORE_SELECT)}
                    value={getStoreName()}
                    InputProps={{
                      // shrink: false,
                      className: classes.inputColor,
                      disableUnderline: open ? false : true,
                      endAdornment: (
                        <InputAdornment position="start">
                          {arrow === 'down' ? (
                            <ExpandMoreIcon
                              className={classes.icon}
                            />
                          ) : (
                            <ExpandLessIcon
                              className={classes.icon}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Hidden>
              <Hidden mdDown>
                <Grid item>
                  {withServiceDropdown && <ServicesDropdown />}
                </Grid>
              </Hidden>
            </Grid>
          </Grid>

          <Grid item xs={4} lg={3}>
            <UserInfo
              handleOpenDialog={handleOpenDialog}
              handleOpenSeachStoreDialog={handleOpenSeachStoreDialog}
            />
          </Grid>
        </Grid>

        <Hidden mdUp>
          <Grid container justify="center">
            <Grid item>
              <TextField
                onClick={openStoresPopover}
                className={classNames(classes.inputUnderLine, TUTORIAL_TARGET_CLASSES.STORE_SELECT)}
                value={getStoreName()}
                InputProps={{
                  // shrink: false,
                  className: classes.inputColor,
                  disableUnderline: open ? false : true,
                  endAdornment: (
                    <InputAdornment position="start">
                      {arrow === 'down' ? (
                        <ExpandMoreIcon className={classes.icon} />
                      ) : (
                        <ExpandLessIcon className={classes.icon} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Hidden>
      </Container>

      <Modal
        openDialog={openDialog}
        handleClose={handleCloseDialog}
        anchorEl={anchorEl}
      >
        <Profile />
      </Modal>
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorElStore}
          onClose={handleCloseStorePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            root: classes.selectStorePopoverContainer, // class name, e.g. `classes-nesting-root-x`
          }}
        >
          <ChooseStore
            currentMerchant={currentMerchant}
            allMerchants={allMerchants}
            withAllStoresOption={withAllStoresOption}
            handleCloseStorePopover={handleCloseStorePopover}
            onOpenAddWarehouse={(merchant_id) => {
              setMerchantId(merchant_id)
              setOpenNewWarehouseModal(true)
            }}
          />
        </Popover>

        {openNewWarehouseModal &&
          <AddNewWarehouse
            open={openNewWarehouseModal}
            handleClose={() => setOpenNewWarehouseModal(false)}
            merchantId={merchantId}
          />
        }
      </div>
    </>
  );
};

Header.propTypes = {
  withServiceDropdown: PropTypes.bool,
  withAllStoresOption: PropTypes.bool,
};

export default Header;
