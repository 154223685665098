import React, { useEffect } from 'react';
import SelectPicker from '@/components/selectPicker';
import { useTranslation } from 'react-i18next';
import { REGISTRATION_TAXES_LIST } from '@/services/storeService';
import { gql, useLazyQuery } from '@apollo/client';
import * as Yup from 'yup';
import { Grid, Box, CircularProgress } from '@material-ui/core';
import SettingInput from '../input';

const RegistrationTaxesPicker = ({
  isFetch,
  value,
  onChange,
  onChangeNumber,
}) => {
  const { t } = useTranslation();

  const registrationTaxesQuery = gql`
    ${REGISTRATION_TAXES_LIST}
  `;
  const [getInitData, { data, previousData, loading }] = useLazyQuery(
    registrationTaxesQuery
  );

  const taxTypes =
    data?.registrationTypes[0]?.taxes ||
    previousData?.registrationTypes[0]?.taxes;

  const getCurrentTax = (id) =>
    value?.find((item) => item.parent === id);

  useEffect(() => {
    if (isFetch) {
      getInitData();
    }
  }, [isFetch]);

  return (
    <>
      {!loading ? (
        taxTypes?.map((type) => {
          const titleDialog = t(
            'settings.business_and_finance.update_registration_tax',
            {
              name: type.name,
            }
          );
          const label = t(
            'settings.business_and_finance.input.registration_tax',
            {
              name: type.name,
            }
          );

          const registrationTaxesSchema = Yup.object().shape({
            [`registration_tax_${type.name}`]: Yup.object({
              id: Yup.string().required(
                t(
                  'settings.business_and_finance.registration_tax_required',
                  {
                    name: type.name,
                  }
                )
              ),
              name: Yup.string().required(
                t(
                  'settings.business_and_finance.registration_tax_required',
                  {
                    name: type.name,
                  }
                )
              ),
            }),
          });

          const vatNumberSchema = Yup.object().shape({
            [`vat_number_${type.name}`]: Yup.string().required(
              t('settings.business_and_finance.vat_number_required', {
                name: type.name,
              })
            ),
          });

          const currentTax = getCurrentTax(type.id);
          return (
            <Grid container key={type.id}>
              <Grid item xs={12} sm={4}>
                <SelectPicker
                  name={`registration_tax_${type.name}`}
                  label={label}
                  placeholder={t(
                    'settings.business_and_finance.select_type'
                  )}
                  options={type.options}
                  currentId={currentTax?.id || ''}
                  value={currentTax?.name || ''}
                  onChange={onChange}
                  validationSchema={registrationTaxesSchema}
                  titleDialog={titleDialog}
                />
              </Grid>
              {currentTax?.reg_required && (
                <Grid item xs={12} sm={4}>
                  <SettingInput
                    name={`vat_number_${type.name}`}
                    label={t(
                      'settings.business_and_finance.input.vat_number',
                      { name: type.name }
                    )}
                    value={currentTax?.reg_number || ''}
                    validationSchema={vatNumberSchema}
                    onChange={onChangeNumber}
                  />
                </Grid>
              )}
            </Grid>
          );
        })
      ) : (
        <Box
          marginBottom={10}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={30} />
        </Box>
      )}
    </>
  );
};

export default RegistrationTaxesPicker;
