import React from 'react';
import Grid from '@material-ui/core/Grid';
import images from '@/assets/images';
import useStyles from './styles';

const NoDebts = ({ title }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={10}
      style={{ marginBottom: 20 }}
    >
      <Grid item>
        <img src={images.noData} width={151} height={151} />
      </Grid>
      <Grid item>
        <span className={classes.emptyProductsTextL}>{title}</span>
      </Grid>
    </Grid>
  );
};

export default NoDebts;
