import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  DialogActions,
  Typography,
  Paper,
  Button,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSelectedStore } from '@/store/modules/store';
import { gql, useMutation, useQuery } from '@apollo/client';
import { TOUCHPOINT_SETTING } from '@/services/storeService';
import {
  filter,
  isEmpty,
  omit,
  some,
  uniq,
  uniqBy,
  map,
  isEqual,
} from 'lodash';
import SearchHeader from './components/SearchHeader';
import NoProducts from './components/NoProducts';
import ProductsTable from './table';
import { contains } from '@/utils/stringUtil';
import {
  UPDATE_PRODUCTS_ON_TOUCHPOINTS,
  GET_PRODUCTS_WITH_TOUCHPOINTS,
} from '@/services/touchpointsManagementService';
import DialogContent from '@material-ui/core/DialogContent';
import useStyles from './styles';

const ManageProductsOnTouchpoints = ({ open, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const currentStore = useSelector(selectSelectedStore);
  const selectedStore = useSelector(selectSelectedStore);
  let categories = [];

  const [isLoading, setIsLoading] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [products, setProducts] = useState([]);
  const [updating, setIsUpdating] = useState(false);
  const [posData, setPosData] = useState([]);
  const [self_order_kioskData, setSelf_order_kioskData] = useState(
    []
  );
  const [digital_frontData, setDigital_frontData] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [filterCategory, setFilterCategory] = useState('');
  const [isChanged, setIsChanged] = useState(false);

  const { data: allTouchpointData, loading: isLoadingTouchpoints } =
    useQuery(
      gql`
        ${TOUCHPOINT_SETTING}
      `,
      {
        onCompleted: (data) => {
          const digital =
            data?.store?.settings?.touchpoint_settings?.digital_front
              ?.product_view?.hidden_products || [];
          const pos =
            data?.store?.settings?.touchpoint_settings?.pos
              ?.product_view?.hidden_products || [];
          const kiosk =
            data?.store?.settings?.touchpoint_settings
              ?.self_order_kiosk?.product_view?.hidden_products || [];
          setDigital_frontData(digital);
          setPosData(pos);
          setSelf_order_kioskData(kiosk);
        },
        fetchPolicy: 'no-cache',
      }
    );

  const setCheckedData = (item, type, checked) => {
    setIsChanged(true);
    if (type === 'pos') {
      let newPosData = posData.filter((id) => id !== item.id);
      if (!checked) {
        newPosData = [...newPosData, item.id];
      }
      setPosData(newPosData);
    } else if (type === 'self_order_kiosk') {
      let newKioskData = self_order_kioskData.filter(
        (id) => id !== item.id
      );
      if (!checked) {
        newKioskData = [...newKioskData, item.id];
      }
      setSelf_order_kioskData(newKioskData);
    } else {
      let newFrontData = digital_frontData.filter(
        (id) => id !== item.id
      );
      if (!checked) {
        newFrontData = [...newFrontData, item.id];
      }
      setDigital_frontData(newFrontData);
    }
  };

  // get products from api
  const {
    data: data,
    loading: isLoadingProducts,
    refetch: refetch,
  } = useQuery(GET_PRODUCTS_WITH_TOUCHPOINTS, {
    variables: {
      ignore_categories: true,
    },
    onCompleted: (data) => {
      // update the array holding products
      updating
        ? setProducts(data.products)
        : setProducts([...products, ...data.products]);
      setIsLoading(false);
      setIsUpdating(false);
    },
    onError: (e) => {
      console.log(e, 'error');
    },
    fetchPolicy: 'no-cache',
  });

  categories = uniq(map(map(products, 'category'), 'name'));

  const [UpdateData, { loading }] = useMutation(
    UPDATE_PRODUCTS_ON_TOUCHPOINTS,
    {
      onCompleted: (response) => {
        setIsUpdating(true);
        handleClose();
        enqueueSnackbar(
          t('workboard.manage_store.touchpoints.updated')
        );
      },
      onError: (error) => {
        handleClose();
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      },
    }
  );

  useEffect(() => {
    isReady && updateProductsOnTouchpoints();
  }, [isReady]);

  const updateProductsOnTouchpoints = () => {
    setIsReady(false);

    const payload = {
      variables: {
        store: {
          id: currentStore.id,
          settings: {
            touchpoint_settings: {
              digital_front: {
                product_view: {
                  hidden_products: digital_frontData,
                },
              },
              pos: {
                product_view: {
                  hidden_products: posData,
                },
              },
              self_order_kiosk: {
                product_view: {
                  hidden_products: self_order_kioskData,
                },
              },
            },
          },
        },
      },
    };
    return UpdateData(payload);
  };

  useEffect(() => {
    setProducts([]);
  }, [selectedStore]);

  const filteredProducts = filter(
    filter(products, function (item) {
      filterCategory && contains(item.category.name, filterCategory);
      return (
        contains(item.name, inputSearch) ||
        contains(item.bar_code, inputSearch)
      );
    }),
    function (item) {
      return contains(item.category.name, filterCategory);
    }
  );

  const onChangeHeaderCheckbox = (type, checked) => {
    const productsToApply = [...filteredProducts];

    setIsChanged(true);
    if (type === 'pos') {
      let newPosData = [];
      if (checked) {
        newPosData = posData.filter(
          (id) => !productsToApply.find((item) => item.id === id)
        );
      } else {
        newPosData = uniq([
          ...posData,
          ...productsToApply.map((item) => item.id),
        ]);
      }
      setPosData(newPosData);
    } else if (type === 'self_order_kiosk') {
      let newKioskData = [];
      if (checked) {
        newKioskData = self_order_kioskData.filter(
          (id) => !productsToApply.find((item) => item.id === id)
        );
      } else {
        newKioskData = uniq([
          ...self_order_kioskData,
          ...productsToApply.map((item) => item.id),
        ]);
      }
      setSelf_order_kioskData(newKioskData);
    } else {
      let newFrontData = [];
      if (checked) {
        newFrontData = digital_frontData.filter(
          (id) => !productsToApply.find((item) => item.id === id)
        );
      } else {
        newFrontData = uniq([
          ...digital_frontData,
          ...productsToApply.map((item) => item.id),
        ]);
      }
      setDigital_frontData(newFrontData);
    }

    setProducts(
      products.map((item) =>
        !!productsToApply?.find((product) => product.id === item.id)
          ? {
              ...item,
              touchpoints: uniqBy(
                [...item.touchpoints, { type }],
                'type'
              ).filter((touchpoint) =>
                checked ? true : touchpoint.type !== type
              ),
            }
          : item
      )
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          alignContent="center"
        >
          <Grid item xs={1} />
          <Grid className={classes.headerText} item xs={10}>
            {t('workboard.manage_store.products_on_touchpoints')}
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            <IconButton
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Typography
          align="center"
          className={classes.enterBarcodeText}
        >
          {t('workboard.manage_store.touchpoints.description')}
        </Typography>
        <SearchHeader
          categories={categories}
          onChangeSearch={setInputSearch}
          onChangeCategories={setFilterCategory}
        />
      </DialogTitle>
      <DialogContent id="scrollableDiv">
        <Grid item xs={12}>
          <Paper
            className={classes.emptyExpensesContainer}
            elevation={0}
          >
            {isEmpty(filteredProducts) && !isLoadingProducts ? (
              <NoProducts
                title={t(
                  'workboard.manage_store.touchpoints.not_found'
                )}
              />
            ) : (
              <ProductsTable
                products={filteredProducts}
                isLoadingProducts={isLoadingProducts || isLoading}
                changedData={(item, type, checked) =>
                  setCheckedData(item, type, checked)
                }
                onChangeHeaderCheckbox={onChangeHeaderCheckbox}
              />
            )}
          </Paper>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading || !isChanged}
          onClick={() => {
            setIsLoading(true);
            updateProductsOnTouchpoints();
          }}
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          className={classes.submit}
        >
          {t('workboard.manage_store.touchpoints.update')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageProductsOnTouchpoints;
