import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, Button, Box } from '@material-ui/core'
import NumberInput from '@/components/numberInput'
import Input from '../../components/input'
import SingleCheckboxInput from '@/components/singleCheckbox'

import { getLocalisationVal, transformInput } from '@/utils/localisationUtil';
import { capitalizeFirstLetter } from '@/utils/stringUtil'
import { MEASURE_STR } from './helper'
import useStyles from '../styles'

const Addon = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    addon,
    addonIndex,
    updateAddons,
    removeAddons,
    localisation
  } = props

  return (
    <Grid container alignItems='center' className={classes.addonWrapper}>
      <Grid item xs={12} md={11}>
        <Grid container alignItems='center'>
          <Grid item xs={12} md={4} lg={2}>
            <Typography className={classes.titleText}>
              {addon.name}
            </Typography>
            <Typography className={classes.barcodeText}>
              {addon.bar_code}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <Box style={{ paddingRight: 24 }}>
              <NumberInput
                title={MEASURE_STR[addon.measure_type]}
                value={addon.measure_amount}
                onChange={(value) => {
                  updateAddons(addonIndex, {
                    ...addon,
                    measure_amount: value,
                    extra: +addon.extra < value ? value : addon.extra // measure_amount should be less than extra
                  })
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <Box style={{ paddingRight: 24 }}>
              <Input
                isCurrencyInput={localisation.currency_decimal}
                localisation={localisation}
                title={`${t('addons.price')} / ${capitalizeFirstLetter(addon.measure_type)}`}
                emptyLabel={t('add_product.add')}
                value={addon.fixed_price
                  ? getLocalisationVal(localisation, addon.fixed_price)
                  : addon.fixed_price
                }
                onChange={(value) => {
                  updateAddons(addonIndex, {
                    ...addon,
                    fixed_price: transformInput(localisation, value)
                  })
                }}
                onBlur={() => { }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <Box style={{ paddingRight: 24 }}>
              <NumberInput
                title={`${t('addons.max')} ${MEASURE_STR[addon.measure_type]}`}
                value={addon.extra}
                onChange={(value) => {
                  updateAddons(addonIndex, {
                    ...addon,
                    extra: value,
                    measure_amount: +addon.measure_amount > value ? value : addon.measure_amount // measure_amount should be less than extra
                  })
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <SingleCheckboxInput
              style={{ paddingBottom: 0 }}
              isShowToast={false}
              value={addon.force_charge}
              label={t('addons.force_charge')}
              labelClass={classes.checkBoxLabel}
              onChange={(value) => {
                updateAddons(addonIndex, {
                  ...addon,
                  force_charge: value
                })
              }}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <SingleCheckboxInput
              style={{ paddingBottom: 0, fontSize: 12 }}
              isShowToast={false}
              value={addon.default}
              label={t('addons.select_by_default')}
              labelClass={classes.checkBoxLabel}
              onChange={(value) => {
                updateAddons(addonIndex, {
                  ...addon,
                  default: value
                })
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={1}>
        <Box display='flex' justifyContent='flex-end'>
          <Button
            color="primary"
            onClick={() => removeAddons(addonIndex)}
            className={classes.removeButton}
          >
            {t('addons.remove')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Addon