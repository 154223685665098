import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    addButtonWrapper: {
      backgroundColor: Colors.WHITE,
      position: 'absolute',
      bottom: 0,
      left: 0,
      borderWidth: 1,
      borderColor: Colors.DISABLED,
      borderStyle: 'solid',
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      borderTopLeftRadius: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        bottom: 0,
        borderBottomLeftRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        borderTopRightRadius: theme.spacing(0),
        borderTopLeftRadius: theme.spacing(0),
      },
    },
    addButton: {
      textTransform: 'none',
      marginRight: theme.spacing(3),
    },

    selectButton: {
      ...CommonFonts.blueBoldFont18,
      cursor: 'pointer',
      fontWeight: 400,
    },
    disabledSelectButton: {
      ...CommonFonts.blueBoldFont18,
      fontWeight: 400,
      opacity: '50%',
    },
    valueSelected: {
      ...CommonFonts.darkFont18,
      color: '#20272FDE',
    },
    label: {
      ...CommonFonts.darkFont18,
      padding: theme.spacing(1, 0),
      cursor: 'pointer',
      borderBottom: '1px solid rgba(0, 0, 0, 0)',
      '&:hover': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      },
      '& .MuiIconButton-root': {
        color: '#939DA8'
      },
      '&:hover .MuiIconButton-root': {
        color: '#505C69'
      },
    },
    emptyLabel: {
      ...CommonFonts.blueFont18,
      padding: theme.spacing(1, 0),
      cursor: 'pointer',
      borderBottom: '1px solid rgba(0, 0, 0, 0)',
      '&:hover': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      }
    },
    iconButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      padding: theme.spacing(1),
    },
  })
);

export default useStyles;
