import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { useSnackbar } from 'notistack';

import { Breadcrumbs, Link, Grid, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link as RouterLink } from 'react-router-dom';
import AppLayout from '@/components/appLayout';
import SearchHeader from './components/SearchHeader';
import NoCustomers from './components/NoCustomers';
import CustomersTable from './table';
import AddCustomer from './AddCustomer';
import ViewCustomer from './ViewCustomer';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_CUSTOMERS, DELETE_CUSTOMER } from '@/services/customerService';

import { selectSelectedStore } from '@/store/modules/store/selectors';

import { contains } from '@/utils/stringUtil';
import useStyles from './styles';


const ONE_PAGE_LIMIT = 50;

const Customers = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const selectedStore = useSelector(selectSelectedStore);
  const [isLoading, setIsLoading] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [openCustomerModal, setAddCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openViewCustomerDialog, setOpenViewCustomerDialog] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [IsAllCustomersLoaded, setIsAllCustomersLoaded] =
    useState(false);

  // get customers from api
  const [getCustomers, { loading: isLoadingCustomers }] =
    useLazyQuery(GET_CUSTOMERS, {
      onCompleted: (data) => {
        // if response give us less than ONE_PAGE_LIMIT results, set all customer loaded
        if (data.shoppers.length < ONE_PAGE_LIMIT) {
          setIsAllCustomersLoaded(true);
        } else {
          setIsAllCustomersLoaded(false);
        }
        // update the array holding customers
        setCustomers([...customers, ...data.shoppers]);
        setIsLoading(false);
      },
      onError: () => {
        setIsAllCustomersLoaded(true);
      },
      fetchPolicy: 'no-cache'
    });

// delete customer
  const [deleteCustomerMutation, { loading: isDeletingCustomer }] =
    useMutation(DELETE_CUSTOMER, {
      onCompleted: () => {
        setCustomers(
          filteredCustomers.filter(
            (customer) => customer.id !== selectedCustomer?.id
          )
        );
        enqueueSnackbar(
          `${selectedCustomer.first_name} ${selectedCustomer.last_name} ${t('customers.deleted')}`
        );
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    });

  // change current page when user reaches bottom
  const handleLoadMore = () => {
    if (isEmpty(filteredCustomers)) return;
    setCurrentPage(currentPage + 1);
  };

  const refetchCustomers = () => {
    setIsLoading(true);
    setCurrentPage(1);
    setCustomers([]);
    let filterSet = {};

    !isEmpty(inputSearch) ? filterSet = {
      __or: { first_name: `%${inputSearch}%`, last_name:`%${inputSearch}%`,  email:`%${inputSearch}%` }
    } : '';

    getCustomers({
      variables: {
        filter: {
          count: true,
          page: currentPage,
          limit: ONE_PAGE_LIMIT,
          ...filterSet,
        }
      }
    });
  };

  useMemo(() => {
    getCustomers({
      variables: {
        filter: {
          count: true,
          page: currentPage,
          limit: ONE_PAGE_LIMIT
        }
      }
    });
  }, [currentPage]);

  // get customers from API
  useEffect(() => {
    refetchCustomers();
  }, [selectedStore, inputSearch]);


  const filteredCustomers = customers;

  const onViewInfo = (customer) => {
    setSelectedCustomer(customer);
    setOpenViewCustomerDialog(true);
  };

  const onArchive = (customer) => {
    setSelectedCustomer(customer);
    const payload = {
      variables: {
        id: customer.id
      }
    };
    return deleteCustomerMutation(payload);
  };

  return (
    <AppLayout
      className={classes.appLayout}
      withFooter
      header
      withServiceDropdown={true}
    >
      <div className={classes.root}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                className={classes.breadcrumbsText}
                color="primary"
                component={RouterLink}
                to="/business-manager"
              >
                {t('settings.business_manager')}
              </Link>
              <Link
                className={classes.breadcrumbsText}
                color={'textPrimary'}
                onClick={() => {
                }}
              >
                {t('menu.customers')}
              </Link>
            </Breadcrumbs>
          </Grid>
          <SearchHeader
            // onChangeSegmentTypes={(value) => isEmpty(value)
            //   ? {}
            //   : setSegmentTypes(value)
            // }
            onChangeSearch={setInputSearch}
            onAdd={() => setAddCustomerModal(true)}
            disabled={isEmpty(customers)}
          />
          <Grid item xs={12}>
            <Paper className={classes.emptyExpensesContainer}>
              {isEmpty(filteredCustomers) && !isLoadingCustomers ? (
                <NoCustomers
                  title={t('customers.no_customers_found')}
                  showAddButton={true}
                  onAdd={() => setAddCustomerModal(true)}
                />
              ) : (
                <InfiniteScroll
                  dataLength={filteredCustomers.length}
                  next={handleLoadMore}
                  hasMore={!IsAllCustomersLoaded}
                  loader={
                    isLoading &&
                    isLoadingCustomers &&
                    currentPage !== 1 && (
                      <div className={classes.root}>
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                      </div>
                    )
                  }
                >
                  <CustomersTable
                    customers={filteredCustomers}
                    isLoadingCustomers={isLoadingCustomers}
                    currentPage={currentPage}
                    onViewInfo={onViewInfo}
                    onArchive={onArchive}
                  />
                </InfiniteScroll>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
      {openCustomerModal && (
        <AddCustomer
          open={openCustomerModal}
          handleClose={() => setAddCustomerModal(false)}
          setRefetchCustomers={refetchCustomers}
        />
      )}
      {openViewCustomerDialog && (
        <ViewCustomer
          customer={selectedCustomer}
          open={openViewCustomerDialog}
          handleClose={() => setOpenViewCustomerDialog(false)} />
      )}
    </AppLayout>
  );
};

export default Customers;
