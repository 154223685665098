import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { isEmpty, find } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  Breadcrumbs,
  Link,
  Grid,
  Paper,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link as RouterLink } from 'react-router-dom';
import AppLayout from '@/components/appLayout';
import PreLoader from '@/components/preLoader';
import SearchHeader from './components/SearchHeader'
import NoOrders from './components/NoOrders'
import OrderTable from './table'

import { useLazyQuery, useMutation, useQuery, gql } from '@apollo/client';
import { GET_EMPLOYEES } from '@/services/userService'
import { GET_ORDERS, CREATE_ORDER } from '@/services/orderService'

import { selectSelectedStore } from '@/store/modules/store/selectors'

import {
  ORDER_STATES,
  ORDER_PAYMENT_STATES,
  getOrderPaymentState,
  getOrderInputOfOrderMutation
} from './helper'
import { getDateRange } from '@/utils/momentUtil'
import useStyles from './styles';

const ONE_PAGE_LIMIT = 10

const Orders = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const selectedStore = useSelector(selectSelectedStore)

  const [inputSearch, setInputSearch] = useState("");

  const [originalOrders, setOriginalOrders] = useState([]) // original orders taken from server
  const [currentPage, setCurrentPage] = useState(1)

  const [isAllOrdersLoaded, setIsAllOrdersLoaded] = useState(false)

  const [orderStates, setOrderStates] = useState(ORDER_STATES)
  const [orderPaymentStates, setOrderPaymentStates] = useState(ORDER_PAYMENT_STATES)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [date, setDate] = useState(new Date())

  const { data: employeesData, loading: isLoadingEmployees } = useQuery(gql`${GET_EMPLOYEES}`, {
    onCompleted: (data) => {
      setSelectedUsers(data.employee)
    }
  })

  const [getOrders, { loading: isLoadingOrders }] = useLazyQuery(GET_ORDERS, {
    onCompleted: (data) => {
      if (data.orders?.length < ONE_PAGE_LIMIT) {
        setIsAllOrdersLoaded(true)
      } else {
        setIsAllOrdersLoaded(false)
      }
      setOriginalOrders([...originalOrders, ...data.orders])
    },
    onError: () => setIsAllOrdersLoaded(true)
  })

  const [createOrderMutation, { loading: isUpdatingOrder }] = useMutation(CREATE_ORDER, {
    onCompleted: (data) => {
      enqueueSnackbar(t('orders.order_updated'));
      const updatedOrder = data.addOrders[0]
      setOriginalOrders(originalOrders
        .map(order => order.id === updatedOrder.id
          ? updatedOrder
          : order
        )
      )
    },
    onError: (error) => {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  })

  // change current page when user reaches bottom
  const handleLoadMore = () => {
    if (isEmpty(originalOrders)) return
    setCurrentPage(currentPage + 1);
  };

  // filer orders
  const orders = originalOrders
    .filter(order => find(orderStates, { state: order.state }))
    .filter(order => find(selectedUsers, { id: order.user.id }))
    .filter(order => find(orderPaymentStates, { state: getOrderPaymentState(order) }))

  const onRefetchOrders = () => {
    setOriginalOrders([])
    setCurrentPage(1)
    getOrders({
      variables: {
        filter: {
          count: true,
          page: 1,
          limit: ONE_PAGE_LIMIT,
        },
        start: getDateRange(date).start,
        end: getDateRange(date).end,
        order_no: `%${inputSearch.toLowerCase()}%`,
      },
    })
  }

  useEffect(() => {
    onRefetchOrders()
  }, [inputSearch, selectedStore, date])

  // Fetch new orders whenever current page changes
  useMemo(() => {
    getOrders({
      variables: {
        filter: {
          count: true,
          page: currentPage,
          limit: ONE_PAGE_LIMIT,
        },
        start: getDateRange(date).start,
        end: getDateRange(date).end,
        order_no: `%${inputSearch.toLowerCase()}%`,
      },
    })
  }, [currentPage])

  const onVoidOrder = (order) => {
    const input = getOrderInputOfOrderMutation(order, 'voided')
    createOrderMutation({
      variables: {
        input
      }
    })
  }

  const onRefundOrder = (order) => {
    const input = getOrderInputOfOrderMutation(order, 'refunded')
    createOrderMutation({
      variables: {
        input
      }
    })
  }

  return (
    <AppLayout
      className={classes.appLayout}
      withFooter
      header
      withServiceDropdown={true}
    >
      <div className={classes.root}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                className={classes.breadcrumbsText}
                color="primary"
                component={RouterLink}
                to="/business-manager"
              >
                {t('settings.business_manager')}
              </Link>
              <Link
                className={classes.breadcrumbsText}
                color={'textPrimary'}
                onClick={() => { }}
              >
                {t('menu.orders')}
              </Link>
            </Breadcrumbs>
          </Grid>
          <SearchHeader
            orderStates={orderStates}
            orderPaymentStates={orderPaymentStates}
            employees={employeesData?.employee || []}
            selectedUsers={selectedUsers}
            date={date}
            onChangeOrderStates={(value) => isEmpty(value) ? {} : setOrderStates(value)} // filters can't be empty
            onChangeOrderPaymentStates={(value) => isEmpty(value) ? {} : setOrderPaymentStates(value)} // filters can't be empty
            onChangeUsers={(value) => isEmpty(value) ? {} : setSelectedUsers(value)} // filters can't be empty
            onChangeDate={setDate}
            onChangeSearch={setInputSearch}
            onAdd={() => { }}
          />
          <Grid item xs={12}>
            <Paper className={classes.emptyOrdersContainer}>
              {(isEmpty(orders) && !isLoadingOrders)
                ?
                <NoOrders
                  title={t('orders.no_orders')}
                />
                :
                <InfiniteScroll
                  dataLength={orders.length}
                  next={handleLoadMore}
                  hasMore={!isAllOrdersLoaded}
                  loader={
                    isLoadingOrders && currentPage !== 1 && (
                      <div className={classes.root}>
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                      </div>
                    )
                  }
                >
                  <OrderTable
                    orders={orders}
                    isLoadingOrders={isLoadingOrders}
                    currentPage={currentPage}
                    onVoidOrder={onVoidOrder}
                    onRefundOrder={onRefundOrder}
                  />
                </InfiniteScroll>
              }
            </Paper>
          </Grid>
        </Grid>
        {(isLoadingEmployees || isUpdatingOrder) && <PreLoader size={35} />}
      </div>
    </AppLayout>
  )
}

export default Orders