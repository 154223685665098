import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { isMobile } from 'react-device-detect'
import moment from 'moment'

import { Box, Grid, Typography } from '@material-ui/core'
import { BarChart, Menu } from '@material-ui/icons'
import PreLoader from '@/components/preLoader'
import LayoutSelect from './layoutSelect'
import Chart from './chart'
import Table from './table'
import NoResults from './noResults'

import { gql, useLazyQuery } from '@apollo/client'
import {
  GET_TOP_WORKBOARD_INSIGHTS,
  GET_BOTTOM_WORKBOARD_INSIGHTS
} from '@/services/widgetService'

import { selectSelectedRegion, selectSelectedStore } from '@/store/modules/store/selectors'

import {
  BEST_SELLER_PRODUCTS_LIMIT,
  BEST_SELLER_SORT_TYPE,
  SORT_DIRECTION
} from './helper'
import useStyles from './styles';

const LAYOUT = {
  CHART: 'CHART',
  TABLE: 'TABLE'
}

const ProductSalesAnalysis = (props) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const { startTime, endTime } = props

  const selectedRegion = useSelector(selectSelectedRegion);
  const selectedStore = useSelector(selectSelectedStore)

  const [layout, setLayout] = useState(LAYOUT.CHART)
  const [bestSellingProducts, setBestSellingProducts] = useState([])
  const [sortTypeOfProducts, setSortTypeOfProducts] = useState(BEST_SELLER_SORT_TYPE.GROSS)

  const [sortDirectionOfProducts, setSortDirectionOfProducts] = useState({
    [BEST_SELLER_SORT_TYPE.GROSS]: SORT_DIRECTION.DESCENDING,
    [BEST_SELLER_SORT_TYPE.ITEM]: SORT_DIRECTION.DESCENDING,
    [BEST_SELLER_SORT_TYPE.NET]: SORT_DIRECTION.DESCENDING,
    [BEST_SELLER_SORT_TYPE.TAX]: SORT_DIRECTION.DESCENDING,
  })

  const [getTopWorkboardInsights, { loading: isLoadingTopWorkboardInsights }] = useLazyQuery(gql`${GET_TOP_WORKBOARD_INSIGHTS}`, {
    onCompleted: (data) => {
      setBestSellingProducts(data.insights.best_sellers.products)
    },
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        storeId: selectedRegion ? selectedRegion.id : selectedStore.id
      },
    },
  })

  const [getBottomWorkboardInsights, { loading: isLoadingBottomWorkboardInsights }] = useLazyQuery(gql`${GET_BOTTOM_WORKBOARD_INSIGHTS}`, {
    onCompleted: (data) => {
      let products = [...data.insights.best_sellers.products]
      products.sort((a, b) => { // sort in ascending order
        const aTotalSaleNet = a.total_sale.net + (+a.total_sale.promotion)
        const aTotalSaleTax = a.total_sale.tax + (+a.total_sale.promotion_tax)
        const bTotalSaleNet = b.total_sale.net + (+b.total_sale.promotion)
        const bTotalSaleTax = b.total_sale.tax + (+b.total_sale.promotion_tax)

        if (sortTypeOfProducts === BEST_SELLER_SORT_TYPE.ITEM) {
          return a.item_sold - b.item_sold
        } else if (sortTypeOfProducts === BEST_SELLER_SORT_TYPE.NET) {
          return aTotalSaleNet - bTotalSaleNet
        } else if (sortTypeOfProducts === BEST_SELLER_SORT_TYPE.TAX) {
          return aTotalSaleTax - bTotalSaleTax
        } else {
          return (aTotalSaleNet + aTotalSaleTax) - (bTotalSaleNet + bTotalSaleTax)
        }
      })
      setBestSellingProducts(products)
    },
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        storeId: selectedRegion ? selectedRegion.id : selectedStore.id
      },
    },
  })

  const loadBestSeller = (sortType, sortDirection) => {
    if (sortDirection === SORT_DIRECTION.ASCENDING) {
      getBottomWorkboardInsights({
        variables: {
          start: moment(startTime).format('YYYY-MM-DD'),
          end: moment(endTime).format('YYYY-MM-DD'),
          sortBy: sortType,
          limit: BEST_SELLER_PRODUCTS_LIMIT
        }
      })
    } else {
      getTopWorkboardInsights({
        variables: {
          start: moment(startTime).format('YYYY-MM-DD'),
          end: moment(endTime).format('YYYY-MM-DD'),
          sortBy: sortType,
          limit: BEST_SELLER_PRODUCTS_LIMIT
        }
      })
    }
  }

  // load sorted insights whenever sort type or sort direction changes
  useEffect(() => {
    loadBestSeller(sortTypeOfProducts, sortDirectionOfProducts[sortTypeOfProducts])
  }, [sortTypeOfProducts, sortDirectionOfProducts, startTime, endTime])

  const content = {
    [LAYOUT.CHART]: (
      <Chart
        items={bestSellingProducts}
      />
    ),
    [LAYOUT.TABLE]: (
      <Table
        items={bestSellingProducts}
        sortType={sortTypeOfProducts}
        sortDirection={sortDirectionOfProducts}
        onPressSort={(sortType) => {
          setSortTypeOfProducts(sortType)
          setSortDirectionOfProducts({
            ...sortDirectionOfProducts,
            [sortType]: sortDirectionOfProducts[sortType] === SORT_DIRECTION.ASCENDING
              ? SORT_DIRECTION.DESCENDING
              : SORT_DIRECTION.ASCENDING
          })
        }}
      />
    )
  }
  return (
    <Box className={classes.root}>
      <Typography className={classes.titleText}>
        {t('globalWidgets.product_sales_analysis')}
      </Typography>

      {isEmpty(bestSellingProducts) && !isLoadingBottomWorkboardInsights && !isLoadingTopWorkboardInsights &&
        <NoResults title={t('workboard.no_data')} />
      }
      {!isEmpty(bestSellingProducts) &&
        <React.Fragment>
          <Box
            display='flex'
            justifyContent={isMobile ? 'center' : 'flex-end'}
            className={classes.layoutSelectContainer}
          >
            <Box>
              <Grid container className={classes.layoutsWrapper}>
                <Grid item>
                  <LayoutSelect
                    isSelected={layout === LAYOUT.CHART}
                    icon={BarChart}
                    layout={LAYOUT.CHART}
                    onClickLayout={(value) => setLayout(value)}
                  />
                </Grid>
                <Grid item>
                  <LayoutSelect
                    isSelected={layout === LAYOUT.TABLE}
                    icon={Menu}
                    layout={LAYOUT.TABLE}
                    onClickLayout={(value) => setLayout(value)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className={classes.contentContainer}>
            {content[layout]}
          </Box>
        </React.Fragment>
      }
      {(isLoadingBottomWorkboardInsights || isLoadingTopWorkboardInsights) && <PreLoader size={30} />}
    </Box>
  )
}

ProductSalesAnalysis.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  dateFilter: PropTypes.string
}

export default React.memo(ProductSalesAnalysis)