import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommonFonts, Colors } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: 0,
      backgroundColor: Colors.WHITE,
      border: `1px solid ${Colors.GRAY_BORDER1}`,
      borderTop: 'none',
      borderRadius: 5,
      maxWidth: 1029,
      marginBottom: theme.spacing(16),
    },
    searchInput: {
      '& .MuiInput-root': {
        padding: 15,
        borderRadius: 5,
        borderTop: `1px solid ${Colors.GRAY_BORDER1}`,
        ...CommonFonts.darkFont18,
      },
    },
    list: {
      padding: 0,
      maxHeight: 500,
      overflowY: 'scroll',
    },
    listItemContainer: {
      borderRadius: 5,
      borderBottom: `1px solid ${Colors.GRAY_BORDER2}`,
    },
    merchantItemWrapper: {
      letterSpacing: 0.14,
      padding: '20px 40px 20px 20px',
      fontWeight: 600,
      ...CommonFonts.darkFont16,
    },
    storeItemWrapperLeft: {
      fontWeight: 300,
      padding: '20px 80px 20px 60px',
      ...CommonFonts.grayFont16,
    },
    storeItemWrapperRight: {
      fontWeight: 300,
      padding: '20px 80px 20px 60px',
      ...CommonFonts.grayFont15,
    },
  })
);

export default useStyles;
