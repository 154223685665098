import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link } from '@material-ui/core';

import SwitchLanguage from '../switchLanguage';

import useStyles from './styles';

const Footer = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        direction="row-reverse"
        className={classes.root}
      >
        <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
          <Grid
            container
            spacing={2}
            className={classes.termsWrapper}
          >
            <Grid item>
              <Link
                href="https://krlmedia.com/terms"
                underline="none"
              >
                <span className={classes.footerText}>
                  {t('footer.terms_of_use')}
                </span>
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="https://krlmedia.com/terms"
                underline="none"
              >
                <span className={classes.footerText}>
                  {t('footer.privacy_policy')}
                </span>
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="https://krlmedia.com/terms"
                underline="none"
              >
                <span className={classes.footerText}>
                  {t('footer.contact')}
                </span>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          className={classes.copywrightWrapper}
        >
          <span className={classes.footerText}>
            {t('footer.copywright')}
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
