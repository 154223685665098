import React, { useState, useCallback, useEffect } from 'react';
import useStyles from './styles';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PdfPreview from './pdfPreview';
import { MUTATE_GENERATE_UPLOAD_URL } from '@/services/storeService';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Colors, CommonFonts } from '@/theme';
import { useDropzone } from 'react-dropzone';
import PreLoader from '@/components/preLoader';
import { useSnackbar } from 'notistack';

const FilePicker = (props) => {
  const {
    open,
    handleClose,
    onSuccess
  } = props

  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedFile, setSelectedFile] = useState(null)
  const [progressLoading, setProgressLoading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)

  const [mutateGenerateURL] = useMutation(
    gql`
      ${MUTATE_GENERATE_UPLOAD_URL}
    `
  );

  const onDrop = useCallback((value) => {
    const file = value[0]
    setSelectedFile(file)
  }, []);

  const onDropRejected = useCallback(() => {
    enqueueSnackbar(t('expenses.unsupported_file_type'), { variant: 'error' })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf, image/png, image/jpeg',
    onDropRejected
  });

  const onSave = async () => {
    setIsGenerating(true)
    try {
      const bucketPara = selectedFile.type === 'application/pdf'
        ? { bucket: 'document' }
        : {}

      const result = await mutateGenerateURL({
        variables: {
          input: {
            ...bucketPara,
            name: selectedFile?.name,
            content_type: selectedFile.type,
          },
        },
        ignoreResults: false,
      });
      const generateUploadUrlResponse =
        result?.data?.generateUploadUrl[0];
      if (generateUploadUrlResponse) {
        const url = generateUploadUrlResponse?.upload_url;
        await fetch(
          new Request(url, {
            method: 'PUT',
            body: selectedFile,
            headers: new Headers({
              'Content-Type': selectedFile.type,
            }),
          })
        );

        onSuccess({
          id: generateUploadUrlResponse.id,
          url: selectedFile.type === 'application/pdf'
            ? selectedFile
            : URL.createObjectURL(selectedFile),
          name: generateUploadUrlResponse.name,
          content_type: selectedFile.type

        })
        handleClose();
      }
    } catch (error) {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setIsGenerating(false);
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={isGenerating ? () => { } : handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          alignContent="center"
        >
          <Grid item xs={1} />
          <Grid className={classes.headerText} item xs={10}>
            <span style={{ fontSize: 18 }}>
              {t('expenses.upload_file')}
            </span>
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            <IconButton
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!selectedFile
          ?
          <div className={classes.boxImageUpload}>
            <Typography className={classes.titleUploadImage}>
              {t('expenses.supported_file')}
            </Typography>
            {!progressLoading ? (
              <div
                {...getRootProps()}
                className={classes.dropZone}
              >
                <input {...getInputProps()} />
                <Typography className={classes.titleDrop}>
                  {t('expenses.drop_title')}
                </Typography>
                <Typography className={classes.titleBrowse}>
                  {t('expenses.browse')}
                </Typography>
              </div>
            ) : (
              <div className={classes.progressBar}>
                <LinearProgress />
              </div>
            )}
          </div>
          :
          <div>
            {selectedFile.type === 'application/pdf'
              ?
              <div>
                <PdfPreview file={selectedFile} />
              </div>
              :
              <div className={classes.boxImagePreview}>
                <img
                  className={classes.imagePreview}
                  src={URL.createObjectURL(selectedFile)}
                />
              </div>
            }
          </div>
        }
      </DialogContent>

      {selectedFile &&
        <DialogActions style={{ paddingRight: 30 }}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
              color="primary"
              onClick={() => { }}
            >
              {t('button_group.change')}
            </Button>
          </div>
          <Button
            color="primary"
            onClick={onSave}
          >
            {t('button_group.save')}
          </Button>
        </DialogActions>
      }

      {isGenerating && <PreLoader size={30} />}
    </Dialog>
  )
}

export default FilePicker

