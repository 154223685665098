export const INITIAL_STATE ={
  currentMerchant: {},
  selectedStore: null,
  selectedRegion: null,
  localisation : {
    digit_separator: ",",
    currency_symbol: "",
    currency_decimal: false,
    currency_position: "suffix"
  },
  merchantSettings: {},
  netPrice: false
}
