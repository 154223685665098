import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import LoginPage from '@/pages/auth/loginPage';
import ResetPasswordPage from '@/pages/auth/resetPassword';
import VerifyEmailPage from '@/pages/auth/verifyEmail';
import StoreRoutes from './storeRoutes';
import WorkboardRoutes from './workboardRoutes';
import BusinessManagerRoutes from './businessManagerRoutes';
import ModulesRoutes from './modulesRoutes';
import SettingsPage from '@/pages/settings';
import UserManagementRoutes from './userManagementRoutes';
import NotFoundPage from '@/pages/notFoundPage';
import InvitationPage from './invitationRoutes';
import Joyrider from '@/components/joyRider';
import ImportPage from './importRoutes';

import { userIsLoggedIn, userIsNotLoggedIn } from '@/hoc/authWrapper';
import TouchpointsManagementPage from '@/pages/touchpointsManagement';
import OrderTypeWorkflowsManagementPage from '@/pages/orderTypeWorkflows';
import SubscriptionManagementPage from '@/pages/subscriptions';
import StoresPage from '@/pages/stores';
import WarehousesPage from '@/pages/warehouses'
import DeveloperPage from '@/pages/developer';

const WrappedLoginPage = userIsNotLoggedIn(LoginPage);
const WrappedResetPasswordPage = userIsNotLoggedIn(ResetPasswordPage);
const WrappedVerifyEmailPage = userIsNotLoggedIn(VerifyEmailPage);
const WrappedStoreRoutes = userIsLoggedIn(StoreRoutes);
const WrappedWorkboardRoutes = userIsLoggedIn(WorkboardRoutes);
const WrappedBusinessManagerRoutes = userIsLoggedIn(
  BusinessManagerRoutes
);
const WrappedUserManagementRoutes = userIsLoggedIn(
  UserManagementRoutes
);
const WrappedModulesRoutes = userIsLoggedIn(ModulesRoutes);

const WrappedTouchpointsManagementRoutes = userIsLoggedIn(
  TouchpointsManagementPage
);
const WrappedSettingsPage = userIsLoggedIn(SettingsPage);
const WrappedInvitationPage = userIsNotLoggedIn(InvitationPage);
const WrappedImportPage = userIsLoggedIn(ImportPage);
const WrappedDeveloperPage = userIsLoggedIn(DeveloperPage);
const WrappedOrderTypeWorkflowsRoutes = userIsLoggedIn(
  OrderTypeWorkflowsManagementPage
);
const WrappedSubscriptionManagementRoutes = userIsLoggedIn(
  SubscriptionManagementPage
);
const WrappedStoresPage = userIsLoggedIn(StoresPage);
const WrappedWarehousesPage = userIsLoggedIn(WarehousesPage);

const Routes = () => {
  return (
    <div>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route exact path="/login" component={WrappedLoginPage} />
        <Route
          exact
          path="/reset/:code"
          component={WrappedResetPasswordPage}
        />
        <Route
          exact
          path="/guest/activate/:id/:code"
          component={WrappedVerifyEmailPage}
        />
        <Route path="/store" component={WrappedStoreRoutes} />
        <Route path="/workboard" component={WrappedWorkboardRoutes} />
        <Route
          path="/business-manager"
          component={WrappedBusinessManagerRoutes}
        />
        <Route
          exact
          path="/settings"
          component={WrappedSettingsPage}
        />
        <Route
          path="/user-management"
          component={WrappedUserManagementRoutes}
        />
        <Route
          path="/touchpoints-management"
          component={WrappedTouchpointsManagementRoutes}
        />
        <Route path="/invitation" component={WrappedInvitationPage} />
        <Route path="/import" component={WrappedImportPage} />
        <Route path="/developer" component={WrappedDeveloperPage} />
        <Route
          path="/order-types-workflows-management"
          component={WrappedOrderTypeWorkflowsRoutes}
        />
        <Route
          path="/subscription-management"
          component={WrappedSubscriptionManagementRoutes}
        />
        <Route path="/modules" component={WrappedModulesRoutes} />
        <Route
          exact
          path="/stores"
          component={WrappedStoresPage}
        />
        <Route
          exact
          path="/warehouses"
          component={WrappedWarehousesPage}
        />
        {/* Show Not Found page when url does not match */}
        <Route component={NotFoundPage} />
      </Switch>

      <Joyrider />
    </div>
  );
};

export default Routes;
