import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme => createStyles({
  inputClass: {
    width: '100% !important',
    height: '40px !important',
    border: 'none !important',
    borderBottom: `1px solid ${Colors.GRAY_BORDER1} !important`,
    borderRadius: '0px !important',
    fontFamily: 'Montserrat',
    fontSize: '18px !important',
    '&:hover': {
      borderBottom: `1px solid ${Colors.BLUE} !important`,
      transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
  },
  headerText: {
    ...CommonFonts.darkBoldFont18,
    textAlign: 'center'
  },
  closeButton: {
    padding: theme.spacing(1.5),
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
  dropDownClass: {
    backgroundColor: 'transparent !important',
    border: 'none !important',
    '& .selected-flag:hover': {
      backgroundColor: 'transparent !important'
    },
    '& .selected-flag': {
      backgroundColor: 'transparent !important'
    }
  },
  appLayout: {
    backgroundColor: Colors.GRAY_LIGHT,
  },
  root: {
    position: 'relative',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingRight: 30,
    paddingLeft: 30,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  breadcrumbsText: {
    cursor: 'pointer'
  },
  emptyExpensesContainer: {
    padding: theme.spacing(4, 4, 0, 4),
  },
  listHeader: {
    background: '#F3F5F7 0% 0% no-repeat padding-box',
    opacity: '1',
    height: 60,
    cursor: 'default',
    ...CommonFonts.darkFont16,
  },
  listItem: {
    height: 80,
    borderBottom: '1px solid #0000001F',
    ...CommonFonts.darkFont18,
  },
  dotsIcon: {
    textAlign: 'right',
    zIndex: 100,
  },
  gridItem: {
    padding: theme.spacing(4),
  },
  radioButton: {
    paddingRight: theme.spacing(4),
  },
  label: {
    fontSize: 'inherit',
  },
  phoneInput: {
    fontSize: 'inherit',
  },
})));

export default useStyles;
