import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TableRow, TableCell, withStyles, Button } from '@material-ui/core';
import { CommonFonts } from '@/theme';
import SingleCheckboxInput from '@/components/singleCheckbox';
import { filter } from 'lodash';
import TextField from '@/components/textField';
import moment from 'moment';

const Row = ({ item, changedData, index }) => {
  const { t } = useTranslation();
  const dayOrDate = item.date ? 'date' : 'day';
  const [values, setValues] = useState(item);
  const [removeDateItem, setRemoveDateItem] = useState({});

  useMemo(() => {
    setValues(item);
  }, [item]);

  const hoursEdit = ((e, hours, index, type) => {
    const value = e.target?.value;

    if (value !== undefined) {
      const openingTimes = values.opening_times.map((item, el) => {
        if (el === index) {
          item[type] = value;
        }
        return item;
      });
      setValues({ ...values, opening_times: openingTimes });
    }
  });

  const setTextField = (hours, index, type) => {
    return (
      <TextField
        id={`${index}-${type}`}
        name={`${index}-${type}-${hours}`}
        value={hours}
        isEdit={true}
        required
        style={{ width: 110 }}
        type='time'
        onChange={(e) => {
          hoursEdit(e, hours, index, type);
        }}
      />
    );
  };
  useEffect(() => {
    // send level up for updating
    changedData(values, index, removeDateItem);
  }, [values, removeDateItem]);

  const addItem = () => {
    setValues({
      ...values, opening_times: [
        ...values.opening_times,
        {
          open: '00:00',
          close: '00:00'
        }
      ]
    });
  };

  const removeItem = (id) => {
    const newData = filter(values.opening_times, function(hours, index) {
      return index !== id;
    });
    setValues({ ...values, opening_times: newData });
  };

  const addingOrRemoveList = (id) => {
    return (
      <div>
        {id ? <StyledButton
            size='medium'
            color='primary'
            onClick={() => removeItem(id)}
          >
            {t('workboard.manage_store.opening_hours_dialog.remove')}
          </StyledButton> :
          <StyledButton
            color='primary'
            onClick={() => addItem()}
          >
            {t('workboard.manage_store.opening_hours_dialog.add_hours')}
          </StyledButton>
        }
      </div>
    );
  };

  const handleChangeCheckbox = (e) => {
    !e ? setValues({ ...values, closed: true, opening_times: [] }) :
      setValues({
        ...values,
        closed: false, opening_times: [
          {
            open: '08:00',
            close: '20:00'
          }
        ]
      });
  };

  const removeDate = () => {
    return (
      <StyledButton
        size='medium'
        color='primary'
        onClick={() => setRemoveDateItem(item)}
      >
        {t('workboard.manage_store.opening_hours_dialog.remove')}
      </StyledButton>
    );
  };

  const rowCells = [
    {
      id: 'opening_checkbox',
      label: <SingleCheckboxInput style={{ paddingBottom: 0 }} value={!values.closed}
                                  isShowToast={false}
                                  label={values.day || moment(values.date).format('YYYY-MM-DD')}
                                  onChange={(e) => handleChangeCheckbox(e)} />
    },
    {
      id: 'opens_at',
      label: values?.closed ? (t('workboard.manage_store.opening_hours_dialog.closed')) :
        values?.opening_times?.map((openTime, index) => setTextField(openTime.open, index, 'open'))
    },
    {
      id: 'closes_at',
      label: values?.opening_times?.map((closeTime, index) => setTextField(closeTime.close, index, 'close'))
    },
    {
      id: 'empty',
      label: dayOrDate === 'date' && values.closed ? removeDate() : values?.opening_times
        ?.map((time, index) => addingOrRemoveList(index))
    }
  ];


  return (
    <TableRow hover style={{ position: 'relative' }}>
      {rowCells.map((rowCell) => (
        <StyledTableCell key={rowCell.id}>
          {rowCell.label}
        </StyledTableCell>
      ))}
    </TableRow>
  );
};

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
    ...CommonFonts.darkFont16,
    fontWeight: 'normal'
  },
  head: {
    backgroundColor: '#F3F5F7'
  },
  body: {
    ...CommonFonts.darkFont16
  }
}))(TableCell);

const StyledButton = withStyles(() => ({
  root: {
    fontWeight: 'normal'
  },
  label: {
    textTransform: 'none',
    whiteSpace: 'nowrap'
  }
}))(Button);


Row.propTypes = {
  item: PropTypes.object
};

export default Row;
