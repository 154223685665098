import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import Button from '@/components/button';

import useStyles from './styles';
import I18n from '@/i18n';

const Widget = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { widget, hasAdded, onAddWidget, isAdding } = props;

  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      item
      xs={widget.width === 1 || isDownSm ? 12 : 6}
      className={classes.root}
    >
      <Box className={classes.container}>
        <Grid container justify="space-between">
          <Typography className={classes.nameText}>
            {widget.name}
          </Typography>
          <Button
            className={classes.addButton}
            variant="contained"
            color="primary"
            disabled={hasAdded}
            onClick={() => onAddWidget(widget)}
            pending={isAdding}
            pendingClassname={classes.pendingClassname}
          >
            {hasAdded
              ? t('button_group.added')
              : t('button_group.add')}
          </Button>
        </Grid>
        <Typography className={classes.descriptionText}>
          {widget.description}
        </Typography>
        {widget.image_url && (
          <img
            className={classes.widgetImg}
            src={widget.image_url}
            alt="widget"
          />
        )}
      </Box>
    </Grid>
  );
};

Widget.propTypes = {
  widget: PropTypes.object,
  hasAdded: PropTypes.bool,
  onAddWidget: PropTypes.func,
  isAdding: PropTypes.bool,
};

export default React.memo(Widget);
