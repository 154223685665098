import {
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SettingAccordion from '.';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { ACCOUNT_TYPES, ADD_ACCOUNT } from '@/services/storeService';
import PreLoader from '@/components/preLoader';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { capitalizeFirstLetter } from '@/utils/stringUtil';
import _ from 'lodash';
import SettingAccountFormDialog from '../accountFormDialog';
import { useSnackbar } from 'notistack';

const accountTypeQuery = gql`
  ${ACCOUNT_TYPES}
`;

const ChartOfAccounts = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [accountTab, setAccountTab] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [menuId, setMenuId] = useState('');
  const [openAccountForm, setOpenAccountForm] = useState(false);
  const [editingAccount, setEditingAccount] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onExpand = (value) => setIsExpand(value);

  const [getAccountType, { loading: loadingAccountType, data }] =
    useLazyQuery(accountTypeQuery, {
      variables: {
        id: '',
      },
      onCompleted: () => {
        setIsLoading(false);
      },
      fetchPolicy: 'no-cache',
    });
  const accountTypeList = data?.accountTypes;

  const isLastTab = _.isEqual(
    `${value}`,
    accountTab[accountTab?.length - 1]?.id
  );

  const handleChange = (event, newValue) => {
    setIsLoading(true);
    setValue(newValue);
    fetchAccountType(newValue);
  };

  const fetchAccountType = (value) =>
    getAccountType({
      variables: {
        id: _.isEqual(value, 0) || isLastTab ? '' : `${value}`,
      },
    });

  useEffect(() => {
    if (isExpand) {
      getAccountType();
    }
  }, [isExpand]);

  useEffect(() => {
    if (!_.isEmpty(accountTypeList)) {
      const result = [];

      // TODO refactor to reduce
      accountTypeList?.map((accountType) => {
        accountType?.children_types?.map((childrenType) => {
          childrenType?.accounts?.map((account) => {
            result.push({
              ...account,
              account_type_id: childrenType?.id,
              account_type: childrenType?.name,
            });
          });
        });
      });

      setAccountList(
        result?.filter((item) =>
          _.isEqual(item.archived, value === accountTab?.length - 1)
        )
      );

      if (_.isEmpty(accountTab)) {
        const maxId =
          parseInt(_.maxBy(accountTypeList, 'id')?.id) + 1;

        const accountTypes = [
          ...accountTypeList,
          {
            __typename: 'ParentAccountType',
            id: '0',
            name: 'ALL ACCOUNT',
          },
          {
            __typename: 'ParentAccountType',
            id: `${maxId}`,
            name: 'ARCHIVED',
          },
        ].sort(
          (firstValue, secondValue) => firstValue.id - secondValue.id
        );
        setAccountTab(accountTypes);
      }
    }
  }, [value, accountTypeList]);

  const [onArchiveAccount] = useMutation(
    gql`
      ${ADD_ACCOUNT}
    `,
    {
      refetchQueries: [
        {
          query: accountTypeQuery,
          variables: {
            id: '',
          },
        },
      ],
    }
  );

  const archiveRestoreAccount = (account) => {
    setIsLoading(true);
    onArchiveAccount({
      variables: {
        input: {
          id: account.id,
          archived: !isLastTab,
          // Below fields are provided just to pass mutation mandatory restriction
          name: account.name,
          account_type_id: account.account_type_id,
        },
      },
    }).then(
      () => {
        enqueueSnackbar(
          isLastTab
            ? t(
                'settings.chart_of_accounts.add_account.account_restored',
                { name: account.name }
              )
            : t(
                'settings.chart_of_accounts.add_account.account_archived',
                { name: account.name }
              )
        );
        fetchAccountType(value);
        setIsLoading(false);
      },
      (err) => enqueueSnackbar(err)
    );
  };

  return (
    <SettingAccordion
      id="chartOfAccounts"
      title={t('settings.chart_of_accounts.title')}
      options={
        <AccordionOption
          onAddAccountClick={() => setOpenAccountForm('ADD')}
          onAddBankClick={() => {
            /** TBD */
          }}
        />
      }
      onExpand={onExpand}
    >
      {loadingAccountType && !isLoading ? (
        <PreLoader size={30} />
      ) : (
        <>
          <Grid container style={{ marginTop: '-20px' }}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Tabs
                  className={classes.tabs}
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {(accountTab || []).map((item) => (
                    <Tab
                      className={classes.tab}
                      style={{ fontSize: 18 }}
                      key={item.name}
                      label={capitalizeFirstLetter(
                        item.name.toLowerCase()
                      )}
                    />
                  ))}
                </Tabs>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <TabPanel
                  value={value}
                  index={value}
                  className={classes.tabPanel}
                >
                  {isLoading || loadingAccountType ? (
                    <Box
                      p={3}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress size={30} />
                    </Box>
                  ) : (
                    <Paper
                      elevation={0}
                      style={{ overflowX: 'auto' }}
                    >
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell style={{ width: '25%' }}>
                              {t(
                                'settings.chart_of_accounts.all_account.account_name'
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ width: '20%' }}>
                              {t(
                                'settings.chart_of_accounts.all_account.type'
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ width: '25%' }}>
                              {t(
                                'settings.chart_of_accounts.all_account.tax'
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              {t(
                                'settings.chart_of_accounts.all_account.opening_balance'
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '10%' }}
                            />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {accountList?.map((row) => (
                            <TableRow key={row.id}>
                              <StyledTableCell>
                                <Typography
                                  className={classes.tableText}
                                >
                                  {row.name}
                                </Typography>
                                <Typography
                                  className={
                                    classes.tableDescriptionText
                                  }
                                >
                                  {row.description}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  className={classes.tableText}
                                >
                                  {row.account_type || ''}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  className={classes.tableText}
                                >
                                  {' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  className={classes.tableText}
                                >
                                  {row.starting_balance}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <IconButton
                                  className={classes.moreIcon}
                                  onClick={(event) => {
                                    setAnchorMenu(
                                      event.currentTarget
                                    );
                                    setMenuId(row.id);
                                  }}
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu
                                  key={row.id}
                                  id="menu-options-chart-of-account"
                                  className={classes.menuOption}
                                  anchorEl={anchorMenu}
                                  keepMounted
                                  open={
                                    Boolean(anchorMenu) &&
                                    _.isEqual(menuId, row.id)
                                  }
                                  onClose={() => setAnchorMenu(null)}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      setOpenAccountForm('EDIT');
                                      setEditingAccount(row);
                                      setAnchorMenu(null);
                                    }}
                                  >
                                    <Typography
                                      className={classes.tableText}
                                    >
                                      {t(
                                        'settings.chart_of_accounts.all_account.edit'
                                      )}
                                    </Typography>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      archiveRestoreAccount(row);
                                      setAnchorMenu(null);
                                    }}
                                  >
                                    <Typography
                                      className={classes.tableText}
                                    >
                                      {isLastTab
                                        ? t(
                                            'settings.chart_of_accounts.all_account.restore'
                                          )
                                        : t(
                                            'settings.chart_of_accounts.all_account.archived'
                                          )}
                                    </Typography>
                                  </MenuItem>
                                </Menu>
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      {!isLoading &&
                        !loadingAccountType &&
                        accountList?.length === 0 && (
                          <Box
                            marginTop={5}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              className={classes.noAccountFound}
                            >
                              {t(
                                'settings.chart_of_accounts.no_account_found'
                              )}
                            </Typography>
                          </Box>
                        )}
                    </Paper>
                  )}
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
          {isExpand && (
            <SettingAccountFormDialog
              onFetchAccounts={getAccountType}
              open={openAccountForm}
              fullWidth
              fullScreen={fullScreen}
              onClose={() => {
                setOpenAccountForm(false);
                setEditingAccount(null);
              }}
              aria-labelledby="chartOfAccount-editAccount"
              disableBackdropClick
              account={editingAccount}
            />
          )}
        </>
      )}
    </SettingAccordion>
  );
};

const AccordionOption = ({ onAddBankClick, onAddAccountClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.boxBankAccount}>
      <Button
        style={{ paddingRight: '60px' }}
        className={classes.addBankAccount}
        color="primary"
        onClick={onAddBankClick}
      >
        {t('settings.chart_of_accounts.add_bank')}
      </Button>
      <Button
        className={classes.addBankAccount}
        onClick={onAddAccountClick}
        color="primary"
      >
        {t('settings.chart_of_accounts.add_account.add_button')}
      </Button>
    </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default ChartOfAccounts;
