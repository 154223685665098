import React, { useState } from 'react';

import {
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
} from '@material-ui/core';
import PrimaryButton from '@/components/button'
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

const AddNewTeam = ({ onChange }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    handleReset();
  };

  const addNewTeamsSchema = Yup.object().shape({
    addNewTeam: Yup.string().required(
      t('userManagement.teams.teams_name_required')
    ),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    setFieldError,
    touched,
    values,
    isValid,
    handleReset,
  } = useFormik({
    initialValues: {
      addNewTeam: '',
    },
    validationSchema: addNewTeamsSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await onChange(values['addNewTeam']);
        handleCloseEditModal();
        enqueueSnackbar(t('userManagement.teams.new_team_added'));
      } catch (error) {
        setFieldError('addNewTeam', t('settings.error.update'));
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <PrimaryButton
        className={classes.addNewUserButton}
        variant="contained"
        color="primary"
        onClick={handleOpenEditModal}
      >
        {t('userManagement.teams.add_team')}
      </PrimaryButton>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        onClose={isLoading ? () => {} : handleCloseEditModal}
        aria-labelledby={`{add-new-team-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{add-new-team-dialog-title}`}
          onClose={handleCloseEditModal}
        >
          {t('userManagement.teams.add_teams_dialog_title')}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              id={'add-new-team'}
              name={'addNewTeam'}
              fullWidth
              value={values['addNewTeam']}
              onChange={handleChange}
              error={
                touched['addNewTeam'] && Boolean(errors['addNewTeam'])
              }
              helperText={
                touched['addNewTeam'] && errors['addNewTeam']
              }
              autoFocus
              required
              onBlur={handleBlur}
              InputProps={{
                className: classes.input,
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={!isValid}
            onClick={handleSubmit}
            type="submit"
            color="primary"
          >
            {t('settings.add')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default AddNewTeam;
