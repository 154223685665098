import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    emptyProductsTextL: {
      ...CommonFonts.darkFont18,
    },
  })
)

export default useStyles
