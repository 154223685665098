import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { find, isEmpty } from 'lodash'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import OptionsList from './optionsList'
import OptionDetail from './optionDetail'
import PreLoader from '@/components/preLoader'

import { useQuery, useMutation } from '@apollo/client';
import { GET_STYLE_OPTIONS, MUTATE_ADD_STYLE_OPTION } from '@/services/styleService'

import useStyles from './styles';

export default function ResponsiveDialog({
  open,
  handleClose,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: styleOptionsData,
    loading: isLoadingStyleOptions,
    refetch: refetchStyleOptions
  } = useQuery(GET_STYLE_OPTIONS)

  const styleOptions = styleOptionsData?.styleOptions || []

  const [selectedOption, setSelectedOption] = useState(null)

  const [addUpdateOptionMutation, { loading: isUpdatingOption }] = useMutation(MUTATE_ADD_STYLE_OPTION, {
    onCompleted: (data) => {
      refetchStyleOptions()
      enqueueSnackbar(t('settings.inventory.success'))
      setSelectedOption(null)
    },
    onError: (error) => {
      const message = error?.message || error?.error[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  })

  const onClickConfigure = (option) => {
    setSelectedOption(option)
  }

  const onSaveOption = () => {
    const originalOption = find(styleOptions, { id: selectedOption.id })
    var values = selectedOption.values.map(value => ({
      id: value.id,
      flag: find(originalOption.values, { id: value.id }) ? 'update' : 'add',
      values: value.values.map(val => ({
        name: val.name,
        value: val.value
      }))
    }))
    originalOption.values.forEach(value => {
      if (!find(selectedOption.values, { id: value.id })) {
        values.push({
          id: value.id,
          flag: 'delete',
          values: value.values.map(val => ({
            name: val.name,
            value: val.value
          }))
        })
      }
    })
    var variables = {
      input: {
        id: selectedOption.id,
        flag: 'update',
        name: selectedOption.name,
        fields: selectedOption.fields.map(field => ({
          name: field.name,
          type: field.type,
          display: field.display
        })),
        values
      }
    }
    addUpdateOptionMutation({
      variables
    })
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          alignContent="center"
        >
          <Grid item xs={1} />
          <Grid className={classes.headerText} item xs={10}>
            <span style={{ fontSize: 18 }}>
              {selectedOption
                ? selectedOption.name + ' ' + t('settings.inventory.values')
                : t('settings.inventory.manage_style_options')
              }
            </span>
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            <IconButton
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.rootDialog}>
          {selectedOption
            ?
            <OptionDetail
              option={selectedOption}
              onUpdateOption={setSelectedOption}
            />
            :
            <OptionsList
              options={styleOptions}
              isLoading={isLoadingStyleOptions}
              onClickConfigure={onClickConfigure}
            />
          }
        </div>
      </DialogContent>
      {selectedOption &&
        <DialogActions style={{ paddingRight: 30 }}>
          <Button
            color="primary"
            className={classes.cancelButton}
            onClick={() => setSelectedOption(null)}
          >
            {t('button_group.cancel')}
          </Button>
          <Button
            color="primary"
            onClick={onSaveOption}
            disabled={isEmpty(selectedOption.values)}
          >
            {t('button_group.save')}
          </Button>
        </DialogActions>
      }
      {isUpdatingOption && <PreLoader size={35} />}
    </Dialog>
  )
}

