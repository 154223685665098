import React from 'react';

import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Input from '@/pages/businessManager/products/addUpdateProduct/components/input';
import Select from '@/pages/businessManager/products/addUpdateProduct/components/selector';
import SingleCheckboxInput from '@/components/singleCheckbox';
import useStyles from './styles';
import _ from 'lodash';

const ShelfInput = ({ shelf, areas, updateShelf }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      className={classes.boxShelf}
      marginY={5}
      display="flex"
      alignItems="center"
    >
      <Grid container spacing={10}>
        <Grid item xs={12} sm={6} md={2}>
          <Input
            name="aisle"
            title={t('modules.floor_and_shelf.aisle')}
            emptyLabel={t('settings.add')}
            value={shelf?.aisle || ''}
            onChange={(value) =>
              updateShelf({ key: 'aisle', value: value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Input
            name="shelf"
            title={t('modules.floor_and_shelf.shelf')}
            emptyLabel={t('settings.add')}
            value={shelf?.shelf || ''}
            onChange={(value) =>
              updateShelf({ key: 'shelf', value: value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Select
            name="areas"
            title={t('modules.floor_and_shelf.area')}
            emptyLabel={t('settings.select')}
            items={areas}
            getItemLabel={(item) => item.name}
            selectedItem={
              areas?.find((area) =>
                _.isEqual(area.id, shelf?.area?.id)
              ) || {}
            }
            onChangeValue={(value) =>
              updateShelf({ key: 'area', value: value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SingleCheckboxInput
            style={{ paddingBottom: 0 }}
            isShowToast={false}
            value={shelf?.customer_access || false}
            label={t(
              'modules.floor_and_shelf.customer_access_permitted'
            )}
            onChange={(value) =>
              updateShelf({ key: 'customer_access', value: value })
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShelfInput;
