import React from 'react'

import {
  Table,
  TableBody,
} from '@material-ui/core'

import TableHeader from './tableHeader'
import TableRow from './tableRow'

import useStyles from './styles';


const TableComponent = (props) => {
  const classes = useStyles()

  const {
    applications,
    onViewApplication,
  } = props

  return (
    <Table className={classes.boxTable}>
      <TableHeader />
      <TableBody>
        {applications.map((item) => (
          <TableRow
            key={item.id}
            item={item}
            onViewApplication={onViewApplication}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default TableComponent