import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';

export default function ViewCustomerDialog({
                                            open,
                                            handleClose,
                                            customer,
                                          }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            alignContent="center"
          >
            <Grid item xs={1} />
            <Grid className={classes.headerText} item xs={10}>
              <span style={{ fontSize: 18 }}>
                {customer.first_name} {customer.last_name}
              </span>
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" alignContent="center">
           <Grid row xs={12} className={classes.gridItem}>
             <Typography>
               {t('customers.general_information')}
             </Typography>
           </Grid>
            <Grid xs={2} row className={classes.gridItem}>
              <Typography>
                {t('customers.customer_first_name')}
              </Typography>
              <Typography>
               {customer.first_name}
             </Typography>
            </Grid>
            <Grid xs={2} row className={classes.gridItem}>
              <Typography>
                {t('customers.customer_last_name')}
              </Typography>
              <Typography>
                {customer.last_name}
              </Typography>
            </Grid>
            <Grid xs={4} row className={classes.gridItem}>
              <Typography>
                {t('customers.customer_email')}
              </Typography>
              <Typography>
                {customer.email ? customer.email : t('customers.na')}
              </Typography>
            </Grid>
            <Grid xs={2} row className={classes.gridItem}>
              <Typography>
                {t('customers.customer_mobile')}
              </Typography>
              <Typography>
                {customer.mobile ? customer.mobile : t('customers.na') }
              </Typography>
            </Grid>
            <Grid row xs={12} className={classes.gridItem}>
              <Typography>
                {t('customers.gender')}
              </Typography>
             <Typography>
               {customer.gender? customer.gender.charAt(0).toUpperCase() + customer.gender.slice(1) :
                 t('customers.na')}
             </Typography>
            </Grid>
            <Grid
              row
              xs={12}
              className={classes.gridItem}
            >
              <Typography>
                {t('customers.loyalty')}
              </Typography>
              <Typography>
                {customer.loyalty ? customer.loyalty : t('customers.no_data')}
              </Typography>
            </Grid>
            <Grid row xs={12} className={classes.gridItem}>
             <Typography>
               {t('customers.orders')}
             </Typography>
              <Typography>
                {customer.orders ? customer.orders : t('customers.no_data') }
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: 30 }}>
          <Button
            onClick={handleClose}
            className={classes.cancelButton}
          >
            {t('customers.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
