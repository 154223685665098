import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  appLayout: {
    backgroundColor: Colors.GRAY_LIGHT,
  },
  root: {
    position: 'relative',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingRight: 30,
    paddingLeft: 30,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  breadcrumbsText: {
    cursor: 'pointer'
  },
  emptyProductsContainer: {
    paddingTop: theme.spacing(4),
    width: '100%',
  },
  listHeader: {
    background: '#F3F5F7 0% 0% no-repeat padding-box',
    opacity: '1',
    height: 60,
    cursor: 'default',
    ...CommonFonts.darkFont16,
  },
  listItem: {
    height: 80,
    borderBottom: '1px solid #0000001F',
    ...CommonFonts.darkFont18,
  },
  dotsIcon: {
    textAlign: 'right',
    zIndex: 100,
  },
}));

export default useStyles;
