import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import TimePicker from '@/components/timePicker';

const ReportDayEndTime = forwardRef(({ value, onChange }, ref) => {
  const { t } = useTranslation();

  const timePickerRef = useRef();

  useImperativeHandle(ref, () => ({
    handleOpenEditModal: () =>
      timePickerRef?.current?.handleOpenEditModal(),
  }));

  return (
    <TimePicker
      ref={timePickerRef}
      name="reportDayEndTime"
      label={t('settings.reports.day_end_time')}
      subTitle={t('settings.reports.day_end_time_sub_title')}
      value={value}
      onChange={onChange}
    />
  );
});

export default ReportDayEndTime;
