import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.grayFont16,
    },
    value: {
      ...CommonFonts.grayFont18,
    },
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    box: {
      marginBottom: theme.spacing(10),
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(25),
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(7),
      },
    },
    addAction: {
      textTransform: 'capitalize',
      cursor: 'pointer',
      color: '#1174F2',
    },
    editIcon: {
      padding: theme.spacing(1),
    },
    boxStreetBuiding: {
      display: 'flex',
      paddingBottom: theme.spacing(5),
    },
    streetNumber: {
      width: '30%',
      marginRight: theme.spacing(7),
    },
    buildingName: {
      width: '70%',
    },
    boxFloorUnit: {
      display: 'flex',
      paddingBottom: theme.spacing(5),
    },
    floorNumber: {
      width: '50%',
      marginRight: theme.spacing(7),
    },
    unitNumber: {
      width: '50%',
    },
    boxStreetName: {
      paddingBottom: theme.spacing(5),
    },
    boxCity: {
      paddingBottom: theme.spacing(5),
    },
    boxPostCode: {
      paddingBottom: theme.spacing(5),
    },
    subTitle: {
      ...CommonFonts.grayFont16,
      marginBottom: theme.spacing(12),
      textAlign: 'center',
    },
  })
);

export default useStyles;
