import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const SettingInputCopy = ({ name, label, value }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  return (
    <Box className={classes.boxWithDescription}>
      <Box display="flex" alignItems="center">
        <Typography className={classes.titleText}>{label}</Typography>
        <CopyToClipboard
          text={value}
          onCopy={() =>
            enqueueSnackbar(
              `${capitalizeEachLetter(label)} ${t('settings.copied')}`
            )
          }
        >
          <Typography className={classes.copyAction}>
            {t('settings.copy')}
          </Typography>
        </CopyToClipboard>
      </Box>
      <Typography
        style={{
          width: '90%',
          textDecoration: 'underline',
          wordBreak: 'break-all',
        }}
        className={classes.descriptionText}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default SettingInputCopy;
