import React from 'react'
import PropTypes from 'prop-types'

import { Box, TableHead, TableRow, TableCell, withStyles } from '@material-ui/core'
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons'
import ArrowIcon from './icon'

import { CommonFonts } from '@/theme'
import { SHOPPERS_SORT_TYPE, SORT_DIRECTION } from '../../helper'
import i18next from 'i18next'

const dropIcons = {
  [SORT_DIRECTION.ASCENDING]: ArrowDropUp,
  [SORT_DIRECTION.DESCENDING]: ArrowDropDown,
}

const headCells = [
  {
    id: 'first_name',
    label: i18next.t('workboard.debts_widget.first_name'),
    width: '15%',
    sortType: null,
  },
  {
    id: 'last_name',
    label: i18next.t('workboard.debts_widget.last_name'),
    width: '15%',
    sortType: null,
  },
  {
    id: 'mobile',
    label: i18next.t('workboard.debts_widget.mobile'),
    width: '20%',
    sortType: null,
  },
  {
    id: 'no_of_invoices',
    label: i18next.t('workboard.debts_widget.no_of_invoices'),
    width: '15%',
    sortType: SHOPPERS_SORT_TYPE.no_of_invoices,
  },
  {
    id: 'outstanding',
    label: i18next.t('workboard.debts_widget.outstanding_amount'),
    width: '20%',
    sortType: SHOPPERS_SORT_TYPE.outstanding,
  },
  {
    id: 'payButton',
    label: i18next.t('workboard.debts_widget.pay_button'),
    width: '15%',
    sortType: null,
    justifyContent: 'center',
  },
]

const TableHeader = props => {
  const { sortType, sortDirection, onPressSort } = props

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            style={{
              width: headCell.width,
              cursor: headCell.sortType ? 'pointer' : 'default',
              borderBottom: 'none',
            }}
            onClick={() => (headCell.sortType ? onPressSort(headCell.sortType) : {})}
          >
            <Box display="flex" justifyContent={headCell.justifyContent}>
              {headCell.label}
              {headCell.sortType && (
                <ArrowIcon
                  icon={dropIcons[sortDirection]}
                  isSelected={headCell.sortType === sortType}
                />
              )}
            </Box>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
    ...CommonFonts.darkFont14,
    fontWeight: 'normal',
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont14,
  },
}))(TableCell)

TableHeader.propTypes = {
  sortType: PropTypes.string,
  sortDirection: PropTypes.object,
  onPressSort: PropTypes.func,
}

export default TableHeader
