import React, { useState } from 'react';

import {
  Box,
  Typography,
  CircularProgress,
  Popover,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

const ColorPicker = ({ label, value, onChange }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [color, setColor] = useState(value);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handlePickerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePickerClose = () => {
    setAnchorEl(null);
    onChange(color);
  };

  const onEnter = (event) => {
    const colorHex = event.target.value;
    if (_.isEqual(event.keyCode, 13)) {
      setColor(colorHex);
      onChange(colorHex);
    }
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? `${label}-popover` : undefined;

  return (
    <Box className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
      <Box display="flex" alignItems="center">
        <div
          aria-describedby={popoverId}
          onClick={handlePickerClick}
          className={classes.boxColor}
          style={{ backgroundColor: color }}
        >
          {isLoading && <CircularProgress size={22} />}
        </div>
        <div className={classes.boxInputHex}>
          <div className={classes.boxHex}>
            <Typography className={classes.hexText}>
              {t('settings.branding.hex')}
            </Typography>
          </div>
          <div>
            <TextField
              className={classes.inputText}
              onChange={(e) => {
                setColor(e.target.value)
                onChange(e.target.value)
              }}
              onKeyDown={(e) => onEnter(e)}
              value={color}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
        </div>
      </Box>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePickerClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          root: classes.accountPopoverContainer,
        }}
      >
        <SketchPicker
          color={color}
          onChange={(color, event) => setColor(color.hex)}
        />
      </Popover>
    </Box>
  );
};

export default ColorPicker;
