import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { omit, isEmpty } from 'lodash';
import { useMutation } from '@apollo/client';
import { UPDATE_STORE_OPENINGS } from '@/services/storeService';
import { useSelector } from 'react-redux';
import { selectSelectedStore } from '@/store/modules/store';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import OpeningHoursTable from './table';
import useStyles from './styles';

const OpeningHours = ({
                        open,
                        handleClose
                      }) => {
    const { t } = useTranslation();
    const currentStore = useSelector(selectSelectedStore);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [updatingData, setUpdatingData] = useState([]);

    const [UpdateData, { loading: updating }] =
      useMutation(UPDATE_STORE_OPENINGS, {
        onCompleted: () => {
          enqueueSnackbar(t('workboard.manage_store.opening_hours_dialog.success_updated'));
        },
        onError: (error) => {
          const message = error?.message || error?.errors[0]?.message;
          enqueueSnackbar(message, { variant: 'error' });
        }
      });

    const onHandleClickSubmit = () => {
      const data = updatingData.map((item) => {
        if (!isEmpty(item.opening_times)) {
          item.opening_times = item.opening_times
          .map(time => ({...time, open: time.open, close: time.close}))
          .map((time) => omit(time, '__typename'));
        }
        return omit(item, ['__typename', 'id']);
      });

      const payload = {
        variables: {
          store: {
            id: currentStore.id,
            store_openings: data
          }
        }
      };
      handleClose();
      UpdateData(payload);
    };

    return (
      <>
        <Dialog
          maxWidth="lg"
          open={open}
          fullWidth
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Grid
              container
              alignItems="center"
              justify="space-between"
              alignContent="center"
            >
              <Grid item xs={1} />
              <Grid className={classes.headerText} item xs={10}>
                {t('workboard.manage_store.opening_hours_dialog.title')}
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'right' }}>
                <IconButton
                  onClick={handleClose}
                  className={classes.closeButton}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container alignItems="center" alignContent="center">
              <OpeningHoursTable payloadData={(dataSet, removedData) => {
                setUpdatingData(dataSet);
              }} />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.submit}
              disableElevation
              onClick={() => onHandleClickSubmit()}
            >
              {t('settings.save')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
;

export default OpeningHours;