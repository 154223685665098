import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { UPDATE_STORE_OPENINGS } from '@/services/storeService';
import { useSelector } from 'react-redux';
import { selectSelectedStore } from '@/store/modules/store';
import { forEach, some } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@/components/textField';

import useStyles from './styles';

const UpdateDialog = ({
                        open,
                        handleClose,
                        index,
                        setRefetch,
                        slotsData,
                        dataSlots
                      }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const currentStore = useSelector(selectSelectedStore);
  const [confirmedValue, setConfirmedValue] = useState(slotsData?.slots[index]?.max_orders);

  const [UpdateData, { loading: updating }] =
    useMutation(UPDATE_STORE_OPENINGS, {
      onCompleted: (response) => {
        setRefetch();
        enqueueSnackbar(`${t('workboard.manage_store.delivery_slots.max_orders')} ${t('settings.updated')}`);
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    });


  // Save updated data
  const updateData = () => {
    const isNeedRewrite = some(dataSlots, ['date', slotsData.date]);
    let payloadData = dataSlots.map((item) => {
      delete item.__typename
      forEach(item.slots, function(value) {
        delete value.__typename;
      });
     forEach(slotsData?.slots, function(value, key) {
       delete value.__typename;
        if (item.date === slotsData.date && key === index) {
          value.max_orders = Number(confirmedValue);
        }
      });
      return item;
    });

    payloadData = isNeedRewrite ? payloadData : [...payloadData, ...[{
      date: slotsData.date,
      disabled: slotsData.disabled,
      slots: forEach(slotsData?.slots, function(value, key) {
        delete value.__typename;
        if (key === index) {
          value.max_orders = Number(confirmedValue);
        }
      })
    }]];

    const payload = {
      variables: {
        store: {
          id: currentStore.id,
          settings: {
            delivery: {
              day_slots: payloadData,
            }
          }
        }
      }
    };
    handleClose();
    return UpdateData(payload);
  };

  return (
    <>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            alignContent="center"
          >
            <Grid className={classes.headerText} item xs={11}>
              <Typography variant='subtitle2' gutterBottom={'true'} align='center'>
                {t('workboard.manage_store.delivery_slots.enter_max_order')} {slotsData?.slots[index]?.start} -
                {slotsData?.slots[index]?.end}
              </Typography>
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" alignContent="center">
            <Grid xs={12} item row className={classes.gridItem}>
              <TextField
                id="standard-basic"
                focused
                name="name"
                fullWidth
                type="number"
                placeholder={confirmedValue}
                onChange={(e) => setConfirmedValue(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: 30 }}>
          <Button
            color="default"
            onClick={handleClose}
            className={classes.cancelButton}
          >
            {t('workboard.manage_store.delivery_slots.cancel')}
          </Button>
          <Button
            disabled={slotsData?.slots[index]?.max_orders === confirmedValue}
            color="primary"
            onClick={updateData}
          >
            {t('workboard.manage_store.delivery_slots.update')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateDialog;