import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { isEmpty, find } from 'lodash';
import { v4 as uuid } from 'uuid';
import moment from 'moment'
import { useSnackbar } from 'notistack';

import {
  Breadcrumbs,
  Link,
  Grid,
  Paper,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link as RouterLink } from 'react-router-dom';
import AppLayout from '@/components/appLayout';
import PreLoader from '@/components/preLoader';
import Modal from '@/components/modal';
import SearchHeader from './components/SearchHeader'
import NoExpenses from './components/NoExpenses'
import ExpenseTable from './table'
import AddUpdateExpense from './addUpdateExpense'
import AddFiles from './components/addFiles';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_EXPENSES, MUTATE_ADD_EXPENSE } from '@/services/expenseService'

import { selectSelectedStore } from '@/store/modules/store/selectors'

import { contains } from '@/utils/stringUtil'
import { getDateRange } from '@/utils/momentUtil'
import {
  EXPENSE_PAYMENT_STATUSES,
  EXPENSE_STATUSES,
  EXPENSE_STATE
} from './helper'
import { getPaymentStatus } from './addUpdateExpense/helper'
import useStyles from './styles';

const ONE_PAGE_LIMIT = 10

const Expenses = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const selectedStore = useSelector(selectSelectedStore)

  const [inputSearch, setInputSearch] = useState("");
  const [openExpenseModal, setOpenExpenseModal] = useState(false)
  const [selectedExpense, setSelectedExpense] = useState(null);

  const [expenses, setExpenses] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const [isAllExpensesLoaded, setIsAllExpensesLoaded] = useState(false)

  const [expenseStatuses, setExpenseStatuses] = useState(EXPENSE_STATUSES)
  const [expensePaymentStatuses, setExpensePaymentStatuses] = useState(EXPENSE_PAYMENT_STATUSES)
  const [dateRange, setDateRange] = useState([getDateRange(new Date()).start, getDateRange(new Date()).end])

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false)

  const filteredExpenses = expenses
    .filter(expense => contains(expense.payee.name, inputSearch) || contains(expense.order_no, inputSearch))
    .filter(expense => !!find(expensePaymentStatuses, { state: getPaymentStatus(expense).state }))
    .filter(expense => !!find(expenseStatuses, { state: expense.status?.name }))
    .filter(expense => expense.state !== EXPENSE_STATE.CANCELLED)

  // get products from api
  const [getExpenses, { loading: isLoadingExpenses }] = useLazyQuery(GET_EXPENSES, {
    onCompleted: (data) => {
      // if response give us less then 10 results, set all expenses loaded
      if (data.expenses.length < 10) {
        setIsAllExpensesLoaded(true);
      } else {
        setIsAllExpensesLoaded(false)
      }

      // update the array holding products
      setExpenses([...expenses, ...data.expenses]);
    },
    onError: () => {
      setIsAllExpensesLoaded(true);
    },
    fetchPolicy: 'no-cache',
  });

  const [addExpenseMutation, { loading: isUpdatingExpense }] = useMutation(MUTATE_ADD_EXPENSE, {
    onCompleted: (data) => {
      const expense = data.addExpenses?.[0]
      if (expense) {
        if (expense.state === EXPENSE_STATE.CANCELLED) {
          enqueueSnackbar(t('expenses.expense_voided'))
          setExpenses(expenses.filter(expense => expense.id !== data.addExpenses[0]?.id))
        } else {
          enqueueSnackbar(t('expenses.expense_updated'))
          setExpenses(expenses.map(item => item.id === expense.id ? expense : item))
        }
      }

    },
    onError: (error) => {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  })

  // change current page when user reaches bottom
  const handleLoadMore = () => {
    if (isEmpty(filteredExpenses)) return
    setCurrentPage(currentPage + 1);
  };

  const refetchExpenses = () => {
    getExpenses({
      variables: {
        filter: {
          count: true,
          page: currentPage,
          limit: ONE_PAGE_LIMIT,
        },
        start: moment(dateRange[0]),
        end: moment(dateRange[1])
      },
    });
  }

  // get products from API
  useEffect(() => {
    refetchExpenses()
  }, [currentPage, dateRange]);

  useEffect(() => {
    setExpenses([])
    setCurrentPage(1);
  }, [selectedStore]);

  const onVoidExpense = (expenseData) => {
    const variables = {
      input: {
        id: uuid(),
        expense_id: expenseData.id,
        bill_date: moment(expenseData.bill_date),
        due_date: moment(expenseData.due_date),
        creation: {
          time: moment().format()
        },
        expense_type: {
          id: expenseData.expense_type.id,
          name: expenseData.expense_type.name,
          type: expenseData.expense_type.type,
        },
        attachments: [],
        items: expenseData.items.map(item => ({
          account_id: item.account?.id,
          amount: item.amount,
          creation: {
            time: item.creation?.time
          },
          item_id: item.item_id,
          inventory_id: item.inventory_id,
          bar_code: item.bar_code,
          order: item.order,
          product_code: item.product_code,
          product_name: item.product_name,
          quantity: item.quantity * -1,
          taxes: item.taxes.map(tax => ({
            id: tax.id,
            name: tax.name,
            rate: tax.rate,
            amount: tax.amount
          }))
        })),
        order_no: expenseData.order_no,
        payee: {
          id: expenseData.payee.id,
          name: expenseData.payee.name,
          type: expenseData.payee.type
        },
        payments: [],
        state: EXPENSE_STATE.CANCELLED,
        statuses: {
          id: expenseData.status?.id,
          name: expenseData.status?.name,
          order: expenseData.status?.order
        },
        total: {
          amount: 0,
          taxes: []
        }
      }
    }
    addExpenseMutation({
      variables
    })
  }

  const onUpdateExpense = (expense) => {
    setOpenExpenseModal(true)
    setSelectedExpense(expense)
  }

  const onSuccessAddUpdateExpense = (newExpense) => {
    const otherExpenses = expenses.filter(expense => expense.id !== newExpense.id)
    setExpenses([...otherExpenses, newExpense])
  }

  const onClickAddFiles = (event, expense) => {
    setSelectedExpense(expense)
    setAnchorEl(event.currentTarget)
    setOpenDialog(true)
  }

  const onUpdateFiles = (attachments) => {
    const expenseData = selectedExpense
    const updatedAttachments = attachments.filter(item => !!item.flag)

    setOpenDialog(false)
    setSelectedExpense(false)

    const variables = {
      input: {
        id: uuid(),
        expense_id: expenseData.id,
        bill_date: moment(expenseData.bill_date),
        due_date: moment(expenseData.due_date),
        creation: {
          time: moment().format()
        },
        expense_type: {
          id: expenseData.expense_type.id,
          name: expenseData.expense_type.name,
          type: expenseData.expense_type.type,
        },
        attachments: updatedAttachments.map(item => ({
          id: item.id,
          flag: item.flag
        })),
        items: [],
        order_no: expenseData.order_no,
        payee: {
          id: expenseData.payee.id,
          name: expenseData.payee.name,
          type: expenseData.payee.type
        },
        payments: [],
        state: expenseData.state,
        statuses: {
          id: expenseData.status?.id,
          name: expenseData.status?.name,
          order: expenseData.status?.order
        },
        total: {
          amount: expenseData.total.amount,
          taxes: expenseData.total.taxes.map(tax => ({
            id: tax.id,
            name: tax.name,
            rate: tax.rate,
            amount: tax.amount
          }))
        }
      }
    }
    addExpenseMutation({
      variables
    })
  }

  return (
    <AppLayout
      className={classes.appLayout}
      withFooter
      header
      withServiceDropdown={true}
    >
      <div className={classes.root}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                className={classes.breadcrumbsText}
                color="primary"
                component={RouterLink}
                to="/business-manager"
              >
                {t('settings.business_manager')}
              </Link>
              <Link
                className={classes.breadcrumbsText}
                color={'textPrimary'}
                onClick={() => { }}
              >
                {t('menu.expenses')}
              </Link>
            </Breadcrumbs>
          </Grid>
          <SearchHeader
            expenseStatuses={expenseStatuses}
            expensePaymentStatuses={expensePaymentStatuses}
            dateRange={dateRange}
            onChangeExpenseStatuses={(value) => isEmpty(value)
              ? {}
              : setExpenseStatuses(value)
            }
            onChangeExpensePaymentStatuses={(value) => isEmpty(value)
              ? {}
              : setExpensePaymentStatuses(value)
            }
            onChangeDateRange={setDateRange}
            onChangeSearch={setInputSearch}
            onAdd={() => setOpenExpenseModal(true)}
          />
          <Grid item xs={12}>
            <Paper className={classes.emptyExpensesContainer}>
              {(isEmpty(filteredExpenses) && !isLoadingExpenses)
                ?
                <NoExpenses
                  title={t('expenses.no_expenses')}
                />
                :
                <InfiniteScroll
                  dataLength={filteredExpenses.length}
                  next={handleLoadMore}
                  hasMore={!isAllExpensesLoaded}
                  loader={
                    isLoadingExpenses && currentPage !== 1 && (
                      <div className={classes.root}>
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                      </div>
                    )
                  }
                >
                  <ExpenseTable
                    expenses={filteredExpenses}
                    isLoadingExpenses={isLoadingExpenses}
                    currentPage={currentPage}
                    onVoidExpense={onVoidExpense}
                    onUpdateExpense={onUpdateExpense}
                    onAddFiles={onClickAddFiles}
                  />
                </InfiniteScroll>
              }
            </Paper>
          </Grid>
        </Grid>
      </div>

      {openExpenseModal &&
        <AddUpdateExpense
          open={openExpenseModal}
          handleClose={() => {
            setOpenExpenseModal(false)
            setSelectedExpense(null)
          }}
          expense={selectedExpense}
          onSuccess={onSuccessAddUpdateExpense}
        />
      }
      
      {openDialog &&
        <Modal
          openDialog={openDialog}
          handleClose={() => {
            setOpenDialog(false)
            setSelectedExpense(false)
          }}
          anchorEl={anchorEl}
        >
          <AddFiles
            expense={selectedExpense}
            onUpdateFiles={onUpdateFiles}
          />
        </Modal>
      }

      {isUpdatingExpense && <PreLoader size={35} />}
    </AppLayout>
  )
}

export default Expenses