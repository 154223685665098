import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  checkUser: ['data'],
  login: ['data'],
  loginSuccess: ['data'],
  sendPhoneOtp: ['data'],
  createUser: ['data'],
  mobileLogin: ['data'],
  logout: ['data'],
  logoutSuccess: ['null'],
  addNewStore: ['merchant', 'store'],
  addNewMerchant: ['data'],
  updateStore: ['data'],
  updateMerchants: ['merchant'],
  setUserMerchants: ['data'],
  setUserProfile: ['data'],
  setMerchants: ['data'],
  setAccessToken: ['accessToken'],
  setRefreshToken: ['refreshToken'],
});

export const AuthTypes = Types;
export default Creators;
