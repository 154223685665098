import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isEmpty, find } from 'lodash';

import { Box, Grid, Typography, Button } from '@material-ui/core';
import PreLoader from '@/components/preLoader';
import PDFDocument from './pdfDocument';

import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { CSVLink } from 'react-csv';
import { getCSVData } from './csvDocument';

import { useLazyQuery, gql } from '@apollo/client';
import {
  GET_INSIGHTS_FOR_BUSINESS_SNAPSHOT,
  GET_ACCOUNT_TYPES_BUSINESS_OVERVIEW,
} from '@/services/widgetService';

import { transformFloat } from '@/utils/localisationUtil';
import { getTaxName } from '@/utils/taxUtil';
import useStyles from './styles';
import { selectLocalisation } from '@/store/modules/store';
import { BEST_SELLER_SORT_TYPE } from '@/pages/workboard/categorySalesAnalysis/helper';
import { CHILDREN_TYPES_IDS } from '@/constants/accountTypes';

const BusinessSnapshot = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { store, reportTime } = props;
  const localisation = useSelector(selectLocalisation);
  const csvRef = useRef();

  const [
    getInsights,
    { data: insightsData, loading: isLoadingInsights },
  ] = useLazyQuery(
    gql`
      ${GET_INSIGHTS_FOR_BUSINESS_SNAPSHOT}
    `,
    {
      fetchPolicy: 'network-only',
    }
  );
  const insights = insightsData?.insights;
  const categories = insights?.best_sellers?.categories || [];
  const totalTaxAmount = insights?.tax_breakdown?.total?.amount || 0;
  const taxesByName = insights?.tax_breakdown?.by_name || [];
  const taxesById = insights?.tax_breakdown?.by_id || [];

  const totalExpenseTaxAmount =
    insights?.expense_tax_breakdown?.total?.amount || 0;
  const expenseTaxesByName =
    insights?.expense_tax_breakdown?.by_name || [];
  const expenseTaxesById =
    insights?.expense_tax_breakdown?.by_id || [];

  const paymentTypeSales = insights?.payment_type_sales;
  const paymentTypeTotalSales = paymentTypeSales?.total || 0;

  const [
    getAccountTypes,
    { data: accountTypesData, loading: isLoadingAccountTypes },
  ] = useLazyQuery(
    gql`
      ${GET_ACCOUNT_TYPES_BUSINESS_OVERVIEW}
    `,
    {
      fetchPolicy: 'network-only',
    }
  );
  const accountTypes = accountTypesData?.accountTypes || [];
  const incomesAccountType = find(accountTypes, { name: 'INCOMES' });
  const assetsAccountType = find(accountTypes, { name: 'ASSETS' });
  const paymentsAccountType = find(accountTypes, {
    name: 'PAYMENTS',
  });

  const salesChildrenTypes =
    assetsAccountType?.children_types?.filter((childrenType) =>
      [
        CHILDREN_TYPES_IDS.PETTY_CASH,
        CHILDREN_TYPES_IDS.BANK,
      ].includes(childrenType.id)
    );

  useEffect(() => {
    getInsights({
      variables: {
        start: reportTime.start,
        end: reportTime.end,
        sortBy: BEST_SELLER_SORT_TYPE.GROSS,
      },
      context: {
        headers: { storeId: store.id },
      },
    });

    getAccountTypes({
      variables: {
        start: reportTime.start,
        end: reportTime.end,
      },
      context: {
        headers: { storeId: store.id },
      },
    });
  }, [store, reportTime]);

  const onClickPrint = () => {};

  const onClickExportPDF = async () => {
    const doc = (
      <PDFDocument
        store={store}
        reportTime={reportTime}
        insights={insights}
        incomesAccountType={incomesAccountType}
        paymentsAccountType={paymentsAccountType}
        paymentTypeSales={paymentTypeSales}
        salesChildrenTypes={salesChildrenTypes}
        categories={categories}
        totalTaxAmount={totalTaxAmount}
        taxesByName={taxesByName}
        taxesById={taxesById}
        totalExpenseTaxAmount={totalExpenseTaxAmount}
        expenseTaxesByName={expenseTaxesByName}
        expenseTaxesById={expenseTaxesById}
        localisation={localisation}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, 'business-snapshot.pdf');
  };

  const onClickExportCSV = () => {
    csvRef.current.link.click();
  };

  const getNetSale = (item) => {
    return item.total_sale.net + item.total_sale.promotion;
  };

  const getGrossSale = (item) => {
    return (
      item.total_sale.net +
      item.total_sale.promotion +
      item.total_sale.tax +
      item.total_sale.promotion_tax
    );
  };

  const hasAccounts = (childrenType) => {
    return !isEmpty(
      (childrenType.accounts || []).filter(
        (account) => account.display_in_report
      )
    );
  };

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={4}>
          <Typography className={classes.titleText}>
            {t('workboard.run_reports.business_snapshot')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container justify="space-between">
            <Grid>
              <Button disabled onClick={onClickPrint} color="primary">
                {t('workboard.run_reports.print')}
              </Button>
            </Grid>
            <Grid>
              <Button onClick={onClickExportPDF} color="primary">
                {t('workboard.run_reports.export_pdf')}
              </Button>
            </Grid>
            <Grid>
              <Button onClick={onClickExportCSV} color="primary">
                {t('workboard.run_reports.export_csv')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box style={{ marginTop: 25 }}>
        <Typography className={classes.storeNameText}>
          {store.name}
        </Typography>
      </Box>

      <Box style={{ marginTop: 25 }}>
        <Typography className={classes.storeNameText}>
          {moment(reportTime.start).format('DD/MM/YYYY HH:mm:ss') +
            ' - ' +
            moment(reportTime.end).format('DD/MM/YYYY HH:mm:ss')}
        </Typography>
      </Box>

      {!isLoadingAccountTypes && !isLoadingInsights && (
        <React.Fragment>
          <Box className={classes.accountTypeContainer}>
            <Box display="flex" justifyContent="space-between">
              <Typography className={classes.titleText}>
                {t('workboard.run_reports.transactions')}
              </Typography>
              <Typography className={classes.titleText}>
                {insights?.performances?.no_of_transactions}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.childrenTypeWrapper}
            >
              <Typography className={classes.storeNameText}>
                {t('workboard.run_reports.no_of_orders')}
              </Typography>
              <Typography className={classes.storeNameText}>
                {insights?.performances?.no_of_transactions}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.childrenTypeWrapper}
            >
              <Typography className={classes.storeNameText}>
                {t('workboard.run_reports.atv')}
              </Typography>
              <Typography className={classes.storeNameText}>
                {transformFloat(
                  insights?.performances?.average_transaction_value ||
                    0,
                  localisation
                )}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.accountTypeContainer}>
            <Box display="flex" justifyContent="space-between">
              <Typography className={classes.titleText}>
                {t('workboard.run_reports.sales_gross')}
              </Typography>
              <Typography className={classes.titleText}>
                {transformFloat(paymentTypeTotalSales, localisation)}
              </Typography>
            </Box>
            {paymentTypeSales?.payment_types?.map(
              (paymentType, id1) => (
                <Box
                  key={`children-type-${id1}`}
                  className={classes.childrenTypeContainer}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    className={classes.childrenTypeWrapper}
                  >
                    <Typography className={classes.storeNameText}>
                      {paymentType.name}
                    </Typography>
                    <Typography className={classes.storeNameText}>
                      {transformFloat(
                        paymentType.amount,
                        localisation
                      )}
                    </Typography>
                  </Box>
                </Box>
              )
            )}
          </Box>

          <Box className={classes.accountTypeContainer}>
            <Box display="flex" justifyContent="space-between">
              <Typography className={classes.titleText}>
                {t('workboard.run_reports.expenses_gross')}
              </Typography>
              <Typography className={classes.titleText}>
                {transformFloat(
                  paymentsAccountType?.performance?.performance || 0,
                  localisation
                )}
              </Typography>
            </Box>
            {paymentsAccountType?.children_types
              ?.filter((childrenType) => hasAccounts(childrenType))
              ?.map((childrenType, id1) => (
                <Box
                  key={`children-type-${id1}`}
                  className={classes.childrenTypeContainer}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    className={classes.childrenTypeWrapper}
                  >
                    <Typography className={classes.storeNameText}>
                      {childrenType.name}
                    </Typography>
                    <Typography className={classes.storeNameText}>
                      {transformFloat(
                        childrenType.performance.performance,
                        localisation
                      )}
                    </Typography>
                  </Box>
                  {childrenType.accounts
                    .filter((account) => account.display_in_report)
                    .map((account, id2) => (
                      <Box
                        key={`account-${id1}-${id2}`}
                        display="flex"
                        justifyContent="space-between"
                        className={classes.accountWrapper}
                      >
                        <Typography className={classes.storeNameText}>
                          {account.name}
                        </Typography>
                        <Typography className={classes.storeNameText}>
                          {transformFloat(
                            account.performance.performance,
                            localisation
                          )}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              ))}
          </Box>

          <Box className={classes.accountTypeContainer}>
            <Box display="flex" justifyContent="space-between">
              <Typography className={classes.titleText}>
                {t('workboard.run_reports.tax_summary')}
              </Typography>
              <Typography className={classes.titleText}>
                {transformFloat(
                  totalTaxAmount - totalExpenseTaxAmount,
                  localisation
                )}
              </Typography>
            </Box>
            {!isEmpty(taxesByName) && (
              <Box marginTop={7} position="relative" height={24}>
                <Typography
                  className={classes.titleText}
                  style={{ position: 'absolute', right: 480 }}
                >
                  {t('workboard.run_reports.net')}
                </Typography>
                <Typography
                  className={classes.titleText}
                  style={{ position: 'absolute', right: 360 }}
                >
                  {t('workboard.run_reports.tax')}
                </Typography>
              </Box>
            )}
            {taxesByName.map((group) => (
              <React.Fragment key={group.tax_name}>
                <Box marginTop={5} position="relative" height={24}>
                  <Typography
                    className={classes.storeNameText}
                    style={{ position: 'absolute', left: 0 }}
                  >
                    {t('workboard.run_reports.tax_on_sales', {
                      name: group.tax_name,
                    })}
                  </Typography>
                  <Typography
                    className={classes.storeNameText}
                    style={{ position: 'absolute', right: 480 }}
                  >
                    {transformFloat(group.tax.sold, localisation)}
                  </Typography>
                  <Typography
                    className={classes.storeNameText}
                    style={{ position: 'absolute', right: 360 }}
                  >
                    {transformFloat(group.tax.amount, localisation)}
                  </Typography>
                  <Typography
                    className={classes.storeNameText}
                    style={{ position: 'absolute', right: 240 }}
                  >
                    {transformFloat(group.tax.amount, localisation)}
                  </Typography>
                </Box>
                {taxesById
                  .filter(
                    (tax) =>
                      tax.tax_name === group.tax_name &&
                      tax.tax_rate != null
                  )
                  .sort((a, b) => b.tax_rate - a.tax_rate)
                  .map((tax) => (
                    <Box
                      key={`${group.tax_name}-${tax.tax_id}`}
                      marginTop={5}
                      position="relative"
                      height={24}
                    >
                      <Typography
                        className={classes.storeNameText}
                        style={{ position: 'absolute', left: 28 }}
                      >
                        {getTaxName({
                          name: tax.tax_name,
                          rate: tax.tax_rate,
                        })}
                      </Typography>
                      <Typography
                        className={classes.storeNameText}
                        style={{ position: 'absolute', right: 480 }}
                      >
                        {transformFloat(tax.tax.sold, localisation)}
                      </Typography>
                      <Typography
                        className={classes.storeNameText}
                        style={{ position: 'absolute', right: 360 }}
                      >
                        {transformFloat(tax.tax.amount, localisation)}
                      </Typography>
                    </Box>
                  ))}
              </React.Fragment>
            ))}
            {expenseTaxesByName.map((group) => (
              <React.Fragment key={group.tax_name}>
                <Box marginTop={5} position="relative" height={24}>
                  <Typography
                    className={classes.storeNameText}
                    style={{ position: 'absolute', left: 0 }}
                  >
                    {t('workboard.run_reports.tax_on_expenses', {
                      name: group.tax_name,
                    })}
                  </Typography>
                  <Typography
                    className={classes.storeNameText}
                    style={{ position: 'absolute', right: 480 }}
                  >
                    {transformFloat(group.tax.sold, localisation)}
                  </Typography>
                  <Typography
                    className={classes.storeNameText}
                    style={{ position: 'absolute', right: 360 }}
                  >
                    {transformFloat(group.tax.amount, localisation)}
                  </Typography>
                  <Typography
                    className={classes.storeNameText}
                    style={{ position: 'absolute', right: 240 }}
                  >
                    {transformFloat(group.tax.amount, localisation)}
                  </Typography>
                </Box>
                {expenseTaxesById
                  .filter(
                    (tax) =>
                      tax.tax_name === group.tax_name &&
                      tax.tax_rate != null
                  )
                  .sort((a, b) => b.tax_rate - a.tax_rate)
                  .map((tax) => (
                    <Box
                      key={`${group.tax_name}-${tax.tax_id}`}
                      marginTop={5}
                      position="relative"
                      height={24}
                    >
                      <Typography
                        className={classes.storeNameText}
                        style={{ position: 'absolute', left: 28 }}
                      >
                        {getTaxName({
                          name: tax.tax_name,
                          rate: tax.tax_rate,
                        })}
                      </Typography>
                      <Typography
                        className={classes.storeNameText}
                        style={{ position: 'absolute', right: 480 }}
                      >
                        {transformFloat(tax.tax.sold, localisation)}
                      </Typography>
                      <Typography
                        className={classes.storeNameText}
                        style={{ position: 'absolute', right: 360 }}
                      >
                        {transformFloat(tax.tax.amount, localisation)}
                      </Typography>
                    </Box>
                  ))}
              </React.Fragment>
            ))}
          </Box>

          <Box className={classes.accountTypeContainer}>
            <Box display="flex" justifyContent="space-between">
              <Typography className={classes.titleText}>
                {t('workboard.run_reports.product_summary')}
              </Typography>
              <Typography className={classes.titleText}>
                {transformFloat(
                  insights?.performances?.total_value || 0,
                  localisation
                )}
              </Typography>
            </Box>
            {!isEmpty(categories) && (
              <Box marginTop={7} position="relative" height={24}>
                <Typography
                  className={classes.titleText}
                  style={{ position: 'absolute', right: 480 }}
                >
                  {t('workboard.run_reports.no_of_items')}
                </Typography>
                <Typography
                  className={classes.titleText}
                  style={{ position: 'absolute', right: 360 }}
                >
                  {t('workboard.run_reports.net')}
                </Typography>
                <Typography
                  className={classes.titleText}
                  style={{ position: 'absolute', right: 240 }}
                >
                  {t('workboard.run_reports.gross')}
                </Typography>
              </Box>
            )}

            {categories.map((category) => (
              <Box
                key={category.name}
                marginTop={5}
                position="relative"
                height={24}
              >
                <Typography
                  className={classes.storeNameText}
                  style={{ position: 'absolute', left: 0 }}
                >
                  {category.name}
                </Typography>
                <Typography
                  className={classes.storeNameText}
                  style={{ position: 'absolute', right: 480 }}
                >
                  {category.item_sold}
                </Typography>
                <Typography
                  className={classes.storeNameText}
                  style={{ position: 'absolute', right: 360 }}
                >
                  {transformFloat(getNetSale(category), localisation)}
                </Typography>
                <Typography
                  className={classes.storeNameText}
                  style={{ position: 'absolute', right: 240 }}
                >
                  {transformFloat(
                    getGrossSale(category),
                    localisation
                  )}
                </Typography>
              </Box>
            ))}
          </Box>
        </React.Fragment>
      )}

      <CSVLink
        data={getCSVData(
          store,
          reportTime,
          insights,
          incomesAccountType,
          paymentsAccountType,
          salesChildrenTypes,
          paymentTypeSales,
          categories,
          totalTaxAmount,
          taxesByName,
          taxesById,
          totalExpenseTaxAmount,
          expenseTaxesByName,
          expenseTaxesById,
          localisation
        )}
        filename="business-snapshot.csv"
        className="hidden"
        ref={csvRef}
        target="_blank"
      />

      {(isLoadingInsights || isLoadingAccountTypes) && (
        <PreLoader size={30} />
      )}
    </div>
  );
};

BusinessSnapshot.propTypes = {
  store: PropTypes.object,
  reportTime: PropTypes.object,
};

export default BusinessSnapshot;
