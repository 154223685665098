import React, { useEffect } from 'react';
import SelectPicker from '@/components/selectPicker';
import { useTranslation } from 'react-i18next';
import { REGISTRATION_TYPE_LIST } from '@/services/storeService';
import { gql, useLazyQuery } from '@apollo/client';
import * as Yup from 'yup';

const RegistrationTypePicker = ({ isFetch, value, onChange }) => {
  const { t } = useTranslation();

  const registrationTypeSchema = Yup.object().shape({
    registration_type: Yup.object({
      id: Yup.string().required(
        t('settings.business_and_finance.registration_type_required')
      ),
      name: Yup.string().required(
        t('settings.business_and_finance.registration_type_required')
      ),
    }),
  });

  const registrationTypeQuery = gql`
    ${REGISTRATION_TYPE_LIST}
  `;
  const [getInitData, { data, previousData }] = useLazyQuery(
    registrationTypeQuery
  );
  const options =
    data?.registrationTypes[0]?.entities ||
    previousData?.registrationTypes[0]?.entities;

  useEffect(() => {
    if (isFetch) {
      getInitData();
    }
  }, [isFetch]);

  return (
    <SelectPicker
      name="registration_type"
      label={t(
        'settings.business_and_finance.input.registration_type'
      )}
      placeholder={t('settings.business_and_finance.select_type')}
      options={options}
      currentId={value.id}
      value={value.name}
      onChange={onChange}
      validationSchema={registrationTypeSchema}
    />
  );
};

export default RegistrationTypePicker;
