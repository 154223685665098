import React from 'react'
import { useTranslation } from 'react-i18next';

import {
  TableHead,
  TableRow,
  TableCell,
  withStyles
} from '@material-ui/core'

import { EXPENSE_TAX_STATUS } from '../../../helper'
import { CommonFonts } from '@/theme'

const TableHeader = ({ taxStatus }) => {
  const { t } = useTranslation()

  const headCellsWithTax = [
    {
      id: 'product_code',
      label: t('expenses.product_code'),
      width: '10%',
    },
    {
      id: 'product_name_description',
      label: t('expenses.product_name_description'),
      width: '22%',
    },
    {
      id: 'account',
      label: t('expenses.account'),
      width: '21%',
    },
    {
      id: 'qty',
      label: t('expenses.qty'),
      width: '8%',
    },
    {
      id: 'unit_price',
      label: t('expenses.unit_price'),
      width: '8%',
    },
    {
      id: 'tax_rate',
      label: t('expenses.tax_rate'),
      width: '16%',
    },
    {
      id: 'total',
      label: t('expenses.total'),
      width: '10%',
    },
    {
      id: 'empty',
      label: '',
      width: '5%'
    }
  ];

  const headCellsWithoutTax = [
    {
      id: 'product_code',
      label: t('expenses.product_code'),
      width: '10%',
    },
    {
      id: 'product_name_description',
      label: t('expenses.product_name_description'),
      width: '27%',
    },
    {
      id: 'account',
      label: t('expenses.account'),
      width: '20%',
    },
    {
      id: 'qty',
      label: t('expenses.qty'),
      width: '10%',
    },
    {
      id: 'unit_price',
      label: t('expenses.unit_price'),
      width: '10%',
    },
    {
      id: 'total',
      label: t('expenses.total'),
      width: '15%',
    },
    {
      id: 'empty',
      label: '',
      width: '5%'
    }
  ];

  const headCells = taxStatus === EXPENSE_TAX_STATUS.NO_TAX
    ? headCellsWithoutTax
    : headCellsWithTax

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            style={{
              width: headCell.width,
              borderBottom: 'none'
            }}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    paddingRight: 10,
    paddingLeft: 10,
    borderBottom: 'none',
    ...CommonFonts.darkFont14,
    fontWeight: 'normal'
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont14
  },
}))(TableCell);


export default TableHeader