import React from 'react'
import { useSelector } from 'react-redux'


import { Typography, Box, Divider } from '@material-ui/core'


import { selectLocalisation } from '@/store/modules/store/selectors'

import { transformFloat } from '@/utils/localisationUtil'
import useStyles from './styles'

const SliderItem = ({ data }) => {
  const classes = useStyles();

  const localisation = useSelector(selectLocalisation);

  return (
    <Box className={classes.root}>
      <Box className={classes.sliderContainer}>
        <Typography className={classes.titleText}>{data.title}</Typography>
        <Typography className={classes.storenameText}>{data.store.name}</Typography>
        <Typography className={classes.amountText}>
          {data.isCurrency
            ?
            transformFloat(data.store.amount, localisation, { showCurrency: true })
            :
            data.store.amount
          }
        </Typography>
        {data.compareStore &&
          <React.Fragment>
            <Divider variant='middle' className={classes.divider} />
            <Typography className={classes.storenameText}>{data.compareStore.name}</Typography>
            <Typography className={classes.amountText}>
              {data.isCurrency
                ?
                transformFloat(data.compareStore.amount, localisation, { showCurrency: true })
                :
                data.compareStore.amount
              }
            </Typography>
          </React.Fragment>
        }
      </Box>
    </Box>
  )
}

export default SliderItem