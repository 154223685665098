import React from 'react'
import { useTranslation } from 'react-i18next';

import {
  TableHead,
  TableRow,
  TableCell,
  withStyles
} from '@material-ui/core'

import { CommonFonts } from '@/theme'

const TableHeader = () => {
  const { t } = useTranslation()

  const headCells = [
    {
      id: 'style_name',
      label: t('styles.style_name'),
      width: '20%',
    },
    {
      id: 'code',
      label: t('styles.code'),
      width: '20%',
    },
    {
      id: 'gender',
      label: t('styles.gender'),
      width: '15%',
    },
    {
      id: 'tags',
      label: t('styles.tags'),
      width: '15%',
    },
    {
      id: 'variants',
      label: t('styles.variants'),
      width: '15%',
    },
    {
      id: 'empty',
      label: '',
      width: '15%',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            style={{
              width: headCell.width,
              borderBottom: 'none'
            }}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
    ...CommonFonts.darkFont14,
    fontWeight: 'normal'
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont14
  },
}))(TableCell);


export default TableHeader