import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { Box } from '@material-ui/core'
import PreLoader from '@/components/preLoader'
import useStyles from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfPreview = ({ file }) => {
  const classes = useStyles()

  const [numPages, setNumPages] = useState(0)

  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf.numPages)
  }

  const renderPages = () => {
    return (
      Array.from(Array(numPages).keys()).map((index) =>
        <div key={index} className={classes.pdfPage}>
          <Page
            pageNumber={index + 1}
            renderTextLayer={false}
            renderInteractiveForms={false}
          />
          {/* <div className="mb-3 font-size-13 text-center">{index + 1}</div> */}
        </div>
      )
    )
  }

  return (
    <Box className={classes.root}>
      <Document
        loading={<PreLoader size={25} />}
        className={classes.pdfDocument}
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {renderPages()}
      </Document>
    </Box>
  )
}

export default PdfPreview