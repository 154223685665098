import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'

import {
  Typography,
  Box,
  FormHelperText,
  Popover
} from '@material-ui/core'
import TextField from '@/components/textField';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import Calendar from 'react-calendar'

import useStyles from './styles'

const Input = (props) => {
  const classes = useStyles()

  const [datePickerAnchor, setDatePickerAnchor] = useState(null)
  const anchor = useRef(null)
  const {
    title,
    emptyLabel,
    value,
    onChange,
    onBlur,
    error,
    helperText,
    ...restProps
  } = props

  return (
    <div ref={anchor}>
      <Typography
        className={
          classNames(classes.titleText, {
            [classes.errorTitleText]: error
          })
        }
      >
        {title}
      </Typography>

      {(!datePickerAnchor && !value) &&
        <Box>
          <Box onClick={(e) => {
            setDatePickerAnchor(e.currentTarget)
          }}>
            <Typography className={classes.emptyLabel}>
              {emptyLabel}
            </Typography>
          </Box>
          {error &&
            <FormHelperText error>
              {helperText}
            </FormHelperText>
          }
        </Box>
      }
      <Box style={{ position: 'relative' }}>
        {(!datePickerAnchor && !!value) && (
          <Box
            className={classes.labelContainer}
            onClick={(e) => setDatePickerAnchor(e.currentTarget)}
          >
            <Typography
              className={classes.label}
            >
              {moment(value).format('YYYY-MM-DD')}
              <IconButton
                className={classes.iconButton}
                onClick={(e) => setDatePickerAnchor(e.currentTarget)}
              >
                <CreateIcon fontSize='small' />
              </IconButton>
            </Typography>
          </Box>
        )}

        {datePickerAnchor && (
          <TextField
            autoFocus
            className={value && classes.textField}
            value={!!value ? moment(value).format('YYYY-MM-DD') : ''}
            onChange={() => { }}
            onBlur={(e) => {
              onBlur(e)
            }}
            error={error}
            helperText={error && helperText}
            {...restProps}
          />
        )}
      </Box>

      <Popover
        id='date-popover'
        open={Boolean(datePickerAnchor)}
        anchorEl={anchor.current}
        onClose={() => setDatePickerAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Calendar
          value={value}
          onChange={(date) => {
            onChange(date)
            setDatePickerAnchor(null)
          }}
        />
      </Popover>
    </div>
  )
}

Input.propTypes = {
  isCurrencyInput: PropTypes.bool,
  title: PropTypes.string,
  emptyLabel: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string
}

export default Input