import React, { useState, useEffect } from 'react';

import {
  Box,
  Tab,
  Grid,
  Tabs,
  Dialog,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useSnackbar } from 'notistack';
import {
  GET_ROLE_USER,
  GET_STORE,
  GET_ALL_PERMISSION,
} from '@/services/userManagementService';
import _ from 'lodash';
import SingleCheckboxInput from '@/components/singleCheckbox';
import {
  capitalizeEachLetter,
  convertUnderToTitleCase,
} from '@/utils/stringUtil';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { selectSelectedStore } from '@/store/modules/store/selectors';
import moment from 'moment';

const roleUserQuery = gql`
  ${GET_ROLE_USER}
`;

const storeQueries = gql`
  ${GET_STORE}
`;

const permissionQueries = gql`
  ${GET_ALL_PERMISSION}
`;

const TabType = [
  { index: 0, type: 'role_and_permissions' },
  { index: 1, type: 'store' },
  { index: 2, type: 'license' },
];

const UpdateUserAuthorize = ({
  isVisible,
  setUpdateUserAuthorizeModal,
  userDetail,
  plans,
  type,
  onChange,
  paymentMethodDefault,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const selectedStore = useSelector(selectSelectedStore);
  const [selectRole, setSelectRole] = useState('');
  const [index, setIndex] = useState(0);
  const [store, setStore] = useState({});
  const [initRoleStore, setInitRoleStore] = useState([]);
  const [updateUser, setUpdateUser] = useState({
    selectTeam: '',
    permissionList: [],
    isLoading: false,
  });
  const [licenses, setLicenses] = useState([]);

  const handleCloseEditModal = () => {
    setStore({});
    setIndex(0);
    setSelectRole('');
    setUpdateUser({
      selectTeam: '',
      permissionList: [],
      isLoading: false,
    });
    setUpdateUserAuthorizeModal(false);
  };

  const { loading, data } = useQuery(roleUserQuery);
  const roles = data?.roles;

  const { loading: loadingStore, data: storeData } =
    useQuery(storeQueries);
  const stores = storeData?.stores;

  const { data: permissionData } = useQuery(permissionQueries);
  const permissions = permissionData?.globalPermissions;

  const handleTabChange = (event, value) => setIndex(value);

  const hasSubscription = () => {
    let isExist = false;
    licenses?.map((license) => {
      if (
        !_.isEmpty(
          license?.packages[0]?.prices?.find(
            (price) => price.isSelected
          )
        )
      ) {
        isExist = true;
      }
    });

    return isExist;
  };

  const isShowPaymentStripe =
    hasSubscription() && _.isEmpty(paymentMethodDefault);

  useEffect(() => {
    if (userDetail?.roles?.length > 0) {
      const rolesFilter = userDetail?.roles?.filter(
        (role) => role.merchant_id === selectedStore.merchant_id
      );

      setInitRoleStore(
        rolesFilter?.map((item) => ({
          role_id: item.id,
          store_id: item.store_id,
        }))
      );
      setIndex(TabType?.find((item) => item.type === type)?.index);

      if (selectRole === '') {
        setSelectRole(rolesFilter[0]?.id);
      }

      const storeIds = rolesFilter?.map((item) => item.store_id);
      if (stores?.length > 0 && storeIds?.length > 0) {
        const storeList = _.groupBy(
          stores,
          (store) =>
            store?.region?.region_name ||
            t('userManagement.users.all_stores')
        );

        const result = Object.keys(storeList)?.reduce((acc, cur) => {
          return {
            ...acc,
            [cur]: storeList[cur].map((item) => ({
              ...item,
              isChecked: storeIds.includes('-1')
                ? true
                : storeIds.includes(item.id),
            })),
          };
        }, {});

        setStore(result);
      }

      if (rolesFilter[0]?.id) {
        let result = [];
        const userPermission =
          rolesFilter[0]?.id === selectRole
            ? rolesFilter[0]?.permissions?.split(',')
            : [];

        userPermission?.map((item) => {
          const value = item.split('.');
          const prefix = value[0];
          const suffix = value[1];
          result.push({ key: prefix, value: suffix });
        });

        const permissionGroupBy = _.groupBy(result, 'key');
        const permissionList = permissions?.map((per) => ({
          ...per,
          permissions: per?.permissions?.map((action) => ({
            ...action,
            ...{
              isChecked:
                permissionGroupBy[per.group]?.findIndex(
                  (item) => item.value === action.action
                ) > -1,
            },
          })),
        }));
        setUpdateUser({
          ...updateUser,
          permissionList: permissionList,
        });
      }

      if (isVisible) {
        const userPlansMapping = plans?.reduce((acc, arg) => {
          return acc.concat(
            arg?.plans?.map((plan) => {
              return {
                ...plan,
                packages: plan?.packages?.map((planPackage) => ({
                  ...planPackage,
                  prices: planPackage?.prices.map((price) => ({
                    ...price,
                    isSelected: _.isEmpty(plan.subscription)
                      ? false
                      : _.isEqual(
                          price.type,
                          plan.subscription?.items?.find(
                            (itemSubscription) =>
                              _.isEqual(
                                itemSubscription.item,
                                userDetail?.id
                              )
                          )?.type
                        ),
                  })),
                })),
              };
            })
          );
        }, []);
        if (!_.isEmpty(userPlansMapping)) {
          setLicenses(userPlansMapping);
        }
      }
    }
  }, [isVisible, type, stores, userDetail, selectRole]);

  const onUpdate = async () => {
    try {
      setUpdateUser({ ...updateUser, isLoading: true });
      const payload = {};
      switch (type) {
        case 'role_and_permissions':
          payload['key'] = 'roles';
          payload['value'] = Object.values(store)[0]
            ?.filter((fil) => fil.isChecked)
            .map((item) => ({
              merchant_id: selectedStore.merchant_id,
              role_id: selectRole,
              store_id: item.id,
              status: 'active',
              overrided_permissions: convertPermissionToStringify(),
            }));
          break;
        case 'store':
          payload['key'] = 'roles';
          payload['value'] = [
            ...initRoleStore?.map((item) => ({
              merchant_id: selectedStore.merchant_id,
              role_id: item.role_id,
              store_id: item.store_id,
              flag: 'delete',
              status: 'active',
            })),
            ...Object.values(store)[0]
              ?.filter((fil) => fil.isChecked)
              .map((item) => ({
                merchant_id: selectedStore.merchant_id,
                role_id: selectRole,
                store_id: item.id,
                status: 'active',
              })),
          ];
          break;
        case 'license':
          payload['value'] = licenses;
        default:
          break;
      }
      await onChange(payload);
      if (!isShowPaymentStripe) {
        enqueueSnackbar(
          `${convertUnderToTitleCase(type)} ${t('settings.updated')}`
        );
        handleCloseEditModal();
      }
    } catch (error) {
      const message = error?.message || error?.errors[0]?.message;
      console.log(error);
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setUpdateUser({ ...updateUser, isLoading: false });
    }
  };

  const convertPermissionToStringify = () => {
    const list = [];
    updateUser.permissionList?.map((per) => {
      per?.permissions.map((action) => {
        list.push({
          key: `${per.group}.${action.action}`,
          value: action.isChecked,
        });
      });
    });

    const result = JSON.stringify(
      list.reduce((acc, cur) => {
        return {
          ...acc,
          [`${cur.key}`]: cur.value ? 'D' : 'X',
        };
      }, {})
    );

    return result;
  };

  const handleSelectPaymentOption = (value, currentLicense) => {
    const list = [...licenses];
    const result = list?.reduce((acc, arg) => {
      if (_.isEqual(currentLicense.id, arg.id)) {
        return acc.concat({
          ...arg,
          packages: {
            ...arg?.packages,
            [0]: {
              ...arg?.packages[0],
              prices: currentLicense?.packages[0]?.prices?.map(
                (price) => ({
                  ...price,
                  isSelected:
                    _.isEqual(price.type, value) && price.isSelected
                      ? false
                      : _.isEqual(price.type, value),
                })
              ),
            },
          },
        });
      }
      return acc.concat(arg);
    }, []);
    setLicenses(result);
  };

  const Pricing = ({ license, price }) => {
    const isShowProRated =
      price?.gross_pro_rated > 0 && price?.next_payment_date;

    return (
      <Box display="flex" alignItems="center" paddingY={2}>
        <CustomRadio
          checked={price?.isSelected}
          onClick={(event) => {
            handleSelectPaymentOption(event.target.value, license);
          }}
          value={price?.type}
        />
        <Box>
          <Typography>{`${t('userManagement.users.pay')} ${
            license?.currency
          }${price?.gross_amount} ${price?.type}.`}</Typography>
          {isShowProRated && (
            <Typography>
              {t('subscriptions.pro_rated', {
                price: `${license?.currency}${price?.gross_pro_rated}`,
                date: moment(
                  new Date(price?.next_payment_date)
                ).format('DD/MM/YYYY'),
              })}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      className={classes.boxDialog}
      maxWidth={false}
      fullWidth
      fullScreen={isMobile}
      onClose={updateUser.isLoading ? () => {} : handleCloseEditModal}
      aria-labelledby={`{edit-add-new-user-dialog-title}`}
      open={isVisible}
      disableBackdropClick
    >
      <DialogTitle
        id={`{edit-add-new-user-dialog-title}`}
        onClose={handleCloseEditModal}
      >
        {t('userManagement.users.update_user_dialog_title')}
      </DialogTitle>
      <DialogContent>
        <>
          <Grid container style={{ marginTop: '-20px' }}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Tabs
                  className={classes.tabs}
                  value={index}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    disabled={index !== 0}
                    className={classes.tab}
                    style={{ fontSize: 18 }}
                    key={'role-and-permissions'}
                    label={t(
                      'userManagement.users.role_and_permissions'
                    )}
                  />
                  <Tab
                    disabled={index !== 1}
                    className={classes.tab}
                    style={{ fontSize: 18 }}
                    key={'stores'}
                    label={t('userManagement.users.stores')}
                  />
                  <Tab
                    disabled={index !== 2}
                    className={classes.tab}
                    style={{ fontSize: 18 }}
                    key={'licenses'}
                    label={t('userManagement.users.licenses')}
                  />
                </Tabs>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12}>
                {/* Role & Permissions Tab */}
                <TabPanel
                  key={'role-and-permissions-panel'}
                  value={index}
                  index={0}
                  className={classes.tabPanel}
                >
                  <div>
                    <div>
                      <Typography className={classes.title}>
                        {t('userManagement.users.role')}
                      </Typography>
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <RadioGroup
                            className={classes.boxRoleGroup}
                            aria-label="role-group"
                            name="role-group"
                            value={selectRole}
                            onChange={(value) =>
                              setSelectRole(value.target.value)
                            }
                          >
                            {!loading ? (
                              roles?.map((item, index) => (
                                <FormControlLabel
                                  style={{ marginRight: 40 }}
                                  key={`${item.name}-${index}`}
                                  control={
                                    <CustomRadio
                                      checked={selectRole === item.id}
                                      value={item.id}
                                      name="radio-button-role"
                                    />
                                  }
                                  label={item.name}
                                />
                              ))
                            ) : (
                              <Skeleton width="80%" />
                            )}
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </div>

                    {updateUser.permissionList?.length > 0 && (
                      <div>
                        <Typography className={classes.title}>
                          {t('userManagement.users.permissions')}
                        </Typography>

                        {updateUser.permissionList?.map(
                          (permission, groupIndex) => (
                            <div key={permission.group}>
                              <Typography
                                className={classes.subTitle}
                              >
                                {convertUnderToTitleCase(
                                  permission.name
                                )}
                              </Typography>
                              <div className={classes.boxSelectGroup}>
                                {permission.permissions?.map(
                                  (item, index) => (
                                    <div
                                      key={`${item.action}-${index}`}
                                    >
                                      <SingleCheckboxInput
                                        style={{
                                          marginRight: 40,
                                          paddingBottom: 4,
                                        }}
                                        isShowToast={false}
                                        name={item.action}
                                        label={capitalizeEachLetter(
                                          item.action
                                        )}
                                        value={item.isChecked}
                                        onChange={(value) => {
                                          const result = [
                                            ...updateUser.permissionList,
                                          ];
                                          result[
                                            groupIndex
                                          ].permissions[
                                            index
                                          ].isChecked = value;
                                          setUpdateUser({
                                            ...updateUser,
                                            permissionList: result,
                                          });
                                        }}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </TabPanel>

                {/* Stores Tab */}
                <TabPanel
                  key={'stores-panel'}
                  value={index}
                  index={1}
                  className={classes.tabPanel}
                >
                  {!loadingStore ? (
                    Object.keys(store)
                      ?.sort()
                      ?.map((key, index) => (
                        <div key={`${key}-${index}`}>
                          <SingleCheckboxInput
                            isShowToast={false}
                            value={
                              store[key]?.findIndex(
                                (item) => !item.isChecked
                              ) < 0
                            }
                            name={key}
                            label={capitalizeEachLetter(key)}
                            onChange={(value) => {
                              const storeValue = {
                                ...store,
                              };
                              const list = storeValue[key].map(
                                (item) => ({
                                  ...item,
                                  isChecked: value,
                                })
                              );
                              setUpdateUser({
                                ...updateUser,
                                store: {
                                  ...store,
                                  [key]: list,
                                },
                              });
                            }}
                          />
                          <div className={classes.boxSelectStore}>
                            {store[key]?.map((item, index) => (
                              <div
                                key={`${item.id}-${index}`}
                                style={{ marginRight: 40 }}
                              >
                                <SingleCheckboxInput
                                  isShowToast={false}
                                  name={item.name}
                                  label={item.name}
                                  value={item?.isChecked || false}
                                  onChange={(value) => {
                                    const result = {
                                      ...store,
                                    };
                                    result[key][index] = {
                                      ...result[key][index],
                                      ...{ isChecked: value },
                                    };
                                    setUpdateUser({
                                      ...updateUser,
                                      store: result,
                                    });
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      ))
                  ) : (
                    <Box
                      p={3}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress size={35} />
                    </Box>
                  )}
                </TabPanel>

                {/* Licenses Tab */}
                <TabPanel
                  key="licenses-panel"
                  value={index}
                  index={2}
                  className={classes.tabPanel}
                >
                  <>
                    {_.isEmpty(
                      licenses.find((license) => license.subscription)
                    ) && (
                      <Typography className={classes.newSubscription}>
                        {t('subscriptions.not_subscription_yet')}
                      </Typography>
                    )}
                    {licenses?.map((license, index) => (
                      <div
                        key={`${license.subscription_id}-${index}`}
                      >
                        <Typography className={classes.licenseTitle}>
                          {license.name}
                        </Typography>
                        {license.description && (
                          <Typography
                            className={classes.licenseDesription}
                          >
                            {license.description}
                          </Typography>
                        )}
                        <Typography
                          className={classes.selectPaymentOption}
                        >
                          {t('subscriptions.select_payment_options')}
                        </Typography>
                        <div key={license?.packages[0]?.id}>
                          {license?.packages[0]?.prices?.map(
                            (price, priceIndex) => (
                              <Pricing
                                key={`${price.type}-${priceIndex}`}
                                license={license}
                                price={price}
                              />
                            )
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseEditModal}>
          {t('settings.cancel')}
        </Button>
        <Button onClick={onUpdate} type="submit" color="primary">
          {t('settings.update')}
        </Button>
      </DialogActions>
      {updateUser.isLoading && <PreLoader size={30} />}
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

const CustomRadio = withStyles({
  root: {
    color: '#939DA8',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default UpdateUserAuthorize;
