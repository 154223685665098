import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  headerText: {
    ...CommonFonts.darkBoldFont18,
    textAlign: 'center'
  },
  closeButton: {
    padding: theme.spacing(1.5),
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
  dialogContent: {
  },
  rootDialog: {
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 5)
    },
  },
  cancelButton: {
    ...CommonFonts.grayFont16,
    letterSpacing: '0.11px',
    color: Colors.GRAY_BORDER3,
    opacity: 1,
  },
  tab: {
    '& .MuiTab-wrapper': {
      textTransform: 'none',
    },
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      borderBottomWidth: 2,
      borderBottomColor: '#E2E2E2',
      borderBottomStyle: 'solid',
    },
  },
  addButton: {
    padding: theme.spacing(2.5, 0),
    background: '#1174F2 0% 0% no-repeat padding-box',
    ...CommonFonts.whiteFont14,
    width: 150,
    textTransform: 'none'
  },
  totalContainer: {
    marginTop: theme.spacing(15),
    paddingRight: theme.spacing(15),
  },
  label: {
    ...CommonFonts.darkFont16
  },
  boldLabel: {
    ...CommonFonts.darkBoldFont16
  },
  subTitleText: {
    ...CommonFonts.darkFont14,
  },
  titleText: {
    ...CommonFonts.grayFont14,
    color: Colors.GRAY_BORDER1
  },
  selectorLabel: {
    ...CommonFonts.darkFont18,
    padding: theme.spacing(1, 0),
    color: Colors.GRAY_BORDER1
  },
  buttonsContainer: {
    padding: theme.spacing(0, 15),
  },
  buttonsInnerContainer: {
    padding: theme.spacing(6, 0),
    borderTopWidth: 1,
    borderTopColor: Colors.GRAY_BORDER1_25,
    borderTopStyle: 'solid'
  },
  productContainer: {
    marginTop: theme.spacing(10),
    paddingBottom: theme.spacing(6),
    borderBottom: `1px solid ${Colors.GRAY_BORDER1_40}`,
  },
  productDescriptionText: {
    ...CommonFonts.darkFont16,
  },
  removeButton: {
    padding: theme.spacing(1.5),
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
  optionsContainer: {
    marginTop: theme.spacing(7),
    paddingTop: theme.spacing(5),
    borderTop: `1px solid ${Colors.GRAY_BORDER1_40}`,
  },
  furtherNotice: {
    position: 'absolute',
    right: 0,
    bottom: 10,
    cursor: 'pointer'
  },
  furtherNoticeText: {
    ...CommonFonts.blueFont10
  }
}));

export default useStyles;
