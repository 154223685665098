import {
  Button,
  IconButton,
  TextField,
  Switch,
  Typography,
  withStyles,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PreLoader from '@/components/preLoader';
import { useFormik } from 'formik';
import { Colors, CommonFonts } from '@/theme';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';
import RangeTimePicker from '@/components/timePicker/RangeTimePicker';
import useStyles from './styles';

const SettingSwitch = ({
  name,
  label,
  description,
  value,
  onChange,
  onChangeOptions,
  optionsName,
  optionsLabel,
  optionsText,
  optionsValue,
  validationSchema = null,
  style,
  disableNotification,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const lowerCaseTitle = optionsLabel?.toLowerCase() || '';
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    setFieldError,
    touched,
    values,
    isValid,
  } = useFormik({
    initialValues: {
      [optionsName]: optionsValue,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await onChangeOptions(values[optionsName]);
        handleCloseEditModal();
        if (!disableNotification) {
          enqueueSnackbar(
            `${capitalizeEachLetter(label)} ${t('settings.updated')}`
          );
        }
      } catch (error) {
        setFieldError(optionsName, t('settings.error.update'));
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <div className={classes.boxSwitchPOS}>
      <div className={classes.switchHeader}>
        <Typography className={classes.titleText}>{label}</Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {value && optionsText && (
            <div className={classes.boxOptions}>
              <Typography className={classes.optionsSwitchPOS}>
                {optionsText[0]} - {optionsText[1]}
              </Typography>
              <Typography
                onClick={handleOpenEditModal}
                className={classes.addAction}
              >
                {t('settings.update')}
              </Typography>
            </div>
          )}
          <CustomSwitch
            style={style}
            checked={!!value}
            onChange={async (event) => {
              const value = event.target.checked;
              await onChange?.(value);
              if (value && onChangeOptions) {
                handleOpenEditModal();
              }
              if (!disableNotification) {
                enqueueSnackbar(
                  `${capitalizeEachLetter(label)} ${t(
                    'settings.updated'
                  )}`
                );
              }
            }}
            name={name}
          />
        </div>
      </div>
      {description && (
        <Typography
          style={{ width: '90%' }}
          className={classes.descriptionText}
        >
          {description}
        </Typography>
      )}
      <RangeTimePicker
        name={name}
        label={label}
        value={optionsValue}
        onChange={(value) => onChangeOptions(value)}
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        disableNotification={disableNotification}
      />
    </div>
  );
};

const CustomSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: '#55CC66',
    },
    '&$checked + $track': {
      backgroundColor: '#55CC66',
      opacity: 0.4,
    },
  },
  checked: {},
  track: {},
})(Switch);

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default SettingSwitch;
