import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: 450,
      width: '100%',
      padding: theme.spacing(10),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
    },
    button: {
      textTransform: 'capitalize',
      ...CommonFonts.darkFont18,
      padding: theme.spacing(4, 3),
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    whiteButton: {
      ...CommonFonts.darkFont18,
      justifyContent: 'flex-start',
      width: 400,
      fontWeight: 300,
      backgroundColor: Colors.WHITE,
      bottom: 25,
    },
    greyButton: {
      ...CommonFonts.darkFont16,
      marginTop: theme.spacing(3),
      backgroundColor: Colors.GRAY_LIGHT,
      height: 50,
      width: 350,
    },
    name: {
      letterSpacing: 0.16,
      padding: theme.spacing(4, 0, 1, 0),
      ...CommonFonts.darkFont18,
    },
    email: {
      fontWeight: 300,
      letterSpacing: 0.56,
      paddingBottom: theme.spacing(12),
      ...CommonFonts.darkFont18,
    },
    link: {
      textDecoration: 'none',
    },
    cameraIcon: {
      position: 'absolute',
      backgroundColor: Colors.GRAY_LIGHT,
      top: theme.spacing(30),
      right: theme.spacing(34),
      '&:hover': {
        backgroundColor: '#d2d2d2',
      },
    },
    uploadInput: {
      display: 'none',
    },
  })
);

export default useStyles;
