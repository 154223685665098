import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    boxSidebar: {
      height: '100%',
      backgroundColor: Colors.WHITE,
      borderRadius: theme.spacing(1),
    },
    boxLink: {
      padding: theme.spacing(4),
    },
    boxLinkActive: {
      padding: theme.spacing(4),
      backgroundColor: '#e7f1fe',
      fontWeight: '600',
    },
    breadcrumbsText: {
      fontSize: 16,
    },
    linkText: {
      ...CommonFonts.darkFont18,
    },
  })
);

export default useStyles;
