import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Popover, Table, TableBody } from '@material-ui/core';
import { isEmpty, filter } from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import Calendar from 'react-calendar';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { GET_STORE_OPENING } from '@/services/storeService';

import TableHeader from './tableHeader';
import TableRow from './tableRow';
import useStyles from './styles';

const TableComponent = ({ payloadData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const anchor = useRef(null);

  const [openCalendar, setOpenCalendar] = useState(false);

  const { data, loading, refetch } = useQuery(GET_STORE_OPENING, {
    onCompleted: (data) => {
      setDefaultData(data.store.store_openings.map(opening => ({
        ...opening,
        opening_times: opening.opening_times.map(time => ({
          open: time.open,
          close: time.close
        }))
      })));
    },
    fetchPolicy: 'no-cache'
  });

  const [defaultData, setDefaultData] = useState([]);

  const updateData = (values, index, removeDateItem) => {
    let dataSet = defaultData;
    let removedData = {};
    if (!isEmpty(removeDateItem) && values.date === removeDateItem.date) {
      removedData = Object.assign(removeDateItem, { 'flag': 'delete' });
      const newData = filter(defaultData, function(o, key) {
        return key !== index;
      });
      dataSet[index] = removedData;
      setDefaultData(newData);
    } else {
      dataSet[index] = values;
    }
    payloadData(dataSet);
  };

  const addHolidays = (date) => {
    setDefaultData([...defaultData, ...[{
        date: moment(date).format('YYYY-MM-DD'),
        closed: true,
        opening_times: []
      }]]
    );
  };

  return (
    <Table>
      {loading ? (
        <div>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </div>
      ) : (
        <>
          <TableHeader />
          <TableBody>
            {defaultData?.map((item, index) => {
              return (<>
                <TableRow
                  key={index + 1}
                  item={item}
                  index={index}
                  changedData={(values, index, removeDateItem) => updateData(values, index, removeDateItem)}
                />
                {
                  index === 6 && <Divider className={classes.divider} />
                }
              </>);
            })}

          </TableBody>
          <Button
            color="primary"
            className={classes.holidayButton}
            onClick={() => setOpenCalendar(true)}
          >
            {t('workboard.manage_store.opening_hours_dialog.add_holiday')}
          </Button>
          {openCalendar &&
          <Popover
            id='date-popover'
            open={openCalendar}
            anchorEl={anchor.current}
            onClose={() => setOpenCalendar(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <Calendar
              onChange={(date) => {
                setOpenCalendar(false);
                addHolidays(date);
              }}
            />
          </Popover>
          }
        </>
      )}
    </Table>
  );
};

export default TableComponent;
