import React from 'react';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash'

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Link } from '@material-ui/core'

import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { selectMerchantSettings } from '@/store/modules/store/selectors'

import { getAllBusinessManagerServices } from '../helper'
import useStyles from './styles';

export default function SimpleSelect() {
  const classes = useStyles();

  const { t } = useTranslation();

  const location = useLocation();
  const urlName = location.pathname;
  const urlSplits = urlName.split('/');

  const merchantSettings = useSelector(selectMerchantSettings)

  const merchantServices = merchantSettings?.products || []

  const allServices = getAllBusinessManagerServices(merchantServices)
  const servicesToShow = []

  allServices.forEach(service => {
    if (!isEmpty(service.subMenu)) {
      servicesToShow.push({
        ...service,
        link: service.subMenu[0].path
      })
      service.subMenu.forEach(subMenu => {
        servicesToShow.push({
          isChild: true,
          id: subMenu.name,
          isActive: subMenu.isActive,
          link: subMenu.path
        })
      })
    } else {
      servicesToShow.push(service)
    }
  })

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          value={urlSplits[urlSplits.length - 1]}
          disableUnderline
          IconComponent={ExpandMoreIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            classes: { paper: classes.select },
          }}
          classes={{
            outlined: classes.outlined,
            root: classes.root,
            select: classes.inputFocused,
            icon: classes.icon,
          }}
          inputProps={{
            className: classes.inputColor,
          }}
        >
          {servicesToShow.map((service) => (
            <MenuItem
              key={service.id}
              value={service.id}
              style={{
                minWidth: 250,
                paddingLeft: !!service.isChild ? 50 : 20
              }}
              disabled={!service.isActive}
            >
              <Link
                component={RouterLink}
                to={service.link}
                underline='none'
                className={classes.link}
              >
                {t(`menu.${service.id}`)}
              </Link>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
