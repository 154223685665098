import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import classNames from 'classnames';
import useStyles from './styles'

const LayoutSelect = (props) => {
  const classes = useStyles();

  const {
    isSelected,
    layout,
    icon,
    onClickLayout
  } = props;

  const ICON = icon;

  return (
    <Box
      display='flex'
      justifyContent='center'
      className={classNames(classes.root, {
        [classes.selectedRoot]: isSelected
      })}
      onClick={() => onClickLayout(layout)}
    >
      <ICON
        color={isSelected ? 'primary' : 'disabled'}
        fontSize='large'
      />
    </Box>
  );
}

LayoutSelect.propTypes = {
  isSelected: PropTypes.bool,
  layout: PropTypes.string,
  icon: PropTypes.object,
  onClickLayout: PropTypes.func,
}

export default LayoutSelect;

