import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT
    },
    greyButton: {
      minWidth: 150,
      width: 'auto',
      height: 50,
      backgroundColor: Colors.GRAY_BORDER1_25,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
      textTransform: 'none',
      ...CommonFonts.darkFont18,
      color: '#20272FDE',
      fontWeight: 'normal',
      borderRadius: 0
    },
    greyFocusedButton: {
      backgroundColor: Colors.GRAY_BORDER3,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER3,
      },
      fontWeight: 500,
    },
    dateFiltersContainer: {
      marginTop: theme.spacing(5),
    },
    dateFiltersWrapper: {
      flexWrap: 'wrap',
      alignItems: 'center'
    },
    closeButton: {
      width: 50,
      height: 50,
      backgroundColor: Colors.BLUE,
      '&:hover': {
        backgroundColor: Colors.BLUE,
      },
      marginLeft: 50
    },
    closeIcon: {
      color: Colors.WHITE
    },
    widgetsContainer: {
      margin: theme.spacing(10, 0)
    },
    widgetContainer: {
      width: '100%',
      marginTop: theme.spacing(5),
      padding: theme.spacing(0, 2),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    droppableRemoveContainer: {
      position: 'fixed',
      width: '100%',
      height: 130,
      left: 0,
      bottom: 0,
      zIndex: -1,
      background: 'transparent;'
    },
    droppableRemoveEnabledContainer: {
      zIndex: 1,
      background: 'transparent linear-gradient(180deg, #FFF4F4 0%, #F63333 100%) 0% 0% no-repeat padding-box;'
    },
    removeWidgetText: {
      ...CommonFonts.darkBoldFont24
    },
    removeWidgetFocusedText: {
      color: Colors.RED
    }
  })
)

export default useStyles
