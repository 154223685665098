import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { Grid } from '@material-ui/core';
import AppLayout from '@/components/appLayout';

import StoresLayout from './storesLayout';

import images from '@/assets/images';
import useStyles from './styles';
import { useHistory } from 'react-router';
import MerchantActions from '@/store/modules/store/actions'; // MerchantTypes,

const StoreSelectPage = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { merchants } = useSelector((state) => state.auth.user);

  useEffect(() => {
    //redirect to add store if not merchants
    if (merchants?.length === 0) {
      history.push('/store/add-store');
    }
    //check if the user has only one merchant and one store
    if (merchants?.length === 1) {
      if (merchants[0].stores.length === 1) {
        //set the currentMerchant and currentStore
        dispatch(MerchantActions.setCurrentMerchant(merchants[0]));
        dispatch(MerchantActions.setSelectedStore(merchants[0].stores[0]));
        history.push('/workboard');
      }
    }
  }, [merchants]);

  return (
    <AppLayout className={classes.appLayout} withFooter>
      <Grid container justify="center" className={classes.logo}>
        <img src={images.logo} width={125} height={50} alt="logo" />
      </Grid>
      <Grid
        container
        justify="center"
        className={classes.selectStoreText}
      >
        {t('select_store_page.select_store_or_business')}
      </Grid>
      <Grid
        container
        justify="center"
        className={classes.storesLayout}
      >
        <StoresLayout />
      </Grid>
    </AppLayout>
  );
};

export default StoreSelectPage;
