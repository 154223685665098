import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isEmpty } from 'lodash';

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import Montserrat from '../fonts/Montserrat-Light.ttf';
import MontserratBold from '../fonts/Montserrat-Medium.ttf';

import { transformFloat } from '@/utils/localisationUtil';
import { getTaxName } from '@/utils/taxUtil';

Font.register({
  family: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fonts: [
    {
      src: Montserrat,
    },
    {
      src: MontserratBold,
      fontWeight: 'bold',
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  root: {
    padding: 15,
  },
  titleText: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: 'black',
  },
  storeNameText: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Montserrat',
    color: 'black',
  },
  accountTypeContainer: {
    marginTop: 20,
  },
  childrenTypeContainer: {
    marginTop: 15,
  },
  accountWrapper: {
    marginTop: 10,
    paddingLeft: 15,
    paddingRight: 140,
  },
  rowBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  absoluteContainer: {
    position: 'absolute',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

const PDFDocument = (props) => {
  const { t } = useTranslation();

  const {
    store,
    reportTime,
    insights,
    incomesAccountType,
    paymentsAccountType,
    paymentTypeSales,
    salesChildrenTypes,
    categories,
    totalTaxAmount,
    taxesByName,
    taxesById,
    totalExpenseTaxAmount,
    expenseTaxesByName,
    expenseTaxesById,
    localisation,
  } = props;

  const getNetSale = (item) => {
    return item.total_sale.net + item.total_sale.promotion;
  };

  const getGrossSale = (item) => {
    return (
      item.total_sale.net +
      item.total_sale.promotion +
      item.total_sale.tax +
      item.total_sale.promotion_tax
    );
  };

  const hasAccounts = (childrenType) => {
    return !isEmpty(
      (childrenType.accounts || []).filter(
        (account) => account.display_in_report
      )
    );
  };

  return (
    <Document>
      <Page size="A4">
        <View style={styles.root}>
          <View style={styles.rowCenter}>
            <Text style={styles.titleText}>
              {t('workboard.run_reports.business_snapshot')}
            </Text>
          </View>
          <View style={{ marginTop: 15, ...styles.rowCenter }}>
            <Text style={styles.storeNameText}>{store.name}</Text>
          </View>
          <View style={{ marginTop: 15, ...styles.rowCenter }}>
            <Text style={styles.storeNameText}>
              {moment(reportTime.start).format(
                'DD/MM/YYYY HH:mm:ss'
              ) +
                ' - ' +
                moment(reportTime.end).format('DD/MM/YYYY HH:mm:ss')}
            </Text>
          </View>

          <View style={styles.accountTypeContainer}>
            <View style={styles.rowBetween}>
              <Text style={styles.titleText}>
                {t('workboard.run_reports.transactions')}
              </Text>
              <Text style={styles.titleText}>
                {insights?.performances?.no_of_transactions}
              </Text>
            </View>
            <View style={styles.childrenTypeContainer}>
              <View
                style={{ ...styles.rowBetween, paddingRight: 70 }}
              >
                <Text style={styles.storeNameText}>
                  {t('workboard.run_reports.no_of_orders')}
                </Text>
                <Text style={styles.storeNameText}>
                  {insights?.performances?.no_of_transactions}
                </Text>
              </View>
            </View>
            <View style={styles.childrenTypeContainer}>
              <View
                style={{ ...styles.rowBetween, paddingRight: 70 }}
              >
                <Text style={styles.storeNameText}>
                  {t('workboard.run_reports.atv')}
                </Text>
                <Text style={styles.storeNameText}>
                  {transformFloat(
                    insights?.performances
                      ?.average_transaction_value || 0,
                    localisation
                  )}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.accountTypeContainer}>
            <View style={styles.rowBetween}>
              <Text style={styles.titleText}>
                {t('workboard.run_reports.sales_gross')}
              </Text>
              <Text style={styles.titleText}>
                {transformFloat(
                  paymentTypeSales?.total || 0,
                  localisation
                )}
              </Text>
            </View>
            {paymentTypeSales?.payment_types?.map(
              (paymentType, id1) => (
                <View
                  key={`children-type-${id1}`}
                  style={styles.childrenTypeContainer}
                >
                  <View
                    style={{ ...styles.rowBetween, paddingRight: 70 }}
                  >
                    <Text style={styles.storeNameText}>
                      {paymentType.name}
                    </Text>
                    <Text style={styles.storeNameText}>
                      {transformFloat(
                        paymentType.amount,
                        localisation
                      )}
                    </Text>
                  </View>
                </View>
              )
            )}
          </View>

          <View style={styles.accountTypeContainer}>
            <View style={styles.rowBetween}>
              <Text style={styles.titleText}>
                {t('workboard.run_reports.expenses_gross')}
              </Text>
              <Text style={styles.titleText}>
                {transformFloat(
                  paymentsAccountType?.performance?.performance || 0,
                  localisation
                )}
              </Text>
            </View>
            {paymentsAccountType?.children_types
              ?.filter((childrenType) => hasAccounts(childrenType))
              ?.map((childrenType, id1) => (
                <View
                  key={`children-type-${id1}`}
                  style={styles.childrenTypeContainer}
                >
                  <View
                    style={{ ...styles.rowBetween, paddingRight: 70 }}
                  >
                    <Text style={styles.storeNameText}>
                      {childrenType.name}
                    </Text>
                    <Text style={styles.storeNameText}>
                      {transformFloat(
                        childrenType.performance.performance,
                        localisation
                      )}
                    </Text>
                  </View>
                  {childrenType.accounts
                    .filter((account) => account.display_in_report)
                    .map((account, id2) => (
                      <View
                        key={`account-${id1}-${id2}`}
                        style={{
                          ...styles.rowBetween,
                          ...styles.accountWrapper,
                        }}
                      >
                        <Text style={styles.storeNameText}>
                          {account.name}
                        </Text>
                        <Text style={styles.storeNameText}>
                          {transformFloat(
                            account.performance.performance,
                            localisation
                          )}
                        </Text>
                      </View>
                    ))}
                </View>
              ))}
          </View>

          <View style={styles.accountTypeContainer}>
            <View style={styles.rowBetween}>
              <Text style={styles.titleText}>
                {t('workboard.run_reports.tax_summary')}
              </Text>
              <Text style={styles.titleText}>
                {transformFloat(
                  totalTaxAmount - totalExpenseTaxAmount,
                  localisation
                )}
              </Text>
            </View>
            {!isEmpty(taxesByName) && (
              <View style={{ ...styles.rowBetween, marginTop: 25 }}>
                <View
                  style={{
                    ...styles.absoluteContainer,
                    paddingRight: 210,
                  }}
                >
                  <Text style={styles.titleText}>
                    {t('workboard.run_reports.net')}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.absoluteContainer,
                    paddingRight: 140,
                  }}
                >
                  <Text style={styles.titleText}>
                    {t('workboard.run_reports.tax')}
                  </Text>
                </View>
              </View>
            )}
            {taxesByName.map((group) => (
              <>
                <View
                  style={{
                    ...styles.rowBetween,
                    marginTop: 30,
                    position: 'relative',
                  }}
                >
                  <View
                    style={{
                      ...styles.absoluteContainer,
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Text style={styles.storeNameText}>
                      {t('workboard.run_reports.tax_on_sales', {
                        name: group.tax_name,
                      })}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.absoluteContainer,
                      paddingRight: 210,
                    }}
                  >
                    <Text style={styles.storeNameText}>
                      {transformFloat(group.tax.sold, localisation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.absoluteContainer,
                      paddingRight: 140,
                    }}
                  >
                    <Text style={styles.storeNameText}>
                      {transformFloat(group.tax.amount, localisation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.absoluteContainer,
                      paddingRight: 70,
                    }}
                  >
                    <Text style={styles.storeNameText}>
                      {transformFloat(group.tax.amount, localisation)}
                    </Text>
                  </View>
                </View>
                {taxesById
                  .filter(
                    (tax) =>
                      tax.tax_name === group.tax_name &&
                      tax.tax_rate != null
                  )
                  .sort((a, b) => b.tax_rate - a.tax_rate)
                  .map((tax) => (
                    <View
                      style={{
                        ...styles.rowBetween,
                        marginTop: 30,
                        position: 'relative',
                      }}
                    >
                      <View
                        style={{
                          ...styles.absoluteContainer,
                          justifyContent: 'flex-start',
                          paddingLeft: 15,
                        }}
                      >
                        <Text style={styles.storeNameText}>
                          {getTaxName({
                            name: tax.tax_name,
                            rate: tax.tax_rate,
                          })}
                        </Text>
                      </View>
                      <View
                        style={{
                          ...styles.absoluteContainer,
                          paddingRight: 210,
                        }}
                      >
                        <Text style={styles.storeNameText}>
                          {transformFloat(tax.tax.sold, localisation)}
                        </Text>
                      </View>
                      <View
                        style={{
                          ...styles.absoluteContainer,
                          paddingRight: 140,
                        }}
                      >
                        <Text style={styles.storeNameText}>
                          {transformFloat(
                            tax.tax.amount,
                            localisation
                          )}
                        </Text>
                      </View>
                    </View>
                  ))}
              </>
            ))}
            {expenseTaxesByName.map((group) => (
              <>
                <View
                  style={{
                    ...styles.rowBetween,
                    marginTop: 30,
                    position: 'relative',
                  }}
                >
                  <View
                    style={{
                      ...styles.absoluteContainer,
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Text style={styles.storeNameText}>
                      {t('workboard.run_reports.tax_on_expenses', {
                        name: group.tax_name,
                      })}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.absoluteContainer,
                      paddingRight: 210,
                    }}
                  >
                    <Text style={styles.storeNameText}>
                      {transformFloat(group.tax.sold, localisation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.absoluteContainer,
                      paddingRight: 140,
                    }}
                  >
                    <Text style={styles.storeNameText}>
                      {transformFloat(group.tax.amount, localisation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.absoluteContainer,
                      paddingRight: 70,
                    }}
                  >
                    <Text style={styles.storeNameText}>
                      {transformFloat(group.tax.amount, localisation)}
                    </Text>
                  </View>
                </View>
                {expenseTaxesById
                  .filter(
                    (tax) =>
                      tax.tax_name === group.tax_name &&
                      tax.tax_rate != null
                  )
                  .sort((a, b) => b.tax_rate - a.tax_rate)
                  .map((tax) => (
                    <View
                      style={{
                        ...styles.rowBetween,
                        marginTop: 30,
                        position: 'relative',
                      }}
                    >
                      <View
                        style={{
                          ...styles.absoluteContainer,
                          justifyContent: 'flex-start',
                          paddingLeft: 15,
                        }}
                      >
                        <Text style={styles.storeNameText}>
                          {getTaxName({
                            name: tax.tax_name,
                            rate: tax.tax_rate,
                          })}
                        </Text>
                      </View>
                      <View
                        style={{
                          ...styles.absoluteContainer,
                          paddingRight: 210,
                        }}
                      >
                        <Text style={styles.storeNameText}>
                          {transformFloat(tax.tax.sold, localisation)}
                        </Text>
                      </View>
                      <View
                        style={{
                          ...styles.absoluteContainer,
                          paddingRight: 140,
                        }}
                      >
                        <Text style={styles.storeNameText}>
                          {transformFloat(
                            tax.tax.amount,
                            localisation
                          )}
                        </Text>
                      </View>
                    </View>
                  ))}
              </>
            ))}
          </View>

          <View style={{ marginTop: 38 }}>
            <View style={styles.rowBetween}>
              <Text style={styles.titleText}>
                {t('workboard.run_reports.product_summary')}
              </Text>
              <Text style={styles.titleText}>
                {transformFloat(
                  insights?.performances?.total_value || 0,
                  localisation
                )}
              </Text>
            </View>
            {!isEmpty(categories) && (
              <View style={{ ...styles.rowBetween, marginTop: 25 }}>
                <View
                  style={{
                    ...styles.absoluteContainer,
                    paddingRight: 210,
                  }}
                >
                  <Text style={styles.titleText}>
                    {t('workboard.run_reports.no_of_items')}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.absoluteContainer,
                    paddingRight: 140,
                  }}
                >
                  <Text style={styles.titleText}>
                    {t('workboard.run_reports.net')}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.absoluteContainer,
                    paddingRight: 70,
                  }}
                >
                  <Text style={styles.titleText}>
                    {t('workboard.run_reports.gross')}
                  </Text>
                </View>
              </View>
            )}
            {categories.map((category) => (
              <View
                key={category.name}
                style={{
                  ...styles.rowBetween,
                  marginTop: 30,
                  position: 'relative',
                }}
              >
                <View
                  style={{
                    ...styles.absoluteContainer,
                    justifyContent: 'flex-start',
                  }}
                >
                  <Text style={styles.storeNameText}>
                    {category.name}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.absoluteContainer,
                    paddingRight: 210,
                  }}
                >
                  <Text style={styles.storeNameText}>
                    {category.item_sold}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.absoluteContainer,
                    paddingRight: 140,
                  }}
                >
                  <Text style={styles.storeNameText}>
                    {transformFloat(
                      getNetSale(category),
                      localisation
                    )}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.absoluteContainer,
                    paddingRight: 70,
                  }}
                >
                  <Text style={styles.storeNameText}>
                    {transformFloat(
                      getGrossSale(category),
                      localisation
                    )}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
