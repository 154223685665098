import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../../constants/colors'
import { CommonFonts } from '../../../constants/fonts'

const useStyles = makeStyles(theme =>
  createStyles({
    verificationText: {
      ...CommonFonts.darkFont24,
      textAlign: 'center',
      marginTop: 90
    },
    checkEmailText: {
      ...CommonFonts.darkFont18,
      marginTop: 70,
      textAlign: 'center',
      padding: '0px 60px',
      [theme.breakpoints.down('xs')]: {
        padding: 0
      },
    },
    buttonWrapper: {
      margin: '60px 0px'
    },
    loginButton: {
      height: 54,
      maxWidth: 262,
      textTransform: 'none',
      fontSize: 14,
    },
    blueText: {
      letterSpacing: 0.14,
      ...CommonFonts.blueFont18,
      cursor: 'pointer'
    },
    changeWrapper: {
      margin: '40px 0 60px 0'
    }
  })
)

export default useStyles
