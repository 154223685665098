import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { IconButton } from '@material-ui/core';
import useStyles from './styles';

const ActionMenu = ({ data, actionMenus }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon
          aria-controls={`edit-menu-${data.id}`}
          aria-haspopup="true"
          id={`edit-menu-${data.id}`}
        />
      </IconButton>
      <Menu
        id="card-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
      >
        {actionMenus.map((actionMenu, index) => (
          <MenuItem
            key={`action-menu-${data?.id}-${index}`}
            className={classes.menuItem}
            style={{ color: actionMenu.color }}
            onClick={() => {
              actionMenu.onClick(data);
              handleClose();
            }}
          >
            {actionMenu.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default ActionMenu;
