/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box } from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Grid from '@material-ui/core/Grid';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';

import { find, takeRight } from 'lodash'

import useStyles from './styles';

export default function TaxesAutocomplete({
  taxes,
  taxName,
  selectedTax,
  onChangeTax,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [step, setstep] = useState(0);

  // render the text for Taxes column
  const renderTax = (tax) => {
    let fullText;

    if (tax.rate < 0) {
      fullText = 'VAT Exempt';
    } else {
      fullText = `${tax.rate}% ${tax.name} `;
    }
    return fullText;
  };

  const sortTaxes = (taxes) => {
    taxes.sort((a, b) => a.rate - b.rate)
    if (find(taxes, { rate: -1 })) {
      return [...takeRight(taxes, taxes.length - 1), taxes[0]]
    }
    return taxes
  }

  return (
    <div style={{ width: '100%' }}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12}>
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            style={{ height: 60 }}
          >
            <Grid item xs={12}>
              <span className={classes.generalInfoItem}>
                {taxName}
              </span>
            </Grid>

            {step === 0 && (
              <Grid
                item
                xs={12}
                style={{ position: 'relative' }}
                onClick={() => !selectedTax
                  ? setstep(1)
                  : {}
                }
              >
                <Typography
                  className={
                    classNames(classes.label, {
                      [classes.emptyLabel]: !selectedTax
                    })
                  }
                  onClick={() => setstep(1)}
                >
                  {!selectedTax ? t('categories.select') : renderTax(selectedTax)}

                  {selectedTax &&
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => setstep(1)}
                    >
                      <CreateIcon fontSize='small' />
                    </IconButton>
                  }
                </Typography>
              </Grid>
            )}

            {step === 1 && (
              <Grid item xs={12} style={{ marginTop: '-10px' }}>
                <Autocomplete
                  id="free-solo-2-demo"
                  disableClearable
                  options={sortTaxes(taxes)}
                  openOnFocus
                  blurOnSelect
                  onChange={(event, value) => {
                    onChangeTax(value)
                  }}
                  onBlur={() => setstep(0)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      margin="normal"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                  getOptionLabel={(option) => renderTax(option)}
                  renderOption={(option, { inputValue }) => {
                    const matches = match(option.name, inputValue);
                    const parts = parse(option.name, matches);

                    return (
                      <Box paddingX={2} paddingY={3}>
                        {renderTax(option)}
                      </Box>
                    );
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
