import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '@/pages/notFoundPage';
import UserManagementPage from '@/pages/userManagement';

const Routes = () => (
  <Switch>
    <Route path="/user-management" component={UserManagementPage} />
    {/* Show Not Found page when url does not match */}
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
