import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Popover,
  List,
  ListItem
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { PERFORMANCE } from '../helper'
import useStyles from './styles';

const PerformanceSelect = (props) => {
  const classes = useStyles();

  const {
    selectedPerformance,
    disabledPerformance,
    onChangePerformance,
    performanceTotal,
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Box
        display='flex'
        className={classes.performanceContainer}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <Typography className={classes.performanceText}>{selectedPerformance}</Typography>
        {open
          ?
          <ExpandLessIcon className={classes.icon} />
          :
          <ExpandMoreIcon className={classes.icon} />
        }
      </Box>
      <Typography className={classes.totalText}>
        {performanceTotal}
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {Object.keys(PERFORMANCE).map((key) => (
            <ListItem
              key={key}
              button
              onClick={() => {
                onChangePerformance(PERFORMANCE[key])
                setAnchorEl(null)
              }}
              disabled={PERFORMANCE[key] === disabledPerformance}
            >
              <Typography className={classes.performanceText}>
                {PERFORMANCE[key]}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  )
}

PerformanceSelect.propTypes = {
  selectedPerformance: PropTypes.string,
  disabledPerformance: PropTypes.string,
  onChangePerformance: PropTypes.func,
  performanceTotal: PropTypes.any,
}

export default PerformanceSelect