import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    performanceContainer: {
      cursor: 'pointer'
    },
    performanceText: {
      ...CommonFonts.darkFont16
    },
    icon: {
      fill: Colors.GRAY_DARK,
      marginLeft: theme.spacing(2)
    },
    totalText: {
      ...CommonFonts.darkBoldFont18
    },
  })
);

export default useStyles;
