import React, { useState, useCallback, memo } from 'react';
import { connect } from 'react-redux';

import PreLoader from '@/components/preLoader';
import EmailStep from './emailStep';
import PasswordStep from './passwordStep';
import { useMutation } from '@apollo/client';
import { SEND_RESET_CODE } from '@/services/authService';

import AuthActions, { AuthTypes } from '@/store/modules/auth/actions';
import { selectIsApiProgress } from '@/store/modules/apiLoopState';

import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import FogotPassword from './forgotPassword';
import ResetPasswordConfirmation from './forgotPassword/ResetPasswordConfirmation';

import Errors from '@/constants/errors'

const LOGIN_STEP = {
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  FORGOT_PASSWORD_EMAIL: 'FORGOT_PASSWORD_EMAIL',
  FORGOT_PASSWORD_CONFIRMATION: 'FORGOT_PASSWORD_CONFIRMATION',
};
const LoginLayout = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    onChangeLoginLayout,
    isCheckingUser,
    checkUser,
    isLoggingIn,
    login,
  } = props;

  const [currentStep, setCurrentStep] = useState(LOGIN_STEP.EMAIL);

  const [initialEmail, setInitialEmail] = useState('');
  const [checkUserError, setCheckUserError] = useState('');
  const [loginError, setLoginError] = useState('');

  const [resetPassword, { data }] = useMutation(SEND_RESET_CODE);

  const onNext = useCallback((values) => {
    setCheckUserError('');
    checkUser({
      email: values.email,
      onSuccess: () => {
        setInitialEmail(values.email);
        setCurrentStep(LOGIN_STEP.PASSWORD);
      },
      onFailed: (error) => {
        if (error === Errors.NO_USER_FOUND) {
          setCheckUserError(t('error_group.error_email'));
        } else {
          setCheckUserError(error);
        }
      },
    });
  }, []);

  const onNextForgotPassword = useCallback((values) => {
    setCheckUserError('');
    checkUser({
      email: values.email,
      onSuccess: () => {
        setInitialEmail(values.email);
        resetPassword({
          variables: { input: { email: values.email } },
        });
        setCurrentStep(LOGIN_STEP.FORGOT_PASSWORD_CONFIRMATION);
      },
      onFailed: (error) => {
        if (error === Errors.NO_USER_FOUND) {
          setCheckUserError(t('error_group.error_email'));
        } else {
          setCheckUserError(error);
        }
      },
    });
  }, []);

  const onLogin = useCallback(
    (values) => {
      setLoginError('');
      login({
        username: initialEmail,
        password: values.password,
        onFailed: (error) => {
          if (error === Errors.USERNAME_PASSWORD_INCORRECT) {
            setLoginError(t('error_group.error_password'));
          } else {
            setLoginError(error);
          }
        },
      });
    },
    [initialEmail]
  );

  return (
    <div className={classes.root}>
      {currentStep === LOGIN_STEP.EMAIL && (
        <EmailStep
          onChangeLoginLayout={onChangeLoginLayout}
          initialEmail={initialEmail}
          checkUserError={checkUserError}
          onNext={onNext}
        />
      )}
      {currentStep === LOGIN_STEP.PASSWORD && (
        <PasswordStep
          initialEmail={initialEmail}
          loginError={loginError}
          onLogin={onLogin}
          onBack={() => setCurrentStep(LOGIN_STEP.EMAIL)}
          setCurrentStep={(value) => setCurrentStep(value)}
        />
      )}

      {currentStep === LOGIN_STEP.FORGOT_PASSWORD_EMAIL && (
        <FogotPassword
          onChangeLoginLayout={onChangeLoginLayout}
          initialEmail={initialEmail}
          checkUserError={checkUserError}
          onNext={onNextForgotPassword}
          setCurrentStep={(value) => setCurrentStep(value)}
        />
      )}
      {currentStep === LOGIN_STEP.FORGOT_PASSWORD_CONFIRMATION && (
        <ResetPasswordConfirmation
          initialEmail={initialEmail}
          setCurrentStep={(value) => setCurrentStep(value)}
        />
      )}

      {(isCheckingUser || isLoggingIn) && <PreLoader size={59} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isCheckingUser: selectIsApiProgress(state, AuthTypes.CHECK_USER),
  isLoggingIn: selectIsApiProgress(state, AuthTypes.LOGIN),
});

const actions = { ...AuthActions };

export default memo(connect(mapStateToProps, actions)(LoginLayout));
