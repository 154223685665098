import { gql } from '@apollo/client';

export const GET_EXPENSES = gql`
  query getExpenses($start: FullDate, $end: FullDate, $filter: Filter) {
    expenses(start: $start, end: $end, filter: $filter) {
      id
      bill_date
      due_date
      expense_type{
        id
        name
        type
        workflow{
          id
          name
          stages{
            id
            name
            order
            actions{
              id
              name
              type
            }
            triggers{
              id
              name
              type
            }
          }
        }
      }
      items{
        item_id
        account_id
        account{
          id
          name
          description
          starting_balance
          archived
          display_in_report
          account_type{
            id
            name
          }
        }
        amount
        bar_code
        creation{
          time
          user_id
        }
        inventory_id
        order
        product_code
        product_name
        quantity
        taxes{
          id
          name
          rate
          amount
        }
      }
      payee{
        id
        name
        type 
      }
      creation{
        time
      }
      order_no
      status{
        id
        name
        order
      }
      state
      statuses{
        creation{
          time
          user_id
        }
        id
        name
        order
      }
      payments{
        amount
        status
      }
      attachments {
        id
        url
        name
        content_type
      }
      total{
        amount
        taxes{
          id
          name
          rate
          amount
        }
      }
    }
  }
`;

export const GET_SUPPLIERS = gql`
  query getSuppliers {
    suppliers{
      id
      lname
      logo{
        id
        url
        thumbnail
      }
      address {
        id
        door_number
        floor
        unit
        line1
        line2
        city_town
        county
        country
        postcode
      }
    }
  }
`;

export const GET_PAYEES = gql`
  query getPayees {
    payees{
      id
      name
      type
    }
  }
`;

export const GET_EXPENSE_TYPES = gql`
  query getExpenseTypes {
    expenseTypes{
      id
      name
      type
      workflow{
        id
        name
        stages{
          id
          name
          order
          actions{
            id
            name
            type
          }
          triggers{
            id
            name
            type
          }
        }
      }
    }
  }
`;

export const MUTATE_ADD_EXPENSE = gql`
  mutation($input: [ExpenseInput!]!) {
    addExpenses(input: $input) {
      id
      bill_date
      due_date
      expense_type{
        id
        name
        type
        workflow{
          id
          name
          stages{
            id
            name
            order
            actions{
              id
              name
              type
            }
            triggers{
              id
              name
              type
            }
          }
        }
      }
      items{
        item_id
        account_id
        account{
          id
          name
          description
          starting_balance
          archived
          display_in_report
          account_type{
            id
            name
          }
        }
        amount
        bar_code
        creation{
          time
          user_id
        }
        inventory_id
        order
        product_code
        product_name
        quantity
        taxes{
          id
          name
          rate
          amount
        }
      }
      payee{
        id
        name
        type 
      }
      creation{
        time
      }
      order_no
      status{
        id
        name
        order
      }
      state
      statuses{
        creation{
          time
          user_id
        }
        id
        name
        order
      }
      payments{
        amount
        status
      }
      attachments {
        id
        url
        name
        content_type
      }
      total{
        amount
        taxes{
          id
          name
          rate
          amount
        }
      }
    }
  }
`;