import React, { useState, useEffect } from 'react';

import {
  Box,
  Tab,
  Grid,
  Tabs,
  Dialog,
  Button,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from '@material-ui/core';
import PrimaryButton from '@/components/button';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import { gql, useQuery, useMutation } from '@apollo/client';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useSnackbar } from 'notistack';
import TextField from '@/components/textField';
import {
  GET_ROLE_USER,
  GET_STORE,
  GET_ALL_PERMISSION,
  MUTATE_INVITE_USER,
} from '@/services/userManagementService';
import _ from 'lodash';
import SingleCheckboxInput from '@/components/singleCheckbox';
import {
  capitalizeEachLetter,
  convertUnderToTitleCase,
} from '@/utils/stringUtil';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const roleUserQuery = gql`
  ${GET_ROLE_USER}
`;

const storeQueries = gql`
  ${GET_STORE}
`;

const permissionQueries = gql`
  ${GET_ALL_PERMISSION}
`;

const AddNewUser = ({
  teams,
  onFetchInvitations,
  plans,
  paymentMethodDefault,
  onShowPaymentStripe,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [addNewUser, setAddNewUser] = useState({
    index: 0,
    email: '',
    selectedRole: '',
    selectTeam: '',
    permissionList: [],
    store: {},
    licenses: [],
    isOpenEditModal: false,
    isLoading: false,
  });

  const handleOpenEditModal = () =>
    setAddNewUser({ ...addNewUser, isOpenEditModal: true });
  const handleCloseEditModal = () => {
    setAddNewUser({
      index: 0,
      email: '',
      selectedRole: '',
      selectTeam: '',
      permissionList: [],
      store: {},
      licenses: [],
      isOpenEditModal: false,
      isLoading: false,
    });
    handleReset();
  };

  const handleChangeTab = (event, index) => {
    setAddNewUser({ ...addNewUser, index: index });
  };

  const { loading, data } = useQuery(roleUserQuery);
  const roles = data?.roles;

  const { loading: loadingStore, data: storeData } =
    useQuery(storeQueries);
  const stores = storeData?.stores;

  const { loading: loadingPermission, data: permissionData } =
    useQuery(permissionQueries);
  const permissions = permissionData?.globalPermissions;

  const [inviteUser] = useMutation(
    gql`
      ${MUTATE_INVITE_USER}
    `
  );

  const hasSubscription = () => {
    let isExist = false;
    addNewUser.licenses?.map((license) => {
      if (
        !_.isEmpty(
          license?.packages[0]?.prices?.find(
            (price) => price.isSelected
          )
        )
      ) {
        isExist = true;
      }
    });

    return isExist;
  };

  const isShowPaymentStripe =
    hasSubscription() && _.isEmpty(paymentMethodDefault);

  const addNewUserSchema = Yup.object().shape({
    emailNewUser: Yup.string()
      .required(t('userManagement.users.email_required'))
      .matches(
        /^[^\s@]+@[^\s@]+$/,
        t('userManagement.users.email_invalid_format')
      ),
  });

  const {
    handleBlur,
    handleReset,
    errors,
    setFieldValue,
    touched,
    values,
    isValid,
  } = useFormik({
    initialValues: {
      emailNewUser: addNewUser.email,
      selectTeam: addNewUser.selectTeam,
    },
    validationSchema: addNewUserSchema,
  });

  useEffect(() => {
    if (stores?.length > 0) {
      setAddNewUser({
        ...addNewUser,
        store: _.groupBy(
          stores,
          (store) =>
            store?.region?.region_name ||
            t('userManagement.users.all_stores')
        ),
      });
    }

    if (addNewUser.isOpenEditModal) {
      const userPlansMapping = plans?.reduce((acc, arg) => {
        return acc.concat(
          arg?.plans?.map((plan) => {
            return {
              ...plan,
              packages: plan?.packages?.map((planPackage) => ({
                ...planPackage,
                prices: planPackage?.prices.map((price) => ({
                  ...price,
                  isSelected: false,
                })),
              })),
            };
          })
        );
      }, []);
      if (!_.isEmpty(userPlansMapping)) {
        setAddNewUser({ ...addNewUser, licenses: userPlansMapping });
      }
    }
  }, [stores, addNewUser.isOpenEditModal]);

  useEffect(() => {
    if (addNewUser.selectedRole !== '') {
      let result = [];
      const roleList =
        roles
          ?.find((item) => item.id === addNewUser.selectedRole)
          ?.permissions?.split(',') || [];

      roleList.map((item) => {
        const value = item.split('.');
        const prefix = value[0];
        const suffix = value[1];
        result.push({ key: prefix, value: suffix });
      });

      const permissionGroupBy = _.groupBy(result, 'key');
      const permissionList = permissions?.map((per) => ({
        ...per,
        permissions: per?.permissions?.map((action) => ({
          ...action,
          ...{
            isChecked:
              permissionGroupBy[per.group]?.findIndex(
                (item) => item.value === action.action
              ) > 0,
          },
        })),
      }));
      setAddNewUser({
        ...addNewUser,
        permissionList: permissionList,
      });
    }
  }, [addNewUser.selectedRole]);

  const onNextStep = () => {
    switch (addNewUser.index) {
      case 0:
        handleChangeTab(event, 1);
        break;
      case 1:
        handleChangeTab(event, 2);
        break;
      case 2:
        handleAddUser();
        break;
      default:
        break;
    }
  };

  const convertPermissionToStringify = () => {
    const result = [];
    addNewUser.permissionList?.map((per) => {
      per?.permissions.map((action) => {
        if (action.isChecked) {
          result.push(`${per.group}.${action.action}`);
        }
      });
    });
    return result.join(',');
  };

  const handleAddUser = () => {
    if (!isShowPaymentStripe) {
      setAddNewUser({ ...addNewUser, isLoading: true });
      const permissionInput = convertPermissionToStringify();
      const storesInput = Object.values(addNewUser.store)[0]
        ?.filter((item) => item?.isChecked)
        ?.map((item) => ({
          id: item.id,
          role_id: addNewUser.selectedRole,
          permissions: permissionInput,
        }));
      const plans = addNewUser.licenses?.reduce((acc, arg) => {
        return acc.concat(
          arg.packages[0].prices
            ?.filter((price) => price.isSelected)
            ?.map((price) => ({
              package_id: arg.packages[0]?.id,
              plan_id: arg?.id,
              type: price.type,
            }))
        );
      }, []);

      const payload = {
        variables: {
          input: {
            email: addNewUser.email,
            team_id: _.isEmpty(addNewUser.selectTeam)
              ? []
              : addNewUser.selectTeam,
            stores: storesInput,
            plans: plans,
          },
        },
      };
      return inviteUser(payload).then(
        async (res) => {
          if (res?.data) {
            await onFetchInvitations?.();
            enqueueSnackbar(
              t('userManagement.users.notify_add_user_successfully')
            );
            handleCloseEditModal();
          }
        },
        (error) => {
          setAddNewUser({ ...addNewUser, isLoading: false });
          const message = error?.message || error?.errors[0]?.message;
          enqueueSnackbar(message, { variant: 'error' });
        }
      );
    } else {
      onShowPaymentStripe?.();
    }
  };

  const handleSelectPaymentOption = (value, currentLicense) => {
    const list = [...addNewUser.licenses];
    const result = list?.reduce((acc, arg) => {
      if (_.isEqual(currentLicense.id, arg.id)) {
        return acc.concat({
          ...arg,
          packages: {
            ...arg?.packages,
            [0]: {
              ...arg?.packages[0],
              prices: currentLicense?.packages[0]?.prices?.map(
                (price) => ({
                  ...price,
                  isSelected:
                    _.isEqual(price.type, value) && price.isSelected
                      ? false
                      : _.isEqual(price.type, value),
                })
              ),
            },
          },
        });
      }
      return acc.concat(arg);
    }, []);
    setAddNewUser({ ...addNewUser, licenses: result });
  };

  const Pricing = ({ license, price }) => {
    return (
      <Box display="flex" alignItems="center" paddingY={2}>
        <CustomRadio
          checked={price?.isSelected}
          onClick={(event) => {
            handleSelectPaymentOption(event.target.value, license);
          }}
          value={price?.type}
        />
        <Box>
          <Typography>{`${t('userManagement.users.pay')} ${
            license?.currency
          }${price?.gross_amount} ${price?.type}.`}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <div>
      <PrimaryButton
        className={classes.addNewUserButton}
        variant="contained"
        color="primary"
        onClick={handleOpenEditModal}
      >
        {t('userManagement.users.add_new_user')}
      </PrimaryButton>

      <Dialog
        className={classes.boxDialog}
        maxWidth={false}
        fullWidth
        fullScreen={fullScreen}
        onClose={
          addNewUser.isLoading ? () => {} : handleCloseEditModal
        }
        aria-labelledby={`{edit-add-new-user-dialog-title}`}
        open={addNewUser.isOpenEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{edit-add-new-user-dialog-title}`}
          onClose={handleCloseEditModal}
        >
          {t('userManagement.users.add_new_user_dialog_title')}
        </DialogTitle>
        <DialogContent>
          <>
            <Grid container style={{ marginTop: '-20px' }}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Tabs
                    className={classes.tabs}
                    value={addNewUser.index}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      className={classes.tab}
                      style={{ fontSize: 18 }}
                      key={'role-and-permissions'}
                      label={t(
                        'userManagement.users.role_and_permissions'
                      )}
                    />
                    <Tab
                      disabled={!isValid}
                      className={classes.tab}
                      style={{ fontSize: 18 }}
                      key={'stores'}
                      label={t('userManagement.users.stores')}
                    />
                    <Tab
                      disabled={!isValid}
                      className={classes.tab}
                      style={{ fontSize: 18 }}
                      key={'licenses'}
                      label={t('userManagement.users.licenses')}
                    />
                  </Tabs>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  {/* Role & Permissions Tab */}
                  <TabPanel
                    key={'role-and-permissions-panel'}
                    value={addNewUser.index}
                    index={0}
                    className={classes.tabPanel}
                  >
                    <div>
                      <Typography className={classes.title}>
                        {t('userManagement.users.invitation')}
                      </Typography>

                      <div>
                        <Typography className={classes.emailTitle}>
                          {t('userManagement.users.email')}
                        </Typography>
                        <form>
                          <Grid container spacing={10}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                placeholder={t(
                                  'userManagement.users.email_placeholder'
                                )}
                                id={'emailNewUser'}
                                name={'emailNewUser'}
                                fullWidth
                                value={values['emailNewUser']}
                                onChange={(event) => {
                                  setAddNewUser({
                                    ...addNewUser,
                                    email: event.target.value,
                                  });
                                  setFieldValue(
                                    'emailNewUser',
                                    event.target.value
                                  );
                                }}
                                error={
                                  touched['emailNewUser'] &&
                                  Boolean(errors['emailNewUser'])
                                }
                                helperText={
                                  touched['emailNewUser'] &&
                                  errors['emailNewUser']
                                }
                                autoFocus
                                onBlur={handleBlur}
                                InputProps={{
                                  className: classes.input,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <>
                                <Select
                                  IconComponent={ExpandMoreIcon}
                                  displayEmpty
                                  fullWidth
                                  id="selectTeam"
                                  name="selectTeam"
                                  className={classes.teamOptionInput}
                                  value={values['selectTeam']}
                                  onChange={(event) => {
                                    setAddNewUser({
                                      ...addNewUser,
                                      selectTeam: event.target.value,
                                    });
                                    setFieldValue(
                                      'selectTeam',
                                      event.target.value
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}
                                >
                                  <MenuItem
                                    key="defaultItem"
                                    value=""
                                  >
                                    {t(
                                      'userManagement.users.select_team'
                                    )}
                                  </MenuItem>
                                  {(teams || []).map(
                                    (option, index) => {
                                      return (
                                        <MenuItem
                                          key={`${option.name}-${index}`}
                                          value={option.id}
                                        >
                                          {option.name}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </>
                            </Grid>
                          </Grid>
                        </form>
                      </div>

                      <div>
                        <Typography className={classes.title}>
                          {t('userManagement.users.role')}
                        </Typography>
                        <Grid container>
                          <Grid item xs={12} sm={12}>
                            <RadioGroup
                              className={classes.boxRoleGroup}
                              aria-label="role-group"
                              name="role-group"
                              value={addNewUser.selectedRole}
                              onChange={(value) =>
                                setAddNewUser({
                                  ...addNewUser,
                                  selectedRole: value.target.value,
                                })
                              }
                            >
                              {!loading ? (
                                roles?.map((item, index) => (
                                  <FormControlLabel
                                    style={{ marginRight: 40 }}
                                    key={`${item.name}-${index}`}
                                    control={
                                      <CustomRadio
                                        checked={
                                          addNewUser.selectedRole ===
                                          item.id
                                        }
                                        value={item.id}
                                        name="radio-button-role"
                                      />
                                    }
                                    label={item.name}
                                  />
                                ))
                              ) : (
                                <Skeleton width="80%" />
                              )}
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </div>

                      {addNewUser.permissionList?.length > 0 && (
                        <div>
                          <Typography className={classes.title}>
                            {t('userManagement.users.permissions')}
                          </Typography>

                          {addNewUser.permissionList?.map(
                            (permission, groupIndex) => (
                              <div key={permission.group}>
                                <Typography
                                  className={classes.subTitle}
                                >
                                  {convertUnderToTitleCase(
                                    permission.name
                                  )}
                                </Typography>
                                <div
                                  className={classes.boxSelectGroup}
                                >
                                  {permission.permissions?.map(
                                    (item, index) => (
                                      <div
                                        key={`${item.action}-${index}`}
                                      >
                                        <SingleCheckboxInput
                                          style={{
                                            marginRight: 40,
                                            paddingBottom: 4,
                                          }}
                                          isShowToast={false}
                                          name={item.action}
                                          label={capitalizeEachLetter(
                                            item.action
                                          )}
                                          value={item.isChecked}
                                          onChange={(value) => {
                                            const result = [
                                              ...addNewUser.permissionList,
                                            ];
                                            result[
                                              groupIndex
                                            ].permissions[
                                              index
                                            ].isChecked = value;
                                            setAddNewUser({
                                              ...addNewUser,
                                              permissionList: result,
                                            });
                                          }}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </TabPanel>

                  {/* Stores Tab */}
                  <TabPanel
                    key={'stores-panel'}
                    value={addNewUser.index}
                    index={1}
                    className={classes.tabPanel}
                  >
                    {!loadingStore ? (
                      Object.keys(addNewUser.store)
                        ?.sort()
                        ?.map((key, index) => (
                          <div key={`${key}-${index}`}>
                            <SingleCheckboxInput
                              isShowToast={false}
                              value={
                                addNewUser.store[key]?.findIndex(
                                  (item) => !item.isChecked
                                ) < 0
                              }
                              name={key}
                              label={capitalizeEachLetter(key)}
                              onChange={(value) => {
                                const storeValue = {
                                  ...addNewUser.store,
                                };
                                const list = storeValue[key].map(
                                  (item) => ({
                                    ...item,
                                    isChecked: value,
                                  })
                                );
                                setAddNewUser({
                                  ...addNewUser,
                                  store: {
                                    ...addNewUser.store,
                                    [key]: list,
                                  },
                                });
                              }}
                            />
                            <div className={classes.boxSelectStore}>
                              {addNewUser.store[key]?.map(
                                (item, index) => (
                                  <div
                                    key={`${item.id}-${index}`}
                                    style={{ marginRight: 40 }}
                                  >
                                    <SingleCheckboxInput
                                      isShowToast={false}
                                      name={item.name}
                                      label={item.name}
                                      value={item?.isChecked || false}
                                      onChange={(value) => {
                                        const result = {
                                          ...addNewUser.store,
                                        };
                                        result[key][index] = {
                                          ...result[key][index],
                                          ...{ isChecked: value },
                                        };
                                        setAddNewUser({
                                          ...addNewUser,
                                          store: result,
                                        });
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        ))
                    ) : (
                      <Box
                        p={3}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={35} />
                      </Box>
                    )}
                  </TabPanel>

                  {/* Licenses Tab */}
                  <TabPanel
                    key="licenses-panel"
                    value={addNewUser.index}
                    index={2}
                    className={classes.tabPanel}
                  >
                    <Typography className={classes.newSubscription}>
                      {t('subscriptions.not_subscription_yet')}
                    </Typography>
                    {addNewUser.licenses?.map((license, index) => (
                      <div
                        key={`${license.subscription_id}-${index}`}
                      >
                        <Typography className={classes.licenseTitle}>
                          {license.name}
                        </Typography>
                        {license.description && (
                          <Typography
                            className={classes.licenseDesription}
                          >
                            {license.description}
                          </Typography>
                        )}
                        <Typography
                          className={classes.selectPaymentOption}
                        >
                          {t('subscriptions.select_payment_options')}
                        </Typography>
                        <div key={license?.packages[0]?.id}>
                          {license?.packages[0]?.prices?.map(
                            (price, priceIndex) => (
                              <Pricing
                                key={`${price.type}-${priceIndex}`}
                                license={license}
                                price={price}
                              />
                            )
                          )}
                        </div>
                      </div>
                    ))}
                  </TabPanel>
                </Grid>
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={!isValid}
            onClick={onNextStep}
            type="submit"
            color="primary"
          >
            {addNewUser.index === 2
              ? t('userManagement.users.add_user')
              : t('userManagement.users.next')}
          </Button>
        </DialogActions>
        {addNewUser.isLoading && <PreLoader size={30} />}
      </Dialog>
    </div>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

const CustomRadio = withStyles({
  root: {
    color: '#939DA8',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default AddNewUser;
