import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import images from '@/assets/images';
import { manageIcons } from './constants';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './styles';

export default function ManageCard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const matchesSmallScreen = useMediaQuery('(max-width:860px)');

  return (
    <Card className={classes.cardContent}>
      <CardHeader
        title={<div className={classes.cardTitle}>Manage</div>}
      />
      <CardContent style={{ paddingTop: 33, paddingBottom: 60 }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid container direction="column" spacing={7}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={10}
                justify="flex-start"
              >
                {manageIcons.map((item, index) => (
                  <Grid key={index} item xs={4} md={3} xl={3}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      spacing={2}
                      style={{
                        paddingBottom: matchesSmallScreen ? 20 : 35
                      }}
                      className={classes.disabled}
                    >
                      <Grid item>
                        <img
                          width={28}
                          height={30}
                          src={images[item.icon]}
                          alt={'...'}
                        />
                      </Grid>

                      <Grid item>
                        <div style={{ textAlign: 'center' }}>
                          {t(`businessManager.${item.label}`)}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
