import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { find } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  CircularProgress,
  Link, Typography, withStyles
} from '@material-ui/core';
import {
  GET_MERCHANT_SETTINGS,
  GET_PROVIDER,
  GET_STORE,
  UPDATE_MERCHANT_SETTINGS, UPDATE_STORE_ORDER
} from '@/services/deliveryService';

import UpdateDialog from './updateDialog';
import useStyles from './styles';
import SingleCheckboxInput from '@/components/singleCheckbox';
import OpeningHours from '@/pages/workboard/manageStore/openingHours';

const LocalDelivery = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: merchantSettings, loading: loadMerchantSettings, refetch: reFetchMerchantSettings } = useQuery(GET_MERCHANT_SETTINGS, {
    fetchPolicy: 'no-cache'
  });

  const { data: store, loading: loadStore, refetch: reFetchStore } = useQuery(GET_STORE, {
    fetchPolicy: 'no-cache'
  });

  const { data: providers, loading: loadProviders, refetch: reFetchProviders } = useQuery(GET_PROVIDER, {
    fetchPolicy: 'no-cache'
  });

  const [SignupPartner, { loading: updating }] =
    useMutation(UPDATE_MERCHANT_SETTINGS, {
      onCompleted: (response) => {
        reFetchMerchantSettings().then(() => setIsLoadingPartners(false));
        enqueueSnackbar(t('modules.delivery.signed_successfully'));
      },
      onError: (error) => {
        setIsLoadingPartners(false);
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    });

  const [HourlyDelivery, { loading }] =
    useMutation(UPDATE_STORE_ORDER, {
      onCompleted: (response) => {
        reFetchStore().then(() => setIsLoadingStore(false));
      },
      onError: (error) => {
        setIsLoadingStore(false);
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    });

  const [checked, setChecked] = useState(store?.store?.settings.delivery.delivery_during_opening_hours);

  const [loadingStore, setIsLoadingStore] = useState(false);
  const [loadingPartners, setIsLoadingPartners] = useState(false);
  const [loadingMerchantSettings, setIsLoadingMerchantSettings] = useState(false);
  const [currentPartner, setCurrentPartner] = useState({});

  const [modal, setModal] = useState({
    updateHourlyCapacity: false,
    updatePayable: false
  });

  const handleChangeCheckbox = (e) => {
    const payload = {
      variables: {
        store: {
          id: store?.store.id,
          settings: {
            delivery: {
              delivery_during_opening_hours: e
            }
          }
        }
      }
    };
    return HourlyDelivery(payload);
  };

  const signupPartner = (partner) => {
    setIsLoadingPartners(true);
    const payload = {
      variables: {
        input: {
          delivery: {
            partners: {
              id: partner.id,
              rate: partner.base_rate
            }
          }
        }
      }
    };
    return SignupPartner(payload);
  };

  const reFetchStoreData = () => {
    setIsLoadingStore(true);
    reFetchStore().then(() => setIsLoadingStore(false));
  };

  const reFetchMerchantData = () => {
    setIsLoadingMerchantSettings(true);
    reFetchMerchantSettings().then(() => setIsLoadingMerchantSettings(false));
  };

  return (
    <>
      <div className={classes.globalInformationWrapper}>
        <Typography variant='subtitle1'
                    paragraph
                    gutterBottom
                    className={classes.title}>
          {t('modules.delivery.general_information')}
        </Typography>
        <Typography variant='body2' paragraph>
          <Trans
            i18nKey={'modules.delivery.general_information_description'}
            components={{
              tag: <StyledLink />
            }}
          />
        </Typography>
        {loadStore ?
          <div className={classes.loading}>
            <CircularProgress size={35} />
          </div> :
          <>
            <SingleCheckboxInput
              value={checked !== undefined ? checked : store?.store?.settings?.delivery?.delivery_during_opening_hours}
              onChange={(e) => {
                setChecked(e);
                handleChangeCheckbox(e);
              }}
              style={{ paddingBottom: 15, float: 'left' }}
              labelVariant='body2'
              label={t('modules.delivery.deliver_during_message')}
            />
            <Typography className={classes.openDelivery} variant='body2' paragraph>
              <StyledLink
                onClick={() => setModal({
                  ...modal,
                  openingHours: true
                })} style={{marginLeft: '-10px'}}> {t('modules.delivery.deliver_during')} </StyledLink>
            </Typography>
            {loadingStore ?
              <div className={classes.loading}>
                <CircularProgress size={35} />
              </div> :
              <Typography variant='body2' paragraph style={{ marginTop: 24 }}>
                {t('modules.delivery.hourly_capacity', { count: store?.store.settings.delivery.hourly_capacity })}
                <StyledLink onClick={() => setModal({ ...modal, updateHourlyCapacity: true })}>
                  {t('modules.delivery.update')}
                </StyledLink>
              </Typography>
            }
          </>
        }
        <Typography variant='body2' paragraph>
          {t('modules.delivery.capacity_description')}
        </Typography>
      </div>
      <div className={classes.localRideWrapper}>
        <Typography
          variant='subtitle1'
          paragraph
          gutterBottom
          className={classes.title}>
          {t('modules.delivery.local_platform_title')}
        </Typography>
        {loadingPartners ?
          <div className={classes.loading}>
            <CircularProgress size={35} />
          </div> :
          providers?.providers.map((item, index) => {
            const partner = find(merchantSettings?.merchantSettings.delivery.partners, { id: item.id });
            return (
              <div className={classes.partnerWrapper} key={index + 1}>
                {item.logo && <img src={item.logo.thumbnail || item.logo.url} alt='partners logo'
                                   className={classes.partnerLogo} />
                }

                <Typography
                  variant='subtitle1'
                  paragraph
                  gutterBottom
                  className={classes.title}>
                  {t('modules.delivery.rates')}
                </Typography>
                <Typography variant='body2' paragraph>
                  <Trans
                    i18nKey={t('modules.delivery.rates_description', { partner_name: item.tname })}
                    components={{
                      tag: <b />
                    }}
                  />
                </Typography>
                {loadingMerchantSettings ?
                  (<div className={classes.loading}>
                    <CircularProgress size={35} />
                  </div>) :
                  <Typography variant='body2' paragraph
                              onClick={() => setCurrentPartner({ ...partner, base_rate: item.base_rate })}>
                    <Trans
                      i18nKey={t('modules.delivery.revenue_share', { percentage: partner?.rate || item.base_rate })}
                      components={{
                        tag: <b style={{ padding: 30 }} />
                      }}
                    />
                    {partner?.id ? (
                      <StyledLink onClick={() => setModal({ ...modal, updatePayable: true })}>
                        {t('modules.delivery.update')}
                      </StyledLink>) : (
                      <Typography variant='body2' paragraph style={{ paddingTop: 15 }}>
                        <Trans
                          i18nKey={t('modules.delivery.signing_description', { partner_name: item.tname })}
                          components={{
                            click: <StyledLink onClick={() => signupPartner(item)} />,
                            tag: <StyledLink href='https://www.ridercommunity.asia/' target='_blank'
                            />
                          }}
                        />
                      </Typography>)}
                  </Typography>}
              </div>);
          })}
      </div>
      {modal.updatePayable &&
      <UpdateDialog open={modal.updatePayable}
                    handleClose={() => setModal({ ...modal, updatePayable: false })}
                    dialogTitle={t('modules.delivery.dialog_revenue_title')}
                    actionType='rate'
                    data={currentPartner}
                    setRefetchData={reFetchMerchantData}
                    dialogDescription={t(
                      'modules.delivery.dialog_revenue_description',
                      {
                        percentage: currentPartner?.base_rate
                      })}
      />}
      {modal.updateHourlyCapacity &&
      <UpdateDialog open={modal.updateHourlyCapacity}
                    handleClose={() => setModal({ ...modal, updateHourlyCapacity: false })}
                    dialogTitle={t('modules.delivery.dialog_hourly_title')}
                    actionType='hour'
                    data={store}
                    setRefetchData={reFetchStoreData}
                    dialogDescription={t('modules.delivery.dialog_hourly_description')}
      />}
      {modal.openingHours &&
      <OpeningHours open={modal.openingHours}
                handleClose={() => setModal({...modal, openingHours: false})}
      />
      }
    </>
  );
};

const StyledLink = withStyles(() => ({
  root: {
    cursor: 'pointer'
  }

}))(Link);

export default LocalDelivery;
