import { gql } from '@apollo/client';

export const GET_CUSTOMERS = gql`
  query getCustomers($filter: Filter) {
    shoppers(filter: $filter) {
      id
      first_name
      last_name
      email
      mobile
      gender
      audiences {
        id
        name
        #segment
      }
    }
  }
`;

export const ADD_NEW_CUSTOMER = gql`
  mutation ($input: [ShopperInput!]!) {
    addShoppers(input: $input) {
      id
      first_name
      last_name
      email
      mobile
      gender
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation($id: UUID!) {  
    deleteShopper(id: $id)
  }
`;
