import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core'

import SelectCompare from './selectCompare'
import SelectProduct from './selectProduct'
import SelectStore from './selectCompareStore'

import { 
  selectSelectedRegion, 
  selectSelectedStore 
} from '@/store/modules/store/selectors'

import { COMPARISON } from '../helper'
import useStyles from './styles';

const Selectors = (props) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    comparison,
    onChangeComparison,
    product,
    onRemoveProduct,
    comparedStore,
    onChangeComparedStore,
    comparedProduct,
    onChangeComparedProduct
  } = props

  const selectedRegion = useSelector(selectSelectedRegion)
  const selectedStore = useSelector(selectSelectedStore)

  return (
    <Grid container justify='center' alignItems='center' spacing={10}>
      <Grid
        item
        style={{
          width: fullScreen ? '100%' : 350,
          marginTop: fullScreen ? 20 : 0
        }}
      >
        <SelectProduct
          product={product}
          onRemoveProduct={onRemoveProduct}
        />
      </Grid>

      <Grid
        item
        style={{
          width: fullScreen ? '100%' : 350,
          marginTop: fullScreen ? 20 : 0
        }}
      >
        <SelectCompare
          comparison={comparison}
          onChangeComparison={onChangeComparison}
        />
      </Grid>

      <Grid
        item
        style={{
          width: fullScreen ? '100%' : 350,
          marginTop: fullScreen ? 20 : 0
        }}
      >
        {comparison === COMPARISON.STORE
          ?
          <SelectStore
            selectedStoreId={selectedRegion ? selectedRegion.id : selectedStore.id}
            compareStore={comparedStore}
            onChangeCompareStore={onChangeComparedStore}
          />
          :
          <SelectProduct
            product={comparedProduct}
            onSelectProduct={(value) => value.id === product.id ? {} : onChangeComparedProduct(value)}
            onRemoveProduct={() => onChangeComparedProduct(null)}
          />
        }
      </Grid>
    </Grid>
  )
}

Selectors.propTypes = {
  comparison: PropTypes.string,
  onChangeComparison: PropTypes.func,
  product: PropTypes.object,
  onRemoveProduct: PropTypes.func,
  comparedStore: PropTypes.object,
  onChangeComparedStore: PropTypes.func,
  comparedProduct: PropTypes.object,
  onChangeComparedProduct: PropTypes.func
}

export default Selectors
