import { gql } from '@apollo/client';

export const GET_ROLES = `
    query getRoles{
        roles{
          id
          name
        }
    }
`;

export const GET_TEAMS = `
    query getTeams{
        teams{
            id
            name
        }
    }
`;

export const GET_TEAMS_FILTER = `
    query getTeams($name: String){
        teams(name: $name){
            id
            name
        }
    }
`;

export const GET_USERS = `
    query getUsers($role_id: String, $team_id: String, $status: String, $filter: Filter){
        employee(role_id: $role_id, team_id: $team_id, status: $status, filter: $filter){
            id
            profile{
                thumbnail
            }
            config{
                background_colour
            }
            first_name
            last_name
            email
            mobile
            roles{
                id
                name
                permissions
                store_id
                merchant_id
            }
            teams{
                id
                name
            }
            last_login
            verified
        }
    }
`;

export const GET_ROLE_USER = `
    query getRoleUser{
        roles{
        id
        name
        permissions
        }
    }
`;

export const GET_STORE = `
    query getStores {
        stores{
          id
          name
          region{
              id
              region_name
          }
        }
    }
`;

export const GET_PLANS = `
    query getPlans{
        plans{ 
            group
                plans{
                    id
                    name
                    fee
                    description
                    currency
                    packages{
                    id
                    type
                    package
                    prices{
                        gross_amount
                        gross_pro_rated
                        next_payment_date
                        type
                        taxes{
                        id
                        rate
                        }
                    }
                }
                subscription {
                    id
                    status
                    next_bill_amount
                    next_payment_date
                    resource {
                        id
                        provider
                        card {
                            last4
                        }
                    }
                    items {
                    id
                    type
                    item
                    item_type
                    next_bill_amount
                    next_payment_date
                    }
                    payments {
                        id
                        date
                        type
                        item
                        failed
                        cycle
                    }
                }
            }
        }
    }
`;

export const MUTATE_INVITE_USER = `
    mutation($input: [InvitationInput!]!) {
        inviteUsers(input: $input)
    }
`;

export const GET_ALL_PERMISSION = `
    query getAllPermission{
        globalPermissions{
            group
            name
            permissions{
                action
                is_admin
                overridable
            }
        }
    }
`;

export const GET_USER_DETAIL = `
    query getUserDetail($userId: String!){
        user(id: $userId){
            id
            teams{
                id
                name
            }
            roles{
                id
                name
                permissions
                store_id
                merchant_id
            }
        }
    }
`;

export const MUTATE_USER_DETAIL = `
mutation updateUserDetail($input: UserInput!) {  
    updateUser(input: $input) {
        id
        profile{
            url
        }
    }
}
`;

export const MUTATE_USER_TEAM = `
mutation updateUserTeam($input: [UserTeamInput!]!){
    updateUserTeams(input: $input)
}
`;

export const MUTATE_TEAM = `
mutation updateTeam($input: [TeamInput!]!){
    addTeam(input: $input){
        id
        name
    }
}
`;

export const GET_APPLICATIONS = gql`
  query getApplications(
    $filter: Filter
    $status: String
    $query: String
  ) {
    applications(filter: $filter, status: $status, query: $query) {
      id
      merchant_id
      store_id
      reason
      role_id
      approved_at
      denied_at
      cards {
        id
        name
        thumbnail
        url
      }
      selfie {
        id
        name
        thumbnail
        url
      }
      conditions {
        id
        value
        description
      }
      user {
        id
        first_name
        last_name
        mobile
        dob
        addresses {
          door_number
          building_name
          line1
          line2
          city_town
          postcode
          floor
          unit
          country
        }
      }
    }
  }
`;

export const MUTATE_APPROVE_APPLICATION = gql`
  mutation approveApplication($id: UUID!) {
    approveApplication(id: $id)
  }
`;

export const MUTATE_DENY_APPLICATION = gql`
  mutation denyApplication($id: UUID!, $reason: String) {
    denyApplication(id: $id, reason: $reason)
  }
`;
