import React from 'react';
import { Route, Switch } from 'react-router-dom';

import StoreSelectPage from '@/pages/store/storeSelectPage';

import NotFoundPage from '@/pages/notFoundPage';

import AddStore from '@/pages/store/addStore';

const Routes = () => (
  <Switch>
    <Route exact path="/store/select" component={StoreSelectPage} />
    <Route exact path="/store/add-store" component={AddStore} />

    {/* Show Not Found page when url does not match */}
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
