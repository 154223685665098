import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  addViewImageText: {
    ...CommonFonts.blueBoldFont14,
    cursor: 'pointer'
  },
  label: {
    ...CommonFonts.darkFont18
  },
  row: {
    '&:hover': {
      backgroundColor: '#FFFFFF !important'
    }
  },
  closeButton: {
    backgroundColor: Colors.GRAY_LIGHT,
    padding: 5,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}));

export default useStyles;
