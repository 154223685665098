import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Box,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import InventorySearchInput from '@/components/inventorySearchInput'
import AdjustStockSection from './AdjustStockSection'

import { useQuery, useMutation, gql } from '@apollo/client';
import { GET_STOCK_REASONS, UPDATE_PRODUCT } from '@/services/inventoryService'

import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const SECTION_TYPE = {
  ADJUST_STOCK: 'ADJUST_STOCK',
  ADD_NEW_STOCK: 'ADD_NEW_STOCK'
}

const AddStock = ({ open, handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar();

  const [selectedProduct, setSelectedProduct] = useState(null)
  const [sectionType, setSectionType] = useState(SECTION_TYPE.ADD_NEW_STOCK)

  const { data: reasonsData, loading: isLoadingStockReasons } = useQuery(GET_STOCK_REASONS)

  const [updateProductMutation, { loading: isUpdatingProduct }] = useMutation(UPDATE_PRODUCT, {
    onCompleted: () => {
      handleClose()
      const successMessage = sectionType === SECTION_TYPE.ADD_NEW_STOCK
        ? t('workboard.add_stock.stock_added')
        : t('workboard.add_stock.stock_adjusted')

      enqueueSnackbar(successMessage);
    },
    onError: (error) => {
      const errorMessage = error?.message || error?.errors[0].message;
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  })

  const onChangeSectionType = () => {
    setSectionType(sectionType === SECTION_TYPE.ADD_NEW_STOCK
      ? SECTION_TYPE.ADJUST_STOCK
      : SECTION_TYPE.ADD_NEW_STOCK
    )
  }

  const onUpdateStock = (variables) => {
    updateProductMutation({
      variables
    })
  }

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          alignContent="center"
        >
          <Grid item xs={1} />
          <Grid className={classes.headerText} item xs={10}>
            {t('workboard.add_stock.add_stock')}
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            <IconButton
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>

        <Box marginY={9}>
          <Typography align='center' className={classes.enterBarcodeText}>
            {t('workboard.add_stock.enter_barcode_to_add_stock')}
          </Typography>
        </Box>

        {selectedProduct
          ?
          <AdjustStockSection
            sectionType={sectionType}
            onChangeSectionType={onChangeSectionType}
            reasons={reasonsData?.reasons || []}
            selectedProduct={selectedProduct}
            onRemoveProduct={() => setSelectedProduct(null)}
            onUpdateStock={onUpdateStock}
          />
          :
          <Box paddingX={10} paddingBottom={10} minHeight={300}>
            <InventorySearchInput
              onSelectInventory={setSelectedProduct}
            />
          </Box>
        }
      </DialogTitle>

      {(isLoadingStockReasons || isUpdatingProduct) && <PreLoader size={35} />}
    </Dialog>
  )
}

export default AddStock