import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {
  Typography,
  IconButton,
  Drawer,
  MenuList,
  MenuItem,
} from '@material-ui/core';

import BackButton from '@/components/backButton';
import MenuExpand from '@/components/menuExpand';

import { getAllBusinessManagerServices, getDrawerMenu } from '../helper'

import { selectMerchantSettings } from '@/store/modules/store/selectors'

import TutorialActions from '@/store/modules/tutorial/actions'

import { TUTORIAL_TARGET_CLASSES, TRIGGERS } from '@/components/joyRider/helper'

import images from '@/assets/images';
import useStyles from './styles';

const Menu = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();

  const location = useLocation();
  const urlName = location.pathname;
  const urlSplits = urlName.split('/');

  const merchantSettings = useSelector(selectMerchantSettings)
  const merchantServices = merchantSettings?.products || []
  const businessServices = getAllBusinessManagerServices(merchantServices)

  const storeProfileTrigger = useSelector(state => state.tutorial?.[TRIGGERS.TRIGGER_STORE_PROFILE])
  const startTrigger = useSelector(state => state.tutorial?.[TRIGGERS.TRIGGER_START])
  const userTrigger = useSelector(state => state.tutorial?.[TRIGGERS.TRIGGER_USER_MANAGEMENT])
  const runReportsTrigger = useSelector(state => state.tutorial?.[TRIGGERS.TRIGGER_RUN_REPORTS])
  const touchpointsTrigger = useSelector(state => state.tutorial?.[TRIGGERS.TRIGGER_TOUCHPOINTS])

  const dispatch = useDispatch()

  const [anchor, setAnchor] = useState('left');
  const [open, setOpen] = useState(false);

  const [autoExpands, setAutoExpands] = useState({})

  useEffect(() => {
    if (startTrigger) {
      setOpen(false)
      dispatch(TutorialActions.initState(TRIGGERS.TRIGGER_START, false))
    }

    if (storeProfileTrigger) {
      setOpen(true)
      setAutoExpands({
        ...autoExpands,
        businessManager: true
      })
      dispatch(TutorialActions.initState(TRIGGERS.TRIGGER_STORE_PROFILE, false))
    }

    if (userTrigger) {
      setAutoExpands({
        ...autoExpands,
        userManagement: true
      })
      dispatch(TutorialActions.initState(TRIGGERS.TRIGGER_USER_MANAGEMENT, false))
    }

    if (runReportsTrigger) {
      setOpen(false)
      dispatch(TutorialActions.initState(TRIGGERS.TRIGGER_RUN_REPORTS, false))
    }

    if (touchpointsTrigger) {
      setOpen(true)
      setAutoExpands({
        ...autoExpands,
        businessManager: true,
        userManagement: true
      })
      dispatch(TutorialActions.initState(TRIGGERS.TRIGGER_TOUCHPOINTS, false))
    }
  }, [startTrigger, storeProfileTrigger, userTrigger, runReportsTrigger, touchpointsTrigger])

  const toggleDrawer = (position, state) => {
    setAnchor(position);
    setOpen(state);
  };

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.menuWrapper}
        onClick={() => toggleDrawer('left', true)}
      >
        <img className={TUTORIAL_TARGET_CLASSES.START} src={images.menu} width={34} height={34} alt="menu" />
      </IconButton>
      <Drawer
        anchor={anchor}
        open={open}
        onClose={() => toggleDrawer(anchor, false)}
      >
        <div className={classes.drawerContent}>
          <div className={classes.backWrapper}>
            <BackButton onClick={() => toggleDrawer(anchor, false)} />
          </div>
          {getDrawerMenu(businessServices).map(({ id, name, items }) => (
            <div key={id} className={classes.menuList}>
              <Typography className={classes.nameMenuList}>
                {' '}
                {t(`menu.${name}`)}
              </Typography>
              <MenuList className={classes.list}>
                {items.map(
                  ({
                    id,
                    name,
                    label,
                    icon,
                    width,
                    height,
                    iconBig,
                    redirectTo,
                    subMenu,
                    isActive,
                    className
                  }) => (
                    <div key={id}>
                      {subMenu?.length > 0 ? (
                        <MenuExpand
                          id={id}
                          name={name}
                          label={label}
                          icon={icon}
                          width={width}
                          height={height}
                          iconBig={iconBig}
                          subMenu={subMenu}
                          autoExpand={autoExpands[name]}
                          initAutoExpands={() => setAutoExpands({})}
                        />
                      ) : (
                        <MenuItem
                          disabled={!isActive}
                          onClick={() => history.push(redirectTo)}
                          key={id}
                          className={classNames(classes.menuItem, className, {
                            [classes.menuBigItem]: iconBig,
                            [classes.menuSmallItem]: !iconBig,
                          })}
                        >
                          <div
                            className={
                              iconBig
                                ? classes.iconBigWrapper
                                : classes.iconSmallWrapper
                            }
                          >
                            <img
                              src={images[icon]}
                              width={width}
                              height={height}
                              alt={label}
                            />
                          </div>
                          <Typography
                            className={classNames(
                              classes.nameMenuItem,
                              {
                                [classes.nameMenuSmallItem]: !iconBig,
                              }
                            )}
                          >
                            {t(`menu.${name}`)}
                          </Typography>
                        </MenuItem>
                      )}
                    </div>
                  )
                )}
              </MenuList>
            </div>
          ))}
        </div>
      </Drawer>
      <div className={classes.workBoardWrapper}>
        <img src={images.logo} width={60} height={24} alt="menu" />
        <Typography className={classes.workBoard}>
          {t(`header.${urlSplits[1]}`)}
        </Typography>
      </div>
    </div>
  );
};

export default Menu;
