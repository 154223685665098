import React from 'react';
import * as Sentry from '@sentry/react';
import { SENTRY_DSN } from '@/constants/env';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const SentryWrapper = ({ children }) => {
  return children;
};

export default SentryWrapper;
