import { isEmpty } from 'lodash';
import { TUTORIAL_TARGET_CLASSES } from '@/components/joyRider/helper';

const isServiceAvailable = (service) => {
  if (service === 'addon') return true
  if (service === 'ingredient') return true
  if (service === 'style') return true
  return false
}

const defaultServices = [
  {
    id: 'store-profile',
    link: '/business-manager/store-profile',
    isActive: true,
    className: TUTORIAL_TARGET_CLASSES.STORE_PROFILE,
  },
  {
    id: 'orders',
    link: '/business-manager/orders',
    isActive: true,
    className: TUTORIAL_TARGET_CLASSES.ORDERS,
  },
  {
    id: 'expenses',
    link: '/business-manager/expenses',
    isActive: true,
    className: TUTORIAL_TARGET_CLASSES.EXPENSES,
  },
  {
    id: 'products',
    link: '/business-manager/products',
    isActive: true,
    className: TUTORIAL_TARGET_CLASSES.INVENTORY,
  },
  {
    id: 'promotions',
    link: '/business-manager/promotions',
    isActive: true,
  },
  {
    id: 'customers',
    link: '/business-manager/customers',
    isActive: true,
    className: TUTORIAL_TARGET_CLASSES.CUSTOMERS,
  },
  {
    id: 'suppliers',
    link: '/business-manager/suppliers',
    isActive: false,
  },
];

export const getAllBusinessManagerServices = (merchantServices) => {
  let tempServices = [...defaultServices];
  //update the array of services putting extra services between
  if (!isEmpty(merchantServices)) {
    for (const [key, value] of Object.entries(merchantServices)) {
      if (key === 'net_price' || key === 'allergy' || key === 'recipe') {
        continue; // skip 
      }
      if (value === true) {
        let subMenu = []
        // if (key === 'style') {
        //   subMenu = [
        //     {
        //       id: 1,
        //       name: 'style-variants',
        //       isActive: true,
        //       path: '/business-manager/style-variants',
        //     },
        //     {
        //       id: 2,
        //       name: 'colors',
        //       isActive: true,
        //       path: '/business-manager/colors',
        //     },
        //     {
        //       id: 3,
        //       name: 'sizes',
        //       isActive: true,
        //       path: '/business-manager/sizes',
        //     },
        //   ]
        // }
        tempServices.splice(4, 0, {
          id: key,
          link: `/business-manager/${key}`,
          isActive: isServiceAvailable(key),
          subMenu
        });
      }
    }
  }
  return tempServices;
};

export const getDrawerMenu = (businessServices) => {
  return [
    {
      id: 0,
      name: 'services',
      items: [
        {
          id: 0,
          name: 'workBoard',
          icon: 'workBoard',
          width: 50,
          height: 50,
          iconBig: true,
          redirectTo: '/workboard',
          isActive: true,
        },
        {
          id: 1,
          name: 'businessManager',
          icon: 'businessManager',
          width: 50,
          height: 50,
          iconBig: true,
          redirectTo: '/business-manager',
          subMenu: businessServices.map((service, id) => ({
            id: id + 1,
            name: service.id,
            isActive: service.isActive,
            path: service.link,
            iconBig: false,
            className: service.className,
            subMenu: service.subMenu
          })),
          isActive: true,
        },
        {
          id: 2,
          name: 'insights',
          icon: 'insights',
          width: 50,
          height: 50,
          iconBig: true,
          redirectTo: '',
          isActive: false,
        },
      ],
    },
    {
      id: 1,
      name: 'admin',
      width: 50,
      height: 50,
      items: [
        {
          id: 0,
          name: 'stores',
          icon: 'stores',
          width: 20,
          height: 20,
          redirectTo: '/stores',
          subMenu: [],
          isActive: true,
        },
        {
          id: 1,
          name: 'warehouses',
          icon: 'wareHouses',
          width: 20,
          height: 20,
          redirectTo: '/warehouses',
          subMenu: [],
          isActive: true,
        },
        {
          id: 2,
          name: 'userManagement',
          icon: 'userManagement',
          width: 20,
          height: 20,
          redirectTo: '/user-management/users',
          subMenu: [
            {
              id: 1,
              name: 'users',
              isActive: true,
              path: '/user-management/users',
              className: TUTORIAL_TARGET_CLASSES.USER_MANAGEMENT,
            },
            {
              id: 2,
              name: 'applications',
              isActive: true,
              path: '/user-management/applications',
              // className: TUTORIAL_TARGET_CLASSES.USER_MANAGEMENT,
            },
            {
              id: 3,
              name: 'archivedUsers',
              isActive: true,
              path: '/user-management/archived-users',
            },
            {
              id: 4,
              name: 'manageTeams',
              isActive: true,
              path: '/user-management/teams',
              className: TUTORIAL_TARGET_CLASSES.TEAMS,
            },
          ],
          isActive: true,
        },
        {
          id: 3,
          name: 'settings',
          icon: 'settings',
          width: 20,
          height: 20,
          redirectTo: '/settings',
          subMenu: [],
          isActive: true,
          className: TUTORIAL_TARGET_CLASSES.SETTINGS,
        },
        {
          id: 4,
          name: 'touchPoints',
          icon: 'touchPoints',
          width: 20,
          height: 20,
          redirectTo: '',
          subMenu: [
            {
              id: 1,
              name: 'touchPoints',
              isActive: true,
              path: '/touchpoints-management/touchpoints',
            },
            {
              id: 2,
              name: 'archivedTouchpoints',
              isActive: true,
              path: '/touchpoints-management/archived-touchpoints',
            },
          ],
          isActive: true,
          className: TUTORIAL_TARGET_CLASSES.TOUCHPOINTS,
        },
        {
          id: 5,
          name: 'modules',
          icon: 'modules',
          width: 20,
          height: 20,
          redirectTo: '/modules',
          subMenu: [],
          isActive: true,
        },
        {
          id: 6,
          name: 'subscriptions',
          icon: 'subscriptions',
          width: 20,
          height: 20,
          redirectTo: '',
          subMenu: [
            {
              id: 1,
              name: 'plans',
              isActive: true,
              path: '/subscription-management/plans',
            },
            {
              id: 2,
              name: 'paymentMethods',
              isActive: true,
              path: '/subscription-management/payment-methods',
            },
          ],
          isActive: true,
        },
        {
          id: 7,
          name: 'import',
          icon: 'import',
          width: 20,
          height: 20,
          redirectTo: '/import',
          subMenu: [],
          isActive: true,
        },
        {
          id: 8,
          name: 'developer',
          icon: 'developer',
          width: 20,
          height: 20,
          redirectTo: '/developer',
          subMenu: [],
          isActive: true,
        },
        {
          id: 9,
          name: 'orderTypeWorkflows',
          icon: 'orderTypeWorkflows',
          width: 20,
          height: 20,
          redirectTo: '/order-types-workflows-management',
          subMenu: [
            {
              id: 1,
              name: 'orderTypes',
              isActive: true,
              path: '/order-types-workflows-management/order-types',
            },
            {
              id: 2,
              name: 'workflows',
              isActive: true,
              path: '/order-types-workflows-management/workflows',
            },
          ],
          isActive: true,
        },
      ],
    },
  ];
};
