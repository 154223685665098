import I18n from '@/i18n'
import { v4 as uuid } from 'uuid';
import moment from 'moment'

export const EXPENSE_STATE = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled'
}

export const EXPENSE_PAYMENT_STATUSES = [
  {
    state: 'paid',
    name: I18n.t('expenses.paid')
  },
  {
    state: 'part-paid',
    name: I18n.t('expenses.part_paid')
  },
  {
    state: 'unpaid',
    name: I18n.t('expenses.unpaid')
  },
]

export const EXPENSE_STATUSES = [
  {
    state: 'Draft',
    name: I18n.t('expenses.draft')
  },
  {
    state: 'Approved',
    name: I18n.t('expenses.approved')
  },
  {
    state: 'Goods received',
    name: I18n.t('expenses.goods_received')
  },
]

export const EXPENSE_TAX_STATUS = {
  EXCLUSIVE_TAX: I18n.t('expenses.exclusive_taxes'),
  INCLUSIVE_TAX: I18n.t('expenses.inclusive_taxes'),
  NO_TAX: I18n.t('expenses.no_taxes'),
}