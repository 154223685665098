import React from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash'

import Grid from '@material-ui/core/Grid';

import { Search } from '@material-ui/icons';
import TextFiled from '@/components/textField';
import { Colors } from '@/theme';
import Button from '@/components/button';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import { Link as RouterLink } from 'react-router-dom';
import useStyles from '../styles';

const FormHeader = ({
  setInputSearch,
  onAddCategory,
  onAddProduct,
  component,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <Paper style={{ width: '100%' }}>
          {/* <AddCategory open={open} handleClose={handleClose} /> */}
          <Grid
            container
            // justify="flex-start"
            alignItems="flex-end"
            // alignContent="flex-e"
            spacing={4}
            className={classes.paperContent}
          >
            <Grid item xs={12} sm={5} md={5} lg={7}>
              <TextFiled
                placeholder=""
                className={classes.searchInput}
                onChange={debounce((e) => setInputSearch(e.target.value), 1000)}
                startAdornment={
                  <Search
                    style={{ color: Colors.GRAY_BORDER1 }}
                    width={18}
                    height={18}
                  />
                }
              />
            </Grid>
            <Hidden xsDown>
              <Grid item sm={7} md={7} lg={5}>
                <Grid container justify="space-around">
                  <Grid item xs={4}>
                    <Button
                      className={classes.addButton}
                      color="primary"
                      variant="contained"
                      onClick={onAddCategory}
                    >
                      {t('categories.add_category')}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      className={classes.addButton}
                      color="primary"
                      variant="contained"
                      onClick={onAddProduct}
                    >
                      {t('categories.add_product')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default FormHeader;
