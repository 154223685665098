import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, find } from 'lodash';

import {
  Dialog,
  IconButton,
  Grid,
  Box,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import TextFiled from '@/components/textField';
import PreLoader from '@/components/preLoader';
import Widget from './widget';

import { gql, useQuery } from '@apollo/client';
import { GET_GLOBAL_WIDGETS } from '@/services/widgetService';

import { Colors } from '@/theme';
import images from '@/assets/images';
import useStyles from './styles';
import I18n from '@/i18n';
import clientWidgets from '@/constants/widgets';
import { contains } from '@/utils/stringUtil';

const DEFAULT_WIDGET = {
  id: '',
  name: I18n.t('globalWidgets.all_widgets'),
  type: '',
};

const DEFAULT_AVAILABLE_DATA = {
  id: '-1',
  name: I18n.t('globalWidgets.all_available_data'),
};

const GlobalWidgetsModal = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { open, onClose, addedWidgets, onAddWidget, isAdding } =
    props;

  const [searchKey, setSearchKey] = useState('');
  const [widget, setWidget] = useState(DEFAULT_WIDGET);
  const [availableData, setAvailableData] = useState(
    DEFAULT_AVAILABLE_DATA
  );

  const [widgets, setWidgets] = useState([DEFAULT_WIDGET]);
  const [availableDatas, setAvailableDatas] = useState([
    DEFAULT_AVAILABLE_DATA,
  ]);
  const [selectedWidgetForAdding, setSelectedWidgetForAdding] =
    useState(null);

  const { loading: isLoadingGlobalWidgets } = useQuery(
    gql`
      ${GET_GLOBAL_WIDGETS}
    `,
    {
      onCompleted: (data) => {
        if (!isEmpty(data?.globalDatasets?.widgets)) {
          let tempWidgets = [...widgets];
          data.globalDatasets.widgets.forEach((item) => {
            let tempWidget = clientWidgets[item.id] || item;
            tempWidgets.push({
              ...tempWidget,
              width: item.width,
              image_url: item.image?.url,
            });
          });
          setWidgets(tempWidgets);
        }
      },
      fetchPolicy: 'network-only',
    }
  );

  const filteredWidgets = widgets.filter(
    (item) =>
      item.id &&
      contains(item.name, searchKey) &&
      contains(item.type, widget.type)
  );

  return (
    <Dialog
      fullScreen
      classes={{
        root: classes.root,
        paperWidthSm: classes.paperWidthSm,
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <IconButton onClick={onClose} className={classes.closeButton}>
        <CloseIcon fontSize="small" />
      </IconButton>
      <Box className={classes.mainContainer}>
        <Grid container justify="center">
          <img src={images.logo} width={150} height={60} alt="logo" />
        </Grid>
        <Typography className={classes.titleText}>
          {t('globalWidgets.insights_market_place')}
        </Typography>
        <Typography className={classes.descriptionText}>
          {t('globalWidgets.choose_widgets')}
        </Typography>

        <Grid container className={classes.filtersContainer}>
          <Grid item xs={12} sm={6} className={classes.searchWrapper}>
            <TextFiled
              autoFocus
              placeholder=""
              className={classes.searchInput}
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              startAdornment={
                <SearchIcon
                  style={{ color: Colors.GRAY_BORDER1 }}
                  width={18}
                  height={18}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.selectWrapper}
              >
                <Select
                  className={classes.select}
                  fullWidth
                  displayEmpty
                  id={'widgetTypes'}
                  name={'widgetTypes'}
                  value={widget.id}
                  onChange={(event) =>
                    setWidget(
                      find(widgets, { id: event.target.value })
                    )
                  }
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {widgets.map((item, id) => (
                    <MenuItem
                      key={`${item.name}-${id}`}
                      value={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.selectWrapper}
              >
                <Select
                  className={classes.select}
                  fullWidth
                  displayEmpty
                  id={'availableData'}
                  name={'availableData'}
                  value={availableData.id}
                  onChange={(event) =>
                    setAvailableDatas(
                      find(availableDatas, { id: event.target.value })
                    )
                  }
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {availableDatas.map((item, id) => (
                    <MenuItem
                      key={`${item.name}-${id}`}
                      value={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          {filteredWidgets.map((item, id) => (
            <Widget
              key={id}
              widget={item}
              hasAdded={
                !!find(
                  addedWidgets,
                  (addedWidget) => addedWidget.widget.id === item.id
                )
              }
              onAddWidget={(item) => {
                setSelectedWidgetForAdding(item);
                onAddWidget(item);
              }}
              isAdding={
                isAdding && selectedWidgetForAdding?.id === item.id
              }
            />
          ))}
        </Grid>
      </Box>
      {isLoadingGlobalWidgets && <PreLoader size={50} />}
    </Dialog>
  );
};

GlobalWidgetsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  addedWidgets: PropTypes.array,
  onAddWidget: PropTypes.func,
  isAdding: PropTypes.bool,
};

export default GlobalWidgetsModal;
