import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SettingAccordion from '.';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import {
  gql,
  useMutation,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import {
  MUTATE_TOUCHPOINT_STOREFRONT_SETTING,
  PROMOTION_LIST,
  TOUCHPOINT_SETTING,
} from '@/services/storeService';
import PreLoader from '@/components/preLoader';
import SettingInput from '../input';
import { useSelector } from 'react-redux';
import SettingSwitch from '@/components/settingSwitch';
import PosArrangeCategory from '../posArrangeCategory';
import PosTypesPicker from '../posTypesPicker';
import PosPrintPromotion from '../posPrintPromotion';
import PosAddressType from '../posAddressType';
import SettingInputCopy from '@/components/settingInputCopy';
import StorefrontSellSelectedProduct from '../storefrontSellSelectedProduct';

const touchpointStorefrontQuery = gql`
  ${TOUCHPOINT_SETTING}
`;

const promotionListQuery = gql`
  ${PROMOTION_LIST}
`;

const TouchpointStorefront = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState(false);

  const { selectedStore } = useSelector((state) => state.store);
  const { id: storeId } = selectedStore;

  const onExpand = (value) => setIsExpand(value);
  const {
    loading,
    data: touchPointData,
    previousData: touchPointPreviousData,
    refetch: refetchTouchpointData,
  } = useQuery(touchpointStorefrontQuery);

  const [getPromotion, { data: promotionsData }] = useLazyQuery(
    promotionListQuery
  );

  useEffect(() => {
    if (isExpand) {
      // getTouchpointStorefront();
      getPromotion();
    }
  }, [isExpand]);

  const [mutateStore, { loading: isUpdatingStore }] = useMutation(
    gql`
      ${MUTATE_TOUCHPOINT_STOREFRONT_SETTING}
    `,
    {
      onCompleted: () => refetchTouchpointData(),
    }
  );

  const getPromotionNames = (values) =>
    values
      ?.map(
        (item) =>
          promotionsData?.promotions?.find((pro) => pro.id === item)
            ?.promo_name
      )
      ?.filter((item) => item)
      ?.join(', ') || '';

  const storefrontSettings =
    touchPointData?.store?.settings?.touchpoint_settings ||
    touchPointPreviousData?.store?.settings?.touchpoint_settings;

  const orderTypes = touchPointData?.store?.order_types || [];
  const paymentTypes = touchPointData?.store?.payment_types || [];

  const updateGeneralSettings = (key, value) => {
    const payload = {
      variables: {
        store: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              digital_front: {
                [key]: value,
              },
            },
          },
        },
      },
      update: (store, { data: { updateStore } }) => {
        const cache = store.readQuery({
          query: touchpointStorefrontQuery,
        });

        const updatedData = {
          ...cache,
          store: {
            ...cache.store,
            settings: {
              ...cache.store.settings,
              touchpoint_settings: {
                ...cache.store.settings.touchpoint_settings,
                digital_front: {
                  ...cache.store.settings.touchpoint_settings
                    .digital_front,
                  [key]: value,
                },
              },
            },
          },
        };
        store.writeQuery({
          query: touchpointStorefrontQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateStore: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              digital_front: {
                [key]: value,
                __typename: 'DigitalFrontSettings',
              },
              __typename: 'StoreDeviceSettings',
            },
            __typename: 'StoreSettings',
          },
          __typename: 'Store',
        },
      },
    };
    return mutateStore(payload);
  };

  const updateProductSettings = (key, value) => {
    const payload = {
      variables: {
        store: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              digital_front: {
                product_view: {
                  [key]: value,
                },
              },
            },
          },
        },
      },
      update: (store, { data: { updateStore } }) => {
        const cache = store.readQuery({
          query: touchpointStorefrontQuery,
        });

        const updatedData = {
          ...cache,
          store: {
            ...cache.store,
            settings: {
              ...cache.store.settings,
              touchpoint_settings: {
                ...cache.store.settings.touchpoint_settings,
                digital_front: {
                  ...cache.store.settings.touchpoint_settings
                    .digital_front,
                  product_view: {
                    ...cache.store.settings.touchpoint_settings
                      .digital_front.product_view,
                    [key]: value,
                  },
                },
              },
            },
          },
        };
        store.writeQuery({
          query: touchpointStorefrontQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateStore: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              digital_front: {
                product_view: {
                  [key]: value,
                  __typename: 'ProductViewSettings',
                },
                __typename: 'DigitalFrontSettings',
              },
              __typename: 'StoreDeviceSettings',
            },
            __typename: 'StoreSettings',
          },
          __typename: 'Store',
        },
      },
    };
    return mutateStore(payload);
  };

  const updateReceiptSettings = (key, value) => {
    const payload = {
      variables: {
        store: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              digital_front: {
                receipts: {
                  [key]: value,
                },
              },
            },
          },
        },
      },
      update: (store, { data: { updateStore } }) => {
        const cache = store.readQuery({
          query: touchpointStorefrontQuery,
        });

        const updatedData = {
          ...cache,
          store: {
            ...cache.store,
            settings: {
              ...cache.store.settings,
              touchpoint_settings: {
                ...cache.store.settings.touchpoint_settings,
                digital_front: {
                  ...cache.store.settings.touchpoint_settings
                    .digital_front,
                  receipts: {
                    ...cache.store.settings.touchpoint_settings
                      .digital_front.receipts,
                    [key]: value,
                  },
                },
              },
            },
          },
        };
        store.writeQuery({
          query: touchpointStorefrontQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateStore: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              digital_front: {
                receipts: {
                  [key]: value,
                  __typename: 'ReceiptsSettings',
                },
                __typename: 'DigitalFrontSettings',
              },
              __typename: 'StoreDeviceSettings',
            },
            __typename: 'StoreSettings',
          },
          __typename: 'Store',
        },
      },
    };
    return mutateStore(payload);
  };

  return (
    <SettingAccordion
      id="touchpointStorefront"
      title={t('settings.touchpoint_storefront.title')}
      onExpand={onExpand}
    >
      {(loading || isUpdatingStore) && <PreLoader size={30} />}
      <Grid container>
        {/* General Section */}
        <Typography className={classes.subTitle}>
          {t('settings.touchpoint_storefront.general')}
        </Typography>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingInputCopy
              name="temporaryURLStorefront"
              label={t(
                'settings.touchpoint_storefront.temporary_url'
              )}
              value={storefrontSettings?.digital_front?.url || ''}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <PosTypesPicker
              title={t(
                'settings.touchpoint_storefront.update_order_types_accepted'
              )}
              value={storefrontSettings?.digital_front?.order_types}
              options={orderTypes}
              name="orderTypesStorefront"
              label={t('settings.touchpoint_storefront.order_types')}
              description={t(
                'settings.touchpoint_storefront.order_types_description'
              )}
              onChange={(value) =>
                updateGeneralSettings('order_types', value)
              }
            />
          </Grid>
        </Grid>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <PosTypesPicker
              title={t(
                'settings.touchpoint_storefront.update_order_types_accepted'
              )}
              value={storefrontSettings?.digital_front?.payment_types}
              options={paymentTypes}
              name="paymentTypesStorefront"
              label={t(
                'settings.touchpoint_storefront.payment_types'
              )}
              description={t(
                'settings.touchpoint_storefront.payment_types_description'
              )}
              onChange={(value) =>
                updateGeneralSettings('payment_types', value)
              }
            />
          </Grid>
        </Grid>

        {/* Product Arrangement Section */}
        <Typography className={classes.subTitle}>
          {t('settings.touchpoint_storefront.product_arrangement')}
        </Typography>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="displayProductCategoryStorefront"
              label={t(
                'settings.touchpoint_storefront.group_and_display_products_by_category'
              )}
              description={t(
                'settings.touchpoint_storefront.group_and_display_products_by_category_description'
              )}
              value={
                storefrontSettings?.digital_front?.product_view
                  ?.by_category
              }
              onChange={(value) =>
                updateProductSettings('by_category', value)
              }
            />
          </Grid>
          {/* <Grid item xs={12} sm={5}>
            <StorefrontSellSelectedProduct
              value={
                storefrontSettings?.digital_front?.product_view
                  ?.show_selected
              }
              options={
                storefrontSettings?.digital_front?.product_view
                  ?.products || []
              }
              name="sellSelectedProduct"
              label={t(
                'settings.touchpoint_storefront.sell_selected_products'
              )}
              description={t(
                'settings.touchpoint_storefront.sell_selected_products_description'
              )}
              isGroupByCategory={
                storefrontSettings?.digital_front?.product_view
                  ?.by_category
              }
              onChange={(value) =>
                updateProductSettings('show_selected', value)
              }
              onChangeOptions={(value) =>
                updateProductSettings('products', value)
              }
            />
          </Grid> */}
        </Grid>

        {storefrontSettings?.digital_front?.product_view
          ?.by_category && (
          <Grid container justify="space-between" spacing={3}>
            <Grid item xs={12} sm={5}>
              <SettingSwitch
                name="displayAlphabeticalOrder"
                label={t(
                  'settings.touchpoint_storefront.display_in_alphabetical_order'
                )}
                description={t(
                  'settings.touchpoint_storefront.display_in_alphabetical_order_description'
                )}
                value={
                  storefrontSettings?.digital_front?.product_view
                    ?.sort_by_name
                }
                onChange={(value) =>
                  updateProductSettings('sort_by_name', value)
                }
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <PosArrangeCategory
                isSortByName={
                  storefrontSettings?.digital_front?.product_view
                    ?.sort_by_name
                }
                value={
                  storefrontSettings?.digital_front?.product_view
                    ?.categories || []
                }
                label={t(
                  'settings.touchpoint_storefront.categories_and_position'
                )}
                description={t(
                  'settings.touchpoint_storefront.categories_and_position_desciption'
                )}
                onChange={(value) =>
                  updateProductSettings('categories', value)
                }
              />
            </Grid>
          </Grid>
        )}

        {/* Receipt Settings Section */}
        <Typography className={classes.subTitle}>
          {t('settings.touchpoint_storefront.receipt_settings')}
        </Typography>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="logoStorefront"
              label={t('settings.touchpoint_storefront.logo')}
              description={t(
                'settings.touchpoint_storefront.logo_description'
              )}
              value={
                storefrontSettings?.digital_front?.receipts?.logo
              }
              onChange={(value) =>
                updateReceiptSettings('logo', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="websiteStorefront"
              label={t(
                'settings.touchpoint_storefront.website_address'
              )}
              description={t(
                'settings.touchpoint_storefront.website_address_description'
              )}
              value={
                storefrontSettings?.digital_front?.receipts?.website
              }
              onChange={(value) =>
                updateReceiptSettings('website', value)
              }
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="phoneNumberStorefront"
              label={t('settings.touchpoint_storefront.phone_number')}
              description={t(
                'settings.touchpoint_storefront.phone_numner_desctiption'
              )}
              value={
                storefrontSettings?.digital_front?.receipts?.phone
              }
              onChange={(value) =>
                updateReceiptSettings('phone', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <PosAddressType
              isFetch={isExpand}
              value={
                storefrontSettings?.digital_front?.receipts
                  ?.address_type
              }
              name="addressTypeStorefront"
              label={t('settings.touchpoint_storefront.address_type')}
              description={t(
                'settings.touchpoint_storefront.address_type_description'
              )}
              onChange={(value) =>
                updateReceiptSettings('address_type', value)
              }
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="RegistrationNumberStorefront"
              label={t(
                'settings.touchpoint_storefront.registration_number'
              )}
              description={t(
                'settings.touchpoint_storefront.registration_number_description'
              )}
              value={
                storefrontSettings?.digital_front?.receipts
                  ?.registration_number
              }
              onChange={(value) =>
                updateReceiptSettings('registration_number', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <PosPrintPromotion
              value={
                storefrontSettings?.digital_front?.receipts
                  ?.promotions || ''
              }
              name="printPromotionStorefront"
              label={t(
                'settings.touchpoint_storefront.print_promotion'
              )}
              description={
                storefrontSettings?.digital_front?.receipts
                  ?.promotions?.length > 0
                  ? t(
                      'settings.touchpoint_storefront.print_promotion_not_empty',
                      {
                        promotions: getPromotionNames(
                          storefrontSettings?.digital_front?.receipts
                            ?.promotions
                        ),
                      }
                    )
                  : t(
                      'settings.touchpoint_storefront.print_promotion_description'
                    )
              }
              actionText={
                storefrontSettings?.digital_front?.receipts
                  ?.promotions?.length > 0
                  ? t('settings.update')
                  : t('settings.select')
              }
              onChange={(value) =>
                updateReceiptSettings('promotions', [
                  ...storefrontSettings?.digital_front?.receipts
                    ?.promotions,
                  value,
                ])
              }
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="QrCodeStorefront"
              label={t('settings.touchpoint_storefront.qr_code')}
              description={t(
                'settings.touchpoint_storefront.qr_code_description'
              )}
              value={
                storefrontSettings?.digital_front?.receipts?.barcode
              }
              onChange={(value) =>
                updateReceiptSettings('barcode', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SettingInput
              value={
                storefrontSettings?.digital_front?.receipts
                  ?.custom_message
              }
              name="customMessageStorefront"
              label={t(
                'settings.touchpoint_storefront.custom_message'
              )}
              description={
                storefrontSettings?.digital_front?.receipts
                  ?.custom_message
                  ? t(
                      'settings.touchpoint_storefront.custom_message_description',
                      {
                        message:
                          storefrontSettings?.digital_front?.receipts
                            ?.custom_message,
                      }
                    )
                  : t(
                      'settings.touchpoint_storefront.custom_message_description_empty'
                    )
              }
              descriptionType
              onChange={(value) =>
                updateReceiptSettings('custom_message', value)
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </SettingAccordion>
  );
};

export default TouchpointStorefront;
