import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    boxPlans: {
      backgroundColor: Colors.WHITE,
      padding: theme.spacing(4),
      borderRadius: theme.spacing(1),
    },
    boxLoading: {
      backgroundColor: Colors.WHITE,
      padding: theme.spacing(4),
      borderRadius: theme.spacing(1),
    },
    boxOverview: {
      padding: theme.spacing(6),
      backgroundColor: '#d3e2f3',
    },
    overviewText: {
      ...CommonFonts.darkFont20,
      fontWeight: '700',
      marginLeft: theme.spacing(2),
    },
    title: {
      ...CommonFonts.darkFont16,
      fontWeight: '600',
      marginTop: theme.spacing(2),
    },
    tableText: {
      ...CommonFonts.darkFont18,
    },
    noDataFound: {
      ...CommonFonts.darkFont16,
    },
    headerTableText: {
      ...CommonFonts.darkFont14,
    },
    boxFullName: {
      wordBreak: 'break-word',
      padding: theme.spacing(2.5, 0),
    },
  })
);

export default useStyles;
