import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../constants/colors';
import { CommonFonts } from '../../../constants/fonts';

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonTab: {
      ...CommonFonts.darkFont18,
      fontWeight: 300,
      letterSpacing: 0.56,
      textTransform: 'initial',
      height: 50,
      width: 235,
      boxShadow: 'none',
      marginRight: theme.spacing(10),
      marginBottom: theme.spacing(5),
      '&:hover': {
        boxShadow: 'none',
      },
    },
    buttonTabActive: {
      backgroundColor: Colors.GRAY_BORDER1,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_40,
      },
    },
    buttonTabDeactivated: {
      backgroundColor: Colors.GRAY_BORDER1_20,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_40,
      },
    },
  })
);

export default useStyles;
