import React, { useState } from 'react';

import { Box, Select, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import _ from 'lodash';
import { MappingProperties } from '@/constants/import';
import CreateIcon from '@material-ui/icons/Create';

const MappingSelectPicker = ({
  name,
  onChange,
  value,
  excludingMappingData,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectValue, setSelectValue] = useState(value);

  return (
    <Box>
      <Typography className={classes.title}>
        {t('import.map_to')}
      </Typography>
      <Select
        className={classes.selectInput}
        disableUnderline
        fullWidth
        IconComponent={CreateIcon}
        id={`mapping-${name}-picker`}
        name={`mapping-${name}-picker`}
        value={
          MappingProperties?.find(
            (item) => item.id === selectValue
          ) || {}
        }
        onChange={(event) => {
          onChange(event.target.value.id);
          setSelectValue(event.target.value.id);
        }}
      >
        {MappingProperties &&
          MappingProperties?.filter(
            (item) => !excludingMappingData.includes(item.id)
          )?.map((option, index) => {
            return (
              <MenuItem key={`${option.id}-${index}`} value={option}>
                {option.name}
              </MenuItem>
            );
          })}
      </Select>
    </Box>
  );
};

export default MappingSelectPicker;
