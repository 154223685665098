import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  headerText: {
    ...CommonFonts.darkBoldFont18,
    textAlign: 'center'
  },
  closeButton: {
    padding: theme.spacing(1.5),
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
  dialogContent: {
    height: '70vh',
  },
  cancelButton: {
    ...CommonFonts.grayFont16,
    letterSpacing: '0.11px',
    color: Colors.GRAY_BORDER3,
    opacity: 1,
  },
  rootDialog: {
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 5)
    },
  },
  tabs: {
    maxWidth: '90%',
    float: 'left',
    '& .MuiTabs-flexContainer': {
      borderBottomWidth: 2,
      borderBottomColor: '#E2E2E2',
      borderBottomStyle: 'solid',
    },
  },
  tab: {
    '& .MuiTab-wrapper': {
      textTransform: 'none',
    },
  },
  titleText: {
    ...CommonFonts.darkFont14,
  },
  errorTitleText: {
    color: Colors.RED
  },

  changeDates: {
    textTransform: 'none',
    borderBottom: `2px solid #E2E2E2`,
    borderRadius: 0,
    height: 50
  }
}));

export default useStyles;
