import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, sumBy } from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  TableRow,
  TableCell,
  withStyles,
  Box,
  Typography,
  Button
} from '@material-ui/core'
import ActionMenu from '@/components/actionMenu'

import { selectLocalisation } from '@/store/modules/store/selectors'

import { Colors, CommonFonts } from '@/theme'

import { transformFloat } from '@/utils/localisationUtil'
import { isMoneyLessThanZero } from '@/utils/mathUtil'
import useStyles from './styles'
import { getPaymentStatus } from '../../addUpdateExpense/helper'

const Row = ({ item, onVoidExpense, onUpdateExpense, onAddFiles }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const localisation = useSelector(selectLocalisation);

  const getAmountValue = () => {
    const taxesTotal = sumBy(item.total.taxes, tax => tax.amount)
    const totalAmount = item.total.amount + taxesTotal
    return transformFloat(totalAmount, localisation, { showCurrency: true })
  }

  const rowCells = [
    {
      id: 'date_time',
      label: moment(item.creation.time).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      id: 'payee',
      label: item?.payee?.name,
    },
    {
      id: 'status',
      label: item?.status?.name,
    },
    {
      id: 'amount',
      label: getAmountValue(),
    },
    {
      id: 'payment_status',
      label: getPaymentStatus(item).name,
    },
  ];

  const actionMenus = [
    {
      name: t('expenses.update'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        onUpdateExpense(data)
      },
    },
    {
      name: t('expenses.void'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        onVoidExpense(data)
      },
    },
  ]

  return (
    <TableRow hover style={{ position: 'relative' }}>
      {rowCells.map(rowCell => (
        <StyledTableCell key={rowCell.id}>
          {rowCell.label}
        </StyledTableCell>
      ))}
      <StyledTableCell onClick={() => { }}>
        <Button
          className={classes.addButton}
          color="primary"
          onClick={(event) => onAddFiles(event, item)}
        >
          {isEmpty(item.images)
            ? t('expenses.add')
            : t('expenses.view_add')
          }
        </Button>
      </StyledTableCell>
      <StyledTableCell align='right'>
        <ActionMenu
          data={item}
          actionMenus={actionMenus}
        />
      </StyledTableCell>
    </TableRow>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    ...CommonFonts.darkFont16,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont16
  },
}))(TableCell);

Row.propTypes = {
  item: PropTypes.object,
}

export default Row