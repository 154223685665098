import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import Input from '../components/input'
import ColorPicker from '../components/colorPicker';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { STYLE_OPTION_FIELD_TYPE } from '@/constants/styles'
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

const AddUpdateOptionValue = ({
  open,
  onClose,
  option,
  onAddUpdateValue,
  selectedValue
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [value, setValue] = useState({
    id: uuid(),
    values: option.fields.map(field => ({
      ...field,
      value: ''
    }))
  })

  useEffect(() => {
    if (selectedValue) {
      setValue({
        id: selectedValue.id,
        values: selectedValue.values.map(item => {
          const field = _.find(option.fields, { name: item.name })
          return {
            ...field,
            value: item.value
          }
        })
      })
    }
  }, [selectedValue])

  const updateValue = (newItem) => {
    setValue({
      id: value.id,
      values: value.values.map(item => item.name === newItem.name
        ? { ...newItem }
        : { ...item }
      )
    })
  }

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      onClose={onClose}
      aria-labelledby={`{update-order-type-dialog-title}`}
      open={open}
      disableBackdropClick
    >
      <DialogTitle
        id={`{update-order-type-dialog-title}`}
        onClose={onClose}
      >
        <Typography className={classes.titleDialog}>
          {selectedValue
            ? t('settings.inventory.update')
            : t('settings.inventory.add_new')
          }
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          marginBottom={10}
        >
          {value.values.map((cell, id) => (
            <Box marginTop={5}>
              {cell.type === STYLE_OPTION_FIELD_TYPE.COLOR
                ?
                <ColorPicker
                  label={cell.name}
                  value={cell.value}
                  onChange={(newValue) =>
                    updateValue({ ...cell, value: newValue })
                  }
                />
                :
                <Input
                  key={`value-${id}`}
                  title={cell.name}
                  emptyLabel={t('add_product.add')}
                  value={cell.value}
                  onChange={(newValue) => {
                    updateValue({ ...cell, value: newValue })
                  }}
                  onBlur={() => { }}
                />
              }
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions style={{ paddingRight: 30 }}>
        <Button
          color="primary"
          className={classes.cancelButton}
          onClick={onClose}
        >
          {t('button_group.cancel')}
        </Button>
        <Button
          color="primary"
          disabled={!!_.find(value.values, { value: '' })}
          onClick={() => {
            onAddUpdateValue(value)
            onClose()
          }}
        >
          {t('button_group.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

export default AddUpdateOptionValue;
