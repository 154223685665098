import React from 'react'
import PropTypes from 'prop-types'

import { Dialog, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import useStyles from './styles'

const FullScreenModal = (props) => {
  const classes = useStyles()

  const { open, onClose, children } = props

  return (
    <Dialog
      fullScreen
      classes={{
        root: classes.root,
        paperWidthSm: classes.paperWidthSm,
        paper: classes.paper
      }}
      open={open}
      onClose={onClose}
    >
      <IconButton onClick={onClose} className={classes.closeButton}>
        <CloseIcon fontSize='small' />
      </IconButton>
      {children}
    </Dialog>
  )
}

FullScreenModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export default FullScreenModal