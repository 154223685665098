import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames';

import { Box, Grid, Typography } from '@material-ui/core'
import FullScreenModal from '@/components/fullScreenModal'
import RunReport from './runReport'
import AddUpdateProduct from '@/pages/businessManager/products/AddUpdateProduct'
import AddStock from './addStock'
import ReplenishStock from './replenishStock'
import AddUpdateExpense from '@/pages/businessManager/expenses/addUpdateExpense'
import AddUpdatePromotion from '@/pages/businessManager/promotions/addUpdatePromotion'
import AddCustomerDialog from '@/pages/businessManager/customers/AddCustomer';
import { TUTORIAL_TARGET_CLASSES } from '@/components/joyRider/helper'

import images from '@/assets/images';
import useStyles from './styles';

const QuickAccess = () => {
  const classes = useStyles();
  const { t } = useTranslation()

  const [openRunReport, setOpenRunReport] = useState(false)
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const [openAddStock, setOpenAddStock] = useState(false)
  const [openReplenishStock, setOpenReplenishStock] = useState(false)
  const [openAddExpense, setOpenAddExpense] = useState(false)
  const [openAddPromotion, setOpenAddPromotion] = useState(false)
  const [openAddCustomer, setOpenAddCustomer] = useState(false)

  const QUICK_ACCESSES = [
    {
      label: t('businessManager.run_report'),
      icon: images.runReport,
      onClick: () => setOpenRunReport(true),
      disabled: false,
      className: TUTORIAL_TARGET_CLASSES.RUN_REPORT,
    },
    {
      label: t('businessManager.add_order'),
      icon: images.addOrder,
      disabled: true
    },
    {
      label: t('businessManager.add_expense'),
      icon: images.addExpense,
      onClick: () => setOpenAddExpense(true),
      disabled: false
    },
    {
      label: t('businessManager.add_product'),
      icon: images.addProduct,
      onClick: () => setOpenAddProduct(true),
      disabled: false
    },
    {
      label: t('businessManager.add_stock'),
      icon: images.addStock,
      onClick: () => setOpenAddStock(true),
      disabled: false
    },
    {
      label: t('businessManager.add_promotion'),
      icon: images.addPromotion,
      onClick: () => setOpenAddPromotion(true),
      disabled: false
    },
    {
      label: t('businessManager.add_customer'),
      icon: images.addCustomer,
      onClick: () => setOpenAddCustomer(true),
      disabled: false
    },
    {
      label: t('businessManager.replenish_stock'),
      icon: images.replenish,
      onClick: () => setOpenReplenishStock(true),
      disabled: false
    }
  ];

  return (
    <Box className={classes.root}>
      <Typography className={classes.titleText}>
        {t('workboard.quick_access.quick_access')}
      </Typography>

      <Box
        display='flex'
        className={classes.accessesContainer}
      >
        {QUICK_ACCESSES.map((item, id) => (
          <Box
            key={id}
            className={classNames(
              classes.accessContainer,
              item.className,
              {
                [classes.disabled]: item.disabled,
              }
            )}
            onClick={() => {
              if (!item.disabled) {
                item.onClick()
              }
            }}
          >
            <Grid
              container
              direction="row-reverse"
              alignItems='baseline'
            >
              <img
                width={40}
                height={40}
                src={item.icon}
                alt={'...'}
                className={classes.image}
              />
              <Typography className={classes.labelText}>
                {item.label}
              </Typography>
            </Grid>
          </Box>
        ))}
      </Box>

      {openRunReport &&
        <FullScreenModal
          open={openRunReport}
          onClose={() => setOpenRunReport(false)}
        >
          <RunReport />
        </FullScreenModal>
      }

      {openAddProduct && (
        <AddUpdateProduct
          open={openAddProduct}
          handleClose={() => setOpenAddProduct(false)}
          onSuccess={() => { }}
        />
      )}

      {openAddStock && (
        <AddStock
          open={openAddStock}
          handleClose={() => setOpenAddStock(false)}
        />
      )}
      {openReplenishStock && (
        <ReplenishStock
          open={openReplenishStock}
          handleClose={() => setOpenReplenishStock(false)}
        />
      )}
      {openAddExpense && (
        <AddUpdateExpense
          open={openAddExpense}
          handleClose={() => setOpenAddExpense(false)}
          onSuccess={() => { }}
        />
      )}
      {openAddPromotion && (
        <AddUpdatePromotion
          open={openAddPromotion}
          handleClose={() => setOpenAddPromotion(false)}
          onSuccess={() => { }}
        />
      )}
      {openAddCustomer && (
        <AddCustomerDialog
          open={openAddCustomer}
          handleClose={() => setOpenAddCustomer(false)}
          setRefetchCustomers={() => { }}
        />
      )}
    </Box>
  )
}

export default QuickAccess