import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
    },
    titleText: {
      ...CommonFonts.grayBoldFont20,
      color: Colors.DIM_GREY,
      fontWeight: 500
    },
    accessesContainer: {
      paddingLeft: theme.spacing(5),
      flexWrap: 'wrap',
    },
    accessContainer: {
      marginTop: theme.spacing(10),
      marginRight: '6vw',
      cursor: 'pointer'
    },
    labelText: {
      textAlign: 'center',
      maxWidth: 80,
      marginTop: theme.spacing(4)
    }
  })
)

export default useStyles
