import { v4 as uuid } from 'uuid';
import moment from 'moment'
import {
  isEmpty,
  sumBy,
  find,
  groupBy,
  isEqual
} from 'lodash'
import { calculateTaxesSumma } from '@/utils/taxUtil'
import { calcGrossPriceFromPrice } from '@/utils/priceUtil'
import { roundToFour, roundToTwo, isMoneyLessThanZero } from '@/utils/mathUtil'
import {
  EXPENSE_TAX_STATUS,
  EXPENSE_PAYMENT_STATUSES,
  EXPENSE_STATE
} from '../helper'

export const getTransformedExpense = (expense) => {
  return {
    id: uuid(),
    expense_id: expense.id,
    payee: expense.payee,
    order_no: expense.order_no,
    bill_date: moment(expense.bill_date),
    due_date: moment(expense.due_date),
    expense_type: expense.expense_type,
    status: expense.status,
    tax_status: EXPENSE_TAX_STATUS.EXCLUSIVE_TAX,
    items: expense.items.map(item => ({
      ...item,
      grossAmount: calcGrossPriceFromPrice(item.amount, item.taxes)
    })),
    payments: expense.payments,
    attachments: expense.attachments
  }
}
export const initExpenseItem = (order) => {
  return {
    item_id: uuid(),
    account_id: '',
    amount: '',
    grossAmount: '',
    bar_code: '',
    creation: {
      time: new Date(),
    },
    inventory_id: '',
    order,
    product_code: '',
    product_name: '',
    quantity: '',
    taxes: []
  }
}

export const getSubTotal = (items) => {
  return items.reduce((total, item) => total + parseInt(item.quantity || '0') * roundToFour(parseFloat(item.amount || '0')), 0)
}

export const getTaxTotal = (items, taxStatus) => {
  var taxTotal = {}
  let taxes = []
  let taxesSumma = 0
  items.forEach(item => {
    taxes = taxStatus === EXPENSE_TAX_STATUS.NO_TAX ? [] : item.taxes
    if (!isEmpty(taxes)) {
      taxesSumma = parseInt(item.quantity || '0') * calculateTaxesSumma(parseFloat(item.amount || '0'), taxes)
      taxTotal = {
        ...taxTotal,
        [taxes[0].name]: (taxTotal?.[taxes[0].name] || 0) + taxesSumma
      }
    }
  })
  return taxTotal
}

export const getTotal = (items, taxStatus) => {
  const subTotal = getSubTotal(items)
  const taxesObject = getTaxTotal(items, taxStatus)
  const taxTotal = Object.keys(taxesObject).reduce((total, key) => total + taxesObject[key], 0)
  return subTotal + roundToTwo(taxTotal)
}

export const getPaymentSumma = (expense) => {
  return sumBy(expense.payments, payment => payment.amount)
}

export const getPaymentDue = (expense) => {
  const total = getTotal(expense.items, expense.tax_status)
  const paymentSumma = getPaymentSumma(expense)
  return total - paymentSumma
}

export const getPaymentStatus = (expense) => {
  const paymentSumma = getPaymentSumma(expense)
  const paymentDue = getPaymentDue(expense)
  if (paymentSumma === 0) {
    return find(EXPENSE_PAYMENT_STATUSES, { state: 'unpaid' })
  }
  if (!isMoneyLessThanZero(Math.abs(paymentDue))) {
    return find(EXPENSE_PAYMENT_STATUSES, { state: 'part-paid' })
  }
  if (isMoneyLessThanZero(Math.abs(paymentDue))) {
    return find(EXPENSE_PAYMENT_STATUSES, { state: 'paid' })
  }
}

export const getExpenseState = (expense) => {
  const paymentStatus = getPaymentStatus(expense)
  if (paymentStatus.state === 'unpaid') {
    return EXPENSE_STATE.PENDING
  }
  if (paymentStatus.state === 'part-paid') {
    return EXPENSE_STATE.ACCEPTED
  }
  if (paymentStatus.state === 'paid') {
    return EXPENSE_STATE.COMPLETED
  }
}

export const getTotalTaxArrayForExpenseMutation = (items) => {
  let tax_arr = []
  items.forEach(item => {
    const temp_tax_arr = item.taxes.map(tax => ({
      id: tax.id,
      name: tax.name,
      rate: tax.rate,
      amount: roundToFour(calculateTaxesSumma(item.amount, [tax])) * parseInt(item.quantity)
    }))
    tax_arr = tax_arr.concat(temp_tax_arr)
  })

  let taxesById = groupBy(tax_arr, tax => tax.id)
  taxesById = Object.keys(taxesById).map(taxId => {
    let taxes = taxesById[taxId]
    let sumOfAmount = sumBy(taxes, tax => tax.amount)
    return {
      ...taxes[0],
      amount: roundToFour(sumOfAmount),
    }
  })

  return taxesById
}

export const validateItems = (items) => {
  if (isEmpty(items)) return false
  for (var i = 0; i < items.length; i++) {
    if (!items[i]?.amount ||
      !items[i]?.account ||
      !items[i]?.quantity ||
      !items[i]?.bar_code
    ) return false
  }
  return true
}

export const getCurrentItems = (expenseData) => {
  return expenseData.items.map(item => ({
    account_id: item.account?.id,
    amount: roundToFour(parseFloat(item.amount)),
    creation: {
      time: item?.creation?.time || moment().format()
    },
    item_id: item.item_id,
    inventory_id: item.inventory_id,
    bar_code: item.bar_code,
    order: item.order,
    product_code: item.product_code,
    product_name: item.product_name,
    quantity: parseFloat(item.quantity),
    taxes: expenseData.tax_status === EXPENSE_TAX_STATUS.NO_TAX
      ? []
      : item.taxes.map(tax => ({
        id: tax.id,
        name: tax.name,
        rate: tax.rate,
        amount: roundToFour(calculateTaxesSumma(item.amount, [tax]))
      }))
  }))
}

export const getOriginalItems = (expenseData) => {
  return (expenseData?.items || []).map(item => ({
    account_id: item.account?.id,
    amount: roundToFour(parseFloat(item.amount)),
    creation: {
      time: item?.creation?.time
    },
    item_id: item.item_id,
    inventory_id: item.inventory_id,
    bar_code: item.bar_code,
    order: item.order,
    product_code: item.product_code,
    product_name: item.product_name,
    quantity: parseFloat(item.quantity),
    taxes: item.taxes.map(tax => ({
      id: tax.id,
      name: tax.name,
      rate: tax.rate,
      amount: roundToFour(calculateTaxesSumma(item.amount, [tax]))
    }))
  }))
}

export const getItemsForMutation = (currentItems, originalItems) => {// remove duplicated items and get only changes
  let items1 = currentItems.map(item => ({ ...item, is_same: false }))
  let items2 = originalItems.map(item => ({
    ...item,
    quantity: item.quantity * -1,
    is_same: false
  }))
  for (let i = 0; i < items1.length; i++) {
    for (let j = 0; j < items2.length; j++) {
      if (isEqual(currentItems[i], originalItems[j]) && !items1[i].is_same && !items2[j].is_same) {
        items1[i] = { ...items1[i], is_same: true }
        items2[j] = { ...items2[j], is_same: true }
        break
      }
    }
  }
  items1 = items1.filter(item => !item.is_same)
  items2 = items2.filter(item => !item.is_same)
  let items = items1.concat(items2)
  items = items.map(item => _.omit(item, 'is_same'))
  return items
}

export const generateVariables = (expenseData, items, status, payment) => ({
  input: {
    id: expenseData.id,
    expense_id: expenseData.expense_id || expenseData.id,
    bill_date: moment(expenseData.bill_date),
    due_date: moment(expenseData.due_date),
    creation: {
      time: moment().format()
    },
    expense_type: {
      id: expenseData.expense_type.id,
      name: expenseData.expense_type.name,
      type: expenseData.expense_type.type,
    },
    attachments: expenseData.attachments
      .filter(item => !!item.flag)
      .map(item => ({
        id: item.id,
        flag: item.flag
      })),
    items,
    order_no: expenseData.order_no,
    payee: {
      id: expenseData.payee.id,
      name: expenseData.payee.name,
      type: expenseData.payee.type
    },
    payments: payment || [],
    state: getExpenseState(expenseData),
    statuses: {
      id: status?.id,
      name: status?.name,
      order: status?.order
    },
    total: {
      amount: getSubTotal(expenseData.items),
      taxes: expenseData.tax_status === EXPENSE_TAX_STATUS.NO_TAX
        ? []
        : getTotalTaxArrayForExpenseMutation(expenseData.items)
    }
  }
})

export const getAccountsPaidTo = (accountTypes) => {
  const assetsChildrentypes = find(accountTypes, { name: 'ASSETS' })?.children_types
  var accounts = []
  assetsChildrentypes
    ?.filter(childrenType => ['10', '11'].includes(childrenType.id)) // 10: Petty Cash, 11: Bank
    ?.forEach(childrenType => {
      accounts = accounts.concat(childrenType.accounts)
    })
  return accounts
}