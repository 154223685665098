import React from 'react';
import {
  BUSINESS_TYPE_LIST,
  MUTATE_BUSINESS_TYPE,
  MUTATE_CREATE_BUSINESS_TYPE,
  STORE_DETAIL,
} from '@/services/storeService';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

const businessTypesQuery = gql`
  ${BUSINESS_TYPE_LIST}
`;

const storeDetailQuery = gql`
  ${STORE_DETAIL}
`;

const useBusinessPicker = () => {
  const {
    store: { selectedStore },
  } = useSelector((state) => state);

  const query = useQuery(businessTypesQuery);

  const [createBusinessType] = useMutation(
    gql`
      ${MUTATE_CREATE_BUSINESS_TYPE}
    `
  );

  const [mutateBusinessType] = useMutation(
    gql`
      ${MUTATE_BUSINESS_TYPE}
    `
  );

  const updateBusinessType = (value) => {
    const { id, name } = value;
    const payload = {
      variables: {
        merchant: {
          id: selectedStore?.merchant_id,
          business_type_id: id,
          business_type: name,
        },
      },
      update: (store, { data: { updateMerchant } }) => {
        const cache = store.readQuery({
          query: storeDetailQuery,
        });

        const currentValue = {
          updateMerchant: {
            business_type: name,
            business_type_id: id,
            id: selectedStore?.merchant_id,
            __typename: 'Merchant',
          },
        };

        const updatedData = {
          ...cache,
          merchant: {
            ...cache?.merchant,
            ...currentValue.updateMerchant,
          },
        };
        store.writeQuery({
          query: storeDetailQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateMerchant: {
          business_type: name,
          business_type_id: id,
          id: selectedStore?.merchant_id,
          __typename: 'Merchant',
        },
      },
    };
    return mutateBusinessType(payload);
  };

  const addBusinessType = (value) => {
    const payload = {
      variables: {
        input: {
          parent: '1',
          name: value,
        },
      },
      refetchQueries: [
        {
          query: businessTypesQuery,
        },
      ],
    };
    return createBusinessType(payload);
  };

  const addBusinessTypeNoStores = (value) => {
    const payload = {
      context: {
        headers: { storeId: null, merchantId: null },
      },
      variables: {
        input: {
          // parent: '1',
          name: value,
        },
      },
      refetchQueries: [
        {
          query: businessTypesQuery,
        },
      ],
    };
    return createBusinessType(payload);
  };

  return {
    query,
    updateBusinessType,
    addBusinessType,
    addBusinessTypeNoStores,
  };
};

export default useBusinessPicker;
