import { gql } from '@apollo/client';

export const GET_PROMOTIONS = gql`
  query getPromotions($filter: Filter){
    promotions(filter: $filter){
      id
      promo_no
      store_id
      audience_id
      user_id
      type
      item_numbers
      promo_name
      valid_from
      valid_to
      start_time
      end_time
      promo_value
      order_type
      trigger_amount
      fixed_price
      active
      mix_match
      options{
        id
        group_name
        products{
          id
          name
          bar_code
          prices {
            price_infos {
              price_type {
                type,
                id,
                name,
                description,
                default,
              }
              price
              taxes{
                id
                name
                rate
              }
            }
          }
          stocks{
            current_stock
          }
        }
        categories{
          id
          name
          parent
          productCount
        }
      }
    }
  }
`

export const ADD_PROMOTION = gql`
  mutation($promotion: PromotionInput!) {
    addPromotion(input: $promotion) {
      id
      promo_no
      store_id
      audience_id
      user_id
      type
      item_numbers
      promo_name
      valid_from
      valid_to
      start_time
      end_time
      promo_value
      order_type
      trigger_amount
      fixed_price
      active
      mix_match
      options{
        id
        group_name
        products{
          id
          name
          bar_code
          prices {
            price_infos {
              price_type {
                type,
                id,
                name,
                description,
                default,
              }
              price
              taxes{
                id
                name
                rate
              }
            }
          }
          stocks{
            current_stock
          }
        }
        categories{
          id
          name
          parent
          productCount
        }
      }
    }
  }
`

// Delete promotion
export const DELETE_PROMOTION = gql`
  mutation($promotion: PromotionInput!) {
    addPromotion(input: $promotion) {
      id
    }
  }
`