export const MappingProperties = [
  {
    id: 'do_not_import',
    name: 'Do not import',
    match: ['Do not import'],
  },
  {
    id: 'name',
    name: 'Product name',
    match: ['name', 'product name', 'Product name', 'Name'],
  },
  { id: 'barcode', name: 'Barcode', match: ['barcode', 'Barcode'] },
  { id: 'price', name: 'Price', match: ['price', 'Price'] },
  {
    id: 'description',
    name: 'Description',
    match: ['description', 'Description'],
  },
  { id: 'image', name: 'Image', match: ['image', 'Image'] },
  {
    id: 'tax_rate',
    name: 'Tax rate',
    match: ['tax_rate', 'Tax rate', 'tax_id', 'Tax_id'],
  },
  {
    id: 'category',
    name: 'Category',
    match: ['category', 'Category', 'category_id', 'Category_id'],
  },
  { id: 'stock', name: 'Stock', match: ['stock', 'Stock'] },
  { id: 'brand', name: 'Brand', match: ['brand', 'Brand'] },
  {
    id: 'short_description',
    name: 'Short description',
    match: ['short_description', 'Short description'],
  },
];

export const ImportAcceptType = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];
