import React, { useState } from 'react';

import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Input from '@/pages/businessManager/products/addUpdateProduct/components/input';
import Select from '@/pages/businessManager/products/addUpdateProduct/components/selector';
import useStyles from './styles';
import _ from 'lodash';

const AreaInput = ({ area, floors, onChangeArea }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.boxArea} marginY={5}>
      <Grid container spacing={10}>
        <Grid item xs={12} md={3}>
          <Input
            name="area"
            title={t('modules.floor_and_shelf.area')}
            emptyLabel={t('settings.add')}
            value={area.name}
            onChange={(value) =>
              onChangeArea({ key: 'name', value: value })
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            name="floor"
            title={t('modules.floor_and_shelf.floor')}
            emptyLabel={t('settings.select')}
            items={floors}
            getItemLabel={(item) => item.name}
            selectedItem={
              floors?.find((floor) =>
                _.isEqual(area.floor.id, floor.id)
              ) || {}
            }
            onChangeValue={(value) =>
              onChangeArea({ key: 'floor', value: value })
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AreaInput;
