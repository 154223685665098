import React from 'react'
import { useTranslation } from 'react-i18next'
import { find, isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import {
  TableRow,
  TableCell,
  withStyles,
  Typography,
  Box
} from '@material-ui/core'

import { Colors, CommonFonts } from '@/theme'
import { STYLE_OPTION_FIELD_DISPLAY, STYLE_OPTION_FIELD_TYPE } from '@/constants/styles'

import useStyles from './styles'

const Row = ({ item, options, onDeleteVariant }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const actionWidth = 15
  const variantNameCellWidth = 20
  const otherWidth = 100 - actionWidth - variantNameCellWidth

  const rowCells = options.map(option => ({
    id: option.id,
    option,
    width: `${otherWidth / options.length}%`
  }))

  const renderRowCell = (option) => {
    let variantValue
    option.values.forEach(value => {
      if (find(item.values, { id: value.id })) {
        variantValue = value
      }
    })

    const mainField = find(option.fields, { display: STYLE_OPTION_FIELD_DISPLAY.MAIN })
    const afterField = find(option.fields, { display: STYLE_OPTION_FIELD_DISPLAY.AFTER })
    const extraFields = option.fields.filter(field => field.display === STYLE_OPTION_FIELD_DISPLAY.EXTRA)

    const mainFieldValue = find(variantValue.values, { name: mainField.name })?.value
    const afterFieldValue = find(variantValue.values, { name: afterField?.name })?.value
    const extraFieldsValues = extraFields.map(field => (
      find(variantValue.values, { name: field.name })
    ))

    return (
      <Box>
        <Typography className={classes.smallText}>
          {option.name}
        </Typography>
        <Box display='flex' alignItems='center'>
          {mainField.type === STYLE_OPTION_FIELD_TYPE.COLOR
            ?
            <Box
              style={{
                width: 20,
                height: 20,
                borderRadius: 4,
                backgroundColor: mainFieldValue
              }}
            />
            :
            <Typography className={classes.normalText}>
              {mainFieldValue}
            </Typography>
          }
          {!!afterField &&
            <Typography
              className={classes.normalText}
              style={{ marginLeft: 15 }}
            >
              {afterFieldValue}
            </Typography>
          }
          {!isEmpty(extraFields) &&
            <Typography
              className={classes.normalText}
              style={{ marginLeft: 15 }}
            >
              {`(${extraFieldsValues.map(value => `${value.name}: ${value.value}`).join(', ')})`}
            </Typography>
          }
        </Box>
      </Box>
    )
  }

  return (
    <TableRow hover style={{ position: 'relative' }}>
      <StyledTableCell
        style={{ width: `${variantNameCellWidth}%` }}
      >
        <Typography className={classes.normalText}>
          {item.name}
        </Typography>
        <Typography className={classes.smallText}>
          {item.code}
        </Typography>
      </StyledTableCell>

      {rowCells.map(rowCell => (
        <StyledTableCell
          key={rowCell.id}
          style={{ width: `${rowCell.width}%` }}
        >
          {renderRowCell(rowCell.option)}
        </StyledTableCell>
      ))}

      <StyledTableCell
        style={{ width: `${actionWidth}%` }}
        align='right'
      >
        <Typography
          className={classes.configureText}
          onClick={() => onDeleteVariant(item)}
        >
          {t('button_group.remove')}
        </Typography>
      </StyledTableCell>
    </TableRow>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    ...CommonFonts.darkFont16,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont16
  },
}))(TableCell);

Row.propTypes = {
  item: PropTypes.object,
}

export default Row