import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCurrentUser } from '@/store/modules/auth';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import images from '@/assets/images';
import AddressesAutocomplete from '@/components/addressAutocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const StoresLayout = ({
  onSelectStore,
  setActiveStep,
  setAddressObject,
  setBusinessName,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const matchesSmallScreen = useMediaQuery('(min-width:1280px)');

  const checkForMissingValues = (values) => {
    if (
      values.door_number === undefined ||
      values.line1 === undefined ||
      values.city_town === undefined ||
      values.postcode === undefined
    ) {
      //send user to the full address form
      setAddressObject(values);
      setActiveStep(0.5);
    } else {
      //update address object and conitnue flow normally
      setAddressObject(values);
      setActiveStep(1);
    }
  };

  return (
    <Grid
      container
      className={classes.searchStoreRoot}
      // spacing={10}
      justify="space-between"
    >
      {matchesSmallScreen && (
        <Grid item lg={4}>
          <img src={images['mobileImage']} width="260px" height="400px" />
        </Grid>
      )}

      <Grid item md={12} lg={8}>
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item>
                <img
                  src={images['locationAddStore']}
                  width="149px"
                  height="132px"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 50 }}>
            <Grid container justify="center" spacing={10}>
              <Grid item>
                <div className={classes.findYourBussinessTitle}>
                  {t('addStore.find_manage_your_bussiness')}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 30 }}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <AddressesAutocomplete
                  icon="search"
                  getFullAdress={onSelectStore}
                  getValues={(value) => checkForMissingValues(value)}
                  setBusinessName={(name) => setBusinessName(name)}
                  // noOptionsButton={() => {
                  //   setActiveStep(0.5);
                  // }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 60 }}>
            <Grid container justify="center">
              <Grid item>
                <div className={classes.cantFindStore}>
                  {t('addStore.cant_find_store')}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <Grid container justify="center">
              <Grid item>
                <div
                  className={classes.addStoreLink}
                  onClick={() => setActiveStep(2)}
                >
                  {t('addStore.add_store_manually')}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    // </Container>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

export default connect(mapStateToProps)(StoresLayout);
