import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { some } from 'lodash'

import {
  Box,
  Typography,
  Grid,
  IconButton,
  Button
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import Input from '../components/input'
import DateTimePicker from '@/components/dateTimePicker'
import TimePicker from '@/components/muiTimePicker'
import SingleCheckboxInput from '@/components/singleCheckbox';

import { selectLocalisation, selectNetPrice } from '@/store/modules/store/selectors'
import { transformFloat, getLocalisationVal, transformInput } from '@/utils/localisationUtil'
import { calculatePrice } from '@/utils/priceUtil'
import { capitalizeFirstLetter } from '@/utils/stringUtil'

import useStyles from '../styles'

const Main = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    promotion,
    defaultPromotion,
    onChangePromotion,
    onAddUpdatePromotion,
    handleClose
  } = props

  const localisation = useSelector(selectLocalisation);
  const netPrice = useSelector(selectNetPrice)

  const product = promotion.options[0].products[0]
  const priceInfos = product.prices?.[0]?.price_infos || []

  const onChangeProducts = (optionIndex, products) => {
    onChangePromotion({
      ...promotion,
      options: promotion.options.map((option, index) => index === optionIndex
        ? { ...option, products }
        : option
      )
    })
  }

  const onRemoveProduct = () => {
    onChangeProducts(0, [])
  }

  const getPrice = (priceInfo) => {
    const price = calculatePrice(priceInfo.price, priceInfo.taxes, { includeTax: !netPrice })
    return transformFloat(price, localisation, {
      showCurrency: true,
    })
  }

  const passValidations = () => {
    if (+promotion.reduced_price <= 0) return false
    const priceInfos = promotion.options?.[0]?.products[0]?.prices?.[0]?.price_infos || []
    const prices = priceInfos.map(priceInfo => {
      return calculatePrice(priceInfo.price, priceInfo.taxes, { includeTax: !netPrice })
    })
    if (some(prices, price => +promotion.reduced_price > price)) return false
    return true
  }

  return (
    <Box>
      <Typography className={classes.subTitleText} align='center'>
        {t('promotions.select_valid_from_to')}
      </Typography>
      <Box className={classes.productContainer}>
        <Box
          margin='auto'
          maxWidth={300}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box>
            <Typography className={classes.productDescriptionText}>
              {product.name}
            </Typography>
            <Typography className={classes.productDescriptionText}>
              {`${t('workboard.add_stock.barcode')}: ${product.bar_code}`}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={onRemoveProduct} className={classes.removeButton}>
              <CloseIcon fontSize='default' />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box marginTop={5}>
        {priceInfos.map((priceInfo, index) => (
          <Box marginTop={2}>
            <Typography
              key={`priceinfo-${index}`}
              align='center'
              className={classes.titleText}
              align='center'
            >
              {`${capitalizeFirstLetter(priceInfo.price_type.name)} Price: ${getPrice(priceInfo)}`}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box marginTop={5}>
        <Input
          isCurrencyInput={localisation.currency_decimal}
          localisation={localisation}
          title={t('promotions.reduced_price')}
          emptyLabel={t('add_product.add')}
          value={getLocalisationVal(localisation, promotion.reduced_price)}
          onChange={(value) => {
            onChangePromotion({
              ...promotion,
              reduced_price: transformInput(localisation, value)
            })
          }}
          onBlur={() => { }}
        />
      </Box>

      <Box marginTop={5}>
        <DateTimePicker
          label={t('promotions.valid_from')}
          labelClass={classes.subTitleText}
          ampm={false}
          format='dd MMM yyyy hh:mm'
          value={promotion.valid_from}
          onChange={(value) => {
            onChangePromotion({
              ...promotion,
              valid_from: value
            })
          }}
        />
      </Box>
      <Box marginTop={5} position={'relative'}>
        <DateTimePicker
          label={t('promotions.valid_to')}
          labelClass={classes.subTitleText}
          ampm={false}
          format='dd MMM yyyy hh:mm'
          value={promotion.valid_to}
          emptyLabel={t('promotions.until_further_notice')}
          onChange={(value) => {
            onChangePromotion({
              ...promotion,
              valid_to: value
            })
          }}
        />
        {promotion.valid_to &&
          <Box
            className={classes.furtherNotice}
            onClick={() => {
              onChangePromotion({
                ...promotion,
                valid_to: null
              })
            }}
          >
            <Typography className={classes.furtherNoticeText}>
              {t('promotions.until_further_notice')}
            </Typography>
          </Box>
        }
      </Box>
      <Box marginTop={12}>
        <SingleCheckboxInput
          style={{ paddingBottom: 0 }}
          isShowToast={false}
          value={promotion.add_happy_hour}
          label={t('promotions.add_happy_hour')}
          labelClass={classes.label}
          onChange={(value) => {
            onChangePromotion({
              ...promotion,
              add_happy_hour: value
            })
          }}
        />
      </Box>
      {promotion.add_happy_hour &&
        <Box marginTop={8} display='flex' justifyContent={'space-between'}>
          <Box width={'45%'}>
            <TimePicker
              label={t('promotions.start_time')}
              labelClass={classes.subTitleText}
              ampm={false}
              value={promotion.start_time}
              onChange={(value) => {
                onChangePromotion({
                  ...promotion,
                  start_time: value
                })
              }}
            />
          </Box>
          <Box width={'45%'}>
            <TimePicker
              label={t('promotions.end_time')}
              labelClass={classes.subTitleText}
              ampm={false}
              value={promotion.end_time}
              onChange={(value) => {
                onChangePromotion({
                  ...promotion,
                  end_time: value
                })
              }}
            />
          </Box>
        </Box>
      }
      <Box display='flex' justifyContent='flex-end' marginTop={12}>
        <Box display='flex'>
          <Button onClick={handleClose}>
            {t('button_group.cancel')}
          </Button>
          <Button
            onClick={onAddUpdatePromotion}
            type="submit"
            color="primary"
            disabled={!passValidations()}
          >
            {defaultPromotion ? t('button_group.update') : t('button_group.add')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Main