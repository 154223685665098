import React from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@material-ui/core'
import OptionsTable from './table'

const OptionsList = ({ options, isLoading, onClickConfigure }) => {
  const { t } = useTranslation();
  
  return (
    <Box>
      <OptionsTable
        options={options}
        isLoading={isLoading}
        onClickConfigure={onClickConfigure}
      />
      <Box marginTop={10} display='flex' justifyContent='center'>
        <Button
          color="primary"
          disabled
        >
          {t('button_group.add')}
        </Button>
      </Box>
    </Box>
  )
}

export default OptionsList