import React from 'react'
import { useTranslation } from 'react-i18next';

import {
  TableHead,
  TableRow,
  TableCell,
  withStyles
} from '@material-ui/core'

import { CommonFonts } from '@/theme'

const TableHeader = () => {
  const { t } = useTranslation()

  const headCells = [
    {
      id: 'first_name',
      label: t('userManagement.applications.first_name'),
      width: '25%',
    },
    {
      id: 'last_name',
      label: t('userManagement.applications.last_name'),
      width: '25%',
    },
    {
      id: 'mobile',
      label: t('userManagement.applications.mobile'),
      width: '30%',
    },
    {
      id: 'empty',
      label: '',
      width: '20%',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            style={{
              width: headCell.width,
              borderBottom: 'none'
            }}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
    ...CommonFonts.darkFont14,
    fontWeight: 500
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);


export default TableHeader