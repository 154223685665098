import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  title: {
    ...CommonFonts.darkFont14,
    fontWeight: 500,
  },
  addNewLocation: {
    ...CommonFonts.darkFont14,
    fontWeight: '500',
    color: '#1174F2',
    cursor: 'pointer',
    marginTop: theme.spacing(2.5),
  },
  redirectModule: {
    ...CommonFonts.darkFont14,
  },
  redirectModuleLink: {
    ...CommonFonts.darkFont14,
    color: '#1174F2',
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
  },
  popper: {
    backgroundColor: Colors.RED,
    borderRadius: theme.spacing(1),
    backgroundColor: '#EBF4FF'
  },
  tooltip: {
    backgroundColor: 'transparent',
    color: Colors.GRAY_DARK
  }
}));

export default useStyles;
