export const Directions = [
  { name: 'users', path: '/user-management/users' },
  { name: 'applications', path: '/user-management/applications' },
  { name: 'archivedUsers', path: '/user-management/archived-users' },
  {
    name: 'manageTeams',
    path: '/user-management/teams',
  },
];

export const UsersManagementBreadcrumbs = [
  { name: 'users', path: '/user-management/users' },
  { name: 'applications', path: '/user-management/applications' },
  { name: 'archived', path: '/user-management/archived-users' },
  {
    name: 'teams',
    path: '/user-management/teams',
  },
];
