import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { TableRow, TableCell, withStyles } from '@material-ui/core';
import ActionMenu from '@/components/actionMenu';

import { Colors, CommonFonts } from '@/theme';

const Row = ({ item, onViewInfo, onArchive }) => {
  const { t } = useTranslation();

  const rowCells = [
    {
      id: 'customer_first_name',
      label: item.first_name,
    },
    {
      id: 'customer_last_name',
      label: item.last_name,
    },
    {
      id: 'customer_email',
      label: item.email,
    },
    {
      id: 'customer_mobile',
      label: item.mobile,
    },
    {
      id: 'customer_segment',
      label: item.audiences.map((item) => item.name).join(', '),
    },
  ];

  const actionMenus = [
    {
      name: t('customers.view'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        onViewInfo(data);
      },
    },
    {
      name: t('customers.archive'),
      color: Colors.RED,
      onClick: (data) => {
        onArchive(data);
      },
    },
  ];

  return (
    <TableRow hover style={{ position: 'relative' }}>
      {rowCells.map((rowCell) => (
        <StyledTableCell key={rowCell.id}>
          {rowCell.label}
        </StyledTableCell>
      ))}
      <StyledTableCell align="right">
        <ActionMenu data={item} actionMenus={actionMenus} />
      </StyledTableCell>
    </TableRow>
  );
};

const StyledTableCell = withStyles(() => ({
  root: {
    ...CommonFonts.darkFont16,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25,
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont16,
  },
}))(TableCell);

Row.propTypes = {
  item: PropTypes.object,
};

export default Row;
