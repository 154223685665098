import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  boldLabel: {
    ...CommonFonts.darkFont14,
    fontWeight: 500
  },
  boldLabelContainer: {
    margin: theme.spacing(7, 0, 5)
  },
  nextButton: {
    height: 54,
    textTransform: 'none',
    fontSize: 14,
    color: Colors.WHITE,
    '&:active': {
      backgroundColor: Colors.BLUE_DARK,
    },
    '&:hover': {
      backgroundColor: Colors.BLUE,
    },
    '&:disabled': {
      backgroundColor: Colors.DISABLED,
      color: Colors.WHITE,
    },
  },
  measuresGroup: {
    flexDirection: 'row',
  },
  priceInfoContainer: {
    marginBottom: theme.spacing(5)
  }
}));

export default useStyles;
