import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  TableRow,
  Avatar,
  TableCell,
  withStyles,
  Checkbox,
} from '@material-ui/core';
import { some } from 'lodash';
import { Colors, CommonFonts } from '@/theme';
import images from '@/assets/images';
import moment from 'moment';

const GreenCheckbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Row = ({ item, changedData }) => {
  const [pos, setPos] = useState(false);
  const [kiosk, setKiosk] = useState(false);
  const [digital, setDigital] = useState(false);

  useEffect(() => {
    setPos(some(item.touchpoints, ['type', 'pos']));
    setKiosk(some(item.touchpoints, ['type', 'self_order_kiosk']));
    setDigital(some(item.touchpoints, ['type', 'digital_front']));
  }, [item]);

  const handleChangeCheckbox = (e, type, item) => {
    const checked = e.target.checked;
    changedData(item, type, checked);
    type === 'pos'
      ? setPos(checked)
      : type === 'self_order_kiosk'
      ? setKiosk(checked)
      : setDigital(checked);
  };

  const rowCells = [
    {
      id: 'product_img',
      value: (
        <Avatar
          src={
            item?.images?.sort(
              (a, b) => moment(a?.created_at) - moment(b?.created_at)
            )[0]?.thumbnail
              ? item?.images?.sort(
                  (a, b) =>
                    moment(a?.created_at) - moment(b?.created_at)
                )[0]?.thumbnail
              : images.noImage
          }
          variant="square"
        />
      ),
    },
    {
      id: 'product_name',
      value: item.name,
    },
    {
      id: 'product_barcode',
      value: item.bar_code,
    },
    {
      id: 'product_category',
      value: item.category.name,
    },
    {
      id: 'empty',
    },
    {
      id: 'category_pos',
      value: (
        <GreenCheckbox
          onChange={(e) => handleChangeCheckbox(e, 'pos', item)}
          checked={pos}
        />
      ),
    },
    {
      id: 'category_kiosk',
      value: (
        <GreenCheckbox
          onChange={(e) =>
            handleChangeCheckbox(e, 'self_order_kiosk', item)
          }
          checked={kiosk}
        />
      ),
    },
    {
      id: 'category_storefront',
      value: (
        <GreenCheckbox
          onChange={(e) =>
            handleChangeCheckbox(e, 'digital_front', item)
          }
          checked={digital}
        />
      ),
    },
  ];

  return (
    <TableRow hover style={{ position: 'relative' }}>
      {rowCells.map((rowCell, index) => (
        <StyledTableCell key={index}>{rowCell.value}</StyledTableCell>
      ))}
    </TableRow>
  );
};

const StyledTableCell = withStyles(() => ({
  root: {
    ...CommonFonts.darkFont16,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25,
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont16,
  },
}))(TableCell);

Row.propTypes = {
  item: PropTypes.object,
};

export default Row;
