import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    boxOutter: {
      backgroundColor: Colors.GRAY_LIGHT,
      maxWidth: '100%',
      minHeight: '100vh',
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
    boxInner: {
      backgroundColor: Colors.WHITE,
      maxWidth: '582px',
      borderRadius: theme.spacing(2),
      borderWidth: '1px',
      borderColor: '#939DA8',
      borderStyle: 'solid',
      padding: theme.spacing(12),
      [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
      },
    },
    accessMerchant: {
      ...CommonFonts.darkFont24,
      fontWeight: '600',
      paddingBottom: theme.spacing(4),
    },
    email: {
      ...CommonFonts.darkFont16,
      paddingBottom: theme.spacing(6),
    },
    subTitle: {
      ...CommonFonts.darkFont16,
      textAlign: 'center',
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
    logo: {
      paddingBottom: theme.spacing(5),
    },
    passwordField: {
      paddingBottom: theme.spacing(5),
    },
    accessButton: {
      ...CommonFonts.darkFont14,
      color: Colors.WHITE,
      padding: `${theme.spacing(3)}px ${theme.spacing(25)}px`,
      maxWidth: theme.spacing(65),
      maxHeight: theme.spacing(13),
      textAlign: 'center',
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      textAlign: 'center',
      letterSpacing: 0.3,
      paddingBottom: theme.spacing(12),
    },
    bottomText: {
      ...CommonFonts.darkFont16,
    },
    bottomBlueText: {
      ...CommonFonts.blueFont16,
    },
    boxFooter: {
      width: '100%',
      textAlign: 'center',
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(6),
    },
    textFooter: {
      ...CommonFonts.darkFont16,
    },
  })
);

export default useStyles;
