import I18n from '@/i18n'
import { sumBy, omit } from 'lodash'
import { v4 as uuid } from 'uuid';
import { osName, deviceType } from 'react-device-detect'

export const ORDER_STATES = [
  {
    state: 'pending',
    name: I18n.t('orders.pending')
  },
  {
    state: 'completed',
    name: I18n.t('orders.completed'),
  },
  {
    state: 'voided',
    name: I18n.t('orders.voided')
  },
  {
    state: 'refunded',
    name: I18n.t('orders.refunded')
  },
  {
    state: 'accepted',
    name: I18n.t('orders.accepted')
  }
]

export const ORDER_PAYMENT_STATES = [
  {
    state: 'paid',
    name: I18n.t('orders.paid')
  },
  {
    state: 'part-paid',
    name: I18n.t('orders.part_paid')
  },
  {
    state: 'unpaid',
    name: I18n.t('orders.unpaid')
  },
]

export const getOrderTotalPrice = (total_price) => {
  return total_price.amount + total_price.tax_amount
}

export const getOrderPaymentSum = (payments) => {
  return sumBy(payments, payment => payment.amount)
}

export const getOrderPaymentState = (order) => {
  const orderTotalPrice = getOrderTotalPrice(order.total_price)
  const orderPaymentSum = getOrderPaymentSum(order.payments)
  if (orderPaymentSum === 0) return 'unpaid'
  if (Math.abs(orderTotalPrice - orderPaymentSum) < 0.01 || orderPaymentSum > orderTotalPrice) return 'paid'
  return 'part-paid'
}

export const getOrderItems = (orderItems) => {
  let items = []
  orderItems.forEach(item => {
    items.push({
      is_manual: item.is_manual,
      bar_code: item.bar_code,
      notes: [],
      category: { ...omit(item.category, '__typename') },
      name: item.name,
      product_id: item.product_id,
      price: {
        ...omit(item.price, '__typename'),
        quantity: item.price.quantity * -1,
        taxes: item.price.taxes.map(tax => {
          const taxWithoutTypename = omit(tax, '__typename')
          return taxWithoutTypename
        })
      },
      addons: item.addons.map(addon => ({
        ...omit(addon, '__typename'),
        price: {
          ...omit(addon.price, '__typename'),
          quantity: addon.price.quantity * -1,
          taxes: addon.price.taxes.map(tax => {
            const taxWithoutTypename = omit(tax, '__typename')
            return taxWithoutTypename
          })
        }
      })),
      promotions: item.promotions.map(promotion => {
        const promotionWithoutTypename = omit(promotion, '__typename')
        return promotionWithoutTypename
      })
    })
  })
  return items
}

export const getOrderInputOfOrderMutation = (order, orderState) => {
  let id = uuid()
  let order_id = order.id
  let order_no = order.order_no
  let device = {
    device_id: deviceType,
    device_name: osName
  }
  let state = orderState
  let order_type = {
    id: order.order_type.id,
    type: order.order_type.type,
    name: order.order_type.name
  }
  let statuses = {
    id: order.status.id,
    name: order.status.name,
    order: order.status.order
  }
  let items = getOrderItems(order.items)
  let total_price = {
    amount: 0,
    taxes: []
  }
  let payments = orderState === 'voided'
    ?
    []
    :
    [{
      status: 'paid',
      payment_type: {
        id: order.payments[0].payment_type.id,
        name: order.payments[0].payment_type.name,
        type: order.payments[0].payment_type.type,
      },
      amount: getOrderPaymentSum(order.payments) * -1
    }]

  return {
    id,
    order_id,
    order_no,
    // shopper,
    state,
    // creation,
    // notes,
    statuses,
    device,
    order_type,
    items,
    total_price,
    payments,
  }
}