import React from 'react'
import { isEmpty, find } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import InventorySearchInput from '@/components/inventorySearchInput'
import Ingredient from './Ingredient'
import NoResults from '../../components/NoResults'

import useStyles from './styles'

const Recipe = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    productData,
    updateProductData,
  } = props

  const onSearchProduct = (inventory) => {
    if (find(productData.ingredients, { id: inventory.id })) return
    const newIngredient = {
      id: inventory.id,
      name: inventory.name,
      ean_upc: inventory.bar_code,
      ingredient_measure_amount: 0,
      measure: {
        id: inventory.measure.id,
        type: inventory.measure.type
      }
    }
    updateProductData('ingredients', [...productData.ingredients, newIngredient])
  }

  const updateIngredient = (ingredient) => {
    updateProductData('ingredients',
      productData.ingredients
        .map(item => item.id === ingredient.id ? ingredient : item)
    )
  }

  const removeIngredient = (ingredient) => {
    updateProductData('ingredients',
      productData.ingredients
        .filter(item => item.id !== ingredient.id)
    )
  }

  return (
    <Box>
      <Box marginTop={7}>
        <Typography className={classes.findProductText}>
          {t('add_product.find_inventory')}
        </Typography>
      </Box>
      <Box className={classes.productSearchWrapper}>
        <InventorySearchInput onSelectInventory={onSearchProduct} />
      </Box>

      <Box marginTop={10}>
        {isEmpty(productData.ingredients) &&
          <NoResults title={t('add_product.no_ingredients_added')} />
        }
        {productData.ingredients.map((ingredient, index) => (
          <Ingredient
            key={`ingredient-${index}`}
            ingredient={ingredient}
            updateIngredient={updateIngredient}
            removeIngredient={removeIngredient}
          />
        ))}
      </Box>
    </Box>
  )
}

export default Recipe