import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography, Box, Select, MenuItem } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { COMPARISON } from '../../helper'
import useStyles from './styles'

const SelectCompareStore = ({ comparison, onChangeComparison }) => {
  const classes = useStyles();
  const { t } = useTranslation()

  return (
    <Box>
      <Typography className={classes.titleText}>
        {t('workboard.performance_overview.compare')}
      </Typography>

      <Box className={classes.selectContainer}>
        <Select
          disableUnderline
          IconComponent={ExpandMoreIcon}
          displayEmpty
          renderValue={() => comparison}
          fullWidth
          className={classes.filterInput}
          value={comparison}
          onChange={(event) => onChangeComparison(event.target.value)}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value={COMPARISON.STORE}>
            {COMPARISON.STORE}
          </MenuItem>
          <MenuItem value={COMPARISON.PRODUCT}>
            {COMPARISON.PRODUCT}
          </MenuItem>
        </Select>
      </Box>
    </Box>
  )
}

export default SelectCompareStore