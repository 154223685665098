import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    breadcrumbsText: {
      fontSize: 16,
    },
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      ...CommonFonts.darkFont18,
    },
    boxWorkflows: {
      backgroundColor: Colors.WHITE,
      padding: theme.spacing(5),
      borderRadius: theme.spacing(1),
    },
    boxTable: {
      marginTop: theme.spacing(7),
    },
    title: {
      ...CommonFonts.darkFont16,
      fontWeight: '600',
      marginTop: theme.spacing(2),
    },
    tableText: {
      ...CommonFonts.darkFont18,
    },
    moreIcon: {
      padding: theme.spacing(1),
    },
    noDataFound: {
      ...CommonFonts.darkFont16,
    },
    headerTableText: {
      ...CommonFonts.darkFont14,
    },
    boxFullName: {
      wordBreak: 'break-word',
      padding: theme.spacing(2.5, 0),
    },
    actionLink: {
      color: '#1174F2',
      ...CommonFonts.blueFont18,
      opacity: 0.5,
      cursor: 'default',
    },
  })
);

export default useStyles;
