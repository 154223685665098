import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    boxPlanItem: {
      backgroundColor: Colors.WHITE,
      padding: theme.spacing(5),
      borderRadius: theme.spacing(1),
    },
    boxTable: {
      marginTop: theme.spacing(7),
    },
    title: {
      ...CommonFonts.darkFont16,
      fontWeight: '600',
      marginTop: theme.spacing(2),
    },
    description: {
      ...CommonFonts.darkFont16,
      marginTop: theme.spacing(3),
      wordBreak: 'break-word',
    },
    tableText: {
      ...CommonFonts.darkFont18,
    },
    headerTableText: {
      ...CommonFonts.darkFont14,
    },
    boxFullName: {
      wordBreak: 'break-word',
      padding: theme.spacing(2.5, 0),
    },
    manage: {
      ...CommonFonts.blueFont18,
      color: '#1174F2',
      cursor: 'pointer',
    },
  })
);

export default useStyles;
