import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      padding: theme.spacing(12, 14, 10),
      height: 287,
    },
    headerDrawer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      justifyContent: 'space-between',
    },
    buttonLang: {
      textTransform: 'initial',
      letterSpacing: 0,
      fontWeight: 'normal',
      ...CommonFonts.darkFont16,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiButton-endIcon': {
        marginLeft: 0,
      },
      '& .MuiButton-startIcon': {
        marginRight: 4,
      },
    },
    button: {
      letterSpacing: 0.29,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    buttonTab: {
      backgroundColor: Colors.GRAY_BORDER1,
    },
    title: {
      letterSpacing: 0.18,
      marginBottom: theme.spacing(10),
      ...CommonFonts.darkFont24,
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      minWidth: 'max-content',
      position: 'relative',
      top: 2,
      left: 14,
      [theme.breakpoints.down('sm')]: {
        top: 14,
        left: 2,
      },
    },
  })
);

export default useStyles;
