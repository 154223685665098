import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    headerText: {
      ...CommonFonts.darkBoldFont18,
      textAlign: 'center'
    },
    closeButton: {
      padding: theme.spacing(1.5),
      backgroundColor: Colors.GRAY_LIGHT,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    enterBarcodeText: {
      ...CommonFonts.darkFont14,
      fontWeight: 'normal',
    },
    clickHereText: {
      ...CommonFonts.blueFont14,
      fontWeight: 'normal',
      cursor: 'pointer'
    },
    productDescriptionText: {
      ...CommonFonts.darkFont16,
    },
    productContainer: {
      paddingBottom: theme.spacing(6),
      borderBottom: `1px solid ${Colors.GRAY_BORDER1_40}`,
    },
    inputWrapper: {
      maxWidth: 150,
    },
    disabled: {
      pointerEvents: 'none',
      opacity: 0.4
    },
    cancelButton: {
      ...CommonFonts.grayFont16,
      letterSpacing: '0.11px',
      color: Colors.GRAY_BORDER3,
      opacity: 1,
    },
  })
)

export default useStyles
