import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline'
import SingleCheckboxInput from '@/components/singleCheckbox';
import useStyles from './styles';
import Input from '../components/input';
import LocationItem from './locationItem';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Colors } from '@/theme'

const StockInformation = (props) => {
  const { productData, updateProductData, shelvesData } = props;
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const addNewLocation = () => {
    const stocks = [...productData?.stocks];
    if (stocks[0]?.stock_locations) {
      stocks[0].stock_locations.push({
        id: uuid(),
        aisle: '',
        shelf: '',
      });
    } else {
      stocks[0].stock_locations = [
        {
          id: uuid(),
          aisle: '',
          shelf: '',
        },
      ];
    }
    updateProductData('stocks', stocks);
  };

  const updateLocation = (index, item) => {
    const stocks = [...productData.stocks];
    const location = stocks[0].stock_locations;
    location[index] = {
      id: item.id,
      aisle: item.aisle,
      shelf: item.shelf,
    };
    updateProductData('stocks', stocks);
  };

  return (
    <Box>
      <Box marginTop={7} marginBottom={5}>
        <Typography className={classes.title}>
          {t('add_product.general_information')}
        </Typography>
      </Box>

      <Grid container spacing={10}>
        <Grid item xs={12} sm={6} md={3}>
          <Box display='flex' alignItems='center'>
            <SingleCheckboxInput
              style={{ paddingBottom: 0 }}
              isShowToast={false}
              value={!productData?.stocked}
              label={t('add_product.made_to_order')}
              onChange={(value) => updateProductData('stocked', !value)}
            />
            <Tooltip
              title={t('add_product.tooltip_for_made_to_order')}
              classes={{
                popper: classes.popper,
                tooltip: classes.tooltip
              }}
            >
              <HelpIcon fontSize='small' style={{ color: Colors.GRAY_BORDER3 }} />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box display='flex' alignItems='center'>
            <SingleCheckboxInput
              style={{ paddingBottom: 0 }}
              isShowToast={false}
              value={productData?.produced || false}
              label={t('add_product.produced')}
              onChange={(value) => updateProductData('produced', value)}
            />
            <Tooltip
              title={t('add_product.tooltip_for_produced')}
              classes={{
                popper: classes.popper,
                tooltip: classes.tooltip
              }}
            >
              <HelpIcon fontSize='small' style={{ color: Colors.GRAY_BORDER3 }} />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box display='flex' alignItems='center'>
            <SingleCheckboxInput
              style={{ paddingBottom: 0 }}
              isShowToast={false}
              value={productData?.expires || false}
              label={t('add_product.item_expires')}
              onChange={(value) => updateProductData('expires', value)}
            />
            <Tooltip
              title={t('add_product.tooltip_for_item_expires')}
              classes={{
                popper: classes.popper,
                tooltip: classes.tooltip
              }}
            >
              <HelpIcon fontSize='small' style={{ color: Colors.GRAY_BORDER3 }} />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Input
            name="sku_no"
            title={t('add_product.sku_number')}
            emptyLabel={t('add_product.add')}
            value={productData?.sku_no}
            onChange={(value) => updateProductData('sku_no', value)}
          />
        </Grid>
      </Grid>

      <Box marginY={4}>
        <Typography className={classes.title}>
          {t('add_product.locations')}
        </Typography>
      </Box>
      <Box marginY={6} display="flex" alignItems="center">
        <Typography className={classes.redirectModule}>
          {t('add_product.manage_floor_shelf')}
        </Typography>
        <Typography
          onClick={() => history.push('/modules')}
          className={classes.redirectModuleLink}
        >
          {t('add_product.modules')}
        </Typography>
      </Box>
      {productData?.stocks[0]?.stock_locations?.map(
        (location, index) => (
          <LocationItem
            key={index}
            location={location}
            shelves={shelvesData?.shelves}
            updateLocation={(value) => updateLocation(index, value)}
          />
        )
      )}
      <Typography
        onClick={addNewLocation}
        className={classes.addNewLocation}
      >
        {t('add_product.add_new_location')}
      </Typography>
    </Box>
  );
};

export default StockInformation;
