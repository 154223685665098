import React from 'react'

import {
  TimePicker
} from '@material-ui/pickers';
import { Typography, Box } from '@material-ui/core'

const MuiTimePicker = (props) => {
  const {
    label = '',
    labelClass = '',
    ...restProps
  } = props

  return (
    <Box>
      {label &&
        <Typography className={labelClass}>
          {label}
        </Typography>
      }
      <TimePicker {...restProps} />
    </Box>
  )
}

export default MuiTimePicker