import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { getFullAddressOfStore } from '@/utils/stringUtil'

import SelectOrderStep from './components/SelectOrderStep'
import PayOrderStep from './components/PayOrderStep'

import useStyles from './styles'

const PayDebtModal = ({ closeModal, currentCustomer }) => {
  const [order, setOrder] = useState(null)
  const classes = useStyles()

  const { selectedStore } = useSelector(state => state.store)

  const { address, name: storeName } = selectedStore
  const onClose = () => {
    closeModal()
    setOrder(null)
  }

  const storeAddress = getFullAddressOfStore(address)
  if (!currentCustomer) return null

  if (!order)
    return (
      <SelectOrderStep
        classes={classes}
        setOrder={setOrder}
        onClose={onClose}
        currentCustomer={currentCustomer}
        storeName={storeName}
        storeAddress={storeAddress}
      />
    )

  return (
    <PayOrderStep
      order={order}
      setOrder={setOrder}
      storeName={storeName}
      storeAddress={storeAddress}
      classes={classes}
      onClose={onClose}
    />
  )
}

export default PayDebtModal
