import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { find, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AppLayout from '@/components/appLayout';
import PreLoader from '@/components/preLoader';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Link } from '@material-ui/core';
import SearchHeader from './components/SearchHeader';
import NoProducts from './components/NoProducts';
import NoResults from './components/NoResults';
import ActionMenu from './components/ActionMenu';
import EditCategory from './EditCategory';
import AddCategory from './AddCategory';
import AddUpdateProduct from './AddUpdateProduct';
import ProductsListWrapper from './ProductsList';
import MoveProducts from './MoveProducts';

import { useQuery, useMutation } from '@apollo/client';
import {
  GET_ALL_CATEGORIES,
  GET_CATEGORIES,
  REMOVE_CATEGORY
} from '@/services/inventoryService';

import { contains } from '@/utils/stringUtil';
import images from '@/assets/images';
import { Colors } from '@/theme';
import useStyles from './styles';

const Products = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [inputSearch, setInputSearch] = useState('');

  const [categoryForAction, setCategoryForAction] = useState(null);

  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [openMoveProducts, setOpenMoveProducts] = useState(false);

  const [currentCategory, setCurrentCategory] = useState({
    id: '',
    name: '',
  });

  const {
    loading: isLoadingAllCategories,
    data: allCategoriesData,
    refetch: refetchAllCategories,
  } = useQuery(GET_ALL_CATEGORIES, {
    context: {
      headers: { storeId: '-1' },
    },
    fetchPolicy: 'no-cache',
  });

  const {
    loading: isLoadingStoreCategories,
    data: storeCategoriesData,
    refetch: refetchStoreCategories,
  } = useQuery(GET_CATEGORIES, {
    fetchPolicy: 'no-cache',
  });

  const [removeCategoryMutation, { loading: isDeletingCategory }] = useMutation(REMOVE_CATEGORY, {
    onCompleted: () => {
      enqueueSnackbar(t('categories.category_deleted', { name: categoryForAction.name }));
      refetchAllCategories()
    },
    onError: (error) => {
      const message = error?.message || error?.error[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  })

  const allCategories = allCategoriesData?.categories || [];
  const storeCategories = storeCategoriesData?.categories || [];

  const filteredCategories = allCategories
    .filter((category) => !!contains(category.name, inputSearch))
    .map((category) => {
      let item = find(storeCategories, { id: category.id });
      if (item)
        return { ...category, productCount: item.productCount };
      else return category;
    });

  // group categories by parent
  const groupCategoriesByParent = (
    items,
    id = null,
    link = 'parent'
  ) =>
    items
      .filter((item) => item[link] === id)
      .map((item) => ({
        ...item,
        children: groupCategoriesByParent(items, item.id),
      }));

  const categoriesGroupedByParent = useMemo(
    () => groupCategoriesByParent(filteredCategories),
    [filteredCategories]
  );

  // if the grouped by parent===0. show filtered categories
  const categoriesToShow = useMemo(
    () =>
      categoriesGroupedByParent.length > 0
        ? categoriesGroupedByParent
        : filteredCategories,
    [filteredCategories]
  );

  // render the text for Taxes column
  const renderTax = (taxes) => {
    let fullText;

    if (taxes.length > 0) {
      fullText = taxes
        .filter((tax) => !!tax)
        .map((tax) => {
          if (tax.rate < 0) {
            return 'VAT Exempt';
          }
          return `${tax.rate}% ${tax.name} `;
        })
        .join(', ');
    }
    return fullText;
  };

  const categoryActionMenus = [
    {
      name: t('categories.edit'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        setCategoryForAction(data);
        setOpenEditCategory(true);
      },
    },
    {
      name: t('categories.move_products'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        setCategoryForAction(data);
        setOpenMoveProducts(true);
      },
    },
    {
      name: t('categories.archive'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        setCategoryForAction(data);
        removeCategoryMutation({
          variables: {
            input: {
              id: [data.id]
            }
          }
        })
      },
    },
  ];

  return (
    <AppLayout
      className={classes.appLayout}
      withFooter
      header
      withServiceDropdown={true}
    >
      <div className={classes.root}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                className={classes.breadcrumbsText}
                color="primary"
                component={RouterLink}
                to="/business-manager"
              >
                {t('settings.business_manager')}
              </Link>
              <Link
                className={classes.breadcrumbsText}
                color={currentCategory.id ? 'primary' : 'textPrimary'}
                onClick={() =>
                  setCurrentCategory({ id: '', name: '' })
                }
              >
                {t('categories.categories')}
              </Link>
              {currentCategory.id && (
                <Link
                  className={classes.breadcrumbsText}
                  color="textPrimary"
                >
                  {currentCategory.name}
                </Link>
              )}
            </Breadcrumbs>
          </Grid>
          <SearchHeader
            setInputSearch={setInputSearch}
            onAddCategory={() => setOpenAddCategory(true)}
            onAddProduct={() => setOpenAddProduct(true)}
            handleClose={() => setOpenAddCategory(false)}
          />

          {isEmpty(allCategories) &&
            !isLoadingAllCategories &&
            !isLoadingStoreCategories ? (
            <Grid item xs={12}>
              <Paper className={classes.emptyProductsContainer}>
                <NoProducts
                  onAddFirstCategory={() => setOpenAddCategory(true)}
                />
              </Paper>
            </Grid>
          ) : (
            <React.Fragment>
              {!currentCategory.id && (
                <Grid item xs={12}>
                  <Paper className={classes.emptyProductsContainer}>
                    {isEmpty(categoriesToShow) &&
                      !isLoadingAllCategories &&
                      !isLoadingStoreCategories ? (
                      <NoResults
                        title={t('categories.no_categories_found')}
                      />
                    ) : (
                      <List className={classes.root}>
                        <Hidden only="xs">
                          <ListItem className={classes.listHeader}>
                            <Grid
                              container
                              justify="space-between"
                              alignItems="center"
                              alignContent="center"
                            >
                              <Grid item xs={10}>
                                <Grid
                                  container
                                  justify="space-between"
                                  alignItems="center"
                                  alignContent="center"
                                >
                                  <Grid item xs={1} />
                                  <Grid item xs={3}>
                                    {t('categories.category_name')}
                                  </Grid>
                                  <Grid item xs={3}>
                                    {t('categories.type')}
                                  </Grid>
                                  <Grid item xs={2}>
                                    {t('categories.no_products')}
                                  </Grid>
                                  <Grid item xs={3}>
                                    {t('categories.taxes')}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={2} />
                            </Grid>
                          </ListItem>
                        </Hidden>

                        {isLoadingAllCategories ||
                          isLoadingStoreCategories ? (
                          <div className={classes.root}>
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                            <Skeleton height={80} />
                          </div>
                        ) : (
                          <div
                            style={{
                              maxHeight: 500,
                              overflowY: 'auto',
                            }}
                          >
                            {categoriesToShow.map((item) => (
                              <React.Fragment key={item.id}>
                                <ListItem
                                  button
                                  className={classes.listItem}
                                >
                                  <Grid
                                    container
                                    justify="space-between"
                                    alignItems="center"
                                    alignContent="center"
                                  >
                                    <Grid item xs={10}>
                                      <Grid
                                        container
                                        justify="space-between"
                                        alignItems="center"
                                        alignContent="center"
                                        onClick={() =>
                                          setCurrentCategory(item)
                                        }
                                      >
                                        <Grid item xs={1}>
                                          <Avatar
                                            src={
                                              item?.image?.thumbnail
                                                ? item?.image
                                                  ?.thumbnail
                                                : images.noImage
                                            }
                                            variant="square"
                                            className={
                                              classes.avatarSize
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={7} sm={3}>
                                          {item.name}
                                        </Grid>
                                        <Hidden only="xs">
                                          <Grid item xs={3}>
                                            {item.parent === null
                                              ? t('categories.parent')
                                              : t(
                                                'categories.sub_category'
                                              )}
                                          </Grid>

                                          <Grid item xs={2}>
                                            {item?.productCount || 0}
                                          </Grid>
                                          <Grid item xs={3}>
                                            {renderTax(item.taxes)}
                                          </Grid>
                                        </Hidden>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      className={classes.dotsIcon}
                                    >
                                      <ActionMenu
                                        data={item}
                                        actionMenus={
                                          categoryActionMenus
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </ListItem>

                                {item?.children &&
                                  item?.children.map(
                                    (subCategory) => (
                                      <ListItem
                                        key={subCategory.id}
                                        button
                                        className={classes.listItem}
                                      >
                                        <Grid
                                          container
                                          justify="space-between"
                                          alignItems="center"
                                          alignContent="center"
                                        >
                                          <Grid item xs={10}>
                                            <Grid
                                              container
                                              justify="space-between"
                                              alignItems="center"
                                              alignContent="center"
                                              onClick={() =>
                                                setCurrentCategory(
                                                  subCategory
                                                )
                                              }
                                            >
                                              <Grid item xs={1}>
                                                <Avatar
                                                  src={
                                                    subCategory?.image
                                                      ?.thumbnail
                                                      ? subCategory
                                                        ?.image
                                                        ?.thumbnail
                                                      : images.noImage
                                                  }
                                                  variant="square"
                                                  className={
                                                    classes.avatarSize
                                                  }
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                xs={7}
                                                sm={3}
                                                className={
                                                  classes.nested
                                                }
                                              >
                                                {subCategory.name}
                                              </Grid>
                                              <Hidden only="xs">
                                                <Grid item xs={3}>
                                                  {t(
                                                    'categories.sub_category'
                                                  )}
                                                </Grid>
                                                <Grid item xs={2}>
                                                  {subCategory?.productCount ||
                                                    0}
                                                </Grid>
                                                <Grid item xs={3}>
                                                  {renderTax(
                                                    subCategory.taxes
                                                  )}
                                                </Grid>
                                              </Hidden>
                                            </Grid>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            className={
                                              classes.dotsIcon
                                            }
                                          >
                                            <ActionMenu
                                              data={subCategory}
                                              actionMenus={
                                                categoryActionMenus
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                      </ListItem>
                                    )
                                  )}
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </List>
                    )}
                  </Paper>
                </Grid>
              )}
            </React.Fragment>
          )}
        </Grid>

        {isDeletingCategory && <PreLoader size={40} />}
      </div>

      {(inputSearch || currentCategory.id) && (
        <ProductsListWrapper
          category={currentCategory}
          inputSearch={inputSearch}
        />
      )}

      {openAddCategory && (
        <AddCategory
          open={openAddCategory}
          handleClose={() => setOpenAddCategory(false)}
          allCategories={allCategories}
          emptyCategories={isEmpty(allCategories)}
          setRefetchCategories={refetchAllCategories}
        />
      )}
      {openAddProduct && (
        <AddUpdateProduct
          open={openAddProduct}
          handleClose={() => setOpenAddProduct(false)}
          onSuccess={refetchStoreCategories}
        />
      )}
      {openEditCategory && (
        <EditCategory
          open={openEditCategory}
          handleClose={() => setOpenEditCategory(false)}
          allCategories={allCategories}
          emptyCategories={isEmpty(allCategories)}
          categoryId={categoryForAction?.id}
          setRefetchCategories={refetchAllCategories}
        />
      )}
      {openMoveProducts && (
        <MoveProducts
          open={openMoveProducts}
          handleClose={() => setOpenMoveProducts(false)}
          defaultCategory={categoryForAction}
          onSuccess={refetchStoreCategories}
        />
      )}
    </AppLayout>
  );
};

export default Products;
