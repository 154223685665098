import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.grayFont16,
    },
    value: {
      ...CommonFonts.grayFont18,
    },
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    box: {
      marginBottom: theme.spacing(10),
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(25),
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(7),
      },
    },
    addAction: {
      textTransform: 'capitalize',
      cursor: 'pointer',
      color: '#1174F2',
    },
    addButtonWrapper: {
      backgroundColor: Colors.WHITE,
      position: 'absolute',
      bottom: 0,
      left: 0,
      borderWidth: 1,
      borderColor: Colors.DISABLED,
      borderStyle: 'solid',
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      borderTopLeftRadius: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        bottom: 0,
        borderBottomLeftRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        borderTopRightRadius: theme.spacing(0),
        borderTopLeftRadius: theme.spacing(0),
      },
    },
    addButton: {
      textTransform: 'none',
      marginRight: theme.spacing(3),
    },
    editIcon: {
      padding: theme.spacing(1),
    },
  })
);

export default useStyles;
