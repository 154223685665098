import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  TableRow,
  TableCell,
  withStyles,
  Box,
  Typography,
  Button,
} from '@material-ui/core'

import { Colors, CommonFonts } from '@/theme'

import useStyles from './styles'

const Row = ({ item, onViewApplication }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const rowCells = [
    {
      id: 'first_name',
      label: item?.user?.first_name,
    },
    {
      id: 'last_name',
      label: item?.user?.last_name,
    },
    {
      id: 'mobile',
      label: item?.user?.mobile,
    },
  ];

  return (
    <TableRow hover style={{ position: 'relative' }}>
      {rowCells.map(rowCell => (
        <StyledTableCell key={rowCell.id}>
          {rowCell.label}
        </StyledTableCell>
      ))}
      <StyledTableCell align='right'>
        <Button
          className={classes.viewButton}
          color="primary"
          onClick={() => onViewApplication(item)}
        >
          {t('userManagement.applications.view')}
        </Button>
      </StyledTableCell>
    </TableRow>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    ...CommonFonts.darkFont18,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont18
  },
}))(TableCell);

Row.propTypes = {
  item: PropTypes.object,
}

export default Row