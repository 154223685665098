import React, { useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  Select,
  MenuItem,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  FormHelperText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { ADD_TOUCHPOINT } from '@/services/touchpointsManagementService';
import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useSnackbar } from 'notistack';
import TextField from '@/components/textField';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TouchpointsType } from '@/constants/touchpointsManagement';

const UpdateTouchpoints = ({
  touchpointsDetail,
  onFetchTouchpoints,
  isVisible,
  setUpdateTouchpointsModal,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseModal = () => {
    setUpdateTouchpointsModal(false);
    setErrorMessage('');
    handleReset();
  };

  const updateTouchpointsSchema = Yup.object().shape({
    touchpointName: Yup.string().required(
      t('touchpointsManagement.touchpoints.touchpoint_name_required')
    ),
  });

  const {
    handleBlur,
    handleReset,
    handleChange,
    errors,
    touched,
    values,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      touchpointName: touchpointsDetail.name,
      touchpointType: TouchpointsType?.find(
        (item) => item.id === touchpointsDetail.type
      ),
    },
    validationSchema: updateTouchpointsSchema,
  });

  const [onUpdateTouchpoint] = useMutation(
    gql`
      ${ADD_TOUCHPOINT}
    `,
    {
      variables: {
        input: {
          id: touchpointsDetail.id,
          name: values.touchpointName,
          type: values.touchpointType?.id,
        },
      },
      context: {
        headers: {
          storeId: touchpointsDetail.store?.id,
        },
      },
      onCompleted: async () => {
        enqueueSnackbar(
          t('touchpointsManagement.touchpoints.touchpoint_updated', {
            name: values.touchpointName,
          })
        );
        await onFetchTouchpoints?.();
        setIsLoading(false);
        handleCloseModal();
      },
      onError: (error) => {
        setIsLoading(false);
        setErrorMessage(error?.message);
      },
    }
  );

  return (
    <Dialog
      className={classes.boxDialog}
      fullWidth
      fullScreen={fullScreen}
      onClose={isVisible ? () => {} : handleCloseModal}
      aria-labelledby={`{edit-update-touchpoint-dialog-title}`}
      open={isVisible}
      disableBackdropClick
    >
      <DialogTitle
        id={`{edit-update-touchpoint-dialog-title}`}
        onClose={handleCloseModal}
      >
        {t('touchpointsManagement.touchpoints.update_touchpoint')}
      </DialogTitle>
      <DialogContent>
        {errorMessage && (
          <Box
            display="flex"
            justifyContent="center"
            marginBottom={7}
          >
            <FormHelperText error>{errorMessage}</FormHelperText>
          </Box>
        )}
        <Box marginBottom={12}>
          <Typography className={classes.titleText}>
            {t('touchpointsManagement.touchpoints.touchpoint_name')}
          </Typography>
          <TextField
            id={'touchpointName'}
            name={'touchpointName'}
            fullWidth
            value={values.touchpointName}
            onChange={handleChange}
            error={
              touched['touchpointName'] &&
              Boolean(errors['touchpointName'])
            }
            helperText={
              touched['touchpointName'] && errors['touchpointName']
            }
            autoFocus
            onBlur={handleBlur}
            InputProps={{
              className: classes.input,
            }}
          />
        </Box>
        <Box marginBottom={12}>
          <Typography className={classes.titleText}>
            {t('touchpointsManagement.touchpoints.touchpoint_type')}
          </Typography>
          <Select
            fullWidth
            className={classes.selectOptionInput}
            id={'touchpointType'}
            name={'touchpointType'}
            value={values.touchpointType}
            onChange={handleChange}
          >
            {TouchpointsType &&
              TouchpointsType?.map((option, index) => {
                return (
                  <MenuItem
                    key={`${option.name}-${index}`}
                    value={option}
                  >
                    {option.name}
                  </MenuItem>
                );
              })}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>
          {t('settings.cancel')}
        </Button>
        <Button
          disabled={!isValid}
          onClick={async () => {
            setIsLoading(true);
            await onUpdateTouchpoint();
          }}
          type="submit"
          color="primary"
        >
          {t('touchpointsManagement.touchpoints.update')}
        </Button>
      </DialogActions>
      {isLoading && <PreLoader size={30} />}
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default UpdateTouchpoints;
