import React, { useState, useCallback } from 'react';
import {
  Box,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@/components/button';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { MUTATE_GENERATE_UPLOAD_URL } from '@/services/storeService';
import {
  MUTATE_ADD_IMPORT,
  READ_IMPORT,
} from '@/services/importService';
import useStyles from './styles';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { Colors, CommonFonts } from '@/theme';
import { useSnackbar } from 'notistack';
import MapColumnProperty from './mappingColumnProperty';
import {
  MappingProperties,
  ImportAcceptType,
} from '@/constants/import';
import _ from 'lodash';

const readImportQuery = gql`
  ${READ_IMPORT}
`;

const ImportDialog = ({ isVisible, onCloseDialog }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [fileId, setFileId] = useState('');
  const [mappingData, setMappingData] = useState([]);

  const isDisableImport =
    mappingData?.findIndex((item) => _.isEmpty(item.mapTo)) > 0;

  const handleCloseDialog = () => {
    onCloseDialog();
    setMappingData([]);
  };

  const [mutateGenerateURL] = useMutation(
    gql`
      ${MUTATE_GENERATE_UPLOAD_URL}
    `
  );

  const [mutateAddImport] = useMutation(
    gql`
      ${MUTATE_ADD_IMPORT}
    `
  );

  const [onReadImport] = useLazyQuery(readImportQuery, {
    onCompleted: (res) => {
      const importData = res?.readImport;
      if (!_.isEmpty(importData)) {
        const headerDisplay = importData[0];
        const firstRowData = importData[1];
        const result = headerDisplay?.map((header, index) => ({
          header: header,
          firstRow: `(${firstRowData[index]})`,
          mapTo:
            MappingProperties?.find((property) =>
              property.match.includes(header)
            )?.id || '',
        }));
        setMappingData(result);
        setIsLoading(false);
      }
    },
  });

  const onDrop = useCallback(async (value) => {
    if (!_.isEmpty(value)) {
      setIsLoading(true);
      const file = value[0];
      try {
        //Generate url from s3 server
        const result = await mutateGenerateURL({
          variables: {
            input: {
              bucket: 'import',
              name: file?.name,
              content_type: 'text/csv',
            },
          },
          ignoreResults: false,
        });
        const generateUploadUrlResponse =
          result?.data?.generateUploadUrl[0];
        if (generateUploadUrlResponse) {
          const url = generateUploadUrlResponse?.upload_url;
          //Send file to s3 server
          await fetch(
            new Request(url, {
              method: 'PUT',
              body: file,
              headers: new Headers({
                'Content-Type': 'text/csv',
              }),
            })
          );
          setFileId(generateUploadUrlResponse?.id);
          //Read import file
          onReadImport({
            variables: {
              id: generateUploadUrlResponse?.id,
            },
          });
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  }, []);

  const dropZoneValidator = (file) => {
    const type = file.type;
    if (!ImportAcceptType.includes(type)) {
      return {
        code: 'invalid-file-type',
        message: t('import.file_type_invalid'),
      };
    }
    return null;
  };

  const { fileRejections, getRootProps, getInputProps } = useDropzone(
    {
      onDrop,
      validator: dropZoneValidator,
    }
  );

  const onImport = async () => {
    setIsLoading(true);
    const mapping = mappingData
      ?.filter(
        (item) => !_.isEqual(item.mapTo, MappingProperties[0]?.id)
      )
      ?.reduce((acc, arg) => {
        return {
          ...acc,
          [`${arg.mapTo}`]: arg.header,
        };
      }, {});

    const payload = {
      variables: {
        input: {
          file_id: fileId,
          import_type: 'product',
          file_type: 'csv',
          mapping: mapping,
        },
      },
    };

    return mutateAddImport(payload)
      .then(() => {
        setIsLoading(false);
        handleCloseDialog();
        enqueueSnackbar(t('import.product_imported'));
      })
      .catch((error) => {
        const message = error?.message || error?.errors[0]?.message;
        setIsLoading(false);
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  return (
    <Dialog
      fullWidth
      fullScreen
      onClose={isLoading ? () => {} : handleCloseDialog}
      aria-labelledby={`import-inventory-dialog-title`}
      open={isVisible}
      disableBackdropClick
    >
      <DialogTitle
        id={`import-inventory-dialog-title`}
        onClose={handleCloseDialog}
      >
        <Typography className={classes.titleDialog}>
          {t('import.import_product')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {_.isEmpty(mappingData) ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ padding: '0 32%' }}
          >
            <Typography className={classes.titleUpload}>
              {t('import.upload_your_file')}
            </Typography>

            <div className={classes.bottomContainer}>
              <span className={classes.bottomText}>
                {t('import.before_upload')}
              </span>
              <span>
                <a
                  className={classes.bottomBlueText}
                  href="/import/inventory.csv"
                  download
                >
                  {t('import.download_sample')}
                </a>
              </span>
            </div>

            <div {...getRootProps()} className={classes.dropZone}>
              <input {...getInputProps()} />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <div>
                  <span className={classes.dragAndDropDescription}>
                    {t('import.drag_and_drop')}
                  </span>
                  <span className={classes.chooseFile}>
                    {t('import.choose_file')}
                  </span>
                  <span className={classes.dragAndDropDescription}>
                    {t('import.from_your_computer')}
                  </span>
                </div>
                <Typography className={classes.typeSupport}>
                  {t('import.type_support')}
                </Typography>
              </Box>
            </div>

            {fileRejections && (
              <Box marginTop={10}>
                {fileRejections?.map(({ errors }) => (
                  <FormHelperText error>
                    <Typography className={classes.errorMessage}>
                      {errors[0]?.message}
                    </Typography>
                  </FormHelperText>
                ))}
              </Box>
            )}
          </Box>
        ) : (
          <MapColumnProperty
            mappingData={mappingData}
            setMappingData={setMappingData}
          />
        )}
      </DialogContent>
      {!_.isEmpty(mappingData) && (
        <DialogActions>
          <Box
            paddingBottom={5}
            paddingRight={5}
            display="flex"
            alignItems="center"
          >
            <Button
              disableElevation
              variant="contained"
              onClick={handleCloseDialog}
              className={classes.cancelButton}
            >
              {t('settings.cancel')}
            </Button>
            <Button
              disabled={isDisableImport}
              disableElevation
              variant="contained"
              onClick={onImport}
              type="submit"
              color="primary"
              className={classes.importButton}
            >
              {t('import.title')}
            </Button>
          </Box>
        </DialogActions>
      )}
      {isLoading && <PreLoader size={30} />}
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default ImportDialog;
