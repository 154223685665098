import React, { useState, useEffect } from 'react';

import useStyles from './styles';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Avatar,
  Paper,
  Popper,
  Menu,
  MenuItem,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';
import InfiniteScroll from 'react-infinite-scroll-component';
import TextField from '@/components/textField';
import SearchHeader from './searchHeader';
import { Colors } from '@/theme';
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_APPLICATIONS } from '@/services/userManagementService';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import moment from 'moment';
import { Alert, AlertTitle } from '@material-ui/lab';
import { isMobile } from 'react-device-detect';

import ApplicationsTable from './table';
import NoApplications from './noApplications';
import AddUpdateApplication from './addUpdateApplication';

import { isEmpty } from 'lodash';
import {
  getFirstLetter,
  capitalizeFirstLetter,
  contains,
} from '@/utils/stringUtil';
import { useSelector } from 'react-redux';
import {
  selectCurrentMerchant,
  selectSelectedStore,
} from '@/store/modules/store/selectors';
import { useSnackbar } from 'notistack';

const ONE_PAGE_LIMIT = 10;

const Applications = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const selectedStore = useSelector(selectSelectedStore);

  const [applications, setApplications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAllApplicationsLoaded, setIsAllApplicationsLoaded] =
    useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedApplication, setSelectedApplication] =
    useState(null);

  const [searchText, setSearchText] = useState('');

  // get products from api
  const [getApplications, { loading: isLoadingApplications }] =
    useLazyQuery(GET_APPLICATIONS, {
      onCompleted: (data) => {
        // if response give us less then 10 results, set all applications loaded
        if (data.applications.length < 10) {
          setIsAllApplicationsLoaded(true);
        } else {
          setIsAllApplicationsLoaded(false);
        }

        // update the array holding applications
        setApplications([...applications, ...data.applications]);
      },
      onError: () => {
        setIsAllApplicationsLoaded(true);
      },
      fetchPolicy: 'no-cache',
    });

  // change current page when user reaches bottom
  const handleLoadMore = () => {
    if (isEmpty(applications)) return;
    setCurrentPage(currentPage + 1);
  };

  const onRefresh = () => {
    setIsAllApplicationsLoaded(false);
    setCurrentPage(1);
    setApplications([]);
    const queryInput = searchText ? { query: `*${searchText}*` } : {};
    getApplications({
      variables: {
        filter: {
          count: true,
          page: 1,
          limit: ONE_PAGE_LIMIT,
        },
        status: 'pending',
        ...queryInput,
      },
    });
  };

  // get applications from API
  useEffect(() => {
    const queryInput = searchText ? { query: `*${searchText}*` } : {};
    getApplications({
      variables: {
        filter: {
          count: true,
          page: currentPage,
          limit: ONE_PAGE_LIMIT,
        },
        status: 'pending',
        ...queryInput,
      },
    });
  }, [currentPage]);

  useEffect(() => {
    onRefresh();
  }, [selectedStore, searchText]);

  const onViewApplication = (application) => {
    setOpenModal(application);
    setSelectedApplication(application);
  };

  const onRemoveApplication = (application) => {
    setApplications(
      applications.filter((item) => item.id !== application.id)
    );
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <Box>
        <div className={classes.boxFilter}>
          <SearchHeader onChangeSearch={setSearchText} />
        </div>

        <div className={classes.boxUserManagement}>
          <Typography className={classes.title}>
            {t('userManagement.applications.applications')}
          </Typography>
          <Paper elevation={0}>
            <InfiniteScroll
              dataLength={applications.length}
              next={handleLoadMore}
              hasMore={!isAllApplicationsLoaded}
              loader={
                isLoadingApplications &&
                currentPage !== 1 && (
                  <Box
                    marginTop={10}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    overflow="hidden"
                  >
                    <CircularProgress size={35} />
                  </Box>
                )
              }
            >
              <ApplicationsTable
                applications={applications}
                onViewApplication={onViewApplication}
                isLoadingApplications={isLoadingApplications}
                currentPage={currentPage}
              />
            </InfiniteScroll>
            {isAllApplicationsLoaded && isEmpty(applications) && (
              <NoApplications
                title={t(
                  'userManagement.applications.no_applications_found'
                )}
              />
            )}
            {isLoadingApplications && currentPage === 1 && (
              <Box
                marginTop={10}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={35} />
              </Box>
            )}
          </Paper>
        </div>

        {openModal && selectedApplication && (
          <AddUpdateApplication
            open={openModal}
            handleClose={() => setOpenModal(false)}
            application={selectedApplication}
            onSuccess={onRemoveApplication}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default Applications;
