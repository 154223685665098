import { gql } from '@apollo/client';
export const GET_CATEGORIES = gql`
  query getCategories {
    categories {
      id
      image {
        id
        thumbnail
      }
      age_restrict
      name
      parent
      productCount
      taxes {
        id
        name
        rate
      }
    }
  }
`;
export const GET_ALL_CATEGORIES = gql`
  query getAllCategories {
    categories {
      id
      image {
        id
        thumbnail
      }
      age_restrict
      name
      parent
      productCount
      taxes {
        id
        name
        rate
      }
    }
  }
`;
export const GET_CATEGORY_BY_NAME = gql`
  query getCategories($filter: Filter) {
    categories(filter: $filter) {
      id
      image {
        id
        thumbnail
      }
      age_restrict
      name
      parent
      productCount
      taxes {
        id
        name
        rate
      }
    }
  }
`;
export const GET_PRODUCTS = gql`
  query getProducts($filter: Filter) {
    products(filter: $filter) {
      id
      description
      short_description
      images {
        id
        thumbnail
        url
        created_at
      }
      name
      bar_code
      category {
        id
        name
      }
      prices {
        price_infos {
          price_type {
            id
            name
          }
          price
          taxes {
            id
            rate
            name
          }
        }
      }
      inner_item {
        id
        name
        bar_code
      }
      inner_item_measure_amount
      measure {
        id
        type
      }
      addons {
        id
        group
        allow_free
        mandatory
        multi_selection
        default_all
        description
        position
      }
      expires
      sku_no
      stocked
      stocks {
        stock_locations {
          id
          aisle
          shelf
        }
        current_stock
      }
      ingredients {
        id
        name
        ean_upc
        ingredient_measure_amount
        measure {
          id
          type
        }
      }
      allergies {
        id
        name
        image {
          id
          thumbnail
          url
        }
      }
      variant {
        id
        code
        name
        values{
          id
          values {
            name
            value
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS_ON_TOUCHPOINTS = gql`
  query getProducts($filter: Filter) {
    products(filter: $filter) {
      id
      description
      short_description
      touchpoints {
          type
      }
      images {
        id
        thumbnail
        url
        created_at
      }
      name
      bar_code
      category {
        id
        name
      }
    }
  }
`;

export const GET_GLOBAL_CATEGORIES = gql`
  query getGlobalCategory {
    globalCategory {
      id
      name
      image {
        id
        url
      }
      taxes {
        id
        name
        rate
      }
    }
  }
`;
export const GET_TAXES = gql`
  query {
    store {
      taxes {
        id
        name
        rate
      }
    }
  }
`;
export const ADD_NEW_CATEGORY = gql`
  mutation ($input: CategoryInput!) {
    addCategory(input: $input) {
      id
      name
      tax_id
      parent
      age_restrict
      age_restriction
      sell_time_restrict
      sell_time_from
      sell_time_until
      commission
      kitchen_print
      image {
        id
      }
    }
  }
`;
export const EDIT_CATEGORY = gql`
  mutation ($input: CategoryInput!) {
    updateCategory(input: $input) {
      id
      name
      tax_id
      parent
      age_restrict
      age_restriction
      sell_time_restrict
      sell_time_from
      sell_time_until
      commission
      kitchen_print
      image {
        id
      }
    }
  }
`;
// Remove category
export const REMOVE_CATEGORY = gql`
  mutation ($input: RemoveCategoryInput!) {
    removeCategory(input: $input) {
      success
    }
  }
`;
export const GET_CATEGORY_BY_ID = gql`
  query getCategoryById($id: String) {
    categories(id: $id) {
      id
      image {
        id
        thumbnail
      }
      name
      parent
      productCount
      taxes {
        id
        name
        rate
      }
      tax_id
      parent
      age_restrict
      age_restriction
      sell_time_restrict
      sell_time_from
      sell_time_until
      commission
      kitchen_print
    }
  }
`;

// Add product
export const ADD_PRODUCT = gql`
  mutation ($product: ProductInput!) {
    addProduct(input: $product) {
      id
    }
  }
`;

// Update product
export const UPDATE_PRODUCT = gql`
  mutation ($product: ProductInput!) {
    updateProduct(input: $product) {
      id
      description
      short_description
      images {
        id
        thumbnail
        url
        created_at
      }
      name
      bar_code
      category {
        id
        name
      }
      prices {
        price_infos {
          price_type {
            id
            name
          }
          price
          taxes {
            id
            rate
            name
          }
        }
      }
      inner_item {
        id
        name
        bar_code
      }
      inner_item_measure_amount
      measure {
        id
        type
      }
      addons {
        id
        group
        allow_free
        mandatory
        multi_selection
        default_all
        description
        position
      }
      expires
      sku_no
      stocked
      produced
      stocks {
        stock_locations {
          id
          aisle
          shelf
        }
        current_stock
      }
      ingredients {
        id
        name
        ean_upc
        ingredient_measure_amount
        measure {
          id
          type
        }
      }
      allergies {
        id
        name
        image {
          id
          thumbnail
          url
        }
      }
      variant {
        id
        code
        name
        values{
          id
          values {
            name
            value
          }
        }
      }
    }
  }
`;

// Add product
export const DELETE_PRODUCT = gql`
  mutation ($input: [RemoveProductInput]!) {
    removeProduct(input: $input) {
      id
    }
  }
`;

// Move products
export const MOVE_PRODUCTS = gql`
  mutation ($input: MovingCategoryProductInput!) {
    movingCategoryProducts(input: $input)
  }
`;
// Get addonGroup by name
export const GET_ADDON_GROUP_BY_NAME = `
  query getAddonGroupByName($filter: Filter){
    addonGroups(filter: $filter){
      id
      group
      allow_free
      mandatory
      multi_selection
      default_all
      description
      addons {
        id
        name
        inventory_id
        bar_code
        measure_amount
        measure_type
        measure_id
        default
        force_charge
        extra
        fixed_price
      }
    }
  }
`;

// Get addon-groups
export const GET_ADDON_GROUPS = gql`
  query {
    addonGroups {
      id
      group
      allow_free
      mandatory
      multi_selection
      default_all
      description
      addons {
        id
        name
        inventory_id
        bar_code
        measure_amount
        measure_type
        measure_id
        default
        force_charge
        extra
        fixed_price
      }
    }
  }
`;

// Add addons
export const ADD_ADDONS = gql`
  mutation ($input: [AddonInput]!) {
    addAddons(input: $input) {
      id
    }
  }
`;

// Remove addons
export const REMOVE_ADDONS = gql`
  mutation ($input: [RemoveAddonInput]!) {
    removeAddons(input: $input) {
      id
    }
  }
`;

// Add addonGroup
export const ADD_ADDON_GROUP = gql`
  mutation ($input: ProductGroupAddonInput!) {
    addAddonGroup(input: $input) {
      id
    }
  }
`;

// Remove addonGroup
export const REMOVE_ADDON_GROUP = gql`
  mutation ($input: ProductGroupAddonInput!) {
    removeAddonGroup(input: $input) {
      id
    }
  }
`;

// Get ingredients
export const GET_INGREDIENTS = gql`
  query getIngredients($filter: Filter) {
    ingredients(filter: $filter) {
      id
      name
      images {
        thumbnail
      }
      bar_code
      category {
        id
        name
      }
      measure {
        id
        name
        type
      }
      expires
      sku_no
      stocked
      stocks {
        stock_locations {
          id
          aisle
          shelf
        }
        current_stock
      }
    }
  }
`;

// Add ingredient
export const ADD_INGREDIENT = gql`
  mutation ($input: [IngredientInput]!) {
    addIngredients(input: $input) {
      id
    }
  }
`;

// Remove ingredient
export const REMOVE_INGREDIENT = gql`
  mutation ($input: [RemoveProductInput]!) {
    removeIngredient(input: $input) {
      id
    }
  }
`;

export const GET_INVENTORY_BY_BARCODE_ID = `
  query getInventoryByBarcodeAndId($filter: Filter){
    inventories(filter: $filter){
      id
      name
      bar_code
      product_code
      category_id
      measure{
        id
        type
      }
      stocks{
        current_stock
      }
    }
  }
`;

export const GET_ALLERGY_BY_NAME = `
  query getAllergy($filter: Filter){
    globalAllergies(filter: $filter){
      id
      name
      image{
        id
        thumbnail
        url
      }
    }
  }
`;

export const MUTATE_ALLERGY = `
  mutation addGlobalAllergies($input: [AllergyInput!]!) {
    addGlobalAllergies(input: $input) {
        id
        name
        image{
          id
          thumbnail
          url
        }
    }
  }
`;

// Get stock reasons of store
export const GET_STOCK_REASONS = gql`
  query getStockReasons {
    reasons {
      id
      name
    }
  }
`;
