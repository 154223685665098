import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors, CommonFonts } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(255, 255, 255, 0)'
      }
    },
    paper: {
      boxShadow: 'none',
      border: '1px solid #939DA8',
      borderRadius: 10
    },
    paperWidthSm: {
      borderRadius: 0,
    },
    closeButton: {
      position: 'absolute',
      right: 20,
      top: 20,
      backgroundColor: Colors.GRAY_LIGHT,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      }
    },
  })
)

export default useStyles
