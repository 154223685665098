import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    backIcon: {
      backgroundColor: Colors.GRAY_LIGHT,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      }
    },
  })
)

export default useStyles
