import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    boxDialog: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: theme.spacing(138),
      },
    },
    title: {
      ...CommonFonts.grayFont24,
      fontWeight: '600',
      textAlign: 'center',
      marginBottom: theme.spacing(10),
    },
    passwordField: {
      marginTop: theme.spacing(7),
    },
    actionButton: {
      marginTop: theme.spacing(15),
      padding: theme.spacing(4),
      ...CommonFonts.whiteFont14,
      '&:disabled': {
        backgroundColor: '#1174F2',
        color: Colors.WHITE,
        opacity: 0.5,
      },
    },
  })
);

export default useStyles;
