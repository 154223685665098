import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { find, isEmpty } from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import I18n from '@/i18n';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import BasicInformation from './addUpdateProduct/basicInformation';
import StockInformation from './addUpdateProduct/stockInformation';
import ImageDescription from './addUpdateProduct/imageDescription';
import AddonOptions from './addUpdateProduct/addonOptions';
import Receipe from './addUpdateProduct/receipe';

import { useQuery, useMutation, gql } from '@apollo/client';
import {
  GET_TAXES,
  GET_CATEGORIES,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
} from '@/services/inventoryService';
import {
  MERCHANT_PRICE_TYPE,
  GET_MEASURES,
} from '@/services/storeService';
import { GET_SHELVES } from '@/services/modulesService';
import { GET_STYLES } from '@/services/styleService'

import { selectMerchantSettings } from '@/store/modules/store/selectors';
import { calcGrossPriceFromPrice } from '@/utils/priceUtil';
import Errors from '@/constants/errors';
import useStyles from './styles';
import { v4 as uuid } from 'uuid';
import Allergy from './addUpdateProduct/allergy';
import { htmlToDescription } from '@/utils/stringUtil'

const validationSchema = Yup.object().shape({
  bar_code: Yup.string().required(
    I18n.t('add_product.barcode_required')
  ),
  product_name: Yup.string().required(
    I18n.t('add_product.product_name_required')
  ),
  category: Yup.object()
    .required(I18n.t('add_product.category_required'))
    .nullable(),
  price_infos: Yup.array().of(
    Yup.object().shape({
      price: Yup.string().required(
        I18n.t('add_product.price_required')
      ),
      taxes: Yup.object().nullable(),
    })
  ),
  multi_pack: Yup.object({
    checked: Yup.boolean(),
    inner_items_num: Yup.string()
      .nullable()
      .when('checked', {
        is: true,
        then: Yup.string()
          .nullable()
          .required(I18n.t('add_product.inner_items_num_required')),
      }),
    inner_item: Yup.object()
      .nullable()
      .when('checked', {
        is: true,
        then: Yup.object()
          .nullable()
          .required(I18n.t('add_product.inner_item_required')),
      }),
  }),
});

const TABS = {
  BASIC_INFORMATION: 'BasicInformation',
  STOCK_INFORMATION: 'StockInformation',
  IMAGE_DESCRIPTION: 'ImageDescription',
  ALLERGY: 'Allergy',
  ADDONS: 'Addons',
  PRODUCT_BUILDER: 'ProductBuilder',
};

export default function ResponsiveDialog({
  open,
  handleClose,
  isUpdate = false,
  product = null,
  defaultCategory,
  defaultTabId = 0,
  onSuccess,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const merchantSettings = useSelector(selectMerchantSettings);

  const [tabId, setTabId] = useState(0);

  const [productData, setProductData] = useState({
    bar_code: '',
    name: '',
    category: null,
    measure: null,
    price_infos: [],
    multi_pack: {
      checked: false,
      inner_items_num: '',
      inner_item: null,
    },
    weighed_item: {
      checked: false,
    },
    stock: '',
    addons: [],
    expires: false,
    produced: false,
    stocked: false,
    stocks: [
      {
        stock_locations: [{ id: uuid(), aisle: '', shelf: '' }],
      },
    ],
    sku_no: '',
    allergies: [],
    description: '',
    short_description: '',
    images: [],
    ingredients: [],
    variant: {}
  });

  const [barcodeErrorText, setBarcodeErrorText] = useState('');

  const { loading: isLoadingStoreTaxes, data: storeTaxes } = useQuery(
    GET_TAXES,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const {
    loading: isLoadingStoreCategories,
    data: storeCategoriesData,
  } = useQuery(GET_CATEGORIES, {
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        storeId: -1,
      },
    },
  });

  const { loading: isLoadingStorePrices } = useQuery(
    gql`
      ${MERCHANT_PRICE_TYPE}
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!isUpdate) {
          updateProductData(
            'price_infos',
            data.priceTypes.map((priceType) => {
              return {
                price_type: priceType,
                grossPrice: '',
                price: '',
                taxes: [],
              };
            })
          );
        }
      },
    }
  );

  const { loading: isLoadingMeasures, data: measuresData } = useQuery(
    gql`
      ${GET_MEASURES}
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!isUpdate) {
          updateProductData('measure', data.measures?.[0]);
        }
      },
    }
  );

  const { loading: isLoadingShelves, data: shelvesData } = useQuery(
    gql`
      ${GET_SHELVES}
    `,
    { fetchPolicy: 'no-cache' }
  );

  const { data: stylesData, loading: isLoadingStyles } = useQuery(GET_STYLES)
  const styles = stylesData?.styles || []
  var totalVariants = []
  styles.forEach(style => {
    totalVariants = totalVariants.concat(style.variants)
  })


  const [addUpdateProductMutation, { loading: isAddingProduct }] =
    useMutation(isUpdate ? UPDATE_PRODUCT : ADD_PRODUCT);

  useEffect(() => {
    if (defaultTabId) {
      setTabId(defaultTabId);
    }
  }, [defaultTabId]);

  useEffect(() => {
    if (product) {
      setProductData({
        name: product.name,
        bar_code: product.bar_code,
        category: product.category,
        measure: product.measure,
        price_infos: product.prices[0].price_infos.map(
          (priceInfo) => ({
            price_type: priceInfo?.price_type,
            grossPrice: calcGrossPriceFromPrice(
              priceInfo?.price,
              priceInfo?.taxes
            ).toFixed(2),
            price: priceInfo?.price,
            taxes: priceInfo?.taxes,
          })
        ),
        multi_pack: {
          checked: !!product.inner_item,
          inner_item: product.inner_item,
          inner_items_num: product.inner_item_measure_amount,
        },
        weighed_item: {
          checked: false,
        },
        stock: '',
        addons: product.addons.map((addon, index) => ({
          ...addon,
          limit_free_options: !!addon.allow_free,
          position: addon.position || index + 1,
        })),
        sku_no: product?.sku_no,
        expires: product?.expires,
        produced: product?.produced,
        stocked: product?.stocked,
        stocks: product?.stocks,
        allergies: product?.allergies || [],
        description: product?.description,
        descriptionString: htmlToDescription(product?.description),
        short_description: product?.short_description,
        images: product?.images,
        ingredients: product.ingredients || [],
        variant: product.variant || {}
      });
    }
  }, [product]);

  const updateProductData = (key, value) => {
    if (barcodeErrorText) {
      setBarcodeErrorText('');
    }

    setProductData({
      ...productData,
      [key]: value,
    });
  };

  useEffect(() => {
    if (defaultCategory) {
      updateProductData('category', defaultCategory);
    }
  }, [defaultCategory]);

  const tabs = [
    {
      id: TABS.BASIC_INFORMATION,
      label: t('add_product.basic_information'),
      enabled: true,
    },
    {
      id: TABS.STOCK_INFORMATION,
      label: t('add_product.stock_information'),
      enabled: true,
    },
    {
      id: TABS.IMAGE_DESCRIPTION,
      label: t('add_product.images_descriptions'),
      enabled: true,
    },
    {
      id: TABS.ALLERGY,
      label: t('add_product.allergies'),
      enabled: !!merchantSettings?.products?.allergy,
    },
    {
      id: TABS.ADDONS,
      label: t('add_product.addon_options'),
      enabled: !!merchantSettings?.products?.addon,
    },
    {
      id: TABS.PRODUCT_BUILDER,
      label: t('add_product.recipe'),
      enabled: !!merchantSettings?.products?.recipe,
    },
  ];

  const isFirstTab = tabId === 0;
  const isLastTab = tabId === tabs.length - 1;

  const onChangeTab = (event, value) => {
    if (isUpdate) {
      // block user from clicking on tabs in add product modal
      setTabId(value);
    }
  };

  const onAddUpdateProduct = () => {
    if (
      find(productData.price_infos, (price_info) =>
        isEmpty(price_info.taxes)
      )
    )
      return;

    const multi_pack_para = productData.multi_pack.checked
      ? {
        inner_item: productData.multi_pack.inner_item.bar_code,
        inner_item_measure_amount: parseFloat(
          productData.multi_pack.inner_items_num
        ),
      }
      : {};
    const variant_para = isEmpty(productData.variant)
      ? {}
      : { variant_id: productData.variant.id };
    let removedAddonGroups = [];
    product?.addons?.forEach((group) => {
      if (!find(productData.addons, { id: group.id })) {
        removedAddonGroups.push({
          id: group.id,
          flag: 'delete',
        });
      }
    });

    let newAddonGroups = productData.addons.map((group) => ({
      id: group.id,
      group: group.group,
      allow_free: group.allow_free || 0,
      mandatory: group.mandatory,
      multi_selection: !!group.multi_selection,
      default_all: !!group.default_all,
      description: group.description,
      position: group.position,
    }));

    let addonGroupsForInput = [
      ...removedAddonGroups,
      ...newAddonGroups,
    ];

    const allergies = productData?.allergies?.map(
      (allergy) => allergy.id
    );

    addUpdateProductMutation({
      variables: {
        product: {
          name: productData.name,
          bar_code: productData.bar_code,
          category_id: productData.category.id,
          measure_id: productData.measure?.id,
          stocks: [
            {
              stock_locations:
                productData.stocks[0].stock_locations?.map(
                  (location) => location.id
                ),
              stock: parseInt(productData.stock || '0'),
            },
          ],
          sku_no: productData?.sku_no,
          description: productData?.description,
          short_description: productData?.short_description,
          images: productData?.images
            ?.map((image) => ({
              id: image.id,
              flag: image.isDelete ? 'delete' : 'add',
            }))
            ?.sort((a, b) =>
              b.flag
                .toLocaleLowerCase()
                .localeCompare(a.flag.toLocaleLowerCase())
            ),
          expires: productData.expires,
          produced: productData.produced,
          stocked: productData.stocked,
          prices: [
            {
              price_infos: productData.price_infos.map(
                (price_info) => {
                  return {
                    price_type_id: price_info.price_type.id,
                    price: parseFloat(price_info.price),
                    taxes: price_info.taxes.map((tax) => ({
                      id: tax.id,
                      name: tax.name,
                      rate: tax.rate,
                    })),
                  };
                }
              ),
            },
          ],
          addons: addonGroupsForInput,
          allergy_ids: allergies,
          ingredients: productData.ingredients.map((ingredient) => ({
            id: ingredient.id,
            ingredient_measure_amount: parseFloat(
              ingredient.ingredient_measure_amount
            ),
          })),
          ...multi_pack_para,
          ...variant_para
        },
      },
    })
      .then((response) => {
        enqueueSnackbar(
          isUpdate
            ? t('add_product.update_product_success')
            : t('add_product.add_product_success')
        );
        if (isUpdate) {
          onSuccess(response.data.updateProduct);
        } else {
          onSuccess();
        }
        handleClose();
      })
      .catch((error) => {
        const message = error?.message || error?.errors[0].message;
        if (message === Errors.BARCODE_ALREADY_EXIST) {
          setBarcodeErrorText(t('add_product.barcode_already_exist'));
        } else {
          enqueueSnackbar(message, { variant: 'error' });
        }
      });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Formik
        initialValues={{
          bar_code: productData.bar_code,
          product_name: productData.name,
          category: productData.category,
          price_infos: productData.price_infos,
          multi_pack: productData.multi_pack,
        }}
        validationSchema={validationSchema}
        onSubmit={onAddUpdateProduct}
      >
        {({
          handleBlur,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isValid,
        }) => (
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit}
          >
            <DialogTitle id="alert-dialog-slide-title">
              <Grid
                container
                alignItems="center"
                justify="space-between"
                alignContent="center"
              >
                <Grid item xs={1} />
                <Grid className={classes.headerText} item xs={10}>
                    {isUpdate
                      ? t('add_product.update_product')
                      : t('add_product.add_new_product')}
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'right' }}>
                  <IconButton
                    onClick={handleClose}
                    className={classes.closeButton}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.rootDialog}>
                <Tabs
                  className={classes.tabs}
                  value={tabId}
                  onChange={onChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {tabs
                    .filter((item) => item.enabled)
                    .map((item, index) => (
                      <Tab
                        className={classes.tab}
                        style={{ fontSize: 18 }}
                        key={`tab-${index}`}
                        label={item.label}
                      />
                    ))}
                </Tabs>
                {tabs
                  .filter((item) => item.enabled)
                  .map((item, index) => {
                    if (item.id === TABS.BASIC_INFORMATION) {
                      return (
                        <TabPanel
                          key={`tab-panel-${index}`}
                          value={tabId}
                          index={index}
                        >
                          <BasicInformation
                            merchantSettings={merchantSettings}
                            productData={productData}
                            taxes={storeTaxes?.store?.taxes || []}
                            categories={
                              storeCategoriesData?.categories || []
                            }
                            measures={measuresData?.measures || []}
                            variants={totalVariants}
                            updateProductData={updateProductData}
                            handleBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            barcodeErrorText={barcodeErrorText}
                          />
                        </TabPanel>
                      );
                    } else if (item.id === TABS.STOCK_INFORMATION) {
                      return (
                        <TabPanel
                          key={`tab-panel-${index}`}
                          value={tabId}
                          index={index}
                        >
                          <StockInformation
                            key={productData}
                            productData={productData}
                            updateProductData={updateProductData}
                            shelvesData={shelvesData}
                          />
                        </TabPanel>
                      );
                    } else if (item.id === TABS.IMAGE_DESCRIPTION) {
                      return (
                        <TabPanel
                          key={`tab-panel-${index}`}
                          value={tabId}
                          index={index}
                        >
                          <ImageDescription
                            key={`${productData}-image-description`}
                            productData={productData}
                            updateProductData={updateProductData}
                          />
                        </TabPanel>
                      );
                    } else if (item.id === TABS.ALLERGY) {
                      return (
                        <TabPanel
                          key={`tab-panel-${index}`}
                          value={tabId}
                          index={index}
                        >
                          <Allergy
                            key={productData}
                            productData={productData}
                            updateProductData={updateProductData}
                          />
                        </TabPanel>
                      );
                    } else if (item.id === TABS.ADDONS) {
                      return (
                        <TabPanel
                          key={`tab-panel-${index}`}
                          value={tabId}
                          index={index}
                        >
                          <AddonOptions
                            productData={productData}
                            updateProductData={updateProductData}
                          />
                        </TabPanel>
                      );
                    } else {
                      return (
                        <TabPanel
                          key={`tab-panel-${index}`}
                          value={tabId}
                          index={index}
                        >
                          <Receipe
                            productData={productData}
                            updateProductData={updateProductData}
                          />
                        </TabPanel>
                      );
                    }
                  })}
              </div>
            </DialogContent>
            <DialogActions style={{ paddingRight: 30 }}>
              <Button
                color="primary"
                onClick={handleClose}
                className={classes.cancelButton}
              >
                {t('button_group.cancel')}
              </Button>

              {!isUpdate && (
                <>
                  {!isFirstTab && (
                    <Button
                      color="primary"
                      onClick={() => setTabId(tabId - 1)}
                      className={classes.cancelButton}
                      style={{ position: 'absolute', left: 30 }}
                    >
                      {t('button_group.back')}
                    </Button>
                  )}
                  {!isLastTab && (
                    <Button
                      disabled={!isValid}
                      color="primary"
                      onClick={() => setTabId(tabId + 1)}
                    >
                      {t('button_group.next')}
                    </Button>
                  )}
                </>
              )}
              <Button
                color="primary"
                onClick={() => onAddUpdateProduct()}
                disabled={!isValid}
              >
                {isUpdate
                  ? t('button_group.update')
                  : t('button_group.skip_and_add')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>

      {(isLoadingStoreCategories ||
        isLoadingStoreTaxes ||
        isLoadingMeasures ||
        isLoadingStorePrices ||
        isLoadingStyles ||
        isAddingProduct) && <PreLoader size={25} />}
    </Dialog>
  );
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};
