import { CommonFonts } from '../../../constants/fonts'
import { Colors } from '../../../constants/colors'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme =>
  createStyles({
    emailField: {
      '&>div': {
        marginTop: '24px',
      }
    },
    passwordsContainer: {
      marginTop: 35
    },
    passwordField: {
      '&>div': {
        marginTop: '24px',
      }
    },
    passwordWrapper: {
      paddingRight: 20,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0
      },
    },
    confirmWrapper: {
      paddingLeft: 20,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        marginTop: 20
      },
    },
    bottomContainer: {
      marginTop: 50,
      textAlign: 'center',
      letterSpacing: 0.2
    },
    bottomText: {
      ...CommonFonts.darkFont16,
    },
    bottomBlueText: {
      ...CommonFonts.blueFont16
    },
    buttonWrapper: {
      margin: '50px 0px 70px 0px'
    },
    signupButton: {
      height: 54,
      maxWidth: 262,
      textTransform: 'none',
      fontSize: 14,
    },
  })
)

export default useStyles
