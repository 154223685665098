import React, { useState } from 'react';

import {
  Box,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import Input from '@/components/textField';
import Button from '@/components/button';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { MUTATE_USER_DETAIL } from '@/services/userManagementService';

const ChangePasswordDialog = ({
  isVisible,
  onCloseDialog,
  userId,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const onCloseModal = () => {
    onCloseDialog();
    handleReset();
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
      t('changePassword.current_password_is_required')
    ),
    newPassword: Yup.string()
      .required(t('changePassword.new_password_is_required'))
      .test(
        'uppercase-validation',
        t('signup.uppercase_required'),
        function (value) {
          return /[A-Z]/.test(value);
        }
      )
      .test(
        'lowercase-validation',
        t('signup.lowercase_required'),
        function (value) {
          return /[a-z]/.test(value);
        }
      )
      .test(
        'number-validation',
        t('signup.number_required'),
        function (value) {
          return /\d/.test(value);
        }
      )
      .test(
        'length-validation',
        t('signup.min_chars'),
        function (value) {
          return value?.length >= 8;
        }
      ),
  });

  const {
    handleSubmit,
    handleReset,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      newPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await mutateUserDetail({
          variables: {
            input: {
              id: userId,
              old_password: values.currentPassword,
              password: values.newPassword,
            },
          },
        });
        onCloseModal();
        enqueueSnackbar(t('changePassword.password_updated'));
      } catch (error) {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const [mutateUserDetail] = useMutation(
    gql`
      ${MUTATE_USER_DETAIL}
    `
  );

  const isDisable =
    !isValid ||
    _.isEmpty(values?.currentPassword) ||
    _.isEmpty(values?.newPassword);

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      onClose={isLoading ? () => {} : onCloseModal}
      aria-labelledby={`{change-password-dialog-title}`}
      open={isVisible}
      disableBackdropClick
      className={classes.boxDialog}
    >
      <DialogTitle
        id={`{change-password-dialog-title}`}
        onClose={onCloseModal}
      />
      <DialogContent>
        <Box padding={10} paddingTop={0}>
          <Typography className={classes.title}>
            {t('changePassword.title')}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Input
              isPassword
              label={t('changePassword.current_password')}
              name="currentPassword"
              value={values.currentPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                !!(touched.currentPassword && errors.currentPassword)
              }
              helperText={
                touched.currentPassword && errors.currentPassword
              }
            />
            <Box marginTop={7}>
              <Input
                isPassword
                label={t('changePassword.new_password')}
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(touched.newPassword && errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
            </Box>

            <Button
              type="submit"
              className={classes.actionButton}
              variant="contained"
              color="primary"
              disabled={isDisable}
            >
              {t('changePassword.title')}
            </Button>
          </form>
        </Box>
      </DialogContent>
      {isLoading && <PreLoader size={25} />}
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

export default ChangePasswordDialog;
