import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    viewButton: {
      textTransform: 'none',
      marginRight: 40
    }
  })
);

export default useStyles;
