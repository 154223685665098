import { Box, Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SettingAccordion from '.';
import PreLoader from '@/components/preLoader';
import PosTypesPicker from '../posTypesPicker';
import SettingInput from '../input';
import ImagePicker from '@/components/imagePicker';
import SettingSwitch from '@/components/settingSwitch';
import PosArrangeCategory from '../posArrangeCategory';
import PosPrintPromotion from '../posPrintPromotion';
import PosAddressType from '../posAddressType';
import SokSellSelectedProduct from '../sokSellSelectedProduct';

import {
  gql,
  useMutation,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import {
  MUTATE_TOUCHPOINT_KIOSK_SETTING,
  PROMOTION_LIST,
  TOUCHPOINT_SETTING,
} from '@/services/storeService';

import { selectSelectedStore } from '@/store/modules/store/selectors';

import * as Yup from 'yup';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import I18n from '@/i18n';

const welcomeTextSchema = Yup.object().shape({
  call_to_action_text: Yup.string().required(
    I18n.t('settings.touchpoint_kiosk.welcome_text_required')
  ),
});

const supportTextSchema = Yup.object().shape({
  call_to_action_support_text: Yup.string().required(
    I18n.t('settings.touchpoint_kiosk.support_text_required')
  ),
});

const touchpointSOKQuery = gql`
  ${TOUCHPOINT_SETTING}
`;

const promotionListQuery = gql`
  ${PROMOTION_LIST}
`;

const TouchpointKiosk = ({ storeDetail }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const selectedStore = useSelector(selectSelectedStore);
  const { id: storeId } = selectedStore;

  const [isExpand, setIsExpand] = useState(false);

  const onExpand = (value) => setIsExpand(value);

  const {
    loading,
    error,
    data: touchPointData,
    refetch: refetchTouchpointData,
  } = useQuery(touchpointSOKQuery, {
    fetchPolicy: 'no-cache',
  });

  const [getPromotion, { data: promotionsData }] = useLazyQuery(
    promotionListQuery
  );

  const sokSettings =
    touchPointData?.store?.settings?.touchpoint_settings || {};

  const orderTypes = touchPointData?.store?.order_types || [];
  const paymentTypes = touchPointData?.store?.payment_types || [];

  const [mutateStore, { loading: isUpdatingStore }] = useMutation(
    gql`
      ${MUTATE_TOUCHPOINT_KIOSK_SETTING}
    `,
    {
      onCompleted: () => refetchTouchpointData(),
    }
  );

  useEffect(() => {
    if (isExpand) {
      // getTouchpointSOK();
      getPromotion();
    }
  }, [isExpand]);

  const updateGeneralSettings = (key, value) => {
    const payload = {
      variables: {
        store: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              self_order_kiosk: {
                [key]: value,
              },
            },
          },
        },
      },
      update: (store, { data: { updateStore } }) => {
        const cache = store.readQuery({
          query: touchpointSOKQuery,
        });

        const updatedData = {
          ...cache,
          store: {
            ...cache.store,
            settings: {
              ...cache.store.settings,
              touchpoint_settings: {
                ...cache.store.settings.touchpoint_settings,
                self_order_kiosk: {
                  ...cache.store.settings.touchpoint_settings
                    .self_order_kiosk,
                  [key]: value,
                },
              },
            },
          },
        };
        store.writeQuery({
          query: touchpointSOKQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateStore: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              self_order_kiosk: {
                [key]: value,
                __typename: 'KioskSettings',
              },
              __typename: 'StoreDeviceSettings',
            },
            __typename: 'StoreSettings',
          },
          __typename: 'Store',
        },
      },
    };
    return mutateStore(payload);
  };

  const updateProductSettings = (key, value) => {
    const payload = {
      variables: {
        store: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              self_order_kiosk: {
                product_view: {
                  [key]: value,
                },
              },
            },
          },
        },
      },
      update: (store, { data: { updateStore } }) => {
        const cache = store.readQuery({
          query: touchpointSOKQuery,
        });

        const updatedData = {
          ...cache,
          store: {
            ...cache.store,
            settings: {
              ...cache.store.settings,
              touchpoint_settings: {
                ...cache.store.settings.touchpoint_settings,
                self_order_kiosk: {
                  ...cache.store.settings.touchpoint_settings
                    .self_order_kiosk,
                  product_view: {
                    ...cache.store.settings.touchpoint_settings
                      .self_order_kiosk.product_view,
                    [key]: value,
                  },
                },
              },
            },
          },
        };
        store.writeQuery({
          query: touchpointSOKQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateStore: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              self_order_kiosk: {
                product_view: {
                  [key]: value,
                  __typename: 'ProductViewSettings',
                },
                __typename: 'KioskSettings',
              },
              __typename: 'StoreDeviceSettings',
            },
            __typename: 'StoreSettings',
          },
          __typename: 'Store',
        },
      },
    };
    return mutateStore(payload);
  };

  const updateReceiptSettings = (key, value) => {
    const payload = {
      variables: {
        store: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              self_order_kiosk: {
                receipts: {
                  [key]: value,
                },
              },
            },
          },
        },
      },
      update: (store, { data: { updateStore } }) => {
        const cache = store.readQuery({
          query: touchpointSOKQuery,
        });

        const updatedData = {
          ...cache,
          store: {
            ...cache.store,
            settings: {
              ...cache.store.settings,
              touchpoint_settings: {
                ...cache.store.settings.touchpoint_settings,
                self_order_kiosk: {
                  ...cache.store.settings.touchpoint_settings
                    .self_order_kiosk,
                  receipts: {
                    ...cache.store.settings.touchpoint_settings
                      .self_order_kiosk.receipts,
                    [key]: value,
                  },
                },
              },
            },
          },
        };
        store.writeQuery({
          query: touchpointSOKQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateStore: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              self_order_kiosk: {
                receipts: {
                  [key]: value,
                  __typename: 'ReceiptsSettings',
                },
                __typename: 'KioskSettings',
              },
              __typename: 'StoreDeviceSettings',
            },
            __typename: 'StoreSettings',
          },
          __typename: 'Store',
        },
      },
    };
    return mutateStore(payload);
  };

  const updateImageBanner = (value) => {
    const payload = {
      variables: {
        store: {
          id: storeId,
          settings: {
            touchpoint_settings: {
              self_order_kiosk: {
                banner: value,
              },
            },
          },
        },
      },
      refetchQueries: [
        {
          query: touchpointSOKQuery,
        },
      ],
      onError: (error) => {
        throw new Error(error);
      },
    };
    return mutateStore(payload);
  };

  const getPromotionNames = (values) =>
    values?.map(
      (item) =>
        promotionsData?.promotions.find((pro) => pro.id === item)
          ?.promo_name
    ) + '' || '';

  return (
    <SettingAccordion
      id="touchpointKiosk"
      title={t('settings.touchpoint_kiosk.title')}
      onExpand={onExpand}
    >
      {(loading || isUpdatingStore) && <PreLoader size={30} />}
      <Grid container>
        {/* General Settings Section */}
        <Typography className={classes.subTitle}>
          {t('settings.touchpoint_kiosk.general')}
        </Typography>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <PosTypesPicker
              title={t(
                'settings.touchpoint_storefront.update_order_types_accepted'
              )}
              value={sokSettings?.self_order_kiosk?.order_types}
              options={orderTypes}
              name="orderTypesStorefront"
              label={t('settings.touchpoint_kiosk.order_types')}
              description={t(
                'settings.touchpoint_kiosk.order_types_description'
              )}
              onChange={(value) =>
                updateGeneralSettings('order_types', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <PosTypesPicker
              name="posPaymentTypeAccepted"
              title={t(
                'settings.touchpoint_kiosk.update_payment_types_accepted'
              )}
              value={sokSettings?.self_order_kiosk?.payment_types}
              options={paymentTypes}
              label={t('settings.touchpoint_kiosk.payment_types')}
              description={t(
                'settings.touchpoint_kiosk.payment_types_desciption'
              )}
              onChange={(value) =>
                updateGeneralSettings('payment_types', value)
              }
            />
          </Grid>
        </Grid>

        {/* Call to action Section */}
        <Typography className={classes.subTitle}>
          {t('settings.touchpoint_kiosk.call_to_action')}
        </Typography>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.titleText}>
              {t('settings.touchpoint_kiosk.call_to_action_text')}
            </Typography>
            <Typography className={classes.descriptionText}>
              {t(
                'settings.touchpoint_kiosk.call_to_action_text_description'
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.titleText}>
              {t(
                'settings.touchpoint_kiosk.call_to_action_support_text'
              )}
            </Typography>
            <Typography className={classes.descriptionText}>
              {t(
                'settings.touchpoint_kiosk.call_to_action_support_text_description'
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justify="space-between"
          spacing={3}
          style={{ marginTop: 20 }}
        >
          <Grid item xs={12} sm={5}>
            <SettingInput
              name="call_to_action_text"
              label={t('settings.touchpoint_kiosk.welcome_text')}
              value={
                sokSettings?.self_order_kiosk?.call_to_action_text
              }
              validationSchema={welcomeTextSchema}
              onChange={(value) =>
                updateGeneralSettings('call_to_action_text', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SettingInput
              name="call_to_action_support_text"
              label={t('settings.touchpoint_kiosk.support_text')}
              value={
                sokSettings?.self_order_kiosk
                  ?.call_to_action_support_text
              }
              validationSchema={supportTextSchema}
              onChange={(value) =>
                updateGeneralSettings(
                  'call_to_action_support_text',
                  value
                )
              }
            />
          </Grid>
        </Grid>

        {/* Banners Section */}
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.titleText}>
              {t('settings.touchpoint_kiosk.banners')}
            </Typography>
            <Typography className={classes.descriptionText}>
              {t('settings.touchpoint_kiosk.baneers_description')}
            </Typography>
            <div className={classes.boxImage}>
              <ImagePicker
                id={sokSettings?.self_order_kiosk?.banner?.id}
                ignoreCrop={true}
                widthCrop={428}
                heightCrop={250}
                imageSizeRequired
                name="kioskBanner"
                title={t('settings.touchpoint_kiosk.update_banner')}
                thumbnail={
                  sokSettings?.self_order_kiosk?.banner?.url || ''
                }
                onChange={(value) =>
                  updateImageBanner({
                    id: value.id,
                  })
                }
              />
            </div>
          </Grid>
        </Grid>

        {/* Product Arrangement Section */}
        <Typography className={classes.subTitle}>
          {t('settings.touchpoint_kiosk.product_arrangement')}
        </Typography>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="displayProductCategoryKiosk"
              label={t(
                'settings.touchpoint_kiosk.group_products_by_category'
              )}
              description={t(
                'settings.touchpoint_kiosk.group_products_by_category_description'
              )}
              value={
                sokSettings?.self_order_kiosk?.product_view
                  ?.by_category
              }
              onChange={(value) =>
                updateProductSettings('by_category', value)
              }
            />
          </Grid>
          {/* <Grid item xs={12} sm={5}>
            <SokSellSelectedProduct
              value={
                sokSettings?.self_order_kiosk?.product_view
                  ?.show_selected
              }
              options={
                sokSettings?.self_order_kiosk?.product_view
                  ?.products || []
              }
              name="sellSelectedProduct"
              label={t(
                'settings.touchpoint_kiosk.sell_selected_products'
              )}
              description={t(
                'settings.touchpoint_kiosk.sell_selected_products_description'
              )}
              isGroupByCategory={
                sokSettings?.self_order_kiosk?.product_view
                  ?.by_category
              }
              onChange={(value) =>
                updateProductSettings('show_selected', value)
              }
              onChangeOptions={(value) =>
                updateProductSettings('products', value)
              }
            />
          </Grid> */}
        </Grid>

        {sokSettings?.self_order_kiosk?.product_view?.by_category && (
          <Grid container justify="space-between" spacing={3}>
            <Grid item xs={12} sm={5}>
              <SettingSwitch
                name="displayAlphabeticalOrder"
                label={t(
                  'settings.touchpoint_kiosk.display_in_alphabetical_order'
                )}
                description={t(
                  'settings.touchpoint_kiosk.display_in_alphabetical_order_description'
                )}
                value={
                  sokSettings?.self_order_kiosk?.product_view
                    ?.sort_by_name
                }
                onChange={(value) =>
                  updateProductSettings('sort_by_name', value)
                }
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <PosArrangeCategory
                isSortByName={
                  sokSettings?.self_order_kiosk?.product_view
                    ?.sort_by_name
                }
                value={
                  sokSettings?.self_order_kiosk?.product_view
                    ?.categories || []
                }
                label={t(
                  'settings.touchpoint_kiosk.categories_position'
                )}
                description={t(
                  'settings.touchpoint_kiosk.categories_position_description'
                )}
                onChange={(value) =>
                  updateProductSettings('categories', value)
                }
              />
            </Grid>
          </Grid>
        )}

        {/* Receipt Settings Section */}
        <Typography className={classes.subTitle}>
          {t('settings.touchpoint_kiosk.receipt_settings')}
        </Typography>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="logoStorefront"
              label={t('settings.touchpoint_kiosk.logo')}
              description={t(
                'settings.touchpoint_kiosk.logo_description'
              )}
              value={sokSettings?.self_order_kiosk?.receipts?.logo}
              onChange={(value) =>
                updateReceiptSettings('logo', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="websiteStorefront"
              label={t('settings.touchpoint_kiosk.website_address')}
              description={t(
                'settings.touchpoint_kiosk.website_address_description'
              )}
              value={sokSettings?.self_order_kiosk?.receipts?.website}
              onChange={(value) =>
                updateReceiptSettings('website', value)
              }
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="phoneNumberStorefront"
              label={t('settings.touchpoint_kiosk.phone_number')}
              description={t(
                'settings.touchpoint_kiosk.phone_number_description'
              )}
              value={sokSettings?.self_order_kiosk?.receipts?.phone}
              onChange={(value) =>
                updateReceiptSettings('phone', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <PosAddressType
              isFetch={isExpand}
              value={
                sokSettings?.self_order_kiosk?.receipts?.address_type
              }
              name="addressTypeStorefront"
              label={t('settings.touchpoint_kiosk.address_type')}
              description={t(
                'settings.touchpoint_kiosk.address_type_description'
              )}
              onChange={(value) =>
                updateReceiptSettings('address_type', value)
              }
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="RegistrationNumberStorefront"
              label={t(
                'settings.touchpoint_kiosk.registration_number'
              )}
              description={t(
                'settings.touchpoint_kiosk.registration_number_description'
              )}
              value={
                sokSettings?.self_order_kiosk?.receipts
                  ?.registration_number
              }
              onChange={(value) =>
                updateReceiptSettings('registration_number', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <PosPrintPromotion
              value={
                sokSettings?.self_order_kiosk?.receipts?.promotions ||
                ''
              }
              name="printPromotionStorefront"
              label={t('settings.touchpoint_kiosk.print_promotion')}
              description={
                sokSettings?.self_order_kiosk?.receipts?.promotions
                  ?.length > 0
                  ? t(
                      'settings.touchpoint_kiosk.print_promotion_not_empty',
                      {
                        promotions: getPromotionNames(
                          sokSettings?.self_order_kiosk?.receipts
                            ?.promotions
                        ),
                      }
                    )
                  : t(
                      'settings.touchpoint_kiosk.print_promotion_description'
                    )
              }
              actionText={
                sokSettings?.self_order_kiosk?.receipts?.promotions
                  ?.length > 0
                  ? t('settings.update')
                  : t('settings.select')
              }
              onChange={(value) =>
                updateReceiptSettings('promotions', [
                  ...sokSettings?.self_order_kiosk?.receipts
                    ?.promotions,
                  value,
                ])
              }
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={5}>
            <SettingSwitch
              name="QrCodeStorefront"
              label={t('settings.touchpoint_kiosk.qr_code')}
              description={t(
                'settings.touchpoint_kiosk.qr_code_description'
              )}
              value={sokSettings?.self_order_kiosk?.receipts?.barcode}
              onChange={(value) =>
                updateReceiptSettings('barcode', value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <SettingInput
              value={
                sokSettings?.self_order_kiosk?.receipts
                  ?.custom_message
              }
              name="customMessageStorefront"
              label={t('settings.touchpoint_kiosk.custom_message')}
              description={
                sokSettings?.self_order_kiosk?.receipts
                  ?.custom_message
                  ? t(
                      'settings.touchpoint_kiosk.custom_message_description',
                      {
                        message:
                          sokSettings?.self_order_kiosk?.receipts
                            ?.custom_message,
                      }
                    )
                  : t(
                      'settings.touchpoint_kiosk.custom_message_description_empty'
                    )
              }
              descriptionType
              onChange={(value) =>
                updateReceiptSettings('custom_message', value)
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </SettingAccordion>
  );
};

export default TouchpointKiosk;
