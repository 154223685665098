import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors, CommonFonts } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(10, 15),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(15, 2),
      },
    },
    titleText: {
      ...CommonFonts.darkBoldFont30,
      fontWeight: 500,
      marginTop: theme.spacing(8),
      textAlign: 'center'
    },
    descriptionText: {
      ...CommonFonts.darkFont16,
      marginTop: theme.spacing(5),
      textAlign: 'center'
    },
    futureTimeText: {
      ...CommonFonts.darkFont12,
      color: Colors.RED,
      marginTop: theme.spacing(5),
      textAlign: 'center'
    },
    reportContainer: {
      marginTop: theme.spacing(25),
    }
  })
)

export default useStyles
