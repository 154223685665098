import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { isEmpty, find } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  Box,
  Breadcrumbs,
  Link,
  Grid,
  Paper,
  List,
  ListItem,
  Avatar,
  Typography,
  Hidden
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link as RouterLink } from 'react-router-dom';
import AppLayout from '@/components/appLayout';
import SearchHeader from '../components/StyleVariantsSearchHeader'
import NoStyles from '../components/NoItems'
import PreLoader from '@/components/preLoader';
import StyleTable from './table'
import AddUpdateStyle from './addUpdateStyle'

import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_STYLES, MUTATE_ADD_STYLE } from '@/services/styleService'

import { selectSelectedStore, selectLocalisation } from '@/store/modules/store/selectors'

import { GENDERS_FOR_STYLE } from '../helper'
import { contains } from '@/utils/stringUtil'
import useStyles from './styles';

const ONE_PAGE_LIMIT = 10

const StyleVariants = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const selectedStore = useSelector(selectSelectedStore)
  const localisation = useSelector(selectLocalisation)

  const [genders, setGenders] = useState(GENDERS_FOR_STYLE)
  const [inputSearch, setInputSearch] = useState("");
  const [openAddNewModal, setOpenAddNewModal] = useState(false)
  const [styles, setStyles] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedStyle, setSelectedStyle] = useState(null)

  const [isAllStylesLoaded, setIsAllStylesLoaded] = useState(false)

  const filteredStyles = styles
    .filter(style => contains(style.name, inputSearch))
  // .filter(style => genders.includes(style.gender))

  // get styles from api
  const [getStyles, { loading: isLoadingStyles }] = useLazyQuery(GET_STYLES, {
    onCompleted: (data) => {
      // if response give us less then 10 results, set all styles loaded
      if (data.styles.length < 10) {
        setIsAllStylesLoaded(true);
      } else {
        setIsAllStylesLoaded(false)
      }

      // update the array holding styles
      setStyles([...styles, ...data.styles]);
    },
    onError: () => {
      setIsAllStylesLoaded(true);
    },
    fetchPolicy: 'no-cache',
  });

  const [addUpdateStyleMutation, { loading: isUpdatingStyle }] = useMutation(MUTATE_ADD_STYLE, {
    onCompleted: () => {
      const successMsg = selectedStyle ? t('styles.style_update_success') : t('styles.style_add_success')
      enqueueSnackbar(successMsg)
      setOpenAddNewModal(false)
      setStyles([])
      setCurrentPage(1);
      refetchStyles()
    },
    onError: (error) => {
      const message = error?.message || error?.error[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  })

  // change current page when user reaches bottom
  const handleLoadMore = () => {
    if (isEmpty(filteredStyles)) return
    setCurrentPage(currentPage + 1);
  };

  const refetchStyles = () => {
    getStyles({
      variables: {
        filter: {
          count: true,
          page: currentPage,
          limit: ONE_PAGE_LIMIT,
        },
      },
    });
  }

  useEffect(() => {
    refetchStyles()
  }, [currentPage]);

  useEffect(() => {
    setStyles([])
    setCurrentPage(1);
  }, [selectedStore]);

  const onClickVoid = (style) => {
    var variables = {
      input: {
        id: style.id,
        flag: 'delete',
      }
    }
    addUpdateStyleMutation({
      variables
    })
  }

  const onClickUpdate = (style) => {
    setSelectedStyle(style)
    setOpenAddNewModal(true)
  }

  const onAddUpdateStyle = (style, options) => {
    const originalVariants = selectedStyle?.variants || []
    var variants = style.variants.map(variant => ({
      id: variant.id,
      name: variant.name,
      code: variant.code,
      values: variant.values.map(value => value.id),
      flag: find(originalVariants, { id: variant.id }) ? 'update' : 'add',
    }))
    originalVariants.forEach(variant => {
      if (!find(style.variants, { id: variant.id })) {
        variants.push({
          id: variant.id,
          name: variant.name,
          code: variant.code,
          values: variant.values.map(value => value.id),
          flag: 'delete',
        })
      }
    })
    var variables = {
      input: {
        id: style.id,
        flag: selectedStyle ? 'update' : 'add',
        name: style.name,
        code: style.code,
        gender: style.gender,
        options: options.map(option => option.id),
        variants
      }
    }
    addUpdateStyleMutation({
      variables
    })
  }

  return (
    <AppLayout
      className={classes.appLayout}
      withFooter
      header
      withServiceDropdown={true}
    >
      <div className={classes.root}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                className={classes.breadcrumbsText}
                color="primary"
                component={RouterLink}
                to="/business-manager"
              >
                {t('settings.business_manager')}
              </Link>
              <Link
                className={classes.breadcrumbsText}
                color={'textPrimary'}
                onClick={() => { }}
              >
                {t('menu.style')}
              </Link>
            </Breadcrumbs>
          </Grid>
          <SearchHeader
            genders={genders}
            onChangeGenders={(value) => isEmpty(value)
              ? {}
              : setGenders(value)
            }
            onChangeSearch={setInputSearch}
            onAdd={() => setOpenAddNewModal(true)}
          />
          <Grid item xs={12}>
            <Paper className={classes.emptyProductsContainer}>
              {(isEmpty(filteredStyles) && !isLoadingStyles)
                ?
                <NoStyles
                  title={t('styles.no_style_variants')}
                />
                :
                <InfiniteScroll
                  dataLength={filteredStyles.length}
                  next={handleLoadMore}
                  hasMore={!isAllStylesLoaded}
                  loader={
                    isLoadingStyles && currentPage !== 1 && (
                      <div className={classes.root}>
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                      </div>
                    )
                  }
                >
                  <StyleTable
                    styles={filteredStyles}
                    isLoadingStyles={isLoadingStyles}
                    currentPage={currentPage}
                    onClickVoid={onClickVoid}
                    onClickUpdate={onClickUpdate}
                  />
                </InfiniteScroll>
              }
            </Paper>
          </Grid>
        </Grid>
      </div>

      {openAddNewModal &&
        <AddUpdateStyle
          open={openAddNewModal}
          handleClose={() => {
            setOpenAddNewModal(false)
            setSelectedStyle(null)
          }}
          selectedStyle={selectedStyle}
          isUpdatingStyle={isUpdatingStyle}
          onAddUpdateStyle={onAddUpdateStyle}
        />
      }
    </AppLayout>
  )
}

export default StyleVariants

