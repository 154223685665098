import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  TableRow,
  TableCell,
  withStyles,
  Typography
} from '@material-ui/core'

import { Colors, CommonFonts } from '@/theme'

import useStyles from './styles'

const Row = ({ item, onClickConfigure }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const rowCells = [
    {
      id: 'type',
      label: item.name,
    },
  ];

  return (
    <TableRow hover style={{ position: 'relative' }}>
      {rowCells.map(rowCell => (
        <StyledTableCell key={rowCell.id}>
          {rowCell.label}
        </StyledTableCell>
      ))}
      <StyledTableCell align='right'>
        <Typography
          className={classes.configureText}
          onClick={() => onClickConfigure(item)}
        >
          {t('settings.inventory.configure')}
        </Typography>
      </StyledTableCell>
    </TableRow>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    ...CommonFonts.darkFont16,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont16
  },
}))(TableCell);

Row.propTypes = {
  item: PropTypes.object,
}

export default Row