export const Directions = [
  {
    name: 'touchPoints',
    path: '/touchpoints-management/touchpoints',
  },
  {
    name: 'archivedTouchpoints',
    path: '/touchpoints-management/archived-touchpoints',
  },
];

export const TouchpointsType = [
  { id: 'pos', name: 'Pos' },
  { id: 'self_order_kiosk', name: 'Self Order Kiosk' },
  { id: 'digital_front', name: 'Digital Front' },
];
