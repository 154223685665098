import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { Box, TextField, Button } from '@material-ui/core';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  GET_ALLERGY_BY_NAME,
  MUTATE_ALLERGY,
} from '@/services/inventoryService';
import useStyles from './styles';
import { v4 as uuid } from 'uuid';
import useDebounce from '@/hooks/useDebounce';

const filter = createFilterOptions();

const AllergySearchInput = ({ onSelectAllergy, ...restProps }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const debounceSearch = useDebounce(inputValue, 1000);

  const { data, loading: isLoadingAllergies } = useQuery(
    gql`
      ${GET_ALLERGY_BY_NAME}
    `,
    {
      variables: {
        filter: {
          count: true,
          page: 1,
          limit: 10,
          name: `%${debounceSearch.toLowerCase()}%`,
        },
      },
      fetchPolicy: 'no-cache',
    }
  );
  const allergies = data?.globalAllergies || [];

  const [mutateAllergy] = useMutation(
    gql`
      ${MUTATE_ALLERGY}
    `
  );

  const addAllergy = async (name) => {
    const payload = {
      variables: {
        input: {
          name,
        },
      },
    };
    return await mutateAllergy(payload)
      .then((response) => {
        if (response) {
          onSelectAllergy(response?.data?.addGlobalAllergies[0]);
        }
      })
      .catch((error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  return (
    <Autocomplete
      inputValue={inputValue}
      value={value}
      id="allergy-search-input"
      freeSolo
      options={allergies}
      renderInput={(params) => (
        <Box style={{ position: 'relative' }}>
          <TextField
            {...params}
            {...restProps}
            placeholder={t('add_product.allergies_placeholder')}
            className={classes.searchInput}
          />
          <SearchIcon
            className={classes.searchIcon}
            fontSize="small"
          />
        </Box>
      )}
      getOptionLabel={(option) => option?.name || ''}
      renderOption={(option, { inputValue }) => {
        if (option?.isNewButton) {
          return (
            <Box
              className={classes.addButtonWrapper}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              paddingY={1}
            >
              <span style={{ fontWeight: 600, marginLeft: 10 }}>
                {inputValue}
              </span>
              <Button
                color="primary"
                className={classes.addButton}
                onClick={() => addAllergy(option.name)}
              >
                {t('add_product.add_new')}
              </Button>
            </Box>
          );
        } else {
          const nameMatches = match(option.name, inputValue);
          const nameParts = parse(option.name, nameMatches);

          return (
            <Box paddingX={2} paddingY={3}>
              <Box>
                {nameParts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 600 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </Box>
            </Box>
          );
        }
      }}
      onInputChange={(e, newInputValue) =>
        setInputValue(newInputValue)
      }
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (
          params.inputValue !== '' &&
          !filtered.includes(params.inputValue)
        ) {
          filtered.push({
            id: uuid(),
            name: params.inputValue,
            image: [],
            isNewButton: true,
          });
        }
        return filtered;
      }}
      loading={isLoadingAllergies}
      loadingText={t(
        'workboard.product_intelligence.loading_products'
      )}
      onChange={(event, allergy) => {
        if (allergy?.id) {
          onSelectAllergy(allergy);
          setValue(null);
          setInputValue('');
        } else {
          return;
        }
      }}
    />
  );
};

export default AllergySearchInput;
