import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { Box, TextField } from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import useStyles from './styles';
import useBusinessPicker from '@/hooks/useBusinessPicker';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

const filter = createFilterOptions();

const BusinessTypePicker = ({ defaultTypeId, getIdOfBusinessType }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    query: { loading, error, data: businessTypeResponse },
    updateBusinessType,
    addBusinessType,
    addBusinessTypeNoStores,
  } = useBusinessPicker();

  const businessTypeList =
    businessTypeResponse?.globalDatasets?.business_types || [];

  const createBusinessType = async (value) => {
    try {
      const {
        data: { addBusinessTypes },
      } = await (getIdOfBusinessType
        ? addBusinessTypeNoStores(value)
        : addBusinessType(value));
      const idOfNewBusinessType = addBusinessTypes[0];

      getIdOfBusinessType(idOfNewBusinessType);
    } catch (error) {
      enqueueSnackbar(`An error occured adding new business type`);
      console.log('error', error);
    }
  };

  const defaultType = useMemo(() => {
    businessTypeResponse
      ? businessTypeList?.find((type) => type.id === defaultTypeId) || ''
      : null;
  }, [defaultTypeId]);

  return (
    <Autocomplete
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={'business_type'}
      options={businessTypeList}
      value={defaultType}
      loading={loading}
      onChange={(event, value) => {
        getIdOfBusinessType(value);
        updateBusinessType(value);
      }}
      classes={{
        inputRoot: classes.input,
      }}
      renderInput={(params) => <TextField {...params} />}
      getOptionLabel={(option) => option.name || ''}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);

        if (!option.isNewButton) {
          return (
            <Box paddingX={2} paddingY={3}>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 600 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </Box>
          );
        } else {
          return (
            <Box
              className={classes.addButtonWrapper}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              paddingY={3}
            >
              <span style={{ fontWeight: 600, marginLeft: 16 }}>
                {inputValue}
              </span>
              <Button
                color="primary"
                className={classes.addButton}
                onClick={() => createBusinessType(inputValue)}
              >
                {t('button_group.add_new')}
              </Button>
            </Box>
          );
        }
      }}
      filterOptions={(options, params) => {
        if (params.inputValue?.length > 2) {
          const filtered = filter(options, params);
          if (
            params.inputValue !== '' &&
            !filtered.includes(params.inputValue)
          ) {
            filtered.push({
              name: params.inputValue,
              isNewButton: true,
            });
          }
          return filtered;
        }
        return options;
      }}
    />
  );
};

BusinessTypePicker.propTypes = {
  defaultTypeId: PropTypes.string,
};

export default BusinessTypePicker;
