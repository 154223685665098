import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import NotFoundPage from '@/pages/notFoundPage'

import BusinessManagerPage from '@/pages/businessManager'
import ProductsPage from '@/pages/businessManager/products'
import PromotionsPage from '@/pages/businessManager/promotions'
import CustomersPage from '@/pages/businessManager/customers'
import AddonsPage from '@/pages/businessManager/addons'
import IngredientsPage from '@/pages/businessManager/ingredients'
import StyleVariantsPage from '@/pages/businessManager/styles/styleVariants'
import OrdersPage from '@/pages/businessManager/orders'
import ExpensesPage from '@/pages/businessManager/expenses'


const Routes = () => (
  <Switch>
    <Redirect exact from="/business-manager" to="/business-manager/store-profile" />
    <Route exact path="/business-manager/store-profile" component={BusinessManagerPage} />
    <Route exact path="/business-manager/products" component={ProductsPage} />
    <Route exact path="/business-manager/promotions" component={PromotionsPage} />
    <Route exact path="/business-manager/customers" component={CustomersPage} />
    <Route exact path="/business-manager/addon" component={AddonsPage} />
    <Route exact path="/business-manager/ingredient" component={IngredientsPage} />
    <Route exact path="/business-manager/style" component={StyleVariantsPage} />
    <Route exact path="/business-manager/orders" component={OrdersPage} />
    <Route exact path="/business-manager/expenses" component={ExpensesPage} />
    {/* Show Not Found page when url does not match */}
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
