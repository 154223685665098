import React, { useEffect, useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  Select,
  MenuItem,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  FormHelperText,
} from '@material-ui/core';
import PrimaryButton from '@/components/button';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { ADD_TOUCHPOINT } from '@/services/touchpointsManagementService';
import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useSnackbar } from 'notistack';
import TextField from '@/components/textField';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AddNewTouchpoints = ({
  touchpointsType,
  stores,
  onFetchTouchpoints,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => {
    setIsOpenModal(false);
    setErrorMessage('');
    handleReset();
  };

  const addNewTouchpointsSchema = Yup.object().shape({
    touchpointName: Yup.string().required(
      t('touchpointsManagement.touchpoints.touchpoint_name_required')
    ),
    touchpointType: Yup.object({
      id: Yup.string().required(
        t(
          'touchpointsManagement.touchpoints.touchpoint_type_required'
        )
      ),
      name: Yup.string().required(
        t(
          'touchpointsManagement.touchpoints.touchpoint_type_required'
        )
      ),
    }),
    touchpointStore: Yup.object({
      id: Yup.string().required(
        t(
          'touchpointsManagement.touchpoints.touchpoint_store_required'
        )
      ),
      name: Yup.string().required(
        t(
          'touchpointsManagement.touchpoints.touchpoint_store_required'
        )
      ),
    }),
  });

  const {
    handleChange,
    handleBlur,
    handleReset,
    setFieldValue,
    errors,
    touched,
    values,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      touchpointName: '',
      touchpointType: {},
      touchpointStore: {},
    },
    validationSchema: addNewTouchpointsSchema,
  });

  useEffect(() => {
    if (stores && stores?.length === 1) {
      setFieldValue('touchpointStore', stores[0]);
    }
  }, [stores]);

  const [onAddTouchpoint] = useMutation(
    gql`
      ${ADD_TOUCHPOINT}
    `,
    {
      variables: {
        input: {
          name: values.touchpointName,
          type: values.touchpointType.id,
        },
      },
      context: {
        headers: {
          storeId: values.touchpointStore.id,
        },
      },
      onCompleted: async () => {
        enqueueSnackbar(
          t(
            'touchpointsManagement.touchpoints.new_touchpoint_added',
            { name: values.touchpointName }
          )
        );
        await onFetchTouchpoints?.();
        setIsLoading(false);
        handleCloseModal();
      },
      onError: (error) => {
        setIsLoading(false);
        setErrorMessage(error?.message);
      },
    }
  );

  return (
    <div>
      <PrimaryButton
        className={classes.addNewButton}
        variant="contained"
        color="primary"
        onClick={handleOpenModal}
      >
        {t('touchpointsManagement.touchpoints.add_new')}
      </PrimaryButton>

      <Dialog
        fullWidth
        fullScreen={fullScreen}
        onClose={isOpenModal ? () => {} : handleCloseModal}
        aria-labelledby={`{edit-add-new-touchpoint-dialog-title}`}
        open={isOpenModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{edit-add-new-touchpoint-dialog-title}`}
          onClose={handleCloseModal}
        >
          {t('touchpointsManagement.touchpoints.add_new_touchpoint')}
        </DialogTitle>
        <DialogContent>
          {errorMessage && (
            <Box
              display="flex"
              justifyContent="center"
              marginBottom={7}
            >
              <FormHelperText error>{errorMessage}</FormHelperText>
            </Box>
          )}
          <Box marginBottom={12}>
            <Typography className={classes.titleText}>
              {t('touchpointsManagement.touchpoints.touchpoint_name')}
            </Typography>
            <TextField
              id={'touchpointName'}
              name={'touchpointName'}
              fullWidth
              value={values.touchpointName}
              onChange={handleChange}
              error={
                touched['touchpointName'] &&
                Boolean(errors['touchpointName'])
              }
              helperText={
                touched['touchpointName'] && errors['touchpointName']
              }
              autoFocus
              onBlur={handleBlur}
              InputProps={{
                className: classes.input,
              }}
            />
          </Box>
          <Box marginBottom={12}>
            <Typography className={classes.titleText}>
              {t('touchpointsManagement.touchpoints.touchpoint_type')}
            </Typography>
            <Select
              fullWidth
              className={classes.selectOptionInput}
              id={'touchpointType'}
              name={'touchpointType'}
              defaultValue=""
              value={
                touchpointsType?.find(
                  (item) => item.id === values.touchpointType
                )?.name
              }
              onChange={handleChange}
              error={
                touched['touchpointType'] &&
                Boolean(errors['touchpointType'])
              }
              onBlur={handleBlur}
            >
              {touchpointsType &&
                touchpointsType.map((option, index) => {
                  return (
                    <MenuItem
                      key={`${option.name}-${index}`}
                      value={option}
                    >
                      {option.name}
                    </MenuItem>
                  );
                })}
            </Select>
            {Boolean(errors['touchpointType']) &&
              touched['touchpointType'] && (
                <FormHelperText
                  error={
                    Boolean(errors['touchpointType']) &&
                    touched['touchpointType']
                  }
                >
                  {errors['touchpointType']?.id ||
                    errors['touchpointType']?.name}
                </FormHelperText>
              )}
          </Box>
          <Box marginBottom={12}>
            <Typography className={classes.titleText}>
              {t('touchpointsManagement.touchpoints.store')}
            </Typography>
            <Select
              fullWidth
              className={classes.selectOptionInput}
              id={'touchpointStore'}
              name={'touchpointStore'}
              defaultValue=""
              value={
                stores?.length === 1
                  ? values.touchpointStore
                  : stores?.find(
                      (item) => item.id === values.touchpointStore
                    )?.name
              }
              onChange={handleChange}
              error={
                touched['touchpointStore'] &&
                Boolean(errors['touchpointStore'])
              }
              onBlur={handleBlur}
            >
              {stores &&
                stores.map((option, index) => {
                  return (
                    <MenuItem
                      key={`${option.name}-${index}`}
                      value={option}
                    >
                      {option.name}
                    </MenuItem>
                  );
                })}
            </Select>
            {Boolean(errors['touchpointStore']) &&
              touched['touchpointStore'] && (
                <FormHelperText
                  error={
                    Boolean(errors['touchpointStore']) &&
                    touched['touchpointStore']
                  }
                >
                  {errors['touchpointStore']?.id ||
                    errors['touchpointStore']?.name}
                </FormHelperText>
              )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={!isValid}
            onClick={async () => {
              setIsLoading(true);
              await onAddTouchpoint();
            }}
            type="submit"
            color="primary"
          >
            {t('touchpointsManagement.touchpoints.add')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={30} />}
      </Dialog>
    </div>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default AddNewTouchpoints;
