import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Box, Grid, Typography } from '@material-ui/core'
import MainLayout from './mainLayout'
import SearchProductLayout from './searchProductLayout'

import useStyles from './styles';

const ProductIntelligence = (props) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const { startTime, endTime } = props

  const [product, setProduct] = useState(null)

  return (
    <Box className={classes.root}>
      <Typography className={classes.titleText}>{t('globalWidgets.product_intelligence')}</Typography>

      {product
        ?
        <MainLayout
          startTime={startTime}
          endTime={endTime}
          product={product}
          onRemoveProduct={() => setProduct(null)}
        />
        :
        <SearchProductLayout
          onSelectProduct={(value) => setProduct(value)}
        />
      }
    </Box>
  )
}

ProductIntelligence.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
}

export default ProductIntelligence