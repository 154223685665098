import React from 'react'
import PropTypes from 'prop-types'

import { TableRow, TableCell, withStyles } from '@material-ui/core'

import { Colors, CommonFonts } from '@/theme'
import Button from '@/components/button'

const Row = ({ item, onPressPay }) => {
  const rowCells = [
    {
      id: 'first_name',
      label: item.first_name,
    },
    {
      id: 'last_name',
      label: item.last_name,
    },
    {
      id: 'mobile',
      label: item.mobile,
    },
    {
      id: 'no_of_invoices',
      label: item.no_of_invoices,
    },
    {
      id: 'outstanding',
      label: item.outstanding,
    },
    {
      id: 'payButton',
      label: <Button onClick={() => onPressPay?.(item)}>Pay</Button>,
    },
  ]

  return (
    <TableRow hover>
      {rowCells.map(rowCell => (
        <StyledTableCell key={rowCell.id}>{rowCell.label}</StyledTableCell>
      ))}
    </TableRow>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    ...CommonFonts.darkFont16,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25,
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont16,
  },
}))(TableCell)

Row.propTypes = {
  item: PropTypes.object,
}

export default Row
