import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Popover, List, ListItem } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import useStyles from './styles'

const DropDown = ({ selectedItem, onChangeItem, items }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box>
      <Box
        display="flex"
        className={classes.performanceContainer}
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <Typography className={classes.performanceText}>{selectedItem}</Typography>
        {open ? (
          <ExpandLessIcon className={classes.icon} />
        ) : (
          <ExpandMoreIcon className={classes.icon} />
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {items.map(item => (
            <ListItem
              key={item.name}
              button
              onClick={() => {
                onChangeItem(item)
                setAnchorEl(null)
              }}
              disabled={item.name === selectedItem.name}
            >
              <Typography className={classes.performanceText}>{item.name}</Typography>
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  )
}

DropDown.propTypes = {
  selectedItem: PropTypes.string,
  onChangeItem: PropTypes.func,
  items: PropTypes.array,
}

export default DropDown
