export const Colors = {
  WHITE: '#FFFFFF',
  GRAY_DARK: '#000000DE',
  GRAY_REGULAR: '#353535',
  GRAY_BORDER1: '#BAC3C9',
  GRAY_BORDER1_40: 'rgba(186, 195, 201, 0.4)',
  GRAY_BORDER1_20: 'rgba(186, 195, 201, 0.2)',
  GRAY_BORDER1_25: 'rgba(186, 195, 201, 0.25)',
  GRAY_BORDER2: '#bac3c930',
  GRAY_LIGHT: '#F3F5F7',
  GRAY_LIGHT_50: 'rgba(243, 245, 247, 0.5);',
  GRAY_LIGHT_20: 'rgba(243, 245, 247, 0.2);',
  DISABLED: '#0000001F',
  BLUE: '#1174F2',
  PALE_BLUE: '#BBC3C8',
  PALE_BLUE_50: 'rgba(187, 195, 200, 50)',
  RED: '#F63333',
  BLUE_DARK: '#FFFFFF00',
  BLUE_LIGHT: '#FFFFFF1F',
  DIM_GREY: '#505C69',
  PURPLE: '#BD10E0',
}