import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Box,
  withStyles,
} from '@material-ui/core';

import { CommonFonts } from '@/theme';

const GreenCheckbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const TableHeader = ({ onChange }) => {
  const { t } = useTranslation();

  const headCells = [
    {
      id: 'product_img',
      label: '',
      width: '10%',
    },
    {
      id: 'product_name',
      label: t('workboard.manage_store.touchpoints.name'),
      width: '15%',
    },
    {
      id: 'product_barcode',
      label: t('workboard.manage_store.touchpoints.barcode'),
      width: '15%',
    },
    {
      id: 'product_category',
      label: t('workboard.manage_store.touchpoints.category'),
      width: '10%',
    },
    {
      id: 'empty',
      label: '',
      width: '10%',
    },
    {
      id: 'category_pos',
      label: t('workboard.manage_store.touchpoints.pos'),
      width: '10%',
      hasCheckbox: true,
      type: 'pos',
    },
    {
      id: 'category_kiosk',
      label: t('workboard.manage_store.touchpoints.kiosk'),
      width: '10%',
      hasCheckbox: true,
      type: 'self_order_kiosk',
    },
    {
      id: 'category_storefront',
      label: t('workboard.manage_store.touchpoints.storefront'),
      width: '10%',
      hasCheckbox: true,
      type: 'digital_front',
    },
  ];

  const handleChangeCheckbox = (e, type) => {
    const checked = e.target.checked;
    onChange(type, checked);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            style={{
              width: headCell.width,
              borderBottom: 'none',
            }}
          >
            <Box display="flex" alignItems={'center'}>
              <span>{headCell.label}</span>
              {headCell.hasCheckbox && (
                <GreenCheckbox
                  onChange={(e) =>
                    handleChangeCheckbox(e, headCell.type)
                  }
                />
              )}
            </Box>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
    ...CommonFonts.darkFont14,
    fontWeight: 'normal',
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont14,
  },
}))(TableCell);

export default TableHeader;
