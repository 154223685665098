import { takeLatest, put, call } from 'redux-saga/effects';
import MerchantActions, { MerchantTypes } from './actions'
import AuthActions from '../auth/actions'

function* updateMerchant(action) {
  const { merchant } = action;
  yield put(MerchantActions.setCurrentMerchant(merchant))
  yield put(AuthActions.updateMerchants(merchant))
}

export const saga = function* () {
  yield takeLatest(MerchantTypes.UPDATE_MERCHANT, updateMerchant);
};
