import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { find } from 'lodash'
import { Box, Button } from '@material-ui/core'
import OptionValuesTable from './table'
import AddUpdateOptionValue from './addUpdateOptionValue'

const OptionDetail = ({ option, onUpdateOption }) => {
  const { t } = useTranslation();

  const [openOptionValueModal, setOpenOptionValueModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)

  const onAddUpdateValue = (newValue) => {
    const newValues = option.values.map(value => value.id === newValue.id ? newValue : value)
    if (!find(newValues, { id: newValue.id })) {
      newValues.push(newValue)
    }
    onUpdateOption({
      ...option,
      values: newValues
    })
  }

  const onDeleteValue = (value) => {
    onUpdateOption({
      ...option,
      values: option.values.filter(item => item.id !== value.id)
    })
  }

  return (
    <Box>
      <OptionValuesTable
        option={option}
        onSelectUpdate={(value) => {
          setSelectedValue(value)
          setOpenOptionValueModal(true)
        }}
        onSelectDelete={onDeleteValue}
      />
      <Box marginTop={10} display='flex' justifyContent='center'>
        <Button
          color="primary"
          onClick={() => setOpenOptionValueModal(true)}
        >
          {t('button_group.add_new')}
        </Button>
      </Box>

      {openOptionValueModal &&
        <AddUpdateOptionValue
          open={openOptionValueModal}
          onClose={() => {
            setOpenOptionValueModal(false)
            setSelectedValue(null)
          }}
          option={option}
          onAddUpdateValue={onAddUpdateValue}
          selectedValue={selectedValue}
        />
      }
    </Box>
  )
}

export default OptionDetail