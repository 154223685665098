import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import images from '@/assets/images';

const AccessMerchantExpired = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      className={classes.boxOutter}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box className={classes.boxInner}>
        <Grid container justify="center" className={classes.logo}>
          <img src={images.logo} width={125} height={50} alt="logo" />
        </Grid>

        <Box paddingBottom={25}>
          <Grid container justify="center">
            <Typography className={classes.text}>
              {t(
                'invitation.accessMerchant.expired.invitation_expired'
              )}
            </Typography>
          </Grid>

          <Grid container justify="center">
            <Typography className={classes.text}>
              {t(
                'invitation.accessMerchant.expired.resend_invitation'
              )}
            </Typography>
          </Grid>
        </Box>
      </Box>
      <Box className={classes.boxFooter}>
        <Typography className={classes.textFooter}>
          {t('invitation.accessMerchant.textFooter', {
            year: new Date().getFullYear(),
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default AccessMerchantExpired;
