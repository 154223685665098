import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    breadcrumbsText: {
      fontSize: 16,
    },
    rootBox: {
      backgroundColor: Colors.WHITE,
      height: '100vh',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(12),
    },
    descriptionText: {
      textAlign: 'center',
      ...CommonFonts.darkFont14,
      margin: theme.spacing(5, 0, 2, 0),
    },
    boxImport: {
      maxWidth: theme.spacing(71),
      borderColor: '#BAC3C9',
      borderWidth: '1px',
      borderStyle: 'solid',
      padding: theme.spacing(2.5),
    },
    importButton: {
      textTransform: 'capitalize',
    },
    titleDialog: {
      ...CommonFonts.darkFont30,
      fontWeight: '600',
    },
    titleUpload: {
      ...CommonFonts.darkFont20,
      fontWeight: '600',
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      textAlign: 'center',
      letterSpacing: 0.3,
      paddingBottom: theme.spacing(12),
      lineHeight: `${theme.spacing(6)}px`,
    },
    bottomText: {
      ...CommonFonts.darkFont16,
    },
    bottomBlueText: {
      ...CommonFonts.blueFont16,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    dropZone: {
      marginTop: theme.spacing(5),
      borderRadius: theme.spacing(1),
      width: theme.spacing(201),
      height: theme.spacing(45),
      backgroundColor: '#e7f1fe',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    typeSupport: {
      ...CommonFonts.darkFont16,
      marginTop: theme.spacing(5),
    },
    dragAndDropDescription: {
      ...CommonFonts.darkFont18,
    },
    chooseFile: {
      ...CommonFonts.blueFont18,
      fontWeight: '500',
      cursor: 'pointer',
    },
    cancelButton: {
      ...CommonFonts.darkFont14,
      backgroundColor: '#F3F5F7',
      padding: theme.spacing(2.5, 10),
    },
    importButton: {
      ...CommonFonts.whiteFont14,
      marginLeft: theme.spacing(10),
      padding: theme.spacing(2.5, 10),
    },
    errorMessage: {
      fontSize: 18,
      textAlign: 'center',
    },
  })
);

export default useStyles;
