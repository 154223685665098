import React, { useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Link, Tab, Tabs
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import AppLayout from '@/components/appLayout';
import { useTranslation } from 'react-i18next';

import LocalDelivery from './LocalDelivery';
import useStyles from './styles';

const TABS = {
  LOCAL_DELIVERIES: 'LocalDeliveries',
  DOMESTIC_DELIVERIES: 'StockDeliveries',
  INTERNATIONAL_DELIVERIES: 'InternationalDeliveries'
};

const DeliveryPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      id: TABS.LOCAL_DELIVERIES,
      label: t('modules.delivery.local_deliveries'),
      disabled: false
    },
    {
      id: TABS.DOMESTIC_DELIVERIES,
      label: t('modules.delivery.domestic_deliveries'),
      disabled: true
    },
    {
      id: TABS.INTERNATIONAL_DELIVERIES,
      label: t('modules.delivery.international_deliveries'),
      disabled: true
    }
  ];

  return (
    <AppLayout className={classes.appLayout} withFooter header>
      <Box marginY={10}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className={classes.breadcrumbsText}
            color="primary"
            component={RouterLink}
            to="/business-manager/store-profile"
          >
            {t('userManagement.users.business_manager')}
          </Link>
          <Link
            className={classes.breadcrumbsText}
            color="primary"
            component={RouterLink}
            to="/modules"
          >
            {t('modules.title')}
          </Link>
          <Link
            className={classes.breadcrumbsText}
            color="textPrimary"
            component={RouterLink}
            to="/modules/delivery"
          >
            {t('modules.delivery.title')}
          </Link>
        </Breadcrumbs>
      </Box>
      <div className={classes.boxModules}>
        <Tabs value={value}
              onChange={handleChange}
              aria-label="delivery tabs"
              className={classes.tabsWrapper}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary">
          {tabs.map((item, index) => (
            <Tab
              key={`tab-${index}`}
              label={item.label}
              className={classes.tab}
              disabled={item.disabled}
            />
          ))}
        </Tabs>
        {tabs.map((item, index) => {
            if (item.id === TABS.LOCAL_DELIVERIES) {
              return (
                <TabPanel value={value} index={index}>
                  <LocalDelivery />
                </TabPanel>
              );
            }
          }
        )}
      </div>

    </AppLayout>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default DeliveryPage;
