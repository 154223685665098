import React from 'react'
import PropTypes from 'prop-types'

import { ListItem, Grid, useTheme, useMediaQuery } from '@material-ui/core';

import useStyles from '../styles'

const Item = (props) => {
  const classes = useStyles()

  const {
    isMerchant,
    leftText,
    rightText,
    item,
    onClick
  } = props
  const theme  = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <ListItem
      button={!isMerchant}
      className={classes.listItemContainer}
      onClick={() => isMerchant ? {} : onClick(item)}
    >
      <Grid
        container
        justify='space-between'
      >
        <div  className={isMerchant ? classes.merchantItemWrapper : classes.storeItemWrapperLeft}>{leftText}</div>
        {matchesSM && (
          <div  className={isMerchant ? classes.merchantItemWrapper : classes.storeItemWrapperRight}>{rightText}</div>
        )}
      </Grid>
    </ListItem>
  )
}

Item.propTypes = {
  isMerchant: PropTypes.bool,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  item: PropTypes.object,
  onClick: PropTypes.func
}

export default Item
