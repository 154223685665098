import { createReducer } from 'reduxsauce';

import { INITIAL_STATE } from './initialState';
import { AuthTypes } from './actions';
import produce from 'immer';

export const loginSuccess = (state, { data }) => {
  return {
    ...state,
    accessToken: data.logIn.access_token,
    refreshToken: data.logIn.refresh_token,
    user: data.logIn.user,
  };
};

export const logoutSuccess = (state) => {
  return {
    ...state,
    ...INITIAL_STATE,
  };
};

export const addNewMerchant = (state, { data }) => {
  return produce(state, (draftState) => {
    draftState.user.merchants.push(data);
  });
};

export const addNewStore = (state, { merchant, store }) => {
  return produce(state, (draft) => {
    //get id of the merchant getting updated
    const indexOfMerchant = draft.user.merchants.findIndex(
      (item) => store.merchant_id === item.id
    );

    if (indexOfMerchant !== -1) {
      //add new store
      draft.user.merchants[indexOfMerchant].stores.push(store);
    }
  });
};

export const updateStore = (state, { data }) => {
  const { merchant_id, id: storeId } = data;

  return produce(state, (draft) => {
    //get id of the merchant getting updated
    const indexOfMerchant = draft.user.merchants.findIndex(
      (merchant) => merchant.id === merchant_id
    );

    if (indexOfMerchant !== -1) {
      //get id of the store getting updated
      const indexOfStore = draft.user.merchants[
        indexOfMerchant
      ].stores.findIndex((store) => store.id === storeId);

      if (indexOfStore !== -1) {
        //update the store
        draft.user.merchants[indexOfMerchant].stores[indexOfStore] = {
          ...draft.user.merchants[indexOfMerchant].stores[
            indexOfStore
          ],
          ...data,
        };
      }
    }
  });
};

export const updateMerchants = (state, { merchant }) => {
  return {
    ...state,
    user: {
      ...state.user,
      merchants: state.user.merchants.map((item) =>
        item.id === merchant.id ? merchant : item
      ),
    },
  };
};

export const setUserMerchants = (state, { data }) => {
  return {
    ...state,
    user: {
      ...state.user,
      merchants: data,
    },
  };
};

export const setUserProfile = (state, { data }) => {
  return {
    ...state,
    user: {
      ...state.user,
      profile: data,
    },
  };
};

export const setAccessToken = (state, { accessToken }) => {
  return {
    ...state,
    accessToken,
  };
};

export const setRefreshToken = (state, { refreshToken }) => {
  return {
    ...state,
    refreshToken,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [AuthTypes.LOGIN_SUCCESS]: loginSuccess,
  [AuthTypes.LOGOUT_SUCCESS]: logoutSuccess,
  [AuthTypes.ADD_NEW_STORE]: addNewStore,
  [AuthTypes.ADD_NEW_MERCHANT]: addNewMerchant,
  [AuthTypes.UPDATE_STORE]: updateStore,
  [AuthTypes.UPDATE_MERCHANTS]: updateMerchants,
  [AuthTypes.SET_USER_MERCHANTS]: setUserMerchants,
  [AuthTypes.SET_USER_PROFILE]: setUserProfile,
  [AuthTypes.SET_ACCESS_TOKEN]: setAccessToken,
  [AuthTypes.SET_REFRESH_TOKEN]: setRefreshToken,
});
