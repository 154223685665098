import React, { useState } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

const UpdatingOrderTypeDialog = ({
  isVisible,
  onCloseDialog,
  priceTypeName,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      onClose={onCloseDialog}
      aria-labelledby={`{update-order-type-dialog-title}`}
      open={isVisible}
      disableBackdropClick
    >
      <DialogTitle
        id={`{update-order-type-dialog-title}`}
        onClose={onCloseDialog}
      >
        <Typography className={classes.titleDialog}>
          {t('settings.inventory.update_order_type')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          marginTop={5}
          marginBottom={10}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes.contentDialog}>
            {t('settings.inventory.price_type_linked', {
              name: priceTypeName,
            })}
          </Typography>
          <div className={classes.boxRedirect}>
            <RouterLink
              className={classes.link}
              to="/order-types-workflows-management/order-types"
            >
              <span className={classes.linkText}>
                {t('settings.inventory.click_here')}
              </span>
            </RouterLink>
            <span className={classes.contentDialog}>
              {t(
                'settings.inventory.remove_price_type_from_order_type',
                { name: priceTypeName.toLowerCase() }
              )}
            </span>
          </div>
        </Box>

        <Box display="flex" justifyContent="center">
          <Button color="primary" onClick={onCloseDialog}>
            {t('settings.inventory.ok')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

export default UpdatingOrderTypeDialog;
