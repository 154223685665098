import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CATEGORIES, MOVE_PRODUCTS } from '@/services/inventoryService';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import PreLoader from '@/components/preLoader';
import Selector from './addUpdateProduct/components/selector';
import { useSnackbar } from 'notistack';
import useStyles from './styles';

export default function ResponsiveDialog({
  open,
  handleClose,
  defaultCategory,
  onSuccess
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [fromCategory, setFromCategory] = useState({})
  const [toCategory, setToCategory] = useState({})

  const { loading: isLoadingCategories, data: categoriesData } = useQuery(GET_CATEGORIES, {
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        storeId: -1
      }
    },
  })

  const [moveProudctsMutation, { loading: isMoveProducts }] = useMutation(MOVE_PRODUCTS, {
    onCompleted: () => {
      enqueueSnackbar(t('categories.items_moved'))
      onSuccess()
      handleClose()
    },
    onError: () => { }
  })

  const onMoveProducts = () => {
    moveProudctsMutation({
      variables: {
        input: {
          from: fromCategory.id,
          to: toCategory.id
        }
      }
    })
  }

  useEffect(() => {
    if (defaultCategory) {
      setFromCategory(defaultCategory)
    }
  }, [defaultCategory])

  return (
    <div>
      <Dialog
        fullWidth
        classes={{ paperWidthSm: classes.moveProductModal }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
        >
          <Grid
            container
            alignItems="center"
            justify="space-between"
            alignContent="center"
          >
            <Grid item xs={1} />
            <Grid className={classes.headerText} item xs={10}>
                {t('categories.move_items')}
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            alignItems='center'
            style={{ 
              padding: '0px 50px',
              marginTop: 30,
            }}
          >
            <Grid item xs={12} sm={5}>
              <Selector
                title={t('categories.move_from')}
                emptyLabel={t('categories.select_category')}
                items={categoriesData?.categories || []}
                getItemLabel={(item) => item.name}
                selectedItem={fromCategory}
                onChangeValue={(category) => {
                  if (category.id === toCategory?.id) return
                  setFromCategory(category)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Box display='flex' justifyContent='center'>
                <Hidden xsDown>
                  <ArrowForward className={classes.arrowIcon} fontSize='default' />
                </Hidden>
                <Hidden smUp>
                  <ArrowDownward className={classes.arrowIcon} fontSize='default' />
                </Hidden>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Selector
                title={t('categories.move_to')}
                emptyLabel={t('categories.select_category')}
                items={categoriesData?.categories || []}
                getItemLabel={(item) => item.name}
                selectedItem={toCategory}
                onChangeValue={(category) => {
                  if (category.id === fromCategory?.id) return
                  setToCategory(category)
                }}
              />
            </Grid>
          </Grid>
          <Typography className={classes.moveProductsText}>
            {t('categories.move_items_description')}
          </Typography>
        </DialogContent>

        <DialogActions style={{ paddingRight: 30 }}>
          <Button
            color="primary"
            onClick={handleClose}
            className={classes.cancelButton}
          >
            {t('button_group.cancel')}
          </Button>
          <Button
            disabled={isEmpty(fromCategory) || isEmpty(toCategory)}
            color="primary"
            onClick={onMoveProducts}
          >
            {t('button_group.move')}
          </Button>
        </DialogActions>

        {(isLoadingCategories || isMoveProducts) && <PreLoader size={40} />}
      </Dialog>
    </div>
  );
}
