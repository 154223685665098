import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  menuItem: { 
    height: 60, 
    color: '#20272FDE', 
    fontSize: 18,
  },
}));

export default useStyles;
