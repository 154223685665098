import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  Box,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons';

import TutorialActions from '@/store/modules/tutorial/actions';

import { TRIGGERS } from '@/components/joyRider/helper'
import useStyles from '../styles'

const Help = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { onClose } = props

  const dispatch = useDispatch()

  const onClickHelp = () => {
    dispatch(
      TutorialActions.runTrigger(TRIGGERS.TRIGGER_MANUAL_START)
    );
    onClose()
  }

  return (
    <div className={classes.drawerContent}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.titleNotification}>
          {t('help.help')}
        </Typography>
        <IconButton
          onClick={onClose}
          size="small"
          className={classes.closeIcon}
        >
          <CloseOutlined style={{ fontSize: '20px' }} />
        </IconButton>
      </Box>
      <Box className={classes.helpContent}>
        <Button
          color="primary"
          className={classes.startTutorialButton}
          onClick={onClickHelp}
        >
          {t('help.start_tutorial')}
        </Button>
      </Box>
    </div>
  )
}

Help.propTypes = {
  onClose: PropTypes.func,
}

export default Help