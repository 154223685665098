import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: theme.spacing(39),
      height: theme.spacing(23),
      borderRadius: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    boxUpdateImage: {
      width: theme.spacing(39),
      height: theme.spacing(7),
      borderRadius: theme.spacing(0.5),
      position: 'absolute',
      backgroundColor: '#20272F',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      opacity: 0.9,
    },
    boxWithoutImage: {
      width: theme.spacing(39),
      height: theme.spacing(23),
      backgroundColor: '#BAC3C9',
      borderRadius: theme.spacing(0.5),
      opacity: 0.4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    boxImage: {
      width: theme.spacing(39),
      height: theme.spacing(23),
      borderRadius: theme.spacing(0.5),
      objectFit: 'cover',
    },
    tab: {
      '& .MuiTab-wrapper': {
        textTransform: 'none',
      },
    },
    tabs: {
      '& .MuiTabs-flexContainer': {
        borderBottomWidth: 2,
        borderBottomColor: '#E2E2E2',
        borderBottomStyle: 'solid',
      },
    },
    boxImageUpload: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: theme.spacing(25),
      paddingBottom: theme.spacing(25),
    },
    dropZone: {
      marginTop: theme.spacing(5),
      borderRadius: theme.spacing(1),
      width: '470px',
      height: '160px',
      backgroundColor: '#e7f1fe',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleUploadImage: {
      ...CommonFonts.darkFont24,
      fontWeight: '600',
    },
    titleDrop: { ...CommonFonts.darkFont18 },
    titleBrowse: {
      ...CommonFonts.darkFont18,
      color: '#1174F2',
      marginLeft: theme.spacing(1),
      fontWeight: '600',
      cursor: 'pointer',
    },
    boxImagePreview: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(14),
      marginBottom: theme.spacing(9),
      cursor: 'pointer',
    },
    boxPreview: {
      width: '825px',
      height: '455px',
      margin: '0 auto',
    },
    imagePreview: {
      maxWidth: '825px',
    },
    progressBar: {
      marginTop: theme.spacing(4),
      '& .MuiLinearProgress-root': {
        width: theme.spacing(75),
        height: '5px',
      },
    },
    closeButton: {
      backgroundColor: Colors.GRAY_LIGHT,
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3.5),
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1,
      },
    },
    closeIcon: {
      fontSize: 14,
    },
    changeImage: {
      ...CommonFonts.grayFont12,
      cursor: 'pointer',
      textAlign: 'center',
    },
    errorText: {
      marginTop: theme.spacing(10),
      textAlign: 'center',
      ...CommonFonts.darkFont16,
      color: Colors.RED,
      fontWeight: 500,
    },
  })
);

export default useStyles;
