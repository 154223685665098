import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import classnames from 'classnames'
import CssTextField from './styles'

const Input = (props) => {

  const {
    className,
    isPassword = false,
    startAdornment = null,
    endAdornment = null,
    autofocus,
    ...restProps
  } = props

  const [showPassword, setShowPassword] = useState(!isPassword)

  const renderEndAdornment = () => {
    if (isPassword) {
      return (
        <IconButton
          onClick={() => setShowPassword(!showPassword)}
          style={{ padding: 5 }}
        >
          {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
        </IconButton>
      )
    }
    return endAdornment
  }

  return (
    <CssTextField
      fullWidth
      type={showPassword ? 'text' : 'password'}
      {...restProps}
      className={classnames([className])}
      InputProps={{
        startAdornment: startAdornment
          ? <InputAdornment position="start">{startAdornment}</InputAdornment>
          : null,
        endAdornment: (endAdornment || isPassword) ? renderEndAdornment() : null
      }}
    />
  )
};

Input.propTypes = {
  className: PropTypes.string,
  isPassword: PropTypes.any,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any
}

export default Input
