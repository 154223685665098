import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      marginBottom: theme.spacing(8),
    },

    descriptionText: {
      ...CommonFonts.grayFont16,
      color: '#505C69',
    },
    titleText: {
      fontSize: 16,
      position: 'relative',
      paddingBottom: '24px',
      paddingRight: '48px',
    },
  })
);

export default useStyles;
