import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  title: {
    ...CommonFonts.darkFont14,
    fontWeight: '500',
  },
  groupSearchWrapper: {
    width: '100%',
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing(7),
  },
}));

export default useStyles;
