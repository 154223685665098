import { CommonFonts, Colors } from '@/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    currencyInput: {
      width: '100%',
      borderWidth: 0,
      padding: theme.spacing(1.5, 0, 2),
      ...CommonFonts.darkFont18,
      fontFamily: 'Montserrat',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      transition:
        'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      outline: 'none',
      '&:hover': {
        borderBottom: '2px solid rgba(0, 0, 0, 0.8)',
      },
      '&:focus': {
        borderBottom: `2px solid ${Colors.BLUE}`,
        transition:
          'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        PointerEvent: 'none',
      },
      '&:focus:hover': {
        borderBottom: `2.5px solid ${Colors.BLUE}`,
        transition:
          'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        PointerEvent: 'none',
      },
    },
  })
);

export default useStyles;
