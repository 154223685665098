import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '@/pages/notFoundPage';

import WorkboardPage from '@/pages/workboard';

const Routes = () => (
  <Switch>
    <Route exact path="/workboard" component={WorkboardPage} />
    {/* Show Not Found page when url does not match */}
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
