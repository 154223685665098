import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '@/pages/notFoundPage';
import ModulesPage from '@/pages/modules';
import CustomerManagement from '@/pages/modules/customerManagment';
import Delivery from '@/pages/modules/delivery';

const Routes = () => (
  <Switch>
    <Route exact path="/modules" component={ModulesPage} />
    <Route exact path="/modules/customer-behaviour-management" component={CustomerManagement} />
    <Route exact path="/modules/delivery" component={Delivery} />
    {/* Show Not Found page when url does not match */}
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
