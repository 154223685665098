import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    breadcrumbsText: {
      fontSize: 16,
    },
    rootBox: {
      backgroundColor: Colors.WHITE,
      height: '100vh',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(12),
    },
    descriptionText: {
      textAlign: 'center',
      ...CommonFonts.darkFont14,
      margin: theme.spacing(5, 0, 2, 0),
    },
    boxImport: {
      maxWidth: theme.spacing(71),
      borderColor: '#BAC3C9',
      borderWidth: '1px',
      borderStyle: 'solid',
      padding: theme.spacing(2.5),
    },
    importButton: {
      textTransform: 'capitalize',
    },
    routerLink: {
      textDecoration: 'none',
    },
  })
);

export default useStyles;
