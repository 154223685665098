import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField';
import Button from '@/components/button';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BackButton from '@/components/backButton';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { countries } from './helper';

const validationSchema = yup.object({
  line1: yup
    .string('Enter your street name')
    .required('Street name is required'),
  door_number: yup
    .string('Enter your street number')
    .required('Street number is required'),
  city_town: yup
    .string('Enter your city name')
    .required('City name is required'),
  postcode: yup
    .string('Enter your post code')
    .required('Post code is required'),
  country: yup.string('Enter your country').required('Country is required'),
  building_name: yup.string('Enter your password'),
});

export default function EnterFullAddressManually({
  address,
  onAddressFilled,
  onBack
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const onSubmit = (values) => {
    //door_number, line 1, city_town and postcode
    onAddressFilled({ ...address, ...values });
  };
  const country = localStorage.getItem('country');

  return (
    <Formik
      initialValues={{
        door_number: address?.door_number,
        building_name: address?.building_name,
        line1: address?.line1,
        line2: address?.line2,
        city_town: address?.city_town,
        postcode: address?.postcode,
        country: country,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <div>
            <Grid container justify="center">
              <h4>{t('stores.enter_store_address')}</h4>
            </Grid>
            <Box marginBottom={10}>
              <BackButton onClick={onBack} fontSize={'small'} />
            </Box>
            <Grid container className={classes.root} spacing={4}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  required
                  id="door_number"
                  label={t('addStore.street_number')}
                  name="door_number"
                  onChange={handleChange}
                  value={values['door_number']}
                  error={
                    touched['door_number'] && Boolean(errors['door_number'])
                  }
                  helperText={touched['door_number'] && errors['door_number']}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  name="building_name"
                  value={values.building_name}
                  onChange={handleChange}
                  id="building_name"
                  label={t('addStore.building_name')}
                  error={
                    touched['building_name'] && Boolean(errors['building_name'])
                  }
                  helperText={
                    touched['building_name'] && errors['building_name']
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="floor"
                  label={t('addStore.floor_number')}
                  name="floor"
                  value={values.floor}
                  onChange={handleChange}
                  error={touched['floor'] && Boolean(errors['floor'])}
                  helperText={touched['floor'] && errors['floor']}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="unit"
                  label={t('addStore.unit')}
                  name="unit"
                  value={values.unit}
                  onChange={handleChange}
                  error={touched['unit'] && Boolean(errors['unit'])}
                  helperText={touched['unit'] && errors['unit']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  id="line1"
                  label={t('addStore.street_name')}
                  name="line1"
                  value={values.line1}
                  onChange={handleChange}
                  error={touched['line1'] && Boolean(errors['line1'])}
                  helperText={touched['line1'] && errors['line1']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  id="city_town"
                  required
                  label={t('addStore.city')}
                  name="city_town"
                  value={values.city_town}
                  onChange={handleChange}
                  error={touched['city_town'] && Boolean(errors['city_town'])}
                  helperText={touched['city_town'] && errors['city_town']}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="postcode"
                  label={t('addStore.postcode')}
                  name="postcode"
                  value={values.postcode}
                  onChange={handleChange}
                  error={touched['postcode'] && Boolean(errors['postcode'])}
                  helperText={touched['postcode'] && errors['postcode']}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="country"
                  required
                  component={Autocomplete}
                  options={countries}
                  getOptionLabel={(option) => option}
                  // style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                      label={t('addStore.country')}
                    />
                  )}
                  value={values['country']}
                  onChange={(e, value) => {
                    setFieldValue('country', value);
                  }}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={1}>
              <Button
                color="primary"
                className={classes.enterFullAddressButton}
                variant="contained"
                type="submit"
              >
                Continue
              </Button>
            </Grid>
          </div>
        </form>
      )}
    </Formik>
  );
}
