import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';
import NoResults from '../../components/NoResults';

import useStyles from './styles';
import AllergySearchInput from '@/components/allergySearchInput';
import AllergyItem from './allergyItem';
import _ from 'lodash';

const Allergy = (props) => {
  const { productData, updateProductData } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const onSelectAllergy = (allergy) => {
    // ignore allergy that has been already added to this product.
    if (_.find(productData?.allergies, { id: allergy.id })) return;

    const newAllergy = {
      id: allergy.id,
      name: allergy.name,
      image: allergy.image,
    };
    updateProductData('allergies', [
      ...productData?.allergies,
      newAllergy,
    ]);
  };

  const onDelete = (id) => {
    updateProductData(
      'allergies',
      productData?.allergies?.filter(
        (allergy) => !_.isEqual(allergy.id, id)
      )
    );
  };

  return (
    <Box
      marginTop={7}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography className={classes.title}>
        {t('add_product.search_and_find_allergies')}
      </Typography>

      <Box className={classes.groupSearchWrapper}>
        <AllergySearchInput onSelectAllergy={onSelectAllergy} />
      </Box>

      <Box paddingY={10}>
        {_.isEmpty(productData?.allergies) ? (
          <NoResults title={t('add_product.no_allergies_added')} />
        ) : (
          <Box display="flex" flexWrap="wrap">
            {productData?.allergies?.map((allergy) => (
              <Box marginRight={7} marginBottom={7} key={allergy.id}>
                <AllergyItem
                  allergy={allergy}
                  onDelete={() => onDelete(allergy.id)}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Allergy;
