import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { find, omit } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Grid,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';

import Bogof from './bogof';
import Manual from './manual';
import MealDeal from './mealDeal';
import MultiBuy from './multiBuy';
import ReducedPrice from './reducedPrice';

import { useMutation } from '@apollo/client';
import { ADD_PROMOTION } from '@/services/promotionService';

import {
  selectNetPrice,
  selectSelectedStore,
} from '@/store/modules/store';

import { roundToTwo } from '@/utils/mathUtil';
import { calculatePrice } from '@/utils/priceUtil';
import { PROMOTION_TYPE } from '@/constants/promotion';
import {
  initPromotion,
  generateVariablesToAddPromotion,
} from '../helper';
import useStyles from './styles';

const MainSection = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    promotionType,
    defaultPromotion,
    storeOrderTypes,
    handleClose,
    onSuccess,
  } = props;

  const netPrice = useSelector(selectNetPrice);
  const selectedStore = useSelector(selectSelectedStore);

  const orderTypes = defaultPromotion
    ? defaultPromotion.order_type
        .split(',')
        ?.map((orderTypeId) =>
          find(storeOrderTypes, { id: orderTypeId })
        )
        ?.filter((item) => !!item)
    : storeOrderTypes;

  const [promotion, setPromotion] = useState(
    initPromotion(
      promotionType,
      defaultPromotion,
      orderTypes,
      netPrice
    )
  );

  useEffect(() => {
    if (orderTypes.length) {
      setPromotion(
        initPromotion(
          promotionType,
          defaultPromotion,
          orderTypes,
          netPrice
        )
      );
    }
  }, [orderTypes?.length]);

  const [addUpdatePromotion, { loading }] = useMutation(
    ADD_PROMOTION,
    {
      onCompleted: (data) => {
        const message = defaultPromotion
          ? t('promotions.promotion_updated')
          : t('promotions.promotion_added');
        enqueueSnackbar(message);
        handleClose();
        onSuccess(data.addPromotion);
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      },
    }
  );

  const onAddUpdatePromotion = () => {
    if (promotion.type === PROMOTION_TYPE.REDUCED_PRICE) {
      const priceInfos = (
        promotion.options?.[0]?.products[0]?.prices?.[0]
          ?.price_infos || []
      ).filter(
        (priceInfo) =>
          !!find(orderTypes, {
            pricing_type: priceInfo?.price_type?.type,
          })
      );
      const prices = priceInfos.map((priceInfo) => {
        return calculatePrice(priceInfo.price, priceInfo.taxes, {
          includeTax: !netPrice,
        });
      });
      for (var i = 0; i < priceInfos.length; i++) {
        let promoValue = roundToTwo(
          ((prices[i] - +promotion.reduced_price) / prices[i]) * 100
        );
        let newPromotion = {
          ...promotion,
          type: PROMOTION_TYPE.MANUAL,
          promo_name:
            promotion.promo_name ||
            promotion.options?.[0]?.products?.[0]?.name +
              ' promotion',
          promo_value: promoValue,
          valid_to: promotion.further_notice
            ? null
            : promotion.valid_to,
          start_time: promotion.add_happy_hour
            ? promotion.start_time
            : null,
          end_time: promotion.add_happy_hour
            ? promotion.end_time
            : null,
        };
        let filteredOrderTypes = orderTypes.filter(
          (orderType) =>
            orderType.pricing_type === priceInfos[i]?.price_type?.type
        );
        let variables = generateVariablesToAddPromotion(
          newPromotion,
          selectedStore.id,
          filteredOrderTypes
        );
        if (!defaultPromotion) {
          // We omit para 'id' to create new promotion, but include it to update promotion
          variables = {
            promotion: omit(variables.promotion, 'id'),
          };
        }
        addUpdatePromotion({
          variables,
        });
      }
    } else {
      let newPromotion = {
        ...promotion,
        valid_to: promotion.further_notice
          ? null
          : promotion.valid_to,
        start_time: promotion.add_happy_hour
          ? promotion.start_time
          : null,
        end_time: promotion.add_happy_hour
          ? promotion.end_time
          : null,
      };
      let variables = generateVariablesToAddPromotion(
        newPromotion,
        selectedStore.id,
        orderTypes
      );
      if (!defaultPromotion) {
        // We omit para 'id' to create new promotion, but include it to update promotion
        variables = {
          promotion: omit(variables.promotion, 'id'),
        };
      }

      addUpdatePromotion({
        variables,
      });
    }
  };

  const title = {
    [PROMOTION_TYPE.REDUCED_PRICE]: defaultPromotion
      ? t('promotions.update_reduced_price_promotion')
      : t('promotions.add_reduced_price_promotion'),
    [PROMOTION_TYPE.MANUAL]: defaultPromotion
      ? t('promotions.update_manual_promotion')
      : t('promotions.add_manual_promotion'),
    [PROMOTION_TYPE.MEAL_DEAL]: defaultPromotion
      ? t('promotions.update_mealdeal_promotion')
      : t('promotions.add_mealdeal_promotion'),
    [PROMOTION_TYPE.MULTI_BUY]: defaultPromotion
      ? t('promotions.update_multibuy_promotion')
      : t('promotions.add_multibuy_promotion'),
    [PROMOTION_TYPE.BOGOF]: defaultPromotion
      ? t('promotions.update_bogof_promotion')
      : t('promotions.add_bogof_promotion'),
  };

  const contents = {
    [PROMOTION_TYPE.BOGOF]: (
      <Bogof
        defaultPromotion={defaultPromotion}
        promotion={promotion}
        onChangePromotion={setPromotion}
        onAddUpdatePromotion={onAddUpdatePromotion}
        handleClose={handleClose}
      />
    ),
    [PROMOTION_TYPE.MANUAL]: (
      <Manual
        defaultPromotion={defaultPromotion}
        promotion={promotion}
        onChangePromotion={setPromotion}
        onAddUpdatePromotion={onAddUpdatePromotion}
        handleClose={handleClose}
      />
    ),
    [PROMOTION_TYPE.MEAL_DEAL]: (
      <MealDeal
        defaultPromotion={defaultPromotion}
        promotion={promotion}
        onChangePromotion={setPromotion}
        onAddUpdatePromotion={onAddUpdatePromotion}
        handleClose={handleClose}
      />
    ),
    [PROMOTION_TYPE.MULTI_BUY]: (
      <MultiBuy
        defaultPromotion={defaultPromotion}
        promotion={promotion}
        onChangePromotion={setPromotion}
        onAddUpdatePromotion={onAddUpdatePromotion}
        handleClose={handleClose}
      />
    ),
    [PROMOTION_TYPE.REDUCED_PRICE]: (
      <ReducedPrice
        defaultPromotion={defaultPromotion}
        promotion={promotion}
        onChangePromotion={setPromotion}
        onAddUpdatePromotion={onAddUpdatePromotion}
        handleClose={handleClose}
      />
    ),
  };

  return (
    <Box>
      <DialogTitle id="alert-dialog-slide-title">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          alignContent="center"
        >
          <Grid item xs={1} />
          <Grid className={classes.headerText} item xs={10}>
            {title[promotionType]}
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            <IconButton
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.rootDialog}>
          {contents[promotionType]}
        </div>
      </DialogContent>

      {loading && <PreLoader size={35} />}
    </Box>
  );
};

export default MainSection;
