import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { isEmpty } from 'lodash'

import {
  Box,
  Grid,
  Typography,
  Button
} from '@material-ui/core'
import PreLoader from '@/components/preLoader'
import PDFDocument from './pdfDocument'

import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { CSVLink } from 'react-csv'
import { getCSVData } from './csvDocument'

import { useLazyQuery, gql } from '@apollo/client'
import { GET_ACCOUNT_TYPES_BUSINESS_OVERVIEW } from '@/services/widgetService'

import { transformFloat } from '@/utils/localisationUtil'
import useStyles from './styles'
import { selectLocalisation } from '@/store/modules/store'

const BusinessOverview = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { store, reportTime } = props
  const localisation = useSelector(selectLocalisation)
  const csvRef = useRef()

  const [getAccountTypes, { data, loading: isLoadingAccountTypes }] = useLazyQuery(gql`${GET_ACCOUNT_TYPES_BUSINESS_OVERVIEW}`, {
    fetchPolicy: 'network-only'
  })
  const accountTypes = data?.accountTypes || []

  useEffect(() => {
    getAccountTypes({
      variables: {
        start: reportTime.start,
        end: reportTime.end
      },
      context: {
        headers: { storeId: store.id },
      },
    })
  }, [store, reportTime])

  const onClickPrint = () => { }

  const onClickExportPDF = async () => {
    const doc = (
      <PDFDocument
        store={store}
        reportTime={reportTime}
        accountTypes={accountTypes}
        localisation={localisation}
      />
    )
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, 'business-overview.pdf');
  }

  const onClickExportCSV = () => {
    csvRef.current.link.click()
  }

  const hasAccounts = (childrenType) => {
    return !isEmpty((childrenType.accounts || []).filter(account => account.display_in_report))
  }

  return (
    <div className={classes.root}>
      <Grid container alignItems='center'>
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={4}>
          <Typography className={classes.titleText}>
            {t('workboard.run_reports.business_overview')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container justify='space-between'>
            <Grid>
              <Button disabled onClick={onClickPrint} color="primary">
                {t('workboard.run_reports.print')}
              </Button>
            </Grid>
            <Grid>
              <Button onClick={onClickExportPDF} color="primary">
                {t('workboard.run_reports.export_pdf')}
              </Button>
            </Grid>
            <Grid>
              <Button onClick={onClickExportCSV} color="primary">
                {t('workboard.run_reports.export_csv')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box style={{ marginTop: 25 }}>
        <Typography className={classes.storeNameText}>
          {store.name}
        </Typography>
      </Box>

      <Box style={{ marginTop: 25 }}>
        <Typography className={classes.storeNameText}>
          {moment(reportTime.start).format('DD/MM/YYYY HH:mm:ss') + ' - ' + moment(reportTime.end).format('DD/MM/YYYY HH:mm:ss')}
        </Typography>
      </Box>


      {accountTypes.map((accountType, id1) => (
        <Box
          key={`account-type-${id1}`}
          className={classes.accountTypeContainer}
        >
          <Box display='flex' justifyContent='space-between'>
            <Typography className={classes.titleText}>{accountType.name}</Typography>
            <Typography className={classes.titleText}>
              {transformFloat(accountType.performance.performance, localisation)}
            </Typography>
          </Box>
          {accountType.children_types
            .filter(childrenType => hasAccounts(childrenType))
            .map((childrenType, id2) => (
              <Box
                key={`children-type-${id1}-${id2}`}
                className={classes.childrenTypeContainer}
              >
                <Box
                  display='flex'
                  justifyContent='space-between'
                  className={classes.childrenTypeWrapper}
                >
                  <Typography className={classes.storeNameText}>
                    {childrenType.name}
                  </Typography>
                  <Typography className={classes.storeNameText}>
                    {transformFloat(childrenType.performance.performance, localisation)}
                  </Typography>
                </Box>
                {childrenType.accounts
                  .filter(account => account.display_in_report)
                  .map((account, id3) => (
                    <Box
                      key={`account-${id1}-${id2}-${id3}`}
                      display='flex'
                      justifyContent='space-between'
                      className={classes.accountWrapper}
                    >
                      <Typography className={classes.storeNameText}>
                        {account.name}
                      </Typography>
                      <Typography className={classes.storeNameText}>
                        {transformFloat(account.performance.performance, localisation)}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            ))}
        </Box>
      ))}

      <CSVLink
        data={getCSVData(store, reportTime, accountTypes, localisation)}
        filename="business-overview.csv"
        className="hidden"
        ref={csvRef}
        target="_blank"
      />

      {isLoadingAccountTypes && <PreLoader size={30} />}
    </div>
  )
}

BusinessOverview.propTypes = {
  store: PropTypes.object,
  reportTime: PropTypes.object,
}

export default BusinessOverview