import React, { useState, useEffect } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import SettingAccordion from '.';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import ColorPicker from '../colorPicker';
import {
  MERCHANT_SETTINGS,
  MUTATE_MERCHANT_SETTINGS,
} from '@/services/storeService';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import PreLoader from '@/components/preLoader';

const merchantSettingsQuery = gql`
  ${MERCHANT_SETTINGS}
`;

const Branding = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState(false);

  const onExpand = (value) => setIsExpand(value);

  const [
    getMerchantSetting,
    { loading: loadingMerchantSetting, data: settings },
  ] = useLazyQuery(merchantSettingsQuery);
  const merchantSettings = settings?.merchantSettings;

  const [mutateMerchantSettings] = useMutation(
    gql`
      ${MUTATE_MERCHANT_SETTINGS}
    `
  );

  const updateCustomizeColour = (key, value) => {
    const { ['__typename']: _, ...newCustomizeColour } = {
      ...merchantSettings?.branding?.customize,
    };
    const customizeColour = {
      ...newCustomizeColour,
      index: '1',
      name: 'default',
      [key]: value,
    };

    const payload = {
      variables: {
        merchantSettings: {
          branding: {
            index: '1',
            customize: customizeColour,
          },
        },
      },
      update: (store, { data: { merchantSettings } }) => {
        const cache = store.readQuery({
          query: merchantSettingsQuery,
        });

        const updatedData = {
          ...cache,
          merchantSettings: {
            ...cache.merchantSettings,
            branding: {
              ...cache.merchantSettings.branding,
              customize: {
                ...cache.merchantSettings.branding.customize,
                [key]: value,
              },
            },
          },
        };
        store.writeQuery({
          query: merchantSettingsQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateMerchantSettings: {
          merchantSettings: {
            branding: {
              index: '1',
              customize: {
                index: '1',
                name: 'default',
                [key]: value,
              },
            },
          },
        },
      },
    };
    return mutateMerchantSettings(payload);
  };

  useEffect(() => {
    if (isExpand) {
      getMerchantSetting();
    }
  }, [isExpand]);

  return (
    <SettingAccordion
      id="branding"
      title={t('settings.branding.title')}
      onExpand={onExpand}
    >
      {loadingMerchantSetting ? (
        <PreLoader size={30} />
      ) : (
        <Grid container>
          <Box marginTop={-5}>
            <Typography className={classes.descriptionText}>
              {t('settings.branding.description')}
            </Typography>
          </Box>
          <Grid container className={classes.boxColorGroup}>
            <Grid item xs={12} md={4} lg={3} xl={2}>
              <ColorPicker
                key={merchantSettings}
                label={t('settings.branding.primary_colour')}
                value={merchantSettings?.branding?.customize?.primary}
                onChange={(value) =>
                  updateCustomizeColour('primary', value)
                }
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={2}>
              <ColorPicker
                key={merchantSettings}
                label={t('settings.branding.secondary_colour')}
                value={
                  merchantSettings?.branding?.customize?.secondary
                }
                onChange={(value) =>
                  updateCustomizeColour('secondary', value)
                }
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={2}>
              <ColorPicker
                key={merchantSettings}
                label={t('settings.branding.third_colour')}
                value={merchantSettings?.branding?.customize?.third}
                onChange={(value) =>
                  updateCustomizeColour('third', value)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </SettingAccordion>
  );
};

export default Branding;
