import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Typography, Box, FormHelperText } from '@material-ui/core';
import TextField from '@/components/textField';
import CurrencyInput from '@/components/currencyInput';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';

import { getCurrencySymbol } from '@/utils/localisationUtil';
import useStyles from './styles';

const Input = (props) => {
  const classes = useStyles();

  const [hasEntered, setHasEntered] = useState(false);

  const {
    isCurrencyInput = false,
    localisation = null,
    title,
    emptyLabel,
    value,
    onChange,
    onBlur,
    error,
    helperText,
    ...restProps
  } = props;

  const currencySymbol = getCurrencySymbol(localisation)
  const paddingLeftOfPrice =
    localisation?.currency_position === 'prefix' && value ? 15 * currencySymbol?.length : 0;

  return (
    <Box>
      <Typography
        className={classNames(classes.titleText, {
          [classes.errorTitleText]: error,
        })}
      >
        {title}
      </Typography>

      {!hasEntered && !value && (
        <Box>
          <Box onClick={() => setHasEntered(true)}>
            <Typography className={classes.emptyLabel}>
              {emptyLabel}
            </Typography>
          </Box>
          {error && (
            <FormHelperText error>{helperText}</FormHelperText>
          )}
        </Box>
      )}
      <Box style={{ position: 'relative' }}>
        {(!hasEntered && !!value) && (
          <Box
            className={classes.labelContainer}
            onClick={() => setHasEntered(true)}
          >
            <Typography
              style={{ paddingLeft: paddingLeftOfPrice }}
              className={classes.label}
            >
              {value}
              <IconButton
                className={classes.iconButton}
                onClick={() => setHasEntered(true)}
              >
                <CreateIcon fontSize="small" />
              </IconButton>
            </Typography>
          </Box>
        )}

        {hasEntered &&
          (isCurrencyInput ? (
            <CurrencyInput
              style={{ paddingLeft: paddingLeftOfPrice }}
              autoFocus
              className={value && classes.currencyInput}
              value={value}
              onChange={onChange}
              error={error}
              onBlur={(e) => {
                if (value) {
                  setHasEntered(false);
                }
                onBlur?.(e);
              }}
              helperText={helperText}
              {...restProps}
            />
          ) : (
            <TextField
              style={{ paddingLeft: paddingLeftOfPrice }}
              autoFocus
              className={value && classes.textField}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              onBlur={(e) => {
                if (value) {
                  setHasEntered(false);
                }
                onBlur?.(e);
              }}
              error={error}
              helperText={error && helperText}
              {...restProps}
            />
          ))}

        {localisation && !!value && (
          <Typography
            style={{
              position: 'absolute',
              top: 6,
              left:
                localisation.currency_position === 'prefix'
                  ? 0
                  : value.length * 11,
            }}
            className={classes.titleText}
          >
            {currencySymbol}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

Input.propTypes = {
  isCurrencyInput: PropTypes.bool,
  title: PropTypes.string,
  emptyLabel: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default Input;
