import { createReducer } from 'reduxsauce';

import { INITIAL_STATE } from './initialState';
import { MerchantTypes } from './actions';

export const setCurrentMerchant = (state, { data }) => {
  return {
    ...state,
    currentMerchant: data,
  };
};

export const setSelectedStore = (state, { data }) => {
  document.cookie = `bm_merchant_id=${data.merchant_id}`;
  document.cookie = `bm_store_id=${data.id}`;
  return {
    ...state,
    selectedStore: data,
  };
};

export const setSelectedRegion = (state, { selectedRegion }) => ({
  ...state,
  selectedRegion,
});

export const updateSelectedStore = (state, { data }) => {
  return {
    ...state,
    selectedStore: { ...state.selectedStore, ...data },
    currentMerchant: {
      ...state.currentMerchant,
      stores: state.currentMerchant.stores.map((store) =>
        store.id === data.id ? { ...store, ...data } : store
      ),
    },
  };
};

export const setMerchantSettings = (state, { merchantSettings }) => {
  return {
    ...state,
    merchantSettings,
  };
};

export const addNewStore = (state, { data }) => {
  return {
    ...state,
    currentMerchant: {
      ...state.currentMerchant,
      stores: data.merchant_id === state.currentMerchant.id
        ? [...state.currentMerchant.stores, data]
        : [...state.currentMerchant.stores]
    }
  };
};


export const reducer = createReducer(INITIAL_STATE, {
  [MerchantTypes.SET_CURRENT_MERCHANT]: setCurrentMerchant,
  [MerchantTypes.SET_SELECTED_STORE]: setSelectedStore,
  [MerchantTypes.SET_SELECTED_REGION]: setSelectedRegion,
  [MerchantTypes.UPDATE_SELECTED_STORE]: updateSelectedStore,
  [MerchantTypes.SET_MERCHANT_SETTINGS]: setMerchantSettings,
  [MerchantTypes.ADD_STORE]: addNewStore,
});
