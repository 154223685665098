import React from 'react'
import { useTranslation } from 'react-i18next';
import { find } from 'lodash'

import {
  TableHead,
  TableRow,
  TableCell,
  withStyles
} from '@material-ui/core'

import { CommonFonts } from '@/theme'
import { STYLE_OPTION_FIELD_DISPLAY } from '@/constants/styles'

const TableHeader = ({ fields }) => {

  const actionWidth = 5
  const otherWidth = 100 - actionWidth

  const mainField = find(fields, { display: STYLE_OPTION_FIELD_DISPLAY.MAIN })
  const otherFields = fields.filter(field => field.display !== STYLE_OPTION_FIELD_DISPLAY.MAIN)
  const newFields = mainField ? [mainField, ...otherFields] : [...otherFields]

  const headCells = newFields.map(field => ({
    id: field.name,
    label: field.name,
    width: `${otherWidth / newFields.length}%`
  }))
  headCells.push({
    id: 'empty',
    label: '',
    width: `${actionWidth}%`,
  })

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            style={{
              width: headCell.width,
              borderBottom: 'none'
            }}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
    ...CommonFonts.darkFont14,
    fontWeight: 'normal'
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont14
  },
}))(TableCell);


export default TableHeader