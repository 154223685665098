import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../constants/colors'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: '#ffffffba',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      borderRadius: 15,
    },
    progressWrapper: {
      padding: 20,
      borderRadius: 50,
      backgroundColor: Colors.WHITE,
      boxShadow: '0px 3px 6px #7395B229',
    }
  })
)

export default useStyles
