export const INITIAL_STATE = {
  tutorials: [
    // {
    //   title: "It all starts here.",
    //   content: "Let's begin our journey!",
    // },
    // {
    //   title: "It all starts there.",
    //   content: "Let's begin our journey!",
    // },
  ]
}
