import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  headerText: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    top: 20,
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
  dialogContent: {
    height: '70vh',
  },
  rootDialog: {
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 5)
    },
  },
  cancelButton: {
    ...CommonFonts.grayFont16,
    letterSpacing: '0.11px',
    color: Colors.GRAY_BORDER3,
    opacity: 1,
  },
  tab: {
    '& .MuiTab-wrapper': {
      textTransform: 'none',
    },
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      borderBottomWidth: 2,
      borderBottomColor: '#E2E2E2',
      borderBottomStyle: 'solid',
    },
  },
}));

export default useStyles;
