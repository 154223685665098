import moment from 'moment'

export const getYearDateRange = (year, financialYearEnd = null) => {
  var startDate, endDate
  if (financialYearEnd) {
    var financialYearStart = moment(financialYearEnd).add(1, 'day')
    financialYearStart.set('year', year)
    startDate = moment(financialYearStart).startOf('day')
    endDate = moment(financialYearStart).add(1, 'year').subtract(1, 'day').endOf('day')
  } else {
    startDate = moment([year]).startOf('year');
    endDate = moment([year]).endOf('year');
  }
  if (endDate > moment()) endDate = moment()

  return { start: startDate, end: endDate };
}

export const getQuarterDateRange = (year, quarter, financialYearEnd = null) => {
  var startDate, endDate
  if (financialYearEnd) {
    var financialYearStart = moment(financialYearEnd).add(1, 'day')
    financialYearStart.set('year', year)
    startDate = moment(financialYearStart).add(quarter - 1, 'quarter').startOf('day')
    endDate = moment(financialYearStart).add(quarter, 'quarter').subtract(1, 'day').endOf('day')
  } else {
    startDate = moment([year]).quarter(quarter).startOf('quarter');
    endDate = moment([year]).quarter(quarter).endOf('quarter');
  }

  if (endDate > moment()) endDate = moment()

  return { start: startDate, end: endDate };
}

export const getMonthDateRange = (year, month) => {
  var startDate = moment([year, month - 1]);
  var endDate = moment(startDate).endOf('month');
  if (endDate > moment()) endDate = moment()

  return { start: startDate, end: endDate };
}

export const getWeekDateRange = (year, week, financialYearEnd = null, weekStartDay = 0) => {
  var financialYearStart, financialFirstWeekDate, startDate, endDate
  if (financialYearEnd) {
    financialYearStart = moment(financialYearEnd).add(1, 'day')
    financialYearStart.set('year', year)
  } else {
    financialYearStart = moment([year]).startOf('year')
  }
  financialFirstWeekDate = financialYearStart.day(weekStartDay)
  if (financialFirstWeekDate < financialYearStart) {
    financialFirstWeekDate.add(1, 'week')
  }

  startDate = moment(financialFirstWeekDate).add(week - 1, 'week').startOf('day')
  endDate = moment(financialFirstWeekDate).add(week, 'week').subtract(1, 'day').endOf('day')

  if (endDate > moment()) endDate = moment()

  return { start: startDate, end: endDate };
}

export const getDateRange = (date) => {
  var startDate = moment(date).startOf('day');
  var endDate = moment(date).endOf('day');
  if (endDate > moment()) endDate = moment()

  return { start: startDate, end: endDate };
}