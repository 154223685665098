import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommonFonts, Colors } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    logo: {
      paddingTop: theme.spacing(14),
      paddingBottom: theme.spacing(12),
    },
    selectStoreText: {
      letterSpacing: 0.18,
      ...CommonFonts.darkFont24,
      marginBottom: theme.spacing(13),
    },
    storesLayout: {
      marginTop: 0,
    },
  })
);

export default useStyles;
