import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import images from '@/assets/images';
import useStyles from '../styles';

const NoProducts = ({ onAddFirstCategory }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={10}
    >
      <Grid item>
        <img src={images['noProducts']} width={151} height={151} />
      </Grid>
      <Grid item>
        <span className={classes.emptyProductsTextL}>
          {t('categories.empty_list_of_products')}
        </span>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-around" direction="row">
          <Grid item style={{ paddingRight: 60 }}>
            <Button
              className={classes.disableTextTransform}
              color="primary"
              onClick={onAddFirstCategory}
            >
              {t('categories.add_first_category')}
            </Button>
          </Grid>
          <Grid item style={{ paddingRight: 60 }}>
            <Button
              className={classes.disableTextTransform}
              color="primary"
              onClick={() => history.push('/import')}
            >
              {t('categories.import_products')}
            </Button>
          </Grid>
          <Grid item style={{ paddingRight: 60 }}>
            <Button
              className={classes.disableTextTransform}
              color="primary"
              disabled
            >
              {t('categories.move_products_another_store')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NoProducts;
