import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_TAXES,
  ADD_NEW_CATEGORY,
} from '@/services/inventoryService';
import ImagePicker from '@/components/imagePicker';
import SettingSwitch from '@/components/settingSwitch';
import { useTranslation } from 'react-i18next';
import RangeTimePickerSwitch from '@/components/rangeTimePickerSwitch';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useSnackbar } from 'notistack';
import CategoriesAutocomplete from './addNewCategory/CategoriesAutocomplete';
import ParentAutocomplete from './addNewCategory/ParentAutocomplete';
import TaxesAutocomplete from './addNewCategory/TaxesAutocomplete';
import useStyles from './styles';

export default function ResponsiveDialog({
  open,
  handleClose,
  allCategories,
  emptyCategories,
  setRefetchCategories,
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [taxes, settaxes] = useState();
  const [categoryTaxes, setCategoryTaxes] = useState([]);
  const [initialTaxes, setInitialTaxes] = useState();
  const [taxesObjLength, settaxesObjLength] = useState();
  const [mainCategorySelected, setMainCategorySelected] = useState();
  const [parentCategoryId, setParentCategoryId] = useState();
  const [ageSelected, setAgeSelected] = useState();
  const [ageRestriction, setAgeRestriction] = useState(false);
  const [comissionSales, setComissionSales] = useState(false);
  const [chefNotes, setChefNotes] = useState(false);
  const [soldHours, setSoldHours] = useState(false);
  const [sellTimeRange, setsellTimeRange] = useState();
  const [imageId, setImageId] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { loading: isLoadingTaxes } = useQuery(GET_TAXES, {
    onCompleted: (data) => {
      const grouped = _.groupBy(data.store.taxes, 'name');
      settaxesObjLength(Object.keys(grouped).length);
      settaxes(grouped);
      setInitialTaxes(grouped);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    // if (!mainCategorySelected?.isNewButton) {
    //   const grouped = _.groupBy(mainCategorySelected?.taxes, 'name');
    //   settaxes(grouped);
    // } else {
    //   settaxes(initialTaxes);
    // }
    setImageUrl(mainCategorySelected?.thumbnail);
  }, [mainCategorySelected]);

  const [AddNewCategory, { loading: loadingSaveCategory }] =
    useMutation(ADD_NEW_CATEGORY, {
      onCompleted: (response) => {
        enqueueSnackbar(
          t('categories.category_created_successfully')
        );
        handleClose();
        setRefetchCategories();
      },
      onError: (error) => {
        console.log('error', error);
      },
    });

  // Save new category created
  const addNewCategory = () => {
    const payload = {
      variables: {
        input: {
          name: mainCategorySelected?.name,
          tax_id: categoryTaxes.map((tax) => tax.id).join(','),
          parent: parentCategoryId,
          age_restrict: ageRestriction,
          age_restriction: ageSelected && parseInt(ageSelected, 10),
          sell_time_restrict: soldHours,
          sell_time_from: sellTimeRange && sellTimeRange[0],
          sell_time_until: sellTimeRange && sellTimeRange[1],
          commission: comissionSales,
          kitchen_print: chefNotes,
          image: imageId && {
            id: imageId,
          },
        },
      },
    };
    return AddNewCategory(payload);
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          onClose={() => console.log('ok')}
        >
          <Grid
            container
            alignItems="center"
            justify="space-between"
            alignContent="center"
          >
            <Grid item xs={1} />
            <Grid className={classes.headerText} item xs={10}>
                {t('categories.add_new_category')}
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {(loadingSaveCategory || isLoadingTaxes) && (
            <PreLoader size={25} />
          )}
          <div className={classes.rootDialog}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  spacing={5}
                  justify={
                    taxesObjLength &&
                    taxesObjLength === 2 &&
                    taxesObjLength === 1
                      ? 'space-between'
                      : 'flex-start'
                  }
                >
                  <Grid item sm={12} xs={12}>
                    <span className={classes.blockTitle}>
                      {t('categories.general_information')}
                    </span>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <CategoriesAutocomplete
                      allCategories={allCategories}
                      setMainCategorySelected={(value) =>
                        setMainCategorySelected(value)
                      }
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <ParentAutocomplete
                      allCategories={allCategories}
                      emptyCategories={emptyCategories}
                      setParentCategoryId={(value) =>
                        setParentCategoryId(value)
                      }
                    />
                  </Grid>
                  {taxes &&
                    Object.keys(taxes).map((taxName, index) => (
                      <Grid key={index} item sm={3} xs={12}>
                        <TaxesAutocomplete
                          taxName={taxName}
                          taxes={taxes[taxName]}
                          selectedTax={categoryTaxes.find(
                            (tax) => tax.name === taxName
                          )}
                          onChangeTax={(tax) => {
                            if (
                              categoryTaxes.find(
                                (item) => item.name === tax.name
                              )
                            ) {
                              setCategoryTaxes(
                                categoryTaxes.map((item) =>
                                  item.name === tax.name ? tax : item
                                )
                              );
                            } else {
                              setCategoryTaxes([
                                ...categoryTaxes,
                                tax,
                              ]);
                            }
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  className={classes.categoryImage}
                  spacing={5}
                >
                  <Grid item xs={12}>
                    <span className={classes.blockTitle}>
                      {t('categories.category_image')}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <ImagePicker
                      widthCrop={450}
                      heightCrop={300}
                      thumbnail={imageUrl || ''}
                      onChange={(value, imageProperty) => {
                        setImageId(value.id);
                        setImageUrl(imageProperty);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  className={classes.rules}
                  spacing={5}
                  justify="space-between"
                >
                  <Grid item sm={12} xs={12}>
                    <span className={classes.blockTitle}>
                      {t('categories.rules')}
                    </span>
                  </Grid>

                  <Grid item sm={5} xs={12}>
                    <SettingSwitch
                      label={t('categories.age_restriction')}
                      description={t(
                        'categories.age_restriction_description'
                      )}
                      value={ageRestriction}
                      optionsText={ageSelected}
                      optionsValue="Ex.18"
                      optionsName="floatAmount"
                      optionsLabel={t('categories.enter_minimum_age')}
                      onChangeOptions={(value) =>
                        setAgeSelected(value)
                      }
                      onChange={(value) => setAgeRestriction(value)}
                      disableNotification
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <RangeTimePickerSwitch
                      label={t('categories.sold_hours')}
                      description={t(
                        'categories.sold_hours_description'
                      )}
                      value={soldHours}
                      onChange={(value) => setSoldHours(value)}
                      onChangeOptions={(value) =>
                        setsellTimeRange(value)
                      }
                      optionsText={sellTimeRange}
                      disableNotification
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <SettingSwitch
                      label={t('categories.commission_sales')}
                      description={t(
                        'categories.commission_sales_description'
                      )}
                      value={comissionSales}
                      onChange={(value) => setComissionSales(value)}
                      disableNotification
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <SettingSwitch
                      label={t('categories.chef_note')}
                      description={t(
                        'categories.chef_note_description'
                      )}
                      value={chefNotes}
                      onChange={(value) => setChefNotes(value)}
                      disableNotification
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: 30 }}>
          <Button
            color="primary"
            onClick={handleClose}
            className={classes.cancelButton}
          >
            {t('categories.cancel')}
          </Button>
          <Button
            disabled={!mainCategorySelected || isEmpty(categoryTaxes)}
            color="primary"
            onClick={addNewCategory}
          >
            {t('categories.add')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
