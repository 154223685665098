import I18n from '@/i18n'
import moment from 'moment'
import {
  getYearDateRange,
  getQuarterDateRange,
  getMonthDateRange,
  getWeekDateRange,
  getDateRange
} from '@/utils/momentUtil'

export const REPORT = {
  BUSINESS_OVERVIEW: I18n.t('workboard.run_reports.business_overview'),
  BUSINESS_SNAPSHOT: I18n.t('workboard.run_reports.business_snapshot'),
}

export const REPORT_TYPE = {
  DAILY_REPORT: I18n.t('workboard.run_reports.daily_report'),
  WEEKLY_REPORT: I18n.t('workboard.run_reports.weekly_report'),
  MONTHLY_REPORT: I18n.t('workboard.run_reports.monthly_report'),
  QUARTERLY_REPORT: I18n.t('workboard.run_reports.quarterly_report'),
  ANNUAL_REPORT: I18n.t('workboard.run_reports.annual_report'),
  CUSTOM_REPORT: I18n.t('workboard.run_reports.custom_report'),
}
const THIS_YEAR = moment().year()
export const YEARS = [
  THIS_YEAR,
  THIS_YEAR - 1,
  THIS_YEAR - 2,
  THIS_YEAR - 3,
  THIS_YEAR - 4,
  THIS_YEAR - 5,
]

export const QUARTERS = [
  {
    id: 1,
    name: 'Q1',
  },
  {
    id: 2,
    name: 'Q2',
  },
  {
    id: 3,
    name: 'Q3',
  },
  {
    id: 4,
    name: 'Q4',
  },
]

export const MONTHS = [
  {
    id: 1,
    name: 'January',
  },
  {
    id: 2,
    name: 'February',
  },
  {
    id: 3,
    name: 'March',
  },
  {
    id: 4,
    name: 'April',
  },
  {
    id: 5,
    name: 'May',
  },
  {
    id: 6,
    name: 'June',
  },
  {
    id: 7,
    name: 'July',
  },
  {
    id: 8,
    name: 'August',
  },
  {
    id: 9,
    name: 'September',
  },
  {
    id: 10,
    name: 'October',
  },
  {
    id: 11,
    name: 'November',
  },
  {
    id: 12,
    name: 'December',
  },
]

export const WEEKS = (year) => Array.apply(null, Array(moment([year]).weeksInYear())).map((val, i) => (
  {
    id: i + 1,
    name: `Week ${i + 1}`
  }
))

export const getReportTime = (
  reportType,
  year,
  periodId,
  date,
  customRange,
  weekStartDay,
  financialYearEnd
) => {
  let start, end
  if (reportType === REPORT_TYPE.ANNUAL_REPORT) {
    start = getYearDateRange(year, financialYearEnd).start
    end = getYearDateRange(year, financialYearEnd).end
  } else if (reportType === REPORT_TYPE.QUARTERLY_REPORT) {
    start = getQuarterDateRange(year, periodId, financialYearEnd).start
    end = getQuarterDateRange(year, periodId, financialYearEnd).end
  } else if (reportType === REPORT_TYPE.MONTHLY_REPORT) {
    start = getMonthDateRange(year, periodId).start
    end = getMonthDateRange(year, periodId).end
  } else if (reportType === REPORT_TYPE.WEEKLY_REPORT) {
    start = getWeekDateRange(year, periodId, financialYearEnd, weekStartDay).start
    end = getWeekDateRange(year, periodId, financialYearEnd, weekStartDay).end
  } else if (reportType === REPORT_TYPE.DAILY_REPORT) {
    start = getDateRange(date).start
    end = getDateRange(date).end
  } else {
    start = getDateRange(customRange[0]).start
    end = getDateRange(customRange[1]).end
  }
  return { start, end }
}