import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.darkFont14,
    },
    actionLink: {
      ...CommonFonts.darkFont14,
      fontWeight: '600',
      color: '#1174F2',
      cursor: 'pointer',
    },
    boxPaymentTypes: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing(5)
    },
  })
);

export default useStyles;
