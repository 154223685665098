import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  headerText: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    top: 20,
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
  dialogContent: {
    height: '70vh',
  },
  cancelButton: {
    ...CommonFonts.grayFont16,
    letterSpacing: '0.11px',
    color: Colors.GRAY_BORDER3,
    opacity: 1,
  },
  rootDialog: {
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 5)
    },
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      borderBottomWidth: 2,
      borderBottomColor: '#E2E2E2',
      borderBottomStyle: 'solid',
    },
  },
  tab: {
    '& .MuiTab-wrapper': {
      textTransform: 'none',
    },
  },
  titleText: {
    ...CommonFonts.darkFont14,
  },
  errorTitleText: {
    color: Colors.RED
  },
  characterLeftText: {
    ...CommonFonts.darkFont12,
    textAlign: 'end',
  },
  descriptionField: {
    '& .MuiInputBase-root': {
      minHeight: 70,
      display: 'block',
      border: `1px solid ${Colors.GRAY_BORDER3}`,
      borderRadius: 4,
      padding: theme.spacing(3)
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
  },
  boldLabel: {
    ...CommonFonts.darkFont14,
    fontWeight: 500
  },
  boldLabelContainer: {
    marginBottom: theme.spacing(5)
  },
  rulesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  ruleWrapper: {
    marginRight: theme.spacing(4)
  },
  freeOptionsContainer: {
    padding: theme.spacing(10, 0),
    borderBottom: `1.5px solid #939da833`,
  },
  findProductText: {
    ...CommonFonts.darkFont14,
    fontWeight: 500,
    textAlign: 'center'
  },
  productSearchWrapper: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing(7),
  },
  removeButton: {
    ...CommonFonts.blueFont14,
    textTransform: 'none'
  },
  barcodeText: {
    ...CommonFonts.darkFont12,
  },
  addonsContainer: {
    marginTop: theme.spacing(10)
  },
  checkBoxLabel: {
    '& .MuiFormControlLabel-label': {
      ...CommonFonts.darkFont14,
    },
  },
  addonWrapper: {
    padding: theme.spacing(3, 0),
    borderBottom: `1px solid #939da833`,
  }
}));

export default useStyles;
