import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 90,
      backgroundColor: Colors.WHITE,
      position: 'relative',
      left: 4,
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    menuWrapper: {
      padding: theme.spacing(3),
    },
    workBoardWrapper: {
      marginLeft: theme.spacing(4),
    },
    workBoard: {
      color: Colors.DIM_GREY,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: '15px',
    },
    drawerContent: {
      width: 435,
    },
    backWrapper: {
      padding: theme.spacing(6, 6, 10, 6),
    },
    menuList: {
      padding: theme.spacing(0, 6, 8, 6),
    },
    list: {
      padding: 0,
      paddingTop: theme.spacing(2),
    },
    nameMenuList: {
      marginBottom: theme.spacing(5),
      ...CommonFonts.darkFont20,
      letterSpacing: '-0.35px',
      fontWeight: 600,
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3),
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    menuBigItem: {
      height: 80,
      padding: theme.spacing(3),
      marginBottom: theme.spacing(4),
    },
    menuSmallItem: {
      height: 60,
      padding: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
    },
    iconBigWrapper: {
      display: 'flex',
      paddingRight: theme.spacing(6),
    },
    iconSmallWrapper: {
      display: 'flex',
      paddingRight: theme.spacing(3),
    },
    nameMenuItem: {
      ...CommonFonts.darkFont24,
      fontWeight: 300,
      letterSpacing: 0.15,
      lineHeight: '20px',
    },
    nameMenuSmallItem: {
      ...CommonFonts.darkFont18,
      fontWeight: 300,
      letterSpacing: 0.56,
      lineHeight: '24px',
    },
  })
);

export default useStyles;
