import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import EnterStorename from './addStoreManuallySteps/EnterStoreName';
import EnterStoreAddress from './addStoreManuallySteps/EnterStoreAddress';
import EnterBussinessType from './addStoreManuallySteps/EnterBussinessType';
import AddFormManually from './addStoreManuallySteps/EnterFullAddressManually';

import { gql, useMutation } from '@apollo/client';
import { ADD_MERCHANT } from '@/services/storeService';
import PreLoader from '@/components/preLoader';
import { useHistory } from 'react-router';
import NavigationHeader from './NavigationHeader';
import { generateName } from './helper';
import AuthActions from '@/store/modules/auth/actions';
import MerchantActions from '@/store/modules/store/actions';
import useStyles from './styles';

function getSteps() {
  return ['', 'enter_store_name', 'enter_store_address', 'enter_store_type'];
}

export default function CustomizedSteppers({ setStep }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = React.useState(1);
  const [storeNameValue, setstoreNameValue] = useState(generateName());
  const [storeAddressValue, setStoreAddressValue] = useState('');
  const [addressObject, setAddressObject] = useState({});
  const [businessTypeId, setBusinessTypeId] = useState();
  const steps = getSteps();

  const [AddMerchantType, { loading }] = useMutation(
    gql`
      ${ADD_MERCHANT}
    `,
    {
      onCompleted: (response) => {
        //add new store in auth reducers
        dispatch(AuthActions.addNewMerchant(response.addMerchant));

        //set the new store as selected current merchant
        dispatch(MerchantActions.setCurrentMerchant(response.addMerchant));
        dispatch(
          MerchantActions.setSelectedStore(response.addMerchant.stores[0])
        );

        history.push('/workboard');
      },
      onError: (error) => {
        console.log('error', error);
        enqueueSnackbar(`Store could not be created!`);
      },
    }
  );

  const addMerchant = (value) => {
    const payload = {
      variables: {
        merchant: {
          tname: storeNameValue,
          lname: storeNameValue,
          business_type_id: businessTypeId,
          address: { ...addressObject },
          stores: [
            {
              name: storeNameValue,
              address: { ...addressObject },
            },
          ],
        },
      },
    };
    return AddMerchantType(payload);
  };

  return (
    <div className={classes.root}>
      <NavigationHeader
        onArrowClick={() =>
          //if activeStep==4, go back to the second step
          activeStep === 1
            ? setStep(0)
            : activeStep === 4
            ? setActiveStep((prevActiveStep) => prevActiveStep - 2)
            : setActiveStep((prevActiveStep) => prevActiveStep - 1)
        }
      />
      {activeStep !== 3 && (
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label} />
          ))}
        </Stepper>
      )}

      <div>
        <div>
          {activeStep === 1 && (
            <EnterStorename
              storeNameValue={storeNameValue}
              setstoreNameValue={(value) => setstoreNameValue(value)}
              setActiveStep={(value) => setActiveStep(value)}
            />
          )}

          {activeStep === 2 && (
            <EnterStoreAddress
              storeAddressValue={storeAddressValue}
              setStoreAddressValue={(value) => setStoreAddressValue(value)}
              setAddressObject={(value) => setAddressObject(value)}
              setActiveStep={(value) => setActiveStep(value)}
            />
          )}
          {activeStep === 3 && (
            <AddFormManually
              storeNameValue={storeNameValue}
              storeAddressValue={storeAddressValue}
              setStoreAddressValue={(value) => setStoreAddressValue(value)}
              setActiveStep={(value) => setActiveStep(value)}
              addMerchant={addMerchant}
              setBusinessTypeId={(id) => setBusinessTypeId(id)}
              setAddressObject={(values) => setAddressObject(values)}
              previousStepValues={addressObject}
              manualSteps
            />
          )}

          {activeStep === 4 && (
            <EnterBussinessType
              storeNameValue={storeNameValue}
              storeAddressValue={storeAddressValue}
              setStoreAddressValue={(value) => setStoreAddressValue(value)}
              setActiveStep={(value) => setActiveStep(value)}
              addMerchant={addMerchant}
              setBusinessTypeId={(id) => setBusinessTypeId(id)}
              businessTypeId={businessTypeId}
            />
          )}

          <div></div>
        </div>
      </div>
      {loading && <PreLoader size={25} />}
    </div>
  );
}

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#ccffcc',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#55CC66',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);
