import React from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Box, Grid } from '@material-ui/core'
import TextField from '@/components/textField'
import Button from '@/components/button'
import { FormHelperText } from '@material-ui/core'

import useStyles from '../styles'

const ChangePassword = ({ onChangePassword, error }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('signup.password_required'))
      .test('uppercase-validation', t('signup.uppercase_required'), function (value) {
        return /[A-Z]/.test(value)
      })
      .test('lowercase-validation', t('signup.lowercase_required'), function (value) {
        return /[a-z]/.test(value)
      })
      .test('number-validation', t('signup.number_required'), function (value) {
        return /\d/.test(value)
      })
      .test('length-validation', t('signup.min_chars'), function (value) {
        return value?.length >= 8
      }),
    confirm_password: Yup.string()
      .test('passwords-match', t('signup.password_mismatch'), function (value) {
        return this.parent.password === value
      })
  })


  return (
    <Formik
      initialValues={{ password: '', confirm_password: '' }}
      validationSchema={validationSchema}
      onSubmit={onChangePassword}
    >
      {({
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          <Box>
            <Box className={classes.passwordContainer}>
              <TextField
                isPassword={true}
                label={t('signup.password')}
                name='password'
                value={values.password}
                onChange={e => setFieldValue('password', e.target.value)}
                onBlur={handleBlur}
                error={!!(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                className={classes.passwordField}
              />
            </Box>
            <Box className={classes.passwordContainer}>
              <TextField
                isPassword={true}
                label={t('signup.confirm_password')}
                name='confirm_password'
                value={values.confirm_password}
                onChange={e => setFieldValue('confirm_password', e.target.value)}
                onBlur={handleBlur}
                error={!!(touched.confirm_password && errors.confirm_password)}
                helperText={touched.confirm_password && errors.confirm_password}
                className={classes.passwordField}
              />
            </Box>

            <Grid container justify="center" className={classes.resetButtonWrapper}>
              <Button
                className={classes.button}
                variant='contained'
                type="submit"
                color="primary"
                onClick={() => { }}
              >
                {t('button_group.change_password')}
              </Button>
            </Grid>

            {error &&
              <Box className={classes.errorContainer}>
                <FormHelperText error={true}>
                  {t('reset_password_page.expired_code')}
                </FormHelperText>
              </Box>
            }
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default ChangePassword
