import React from 'react'
import PropTypes from 'prop-types'

import EmailLayout from './emailLayout'
import PreLoader from '../../components/preLoader'

const SignupSuccessLayout = (props) => {
  const {
    emailFormData,
    onEmailLogin,
    onEmailSignup,
    onChangeCredential,
    isSignupProgress,
  } = props

  return (
    <div>
      <EmailLayout
        onLogin={onEmailLogin}
        emailFormData={emailFormData}
        onResend={onEmailSignup}
        onChangeEmail={onChangeCredential}
      />
      {isSignupProgress && <PreLoader size={60} />}
    </div>
  )
}

SignupSuccessLayout.propTypes = {
  isUsingEmail: PropTypes.bool,
  emailFormData: PropTypes.object,
  phoneNumber: PropTypes.string,
  onLogin: PropTypes.func,
  onEmailSignup: PropTypes.func,
  onChangeCredential: PropTypes.func,
  isSignupProgress: PropTypes.bool,
}

export default SignupSuccessLayout