import {
  Box,
  Breadcrumbs,
  Link,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Paper,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Link as RouterLink, useHistory, useRouteMatch } from 'react-router-dom';

import AppLayout from '@/components/appLayout';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  GET_MODULES,
  UPDATE_MODULE,
} from '@/services/modulesService';
import { useSnackbar } from 'notistack';
import FloorAndShelf from './floorAndShelf';
import Payment from './payment'

const moduleQueries = gql`
  ${GET_MODULES}
`;

const ModulesPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({
    floorAndShelf: false,
    payment: false
  });
  const history = useHistory();
  const { path } = useRouteMatch();

  const { data, loading, refetch } = useQuery(moduleQueries, {
    fetchPolicy: 'no-cache',
  });
  const modules = data?.modules;

  const [updateModule] = useMutation(
    gql`
      ${UPDATE_MODULE}
    `
  );

  const onChangeStatusModule = (module) => {
    setIsLoading(true);
    const payload = module.status
      ? {
        id: module.id,
        flag: 'delete',
      }
      : {
        id: module.id,
      };
    updateModule({
      variables: {
        input: payload,
      },
    })
      .then(async (res) => {
        await refetch();
        enqueueSnackbar(
          module.status
            ? t('modules.module_deactivated')
            : t('modules.module_activated')
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  const onConfigure = (type) => {
    switch (type) {
      case 'floor_management':
        setModal({ ...modal, floorAndShelf: true });
        break;
      case 'payment':
        setModal({ ...modal, payment: true });
        break;
      case 'customer_behaviour_management': history.push( path + '/customer-behaviour-management');
        break;
      case 'delivery': history.push( path + '/delivery');
        break;
      default:
        break;
    }
  };

  return (
    <AppLayout className={classes.appLayout} withFooter header>
      <Box marginY={10}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className={classes.breadcrumbsText}
            color="primary"
            component={RouterLink}
            to="/business-manager/store-profile"
          >
            {t('userManagement.users.business_manager')}
          </Link>
          <Link
            className={classes.breadcrumbsText}
            color="textPrimary"
            component={RouterLink}
            to="/modules"
          >
            {t('modules.title')}
          </Link>
        </Breadcrumbs>
      </Box>
      <div className={classes.boxModules}>
        <Typography className={classes.title}>
          {t('modules.title')}
        </Typography>
        <Paper elevation={0} style={{ overflowX: 'auto' }}>
          <Table className={classes.boxTable}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  className={classes.headerTableText}
                  style={{ width: '60%' }}
                >
                  {t('modules.module')}
                </StyledTableCell>
                <StyledTableCell
                  className={classes.headerTableText}
                  style={{ width: '20%' }}
                >
                  {t('modules.status')}
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
              </TableRow>
            </TableHead>

            {loading || isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{ borderBottom: 'none' }}
                    colSpan={6}
                  >
                    <Box
                      marginTop={10}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress size={35} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {modules?.map((row) => (
                  <TableRow hover key={row.id}>
                    <StyledTableCell>
                      <div className={classes.boxFullName}>
                        <Typography className={classes.tableText}>
                          {row.name}
                        </Typography>
                        <Typography
                          className={classes.headerTableText}
                        >
                          {row.description}
                        </Typography>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.boxFullName}>
                        <Typography className={classes.tableText}>
                          {row.status
                            ? t('modules.active')
                            : t('modules.inactive')}
                        </Typography>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        onClick={() => onChangeStatusModule(row)}
                        className={classes.actionLink}
                      >
                        {row.status
                          ? t('modules.deactive')
                          : t('modules.active')}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        onClick={() => onConfigure(row.type)}
                        className={classes.actionLink}
                      >
                        {t('modules.configure')}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {!loading && _.isEmpty(modules) && (
            <Box
              marginTop={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography className={classes.noDataFound}>
                {t('modules.no_module_found')}
              </Typography>
            </Box>
          )}
        </Paper>
      </div>
      <FloorAndShelf
        isVisible={modal.floorAndShelf}
        onCloseModal={() =>
          setModal({ ...modal, floorAndShelf: false })
        }
      />
      <Payment
        isVisible={modal.payment}
        onCloseModal={() =>
          setModal({ ...modal, payment: false })
        }
      />
    </AppLayout>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F3F5F7',
    borderBottom: 'none',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default ModulesPage;
