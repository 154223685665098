import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
  Typography,
  Link,
  FormHelperText,
} from '@material-ui/core';
import Button from '@/components/button';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import images from '@/assets/images';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@/components/textField';
import AuthActions from '@/store/modules/auth/actions';
import PreLoader from '@/components/signupModal/components/preLoader';
import { gql, useQuery } from '@apollo/client';
import { CHECK_INVITATION } from '@/services/invitationService';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TERMS_URL } from '@/constants';

const checkInvitationQuery = gql`
  ${CHECK_INVITATION}
`;

const AccessMerchantAccept = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { checkUser, createUser, login } = AuthActions;
  const { inviteId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState('');

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('signup.password_required'))
      .test(
        'uppercase-validation',
        t('signup.uppercase_required'),
        function (value) {
          return /[A-Z]/.test(value);
        }
      )
      .test(
        'lowercase-validation',
        t('signup.lowercase_required'),
        function (value) {
          return /[a-z]/.test(value);
        }
      )
      .test(
        'number-validation',
        t('signup.number_required'),
        function (value) {
          return /\d/.test(value);
        }
      )
      .test(
        'length-validation',
        t('signup.min_chars'),
        function (value) {
          return value?.length >= 8;
        }
      ),
    confirmPassword: Yup.string().test(
      'passwords-match',
      t('signup.password_mismatch'),
      function (value) {
        return this.parent.password === value;
      }
    ),
    firstName: Yup.string().required(
      t('invitation.accessMerchant.accept.first_name_required')
    ),
    surName: Yup.string().required(
      t('invitation.accessMerchant.accept.sur_name_required')
    ),
  });

  const { loading: isCheckingInvitation, data } = useQuery(
    checkInvitationQuery,
    {
      variables: {
        id: inviteId,
      },
      onError: (e) => {
        history.push('/invitation/access-merchant/expired');
      },
    }
  );
  const invitationData = data?.checkInvitation;

  useEffect(() => {
    // Check user is exist
    if (invitationData) {
      setIsLoading(true);
      dispatch(
        checkUser({
          email: invitationData?.email,
          onSuccess: (res) => {
            if (res?.user) {
              //Accept invitation with exist user automatically
              dispatch(
                createUser({
                  email: invitationData?.email,
                  invite_id: inviteId,
                  redirect_url: '',
                  onSuccess: () => {
                    history.push('');
                    setIsLoading(false);
                  },
                  onFailed: (error) => {
                    const message =
                      error ||
                      error?.message ||
                      error?.errors[0]?.message;
                    setIsErrorMessage(message);
                    setIsLoading(false);
                  },
                })
              );
            }
            setIsLoading(false);
          },
          onFailed: (error) => {
            setIsLoading(false);
          },
        })
      );
    }
  }, [invitationData]);

  const onAccess = (
    email,
    password,
    first_name,
    last_name,
    invite_id
  ) => {
    setIsLoading(true);
    dispatch(
      createUser({
        email,
        password,
        first_name,
        last_name,
        invite_id,
        redirect_url: '',
        onSuccess: () => onLogin(email, password),
        onFailed: (error) => {
          const message =
            error || error?.message || error?.errors[0]?.message;
          setIsErrorMessage(message);
          setIsLoading(false);
        },
      })
    );
  };

  const onLogin = (email, password) => {
    dispatch(
      login({
        username: email,
        password,
        redirect_url: '',
        onSuccess: () => {
          setIsLoading(false);
          history.push('');
        },
        onFailed: (error) => {
          const message =
            error || error?.message || error?.errors[0]?.message;
          setIsErrorMessage(message);
          setIsLoading(false);
        },
      })
    );
  };

  const {
    handleSubmit,
    handleBlur,
    errors,
    setFieldValue,
    touched,
    values,
    isValid,
  } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      firstName: '',
      surName: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) =>
      onAccess(
        invitationData?.email,
        values.password,
        values.firstName,
        values.surName,
        inviteId
      ),
  });
  return (
    <>
      {!isCheckingInvitation ? (
        <>
          <Box
            className={classes.boxOutter}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <form
              onSubmit={handleSubmit}
              className={classes.boxInner}
            >
              <Grid
                container
                justify="center"
                className={classes.logo}
              >
                <img
                  src={images.logo}
                  width={125}
                  height={50}
                  alt="logo"
                />
              </Grid>
              <Grid container justify="center">
                <Typography className={classes.accessMerchant}>
                  {t(
                    'invitation.accessMerchant.accept.access_to_merchant',
                    {
                      name: invitationData?.merchant_name || '',
                    }
                  )}
                </Typography>
              </Grid>
              <Grid container justify="center">
                <Typography className={classes.email}>
                  {invitationData?.email}
                </Typography>
              </Grid>
              <Grid container justify="center">
                <Typography className={classes.subTitle}>
                  {t('invitation.accessMerchant.accept.sub_title')}
                </Typography>
              </Grid>

              <Grid container justify="center">
                <FormHelperText error={isErrorMessage !== ''}>
                  {isErrorMessage}
                </FormHelperText>
              </Grid>

              <Box marginTop={10} marginBottom={8} paddingX={5}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t(
                        'invitation.accessMerchant.accept.first_name'
                      )}
                      name="firstName"
                      value={values.firstName}
                      onChange={(e) =>
                        setFieldValue('firstName', e.target.value)
                      }
                      onBlur={handleBlur}
                      error={
                        !!(touched.firstName && errors.firstName)
                      }
                      helperText={
                        touched.firstName && errors.firstName
                      }
                      className={classes.passwordField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t(
                        'invitation.accessMerchant.accept.sur_name'
                      )}
                      name="surName"
                      value={values.surName}
                      onChange={(e) =>
                        setFieldValue('surName', e.target.value)
                      }
                      onBlur={handleBlur}
                      error={!!(touched.surName && errors.surName)}
                      helperText={touched.surName && errors.surName}
                      className={classes.passwordField}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      isPassword
                      label={t(
                        'invitation.accessMerchant.accept.password'
                      )}
                      name="password"
                      value={values.password}
                      onChange={(e) =>
                        setFieldValue('password', e.target.value)
                      }
                      onBlur={handleBlur}
                      error={!!(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      className={classes.passwordField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      isPassword
                      label={t(
                        'invitation.accessMerchant.accept.confirm_password'
                      )}
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={(e) =>
                        setFieldValue(
                          'confirmPassword',
                          e.target.value
                        )
                      }
                      onBlur={handleBlur}
                      error={
                        !!(
                          touched.confirmPassword &&
                          (errors.confirmPassword ||
                            values.password !==
                              values.confirmPassword)
                        )
                      }
                      helperText={
                        touched.confirmPassword &&
                        errors.confirmPassword
                      }
                      className={classes.passwordField}
                    />
                  </Grid>
                </Grid>
              </Box>

              <div className={classes.bottomContainer}>
                <span className={classes.bottomText}>
                  {t(
                    'invitation.accessMerchant.accept.by_clicking_signup'
                  )}
                </span>
                <Link
                  href={TERMS_URL}
                  underline="none"
                  target="_blank"
                >
                  <span className={classes.bottomBlueText}>
                    {t(
                      'invitation.accessMerchant.accept.terms_policy'
                    )}{' '}
                  </span>
                </Link>
                <span className={classes.bottomText}>
                  {t(
                    'invitation.accessMerchant.accept.you_receive_sms_opt'
                  )}
                </span>
              </div>

              <Grid container justify="center">
                <Button
                  disabled={!isValid}
                  variant="contained"
                  color="primary"
                  className={classes.accessButton}
                  onClick={handleSubmit}
                >
                  {t('invitation.accessMerchant.accept.access')}
                </Button>
              </Grid>
            </form>
            <Box className={classes.boxFooter}>
              <Typography className={classes.textFooter}>
                {t('invitation.accessMerchant.textFooter', {
                  year: new Date().getFullYear(),
                })}
              </Typography>
            </Box>
          </Box>
          {isLoading && <PreLoader size={30} />}
        </>
      ) : (
        <PreLoader size={30} />
      )}
    </>
  );
};

export default AccessMerchantAccept;
