import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  addViewImageText: {
    ...CommonFonts.blueBoldFont14,
    cursor: 'pointer'
  },
}));

export default useStyles;
