import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { debounce, isEmpty } from 'lodash'
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import classNames from 'classnames';

import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Grid,
  Typography,
  Box,
  TextField,
  InputAdornment
} from '@material-ui/core';

import { useLazyQuery, gql } from '@apollo/client'
import { GET_INVENTORY_BY_BARCODE_ID } from '@/services/inventoryService'

import images from '@/assets/images';
import { Colors } from '@/theme';
import useStyles from './styles';


const InventorySearchInput = ({
  defaultProductName,
  onSelectInventory,
  onAddNewProduct,
  ...restProps
}) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const [inventories, setInventories] = useState([])
  const [isInputBarcode, setIsInputBarcode] = useState(false)

  const [step, setStep] = useState(0)
  const [valueSelected, setValueSelected] = useState(null)

  const [getInventoriesByBarcodeId, { loading: isLoadingInventories }] = useLazyQuery(gql`${GET_INVENTORY_BY_BARCODE_ID}`, {
    onCompleted: (data) => {
      setInventories([...data.inventories, { isManualAdd: true }])
    },
    onError: () => {

    },
    fetchPolicy: 'cache-and-network'
  })

  const isBarcode = (input) => input.match(/^[0-9]+$/) != null

  useEffect(() => {
    if (defaultProductName) {
      setStep(0)
      setValueSelected(defaultProductName)
    }
  }, [defaultProductName])

  const searchInventories = (input) => {
    setInventories([])
    if (input) {
      getInventoriesByBarcodeId({
        variables: {
          filter: {
            count: true,
            page: 1,
            limit: 10,
            name: isBarcode(input) ? `%%` : `%${input.toLowerCase()}%`,
            bar_code: isBarcode(input) ? `%${input.toLowerCase()}%` : `%%`,
          }
        },
      })
      setIsInputBarcode(isBarcode(input))
    }
  }

  return (
    step === 0
      ?
      <Box onClick={() => setStep(1)}>
        <Typography
          className={
            classNames(classes.label, {
              [classes.emptyLabel]: !valueSelected
            })
          }
          onClick={() => setStep(1)}
        >
          {!valueSelected ? t('categories.select') : valueSelected}
        </Typography>
      </Box>
      :
      <Autocomplete
        id="product-search-input"
        freeSolo
        options={inventories}
        onBlur={() => setStep(0)}
        openOnFocus={defaultProductName ? false : true}
        renderInput={(params) => (
          <Box style={{ position: 'relative' }}>
            <TextField
              {...params}
              {...restProps}
              className={classes.searchInput}
              autoFocus
            />
          </Box>
        )}
        getOptionLabel={(option) => option?.name || ''}
        renderOption={(option, { inputValue }) => {
          if (option?.isManualAdd) {
            return (
              <Grid container justify="center">
                <span
                  className={classes.noOptionsButton}
                  onMouseDown={onAddNewProduct}
                >
                  {t('expenses.add_new_product')}
                </span>
              </Grid>
            )
          } else {
            const nameMatches = match(option.name, inputValue);
            const nameParts = parse(option.name, nameMatches);

            const barcodeMatches = match(option.bar_code, inputValue);
            const barcodeParts = parse(option.bar_code, barcodeMatches);

            return (
              <Box paddingX={2} paddingY={3}>
                <Box>
                  {nameParts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight && !isInputBarcode ? 600 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Box>
                <Box>
                  {barcodeParts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight && isInputBarcode ? 600 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Box>
              </Box>
            )
          }
        }}
        onInputChange={debounce((e, newInputValue) => searchInventories(newInputValue), 1000)}
        filterOptions={(x) => x}
        loading={isLoadingInventories}
        loadingText={t('workboard.product_intelligence.loading_products')}
        onChange={(event, product) => {
          if (product?.id) {
            onSelectInventory(product)
            setValueSelected(product.name)
          } else {
            return
          }
        }}
      />
  )
}

export default InventorySearchInput