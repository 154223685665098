import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      ...CommonFonts.darkFont18,
    },
    boxFilter: {
      backgroundColor: Colors.WHITE,
      padding: theme.spacing(5),
      borderRadius: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
    },
    boxUserManagement: {
      backgroundColor: Colors.WHITE,
      padding: theme.spacing(5),
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(7),
    },
    boxTable: {
      marginTop: theme.spacing(7),
    },
    title: {
      ...CommonFonts.darkFont16,
      fontWeight: '600',
      marginTop: theme.spacing(2),
    },
    tableText: {
      ...CommonFonts.darkFont18,
    },
    moreIcon: {
      padding: theme.spacing(1),
    },
    boxFullName: {
      wordBreak: 'break-word',
      padding: theme.spacing(2.5, 0),
    },
    menuOption: {
      '& .MuiMenu-paper': {
        boxShadow:
          '0px 0px 0px 0px rgb(166 166 166 / 10%), 0 0px 0px 0px rgb(136 136 136 / 14%), 0 1px 4px 2px rgb(160 160 160 / 12%)',
        width: theme.spacing(50),
      },
    },
    menuItemText: {
      ...CommonFonts.darkFont18,
    },
    menuItem: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    noTouchpointsFound: {
      ...CommonFonts.darkFont16,
    },
    headerTableText: {
      ...CommonFonts.darkFont14,
    },
  })
);

export default useStyles;
