import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../constants/colors'
import { CommonFonts } from '../../constants/fonts'

const useStyles = makeStyles(theme =>
  createStyles({
    signupText: {
      ...CommonFonts.darkFont24,
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 80
    },
    signupMethodWrapper: {
      margin: '50px 0px'
    },
    greyButton: {
      height: 60,
      backgroundColor: Colors.GRAY_LIGHT,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
      textTransform: 'none',
      ...CommonFonts.darkFont18,
      fontWeight: 'normal',
      borderRadius: 0
    },
    greyFocusedButton: {
      backgroundColor: Colors.GRAY_BORDER1,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1,
      },
    },
    mobileButtonWrapper: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 20
      }
    }
  })
)

export default useStyles
