import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';
import images from '@/assets/images';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash'

const MenuExpand = ({
  id,
  name,
  label,
  icon,
  width,
  height,
  iconBig,
  subMenu,
  className,
  autoExpand,
  initAutoExpands
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (autoExpand) {
      setIsExpanded(true)
      initAutoExpands()
    }
  }, [autoExpand])

  return (
    <Accordion
      expanded={isExpanded}
      className={classes.root}
      onChange={(event, expanded) => setIsExpanded(expanded)}
    >
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        classes={{
          content: classes.summaryContent,
        }}
      >
        <div className={classes.boxAccordionTitle}>
          <div
            className={
              iconBig
                ? classes.iconBigWrapper
                : classes.iconSmallWrapper
            }
          >
            <img
              src={images[icon]}
              width={width}
              height={height}
              alt={label}
            />
          </div>
          <Typography
            className={classNames(classes.nameMenuItem, className, {
              [classes.nameMenuSmallItem]: !iconBig,
            })}
          >
            {t(`menu.${name}`)}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <MenuList className={classes.list}>
          {subMenu?.map((menu) => (
            isEmpty(menu.subMenu) ?
              <MenuItem
                disabled={!menu.isActive}
                onClick={() => history.push(menu.path)}
                key={`${menu.id}-${menu.name}`}
                className={classNames(classes.menuItem, menu.className, {
                  [classes.menuBigItem]: !!menu?.iconBig,
                  [classes.menuSmallItem]: !menu?.iconBig,
                })}
              >
                <Typography
                  className={classNames(classes.nameMenuItem, {
                    [classes.nameMenuSmallItem]: !menu?.iconBig,
                  })}
                >
                  {t(`menu.${menu.name}`)}
                </Typography>
              </MenuItem>
              :
              <Accordion className={classes.root}>
                <AccordionSummary
                  className={classes.summary}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${id}-content`}
                  id={`${id}-${menu.id}-header`}
                  classes={{
                    content: classes.summaryContent,
                  }}
                >
                  <div className={classes.boxAccordionTitle}>
                    <Typography
                      className={classNames(classes.nameMenuItem, menu.className, {
                        [classes.nameMenuSmallItem]: true,
                      })}
                    >
                      {t(`menu.${menu.name}`)}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <MenuList className={classes.list}>
                    {menu.subMenu.map((subMenu) => (
                      <MenuItem
                        disabled={!subMenu.isActive}
                        onClick={() => history.push(subMenu.path)}
                        key={`${menu.id}-${subMenu.id}-${subMenu.name}`}
                        className={classNames(classes.menuItem, subMenu.className, {
                          [classes.menuSmallItem]: true,
                        })}
                      >
                        <Typography
                          className={classNames(classes.nameMenuItem, {
                            [classes.nameMenuSmallItem]: true,
                          })}
                        >
                          {t(`menu.${subMenu.name}`)}
                        </Typography>
                      </MenuItem>
                    ))}
                  </MenuList>
                </AccordionDetails>
              </Accordion>
          ))}
        </MenuList>
      </AccordionDetails>
    </Accordion>
  );
};

export default MenuExpand;
