import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  titleText: {
    ...CommonFonts.darkFont24,
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(4)
  },
  rootDialog: {
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 5)
    },
  },
  darkFont14: {
    ...CommonFonts.darkFont14,
  },
  darkFont16: {
    ...CommonFonts.darkFont16,
  },
  darkFont18: {
    ...CommonFonts.darkFont18,
  },
  blueFont16: {
    ...CommonFonts.blueFont16,
  },
  enterFullAddressButton: {
    fontSize: '9px',
    marginTop: '30px',
    padding: '10px 40px',
    background: '#1174F2 0% 0% no-repeat padding-box',
    color: Colors.WHITE,
    textTransform: 'Uppercase',
    width: 208
  },
  addStoreButton: {
    padding: theme.spacing(2.5, 0),
    background: '#1174F2 0% 0% no-repeat padding-box',
    textTransform: 'Uppercase',
    ...CommonFonts.whiteFont14,
    width: 150
  },
}));

export default useStyles;
