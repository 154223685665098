import { calculateTaxesSumma } from './taxUtil'

export const calcPriceFromGrossPrice = (grossPrice, taxes) => {
  var total_rate = 0
  taxes.forEach(tax => total_rate += (tax?.rate || 0) <= 0 ? 0 : tax.rate)
  return grossPrice * (100 / (100 + total_rate))
}

export const calcGrossPriceFromPrice = (price, taxes) => {
  return price + calculateTaxesSumma(price, taxes)
}

export function calculatePrice(priceWithoutTaxes, taxes, { includeTax }) {
  return includeTax
    ? calcGrossPriceFromPrice(priceWithoutTaxes, taxes)
    : priceWithoutTaxes
}
