import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import OpeningHours from './openingHours';
import { Box, Grid, Typography } from '@material-ui/core';
import DeliverySlot from './deliverySlot';
import ProductsOnTouchpoint from './productsOnTouchpoints';

import useStyles from './styles';

const ManageStore = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openProducts, setOpenProducts] = useState(false);
  const [openingHours, setOpeningHours] = useState(false);
  const [openDeliverySlotDialog, setOpenDeliverySlotDialog] = useState(false);

  const MANAGE_STORE = [
    {
      label: t('workboard.manage_store.opening_hours'),
      onClick: () => setOpeningHours(true),
      disabled: false
    },
    {
      label: t('workboard.manage_store.delivery_slot'),
      onClick: () => setOpenDeliverySlotDialog(true),
      disabled: false
    },
    {
      label: t('workboard.manage_store.products_on_touchpoints'),
      onClick: () => setOpenProducts(true),
      disabled: false
    }
  ];

  return (
    <Box className={classes.root}>
      <Typography className={classes.titleText}>
        {t('workboard.manage_store.manage_store')}
      </Typography>
      <Box
        display='flex'
        className={classes.accessesContainer}
      >
        {MANAGE_STORE.map((item, id) => (
          <Box
            key={id}
            className={classNames(
              classes.accessContainer,
              {
                [classes.disabled]: item.disabled
              }
            )}
            onClick={() => {
              if (!item.disabled) {
                item.onClick();
              }
            }}
          >
            <Grid
              container
              alignItems='baseline'
            >
              <Typography className={classes.labelText}>
                {item.label}
              </Typography>
            </Grid>
          </Box>
        ))}
      </Box>

      {openProducts && <ProductsOnTouchpoint
        open={openProducts}
        handleClose={() => setOpenProducts(false)} />}
      {openingHours &&
      <OpeningHours open={openingHours}
                    handleClose={() => setOpeningHours(false)} />}
      {openDeliverySlotDialog && (
        <DeliverySlot open={openDeliverySlotDialog}
                      handleClose={() => setOpenDeliverySlotDialog(false)}
        />
      )}
    </Box>
  );
};

export default ManageStore;