import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';

import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  withStyles
} from '@material-ui/core'
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons'
import ArrowIcon from './icon'

import { CommonFonts } from '@/theme'
import { BEST_SELLER_SORT_TYPE, SORT_DIRECTION } from '../../helper'

const TableHeader = (props) => {
  const { t } = useTranslation()

  const {
    sortType,
    sortDirection,
    onPressSort
  } = props

  const dropIcons = {
    [SORT_DIRECTION.ASCENDING]: ArrowDropUp,
    [SORT_DIRECTION.DESCENDING]: ArrowDropDown
  }

  const headCells = [
    {
      id: 'product',
      label: t('workboard.product_sales_analysis.product'),
      width: '25%',
      sortType: null,
    },
    {
      id: 'barcode',
      label: t('workboard.product_sales_analysis.barcode'),
      width: '15%',
      sortType: null
    },
    {
      id: 'no_items_sold',
      label: t('workboard.product_sales_analysis.no_items_sold'),
      width: '15%',
      sortType: BEST_SELLER_SORT_TYPE.ITEM
    },
    {
      id: 'net_sales',
      label: t('workboard.product_sales_analysis.net_sales'),
      width: '15%',
      sortType: BEST_SELLER_SORT_TYPE.NET
    },
    {
      id: 'taxes',
      label: t('workboard.product_sales_analysis.taxes'),
      width: '15%',
      sortType: BEST_SELLER_SORT_TYPE.TAX
    },
    {
      id: 'gross_sales',
      label: t('workboard.product_sales_analysis.gross_sales'),
      width: '15%',
      sortType: BEST_SELLER_SORT_TYPE.GROSS
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            style={{
              width: headCell.width,
              cursor: headCell.sortType ? 'pointer' : 'default',
              borderBottom: 'none'
            }}
            onClick={() => headCell.sortType
              ? onPressSort(headCell.sortType)
              : {}
            }
          >
            <Box display='flex'>
              {headCell.label}
              {headCell.sortType &&
                <ArrowIcon
                  icon={dropIcons[sortDirection[headCell.sortType]]}
                  isSelected={headCell.sortType === sortType}
                />
              }
            </Box>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
    ...CommonFonts.darkFont14,
    fontWeight: 'normal'
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont14
  },
}))(TableCell);

TableHeader.propTypes = {
  sortType: PropTypes.string,
  sortDirection: PropTypes.object,
  onPressSort: PropTypes.func,
}

export default TableHeader