import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%'
    },
    titleText: {
      ...CommonFonts.darkFont14,
    },
    errorTitleText: {
      color: Colors.RED
    },
    filterInput: {
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
      ...CommonFonts.blueFont18,
    },
    selectContainer: {
      width: '100%'
    },
    label: {
      ...CommonFonts.darkFont18,
      padding: theme.spacing(1, 0),
      cursor: 'pointer',
      borderBottom: '1px solid rgba(0, 0, 0, 0)',
      '&:hover': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      },
      '& .MuiIconButton-root': {
        color: '#939DA8'
      },
      '&:hover .MuiIconButton-root': {
        color: '#505C69'
      },
    },
    emptyLabel: {
      ...CommonFonts.blueFont18,
      padding: theme.spacing(1, 0),
      cursor: 'pointer',
      borderBottom: '1px solid rgba(0, 0, 0, 0)',
      '&:hover': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      }
    },
    iconButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      padding: theme.spacing(1),
    },
  })
)

export default useStyles
