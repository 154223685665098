import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Box } from '@material-ui/core'
import Selectors from './selectors'
import Slider from 'react-slick'
import SliderItem from '../../performanceOverview/sliderItem'
import PreLoader from '@/components/preLoader'

import {
  selectSelectedRegion,
  selectSelectedStore
} from '@/store/modules/store/selectors'

import { gql, useQuery, useLazyQuery } from '@apollo/client'
import {
  GET_INSIGHTS_FOR_INTELLIGENCE,
  GET_COMPARED_INSIGHTS_FOR_INTELLIGENCE,
  GET_STOCK_FOR_INTELLIGENCE,
  GET_COMPARED_STOCK_FOR_INTELLIGENCE,
} from '@/services/widgetService'

import { COMPARISON, getIntelligenceData } from './helper'
import useStyles from './styles';

const MainLayout = (props) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const {
    startTime,
    endTime,
    product,
    onRemoveProduct
  } = props

  const selectedRegion = useSelector(selectSelectedRegion)
  const selectedStore = useSelector(selectSelectedStore)

  const [comparison, setComparison] = useState(COMPARISON.STORE)
  const [comparedStore, setComparedStore] = useState(null)
  const [comparedProduct, setComparedProduct] = useState(null)

  const { data: stockData, loading: isLoadingStockData } = useQuery(gql`${GET_STOCK_FOR_INTELLIGENCE}`, {
    variables: {
      inventory_id: product.id
    },
    context: {
      headers: {
        storeId: selectedRegion ? selectedRegion.id : selectedStore.id
      },
    },
    fetchPolicy: 'network-only',
  })

  const { data: insightsData, loading: isLoadingInsightsData } = useQuery(gql`${GET_INSIGHTS_FOR_INTELLIGENCE}`, {
    variables: {
      start: moment(startTime).format('YYYY-MM-DD'),
      end: moment(endTime).format('YYYY-MM-DD'),
      product_id: product.id
    },
    context: {
      headers: {
        storeId: selectedRegion ? selectedRegion.id : selectedStore.id
      },
    },
    fetchPolicy: 'network-only'
  })

  const [getComparedInsightsData, {
    data: comparedInsightsData,
    loading: isLoadingComparedInsightsData
  }] = useLazyQuery(gql`${GET_COMPARED_INSIGHTS_FOR_INTELLIGENCE}`, {
    fetchPolicy: 'network-only',
  })

  const [getComparedStockData, {
    data: comparedStockData,
    loading: isLoadingComparedStockData
  }] = useLazyQuery(gql`${GET_COMPARED_STOCK_FOR_INTELLIGENCE}`, {
    fetchPolicy: 'network-only',
  })

  const sliderSettings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
  };

  const dataToDisplay = getIntelligenceData(
    comparison,
    insightsData,
    stockData,
    comparedInsightsData,
    comparedStockData,
    selectedStore,
    selectedRegion,
    product,
    comparedStore,
    comparedProduct
  )

  useEffect(() => {
    if (comparedStore && comparison === COMPARISON.STORE) {
      getComparedStockData({
        context: {
          headers: {
            storeId: comparedStore.id
          },
        },
        variables: {
          inventory_id: product.id
        }
      })
      getComparedInsightsData({
        context: {
          headers: {
            storeId: comparedStore.id
          },
        },
        variables: {
          start: moment(startTime).format('YYYY-MM-DD'),
          end: moment(endTime).format('YYYY-MM-DD'),
          product_id: product.id
        }
      })
    }
    if (comparedProduct && comparison === COMPARISON.PRODUCT) {
      getComparedStockData({
        context: {
          headers: {
            storeId: selectedRegion ? selectedRegion.id : selectedStore.id
          },
        },
        variables: {
          inventory_id: comparedProduct.id
        }
      })
      getComparedInsightsData({
        context: {
          headers: {
            storeId: selectedRegion ? selectedRegion.id : selectedStore.id
          },
        },
        variables: {
          start: moment(startTime).format('YYYY-MM-DD'),
          end: moment(endTime).format('YYYY-MM-DD'),
          product_id: comparedProduct.id
        }
      })
    }
  }, [comparedStore, comparedProduct, startTime, endTime])

  return (
    <Box className={classes.root}>
      <Selectors
        comparison={comparison}
        onChangeComparison={(value) => setComparison(value)}
        product={product}
        onRemoveProduct={onRemoveProduct}
        comparedProduct={comparedProduct}
        onChangeComparedProduct={(value) => setComparedProduct(value)}
        comparedStore={comparedStore}
        onChangeComparedStore={(value) => setComparedStore(value)}
      />

      <Box className={classes.sliderContainer}>
        <Slider {...sliderSettings}>
          {dataToDisplay.map((data, index) => (
            <SliderItem key={index} data={data} />
          ))}
        </Slider>
      </Box>

      {(isLoadingStockData ||
        isLoadingInsightsData ||
        isLoadingComparedInsightsData ||
        isLoadingComparedStockData) &&
        <PreLoader size={30} />
      }
    </Box>
  )
}

MainLayout.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  product: PropTypes.object,
  onRemoveProduct: PropTypes.func
}

export default MainLayout
