import React, { useEffect, useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import {
  PRODUCT_IN_STOREFRONT,
  ALL_PRODUCT_STOREFRONT,
} from '@/services/storeService';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';
import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import useDebounce from '@/hooks/useDebounce';
import RootRef from '@material-ui/core/RootRef';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import TextField from '@/components/textField';
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';

const productInStorefrontQueries = gql`
  ${PRODUCT_IN_STOREFRONT}
`;

const allProductInStorefrontQueries = gql`
  ${ALL_PRODUCT_STOREFRONT}
`;

const StorefrontSellSelectedProduct = ({
  name,
  label,
  value,
  options,
  onChange,
  onChangeOptions,
  description,
  isGroupByCategory,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lowerCaseTitle = label.toLowerCase();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [productLoaded, setProductLoaded] = useState(false);
  const [productsVisible, setProductsVisible] = useState([]);
  const [productHidden, setProductHidden] = useState([]);
  const [products, setProducts] = useState([]);

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setProducts([]);
    setProductsVisible([]);
    setProductHidden([]);
    setPage(1);
    setSearchText('');
  };

  useEffect(() => {
    if (openEditModal) {
      getProductsInStorefront();
    }
  }, [openEditModal]);

  useEffect(() => {
    if (page !== 1) {
      getAllProducts();
    }
  }, [page]);

  useEffect(() => {
    if (openEditModal) {
      setProducts([]);
      setProductHidden([]);
      setPage(1);
      getProductsInStorefront();
    }
  }, [debouncedSearchTerm]);

  const [
    getProductsInStorefront,
    { loading: loadingProductInStorefront },
  ] = useLazyQuery(productInStorefrontQueries, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        __or: [
          { name: { __eq: `%${debouncedSearchTerm}%` } },
          { bar_code: { __eq: `%${debouncedSearchTerm}%` } },
        ],
      },
    },
    onCompleted: (data) => {
      if (data?.products) {
        const result = getProductsFiltered(data?.products);
        setProducts(_.uniqBy([...products, ...result], 'id'));
        if (_.isEmpty(productsVisible)) {
          setProductsVisible(result);
        }
        getAllProducts();
      }
    },
  });

  const [getAllProducts, { loading: loadingAllProduct }] =
    useLazyQuery(allProductInStorefrontQueries, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        filter: {
          count: true,
          limit: 20,
          page: page,
          __or: [
            { name: { __eq: `%${debouncedSearchTerm}%` } },
            { bar_code: { __eq: `%${debouncedSearchTerm}%` } },
          ],
        },
      },
      onCompleted: (data) => {
        if (data.products.length < 20) {
          setProductLoaded(true);
        } else {
          setProductLoaded(false);
        }

        setProductHidden([...productHidden, data?.products]);

        setProducts(
          _.uniqBy(
            [...products, ...getProductsFiltered(data?.products)],
            'id'
          )
        );
      },
    });

  const getProductsFiltered = (value) => {
    const findInVisible = (id) =>
      productsVisible?.find((itemVisible) =>
        _.isEqual(itemVisible.id, id)
      );

    const filters = options.map((option) => {
      const existVisible = findInVisible(option.id);
      const existOption = options.find((item) =>
        _.isEqual(
          productsVisible?.find((itemVisible) =>
            _.isEqual(itemVisible.position, item.position)
          )?.id,
          option.id
        )
      );
      return {
        ...option,
        position: existVisible
          ? existVisible.position
          : existOption && findInVisible(existOption.id)
          ? existOption.position
          : existOption || _.isEmpty(productsVisible)
          ? option.position
          : null,
      };
    });
    return value?.map((prod) => {
      const isExist =
        filters?.findIndex((item) => item.id === prod.id) > -1;
      return {
        id: prod.id,
        position: isExist
          ? filters?.find((item) => item.id === prod.id)?.position
          : productsVisible?.find((item) => item.id === prod.id)
              ?.position || null,
        name: prod.name,
        bar_code: prod.bar_code,
      };
    });
  };

  // change current page when user reaches bottom
  const handleLoadMore = () => {
    if (_.isEmpty(productHidden)) return;
    setPage(page + 1);
  };

  const onShowHide = (value) => {
    const { id, position } = value;
    const list = products.map((item, index) => ({
      ...item,
      position:
        (item.id === id && position) ||
        (item.id !== id && item.position === null)
          ? null
          : item.id === id
          ? productsVisible?.length + 1
          : item.position,
      name: item.name,
      bar_code: item.bar_code,
    }));
    const result = list
      .sort(
        (first, second) =>
          (first.position === null) - (second.position === null) ||
          +(first.position > second.position) ||
          -(first.position < second.position)
      )
      .map((item, index) => ({
        ...item,
        position:
          item.position === null
            ? null
            : debouncedSearchTerm === ''
            ? index + 1
            : item.position,
      }));

    const findInVisible = productsVisible?.find((itemVisible) =>
      _.isEqual(itemVisible.id, id)
    );

    setProductsVisible(
      findInVisible
        ? productsVisible
            .filter((item) => item.id !== id)
            .map((item, index) => ({ ...item, position: index + 1 }))
        : _.uniqBy(
            [
              ...productsVisible,
              ...result.filter((item) => item.position !== null),
            ],
            'id'
          )
    );
    setProducts(result);
  };

  const onDragEnd = (result) => {
    if (result.destination) {
      const currentIndex = result.source.index;
      const targetIndex = result.destination.index;

      const list = products.map((item, index) =>
        currentIndex + 1 === targetIndex ||
        !_.isEmpty(debouncedSearchTerm)
          ? {
              ...item,
              position:
                index === currentIndex
                  ? products[targetIndex].position
                  : index === targetIndex
                  ? products[currentIndex].position
                  : item.position,
            }
          : currentIndex < targetIndex
          ? {
              ...item,
              position:
                index === currentIndex
                  ? products[targetIndex].position
                  : index <= targetIndex && index > currentIndex
                  ? item.position - 1
                  : item.position,
            }
          : {
              ...item,
              position:
                index === currentIndex
                  ? products[targetIndex].position
                  : index > currentIndex || index < targetIndex
                  ? item.position
                  : index < currentIndex && item.position !== null
                  ? item.position + 1
                  : null,
            }
      );
      const listVisible = _.uniqBy(
        [
          ...list?.filter((item) => item.position !== null),
          ...productsVisible,
        ],
        'id'
      );
      setProductsVisible(listVisible);
      setProducts(list);
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      backgroundColor: '#F3F5F7',
      display: 'flex',
    }),
  });

  const onSave = async () => {
    setIsLoading(true);
    try {
      await onChangeOptions(
        productsVisible?.map((item) => ({
          id: item.id,
          position: item.position,
        }))
      );
      handleCloseEditModal();
      enqueueSnackbar(
        `${capitalizeEachLetter(label)} ${t('settings.updated')}`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loading =
    (loadingProductInStorefront || loadingAllProduct) && page === 1;

  return (
    <div className={classes.boxSwitchPOS}>
      <div className={classes.switchHeader}>
        <Typography className={classes.titleText}>{label}</Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {value && (
            <div className={classes.boxOptions}>
              <Typography
                onClick={handleOpenEditModal}
                className={classes.addAction}
              >
                {t('settings.update')}
              </Typography>
            </div>
          )}
          <CustomSwitch
            checked={!!value}
            onChange={async (event) => {
              const value = event.target.checked;
              if (value) {
                handleOpenEditModal();
              }
              await onChange?.(value);
            }}
            name={name}
          />
        </div>
      </div>
      {description && (
        <Typography
          style={{ width: '90%' }}
          className={classes.descriptionText}
        >
          {description}
        </Typography>
      )}
      <Dialog
        className={classes.boxDialog}
        fullWidth
        maxWidth={false}
        fullScreen={fullScreen}
        onClose={isLoading ? () => {} : handleCloseEditModal}
        aria-labelledby={`{edit-${lowerCaseTitle}-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{edit-${lowerCaseTitle}-dialog-title}`}
          onClose={handleCloseEditModal}
        >
          {t('settings.touchpoint_storefront.select_products')}
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder={t(
              'settings.touchpoint_storefront.select_products_placeholder'
            )}
            id={name}
            name={name}
            fullWidth
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            autoFocus
            required
            InputProps={{
              className: classes.input,
            }}
            startAdornment={
              <SearchIcon
                style={{ color: Colors.GRAY_BORDER1 }}
                width={18}
                height={18}
              />
            }
          />
          {loading ? (
            <Box
              marginTop={10}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={30} />
            </Box>
          ) : products?.length > 0 ? (
            <InfiniteScroll
              scrollThreshold={'100px'}
              height={680}
              style={{ marginTop: 10 }}
              dataLength={productHidden?.length}
              next={handleLoadMore}
              hasMore={!productLoaded}
              loader={
                loadingAllProduct &&
                page !== 1 && (
                  <Box paddingX={2}>
                    <Skeleton height={80} />
                    <Skeleton height={80} />
                    <Skeleton height={80} />
                  </Box>
                )
              }
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <RootRef rootRef={provided.innerRef}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{
                                width: !isGroupByCategory
                                  ? '75%'
                                  : '50%',
                              }}
                            >
                              {t(
                                'settings.touchpoint_storefront.product_name'
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{ width: '25%' }}
                            >
                              {t(
                                'settings.touchpoint_storefront.show_hide'
                              )}
                            </StyledTableCell>
                            {!isGroupByCategory && (
                              <StyledTableCell
                                align="center"
                                style={{ width: '25%' }}
                              >
                                {t(
                                  'settings.touchpoint_storefront.position'
                                )}
                              </StyledTableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        {products && (
                          <TableBody>
                            {products
                              .sort(
                                (first, second) =>
                                  (first.position === null) -
                                    (second.position === null) ||
                                  +(
                                    first.position > second.position
                                  ) ||
                                  -(first.position < second.position)
                              )
                              .map((row, index) => (
                                <Draggable
                                  key={`${row.id}-${index}`}
                                  draggableId={row.id}
                                  index={index}
                                  isDragDisabled={
                                    !row.position || isGroupByCategory
                                  }
                                >
                                  {(provided, snapshot) => (
                                    <TableRow
                                      ref={provided.innerRef}
                                      key={row.id}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <StyledTableCell
                                        style={{
                                          width: !isGroupByCategory
                                            ? '75%'
                                            : '50%',
                                        }}
                                      >
                                        <Typography
                                          className={
                                            classes.tableText
                                          }
                                        >
                                          {row.name}
                                        </Typography>
                                        <Typography
                                          className={
                                            classes.descriptionText
                                          }
                                        >
                                          {row.bar_code}
                                        </Typography>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        style={{ width: '25%' }}
                                      >
                                        <Typography
                                          className={
                                            classes.tableText
                                          }
                                        >
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              onShowHide(row)
                                            }
                                          >
                                            {row.position ? (
                                              <VisibilityIcon />
                                            ) : (
                                              <VisibilityOffIcon
                                                style={{
                                                  color: '#939DA8',
                                                }}
                                              />
                                            )}
                                          </IconButton>
                                        </Typography>
                                      </StyledTableCell>
                                      {!isGroupByCategory && (
                                        <StyledTableCell
                                          align="center"
                                          style={{ width: '25%' }}
                                        >
                                          <Typography
                                            style={{
                                              fontWeight: '600',
                                            }}
                                            className={
                                              classes.tableText
                                            }
                                          >
                                            {row.position || ''}
                                          </Typography>
                                        </StyledTableCell>
                                      )}
                                    </TableRow>
                                  )}
                                </Draggable>
                              ))}
                          </TableBody>
                        )}
                        {provided.placeholder}
                      </Table>
                    </RootRef>
                  )}
                </Droppable>
              </DragDropContext>
            </InfiniteScroll>
          ) : (
            <div className={classes.boxNotFound}>
              <Typography className={classes.titleText}>
                {t('settings.touchpoint_storefront.no_product_found')}
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>
            {t('settings.cancel')}
          </Button>
          <Button onClick={onSave} type="submit" color="primary">
            {t('settings.save')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
    </div>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

const CustomSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: '#55CC66',
    },
    '&$checked + $track': {
      backgroundColor: '#55CC66',
      opacity: 0.4,
    },
  },
  checked: {},
  track: {},
})(Switch);

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default StorefrontSellSelectedProduct;
