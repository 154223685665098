import React, { useState } from 'react';

import {
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  Box,
  Select,
  MenuItem,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  GLOBAL_ORDER_TYPES,
  GET_PRICE_TYPE,
  GET_WORKFLOWS,
  MUTATE_ORDER_TYPE,
} from '@/services/orderTypesWorkflowsService';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';
import { isMobile } from 'react-device-detect';

const globalOrderTypesQueries = gql`
  ${GLOBAL_ORDER_TYPES}
`;

const priceTypeQueries = gql`
  ${GET_PRICE_TYPE}
`;

const workflowsQueries = gql`
  ${GET_WORKFLOWS}
`;

const UpdateOrderTypes = ({
  orderTypeDetail,
  isVisible,
  onCloseModal,
  onFetchOrderTypes,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState({
    orderType: undefined,
    priceType: undefined,
    workflow: undefined,
  });

  const handleCloseModal = (isReset = true) => {
    onCloseModal();
    setIsLoading(false);
    if (isReset) {
      setUpdateData({});
    }
  };

  const { loading: loadingGlobalOrderTypes, data: globalOrderTypes } =
    useQuery(globalOrderTypesQueries);
  const globalOrderTypesData = globalOrderTypes?.globalOrderTypes;

  const { loading: loadingPriceType, data: priceType } =
    useQuery(priceTypeQueries);
  const priceTypeData = priceType?.priceTypes;

  const { loading: loadingWorkflows, data: workflows } =
    useQuery(workflowsQueries);
  const workflowsData = workflows?.workflows;

  const [mutateOrderType] = useMutation(
    gql`
      ${MUTATE_ORDER_TYPE}
    `
  );

  const onUpdate = async () => {
    setIsLoading(true);
    mutateOrderType({
      variables: {
        input: {
          id: orderTypeDetail?.id,
          name: updateData.orderType?.name || orderTypeDetail?.name,
          type: updateData.orderType?.type || orderTypeDetail?.type,
          pricing_type:
            updateData.priceType?.type ||
            orderTypeDetail?.pricing_type,
          workflow_id:
            updateData.workflow?.id || orderTypeDetail?.workflow?.id,
        },
      },
    })
      .then(async () => {
        await onFetchOrderTypes?.();
        handleCloseModal(false);
        enqueueSnackbar(t('orderTypeWorkflows.order_type_updated'));
      })
      .catch((error) => {
        handleCloseModal(false);
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  return (
    <>
      <Dialog
        fullWidth
        fullScreen={isMobile}
        onClose={isLoading ? () => {} : handleCloseModal}
        aria-labelledby={`{update-order-type-dialog-title}`}
        open={isVisible}
        disableBackdropClick
      >
        <DialogTitle
          id={`{update-order-type-dialog-title}`}
          onClose={handleCloseModal}
        >
          {t('orderTypeWorkflows.update_order_type')}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box marginBottom={12}>
              <Typography className={classes.descriptionText}>
                {t('orderTypeWorkflows.order_type_name')}
              </Typography>
              {loadingGlobalOrderTypes ? (
                <Skeleton height={35} />
              ) : (
                <Select
                  IconComponent={ExpandMoreIcon}
                  displayEmpty
                  fullWidth
                  id="orderType"
                  name="orderType"
                  className={classes.selectOptionInput}
                  defaultValue=""
                  value={
                    updateData.orderType ||
                    globalOrderTypesData?.find(
                      (item) => item.name === orderTypeDetail.name
                    )
                  }
                  onChange={(event) =>
                    setUpdateData({
                      ...updateData,
                      orderType: event.target.value,
                    })
                  }
                  inputProps={{
                    'aria-label': 'Without label',
                  }}
                >
                  {(globalOrderTypesData || []).map(
                    (option, index) => {
                      return (
                        <MenuItem
                          key={`${option.id}-${index}`}
                          value={option}
                        >
                          {option.name}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              )}
            </Box>

            <Box marginBottom={12}>
              <Typography className={classes.descriptionText}>
                {t('orderTypeWorkflows.price_type')}
              </Typography>
              {loadingPriceType ? (
                <Skeleton height={35} />
              ) : (
                <Select
                  IconComponent={ExpandMoreIcon}
                  displayEmpty
                  fullWidth
                  id="priceType"
                  name="priceType"
                  className={classes.selectOptionInput}
                  defaultValue=""
                  value={
                    updateData.priceType ||
                    priceTypeData?.find(
                      (item) =>
                        item.type === orderTypeDetail.pricing_type
                    )
                  }
                  onChange={(event) =>
                    setUpdateData({
                      ...updateData,
                      priceType: event.target.value,
                    })
                  }
                  inputProps={{
                    'aria-label': 'Without label',
                  }}
                >
                  {(priceTypeData || []).map((option, index) => {
                    return (
                      <MenuItem
                        key={`${option.id}-${index}`}
                        value={option}
                      >
                        {option.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </Box>

            <Box marginBottom={12}>
              <Typography className={classes.descriptionText}>
                {t('orderTypeWorkflows.workflow')}
              </Typography>
              {loadingWorkflows ? (
                <Skeleton height={35} />
              ) : (
                <Select
                  IconComponent={ExpandMoreIcon}
                  displayEmpty
                  fullWidth
                  id="workflows"
                  name="workflows"
                  className={classes.selectOptionInput}
                  defaultValue=""
                  value={
                    updateData.workflow ||
                    workflowsData?.find(
                      (item) =>
                        item.id === orderTypeDetail?.workflow?.id
                    )
                  }
                  onChange={(event) =>
                    setUpdateData({
                      ...updateData,
                      workflow: event.target.value,
                    })
                  }
                  inputProps={{
                    'aria-label': 'Without label',
                  }}
                >
                  {(workflowsData || []).map((option, index) => {
                    return (
                      <MenuItem
                        key={`${option.id}-${index}`}
                        value={option}
                      >
                        {capitalizeEachLetter(option.name)}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>
            {t('settings.cancel')}
          </Button>
          <Button onClick={onUpdate} type="submit" color="primary">
            {t('settings.update')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default UpdateOrderTypes;
