import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%'
    },
    selectsContainer: {
      marginTop: theme.spacing(5)
    },
    titleText: {
      ...CommonFonts.grayBoldFont20,
      color: Colors.DIM_GREY,
      fontWeight: 500
    },
    firstSelectContainer: {
      marginRight: isMobile ? 0 : theme.spacing(20),
    },
    secondSelectContainer: {
      marginRight: isMobile ? 0 : theme.spacing(10),
    },
    lineChartContainer: {
      overflowX: 'scroll'
    }
  })
)

export default useStyles
