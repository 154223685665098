import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommonFonts, Colors } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(12, 12, 18, 12),
      border: `1px solid ${Colors.GRAY_BORDER1}`,
      borderRadius: 15,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(12, 6, 18, 6),
      },
    },
    relative: {
      position: 'relative',
    },
    wrapperBackButton: {
      marginRight: theme.spacing(5),
    },
    title: {
      ...CommonFonts.darkFont24,
      fontWeight: 500,
      letterSpacing: 0.28,
      [theme.breakpoints.down('sm')]: {
        // position: 'relative',
        bottom: 4,
        letterSpacing: 0.4,
        fontSize: 20,
      },
    },
    // buttonWrapper: {
    //   [theme.breakpoints.up('md')]: {
    //     padding: theme.spacing(25, 14, 18, 14),
    //   },
    //   [theme.breakpoints.down('md')]: {
    //     padding: theme.spacing(4, 0, 4, 0),
    //   },
    // },
    nextButton: {
      height: 54,
      textTransform: 'none',
      fontSize: 14,
      color: Colors.WHITE,
    },
    createText: {
      letterSpacing: 0.3,
      ...CommonFonts.blueFont16,
      cursor: 'pointer',
      marginTop: theme.spacing(6),
    },
    signWithText: {
      letterSpacing: 0.3,
      ...CommonFonts.blueFont16,
      cursor: 'pointer',
    },
    smsText: {
      ...CommonFonts.grayFont16,
      textAlign: 'center',
      marginTop: theme.spacing(6),
      color: Colors.DIM_GREY
    },
    backWrapper: {
      paddingRight: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(0.5),
      },
    },
    passwordField: {
      '&>div': {
        marginTop: '7px',
      },
    },
    emailField: {
      // '&>div': {
      //   marginTop: '24px',
      // },
      '&>p': {
        position: 'absolute',
        top: 55,
      },
      [theme.breakpoints.up('lg')]: {
        top: 50,
      },
    },
    form: {
      // [theme.breakpoints.down('sm')]: {
      //   paddingTop: 46,
      // },
    },
    otpContainer: {
      margin: theme.spacing(6, 0)
    },
    errorText: {
      ...CommonFonts.darkFont12,
      color: Colors.RED,
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
  })
);

export default useStyles;
