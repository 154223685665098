import React, { useMemo } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Divider,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import DateFnsUtils from '@date-io/date-fns'
import { Colors } from '@/components/signupModal/constants/colors'
import { getFullAddressOfStore } from '@/utils/stringUtil'
import { useSelector } from 'react-redux'
import useStyles from './styles'

const dateAdapter = new DateFnsUtils()

function formatOrderDate(dateString) {
  return dateAdapter.format(new Date(dateString), 'HH:mm dd MMMM yyyy')
}

const getItemPrice = (item, amount) => {
  if (!item && !amount) return 0
  if (!item.addons) return amount
  const addonsPrice = item.addons.reduce((acc, addon) => {
    const price = addon.price?.amount || 0
    const quantity = addon.price?.quantity || 1

    return acc + price * quantity
  }, 0)
  return addonsPrice + (amount || 0)
}

const OrderDetailsModal = ({ isOpen, onClose, order }) => {
  const classes = useStyles()

  const paymentsByType = useMemo(() => {
    if (!order) return []
    const ordersByType = order.payments.reduce((acc, payment) => {
      const type = payment.payment_type?.name || 'Other'
      acc[type] = (acc[type] || 0) + payment.amount
      return acc
    }, {})

    return Object.entries(ordersByType)
  }, [order])

  const { selectedStore } = useSelector(state => state.store)
  const { address, name: storeName } = selectedStore

  if (!order) return null

  const storeAddress = getFullAddressOfStore(address)

  const formattedDate = formatOrderDate(order?.creation?.time || 0)
  const dialogTitle = `${order.order_no} - ${formattedDate} - ${order.state}`

  const shopperName =
    order.shopper && (order.shopper.first_name || order.shopper.last_name)
      ? `${order.shopper?.first_name} ${order.shopper?.last_name}`.trim()
      : 'N/A'

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle classes={classes} style={{ paddingRight: 48 }} className={'titleText'}>
        {dialogTitle}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box style={{ textAlign: 'center', marginBottom: '16px' }}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: Colors.DIM_GREY }}>
            {storeName}
          </Typography>
          <Typography variant="body2">{storeAddress}</Typography>
          <Typography variant="body2">
            <span style={{ fontWeight: 'bold', color: Colors.DIM_GREY }}>Customer:</span>{' '}
            {shopperName}
          </Typography>
        </Box>

        <Table size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: Colors.GRAY_LIGHT }}>
              <TableCell>Qty</TableCell>
              <TableCell>Item</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.items.map((item, idx) => {
              const qty = item.price.quantity
              const amount = item.price.amount
              return (
                <>
                  <TableRow
                    style={{
                      borderBottomWidth: 0,
                      borderBottom: 'none',
                    }}
                    key={idx}
                  >
                    <TableCell
                      style={{
                        borderBottomWidth: 0,
                        borderBottom: 'none',
                      }}
                    >
                      {qty}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottomWidth: 0,
                        borderBottom: 'none',
                      }}
                    >
                      {item.name}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottomWidth: 0,
                        borderBottom: 'none',
                      }}
                      align="right"
                    >
                      {getItemPrice(item, amount).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  {item.addons &&
                    item.addons.map((sub, sIdx) => (
                      <TableRow key={`${sub.name}-sub-${sIdx}`}>
                        <TableCell style={{ borderBottom: 'none' }} />
                        <TableCell style={{ borderBottom: 'none' }}>
                          {sub.price?.quantity}x {sub.name}{' '}
                          {((sub.price?.amount || 0) * (sub.price?.quantity || 1)).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )
            })}
          </TableBody>
        </Table>

        <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />

        <Box
          style={{
            marginTop: '16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Typography style={{ color: Colors.DIM_GREY }}>
            <span style={{ fontWeight: 'bold', color: Colors.DIM_GREY }}>Gross Total:</span>{' '}
            {order.total_price?.gross_amount?.toFixed(2) ?? '0.00'}
          </Typography>

          {paymentsByType.map(([name, amount]) => {
            return (
              <Typography style={{ color: Colors.DIM_GREY }}>
                <span style={{ fontWeight: 'bold', color: Colors.DIM_GREY }}>
                  {name ?? 'Payment'}:
                </span>{' '}
                {amount.toFixed(2)}
              </Typography>
            )
          })}
          {order.total_price?.taxes &&
            order.total_price.taxes.map(tax => (
              <Typography style={{ color: Colors.DIM_GREY }}>
                {tax.name?.toUpperCase()}: {tax?.amount?.toFixed(2) ?? '0.00'}
              </Typography>
            ))}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default OrderDetailsModal
