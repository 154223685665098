import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { transformAdressComponentsFromGoogleApi } from '@/utils/stringUtil';
import useStyles from './styles';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

//initiate google maps
function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
let map;
let service;

GoogleMapsPlaces.propTypes = {
  icon: PropTypes.string,
  getValues: PropTypes.func.isRequired,
  getFullAdress: PropTypes.func,
};

GoogleMapsPlaces.defaultProps = {
  icon: 'location',
};

export default function GoogleMapsPlaces({
  icon,
  getValues,
  getFullAdress,
  setBusinessName,
  noOptionsButton,
  country,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const [countryOfUser, setCountryOfUser] = useState('');

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  //get suggestions from google maps api
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  //get details of the selected place from autocomplete
  const getDetailsOfPlaceSelected = (placeId) => {
    map = new google.maps.Map(document.getElementById('map'));

    service = new google.maps.places.PlacesService(map);
    try {
      service.getDetails(
        {
          placeId,
          fields: [
            'address_components',
            'name',
            'geometry',
            'formatted_address',
          ],
        },
        (place) => {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          getValues({
            ...transformAdressComponentsFromGoogleApi(place.address_components),
            lat,
            lng,
          });
          setBusinessName && setBusinessName(place.name);
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: { country: countryOfUser },
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, countryOfUser]);

  //add address manually if no addresses found
  const handleClick = () => {
    noOptionsButton();
  };

  // Detect default country using IP
  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        setCountryOfUser(data.country.toLowerCase());
        localStorage.setItem('country', data.country_name);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const renderNoOptionsButton = () => {
    return (
      <span>{t('addressAutoComplete.no_options_found')}</span>
    );
  };

  return (
    <div>
      <div id="map" />
      <Autocomplete
        freeSolo={inputValue.length === 0}
        popupIcon={
          icon === 'location' ? (
            <LocationOnOutlinedIcon />
          ) : (
            <SearchOutlinedIcon />
          )
        }
        id="google-map-demo"
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={noOptionsButton && inputValue !== ''
          ? [...options.slice(0, 3), { isManualAdd: true }]
          : options.slice(0, 3)
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          getFullAdress(newValue.description);
          getDetailsOfPlaceSelected(newValue.place_id);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        classes={{
          endAdornment: classes.autoCompleteRoot,
          popupIndicatorOpen: classes.popupIndicatorOpen,
          noOptions: noOptionsButton ? classes.noDisplay : classes.display
        }}
        noOptionsText={renderNoOptionsButton()}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('addressAutoComplete.placeholder')}
            fullWidth
            InputProps={{
              ...params.InputProps,
              style: {
                padding: 10,
                paddingRight: 60
              },
            }}
          />
        )}
        renderOption={(option) => {
          if (option?.isManualAdd) {
            return (
              <Grid container justify="center">
                <span
                  className={classes.noOptionsButton}
                  onMouseDown={() => handleClick()}
                >
                  {t('addressAutoComplete.add_manually')}
                </span>
              </Grid>
            )
          } else {
            //get matching letters
            const matches =
              option.structured_formatting.main_text_matched_substrings;
            let parts;
            let onlyAdress = false;

            //check if the suggestions is a route or a business
            if (
              option.types.includes('route') ||
              option.types.includes('street_address') ||
              option.types.includes('geocode')
            ) {
              onlyAdress = true;
              parts = parse(
                option.description,
                matches.map((match) => [
                  match.offset,
                  match.offset + match.length,
                ])
              );
            } else {
              parts = parse(
                option.structured_formatting.main_text,
                matches.map((match) => [
                  match.offset,
                  match.offset + match.length,
                ])
              );
            }

            return (
              <Grid
                container
                alignItems="flex-start"
                style={{ paddingTop: 7, paddingBottom: 7 }}
              >
                <Grid item>
                  <LocationOnOutlinedIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                        fontSize: 18,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                  {!onlyAdress && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontSize: 16 }}
                    >
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            );
          }
        }}
      />
    </div>
  );
}
