import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { find } from 'lodash'
import moment from 'moment'

import { useTranslation } from 'react-i18next'

import {
  Grid,
  Typography,
  Box,
  Popover
} from '@material-ui/core'
import Button from '@/components/button'
import Selector from '../selector'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Calendar from 'react-calendar'

import { selectCurrentMerchant } from '@/store/modules/store/selectors'

import { getQuarterDateRange, getWeekDateRange } from '@/utils/momentUtil'
import {
  REPORT,
  REPORT_TYPE,
  YEARS,
  QUARTERS,
  MONTHS,
  WEEKS
} from '../helper'
import useStyles from './styles'

const Selectors = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    store,
    report,
    reportType,
    year,
    period,
    date,
    customRange,
    onChangeStore,
    onChangeReport,
    onChangeReportType,
    onChangeYear,
    onChangePeriod,
    onChangeDate,
    onChangeCustomRange,
    onClickReport
  } = props

  const [datePickerAnchor, setDatePickerAnchor] = useState(null)

  const currentMerchant = useSelector(selectCurrentMerchant)
  const weekStartDay = currentMerchant.week_start_day
  const financialYearEnd = currentMerchant.financial_year_end

  const stores = currentMerchant.stores

  const groupedByRegion = _.groupBy(
    stores,
    (store) => store?.region?.id
  );

  const storeItems = useMemo(() => {
    let items = [{
      id: '-1',
      name: t('header.all_stores'),
    }]
    Object.keys(groupedByRegion).forEach(regionId => {
      if (regionId !== 'undefined') {
        items.push({
          id: regionId,
          name: groupedByRegion[regionId][0]?.region?.region_name,
        })
      }
      groupedByRegion[regionId].forEach(store => {
        items.push({
          id: store.id,
          name: store.name,
        })
      })
    })
    return items
  }, [currentMerchant])

  const periodItems = useMemo(() => ({
    [REPORT_TYPE.WEEKLY_REPORT]: WEEKS(year),
    [REPORT_TYPE.MONTHLY_REPORT]: MONTHS,
    [REPORT_TYPE.QUARTERLY_REPORT]: QUARTERS
  }), [year])

  const getPeriodItemLabel = (item) => {
    let label = item.name
    let dateRange
    if (reportType === REPORT_TYPE.WEEKLY_REPORT) {
      dateRange = getWeekDateRange(year, item.id, financialYearEnd, weekStartDay)
      if (moment().startOf('day').isBetween(dateRange.start, dateRange.end)) {
        label = `${t('workboard.run_reports.week_to_date')} (${label})`
      }
    } else if (reportType === REPORT_TYPE.MONTHLY_REPORT) {
      if (item.id === moment().month() + 1 && moment().year() === year) {
        label = `${t('workboard.run_reports.month_to_date')} (${label})`
      }
    } else if (reportType === REPORT_TYPE.QUARTERLY_REPORT) {
      dateRange = getQuarterDateRange(year, item.id, financialYearEnd)
      if (moment().startOf('day').isBetween(dateRange.start, dateRange.end)) {
        label = `${t('workboard.run_reports.quarter_to_date')} (${label})`
      }
    }
    return label
  }

  return (
    <Grid
      container
      className={classes.root}
      alignItems='center'
    >
      <Grid item xs={12} sm={6} md={4} lg={2} className={classes.itemContainer}>
        <Selector
          title={t('workboard.run_reports.select_store')}
          items={storeItems}
          getItemValue={(item) => item.id}
          getItemLabel={(item) => item.name}
          selectedItem={store}
          onChangeValue={(value) => onChangeStore(find(storeItems, { id: value }))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} className={classes.itemContainer}>
        <Selector
          title={t('workboard.run_reports.report')}
          items={Object.keys(REPORT).map(key => REPORT[key])}
          getItemValue={(item) => item}
          getItemLabel={(item) => item}
          selectedItem={report}
          onChangeValue={(value) => onChangeReport(value)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} className={classes.itemContainer}>
        <Selector
          title={t('workboard.run_reports.report_type')}
          items={Object.keys(REPORT_TYPE).map(key => REPORT_TYPE[key])}
          getItemValue={(item) => item}
          getItemLabel={(item) => item}
          selectedItem={reportType}
          onChangeValue={(value) => onChangeReportType(value)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} className={classes.itemContainer}>
        <Selector
          title={t('workboard.run_reports.year')}
          items={YEARS}
          getItemValue={(item) => item}
          getItemLabel={(item) => item}
          selectedItem={year}
          onChangeValue={(value) => onChangeYear(value)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} className={classes.itemContainer}>
        {(reportType === REPORT_TYPE.WEEKLY_REPORT ||
          reportType === REPORT_TYPE.MONTHLY_REPORT ||
          reportType === REPORT_TYPE.QUARTERLY_REPORT) &&
          <Selector
            title={t('workboard.run_reports.select_period')}
            items={periodItems[reportType]}
            getItemValue={(item) => item.id}
            getItemLabel={getPeriodItemLabel}
            selectedItem={period}
            onChangeValue={(value) => onChangePeriod(value)}
          />
        }

        {(reportType === REPORT_TYPE.DAILY_REPORT ||
          reportType === REPORT_TYPE.CUSTOM_REPORT) &&
          <Box>
            <Typography className={classes.titleText}>
              {t('workboard.run_reports.select_period')}
            </Typography>
            <Box
              className={classes.selectContainer}
              onClick={(e) => setDatePickerAnchor(e.currentTarget)}
            >
              <Typography className={classes.filterInput}>
                {reportType === REPORT_TYPE.DAILY_REPORT && (
                  moment().isSame(moment(date), 'day')
                    ?
                    t('workboard.run_reports.today')
                    :
                    moment(date).format('DD/MM/YY')
                )}
                {reportType === REPORT_TYPE.CUSTOM_REPORT &&
                  `${moment(customRange[0]).format('DD/MM/YY')} - ${moment(customRange[1]).format('DD/MM/YY')}`
                }
              </Typography>
              <ExpandMoreIcon fontSize='default' className={classes.expandIcon} />
            </Box>
          </Box>
        }
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} className={classes.itemContainer}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={onClickReport}
        >
          {t('button_group.get_report')}
        </Button>
      </Grid>


      <Popover
        id='simple-popover'
        open={Boolean(datePickerAnchor)}
        anchorEl={datePickerAnchor}
        onClose={() => setDatePickerAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Calendar
          value={reportType === REPORT_TYPE.DAILY_REPORT ? date : customRange}
          onChange={(value) => {
            if (reportType === REPORT_TYPE.DAILY_REPORT) {
              onChangeDate(value)
            } else {
              onChangeCustomRange(value)
            }
            setDatePickerAnchor(null)
          }}
          showDoubleView={reportType === REPORT_TYPE.CUSTOM_REPORT}
          selectRange={reportType === REPORT_TYPE.CUSTOM_REPORT}
        />
      </Popover>
    </Grid>
  )
}

Selectors.propTypes = {
  store: PropTypes.object,
  report: PropTypes.string,
  reportType: PropTypes.string,
  year: PropTypes.number,
  period: PropTypes.any,
  date: PropTypes.any,
  customRange: PropTypes.any,
  onChangeStore: PropTypes.func,
  onChangeReport: PropTypes.func,
  onChangeReportType: PropTypes.func,
  onChangeYear: PropTypes.func,
  onChangePeriod: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeCustomRange: PropTypes.func,
  onClickReport: PropTypes.func,
}

export default Selectors