import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { isEmpty, find, omit } from 'lodash';
import { v4 as uuid } from 'uuid';
import moment from 'moment'
import { useSnackbar } from 'notistack';

import {
  Breadcrumbs,
  Link,
  Grid,
  Paper,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link as RouterLink } from 'react-router-dom';
import AppLayout from '@/components/appLayout';
import PreLoader from '@/components/preLoader';
import Modal from '@/components/modal';
import SearchHeader from './components/SearchHeader'
import NoPromotions from './components/NoPromotions'
import PromotionTable from './table'
import AddUpdatePromotion from './addUpdatePromotion'

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_PROMOTIONS,
  DELETE_PROMOTION,
  ADD_PROMOTION
} from '@/services/promotionService'

import { selectSelectedStore } from '@/store/modules/store/selectors'

import { contains } from '@/utils/stringUtil'
import { isPromotionActive } from '@/utils/promotionUtil'
import { PROMOTION_TYPES, PROMOTION_STATUSES } from './helper'
import useStyles from './styles';

const ONE_PAGE_LIMIT = 10

const Promotions = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const selectedStore = useSelector(selectSelectedStore)

  const [inputSearch, setInputSearch] = useState("");
  const [openPromotionModal, setOpenPromotionModal] = useState(false)
  const [selectedPromotion, setSelectedPromotion] = useState(null);

  const [promotions, setPromotions] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const [isAllPromotionsLoaded, setIsAllPromotionsLoaded] = useState(false)

  const [promotionTypes, setPromotionTypes] = useState(PROMOTION_TYPES)
  const [promotionStatuses, setPromotionStatuses] = useState(PROMOTION_STATUSES)

  const filteredPromotions = promotions
    .filter(promotion => contains(promotion.promo_name, inputSearch))
    .filter(promotion => !!find(promotionTypes, { type: promotion.type }))
    .filter(promotion => {
      const status = isPromotionActive(promotion) ? 'active' : 'inactive'
      return !!find(promotionStatuses, { status })
    })

  // get promotions from api
  const [getPromotions, { loading: isLoadingPromotions }] = useLazyQuery(GET_PROMOTIONS, {
    onCompleted: (data) => {
      // if response give us less than 10 results, set all promotions loaded
      if (data.promotions.length < 10) {
        setIsAllPromotionsLoaded(true);
      } else {
        setIsAllPromotionsLoaded(false)
      }

      // update the array holding products
      setPromotions([...promotions, ...data.promotions]);
    },
    onError: () => {
      setIsAllPromotionsLoaded(true);
    },
    fetchPolicy: 'no-cache',
  });

  const [deletePromotionMutation, { loading: isDeleting }] = useMutation(DELETE_PROMOTION, {
    onCompleted: (data) => {
      onSuccessAddUpdatePromotion(data.addPromotion)
    },
    onError: (error) => {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  })

  const [updatePromotionMutation, { loading: isUpdating }] = useMutation(ADD_PROMOTION, {
    onCompleted: (data) => {
      onSuccessAddUpdatePromotion(data.addPromotion)
    },
    onError: (error) => {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  })

  // change current page when user reaches bottom
  const handleLoadMore = () => {
    if (isEmpty(filteredPromotions)) return
    setCurrentPage(currentPage + 1);
  };

  const refetchPromotions = () => {
    getPromotions({
      variables: {
        filter: {
          count: true,
          page: currentPage,
          limit: ONE_PAGE_LIMIT,
        },
      },
    });
  }

  // get products from API
  useEffect(() => {
    refetchPromotions()
  }, [currentPage]);

  useEffect(() => {
    setPromotions([])
    setCurrentPage(1);
  }, [selectedStore]);

  const onUpdateDetail = (promotion) => {
    setSelectedPromotion(promotion)
    setOpenPromotionModal(true)
  }

  const onPauseActivate = (promotion) => {
    updatePromotionMutation({
      variables: {
        promotion: {
          ...omit(promotion, '__typename'),
          active: !promotion.active,
          options: promotion.options.map(option => ({
            inventory_id: option.products.map(product => product.id),
            category_id: option.categories.map(category => category.id)
          })),
          // start_time: moment(promotion.start_time).format('HH:mm'),
          // end_time: moment(promotion.end_time).format('HH:mm'),
        }
      }
    })
  }

  const onDelete = (promotion) => {
    deletePromotionMutation({
      variables: {
        promotion: {
          id: promotion.id,
          flag: "delete"
        }
      }
    })
  }

  const onSuccessAddUpdatePromotion = (promotion) => {
    let newPromotions = promotions.map(item => item.id === promotion.id ? promotion : item)
    if (!find(newPromotions, { id: promotion.id })) {
      newPromotions = [promotion, ...newPromotions]
    }
    setPromotions(newPromotions)
  }

  return (
    <AppLayout
      className={classes.appLayout}
      withFooter
      header
      withServiceDropdown={true}
    >
      <div className={classes.root}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                className={classes.breadcrumbsText}
                color="primary"
                component={RouterLink}
                to="/business-manager"
              >
                {t('settings.business_manager')}
              </Link>
              <Link
                className={classes.breadcrumbsText}
                color={'textPrimary'}
                onClick={() => { }}
              >
                {t('menu.promotions')}
              </Link>
            </Breadcrumbs>
          </Grid>
          <SearchHeader
            promotionTypes={promotionTypes}
            promotionStatuses={promotionStatuses}
            onChangePromotionTypes={(value) => isEmpty(value)
              ? {}
              : setPromotionTypes(value)
            }
            onChangePromotionStatuses={(value) => isEmpty(value)
              ? {}
              : setPromotionStatuses(value)
            }
            onChangeSearch={setInputSearch}
            onAdd={() => setOpenPromotionModal(true)}
          />
          <Grid item xs={12}>
            <Paper className={classes.emptyExpensesContainer}>
              {(isEmpty(filteredPromotions) && !isLoadingPromotions)
                ?
                <NoPromotions
                  title={t('promotions.no_promotions_found')}
                />
                :
                <InfiniteScroll
                  dataLength={filteredPromotions.length}
                  next={handleLoadMore}
                  hasMore={!isAllPromotionsLoaded}
                  loader={
                    isLoadingPromotions && currentPage !== 1 && (
                      <div className={classes.root}>
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                      </div>
                    )
                  }
                >
                  <PromotionTable
                    promotions={filteredPromotions}
                    isLoadingPromotions={isLoadingPromotions}
                    currentPage={currentPage}
                    onUpdateDetail={onUpdateDetail}
                    onPauseActivate={onPauseActivate}
                    onDelete={onDelete}
                  />
                </InfiniteScroll>
              }
            </Paper>
          </Grid>

        </Grid>
      </div>

      {openPromotionModal &&
        <AddUpdatePromotion
          open={openPromotionModal}
          handleClose={() => {
            setOpenPromotionModal(false)
            setSelectedPromotion(null)
          }}
          defaultPromotion={selectedPromotion}
          onSuccess={onSuccessAddUpdatePromotion}
        />
      }

      {(isDeleting || isUpdating) && <PreLoader size={35} />}
    </AppLayout>
  )
}

export default Promotions