import React from 'react'

const ArrowIcon = ({ icon, isSelected }) => {
  const ICON = icon
  return (
    <ICON
      color={isSelected ? 'primary' : 'disabled'}
      fontSize='small'
    />
  )
}

export default ArrowIcon