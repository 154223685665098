const TRANSLATIONS_EN = {
  button_group: {
    yes: 'Yes',
    no: 'No',
    next: 'NEXT',
    back: 'BACK',
    ok: 'OK',
    login: 'LOGIN',
    close: 'Close',
    logout: 'Logout',
    managePreferences: 'Manage Preferences',
    changePassword: 'Change Password',
    add: 'ADD',
    update: 'UPDATE',
    add_new: 'Add new',
    added: 'ADDED',
    get_report: 'GET REPORT',
    cancel: 'CANCEL',
    add_product: 'ADD PRODUCT',
    change_password: 'CHANGE PASSWORD',
    move: 'MOVE',
    skip_and_add: 'SKIP & ADD',
    add_new: 'ADD NEW',
    skip_add: 'SKIP & ADD',
    skip_update: 'SKIP & UPDATE',
    profile: 'Profile',
    profile_icon_updated: 'Profile icon updated',
    remove: 'Remove',
    adjust: 'Adjust',
    save: 'Save',
    change: 'Change',
    approve: 'Approve',
    decline: 'Decline',
    delete: 'Delete',
  },

  footer: {
    copywright: 'Karan Retail Ltd © 2024',
    terms_of_use: 'Terms of use',
    privacy_policy: 'Privacy Policy',
    contact: 'Contact',
  },

  receipt_page: {
    order_no: "ORDER NO",
    qty: "Qty",
    item: "Item",
    amount: "Amount",
    total: "Total",
    paid: "Paid",
    store: "Store: {{name}}",
    touchpoint: "Touchpoint: {{no}}",
    total_paid: "Total Paid",
    no_of_items: "No of Items",
    get_pdf_receipt: "Get PDF Receipt",
  },

  login_page: {
    login_description:
      'Login to connect your business with your suppliers & customers.',
    use_myda_account: 'Use your KRL Account to login.',
    email_address: 'Email address',
    sign_with_email: 'Sign in with email',
    sign_with_mobile: 'Sign in with mobile',
    create_account: 'Create KRL Account',
    no_account_exist: "That KRL Account doesn't exist",
    enter_valid_email: 'Enter a valid email address',
    email_required: 'Email required',
    password: 'Password',
    password_invalid: 'That password is incorrect',
    password_required: 'Password required',
    forgot_password: 'Forgot password',
    you_receive_sms_opt:
      'By continuing you will receive an SMS for verification.',
    enter_otp_description:
      'Enter OTP sent to your mobile ending with {{suffix_number}}',
    enter_otp: 'Enter OTP',
    resend_otp: 'Resend OTP',
    invalid_otp: 'Invalid OTP. Try again.',
    reset_password: 'Reset Password',
    back_to_login: 'Back to login',
    new_myda: 'New to KRL?',
  },

  reset_password_page: {
    change_password: 'Change password',
    change_password_success:
      'Password changed successfully. You can now login with your new password.',
    expired_code: 'Invalid or expired reset code',
  },
  select_store_page: {
    select_store_or_business: 'Select store or business',
    search_name: 'Enter store or merchant name',
  },

  language_popup: {
    change_language: 'Change language',
  },

  error_group: {
    error_email: 'That KRL account doesn’t exist',
    error_password: 'That password is incorrect',
  },

  header: {
    change: 'Change',
    enter_store_region_warehouse:
      'Enter store or region or warehouse name',
    enter_merchant_name: 'Enter merchant name',
    select_business: 'Select Business',
    add_merchant: 'Add New Merchant',
    visit_stores_page:
      'Visit <tag>stores</tag> page under Settings to add a store to an existing merchant.',
    workboard: 'Workboard',
    'business-manager': 'Business Manager',
    'user-management': 'User management',
    settings: 'Settings',
    'touchpoints-management': 'Touchpoints',
    'order-types-workflows-management': 'Order Types & Workflows',
    'subscription-management': 'Subscriptions',
    import: 'Import',
    help: 'help',
    all_stores: 'All Stores',
    modules: 'Modules',
    stores: 'Stores',
    warehouses: 'Warehouses',
    merchants: 'Merchants',
    no_warehouse_available: 'No warehouse available.',
    add_warehouse: 'Add warehouse.',
    developer: 'Developer',
  },

  menu: {
    services: 'Services',
    workBoard: 'Workboard',

    /*businessManager */
    businessManager: 'Business Manager',
    'store-profile': 'Store profile',
    orders: 'Orders',
    expenses: 'Expenses',
    products: 'Products',
    promotions: 'Promotions',
    style: 'Style Management',
    /*styleManagement */
    'style-variants': 'Style Variants',
    colors: 'Colours',
    sizes: 'Sizes',
    /*styleManagement */
    allergy: 'Allergies',
    recipe: 'Product builders',
    ingredient: 'Raw Materials',
    net_price: 'Net price',
    raw_materials: 'Raw materials',
    add_ons: 'Add-ons',
    customers: 'Customers',
    suppliers: 'Suppliers',
    addon: 'Add-ons',
    /*businessManager */

    insights: 'Insights',
    admin: 'Admin',
    stores: 'Stores',
    warehouses: 'Warehouses',

    /*userManagement */
    userManagement: 'User management',
    users: 'Active Users',
    applications: 'Applications',
    archivedUsers: 'Archived users',
    manageTeams: 'Manage teams',
    teams: 'Teams',
    archived: 'Archived Users',
    /*userManagement */

    settings: 'Settings',
    developer: 'Developer',

    /*touchpoints */
    touchPoints: 'Touchpoints',
    archivedTouchpoints: 'Archived Touchpoints',

    automationWorkflows: 'Automation & Workflows',
    subscriptions: 'Subscriptions',
    plans: 'Plans',
    paymentMethods: 'Payment methods',
    import: 'Import data',
    orderTypeWorkflows: 'Order Types & Workflows',
    orderTypes: 'Order types',
    workflows: 'Workflows',
    modules: 'Modules',
  },

  signup: {
    signup_for_myda: 'Sign up for KRL Account.',
    use_email: 'Use Email',
    use_mobile: 'Use Mobile',
    email_address: 'Email address',
    email_rquired: 'Email required',
    enter_valid_email: 'Enter a valid email',
    password: 'Password',
    confirm_password: 'Confirm Password',
    password_required: 'Password required',
    confirm_required: 'Confirm required',
    password_mismatch: 'Password mismatched',
    lowercase_required: 'Lower case required',
    uppercase_required: 'Upper case required',
    number_required: 'Number required',
    min_chars: 'Min chars 8',
    by_clicking_signup: 'By clicking Sign Up, you agree to our ',
    terms_policy: 'Terms of use and Privacy Policy.',
    you_receive_sms_opt:
      'You may receive SMS notifications & email communications from us and you can opt out or manage preferences at any time.',
    signup: 'SIGN UP',
    copyright: 'Karan Retail Ltd © 2024',
    need_verification: 'We need to verify you are who you are.',
    check_your_email:
      'An email is sent to {{email}}. Check your email and and follow instructions.',
    login: 'LOG IN',
    resend_email: 'Resend email',
    change_email: 'Change email',
    first_name: 'First name',
    first_name_required: 'First name required',
    second_name: 'Second name',
    second_name_required: 'Second name required',
    mobile_number: 'Mobile number',
    mobile_number_required: 'Mobile number required',
    invalid_number: 'Invalid Phone number',
    enter_otp:
      'Enter OTP sent to your mobile ending with {{suffix_number}}',
    resend_otp: 'Resend OTP',
    change_number: 'Change mobile number',
    invalid_otp: 'Invalid OTP. Try again.',
    change_language: 'Change language',
    close: 'Close',
    email_verify_success: 'Email verified. You can login now.',
  },

  businessManager: {
    store_overview: 'Store Overview',
    view_and_update_storefront: 'View & Update Storefront',
    store_profile: 'Store profile',
    orders: 'Orders',
    expenses: 'Expenses',
    inventory: 'Inventory',
    promotions: 'Promotions',
    customers: 'Customers',
    run_report: 'Run Report',
    add_order: 'Add Order',
    add_expense: 'Add Expenses',
    add_product: 'Add Product',
    add_stock: 'Add Stock',
    add_promotion: 'Add Promotion',
    add_customer: 'Add Customer',
    replenish_stock: 'Replenish Stock',
    settings: 'Settings',
    touchpoints: 'Touchpoints',
    workflows: 'Workflows',
    users: 'Users',
    subscriptions: 'Subscriptions',
    integrations: 'Integrations',
    import_data: 'Import Data',
    store_name_required: 'Store name is required!',
    nick_name: 'Nick name',
    nickname: 'Nickname',
    nickname_description:
      'Each of your stores can be given a Nickname to identify them and filter data across all KRL platforms and applications which will only be visible to you and not your customers or suppliers',
    home: 'Home',
    products: 'Products',
    style: 'Styles',
    allergy: 'Allergies',
    recipe: 'Product builders',
    ingredient: 'Raw Materials',
    net_price: 'Net price',
    raw_materials: 'Raw materials',
    add_ons: 'Add-ons',
    suppliers: 'Suppliers',
    addon: 'Add-ons',
  },

  categories: {
    categories: 'Categories',
    add_category: 'Add Category',
    add_product: 'Add Products',
    category_name: 'Category name',
    type: 'Type',
    no_products: 'No. of products',
    taxes: 'Taxes',
    parent: 'Parent',
    sub_category: 'Sub Category',
    vat_exempt: 'VAT Exempt',
    empty_list_of_products: 'Your product list is empty',
    add_first_product: 'Add first product',
    add_first_category: 'Add first category',
    import_products: 'Import products',
    move_products_another_store: 'Move products from another store',
    no_match_found: 'No match found. Try again.',
    no_products_found: 'No products found',
    no_categories_found: 'No matching categories found.',
    no_categories_with_no_products_found:
      'No categories with no products found. Try again.',
    no_matching_products_found: 'No matching products found.',
    age_restriction: 'Age restriction',
    age_restriction_description:
      'If switched on, supported touchpoints (POS, Kiosk, etc.) will require users to verify the customer’s age before allowing the sale to continue.',
    sold_hours: 'Sold hours',
    sold_hours_description:
      'If switched on, supported touchpoints (POS, Kiosk, etc.) will prevent users from selling items in this category outside the selected period.',
    commission_sales: 'Commission sales',
    commission_sales_description:
      'If switched on, revenue from items from this category will be calculated as commission sales in reports. Recommended if you are selling products for commission.',
    chef_note: 'Chef note',
    chef_note_description:
      'If switched on, supported touchpoints (POS, Kiosk, etc.) will send the items in this category to the back kitchen using a connected printer or order display system. Recommended for fast food, restaurant & beverage outlets.',
    enter_minimum_age: 'Enter minimin age',
    add_new_category: 'Add new category',
    cancel: 'Cancel',
    add: 'Add',
    category_created_successfully: 'Category created successfully',
    category_updated_successfully: 'Category updated successfully',

    general_information: 'General information',
    category_image: 'Category image',
    rules: 'Rules',
    this_is_parent: 'This is parent',
    edit_category_title: 'Edit category',
    update_category: 'Update',
    age_required: 'Age is required',
    parent_category: 'Parent category',
    select: 'Select',
    product: 'Product',
    ean_upc_mpc: 'EAN/UPC/MPC',
    price_tax_inc: 'Price & Tax Inc',
    edit: 'Edit',
    move_products: 'Move products',
    archive: 'Archive',
    update_general_info: 'Update general info & Price',
    update_stock_info: 'Update stock information',
    update_image_description: 'Update image & Description',
    update_allergy: 'Update allergy',
    update_addons: 'Update add-ons',
    update_product_builder: 'Update product builder',
    delete: 'Delete',
    deleted: 'Deleted',
    move_items: 'Move Items',
    move_from: 'Move from',
    move_to: 'Move to',
    select_category: 'Select category',
    move_items_description:
      'You are moving all of the items under one category to another. All of your connected touchpoints such as POS, Kiosk, Digital storefront will be updated.',
    items_moved: 'Items moved',
    current_stock: 'Current stock',
    made_to_order: 'Made to order',
    category_deleted: 'Category {{name}} deleted',
    enter_category_name: 'Enter category name',
  },

  settings: {
    add: 'Add',
    added: 'Added',
    updated: 'Updated',
    update: 'Update',
    select: 'Select',
    save: 'Save',
    copy: 'Copy',
    copied: 'Copied',
    cancel: 'Cancel',
    business_manager: 'Business Manager',
    settings: 'Settings',
    manage: 'Manage',
    error: {
      update: 'Something went wrong! Please try again.',
    },
    business_and_finance: {
      title: 'Business & Finance',
      sub_title: 'Sales taxes',
      select_type: 'Select type',
      registered_name_required: 'Register name is required!',
      trading_name_required: 'Trading name is required!',
      registered_address_required: 'Registered address is required!',
      business_type_required: 'Business type is required!',
      registration_type_required: 'Registration type is required!',
      registration_number_required:
        'Registration number is required!',
      registration_tax_required: '{{name}} registration is required!',
      vat_number_required: '{{name}} number is required!',
      update_registration_tax: 'Update {{name}} registration',
      gst_registration_required: 'GST registration is required!',
      gst_number_required: 'GST number is required!',
      registered_address_sub_title:
        'Start typing your address to find and select.',
      input: {
        registered_name: 'Registered name',
        trading_name: 'Trading name',
        registered_address: 'Registered address',
        business_type: 'Business type',
        registration_type: 'Registration type',
        registration_number: 'Registration number',
        registration_tax: '{{name}} registration',
        vat_number: '{{name}} number',
        gst_registration: 'GST registration',
        gst_number: 'GST number',
      },
      address: {
        street_number: 'Street number',
        building_name: 'Building name',
        floor_number: 'Floor number',
        unit_number: 'Unit number',
        street_name: 'Street name',
        city: 'City',
        post_code: 'Postcode',
        stress_name_fill: 'Enter your street name',
        street_name_required: 'Street name is required',
        street_number_fill: 'Enter your street number',
        street_number_required: 'Street number is required',
        city_fill: 'Enter your city name',
        city_required: 'City name is required',
        postcode_fill: 'Enter your post code',
        postcode_required: 'Post code is required',
        country_fill: 'Enter your country',
        country_required: 'Country is required',
        building_name_fill: 'Enter your building name',
      },
    },
    reports: {
      title: 'Reports',
      to_update: 'to update.',
      click_here: 'Click here ',
      financial_year_end: 'Financial year end',
      financial_year_end_sub_title:
        'Select date and month of financial year end.',
      financial_year_end_description:
        'This is the completion date of one year or 12 month accounting period. ',
      week_commencing_day: 'Week commencing day',
      week_commencing_day_description:
        'This is the week start day to calculate weekly reports. Weekly reports will start from the week commencing day. ',
      day_end_time: 'Day end time',
      day_end_time_sub_title:
        'Select hour, minute and seconds in order.',
      day_end_time_description:
        'Transactions received after this time will be recorded in the following day. ',
    },
    chart_of_accounts: {
      title: 'Chart of Accounts',
      add_bank: 'Add bank',
      add_account: {
        add: 'Add',
        update: 'Update',
        add_button: 'Add account',
        modal_title_add: 'Add Account',
        modal_title_edit: 'Update Account',
        name_required: 'Account name is required',
        name_max: 'Maximum of 150 characters',
        description_max: 'Maximum of 400 characters',
        type_required: 'Account type is required',
        account_name: 'Account name',
        account_description: 'Description (optional)',
        account_type: 'Account type',
        reports: 'Reports',
        account_show_in_performance_report:
          'Show in performance overview',
        new_account_added: '{{name}} added',
        account_updated: '{{name}} updated',
        account_archived: '{{name}} archived',
        account_restored: '{{name}} restored',
      },
      all_account: {
        account_name: 'Account name',
        type: 'Type',
        opening_balance: 'Opening balance',
        edit: 'Edit',
        merge: 'Merge',
        archived: 'Archived',
        restore: 'Restore',
        tax: 'Tax',
      },
      no_account_found: 'No Account Found!',
    },
    inventory: {
      title: 'Inventory',
      pricing: 'Pricing',
      price_types: 'Price types',
      price_types_description:
        'Multiple price types can help you manage multiple prices for each product, enabling you to offer bespoke pricing for order types or selected customer segments.',
      select_modules: 'Select modules',
      price_exclusive_taxes: 'Show prices exclusive of taxes.',
      price_exclusive_taxes_description:
        'Recommended to switch on if your customers are businesses. This will show display product prices in inventory, orders and receipts exclusive of taxes. Taxes will be displayed separately.',
      product_price_across:
        'Update product price across all stores when edited.',
      product_price_across_description:
        'Enabling this will change the product price across all stores when its updated in any one of your stores.',
      raw_materials: 'Raw materials',
      raw_materials_description:
        'Raw materials module can help you to manage raw materials / ingredients and optimise stock control.',
      add_ons: 'Add-ons',
      add_ons_description:
        'Add-ons module can help you to create & manage product options / variants to help staff up-sell and help your customers all of the options available with each product. .',
      recipe_management: 'Product builder',
      recipe_manegement_description:
        'Product builder allows you to add raw materials and products used to make a product. This will allow you to gain access to accurate stock insights and ensure the right stock is available at all times.',
      allergy_management: 'Allergy management',
      allergy_management_description:
        'Allergy management gives you the capabilities to manage allergy information of each and every items sold and share it with staff and customers.',
      style_management: 'Style management',
      style_management_description:
        'Style management can help you create & manage style variations of clothing or accessories you manufacture or sell.',
      measures: 'Measures',
      measures_description:
        'Select measure types required to manage your inventory items and raw materials to optimise stock control.',
      update_order_type: 'Update order type',
      price_type_linked:
        '{{name}} price type is linked to one of more order types.',
      click_here: 'Click here',
      remove_price_type_from_order_type:
        ' to remove {{name}} price type from order types before unselecting.',
      ok: 'OK',
      manage_style_options: 'Manage style options',
      type: 'Type',
      configure: 'Configure',
      values: 'Values',
      add_new: 'Add new',
      update: 'Update',
      delete: 'Delete',
      success: 'Success',
    },
    branding: {
      title: 'Branding',
      description:
        'Select your business brand colours so that software services and apps can utilise your brand colours where possible.',
      hex: 'Hex',
      primary_colour: 'Primary colour',
      secondary_colour: 'Secondary colour',
      third_colour: 'Third colour',
      colour_updated: '{{colour}} updated',
    },
    touchpoint_pos: {
      title: 'Point of Sale (POS) Touchpoint',
      general_settings: 'General Settings',
      automatic_receipt_printing: 'Automatic Receipt Printing',
      automatic_receipt_printing_description:
        'Switching on Automatic Product builder Printing will automatically print customer receipts when an order is paid in full.',
      automatic_chefnote_printing: 'Automatic Chef note Printing',
      automatic_chefnote_printing_description:
        'Switch on auto chef note printing if you chef notes to be printed automatically when paid or accepted.',
      automatic_expense_receipt_printing:
        'Automatic Expense Receipt Printing',
      automatic_expense_receipt_printing_description:
        'Switching on Automatic Expense Product builder Printing will automatically print a receipts when an expense is paid.',
      float: 'Float',
      float_description:
        'Switch on if you use float. This is the amount kept in cash-drawer at the start of a shift. The amount entered will be automatically deducted from available cash to calculate cash takings.',
      manual_amount_authorisation: 'Manual Amount Authorisation',
      manual_amount_authorisation_description:
        'Switching on will allow you to set a maximum amount that can be manually added to cart without confirmation.',
      auto_lock: 'Auto Lock',
      auto_lock_description:
        'Enter the idle time in second before POS should automatically lock for enhanced security.',
      seconds: 'Seconds',
      cash_drawer_alarm: 'Cash Drawer Alarm',
      cash_drawer_alarm_desciption:
        'Switching on will set a timer to alert cashiers if cash drawer remains open for longer than the selected minutes. Enter in minutes.',
      group_same_items_in_cart: 'Group Same  Items in cart',
      group_same_items_in_cart_desctiption:
        'Switching on this feature will group items in shopping cart in supported touchpoints.',
      payment_types: 'Payment types',
      payment_types_desciption:
        'Your POS is set to accept Cash, Card, PayPal and GoPay. Click on update to add or remove payment types.',
      update_payment_types_accepted: 'Update payment types accepted',
      images: 'Images',
      banners: 'Banners',
      banners_description:
        'Upload a banner that can be displayed when all users logged out of POS is locked. Recommended file size is size 1280 x 800 (Jpg / Png)',
      customer_display_images: 'Customer display images',
      customer_display_images_description:
        'Add up to 4 images to be displayed in customer display screens if you use a support device with a customer display screen.',
      product_arrangement: 'Product Arrangement',
      alphabetical_order: 'Alphabetical order',
      sort_by_alphabetical_order: 'Sort by alphabetical order',
      sort_by_alphabetical_order_description:
        'Switching on will display categories & products in alphabetical order regardless of category positions set.',
      category_display_position: 'Category display & position',
      category_display_position_description:
        'Customise what categories to be displayed in POS application and in what order. Choose between alphabetical order or custom order.',
      category_display_order: 'Category display order',
      category_display_order_description:
        'Customise what categories to be displayed in POS application and in what order. Choose between alphabetical order or custom order.',
      add_ons_group_display_order: 'Add ons group display order',
      add_ons_group_display_order_description:
        'Select in which order add-on groups are displayed to when products with add-on options are sold.',
      arrange_category_visibility: 'Arrange category visibility',
      category_name: 'Category name',
      show_hide: 'Show / Hide',
      position: 'Position',
      receipt_settings: 'Receipt Settings',
      logo: 'Logo',
      logo_description:
        'Switching on will print your logo in customer receipt when printed if you have a logo updated in your business profile in Business Manager.',
      tax_summary: 'Tax summary',
      tax_summary_description:
        'Enable this option if you want receipt to include sales tax summary in customer receipts printed by default.',
      phone_number: 'Phone number',
      phone_numner_desctiption:
        'Switching on will print your store phone number in customer receipt when printed if you have a phone number added to your store profile.',
      cashier_name: 'Cashier name',
      cashier_name_description:
        'Switching on will print the name of the cashier in full who completed the order.',
      registration_number: 'Registration number',
      registration_number_description:
        'Switching on will print registration number in customer receipt if you have a registration number is updated in business settings.',
      website_address: 'Website address',
      website_address_description:
        'Switching on will print your website address in customer receipt if you have your website address updated in store profile.',
      qr_code: 'QR code',
      qr_code_description:
        'Switching on will print your logo in customer receipt when printed if you have a logo updated in your business profile in Business Manager.',
      address_type: 'Address type',
      address_type_description:
        'Your registered address will be printed in customer receipt. Click on update to print store address.',
      custom_message: 'Custom message',
      custom_message_description:
        'Your custom message is “{{message}}”. Click on update if you want to change it',
      custom_message_description_empty:
        'You do not have a custom message added. Add a greeting to your customer. ',
      print_promotion: 'Print promotion',
      print_promotion_description:
        'You have no promotions selected to print in your receipt. Click on select to pick a promotion to be printed in your receipt to encourage customers to visit again.',
      float_amount: 'float amount',
      amount: 'amount',
      lock_time: 'auto lock time in seconds',
      cash_drawer_alarm_timer: 'cash drawer alarm timer in seconds',
      select_a_promotion: 'Select a promotion',
      promotion_placeholder: 'Enter promotion name or product name',
      promotion_found: 'All promotions found!',
      loading: 'Loading...',
      select_address_type: 'Select address type',
      store_address: 'Store address',
      store_address_description:
        'Address of the store in which POS is used.',
      registered_address: 'Registered address',
      update_banner: 'Update banner',
      update_customer_display: 'Update customer display',
      print_promotion_not_empty:
        '"{{promotions}}", selected to print in your receipt. Click on update to change.',
      print_promotion_placeholder:
        'Enter promotion name or product name',
      no_promotion_found: 'No promotion found.',
    },
    touchpoint_kiosk: {
      title: 'Self Order Kiosk Touchpoint',
      general: 'General',
      order_types: 'Order types',
      order_types_description:
        'You are set to accept Collection & Delivery orders through Self Order Kiosks. Click on update to add or remove order types',
      payment_types: 'Payment types',
      payment_types_desciption:
        'You are set to accept card payment through all your self order kiosks.. Click on update to add or remove payment types.',
      update_payment_types_accepted: 'Update payment types accepted',
      call_to_action: 'Call to action',
      call_to_action_text: 'Call to action text',
      call_to_action_text_description:
        'Enter a call to action, not exceeding 150 characters to encourage visitors to start using your kiosks.',
      call_to_action_support_text: 'Call to action support text',
      call_to_action_support_text_description:
        'Enter a call to action, not exceeding 150 characters to encourage visitors to start using your kiosks.',
      welcome_text: 'Welcome text',
      welcome_text_required: 'Welcome text required',
      support_text: 'Support text',
      support_text_required: 'Support text required',
      banners: 'Banners',
      baneers_description:
        'Upload a banner that can be displayed to greet users when a kiosk is ready to accept orders. Recommended file size is size 1280 x 800 (Jpg / Png)',
      update_banner: 'Update banner',
      product_arrangement: 'Product Arrangement',
      group_products_by_category:
        'Group & display products by category',
      group_products_by_category_description:
        'Display products grouped by category. Disabling this option may display all selected products in a single page',
      sell_selected_products: 'Sell selected products',
      sell_selected_products_description:
        'Display only selected prducts from your inventroy in your kiosk. Switching this option off would display all products in your inventory.',
      display_in_alphabetical_order: 'Display in alphabetical order',
      display_in_alphabetical_order_description:
        'Display products grouped by category. Disabling this option may display all selected products in a single page',
      categories_position: 'Categories & position',
      categories_position_description:
        'Choose between Alphabetical order and custom order in which your products & categories (if grouped) displayed',
      receipt_settings: 'Receipt Settings',
      logo: 'Logo',
      logo_description:
        'Switching on will print your logo in customer receipt when printed if you have a logo updated in your business profile in Business Manager.',
      website_address: 'Website address',
      website_address_description:
        'Switching on will print your website address in customer receipt if you have your website address updated in store profile.',
      phone_number: 'Phone number',
      phone_number_description:
        'Switching on will print your store phone number in customer receipt when printed if you have a phone number added to your store profile.',
      address_type: 'Address type',
      address_type_description:
        'Your registered address will be printed in customer receipt. Click on update to print store address.',
      registration_number: 'Registration number',
      registration_number_description:
        'Switching on will print registration number in customer receipt if you have a registration number is updated in business settings.',
      print_promotion: 'Print promotion',
      print_promotion_description:
        'You have no promotions selected to print in your receipt. Click on select to pick a promotion to be printed in your receipt to encourage customers to visit again.',
      print_promotion_not_empty:
        '"{{promotions}}", selected to print in your receipt. Click on update to change.',
      print_promotion_placeholder:
        'Enter promotion name or product name',
      qr_code: 'QR Code',
      qr_code_description:
        'Switching on will print your logo in customer receipt when printed if you have a logo updated in your business profile in Business Manager.',
      custom_message: 'Custom message',
      custom_message_description:
        'Your custom message is “{{message}}”. Click on update if you want to change it',
      custom_message_description_empty:
        'You do not have a custom message added. Add a greeting to your customer. ',
    },
    touchpoint_storefront: {
      title: 'Digital Storefront Touchpoint',
      general: 'General',
      temporary_url: 'Temporary URL',
      payment_types: 'Payment types',
      payment_types_description:
        'You are set to accept card payment through all your digital fronts. Click on update to add or remove payment types.',
      order_types: 'Order types',
      order_types_description:
        'You are set to accept Collection & Delivery orders through your digital storefront. . Click on update to add or remove order types',
      update_order_types_accepted: 'Update order types accepted',
      product_arrangement: 'Product Arrangement',
      group_and_display_products_by_category:
        'Group & display products by category',
      group_and_display_products_by_category_description:
        'Display products grouped by category. Disabling this option may display all selected products in a single page',
      sell_selected_products: 'Sell selected products',
      sell_selected_products_description:
        'Display only selected prducts from your inventroy in your kiosk. Switching this option off would display all products in your inventory.',
      select_products: 'Select products',
      select_products_placeholder: 'Enter product name or barcode',
      loading: 'Loading...',
      all_product_found: 'All products found!',
      no_product_found: 'No product found.',
      product_name: 'Product name',
      show_hide: 'Show / Hide',
      position: 'Position',
      display_in_alphabetical_order: 'Display in alphabetical order',
      display_in_alphabetical_order_description:
        'Display products grouped by category. Disabling this option may display all selected products in a single page',
      categories_and_position: 'Categories & position',
      categories_and_position_desciption:
        'Choose between Alphabetical order and custom order in which your products & categories (if grouped) displayed',
      receipt_settings: 'Receipt Settings',
      logo: 'Logo',
      logo_description:
        'Switching on will print your logo in customer receipt when printed if you have a logo updated in your business profile in Business Manager.',
      website_address: 'Website address',
      website_address_description:
        'Switching on will print your website address in customer receipt if you have your website address updated in store profile.',
      phone_number: 'Phone number',
      phone_numner_desctiption:
        'Switching on will print your store phone number in customer receipt when printed if you have a phone number added to your store profile.',
      address_type: 'Address type',
      address_type_description:
        'Your registered address will be printed in customer receipt. Click on update to print store address.',
      registration_number: 'Registration number',
      registration_number_description:
        'Switching on will print registration number in customer receipt if you have a registration number is updated in business settings.',
      print_promotion: 'Print promotion',
      print_promotion_description:
        'You have no promotions selected to print in your receipt. Click on select to pick a promotion to be printed in your receipt to encourage customers to visit again.',
      print_promotion_not_empty:
        '"{{promotions}}", selected to print in your receipt. Click on update to change.',
      qr_code: 'QR code',
      qr_code_description:
        'Switching on will print your logo in customer receipt when printed if you have a logo updated in your business profile in Business Manager.',
      custom_message: 'Custom message',
      custom_message_description:
        'Your custom message is “{{message}}”. Click on update if you want to change it',
      custom_message_description_empty:
        'You do not have a custom message added. Add a greeting to your customer. ',
    },
  },
  addStore: {
    find_manage_your_bussiness: 'Find & Manage your business',
    cant_find_store: "Can't find your store?",
    add_store_manually: 'Add store manually',
    enter_bussiness_type:
      'Enter business type that best describes your business.',
    enter_bussiness_type_information:
      '  This gives you access to most relevant tools and customers find you if they are looking for businesses like yours.',
    whats_your_store_name: 'What is your store name?',
    whats_your_store_name_information:
      'This helps customers find your business in KRL and for us to identify your business.',
    add_store: 'Add Store',
    whats_your_store_address: 'What is your store address?',
    whats_your_store_address_information:
      ' This helps us setup your account with relevant records and customers find your store if they need to place an order or collect items.',
    street_number: 'Street number',
    building_name: 'Building name',
    floor_number: 'Floor number',
    unit: 'Unit number',
    street_name: 'Street name',
    city: 'City',
    postcode: 'Postcode',
    country: 'Country',
    adding_new_store: 'Adding new store',
  },
  workboard: {
    today: 'Today',
    yesterday: 'Yesterday',
    tomorrow: 'Tomorrow',
    week_to_date: 'Week to date',
    month_to_date: 'Month to date',
    select_dates: 'Select Dates',
    change_dates: 'Change',
    manage_store: {
      manage: 'Manage',
      manage_store: 'Manage Store',
      opening_hours: 'Opening Hours',
      delivery_slot: 'Delivery Slot',
      products_on_touchpoints: 'Products on Touchpoints',
      delivery_slots: {
        title: 'Manage product display',
        slots: 'Delivery Slot',
        max_orders: 'Max orders',
        delivering: 'Delivering',
        today: 'Today',
        tomorrow: 'Tomorrow',
        select_dates: 'Select Dates',
        slots_title: 'Slots',
        enter_max_order: 'Enter Max Orders for ',
        cancel: ' Cancel',
        update: 'Update',
        delivering_status: 'Delivery status',
        slots_description:
          'Manage delivery availability and no of orders you can deliver within each slot.',
        delivering_description:
          'Switch off If you are not accepting orders for delivery for the entire day. Shoppers will not be able to place orders for delivery for the day.',
      },
      touchpoints: {
        description:
          'Items selected will be available for your customers purchase from respective touchpoints. ' +
          'If you remove one of the items from a touchpoint temporarily, make sure to update again when you decide to list the items again.',
        search_description: 'Enter product name or barcode',
        all_categories: 'All categories',
        not_found: 'Products not found',
        update: 'Update',
        name: 'Product name',
        barcode: 'Barcode',
        category: 'Category',
        pos: 'POS',
        kiosk: 'Kiosk',
        storefront: 'Storefront',
        updated: 'Products updated',
      },
      opening_hours_dialog: {
        title: 'Opening Hours',
        add_holiday: '+ Add Holiday hours',
        opens_at: 'Opens at',
        closes_at: 'Closes at',
        opening_day: 'Day',
        add_hours: 'Add hours',
        closed: 'Closed',
        remove: 'Remove',
        cancel: 'Cancel',
        add: 'Add',
        success_updated: 'Opening hours updated successfully',
      },
    },
    quick_access: {
      quick_access: 'Quick Access',
    },
    run_reports: {
      reports: 'Reports',
      reports_description: 'Reports description',
      select_store: 'Select store',
      report: 'Report',
      business_overview: 'Business overview',
      business_snapshot: 'Business snapshot',
      report_type: 'Report type',
      daily_report: 'Daily report',
      weekly_report: 'Weekly report',
      monthly_report: 'Monthly report',
      quarterly_report: 'Quartely report',
      annual_report: 'Annual report',
      custom_report: 'Custom report',
      year: 'Year',
      select_period: 'Select period',
      print: 'PRINT',
      export_pdf: 'EXPORT PDF',
      export_csv: 'EXPORT CSV',
      today: 'Today',
      week_to_date: 'Week to date',
      month_to_date: 'Month to date',
      quarter_to_date: 'Quarter to date',
      future_time_alert: "Don't select time in future",
      transactions: 'TRANSACTIONS',
      no_of_orders: 'No of orders',
      atv: 'Avarage Transaction Value',
      sales_gross: 'SALES (Gross)',
      total_sales: 'Total Sales',
      sales: '{{payment}} Sales',
      expenses_gross: 'EXPENSES (Gross)',
      toal_expenses: 'TOTAL EXPENSES',
      tax_summary: 'TAX SUMMARY',
      product_summary: 'PRODUCT SUMMARY',
      no_of_items: 'No of items',
      net: 'Net',
      tax: 'Tax',
      gross: 'Gross',
      tax_on_sales: '{{name}} on Sales',
      tax_on_expenses: '{{name}} on Expenses',
    },
    add_stock: {
      add_stock: 'Add stock',
      barcode: 'Barcode',
      enter_barcode_to_add_stock:
        'Enter product name or barcode to find product to add stock.',
      current_stock: 'Current stock',
      new_stock_to_add: 'New stock to add in {{measure_type}}',
      click_here_to_adjust_current_stock: `<tag>Click here</tag> to adjust current stock if it's inaccurate.`,
      click_herer_to_add_new_stock:
        '<tag>Click here</tag> to add new stock.',
      reason: 'Reason',
      select_reason: 'Select reason',
      stock_added: 'Stock added',
      stock_adjusted: 'Stock adjusted',
    },
    replenish_stock: {
      replenish_stock: 'Replenish stock',
      select_from_to:
        'Select from and store premises to initiate replenishment.',
      move_from: 'Move from',
      move_to: 'Move to',
      select_location: 'Select location',
      replenish_description:
        'You are moving all of the items under one category to another. All of your connected touchpoints such as POS, Kiosk, Digital storefront will be updated.',
      replenish: 'Replenish',
      replenish_no: 'No of items to replenish',
    },
    trends: {
      revenue: 'Revenue',
      expenses: 'Expenses',
      atv: 'Average transaction value',
      basket_size: 'Basket size',
      number_of_transactions: 'Number of transactions',
    },
    product_sales_analysis: {
      gross_sales: 'Gross sales',
      no_items: 'No of items',
      product: 'Product',
      category: 'Category',
      barcode: 'Barcode',
      no_items_sold: 'No of items sold',
      net_sales: 'Net sales',
      taxes: 'Taxes',
    },
    performance_overview: {
      compare: 'Compare',
      select_store: 'Select store',
      total_sales: 'Total sales',
      atv: 'ATV',
      avg_basket_size: 'Avg Basket Size',
      not: 'NoT',
      items_sold: 'Items sold',
      football: 'Football',
      estate: 'Estate',
    },
    product_intelligence: {
      find_product:
        'Search & Find product to view insights & predictions.',
      enter_name_barcode: 'Enter product name or barcode',
      no_matching_products: 'No matching products found.',
      loading_products: 'Loading...',
      store: 'Store',
      product: 'Product',
      barcode: 'Barcode',
      gross_sales: 'Gross sales',
      net_sales: 'Net sales',
      tax_sales: 'Tax liability',
      no_items: 'No of items sold',
      not: 'NoT',
      atv: 'ATV',
      current_stock: 'Current stock',
      stockout: 'Stockout',
    },
    no_data: 'No data available.',
    drop_here_to_remove: 'Drop here to remove',
    widget_removed: '{{name}} removed',
    widget_added: '{{name}} added',
    drag_drop: 'Drag & drop',
  },
  globalWidgets: {
    insights_market_place: 'Insights market place',
    choose_widgets:
      'Pick & Choose widgets to build to customise Workboard.',
    all_widgets: 'All Widgets',
    all_available_data: 'All available data',
    performance_overview: 'Performance Overview',
    performance_overview_description:
      'Gives you a performance overview all KPIs that has a direct impact on your bottomline.',
    product_sales_analysis: 'Product Sales Analysis',
    product_sales_analysis_description:
      'Gives you access to performance intelligence of top selling & least selling products to help you increase their sales.',
    category_sales_analysis: 'Category Sales Analysis',
    category_sales_analysis_description:
      'Gives you a deeper understanding of the different factors that increase or decrease the demand for a product category.',
    trends: 'Trends',
    trends_description:
      'Measure performance of key performance indicators to understand trends and compare performance to improve performance over time.',
    stockout_prediction: 'Stockout Predictions',
    stockout_prediction_description:
      'Gives you access to powerful prediction about stockout to help you stock the right item at the right time and place to minimise loss of revenue.',
    employee_kpis: 'Employee KPIs',
    employee_kpis_description:
      'Gives you access to employee performance metrics to help you offer better training to help them improve their performance and satisfaction.',
    chart_of_accounts: 'Chart Of Accounts',
    chart_of_accounts_description: 'Chart of accounts description',
    product_intelligence: 'Product Intelligence',
    product_intelligence_description:
      'Gives you access to intelligence & comparison about the performance of a product sold.',
  },
  error: {
    title: 'Something went wrong ! Please try again later...',
    back_to_home: 'Back to home page',
  },
  image: {
    upload: 'Upload',
    library: 'Library',
    instagram: 'Instagram',
    dropbox: 'Drop box',
    upload_image: 'Upload image',
    browse: 'Browse.',
    drop_title: 'Drop your image here or',
    change_image: 'Change image',
    dimension_error:
      'At least {{width}} x {{height}} image size required',
  },

  userManagement: {
    users: {
      users: 'Users',
      business_manager: 'Business Mananger',
      user_management: 'User Management',
      search_placeholder: 'Enter user name or email or mobile',
      all_role: 'All roles',
      all_teams: 'All teams',
      add_new_user: 'Add New User',
      name: 'Name',
      teams: 'Teams',
      status: 'Status',
      last_login: 'Last login',
      all_users: 'All users',
      active_users: 'Active users',
      inactive_users: 'Inactive users',
      active: 'Active',
      invitation_sent: 'Invitation sent',
      resend_invitation: 'Resend invitation',
      cancel_invitation: 'Cancel invitation',
      invitation_canceled: 'Invitation canceled',
      loading: 'Loading...',
      all_users_found: 'All users found!',
      role_and_permissions: 'Role & Permissions',
      stores: 'Stores',
      licenses: 'Licenses',
      add_new_user_dialog_title: 'Add new user',
      update_user_dialog_title: 'Update user',
      invitation: 'Invitation',
      email: 'Email',
      email_placeholder: 'Enter valid email address',
      role: 'Role',
      permissions: 'Permissions',
      inventory: 'Inventory',
      orders: 'Orders',
      expenses: 'Expenses',
      select_team: 'Select team',
      next: 'Next',
      add_user: 'Add user',
      price_subscription:
        '{{time}} subscription - {{currency}} per {{unit}}',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      yearly: 'Annual',
      day: 'day',
      week: 'week',
      month: 'Month',
      year: 'year',
      or: 'OR',
      all_stores: 'All stores',
      notify_add_user_successfully: 'Invitation sent. Please wait.',
      email_required: 'Email is required!',
      team_required: 'Team is required!',
      email_invalid_format: 'Please check your email format!',
      update_team: 'Update team',
      update_role_and_permission: 'Update role & permissions',
      update_store_access: 'Update store access',
      update_license: 'Update license',
      block: 'Block',
      activate: 'Activate',
      user_team_updated: 'User team updated',
      no_user_found: 'No User Found!',
      block_user: '{{value}} blocked',
      pay: 'Pay',
    },
    applications: {
      search_placeholder:
        'Enter first name or last name or mobile number',
      applications: 'Applications',
      first_name: 'First name',
      last_name: 'Last name',
      mobile: 'Mobile',
      no_applications_found: 'No applications found.',
      view: 'View',
      email: 'Email',
      date_of_birth: 'Date of birth',
      role: 'Role',
      home_address: 'Home address',
      identifications: 'Identifications',
      selfie: 'Selfie',
      identity_card_license: 'Identity card / drivers license',
      questionaire: 'Questionaire',
      general_information: 'General Information',
      application_approved: 'Application approved',
      application_denied: 'Application denied',
    },
    archivedUsers: {
      activate_user: '{{value}} activated',
    },
    teams: {
      add_team: 'Add team',
      teams: 'Teams',
      name: 'Name',
      edit: 'Edit',
      delete: 'Delete',
      search_placeholder: 'Enter team name',
      teams_name_required: 'Team name is required!',
      new_team_added: 'New team added',
      add_teams_dialog_title: 'Enter team name',
      team_updated: 'Team updated',
      team_delete: 'Team deleted',
      no_team_found: 'No Team Found!',
    },
  },
  addressAutoComplete: {
    add_manually: 'Address not listed. Add manually.',
    placeholder: '150 Palace road, London, W1H 8HA',
    no_options_found: 'No options found',
  },

  invitation: {
    invite: {
      accept: 'Accept',
      decline: 'Decline',
      description:
        'Invited you to access their business information.',
      remind_later: 'Remind me later',
      accept_invitation_message: 'You now have access to {{name}}',
      decline_invitation_message: 'Invitation declined.',
    },
    accessMerchant: {
      textFooter: 'Karan Retail Ltd © {{year}}',
      accept: {
        sub_title:
          'Enter password to create account and get instant access to all the tools and information.',
        access_to_merchant: 'Access to {{name}}',
        first_name: 'First name',
        sur_name: 'Surname',
        password: 'Password',
        confirm_password: 'Confirm Password',
        first_name_required: 'First name is required!',
        sur_name_required: 'Surname is required!',
        password_required: 'Password is required!',
        confirm_password_required: 'Confirm Password is required!',
        access: 'Access',
        by_clicking_signup: 'By clicking Access, you agree to our ',
        terms_policy: 'Terms of use and Privacy Policy.',
        you_receive_sms_opt:
          'You may receive SMS notifications & email communications from us and you can opt out or manage preferences at any time.',
        password_mismatch: 'Password mismatched',
      },
      decline: {
        cancel: 'Cancel',
        decline: 'Decline',
        description: 'Are you sure to decline the invitation?',
        invitation_declined: 'Invitation Declined.',
        email_received: 'Email received without your consent? ',
        contact_us: 'Contact us',
        privacy_seriously:
          ' so that we can investigate further. We take your privacy seriously.',
      },
      expired: {
        invitation_expired: 'Invitation expired.',
        resend_invitation: 'Request merchant to resend invitation.',
      },
    },
  },
  notification: {
    title: 'Notifications',
    no_notifications: 'No notifications.',
  },
  help: {
    help: 'Help',
    start_tutorial: 'Start Tutorial',
  },

  add_product: {
    add_new_product: 'Add new product',
    update_product: 'Update product',
    basic_information: 'Basic information',
    stock_information: 'Stock information',
    images_descriptions: 'Images & descriptions',
    addon_options: 'Add-on options',
    recipe: 'Product builder',
    general_information: 'General information',
    barcode: 'Barcode - EAN / UPC / MPC',
    product_name: 'Product name',
    category: 'Category',
    measures: 'Measures',
    sold_individually: 'Sold individually',
    measured_in_kg: 'Measured in Kg',
    measured_in_g: 'Measured in grams',
    measured_in_m: 'Measured in meters',
    pricing: 'Pricing',
    price: 'Price',
    enter: 'Enter',
    add: 'Add',
    select: 'Select',
    barcode_required: 'Barcode required',
    product_name_required: 'Product name required',
    category_required: 'Category required',
    price_required: 'Price required',
    tax_required: 'Tax required',
    inner_items_num_required: 'Inner items required',
    inner_item_required: 'Inner item required',
    qty: 'Sold individually',
    gram: 'Measured in grams',
    kgram: 'Measured in Kg',
    pack: 'Pack',
    multi_pack: 'Multi pack',
    weighed_item: 'Weighed item',
    inner_item_num: 'Number of inner items',
    select_inner_item:
      'Enter product name or barcode to select inner item',
    add_product_success: 'Product added successfully',
    update_product_success: 'Product updated successfully',
    barcode_already_exist:
      'This bar_code already exists in your inventory.',
    locations: 'Locations',
    sku_number: 'SKU number',
    item_expires: 'Item expires',
    aisle: 'Aisle',
    section: 'Section',
    add_new_location: 'Add new location',
    aisle_and_shelf: 'Aisle & Shelf',
    floor: 'Floor',
    area: 'Area',
    shelf: 'Shelf',
    customer_access_permitted: 'Customer access permitted',
    manage_floor_shelf:
      'Manage floors, areas, aisles and shelves in ',
    modules: 'modules.',
    find_addon_group: 'Search & find add-on groups to add.',
    enter_addon_group_name: 'Enter add on group name',
    no_addon_groups_added: 'No Add-on groups added.',
    addons: 'add-ons',
    addon_description:
      'Drag and drop add-on groups to change the order in which they should be displayed in touchpoints. (POS, Kiosk, website, etc.) Rules changed in this page will override the add-on group rule for this product only. It will not change the add-on group’s rules.',
    multi_selection: 'Multi selection',
    default_all: 'Select all by default',
    limit_free_options: 'Limit free options',
    free_addons_no: 'No of free add-ons',
    remove: 'Remove',
    no_of_free_options: 'No of free add-ons',
    raw_material: 'This is a raw material and no sold.',
    description: 'Description',
    short_description: 'Short Description',
    characters_left: '{{character}} characters left',
    images: 'Images',
    input_description_here: 'You can input some descriptions here...',
    enter_product_ingredient_name:
      'Enter product or raw material name or barcode',
    find_inventory:
      'Search & find products and raw material used to produce this item',
    no_ingredients_added: 'No product builders added',
    allergies: 'Allergies',
    search_and_find_allergies:
      'Search & find allergies to add or add new.',
    no_allergies_added: 'No allergies added.',
    allergies_placeholder: 'Enter allergy name. Ex: Dairy',
    add_new: 'Add new',
    allergy_name: 'Allergy name',
    tooltip_for_made_to_order:
      'Select this option if the item is made when a customer places an order. Ex: Food, Furniture, Jewellery, etc..',
    tooltip_for_produced:
      'Select this option if the item is manufactured, stocked and sold by your company',
    tooltip_for_item_expires:
      'Select this option if the item has shelf life',
    made_to_order: 'Made to order',
    produced: 'Produced',
    style_variant: 'Style variant',
  },

  touchpointsManagement: {
    touchpoints: {
      title: 'Touchpoints',
      name: 'Name',
      type: 'Type',
      store: 'Store',
      add_new: 'Add new',
      add: 'Add',
      update: 'Update',
      all_stores: 'All stores',
      all_types: 'All types',
      search_placeholder: 'Enter touchpoints name',
      no_touchpoints_found: 'No Touchpoints Found!',
      archive: 'Archive',
      activate: 'Activate',
      add_new_touchpoint: 'Add New Touchpoint',
      touchpoint_name: 'Touchpoint name',
      touchpoint_type: 'Touchpoint type',
      touchpoint_name_required: 'Touchpoint name is required!',
      touchpoint_type_required: 'Touchpoint type is required!',
      touchpoint_store_required: 'Store is required!',
      new_touchpoint_added: '{{name}} added',
      touchpoint_updated: '{{name}} updated',
      touchpoint_archived: '{{name}} archived',
      touchpoint_activated: '{{name}} activated',
      update_touchpoint: 'Update Touchpoint',
    },
  },

  joy_rider: {
    next: 'Got it!',
    back: 'Back',
    close: ' Let’s start.',
    skip: 'Skip tutorial',
    welcome_myda: 'Welcome KRL Business Manager',
    start_connect:
      'Start connecting with your customers and suppliers, sell more and create a sustainable business.',
    simple_start: 'Getting started is simple.',
    quick_guide: 'Quick guide',
    start: {
      title: 'It all starts here.',
      content:
        'Click to access services and settings to get started.',
    },
    store_profile: {
      title: 'Manage store profile & Storefront',
      content:
        'You can manage your store address, contact details, opening hours, and customise digital storefront.',
    },
    inventory: {
      title: 'Add & Manage products',
      content:
        'You can add and manage products you sell, raw materials you use to produce goods, pricing, stock locations, and more. ',
    },
    orders: {
      title: 'orders title',
      content: 'orders content',
    },
    expenses: {
      title: 'expenses title',
      content: 'expenses content',
    },
    customers: {
      title: 'customers title',
      content: 'customers content',
    },
    user_management: {
      title: 'Invite & Manage staff ',
      content:
        'You can invite users to access your business tools and apps and manage their roles, permissions and licenses. ',
    },
    teams: {
      title: 'Create & Manage teams.',
      content:
        'You can create teams or departments and assign users to respective teams or departments. ',
    },
    settings: {
      title: 'Here you can customise ',
      content:
        'Manage and update critical business information and make apps and services to support your business need. ',
    },
    touchpoints: {
      title: 'Add & Manage touchpoints',
      content:
        'Here you can create and manage touchpoints such as POS, Kiosk & Digital storefront. You can select what product to sell where and configure touchpoints via Settings.  ',
    },
    run_report: {
      title: 'Business Reports',
      content:
        'You can run reports to understand your business performance such as daily sales, weekly sales, quarterly sales, and more. ',
    },
    add_widgets: {
      title: 'Customise your workboard.',
      content:
        'Here you can pick and choose widgets from the marketplace to build your workboard so that you can see what you want to see every time you log in. ',
    },
    store_select: {
      title: 'Switch to a different store or business.',
      content:
        'If you have access to more than one store or business, you can switch to a different store/region/business from here.',
    },
  },

  import: {
    title: 'Import',
    import_data: 'Import data',
    description:
      'Import your products, pricing, categories & description to KRL.',
    import_product: 'Import you products to KRL',
    upload_your_file: 'Upload your file',
    before_upload:
      'Before you upload your files below, make sure your file is ready to be imported. ',
    download_sample: 'Download sample file here.',
    drag_and_drop: 'Drag and drop or ',
    choose_file: 'choose a file ',
    from_your_computer: 'from your computer to upload your Products.',
    type_support:
      'All .csv, .xlsx, and .xls file types are supported.',
    map_column_property: 'Map columns and properties',
    map_column_property_description:
      'Each column from your file should be mapped to a Inventory property in KRL. Based on the names, some of it are already mapped.',
    change_manually:
      'You can change it as well as manually map anything that’s not been mapped automatically.',
    data_from_your_file: 'Data from your file',
    properties_in_myda: 'Properties in KRL',
    do_not_import_first_row: 'Do not import first row from my file',
    map_to: 'Map to',
    product_imported: 'Products imported.',
    file_type_invalid:
      'File is unsupported or corrupted. Upload a csv or xlsx or xls file.',
  },
  developer: {
    title: 'Developer',
    add_new_secret: 'ADD SECRET',
    add_new_secret_title: 'Enter application name',
    add_new_secret_description:
      'Enter name and click Add. Key will be generated and added to the list.',
    secret_keys_title: 'Secret Keys',
    secret_keys_description:
      'These keys will allow you to authenticate API requests by your own apps.',
    merchant_and_store_title: 'Merchant & Store IDs',
    merchant_and_store_description:
      'These ids uniquely identify your business and stores.',
    no_data_found: 'No Data Found',
    add: 'Add',
    revoke: 'Revoke',
    copy: 'Copy',
    revoke_item_title: 'Enter secret key name to revoke',
    revoke_item_description:
      'Applications that use this secret key will stop working immediately.',
    secret_revoked_successfully: 'secret revoked successfully',
    secret_created_successfully: 'secret created successfully',
    default_label: 'App name',
    key_copied: 'key copied to clipboard',
    merchant_id: 'Merchant ID',
    click_here: 'Click here',
    documentation:
      ' to access API documentation and get developer support.',
    already_exist: 'Name already exist',
  },
  orderTypeWorkflows: {
    order_type_and_workflows: 'Order types & Workflows',
    order_type: 'Order types',
    add_new: 'Add New',
    name: 'Name',
    type: 'Type',
    price_type: 'Price type',
    workflow: 'Workflow',
    update: 'Update',
    remove: 'Remove',
    order_type_name: 'Order type name',
    add_new_order_type: 'Add new order type',
    order_type_name_required: 'Order type name is required!',
    price_type_required: 'Price type is required!',
    workflow_required: 'Workflow is required',
    order_type_added: 'Order type added',
    update_order_type: 'Update order type',
    order_type_updated: 'Order type updated',
    order_type_removed: 'Order type removed',
    workflows: 'Workflows',
    configure: 'Configure',
    archive: 'Archive',
    no_order_type_found: 'No Order Type Found!',
    no_workflows_found: 'No Workflow Found!',
    complete_upon_payment: 'Complete upon payment',
  },
  addons: {
    no_matching_addon_groups_found: 'No matching addon groups found.',
    no_addon_groups_found: 'No addon groups found.',
    group_name: 'Group name',
    no_of_options: 'No of options',
    update_name_description: 'Update name & description',
    update_rules_options: 'Update rules & options',
    delete: 'Delete',
    name_description: 'Name & Description',
    rules_options: 'Rules & Options',
    short_description: 'Short Description',
    character_left: 'characters left',
    add: 'Add',
    group_name_required: 'Group name required',
    description_required: 'Short Description required',
    rules: 'Rules',
    make_it_mandatory: 'Make it mandatory',
    select_all_default: 'Select all by default',
    allow_multi_select: 'Allow multi select',
    limit_free_options: 'Limit free options',
    no_of_free_options: 'No of free options',
    find_product: 'Search & find product to add',
    qty: 'Qty',
    weight_g: 'Weight (g)',
    weight_kg: 'Weight (kg)',
    cm: 'Cm',
    inch: 'Inch',
    meter: 'M',
    yard: 'Yard',
    litre: 'Litre',
    ml: 'Ml',
    price: 'Price',
    max: 'Max',
    force_charge: 'Force charge',
    select_by_default: 'Select by default',
    remove: 'Remove',
    add_addon_success: 'Add-on added successfully',
    update_addon_success: 'Add-on updated successfully',
    add_one_addon: 'Add one add-on at least',
    group_deleted: 'Add-on group deleted',
  },
  subscriptions: {
    plans: 'Plans',
    plan_overview:
      'Each plan comes with options for you to pay Monthly or yearly. For permanent employees you can choose to pay yearly and for part time or temporary workers you can choose to pay monthly. All prices are inclusive of taxes where appropriate.',
    next_bill: 'Next Bill: ',
    next_payment_date: 'Next Payment date: ',
    payment_methods: 'Payment Method: ',
    payment_options: 'Payment options',
    cost: 'Cost',
    no_of_user_store: 'No of {{type}}',
    manage: 'Manage',
    ending_with: '(Ending with {{number}})',
    no_subscription_found: 'No Subscription Found!',
    select_payment_options: 'Select payment option',
    add: 'Add',
    payment_method_added: 'Payment method added',
    payment_method_title: 'Payment method',
    payment_methods_title: 'Payment methods',
    add_new: 'Add new',
    card_number: 'Card number',
    type: 'Type',
    delete: 'Delete',
    default: 'Default',
    set_as_default: 'Set as default',
    no_payment_methods_available: 'No payment methods available.',
    add_payment_method: 'Add Payment method',
    default_payment_method_changed: 'Default payment method changed',
    payment_method_delete: 'Payment method deleted',
    not_subscription_yet:
      'Licenses are optional. If license are added, you will only be invoiced when the invitation is accepted by the user.',
    pro_rated:
      'You will be charged a pro-rated fee of {{price}} today until your next billing date {{date}}',
  },
  modules: {
    title: 'Modules',
    module: 'Modules',
    status: 'Status',
    deactive: 'Deactive',
    configure: 'Configure',
    no_module_found: 'No Module Found!',
    inactive: 'Inactive',
    active: 'Active',
    module_activated: 'Module activated.',
    module_deactivated: 'Module deactivated.',
    customers: {
      customer_behaviour_management: 'Customer Behaviour Management',
      title: 'Customer Behaviour Management',
      add_new_segment: 'ADD NEW SEGMENT',
      add_new_segment_title: 'Add new segment',
      segment_created_successfully: 'segment created successfully',
      segment_updated_successfully: 'updated successfully',
      name: 'Segment Name',
      description: 'Short Description',
      characters: 'characters left',
      update: 'Update',
      delete: 'Delete',
      no_segment_found: 'Segment not found',
      update_segment_title: 'Update Segment',
      delete_segment_title: 'Enter Segment Name to Delete',
      segment_deleted_successfully: 'segment deleted successfully',
      rules: 'Rules',
      gender: 'Gender',
      frequency: 'Frequency',
      rulesDescription:
        'Customers who meet selected criteria will be allocated to this segment.',
    },
    floor_and_shelf: {
      floor_and_shelf_management: 'Floor & Shelf Management',
      floors: 'Floors',
      add_floor: 'Add Floor',
      areas: 'Areas',
      area: 'Area',
      floor: 'Floor',
      add_area: 'Add Area',
      shelves: 'Shelves',
      aisle: 'Aisle',
      shelf: 'Shelf',
      customer_access_permitted: 'Customer access permitted',
      add_new_location: 'Add new location',
      floor_added: 'Floor added',
      floor_deleted: 'Floor deleted',
      floor_name_required: 'Floor name is required!',
      floor_and_shelves_updated: 'Floor & shelves updated',
    },
    payment: {
      payment_types_accepted: 'Payment types accepted',
      select_payment_types:
        'Select payment types accepted in MK Store Ltd',
      payment_types_updated: 'Payment types updated',
    },
    delivery: {
      title: 'Delivery',
      local_deliveries: 'Local Deliveries',
      domestic_deliveries: 'Domestic Deliveries',
      international_deliveries: 'International Deliveries',
      general_information: 'General Information',
      general_information_description: `Delivery orders are accepted in hourly slots. <tag>Click here</tag> to manage delivery slots and maximum orders per slot.`,
      deliver_during: 'opening hours.',
      deliver_during_message: 'Deliver during',
      hourly_capacity: 'Hourly capacity: {{count}} per hour ',
      update: 'Update',
      cancel: 'Cancel',
      capacity_description:
        'This is the capacity for a normal day. You can change capacity for a specific day or slot.',
      local_platform_title: 'Your local ride community platform',
      rates: 'Rates',
      rates_description:
        `Rates are defined based on business type. Revenue share is directly paid to the riders and <tag>no commission</tag> charged by {{partner_name}}.` +
        'Revenue share percentages are fixed by {{partner_name}}. However you can, choose to pay more if you wish to.' +
        'Revenue share is payable on net order amount (Amount exclusive of taxes).',
      signing_description: `<click>Click here</click> to sign up and start delivering. By signing up to work with {{partner_name}}, you agree {{partner_name}}\'s <tag>terms and conditions.</tag>`,
      revenue_share: `Revenue share payable to riders <tag>{{percentage}} %</tag>`,
      dialog_hourly_title: 'Enter hourly capacity',
      dialog_hourly_description:
        'This is the number of maximum orders your store can process per hour.',
      dialog_revenue_title: 'Enter revenue share offered',
      dialog_revenue_description:
        'The minimum percentage offered is {{percentage}} %. You can choose to offer more.',
      revenue_updated_successfully: 'Revenue share rate updated',
      capacity_updated_successfully: 'Capacity updated',
      signed_successfully: 'Signed successfully',
    },
  },
  measures: {
    qty: 'Sold individually',
    g: 'Gram',
    kg: 'Kilogram',
    cm: 'Centimeter',
    in: 'Inch',
    m: 'Meter',
    yd: 'Yard',
    l: 'Litre',
    ml: 'Millimeter',
  },
  ingredients: {
    add_new_ingredient: 'Add new Raw Material',
    update_ingredient: 'Update Raw Material',
    update_ingredient_success: 'Update Raw Material success',
    add_ingredient_success: 'Add Raw Material success',
    delete: 'Delete',
    ingredient_deleted: 'Raw Material deleted',
    no_ingredients_found: 'No Raw Materials found',
    no_matching_ingredients_found: 'No matching Raw Materials found',
    product: 'Product',
    ingredient: 'Raw Material',
    ingredient_name: 'Raw Material name',
    ean_upc_mpc: 'EAN/UPC/MPC',
    basic_information: 'Basic information',
    stock_information: 'Stock information',
    current_stock: 'Current stock',
    made_to_order: 'Made to order',
    measure: 'Measure',
    units: 'units',
  },
  orders: {
    enter_order_no: 'Enter order number',
    all_orders_states: 'All orders states',
    pending: 'Pending',
    completed: 'Completed',
    voided: 'Voided',
    refunded: 'Refunded',
    accepted: 'Accepted',
    all_orders: 'All orders',
    paid: 'Paid',
    part_paid: 'Part paid',
    unpaid: 'Unpaid',
    all_users: 'All users',
    today: 'Today',
    yesterday: 'Yesterday',
    date_time: 'Date & Time',
    order_no: 'Order number',
    order_type: 'Order type',
    user: 'User',
    status: 'Status',
    order_value: 'Order value',
    void: 'Void',
    refund: 'Refund',
    no_name_added: 'No name added',
    no_orders: 'No orders.',
    refunded_order: 'Refunded order',
    voided_order: 'Voided order',
    no_action: 'No action',
    order_updated: 'Order updated',
  },
  expenses: {
    enter_payee_name: 'Enter payee name or invoice no',
    all_expenses: 'All expenses',
    paid: 'Paid',
    part_paid: 'Part paid',
    unpaid: 'Unpaid',
    all_status: 'All status',
    today: 'Today',
    date_time: 'Date & time',
    payee: 'Payee',
    status: 'Status',
    expense_type: 'Expense type',
    amount: 'Amount',
    payment_status: 'Payment status',
    no_expenses: 'No Expenses',
    add: 'Add',
    view_add: 'View / Add',
    void: 'Void',
    update: 'Update',
    draft: 'Draft',
    approved: 'Approved',
    goods_received: 'Goods Received',
    add_new_expense: 'Add new expense',
    update_expense: 'Update expense',
    from_payee: 'From (Payee)',
    invoice_no: 'Invoice no',
    reference: 'Reference',
    bill_date: 'Bill date',
    due_date: 'Due date',
    amounts_are: 'Amounts are',
    inclusive_taxes: 'Inclusive of taxes',
    exclusive_taxes: 'Exclusive of taxes',
    no_taxes: 'No taxes',
    add_files: 'Add Files',
    product_code: 'Product code',
    product_name_description: 'Product name & description',
    account: 'Account',
    qty: 'Qty',
    unit_price: 'Unit price',
    tax_rate: 'Tax rate',
    total: 'Total',
    add_line: 'Add a line',
    add_5_lines: 'Add 5 lines',
    add_10_lines: 'Add 10 lines',
    vat: 'VAT',
    sub_total: 'Sub total',
    payment_outstanding: 'Payment outstanding',
    add_payment_amount: 'Add payment amount',
    payment_date: 'Payment date',
    add_payment: 'Add payment',
    payee_required: 'Payee required',
    invoice_no_required: 'Invoice no required',
    reference_required: 'Reference required',
    bill_date_required: 'Invoice date required',
    due_date_required: 'Due date required',
    expense_type_required: 'Expense type required',
    status_required: 'Status required',
    payment_status_required: 'Payment status required',
    select: 'Select',
    expense_added: 'Expense added successfully',
    expense_updated: 'Expense updated successfully',
    expense_voided: 'Expense voided',
    save: 'Save',
    approve: 'Approve',
    add_new_product: 'Add new product',
    items_not_validated: 'Items not validated',
    paid_to: 'Paid to',
    select_account: 'Select account',
    payment_amount_exceeded: 'Payment amount exceeded',
    files: 'Files',
    upload_images_pdf:
      'Take a photo of your receipt & upload files. JPG, PNG & PDF are supported.',
    upload_file: 'Upload file',
    upload_more: 'Upload more',
    supported_file: 'JPG, PNG & PDF are supported',
    drop_title: 'Drop your files here or',
    browse: 'Browse',
    unsupported_file_type: 'Unsupported File type',
  },
  styles: {
    enter_style_name: 'Enter style name',
    all_genders: 'All genders',
    styles: 'Styles',
    style_name: 'Style name',
    code: 'Code',
    gender: 'Gender',
    tags: 'Tags',
    variants: 'Variants',
    no_style_variants: 'No style variants',
    no_colors: 'No colors',
    no_sizes: 'No sizes',
    add_new_style: 'Add New Style',
    update_style: 'Update Style',
    general_information: 'General information',
    style_name_required: 'Style name requried',
    code_required: 'Code required',
    gender_required: 'Gender required',
    add_new_variant: 'Add new variant',
    update: 'Update',
    archive: 'Archive',
    enter_unique_variant: 'Enter a unique variant name and code',
    variant_name: 'Variant name',
    variant_code: 'Variant code',
    style_add_success: 'Style added successfully',
    style_update_success: 'Style updated successfully',
  },
  stores: {
    store_nick_name: 'Store nick name',
    address: 'Address',
    view_storefront: 'View Storefront',
    no_stores: 'No Stores',
    nickname_required: 'Nick name is required',
    add_new_store: 'Add new store',
    find_your_store: 'Find your store or address to get started.',
    enter_store_name: 'Enter store name or address to find address',
    enter_store_address: 'Enter your store address',
    cant_find_store: 'Can’t find your store or address?',
    add_store_manually: 'Add address manually',
    enter_store_nickname: 'Enter a nick name for your new store.',
    nickname_not_visible:
      'Nicknames are not visible to your customers.',
    store_nickname: 'Store nick name',
    add_store: 'Add Store',
    store_added: 'Store added',
  },
  warehouses: {
    warehouse_name: 'Warehouse name',
    address: 'Address',
    view_storefront: 'View Storefront',
    no_stores: 'No Warehouses',
    nickname_required: 'Nick name is required',
    add_new_store: 'Add new warehouse',
    find_your_store: 'Find your warehouse address',
    enter_warehouse_address: 'Enter your warehouse address',
    enter_store_name: 'Enter store name or address to find address',
    cant_find_store: 'Can’t find your warehouse address?',
    add_store_manually: 'Add address manually',
    enter_store_nickname: 'Enter a name for your warehouse',
    nickname_not_visible:
      'Nicknames are not visible to your customers.',
    store_nickname: 'Warehouse name',
    add_store: 'Add Warehouse',
    store_added: 'Warehouse added',
  },
  changePassword: {
    title: 'Change Password',
    current_password: 'Current password',
    new_password: 'New password',
    current_password_is_required: 'Current password is required',
    new_password_is_required: 'New password is required',
    password_updated: 'Password updated',
  },
  promotions: {
    all_types: 'All types',
    all_promotions: 'All promotions',
    active_promotions: 'Active promotions',
    inactive_promotions: 'Inactive promotions',
    manual: 'Manual',
    promotion_name: 'Promotion name',
    promotion_type: 'Promotion type',
    expiry_date: 'Expiry date',
    status: 'Status',
    no_promotions_found: 'No promotions found',
    enter_promotion_name: 'Enter promotion name',
    until_further_notice: 'Until further notice',
    active: 'Active',
    inactive: 'Inactive',
    update_info: 'Update info',
    update_detail: 'Update detail',
    activate: 'Activate',
    pause: 'Pause',
    update_products: 'Update products',
    add_new_promotion: 'Add new promotion',
    update_promotion: 'Update promotion',
    select_promotion_type: 'Select promotion type',
    price_reduction: 'Price reduction',
    price_reduction_description:
      'Reduce price of a selected item for a period.',
    manual_promotion: 'Manual Promotion',
    manual_promotion_description:
      'Apply discount to entire order manually with promotion permission or automatically to selected categories.',
    bogof: 'BOGOF',
    bogof_description:
      'Offer one of the items free when number of items from products or categories selected.',
    multi_buy: 'Multi-Buy',
    multi_buy_description:
      'Offer number of items from selected products or categories for a fixed fee.',
    meal_deal: 'Meal Deal',
    meal_deal_description:
      'Offer fixed fee for items from three different group of product or categories.',
    add_reduced_price_promotion: 'Add reduced price promotion',
    enter_product_name:
      'Enter product name of barcode to find product to reduce price',
    select_valid_from_to: 'Select valid from and to date and time.',
    valid_from: 'Valid from date and time',
    valid_to: 'Valid to date and time',
    valid_until_further_notice:
      'Valid until further notice (promotion applied between selected hours during validity)',
    start_time: 'Start time',
    end_time: 'End time',
    add_manual_promotion: 'Add manual promotion',
    general_info: 'General info',
    enter_discount: 'Enter discount %',
    add_happy_hour: 'Add happy hour',
    select_categories: 'Select categories',
    select_categories_description:
      'If one or more categories selected, this promotion will be applied automatically.',
    add_mealdeal_promotion: 'Add meal deal promotion',
    add_multibuy_promotion: 'Add multi-buy promotion',
    add_bogof_promotion: 'Add BOGOF promotion',
    update_reduced_price_promotion: 'Update reduced price promotion',
    update_manual_promotion: 'Update manual promotion',
    update_mealdeal_promotion: 'Update meal deal promotion',
    update_multibuy_promotion: 'Update multi-buy promotion',
    update_bogof_promotion: 'Update BOGOF promotion',
    reduced_price: 'Reduced price',
    promotion_added: 'Promotion added',
    promotion_updated: 'Promotion updated',
    items: 'items',
    no_items_to_get_one_free: 'No of items to get one free',
    no_of_items: 'No of items',
    price: 'Price',
    mix_match: 'Mix and match',
    promotion_applied_to_categories:
      'This promotion will be applied to all items under selected categories.',
    select_products: 'Select Products',
    promotion_applied_to_items:
      'Select item/s for you want this promotion to apply.',
    meal_deal_price: 'Meal deal price',
    meal_deal_description:
      'Select categories / products to all three groups. Meal deal promotion will apply when a customer buys an item from all three groups.',
    product_group: 'Product Group {{number}}',
  },
  customers: {
    no_customers_found: 'You do not have any customers.',
    add_first_customer: 'Add your first customer.',
    add_new_customer: 'Add new customer',
    invalid_number: 'Invalid phone number',
    add: 'Add',
    cancel: 'Cancel',
    customer_created_successfully: 'Customer created successfully',
    enter_customer_details:
      'Enter first name or second name or email',
    all_segments: 'All Segments',
    customer_last_name: 'Last Name',
    customer_first_name: 'First Name',
    customer_email: 'Email',
    customer_mobile: 'Mobile',
    customer_segment: 'Segment',
    update_preference: 'Update Preference',
    archive: 'Archive',
    view: 'View',
    first_name: 'First name',
    last_name: 'Last name',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    mobile: 'Mobile number',
    email: 'Email address',
    enter_valid_email: 'Enter valid email address',
    terms_of_service: 'Terms of service',
    privacy_policy: 'Privacy Policy',
    and: 'and',
    agreement: 'By continuing you agree to our',
    deleted: 'deleted',
    loyalty: 'Loyalty',
    orders: 'Orders',
    general_information: 'General Information',
    no_data: 'No data.',
    na: 'N/A',
    close: 'Close',
    only_letters: 'Enter only letters',
  },
};

export default TRANSLATIONS_EN;
