import React from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash'

import Grid from '@material-ui/core/Grid';
import { Search } from '@material-ui/icons';
import TextFiled from '@/components/textField';
import { Colors } from '@/theme';
import Button from '@/components/button';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import useStyles from './styles';

const FormHeader = ({ onChangeSearch, onAdd }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Paper style={{ width: '100%' }}>
        <Grid
          container
          alignItems="flex-end"
          spacing={4}
          className={classes.paperContent}
        >
          <Grid item xs={12} sm={5} md={5} lg={7}>
            <TextFiled
              placeholder=""
              className={classes.searchInput}
              onChange={(e) => onChangeSearch(e.target.value)}
              startAdornment={
                <Search
                  style={{ color: Colors.GRAY_BORDER1 }}
                  width={18}
                  height={18}
                />
              }
            />
          </Grid>
          <Hidden xsDown>
            <Grid item sm={7} md={7} lg={5}>
              <Grid container justify="flex-end" style={{ paddingRight: 20 }}>
                <Grid item xs={4}>
                  <Button
                    className={classes.addButton}
                    color="primary"
                    variant="contained"
                    onClick={onAdd}
                  >
                    {t('button_group.add_new')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default FormHeader;
