import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { isEmpty, maxBy, find } from 'lodash'
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as Yup from 'yup';
import I18n from '@/i18n';
import { v4 as uuid } from 'uuid';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Box,
  Typography
} from '@material-ui/core'
import PrimaryButton from '@/components/button'
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import PayeeSelector from '../components/payeeSelector'
import Selector from '../components/selector'
import Input from '../components/input'
import DateInput from '../components/dateInput'
import ItemsTable from './table'
import AddUpdateProduct from '@/pages/businessManager/products/AddUpdateProduct'
import Payment from './payment'
import Modal from '@/components/modal';
import AddFiles from '../components/addFiles'

import { selectLocalisation } from '@/store/modules/store/selectors'

import {
  useQuery,
  useMutation,
  gql
} from '@apollo/client';
import {
  GET_PAYEES,
  GET_EXPENSE_TYPES,
  MUTATE_ADD_EXPENSE
} from '@/services/expenseService'
import { ACCOUNT_TYPES } from '@/services/storeService'
import { GET_TAXES } from '@/services/inventoryService'

import {
  EXPENSE_TAX_STATUS
} from '../helper'
import {
  initExpenseItem,
  getTotal,
  getSubTotal,
  getTaxTotal,
  getPaymentStatus,
  getTransformedExpense,
  validateItems,
  getCurrentItems,
  getOriginalItems,
  getItemsForMutation,
  generateVariables,
  getAccountsPaidTo
} from './helper'
import { EXPENSE_PAYEE_TYPE } from '@/constants/expense'
import useStyles from './styles';
import { getLocalisationVal } from '@/utils/localisationUtil';

const validationSchema = Yup.object().shape({
  payee: Yup.object()
    .required(I18n.t('expenses.payee_required'))
    .nullable(),
  order_no: Yup.string()
    .required(I18n.t('expenses.invoice_no_required')),
  bill_date: Yup.string()
    .required(I18n.t('expenses.bill_date_required')),
  due_date: Yup.string()
    .required(I18n.t('expenses.due_date_required')),
});

export default function ResponsiveDialog({
  open,
  handleClose,
  expense = null,
  onSuccess
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const localisation = useSelector(selectLocalisation)

  const [openAddProduct, setOpenAddProduct] = useState(false)
  const [openAddFiles, setOpenAddFiles] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);

  const [expenseData, setExpenseData] = useState({
    id: uuid(),
    expense_id: '',
    payee: {},
    order_no: '',
    bill_date: new Date(),
    due_date: new Date(),
    expense_type: {},
    status: {},
    tax_status: EXPENSE_TAX_STATUS.EXCLUSIVE_TAX,
    items: [initExpenseItem(1)], // we add one item by default
    payments: [],
    attachments: [],
  })

  const { data: payeesData, loading: isLoadingPayees } = useQuery(GET_PAYEES)
  const payees = payeesData?.payees || []

  const { data: expenseTypesData, loading: isLoadingExpenseTypes } = useQuery(GET_EXPENSE_TYPES, {
    fetchPolicy: 'no-cache'
  })
  const expenseTypes = expenseTypesData?.expenseTypes || []

  const { data: accountTypesData, loading: isLoadingAccountTypes } = useQuery(gql`${ACCOUNT_TYPES}`)
  const accountTypes = accountTypesData?.accountTypes || []
  const accounts = []
  accountTypes.filter(accountType => accountType.name === 'PAYMENTS').map((accountType) => {
    accountType?.children_types?.map((childrenType) => {
      childrenType?.accounts?.map((account) => {
        accounts.push(account);
      });
    });
  });

  const accountsPaidTo = getAccountsPaidTo(accountTypes)

  const { data: taxesData, loading: isLoadingTaxes } = useQuery(GET_TAXES)
  const taxes = taxesData?.store?.taxes || []

  const [addExpenseMutation, { loading: isUpdatingExpense }] = useMutation(MUTATE_ADD_EXPENSE, {
    onCompleted: (data) => {
      if (expense) {
        enqueueSnackbar(t('expenses.expense_updated'));
      } else {
        enqueueSnackbar(t('expenses.expense_added'));
      }
      onSuccess(data.addExpenses[0])
      handleClose()
    },
    onError: (error) => {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  })

  useEffect(() => {
    if (!isEmpty(expenseTypes)) {
      if (!expense) { // when add new expense, select first expense_type by default
        const expenseType = expenseTypes[0]
        setExpenseData({
          ...expenseData,
          expense_type: expenseType,
          status: expenseType.workflow.stages[0]
        })
      }
    }
  }, [expenseTypes.length])

  useEffect(() => {
    if (expense) {
      setExpenseData(getTransformedExpense(expense))
    }
  }, [expense])

  const updateExpenseData = (key, value) => {
    setExpenseData({
      ...expenseData,
      [key]: value,
    });
  };

  const onAddNewPayee = (payeeName) => {
    setExpenseData({
      ...expenseData,
      payee: {
        id: uuid(),
        name: payeeName,
        type: EXPENSE_PAYEE_TYPE.SUPPLIER
      }
    })
  }

  const onAddItems = (itemNum) => {
    const currentMaxOrder = parseInt(maxBy(expenseData.items, 'order')?.order) || 0
    const newItems = []
    for (var i = 0; i < itemNum; i++) {
      newItems.push(initExpenseItem(currentMaxOrder + i + 1))
    }
    setExpenseData({
      ...expenseData,
      items: [...expenseData.items, ...newItems]
    })
  }

  const onUpdateItem = (updatedItem) => {
    setExpenseData({
      ...expenseData,
      items: expenseData.items
        .map(item => item.item_id === updatedItem.item_id
          ? updatedItem
          : item
        )
    })
  }

  const onVoidItem = (item) => {
    setExpenseData({
      ...expenseData,
      items: expenseData.items
        .filter(_item => _item.item_id !== item.item_id)
    })
  }

  const onDragEnd = (result) => {
    const currentIndex = result.source.index;
    const targetIndex = result.destination.index;
    if (!result.destination) {
      return;
    }
    setExpenseData({
      ...expenseData,
      items: expenseData.items
        .map((item, index) => ({
          ...item,
          order: index === currentIndex
            ? expenseData.items[targetIndex].order
            : index === targetIndex
              ? expenseData.items[currentIndex].order
              : item.order,
        }))
    })
  }

  const totalTaxes = getTaxTotal(expenseData.items, expenseData.tax_status)

  const onAddUpdateExpense = (statusName, payment = null) => {
    const hasItemsValidated = validateItems(expenseData.items)
    if (!hasItemsValidated) {
      enqueueSnackbar(t('expenses.items_not_validated'), { variant: 'error' });
      return
    }
    const expenseType = find(expenseTypes, { id: expenseData.expense_type.id })
    const status = find(expenseType?.workflow?.stages, { name: statusName })

    const currentItems = getCurrentItems(expenseData)
    const originalItems = getOriginalItems(expense)
    const items = getItemsForMutation(currentItems, originalItems)
    const newPayments = [...expenseData.payments, payment].filter(item => !!item)

    var variables = generateVariables(
      { ...expenseData, payments: newPayments },
      items,
      status,
      payment
    )
    addExpenseMutation({
      variables
    })
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Formik
        initialValues={{
          payee: expenseData.payee,
          order_no: expenseData.order_no,
          bill_date: expenseData.bill_date,
          due_date: expenseData.due_date,
        }}
        validationSchema={validationSchema}
        onSubmit={() => { }}
        isInitialValid={!!expense}
      >
        {({
          handleBlur,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
          isValid,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
          >
            <DialogTitle id="alert-dialog-slide-title">
              <Grid
                container
                alignItems="center"
                justify="space-between"
                alignContent="center"
              >
                <Grid item xs={1} />
                <Grid className={classes.headerText} item xs={10}>
                    {expense
                      ? t('expenses.update_expense')
                      : t('expenses.add_new_expense')
                    }
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'right' }}>
                  <IconButton
                    onClick={handleClose}
                    className={classes.closeButton}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.rootDialog}>
                <Grid container spacing={10}>
                  <Grid item xs={12} sm={6} md={3}>
                    <PayeeSelector
                      name='payee'
                      title={t('expenses.from_payee')}
                      emptyLabel={t('add_product.add')}
                      items={payees}
                      getItemLabel={(payee) => payee.name}
                      selectedItem={expenseData.payee || {}}
                      onChangeValue={(payee) => {
                        if (!payee.isNewButton) {
                          updateExpenseData('payee', payee)
                          setFieldValue('payee', payee)
                        }
                      }}
                      onAddNewValue={(payeeName) => {
                        onAddNewPayee(payeeName)
                        setFieldValue('payee', { name: payeeName })
                      }}
                      onBlur={handleBlur}
                      error={touched.payee && !!errors.payee}
                      helperText={touched.payee && errors.payee}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Input
                      name='order_no'
                      title={t('expenses.invoice_no')}
                      emptyLabel={t('add_product.add')}
                      value={expenseData.order_no}
                      onChange={(value) => {
                        updateExpenseData('order_no', value)
                        setFieldValue('order_no', value)
                      }}
                      onBlur={handleBlur}
                      error={touched.order_no && !!errors.order_no}
                      helperText={touched.order_no && errors.order_no}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Typography
                      className={classes.titleText}
                    >
                      {t('expenses.reference')}
                    </Typography>
                    <Typography
                      className={classes.selectorLabel}
                    >
                      {t('add_product.add')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <DateInput
                      name='bill_date'
                      title={t('expenses.bill_date')}
                      emptyLabel={t('expenses.select')}
                      value={expenseData.bill_date}
                      onChange={(value) => {
                        updateExpenseData('bill_date', value)
                        setFieldValue('bill_date', value)
                      }}
                      onBlur={handleBlur}
                      error={touched.bill_date && !!errors.bill_date}
                      helperText={touched.bill_date && errors.bill_date}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <DateInput
                      name='due_date'
                      title={t('expenses.due_date')}
                      emptyLabel={t('expenses.select')}
                      value={expenseData.due_date}
                      onChange={(value) => {
                        updateExpenseData('due_date', value)
                        setFieldValue('due_date', value)
                      }}
                      onBlur={handleBlur}
                      error={touched.due_date && !!errors.due_date}
                      helperText={touched.due_date && errors.due_date}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={10}>
                  <Grid item xs={12} sm={6} md={2}>
                    <Typography
                      className={classes.titleText}
                    >
                      {t('expenses.status')}
                    </Typography>
                    <Typography
                      className={classes.selectorLabel}
                    >
                      {expenseData.status?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Typography
                      className={classes.titleText}
                    >
                      {t('expenses.payment_status')}
                    </Typography>
                    <Typography
                      className={classes.selectorLabel}
                    >
                      {getPaymentStatus(expenseData).name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Selector
                      title={t('expenses.amounts_are')}
                      emptyLabel={t('add_product.add')}
                      items={Object.keys(EXPENSE_TAX_STATUS).map(key => EXPENSE_TAX_STATUS[key])}
                      getItemLabel={(status) => status}
                      selectedItem={expenseData.tax_status || {}}
                      onChangeValue={(status) => {
                        updateExpenseData('tax_status', status)
                      }}
                      onBlur={() => { }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Box display='flex' justifyContent='flex-end'>
                      <PrimaryButton
                        className={classes.addButton}
                        color="primary"
                        variant="contained"
                        onClick={(event) => {
                          setOpenAddFiles(true)
                          setAnchorEl(event.currentTarget)
                        }}
                      >
                        {t('expenses.add_files')}
                      </PrimaryButton>
                    </Box>
                  </Grid>
                </Grid>

                <Box marginTop={10}>
                  <ItemsTable
                    taxStatus={expenseData.tax_status}
                    items={expenseData.items}
                    taxes={taxes}
                    accounts={accounts}
                    onAddItems={onAddItems}
                    onUpdateItem={onUpdateItem}
                    onVoidItem={onVoidItem}
                    onDragEnd={onDragEnd}
                    onAddNewProduct={() => setOpenAddProduct(true)}
                  />
                </Box>

                <Box className={classes.totalContainer}>
                  {!isEmpty(expenseData.items) &&
                    <Typography
                      style={{ marginBottom: 10 }}
                      align='right'
                      className={classes.label}
                    >
                      {`${t('expenses.sub_total')}  ${getLocalisationVal(localisation, getSubTotal(expenseData.items))}`}
                    </Typography>
                  }
                  {Object.keys(totalTaxes).map(taxName => (
                    <Typography
                      style={{ marginBottom: 10 }}
                      align='right'
                      className={classes.label}
                    >
                      {`${taxName}  ${getLocalisationVal(localisation, totalTaxes[taxName])}`}
                    </Typography>
                  ))}
                  {!isEmpty(expenseData.items) &&
                    <Typography
                      style={{ marginBottom: 10 }}
                      align='right'
                      className={classes.boldLabel}
                    >
                      {`${t('expenses.total')}  ${getLocalisationVal(localisation, getTotal(expenseData.items, expenseData.tax_status))}`}
                    </Typography>
                  }
                </Box>
              </div>
            </DialogContent>

            <Box className={classes.buttonsContainer}>
              <Box className={classes.buttonsInnerContainer}>
                <Grid container spacing={10}>
                  {expenseData.status?.order > 1 && getPaymentStatus(expenseData).state !== 'paid' &&
                    <Grid item sm={12}>
                      <Payment
                        expense={expenseData}
                        accountsPaidTo={accountsPaidTo}
                        localisation={localisation}
                        onAddPayment={onAddUpdateExpense}
                      />
                    </Grid>
                  }
                  <Grid item sm={12}>
                    {expenseData.status?.order > 1
                      ?
                      <Box display='flex' justifyContent='flex-end'>
                        <PrimaryButton
                          type="submit"
                          disabled={!isValid}
                          className={classes.addButton}
                          color="primary"
                          variant="contained"
                          onClick={() => onAddUpdateExpense(expenseData.status?.name)}
                        >
                          {t('button_group.update')}
                        </PrimaryButton>
                      </Box>
                      :
                      <Box display='flex' justifyContent='flex-end'>
                        <Box marginRight={10}>
                          <PrimaryButton
                            type="submit"
                            disabled={!isValid}
                            className={classes.addButton}
                            color="primary"
                            variant="contained"
                            onClick={() => onAddUpdateExpense('Draft')}
                          >
                            {t('expenses.save')}
                          </PrimaryButton>
                        </Box>
                        <Box>
                          <PrimaryButton
                            type="submit"
                            disabled={!isValid}
                            className={classes.addButton}
                            color="primary"
                            variant="contained"
                            onClick={() => onAddUpdateExpense('Approved')}
                          >
                            {t('expenses.approve')}
                          </PrimaryButton>
                        </Box>
                      </Box>
                    }
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        )}
      </Formik>

      {(isLoadingPayees ||
        isLoadingExpenseTypes ||
        isLoadingAccountTypes ||
        isUpdatingExpense ||
        isLoadingTaxes) && <PreLoader size={35} />
      }

      {openAddProduct && (
        <AddUpdateProduct
          open={openAddProduct}
          handleClose={() => setOpenAddProduct(false)}
          onSuccess={() => { }}
        />
      )}

      {openAddFiles &&
        <Modal
          openDialog={openAddFiles}
          handleClose={() => {
            setOpenAddFiles(false)
          }}
          anchorEl={anchorEl}
        >
          <AddFiles
            expense={expenseData}
            onUpdateFiles={(attachments) => {
              updateExpenseData('attachments', attachments)
            }}
          />
        </Modal>
      }
    </Dialog>
  );
}