import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { find, isEmpty } from 'lodash';
import Calendar from 'react-calendar';
import moment from 'moment';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Box, Popover,
  Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from '@apollo/client';
import { GET_STORE, GET_STORE_DELIVERY } from '@/services/deliveryService';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import { GET_STORE_OPENING } from '@/services/storeService';
import DeliverySlotsData from './table';

import useStyles from './styles';

export default function ResponsiveDialog({
                                           open,
                                           handleClose,
                                           defaultTabId = 0
                                         }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const anchor = useRef(null);
  const { width } = useWindowDimensions();
  const count = Math.floor(width / 230);

  const tabsDateFormat = 'DD MMM YYYY';
  const valueDateFormat = 'YYYY-MM-DD';
  const nullTimeZone = 'T00:00:00.000Z';

  let defaultTabs = [
    {
      id: 0,
      label: t('workboard.today'),
      value: moment().add('day').format(valueDateFormat) + nullTimeZone
    },
    {
      id: 1,
      label: t('workboard.tomorrow'),
      value: moment().add(1, 'day').format(valueDateFormat) + nullTimeZone
    }
  ];


  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [calendarValue, setCalendarValue] = useState(false);

  let dateRangeValuesDefault = [{
    id: 0,
    label: moment(dateRange[0]).format(tabsDateFormat),
    value: moment(dateRange[0]).utc().format(valueDateFormat) + nullTimeZone
  }];

  const [tabId, setTabId] = useState(defaultTabId);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [tabs, setTabs] = useState(defaultTabs);

  const [openings, setOpenings] = useState({});
  const [capacity, setCapacity] = useState('');

  const { data, loading: loadingSlots, refetch } = useQuery(GET_STORE_DELIVERY, {
    fetchPolicy: 'no-cache'
  });
  useQuery(GET_STORE_OPENING, {
    onCompleted: (openings) => {
      setOpenings(openings?.store?.store_openings);
    },
    fetchPolicy: 'no-cache'
  });
  useQuery(GET_STORE, {
    onCompleted: (store) => {
      setCapacity(store?.store?.settings?.delivery?.hourly_capacity);
    },
    fetchPolicy: 'no-cache'
  });

  const dateSlots = data?.store?.settings?.delivery?.day_slots;

  const slotsData = (item) => {
    let slots = find(dateSlots, ['date', item.value]);
    if (!slots) {
      const storeHourlyCapacity = capacity;
      const storeOpenings = find(openings, function(o) {
        return o.date === item.value || o.day === moment(item.value).format('dddd');
      });
      slots = {
        date: item?.value,
        disabled: storeOpenings?.closed,
        slots: storeOpenings?.opening_times.map((elem) => {
            return {
              start: elem.open,
              end: elem.close,
              disabled: storeOpenings?.closed,
              max_orders: storeHourlyCapacity
            };
          }
        )
      };
    }
    return slots;
  };

  useMemo(() => {
    if (calendarValue) {
      const rangeCount = new Date(dateRange[1] - dateRange[0]).getDate() - 1;
      const tabCount = count + 1 <= rangeCount ? count : rangeCount;
      let dateRangeValues = [];

      for (let i = 0; i < tabCount; i++) {
        dateRangeValues = [...dateRangeValues, {
          id: i,
          label: moment(dateRange[0]).add(i, 'day').format(tabsDateFormat),
          value: moment(dateRange[0]).add(i, 'day').format(valueDateFormat) + nullTimeZone
        }];
      }

      const values = isEmpty(dateRangeValues) ? dateRangeValuesDefault : dateRangeValues;
      setTabs(values);
    } else {
      for (let i = 0; i < count - 2; i++) {
        defaultTabs = [...defaultTabs, {
          id: i + 2,
          label: moment().add(i + 2, 'day').format(tabsDateFormat),
          value: moment().add(i + 2, 'day').format(valueDateFormat) + nullTimeZone
        }];
      }
      setTabs(defaultTabs);
    }
  }, [width, tabId, openCalendar, dateRange]);


  const onChangeTab = (event, value) => {
    setTabId(value);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          alignContent="center"
        >
          <Grid item xs={1} />
          <Grid className={classes.headerText} item xs={10}>
            {t('workboard.manage_store.delivery_slots.title')}
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            <IconButton
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.rootDialog}>
          <Tabs
            className={classes.tabs}
            value={tabId}
            onChange={onChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            scrollButtons="off"
          >
            {tabs.map((item, key) => (
              <Tab
                className={classes.tab}
                style={{ fontSize: 18 }}
                key={`tab-${key}`}
                label={item.label}
              />
            ))}
          </Tabs>
          <Button
            color={calendarValue ? 'primary' : 'textColor'}
            className={classes.changeDates}
            onClick={() => {
              setOpenCalendar(true);
              setCalendarValue(true);
            }}>{calendarValue ? t('workboard.change_dates') : t('workboard.select_dates')}</Button>
          {openCalendar && (
            <Popover
              id='date-popover'
              open={openCalendar}
              anchorEl={anchor.current}
              onClose={() => setOpenCalendar(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <Calendar
                value={dateRange}
                onChange={(value) => {
                  setDateRange(value);
                }}
                showDoubleView
                selectRange
              />
            </Popover>)}
          {tabs.map((item, index) => {
              return <TabPanel value={tabId} index={index}>
                <DeliverySlotsData dataSlots={dateSlots} slots={slotsData(item)} isLoadingSlots={loadingSlots}
                                   setRefetch={() => refetch()} />
              </TabPanel>;
            }
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ paddingTop: 40 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};