import React from 'react'

import {
  Paper,
  Table,
  TableBody,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';

import TableHeader from './tableHeader'
import TableRow from './tableRow'

import useStyles from './styles'

const TableComponent = (props) => {
  const classes = useStyles()
  const { 
    styles, 
    isLoadingStyles, 
    currentPage, 
    onClickVoid, 
    onClickUpdate
  } = props

  return (
    <Table>
      {isLoadingStyles && currentPage === 1
        ?
        <div>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </div>
        :
        <>
          <TableHeader />
          <TableBody>
            {styles.map((item) => (
              <TableRow
                key={item.id}
                item={item}
                onVoidStyle={onClickVoid}
                onUpdateStyle={onClickUpdate}
              />
            ))}
          </TableBody>
        </>
      }
    </Table>
  )
}

export default TableComponent