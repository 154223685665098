import React from 'react'
import { useTranslation } from 'react-i18next';

import {
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Grid,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

import { PROMOTION_TYPE } from '@/constants/promotion'
import useStyles from './styles';

const SelectPromotionType = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { handleClose, onSelectPromotionType } = props

  const promotionTypes = [
    {
      type: PROMOTION_TYPE.REDUCED_PRICE,
      name: t('promotions.price_reduction'),
      description: t('promotions.price_reduction_description'),
    },
    {
      type: PROMOTION_TYPE.MANUAL,
      name: t('promotions.manual_promotion'),
      description: t('promotions.manual_promotion_description'),
    },
    {
      type: PROMOTION_TYPE.BOGOF,
      name: t('promotions.bogof'),
      description: t('promotions.bogof_description'),
    },
    {
      type: PROMOTION_TYPE.MULTI_BUY,
      name: t('promotions.multi_buy'),
      description: t('promotions.multi_buy_description'),
    },
    {
      type: PROMOTION_TYPE.MEAL_DEAL,
      name: t('promotions.meal_deal'),
      description: t('promotions.meal_deal_description'),
    },
  ]

  return (
    <Box>
      <DialogTitle id="alert-dialog-slide-title">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          alignContent="center"
        >
          <Grid item xs={1} />
          <Grid className={classes.headerText} item xs={10}>
              {t('promotions.select_promotion_type')}
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            <IconButton
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {promotionTypes.map((promotionType, id) => (
          <Box
            key={`promotion-${id}`}
            style={{ cursor: 'pointer' }}
            marginTop={5}
            onClick={() => onSelectPromotionType(promotionType.type)}
          >
            <Typography className={classes.boldLabel}>
              {promotionType.name}
            </Typography>
            <Typography className={classes.label}>
              {promotionType.description}
            </Typography>
          </Box>
        ))}
      </DialogContent>
    </Box>
  )
}

export default SelectPromotionType