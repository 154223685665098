import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors, CommonFonts } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'relative',
      maxWidth: 1200,
      margin: 'auto',
      border: '1px solid #BAC3C9',
      borderRadius: 4,
      padding: theme.spacing(12),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    titleText: {
      ...CommonFonts.darkFont16,
      fontWeight: 700,
      textAlign: 'center'
    },
    storeNameText: {
      ...CommonFonts.darkFont16,
      textAlign: 'center',
    },
    accountTypeContainer: {
      marginTop: theme.spacing(12)
    },
    childrenTypeContainer: {
      marginTop: theme.spacing(7),
    },
    childrenTypeWrapper: {
      paddingRight: theme.spacing(60),
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(2),
      },
    },
    accountWrapper: {
      marginTop: theme.spacing(5),
      padding: theme.spacing(0, 90, 0, 7),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 4, 0, 4),
      },
    }
  })
)

export default useStyles
