import React from 'react';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import MerchantActions from '@/store/modules/store/actions';

import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import images from '@/assets/images'

const ListStores = ({
  // groupedByRegion,
  handleCloseStorePopover,
  stores = [],
  selectedMerchant,
  withAllStoresOption,
  onOpenAddWarehouse
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleListItemClick = (store) => {
    dispatch(MerchantActions.setSelectedRegion(null));
    dispatch(MerchantActions.setSelectedStore(store));
    dispatch(MerchantActions.setCurrentMerchant(selectedMerchant));
    handleCloseStorePopover();
  };

  const handleRegionItemClick = (region) => {
    dispatch(MerchantActions.setSelectedRegion(region));
    dispatch(MerchantActions.setCurrentMerchant(selectedMerchant));
    dispatch(MerchantActions.setSelectedStore(stores?.[0]));
    handleCloseStorePopover();
  }

  const storesWithTypeStore = stores.filter(store => store.type === 'store')
  const warehouses = stores.filter(store => store.type === 'warehouse')

  // group stores by region
  const groupedByRegion = _.groupBy(
    storesWithTypeStore,
    (store) => store?.region?.id
  );

  return (
    <List component="nav" aria-label="contacts" className={classes.listItem}>
      <span style={{ fontWeight: 500, marginLeft: 15 }}>
        {t('header.stores')}
      </span>
      {withAllStoresOption &&
        <ListItem
          button
          style={{
            paddingTop: 24,
            paddingBottom: 24,
          }}
          onClick={() => handleRegionItemClick({
            id: '-1',
            name: `${t('header.all_stores')} (${selectedMerchant.lname})`
          })}
        >
          <ListItemText
            primary={`${t('header.all_stores')} (${selectedMerchant.lname})`}
            style={{ marginLeft: 15 }}
          />
        </ListItem>
      }
      {Object.keys(groupedByRegion).map((regionId, index) => (
        <>
          {regionId === 'undefined' ? (
            <React.Fragment key={`${regionId}-${index}`}>
              {groupedByRegion[regionId].map((store) => (
                <ListItem
                  key={`${store?.id}-${index}`}
                  button
                  style={{
                    paddingTop: 24,
                    paddingBottom: 24,
                    paddingLeft: 30
                  }}
                  onClick={() => handleListItemClick(store)}
                >
                  <ListItemIcon>
                    <img src={images.store} width={20} height={20} alt='store' />
                  </ListItemIcon>
                  <ListItemText
                    primary={store.name}
                    style={{ marginLeft: '-15px' }}
                  />
                </ListItem>
              ))}
            </React.Fragment>
          ) : (
            <React.Fragment key={`${regionId}-${index}`}>
              <ListItem
                button
                style={{
                  paddingTop: 24,
                  paddingBottom: 24,
                }}
                onClick={() => withAllStoresOption
                  ?
                  handleRegionItemClick({
                    id: regionId,
                    name: groupedByRegion[regionId][0]?.region?.region_name
                  })
                  :
                  {}
                }
              >
                <ListItemText
                  primary={groupedByRegion[regionId][0]?.region?.region_name}
                  style={{ marginLeft: 15, cursor: 'default' }}
                />
              </ListItem>
              {groupedByRegion[regionId].map((store) => (
                <div key={store?.id}>
                  <ListItem
                    button
                    onClick={() => handleListItemClick(store)}
                    style={{
                      paddingTop: 24,
                      paddingBottom: 24,
                      paddingLeft: 30
                    }}
                  >
                    <ListItemIcon>
                      <img src={images.store} width={20} height={20} alt='store' />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        marginLeft: '-15px',
                      }}
                      primary={store.name}
                    />
                  </ListItem>
                </div>
              ))}
            </React.Fragment>
          )}
        </>
      ))}
      <div
        style={{
          fontWeight: 500,
          marginLeft: 15,
          marginTop: 15
        }}
      >
        {t('header.warehouses')}
      </div>
      {warehouses.map(warehouse => (
        <ListItem
          key={`${warehouse?.id}`}
          button
          style={{
            paddingTop: 24,
            paddingBottom: 24,
            paddingLeft: 30
          }}
          onClick={() => handleListItemClick(warehouse)}
        >
          <ListItemIcon>
            <img src={images.wareHouses} width={20} height={20} alt='warehouse' />
          </ListItemIcon>
          <ListItemText
            primary={warehouse.name}
            style={{ marginLeft: '-15px' }}
          />
        </ListItem>
      ))}
      {isEmpty(warehouses) &&
        <Box paddingY={5}>
          <Typography align='center' className={classes.noWarehouses}>
            {t('header.no_warehouse_available')}
          </Typography>
          <Box display='flex' justifyContent='center' marginTop={2}>
            <Button
              color="primary"
              className={classes.addMerchantButton}
              onClick={() => {
                onOpenAddWarehouse(selectedMerchant.id)
                handleCloseStorePopover()
              }}
            >
              {t('header.add_warehouse')}
            </Button>
          </Box>
        </Box>
      }
    </List>
  );
};

ListStores.propTypes = {
  groupedByRegion: PropTypes.object,
  handleCloseStorePopover: PropTypes.func,
  withAllStoresOption: PropTypes.bool
};

export default ListStores;
