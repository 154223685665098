import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@/components/button';
import Grid from '@material-ui/core/Grid';
import AddressesAutocomplete from '@/components/addressAutocomplete';

import useStyles from '../styles';

const StoresLayout = ({
  setStoreAddressValue,
  storeAddressValue,
  setActiveStep,
  setAddressObject,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const checkForMissingValues = (values) => {
    if (
      values.door_number === undefined ||
      values.line1 === undefined ||
      values.city_town === undefined ||
      values.postcode === undefined
    ) {
      //send user to the full address form
      setAddressObject(values);
      setActiveStep(3);
    } else {
      //update address object and conitnue flow normally
      setAddressObject(values);
    }

    console.log('🔥🔥 ~ checkForMissingValues ~ values', values);
  };
  return (
    <Grid
      container
      className={classes.addNewStore}
      spacing={10}
      justify="center"
    >
      <Grid
        container
        justify="flex-start"
        spacing={4}
        style={{ paddingTop: 30 }}
      >
        <Grid item>
          <div className={classes.findYourBussinessTitle}>
            {t('addStore.whats_your_store_address')}
          </div>
        </Grid>
        <Grid item>
          <div className={classes.descriptionSecondStep}>
            {t('addStore.whats_your_store_address_information')}
          </div>
        </Grid>
      </Grid>

      <Grid container justify="center" style={{ paddingTop: 50 }}>
        <Grid item xs={12}>
          <AddressesAutocomplete
            getFullAdress={(value) => setStoreAddressValue(value)}
            getValues={(value) => checkForMissingValues(value)}
            noOptionsButton={() => {
              setActiveStep(3);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justify="center" style={{ paddingTop: 50 }}>
        <Grid item>
          <div style={{ textAlign: 'center' }}>
            By continuing you agree to KRL{' '}
            <span className={classes.addStoreLink}>
              Terms of Service
            </span>{' '}
            and{' '}
            <span className={classes.addStoreLink}>
              Privacy Policy.
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ padding: '20px 0' }}>
        <Button
          color="primary"
          className={classes.addStoreButton}
          variant="contained"
          onClick={() => setActiveStep(4)}
          disabled={storeAddressValue === ''}
        >
          {t('addStore.add_store')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default StoresLayout;
