import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, TextField } from '@material-ui/core';
import useStyles from './styles';
import ImagePicker from '@/components/imagePicker';
import Editor from 'mui-rte';
import { stateToHTML } from 'draft-js-export-html';
import {
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';

const defaultTheme = createTheme();
Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        border: '1px solid #939da8',
        padding: '12px',
        borderRadius: '4px',
        minHeight: '175px',
      },
      toolbar: {
        textAlign: 'center',
      },
      editor: {
        marginLeft: '15px',
      },
    },
  },
});

const ImageDescription = (props) => {
  const { productData, updateProductData } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [plainDescription, setPlainDescription] = useState('');
  const [description, setDescription] = useState('');

  const onChangeImage = (currentId, value, imageProperty) => {
    const { id } = value || {};
    const images = productData?.images;
    const result =
      currentId !== ''
        ? images?.map((item) => ({
            ...item,
            id: item.id === currentId ? id : item.id,
            url: item.id === currentId ? imageProperty : item.url,
          }))
        : [
            ...images.map((item) => ({
              ...item,
              id: item.id,
              url: item.url,
            })),
            { id: id, url: imageProperty },
          ];
    updateProductData('images', result);
  };

  const onDeleteImage = (image) => {
    const result = productData?.images?.map((item) =>
      _.isEqual(item.id, image.id)
        ? {
            ...item,
            isDelete: true,
          }
        : item
    );
    updateProductData('images', result);
  };

  const onChangeEditor = (event) => {
    const currentContent = event.getCurrentContent();
    setPlainDescription(currentContent.getPlainText());
    setDescription(stateToHTML(currentContent));
  };

  const onBlurEditor = () =>
    updateProductData('description', description);

  const isJson = (item) => {
    let result = true;
    try {
      JSON.parse(item);
    } catch (e) {
      result = false;
    }
    return result;
  };

  return (
    <Box>
      <Box marginTop={10}>
        <Typography className={classes.titleText}>
          {t('add_product.images')}
        </Typography>
        <Box marginTop={5} alignItems="center" display="flex">
          {productData?.images
            ?.filter((item) => !item?.isDelete)
            ?.sort(
              (a, b) => moment(a?.created_at) - moment(b?.created_at)
            )
            ?.map((image) => (
              <Box marginRight={3} key={image.id}>
                <ImagePicker
                  widthCrop={600}
                  heightCrop={500}
                  id={image?.id || ''}
                  name={`productImage-${image?.id}`}
                  thumbnail={image?.url || ''}
                  onChange={(value, imageProperty) =>
                    onChangeImage(
                      image?.id || '',
                      value,
                      imageProperty
                    )
                  }
                  isShowDelete
                  onDelete={() => onDeleteImage(image)}
                />
              </Box>
            ))}
          {productData?.images?.length < 5 && (
            <ImagePicker
              widthCrop={600}
              heightCrop={500}
              key={productData?.images}
              name="newProductImage"
              thumbnail={''}
              onChange={(value, imageProperty) =>
                onChangeImage('', value, imageProperty)
              }
            />
          )}
        </Box>
      </Box>
      <Box marginTop={10}>
        <Typography className={classes.titleText}>
          {t('add_product.short_description')}
        </Typography>
        <Typography className={classes.characterLeftText}>
          {t('add_product.characters_left', {
            character:
              250 - (productData?.short_description?.length || 0),
          })}
        </Typography>
        <TextField
          name="shortDescription"
          className={classes.shortDescriptionField}
          fullWidth
          multiline
          value={productData?.short_description}
          onChange={(e) =>
            updateProductData('short_description', e.target.value)
          }
          inputProps={{ maxLength: 250 }}
        />
      </Box>
      <Box marginTop={10}>
        <Typography className={classes.titleText}>
          {t('add_product.description')}
        </Typography>
        <Typography className={classes.characterLeftText}>
          {t('add_product.characters_left', {
            character: 2500 - plainDescription?.length,
          })}
        </Typography>
        <MuiThemeProvider theme={defaultTheme}>
          <Editor
            id={productData.id}
            defaultValue={productData.descriptionString}
            controls={[
              'bold',
              'italic',
              'underline',
              'numberList',
              'bulletList',
            ]}
            label={t('add_product.input_description_here')}
            maxLength={2500}
            onChange={(event) => onChangeEditor(event)}
            onBlur={() => onBlurEditor()}
          />
        </MuiThemeProvider>
      </Box>
    </Box>
  );
};

export default ImageDescription;
