import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  Box,
  Breadcrumbs,
  Link,
  Grid,
  Paper,
  List,
  ListItem,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import { Link as RouterLink } from 'react-router-dom';
import AppLayout from '@/components/appLayout';
import SearchHeader from './components/SearchHeader'
import NoAddonGroups from './components/NoAddonGroups'
import ActionMenu from './components/ActionMenu'
import AddUpdateAddonGroup from './addUpdateAddonGroup'
import PreLoader from '@/components/preLoader';

import { useQuery, useMutation } from '@apollo/client';
import { GET_ADDON_GROUPS, REMOVE_ADDON_GROUP } from '@/services/inventoryService';

import { contains } from '@/utils/stringUtil'
import { Colors } from '@/theme'
import useStyles from './styles';

const Addons = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [inputSearch, setInputSearch] = useState("");
  const [openAddonModal, setOpenAddonModal] = useState(false)
  const [tabIdForGroupUpdate, setTabIdForGroupUpdate] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(null);


  const { loading: isLoadingAddonGroups, data: addonGroupsData, refetch: refetchAddonGroups } = useQuery(GET_ADDON_GROUPS, {
    fetchPolicy: 'no-cache'
  })

  const addonGroups = addonGroupsData?.addonGroups || []
  const filteredAddonGroups = addonGroups.filter(group => !!contains(group.group, inputSearch))

  const [removeAddonGroupMutation, { loading: isRemovingAddonGroup }] = useMutation(REMOVE_ADDON_GROUP, {
    onCompleted: (data) => {
      enqueueSnackbar(t('addons.group_deleted'))
      refetchAddonGroups()
    },
    onError: (error) => {
    }
  })

  const actionMenus = [
    {
      name: t('addons.update_name_description'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        setSelectedGroup(data)
        setTabIdForGroupUpdate(0)
        setOpenAddonModal(true)
      }
    },
    {
      name: t('addons.update_rules_options'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        setSelectedGroup(data)
        setTabIdForGroupUpdate(1)
        setOpenAddonModal(true)
      }
    },
    {
      name: t('addons.delete'),
      color: Colors.RED,
      onClick: (data) => {
        removeAddonGroupMutation({
          variables: {
            input: {
              id: data.id,
              flag: 'delete'
            }
          }
        })
      }
    }
  ]

  return (
    <AppLayout
      className={classes.appLayout}
      withFooter
      header
      withServiceDropdown={true}
    >
      <div className={classes.root}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                className={classes.breadcrumbsText}
                color="primary"
                component={RouterLink}
                to="/business-manager"
              >
                {t('settings.business_manager')}
              </Link>
              <Link
                className={classes.breadcrumbsText}
                color={'textPrimary'}
                onClick={() => { }}
              >
                {t('menu.add_ons')}
              </Link>
            </Breadcrumbs>
          </Grid>
          <SearchHeader
            onChangeSearch={setInputSearch}
            onAdd={() => {
              setSelectedGroup(null)
              setTabIdForGroupUpdate(0)
              setOpenAddonModal(true)
            }}
          />
          <Grid item xs={12}>
            <Paper className={classes.emptyProductsContainer}>
              {(isEmpty(filteredAddonGroups) && !isLoadingAddonGroups)
                ?
                <NoAddonGroups
                  title={inputSearch
                    ? t('addons.no_matching_addon_groups_found')
                    : t('addons.no_addon_groups_found')
                  }
                  showAddButton={!inputSearch}
                />
                :
                <List className={classes.root}>
                  <ListItem className={classes.listHeader}>
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      alignContent="center"
                    >
                      <Grid item xs={10}>
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                          alignContent="center"
                        >
                          <Grid item xs={3}>
                            {t('addons.group_name')}
                          </Grid>
                          <Grid item xs={9}>
                            {t('addons.no_of_options')}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                  </ListItem>
                  {isLoadingAddonGroups
                    ?
                    <div className={classes.root}>
                      <Skeleton height={80} />
                      <Skeleton height={80} />
                      <Skeleton height={80} />
                    </div>
                    :
                    <div
                      style={{
                        maxHeight: 500,
                        overflowY: 'auto',
                      }}
                    >
                      {filteredAddonGroups.map((group, id) => (
                        <ListItem button key={`group-${id}`} className={classes.listItem}>
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                            alignContent="center"
                          >
                            <Grid
                              item
                              xs={10}
                              onClick={() => {
                                setSelectedGroup(group)
                                setTabIdForGroupUpdate(0)
                                setOpenAddonModal(true)
                              }}
                            >
                              <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                                alignContent="center"
                                onClick={() => { }}
                              >
                                <Grid item xs={3}>
                                  {group.group}
                                </Grid>
                                <Grid item xs={9}>
                                  {group.addons?.length}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              className={classes.dotsIcon}
                            >
                              <ActionMenu
                                data={group}
                                actionMenus={actionMenus}
                              />
                            </Grid>
                          </Grid>
                        </ListItem>
                      ))}
                    </div>
                  }
                </List>
              }
            </Paper>
          </Grid>
        </Grid>

        {isRemovingAddonGroup && <PreLoader size={25} />}
      </div>

      {openAddonModal &&
        <AddUpdateAddonGroup
          open={openAddonModal}
          handleClose={() => setOpenAddonModal(false)}
          addonGroup={selectedGroup}
          defaultTabId={tabIdForGroupUpdate}
          onSuccess={refetchAddonGroups}
        />
      }
    </AppLayout>
  )
}

export default Addons