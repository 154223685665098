import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Line } from 'react-chartjs-2'

import { isMobile } from 'react-device-detect'

import { Colors, CommonFonts } from '@/theme'
import { transformFloat } from '@/utils/localisationUtil'

const LineChart = (props) => {
  const {
    firstPerformance,
    secondPerformance,
    firstChartData,
    secondChartData,
    localisation
  } = props

  const dataLine = {
    labels: firstChartData.xAxisValues,
    datasets: [
      {
        label: firstPerformance,
        fill: true,
        lineTension: 0,
        backgroundColor: "#55CC6643",
        borderColor: "#55CC66",
        borderCapStyle: "butt",
        borderDash: [],
        borderWidth: isMobile ? 1 : 3,
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        data: firstChartData.yAxisValues,
        yAxisID: 'y-axis-1',
      },
      {
        label: secondPerformance,
        fill: true,
        lineTension: 0,
        backgroundColor: "#0058FF43",
        borderColor: "#0058FF",
        borderCapStyle: "butt",
        borderDash: [],
        borderWidth: isMobile ? 1 : 3,
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        data: secondChartData.yAxisValues,
        yAxisID: 'y-axis-2',
      }
    ]
  }

  const calculateWidth = () => {
    let length = firstChartData.xAxisValues.length
    // switch (true) {
    //   case length >= 0 && length <= 2:
    //     return 400
    //   case length >= 4 && length <= 50:
    //     return 2000
    //   default:
    //     return 5000
    // }
    return length * 100
  }

  const getTooltipAmount = (amount, isCurrencyAmount) => {
    if (isCurrencyAmount) {
      return transformFloat(amount, localisation, { showCurrency: true })
    } else {
      return amount
    }
  }

  const getTooltipTitle = (label) => {
    if (firstChartData.isHourlyBracket) {
      return `${moment(label).subtract(0.5, 'hour').format('HH:mm')} - ${moment(label).add(0.5, 'hour').format('HH:mm')}`
    } else {
      return moment(label).format('MM-DD')
    }
  }

  const getXaxisLabel = (label) => {
    if (firstChartData.isHourlyBracket) {
      return moment(label).format('HH:mm')
    } else {
      return moment(label).format('MM-DD')
    }
  }

  return (
    <Line
      data={dataLine}
      // width={calculateWidth()}
      height={isMobile ? 200 : 70}
      options={
        {
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                return firstPerformance + ': ' + getTooltipAmount(data['datasets'][0]['data'][tooltipItem['index']], firstChartData.isCurrencyAmount);
              },
              afterLabel: (tooltipItem, data) => {
                return secondPerformance + ': ' + getTooltipAmount(data['datasets'][1]['data'][tooltipItem['index']], secondChartData.isCurrencyAmount);
              },
              title: (tooltipItem, data) => {
                return getTooltipTitle(data['labels'][tooltipItem[0]['index']]);
              },
            },
            displayColors: false,
            backgroundColor: '#dceafd',
            titleFontColor: Colors.GRAY_DARK,
            bodyFontColor: Colors.GRAY_DARK,
          },
          responsive: true,
          scales: {
            xAxes: [{
              ticks: {
                userCallback: (label) => {
                  return getXaxisLabel(label)
                }
              },
              gridLines: { display: false }
            }],
            yAxes: [
              {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                ticks: {
                  beginAtZero: true,
                  userCallback: function (label) {
                    if (Math.floor(label) === label) {
                      return label;
                    }
                  },
                },
                gridLines: { display: false }
              },
              {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                ticks: {
                  beginAtZero: true,
                  userCallback: function (label) {
                    if (Math.floor(label) === label) {
                      return label;
                    }
                  },
                },
                gridLines: { display: false }
              },
            ],
          }
        }
      }
    />
  )
}

LineChart.propTypes = {
  firstPerformance: PropTypes.string,
  secondPerformance: PropTypes.string,
  firstChartData: PropTypes.object,
  secondChartData: PropTypes.object,
  localisation: PropTypes.object
}

export default LineChart