import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core'
import ChangePassword from './changePassword'
import Success from './success'
import PreLoader from '@/components/preLoader'

import { useMutation } from '@apollo/client'
import { RESET_PASSWORD } from '@/services/authService'

import images from '@/assets/images'
import useStyles from './styles'

const STEPS = {
  RESET: 'RESET',
  SUCCESS: 'SUCCESS'
}

const ResetPassword = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { code } = useParams()
  const history = useHistory()

  const [step, setStep] = useState(STEPS.RESET)
  const [error, setError] = useState(false)

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      setStep(STEPS.SUCCESS)
    },
    onError: () => setError(true)
  })

  const onLogin = () => history.push('/login')

  const onChangePassword = (values) => {
    resetPassword({
      variables: {
        input: {
          code: code,
          password: values.password
        }
      }
    })
  }

  const contents = {
    [STEPS.RESET]: (
      <ChangePassword
        error={error}
        onChangePassword={onChangePassword}
      />
    ),
    [STEPS.SUCCESS]: (
      <Success
        onLogin={onLogin}
      />
    )
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box display='flex' justifyContent='center'>
          <img src={images.logo} width={115} height={45} alt="logo" />
        </Box>

        <Typography className={classes.changePasswordText}>
          {t('reset_password_page.change_password')}
        </Typography>

        {contents[step]}

        {loading && <PreLoader size={59} />}
      </Box>
    </Box>
  )
}

export default ResetPassword