import React from 'react'
import PropTypes from 'prop-types'

import { Container } from '@material-ui/core';
import Footer from '@/components/footer'
import Header from '@/components/header'

import useStyles from './styles'

const AppLayout = (props) => {
  const {
    className,
    withFooter,
    children,
    header,
    withServiceDropdown,
    withAllStoresOption
  } = props

  const classes = useStyles()

  return (
    <div className={className}>
      {header && (
        <Header
          withServiceDropdown={withServiceDropdown}
          withAllStoresOption={withAllStoresOption}
        />
      )}
      <Container maxWidth='lg' className={classes.root}>
        <div className={classes.container}>
          {children}
        </div>
        {withFooter &&
          <Footer />
        }
      </Container>
    </div>
  )
}

AppLayout.propTypes = {
  className: PropTypes.string,
  withFooter: PropTypes.bool,
  header: PropTypes.bool,
  withServiceDropdown: PropTypes.bool
}

AppLayout.defaultProps = {
  header: false,
  withServiceDropdown: false,
  withAllStoresOption: false
}

export default AppLayout

