import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  TableRow,
  TableCell,
  withStyles,
  Tooltip,
  Box
} from '@material-ui/core'
import ActionMenu from '@/components/actionMenu'

import { selectLocalisation } from '@/store/modules/store/selectors'

import { Colors, CommonFonts } from '@/theme'

import { transformFloat } from '@/utils/localisationUtil'
import { ORDER_STATES, getOrderPaymentSum } from '../../helper'

const Row = ({ item, onVoidOrder, onRefundOrder, onOpenDetails }) => {
  const { t } = useTranslation()

  const localisation = useSelector(selectLocalisation);

  const getOrderValue = (value) => {
    return transformFloat(value, localisation, { showCurrency: true })
  }

  const getUserName = (user) => {
    return [user.first_name, user.last_name]
      .filter(name => !!name)
      .join(' ') ||
      user.email
  }


  const rowCells = [
    {
      id: 'date_time',
      label: moment(item.creation.time).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      id: 'order_no',
      label: item.order_no,
    },
    {
      id: 'order_type',
      label: item.order_type.name,
    },
    {
      id: 'user',
      label: getUserName(item.user),
    },
    {
      id: 'status',
      label: item?.status?.name,
    },
    {
      id: 'order_value',
      label: getOrderValue(item.total_price.gross_amount),
    },
  ];

  const actionMenus = [
    {
      name: t('orders.view'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        onOpenDetails(data)
      },
      enabled: !!item
    },
    {
      name: t('orders.void'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        onVoidOrder(data)
      },
      enabled: item.state !== 'refunded' && item.state !== 'voided'
    },
    {
      name: t('orders.refund'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        onRefundOrder(data)
      },
      enabled: getOrderPaymentSum(item.payments) > 0
    }
  ]

  const enabledActionMenus = actionMenus.filter(menu => menu.enabled)

  const noActionMenu = [{
    name: t('orders.no_action'),
    color: Colors.GRAY_DARK,
    onClick: () => {
    },
  }]

  return (
    <TableRow hover style={{ position: 'relative' }}>
      {rowCells.map(rowCell => (
        <StyledTableCell key={rowCell.id}>
          {rowCell.label}
        </StyledTableCell>
      ))}
      <StyledTableCell align='right'>
        <ActionMenu
          data={item}
          actionMenus={isEmpty(enabledActionMenus)
            ? noActionMenu
            : enabledActionMenus
          }
        />
      </StyledTableCell>

      {(item.state === 'refunded' || item.state === 'voided') &&
        <Tooltip
          title={item.state === 'refunded'
            ? t('orders.refunded_order')
            : t('orders.voided_order')
          }
        >
          <Box
            style={{
              position: 'absolute',
              height: '100%',
              width: 8,
              left: 0,
              backgroundColor: item.state === 'refunded'
                ? Colors.PURPLE
                : Colors.RED
            }}
          >

          </Box>
        </Tooltip>
      }
    </TableRow>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    ...CommonFonts.darkFont16,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont16
  },
}))(TableCell);

Row.propTypes = {
  item: PropTypes.object,
}

export default Row
