import React, { useState, useEffect } from 'react';
import classNames from 'classnames'

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { Box } from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Grid from '@material-ui/core/Grid';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const filter = createFilterOptions();

export default function FreeSolo({
  setParentCategoryId,
  allCategories,
  emptyCategories,
  defaultValue,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [step, setstep] = useState(0);
  const [valueSelected, setvalueSelected] = useState();
  const thisIsParent = {
    id: null,
    name: t('categories.this_is_parent'),
  };

  useEffect(() => {
    if (defaultValue && defaultValue !== null) {
      const defaultValueSelected = allCategories.find(
        (element) => element.id === defaultValue
      );
      setvalueSelected(defaultValueSelected?.name || null);
      setstep(0);
    }
  }, [defaultValue, allCategories]);

  return (
    <div style={{ width: '100%' }}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12}>
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            style={{ height: 60 }}
          >
            <Grid item xs={12}>
              <span className={classes.generalInfoItem}>
                {t('categories.parent_category')}
              </span>
            </Grid>
            {/* if no value selected and store has categories */}
            {step === 0 && (
              <Grid
                item
                xs={12}
                style={{ position: 'relative' }}
                onClick={() => !valueSelected && !emptyCategories
                  ? setstep(1)
                  : {}
                }
              >
                <Typography
                  className={
                    classNames(classes.label, {
                      [classes.emptyLabel]: !valueSelected && !emptyCategories
                    })
                  }
                  onClick={() => setstep(1)}
                >
                  {!valueSelected ? t('categories.select') : valueSelected}

                  {valueSelected &&
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => setstep(1)}
                    >
                      <CreateIcon fontSize='small' />
                    </IconButton>
                  }
                </Typography>
              </Grid>
            )}

            {step === 1 && (
              <Grid item xs={12} style={{ marginTop: '-10px' }}>
                <Autocomplete
                  id="free-solo-2-demo"
                  disableClearable
                  options={
                    !valueSelected
                      ? allCategories
                      : [thisIsParent, ...allCategories]
                  }
                  openOnFocus
                  blurOnSelect
                  fullWidth
                  onChange={(event, value) => {
                    setvalueSelected(value.name);
                    setParentCategoryId(value.id);
                  }}
                  onBlur={() => setstep(0)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      margin="normal"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { inputValue }) => {
                    const matches = match(option.name, inputValue);
                    const parts = parse(option.name, matches);

                    return (
                      <Box paddingX={2} paddingY={3}>
                        {inputValue?.length > 2 ? (
                          <>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight
                                    ? 600
                                    : 400,
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </>
                        ) : (
                          <span>{option.name}</span>
                        )}
                      </Box>
                    );
                  }}
                  filterOptions={(options, params) => {
                    if (params.inputValue?.length > 2) {
                      const filtered = filter(options, params);

                      return filtered;
                    }
                    return options;
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
