import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as Yup from 'yup';
import I18n from '@/i18n';
import { v4 as uuid } from 'uuid';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Box,
  Tab,
  Tabs
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import BasicInformation from './basicInformation'
import StockInformation from './stockInformation'

import {
  useQuery,
  useMutation,
  gql
} from '@apollo/client';
import {
  GET_CATEGORIES,
  ADD_INGREDIENT
} from '@/services/inventoryService'
import { GET_MEASURES } from '@/services/storeService'
import { GET_SHELVES } from '@/services/modulesService';

import Errors from '@/constants/errors'
import useStyles from './styles';

const validationSchema = Yup.object().shape({
  bar_code: Yup.string()
    .required(I18n.t('add_product.barcode_required')),
  name: Yup.string()
    .required(I18n.t('add_product.product_name_required')),
});

export default function ResponsiveDialog({
  open,
  handleClose,
  ingredient = null,
  defaultTabId = 0,
  onSuccess
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [tabId, setTabId] = useState(0);

  const [ingredientData, setIngredientData] = useState({
    id: '',
    bar_code: '',
    name: '',
    category: null,
    measure: null,
    expires: false,
    stocks: [
      {
        stock_locations: [{ id: uuid(), aisle: '', shelf: '' }],
      },
    ],
    sku_no: '',
  })

  const [barcodeErrorText, setBarcodeErrorText] = useState('')

  const { loading: isLoadingStoreCategories, data: storeCategoriesData } = useQuery(GET_CATEGORIES, {
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        storeId: -1
      }
    },
  })

  const { loading: isLoadingMeasures, data: measuresData } = useQuery(gql`${GET_MEASURES}`, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!ingredient) {
        updateIngredientData('measure', data.measures?.[0])
      }
    }
  })

  const { loading: isLoadingShelves, data: shelvesData } = useQuery(
    gql`
      ${GET_SHELVES}
    `,
    { fetchPolicy: 'no-cache' }
  );

  const [addUpdateIngredientMutation, { loading: isAddingIngredient }] = useMutation(ADD_INGREDIENT, {
    onCompleted: (data) => {
      enqueueSnackbar(ingredient
        ? t('ingredients.update_ingredient_success')
        : t('ingredients.add_ingredient_success')
      )
      onSuccess(data.addIngredients?.[0])
      handleClose()
    },
    onError: (error) => {
      if (error?.message === Errors.BARCODE_ALREADY_EXIST || error?.message === Errors.INVALID_INVENTORY) {
        setBarcodeErrorText(t('add_product.barcode_already_exist'))
      }
    }
  })

  const tabs = [
    {
      id: 0,
      label: t('ingredients.basic_information'),
      disabled: false,
    },
    {
      id: 1,
      label: t('ingredients.stock_information'),
      disabled: false,
    },
  ];

  const isFirstTab = tabId === 0;
  const isLastTab = tabId === tabs.length - 1;

  const onChangeTab = (event, value) => setTabId(value)

  useEffect(() => {
    if (defaultTabId) {
      setTabId(defaultTabId);
    }
  }, [defaultTabId]);

  useEffect(() => {
    if (ingredient) {
      setIngredientData({
        id: ingredient.id,
        name: ingredient.name,
        bar_code: ingredient.bar_code,
        category: ingredient.category,
        measure: ingredient.measure,
        sku_no: ingredient?.sku_no,
        expires: ingredient?.expires,
        stocks: ingredient?.stocks,
      })
    }
  }, [ingredient])

  const updateIngredientData = (key, value) => {
    if (barcodeErrorText) {
      setBarcodeErrorText('')
    }
    setIngredientData({
      ...ingredientData,
      [key]: value
    })
  }

  const onAddUpdateIngredient = () => {
    const idPara = ingredientData.id ? { id: ingredientData.id } : {}
    
    addUpdateIngredientMutation({
      variables: {
        input: [{
          ...idPara,
          name: ingredientData.name,
          bar_code: ingredientData.bar_code,
          measure_id: ingredientData.measure?.id,
          prices: [{
            price_infos: [],
            store_id: '-1'
          }],
          category_id: null,//ingredientData.category?.id || null,
          sku_no: ingredientData.sku_no,
          stocks: [
            {
              stock_locations:
                ingredientData.stocks[0].stock_locations?.map(
                  (location) => location.id
                ),
              stock: 0,
            },
          ],
          expires: ingredientData.expires
        }]
      }
    })
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Formik
        initialValues={{
          bar_code: ingredientData.bar_code,
          name: ingredientData.name,
          category: ingredientData.category,
        }}
        validationSchema={validationSchema}
        onSubmit={onAddUpdateIngredient}
      >
        {({
          handleBlur,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
          setFieldTouched
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
          >
            <DialogTitle id="alert-dialog-slide-title">
              <Grid
                container
                alignItems="center"
                justify="space-between"
                alignContent="center"
              >
                <Grid item xs={1} />
                <Grid className={classes.headerText} item xs={10}>
                  <span style={{ fontSize: 18 }}>
                    {ingredient
                      ? t('ingredients.update_ingredient')
                      : t('ingredients.add_new_ingredient')
                    }
                  </span>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'right' }}>
                  <IconButton
                    onClick={handleClose}
                    className={classes.closeButton}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.rootDialog}>
                <Tabs
                  className={classes.tabs}
                  value={tabId}
                  onChange={onChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {tabs.map((item) => (
                    <Tab
                      className={classes.tab}
                      style={{ fontSize: 18 }}
                      key={item.id}
                      label={item.label}
                    />
                  ))}
                </Tabs>
                <TabPanel value={tabId} index={0}>
                  <BasicInformation
                    ingredientData={ingredientData}
                    categories={storeCategoriesData?.categories || []}
                    measures={measuresData?.measures || []}
                    updateIngredientData={updateIngredientData}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    barcodeErrorText={barcodeErrorText}
                  />
                </TabPanel>
                <TabPanel value={tabId} index={1}>
                  <StockInformation
                    ingredientData={ingredientData}
                    updateIngredientData={updateIngredientData}
                    shelvesData={shelvesData}
                  />
                </TabPanel>
              </div>
            </DialogContent>
            <DialogActions style={{ paddingRight: 30 }}>
              <Button
                color="primary"
                onClick={handleClose}
                className={classes.cancelButton}
              >
                {t('button_group.cancel')}
              </Button>
              {!isFirstTab && (
                <Button
                  color="primary"
                  onClick={() => setTabId(tabId - 1)}
                >
                  {t('button_group.back')}
                </Button>
              )}
              {!isLastTab && (
                <Button
                  color="primary"
                  onClick={() => setTabId(tabId + 1)}
                >
                  {t('button_group.next')}
                </Button>
              )}
              <Button type='submit' color="primary">
                {ingredient
                  ? t('button_group.update')
                  : t('button_group.add')
                }
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>

      {(isLoadingStoreCategories ||
        isLoadingMeasures ||
        isAddingIngredient ||
        isLoadingShelves
      ) &&
        <PreLoader size={25} />
      }
    </Dialog>
  );
}


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};
