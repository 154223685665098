import React from 'react'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'

import { Grid, Typography } from '@material-ui/core';
import ReactCodeInput from 'react-verification-code-input'
import Button from '@/components/button';
import BackButton from '@/components/backButton';

import useStyles from '../styles';

const EnterOTPStep = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    phone,
    checkOTPError,
    onBack,
    onCheckOTP,
    onResend
  } = props

  return (
    <Grid container spacing={10}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          direction="row"
        >
          <Grid item>
            <div className={classes.backWrapper}>
              <BackButton onClick={onBack} />
            </div>
          </Grid>
          <Grid item>
            <span className={classes.title}>
              {t('login_page.enter_otp')}
            </span>
          </Grid>
        </Grid>
        <Typography className={classes.smsText}>
          {t('login_page.enter_otp_description', { suffix_number: phone.number.slice(-4) })}
        </Typography>
      </Grid>

      <Grid container justify='center' className={classes.otpContainer}>
        <ReactCodeInput
          autoFocus={true}
          type='number'
          fields={6}
          onComplete={(val) => onCheckOTP(`+${phone.number}`, val)}
        />
        {checkOTPError &&
          <Typography className={classes.errorText}>
            {t('login_page.invalid_otp')}
          </Typography>
        }
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justify="center"
          className={classes.buttonWrapper}
        >
          <Grid item xs={11} md={7}>
            <Button
              className={classes.nextButton}
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => {}}
            >
              {t('button_group.login')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ marginTop: 30 }}>
        <span
          className={classes.createText}
          onClick={() => onResend(`+${phone.number}`)}
        >
          {t('login_page.resend_otp')}
        </span>
      </Grid>
    </Grid>
  )
}

EnterOTPStep.propTypes = {
  phone: PropTypes.object,
  checkOTPError: PropTypes.bool,
  onBack: PropTypes.func,
  onCheckOTP: PropTypes.func,
  onResend: PropTypes.func
}

export default EnterOTPStep