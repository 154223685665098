import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import DayMonthPicker from '@/components/dayMonthPicker';
import { useTranslation } from 'react-i18next';

const ReportFinancialYearEnd = forwardRef(
  ({ value, onChange }, ref) => {
    const { t } = useTranslation();
    const dayMonthPickerRef = useRef();

    useImperativeHandle(ref, () => ({
      handleOpenEditModal: () =>
        dayMonthPickerRef?.current?.handleOpenEditModal(),
    }));

    return (
      <DayMonthPicker
        ref={dayMonthPickerRef}
        subTitle={t('settings.reports.financial_year_end_sub_title')}
        name="reportFinancialYearEnd"
        label={t('settings.reports.financial_year_end')}
        value={value}
        onChange={onChange}
      />
    );
  }
);

export default ReportFinancialYearEnd;
