import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors, CommonFonts } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    titleText: {
      ...CommonFonts.darkFont14,
    },
    textField: {
      '& .MuiInput-underline:before': {
        borderBottomWidth: 0,
      },
      '& input': {
        WebkitAppearance: 'textfield',
        MozAppearance: 'textfield',
        appearance: 'textfield',
      },
      '& input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
      '& input::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      }
    },
    arrowsWrapper: {
      position: 'absolute',
      right: 0,
      top: -5
    },
    iconButton: {
      padding: 0,
    },
  })
)

export default useStyles
