import { gql } from '@apollo/client';

export const GET_MERCHANT_SETTINGS = gql`
  query getMerchantSettings {
    merchantSettings {
      delivery {
        partners {
          id
          rate
        }
      }
    }
  }
`;

export const GET_STORE = gql`
  query getStore {
    store {
      id
      settings {
        delivery {
          delivery_during_opening_hours
          hourly_capacity
        }
      }
    }
  }
`;

export const GET_PROVIDER = gql`
  query getProvider {
    providers {
      id
      logo {
        url
        thumbnail
        #use this if fit.
      }
      tname
      base_rate
    }
  }
`;

export const UPDATE_STORE_ORDER = gql`
  mutation ($store: StoreInput!) {
    updateStore(input: $store) {
      id
    }
  }
`;

export const UPDATE_MERCHANT_SETTINGS = gql`
  mutation ($input: MerchantSettingsInput!) {
    merchantSettings(input: $input) {
      delivery {
        partners {
          id
          rate
        }
      }
    }
  }
`;

export const GET_STORE_DELIVERY = gql`
  query getStore($filter: Filter) {
    store(filter: $filter) {
      id
      settings {
        delivery {
          day_slots {
            date
            disabled
            slots {
              start
              end
              disabled
              max_orders
            }
          }
        }
      }
    }
  }
`;
