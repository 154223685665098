import I18n from '@/i18n';
import moment from 'moment';
import { find } from 'lodash'

import { transformFloat } from '@/utils/localisationUtil'
import { DATE_FILTERS } from '../helper'

export const PERFORMANCE = {
  REVENUE: I18n.t('workboard.trends.revenue'),
  EXPENSES: I18n.t('workboard.trends.expenses'),
  ATV: I18n.t('workboard.trends.atv'),
  BASKET_SIZE: I18n.t('workboard.trends.basket_size'),
  NUMBER_OF_TRANSACTIONS: I18n.t('workboard.trends.number_of_transactions'),
}

export const getPerformanceTotal = (performance, accountTypesData, insightsData, localisation) => {
  if (performance === PERFORMANCE.REVENUE) {
    const accountType = find(accountTypesData?.accountTypes, { name: 'INCOMES' })
    return transformFloat(accountType?.performance?.performance || 0, localisation, { showCurrency: true })
  }
  if (performance === PERFORMANCE.EXPENSES) {
    const accountType = find(accountTypesData?.accountTypes, { name: 'PAYMENTS' })
    return transformFloat(accountType?.performance?.performance || 0, localisation, { showCurrency: true })
  }
  if (performance === PERFORMANCE.ATV) return transformFloat(insightsData?.insights?.performances?.average_transaction_value || 0, localisation, { showCurrency: true })
  if (performance === PERFORMANCE.BASKET_SIZE) return transformFloat(insightsData?.insights?.performances?.average_basket_size || 0, localisation, { showCurrency: true })
  if (performance === PERFORMANCE.NUMBER_OF_TRANSACTIONS) return insightsData?.insights?.performances?.no_of_transactions || 0
}

const findYAxisValue = (array, time, performance) => {
  let data = find(array, item => moment(time) >= moment(item.start) && moment(time) < moment(item.end))
  if (performance === PERFORMANCE.ATV) {
    return data?.average_transaction_value || 0
  } else if (performance === PERFORMANCE.BASKET_SIZE) {
    return data?.average_basket_size || 0
  } else if (performance === PERFORMANCE.NUMBER_OF_TRANSACTIONS) {
    return data?.no_of_transactions || 0
  } else {
    return data?.performance || 0
  }
}

export const getChartData = (startTime, endTime, dateFilter, performance, accountTypesData, insightsData) => {
  const isHourlyBracket = dateFilter === DATE_FILTERS.TODAY || dateFilter === DATE_FILTERS.YESTERDAY

  let startTimeArr = [startTime]
  let tempTime = moment(startTime)
  while (1) {
    if (isHourlyBracket) {
      tempTime = tempTime.add(1, 'hour')
    } else {
      tempTime = tempTime.add(1, 'day')
    }

    if (tempTime > moment(endTime)) break
    startTimeArr.push(tempTime.utc().format())
  }

  let breakdown
  if (performance === PERFORMANCE.ATV || performance === PERFORMANCE.BASKET_SIZE || performance === PERFORMANCE.NUMBER_OF_TRANSACTIONS) {
    breakdown = insightsData?.insights?.performances?.breakdown
  } else {
    let accountType
    if (performance === PERFORMANCE.REVENUE) accountType = find(accountTypesData?.accountTypes, { name: 'INCOMES' })
    else accountType = find(accountTypesData?.accountTypes, { name: 'PAYMENTS' })
    breakdown = accountType?.performance?.breakdown
  }

  const yAxisValues = startTimeArr.map(time => {
    if (isHourlyBracket) {
      return findYAxisValue(breakdown?.hourly, time, performance)
    } else {
      return findYAxisValue(breakdown?.daily, time, performance)
    }
  })

  let xAxisValues
  if (isHourlyBracket) {
    xAxisValues = startTimeArr.map(time => moment(time).add(0.5, 'hour'))
  } else {
    xAxisValues = startTimeArr.map(time => moment(time))
  }

  const isCurrencyAmount = performance === PERFORMANCE.ATV || performance === PERFORMANCE.BASKET_SIZE || performance === PERFORMANCE.REVENUE || performance === PERFORMANCE.EXPENSES


  return {
    xAxisValues,
    yAxisValues,
    isCurrencyAmount,
    isHourlyBracket
  }
}