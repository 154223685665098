import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
  IconButton,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import PreLoader from '@/components/preLoader';

import images from '@/assets/images';
import useStyles from '../styles';

const Notifications = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    loading,
    isLoading,
    userInvitationData,
    onClose,
    onDecline,
    onAccept,
  } = props;

  return (
    <div className={classes.drawerContent}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.titleNotification}>
          {t('notification.title')}
        </Typography>
        <IconButton
          onClick={onClose}
          size="small"
          className={classes.closeIcon}
        >
          <CloseOutlined style={{ fontSize: '20px' }} />
        </IconButton>
      </Box>
      {!loading ? (
        <div>
          {userInvitationData?.length > 0 ? (
            <Box marginTop={12}>
              {userInvitationData
                ?.filter((item) => !item.expired)
                ?.sort(
                  (a, b) =>
                    new Date(b.created_at) - new Date(a.created_at)
                )
                ?.map((item) => (
                  <div
                    key={item.id}
                    className={classes.notificationCard}
                  >
                    <Typography className={classes.contentTitle}>
                      {item.merchant_name}
                    </Typography>
                    <Typography
                      className={classes.contentDescription}
                    >
                      {t('invitation.invite.description')}
                    </Typography>
                    <Box
                      marginTop={10}
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Button
                        style={{
                          marginRight: '20px',
                          color: 'black',
                        }}
                        className={classes.actionButton}
                        variant="contained"
                        onClick={() => onDecline(item)}
                        disableElevation
                      >
                        {t('invitation.invite.decline')}
                      </Button>
                      <Button
                        className={classes.actionButton}
                        color="primary"
                        variant="contained"
                        onClick={() => onAccept(item)}
                        disableElevation
                      >
                        {t('invitation.invite.accept')}
                      </Button>
                    </Box>
                  </div>
                ))}
            </Box>
          ) : (
            <Box
              height="100vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <>
                <img
                  src={images.noNotifications}
                  width={92}
                  height={92}
                />
                <Typography className={classes.noNotifications}>
                  {t('notification.no_notifications')}
                </Typography>
              </>
            </Box>
          )}
        </div>
      ) : (
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={30} />
        </Box>
      )}
      {isLoading && <PreLoader size={30} />}
    </div>
  );
};

Notifications.propTypes = {
  loading: PropTypes.bool,
  isLoading: PropTypes.bool,
  userInvitationData: PropTypes.any,
  onClose: PropTypes.func,
  onDecline: PropTypes.func,
  onAccept: PropTypes.func,
};

export default Notifications;
