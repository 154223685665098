import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { useMutation } from '@apollo/client';
import TextField from '@/components/textField';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { DELETE_SEGMENT } from '@/services/audienceService';

const ConfirmDialog = ({
                         dialogTitle,
                         segment = {},
                         open,
                         handleClose,
                         setRefetchSegments
                       }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [confirmedValue, setConfirmedValue] = useState({});

  const [DeleteSegment, { loading: deletingSegment }] =
    useMutation(DELETE_SEGMENT, {
      onCompleted: (response) => {
        enqueueSnackbar(confirmedValue + ' ' + t('modules.customers.segment_deleted_successfully'));
        handleClose();
        setRefetchSegments();
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    });


  // Save updated segment
  const deleteSegment = () => {
    const payload = {
      variables: {
        input: {
          id: segment.id,
          name: confirmedValue,
          flag: 'delete'
        }
      }
    };
    return DeleteSegment(payload);
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            alignContent="center"
          >
            <Grid className={classes.headerText} item xs={11}>
              <span style={{ fontSize: 18 }}>
                {dialogTitle}
              </span>
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" alignContent="center">
            <Grid xs={12} item row className={classes.gridItem}>
              <TextField
                id="standard-basic"
                label={t('modules.customers.name')}
                name="name"
                fullWidth
                placeholder = {segment?.name}
                onChange={(e) => setConfirmedValue(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: 30 }}>
          <Button
            color="primary"
            onClick={handleClose}
            className={classes.cancelButton}
          >
            {t('customers.cancel')}
          </Button>
          <Button
            disabled={confirmedValue !== segment?.name}
            color="secondary"
            onClick={deleteSegment}
          >
            {t('modules.customers.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;