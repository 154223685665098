import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';

import {
  Box,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Typography,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CardSection from '../cardSection';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@/components/button';
import useStyles from './styles';
import images from '@/assets/images';
import { useSnackbar } from 'notistack';
import { Colors, CommonFonts } from '@/theme';
import { gql, useMutation } from '@apollo/client';
import { ADD_RESOURCE } from '@/services/subscriptionService';
import PreLoader from '@/components/preLoader';

const StripeCheckoutForm = ({ isVisible, onClose, onFetch }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMesaage] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [onAddResource] = useMutation(
    gql`
      ${ADD_RESOURCE}
    `
  );

  const onAddCard = async () => {
    setIsLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createSource(card);

    if (result.error) {
      setIsLoading(false);
      enqueueSnackbar(result.error.message, { variant: 'error' });
    } else {
      onAddResource({
        variables: {
          input: {
            provider: 'stripe',
            source: result.source.id,
          },
        },
      })
        .then(async (res) => {
          if (res) {
            await onFetch?.();
            setIsLoading(false);
            onClose();
            enqueueSnackbar(t('subscriptions.payment_method_added'));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          const message = error?.message || error?.errors[0]?.message;
          enqueueSnackbar(message, { variant: 'error' });
        });
    }
  };

  const onCardChange = (value) => {
    setErrorMesaage(value?.error?.message || '');
    setIsComplete(value.complete);
  };

  const isDisableAdd =
    errorMessage || !isComplete || !stripe || isLoading;

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      aria-labelledby={`stripe-dialog`}
      open={isVisible}
      disableBackdropClick
    >
      <DialogTitle
        id={`stripe-dialog`}
        onClose={onClose}
      ></DialogTitle>
      <DialogContent>
        <Box paddingX={4} paddingY={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justify="center"
            paddingBottom={6}
          >
            <img
              src={images.logo}
              width={100}
              height={50}
              alt="logo"
            />
            <Typography className={classes.title}>
              {t('subscriptions.payment_method_title')}
            </Typography>
          </Box>
          <CardSection onCardChange={onCardChange} />
          {errorMessage && (
            <Box paddingY={2}>
              <FormHelperText error>{errorMessage}</FormHelperText>
            </Box>
          )}
          <Button
            onClick={onAddCard}
            className={classes.addCard}
            color="primary"
            disabled={isDisableAdd}
          >
            {t('subscriptions.add_payment_method')}
          </Button>
        </Box>
        {isLoading && <PreLoader size={25} />}
      </DialogContent>
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

export default StripeCheckoutForm;
