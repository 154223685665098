import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'

import {
  Box,
  Typography,
  IconButton,
  Grid,
  DialogActions,
  Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import Selector from './components/selector'
import InputWithLabel from './components/input'

import { selectCurrentMerchant } from '@/store/modules/store/selectors'

import useStyles from './styles'

const ReplenishStockSection = ({
  selectedProduct,
  onRemoveProduct,
  onReplenish
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const merchant = useSelector(selectCurrentMerchant)
  const stores = merchant.stores

  const [fromStore, setFromStore] = useState(null)
  const [toStore, setToStore] = useState(null)
  const [replenishAmount, setReplenishAmount] = useState(0)

  return (
    <Box>
      <Box paddingX={10} paddingBottom={10} minHeight={300}>
        <Box className={classes.productContainer}>
          <Box
            margin='auto'
            maxWidth={300}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box>
              <Typography className={classes.productDescriptionText}>
                {selectedProduct.name}
              </Typography>
              <Typography className={classes.productDescriptionText}>
                {`${t('workboard.add_stock.barcode')}: ${selectedProduct.bar_code}`}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={onRemoveProduct} className={classes.closeButton}>
                <CloseIcon fontSize='default' />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Grid
          container
          spacing={10}
          style={{ marginTop: 36 }}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <InputWithLabel
              type="number"
              title={t('workboard.replenish_stock.replenish_no')}
              emptyLabel={t('button_group.add')}
              value={replenishAmount}
              onChange={setReplenishAmount}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={10}
          style={{ marginTop: 36 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
          >
            <Selector
              title={t('workboard.replenish_stock.move_from')}
              emptyLabel={t('workboard.replenish_stock.select_location')}
              items={stores.filter(store => store.id !== toStore?.id)}
              getItemLabel={(store) => store.name}
              selectedItem={find(stores, { id: fromStore?.id }) || {}}
              onChangeValue={setFromStore}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
          >
            <Selector
              title={t('workboard.replenish_stock.move_to')}
              emptyLabel={t('workboard.replenish_stock.select_location')}
              items={stores.filter(store => store.id !== fromStore?.id)}
              getItemLabel={(store) => store.name}
              selectedItem={find(stores, { id: toStore?.id }) || {}}
              onChangeValue={setToStore}
            />
          </Grid>
        </Grid>
      </Box>

      <DialogActions>
        <Button
          color="primary"
          onClick={onRemoveProduct}
          className={classes.cancelButton}
        >
          {t('button_group.cancel')}
        </Button>
        <Button
          disabled={!fromStore || !toStore || +replenishAmount <= 0}
          color="primary"
          onClick={() => onReplenish(fromStore, toStore, +replenishAmount)}
        >
          {t('workboard.replenish_stock.replenish')}
        </Button>
      </DialogActions>
    </Box>
  )
}

export default ReplenishStockSection