import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  titleText: {
    ...CommonFonts.darkFont14,
  },
  selectorLabel: {
    ...CommonFonts.darkFont18,
    padding: theme.spacing(1, 0),
  },
  addButton: {
    padding: theme.spacing(2.5, 0),
    background: '#1174F2 0% 0% no-repeat padding-box',
    textTransform: 'Uppercase',
    ...CommonFonts.whiteFont14,
    width: 150,
    textTransform: 'none'
  },
}));

export default useStyles;
