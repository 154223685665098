import React, { useState, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import { Container, List } from '@material-ui/core';
import TextFiled from '@/components/textField';
import { Search } from '@material-ui/icons';
import { selectCurrentUser } from '@/store/modules/auth';
import { contains } from '@/utils/stringUtil';
import { Colors } from '@/theme';
import MerchantActions from '@/store/modules/store/actions';
import ListItem from './item';

import { getFullAddressOfStore } from '@/utils/stringUtil';

import useStyles from './styles';

const StoresLayout = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { currentUser } = props;

  const [searchKey, setSearchKey] = useState('');

  const onClickStore = (merchant, store) => {
    dispatch(MerchantActions.setCurrentMerchant(merchant));
    dispatch(MerchantActions.setSelectedStore(store));
    history.push('/workboard');
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <TextFiled
        autoFocus
        placeholder={t('select_store_page.search_name')}
        className={classes.searchInput}
        value={searchKey}
        onChange={(e) => setSearchKey(e.target.value)}
        startAdornment={
          <Search
            style={{ color: Colors.GRAY_BORDER1 }}
            width={18}
            height={18}
          />
        }
      />
      <List className={classes.list}>
        {(currentUser.merchants || []).map((merchant, index) => (
          <Fragment key={index}>
            {!isEmpty(
              (merchant.stores || []).filter((store) =>
                contains(store.name, searchKey)
              )
            ) && (
              <ListItem
                key={`${merchant.id}-${index}`}
                item={merchant}
                isMerchant
                leftText={merchant.tname}
                rightText={merchant.mid}
              />
            )}
            {(merchant.stores || []).map(
              (store) =>
                contains(store.name, searchKey) && (
                  <ListItem
                    key={`${store.id}-${index}`}
                    item={store}
                    isMerchant={false}
                    leftText={store.name}
                    rightText={getFullAddressOfStore(store.address)}
                    onClick={() => onClickStore(merchant, store)}
                  />
                )
            )}
          </Fragment>
        ))}
      </List>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

export default connect(mapStateToProps)(StoresLayout);
