import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%'
    },
    titleText: {
      ...CommonFonts.darkFont14,
    },
    filterInput: {
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
      ...CommonFonts.blueFont18,
    },
    selectContainer: {
      width: 250
    }
  })
)

export default useStyles
