import React, { useEffect, useState } from 'react';

import {
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  Box,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import PrimaryButton from '@/components/button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import {
  GLOBAL_ORDER_TYPES,
  GET_PRICE_TYPE,
  GET_WORKFLOWS,
  MUTATE_ORDER_TYPE,
} from '@/services/orderTypesWorkflowsService';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { capitalizeEachLetter } from '@/utils/stringUtil';
import { isMobile } from 'react-device-detect';
import { isDeepEmpty } from '@/utils/collectionUtil';

const globalOrderTypesQueries = gql`
  ${GLOBAL_ORDER_TYPES}
`;

const priceTypeQueries = gql`
  ${GET_PRICE_TYPE}
`;

const workflowsQueries = gql`
  ${GET_WORKFLOWS}
`;

const AddNewOrderTypes = ({ onFetchOrderTypes, orderTypesData }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const orderTypesName = orderTypesData?.map((item) => item.name);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setIsLoading(false);
    handleReset();
  };

  const addNewOrderType = Yup.object().shape({
    orderType: Yup.object({
      id: Yup.string().required(
        t('orderTypeWorkflows.order_type_name_required')
      ),
    }),
    priceType: Yup.object({
      id: Yup.string().required(
        t('orderTypeWorkflows.price_type_required')
      ),
    }),
    workflows: Yup.object({
      id: Yup.string().required(
        t('orderTypeWorkflows.workflow_required')
      ),
    }),
  });

  const [
    getGlobalOrderTypes,
    { loading: loadingGlobalOrderTypes, data: globalOrderTypes },
  ] = useLazyQuery(globalOrderTypesQueries);
  const globalOrderTypesData =
    globalOrderTypes?.globalOrderTypes?.filter(
      (item) => !orderTypesName?.includes(item.name)
    );

  const [
    getPriceType,
    { loading: loadingPriceType, data: priceType },
  ] = useLazyQuery(priceTypeQueries);
  const priceTypeData = priceType?.priceTypes;

  const [
    getWorkflows,
    { loading: loadingWorkflows, data: workflows },
  ] = useLazyQuery(workflowsQueries);
  const workflowsData = workflows?.workflows;

  const [mutateOrderType] = useMutation(
    gql`
      ${MUTATE_ORDER_TYPE}
    `
  );

  useEffect(() => {
    if (openModal) {
      getGlobalOrderTypes();
      getPriceType();
      getWorkflows();
    }
  }, [openModal]);

  const onAdd = () => {
    setIsLoading(true);
    mutateOrderType({
      variables: {
        input: {
          name: values?.orderType?.name,
          type: values?.orderType?.type,
          pricing_type: values?.priceType?.type,
          workflow_id: values?.workflows?.id,
        },
      },
    })
      .then(async () => {
        await onFetchOrderTypes?.();
        handleCloseModal();
        enqueueSnackbar(t('orderTypeWorkflows.order_type_added'));
      })
      .catch((error) => {
        handleCloseModal();
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  const {
    setFieldValue,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    isValid,
    handleReset,
  } = useFormik({
    initialValues: {
      orderType: {},
      priceType: {},
      workflows: {},
    },
    validationSchema: addNewOrderType,
  });

  useEffect(() => {
    if (values?.orderType) {
      setFieldValue(
        'priceType',
        priceTypeData?.find(
          (item) =>
            item.name ===
            capitalizeEachLetter(values?.orderType?.pricing_type)
        ) || {}
      );
      setFieldValue(
        'workflows',
        workflowsData?.find(
          (item) => item.id === values?.orderType?.workflow_id
        ) || {}
      );
    }
  }, [values?.orderType]);

  return (
    <>
      <PrimaryButton
        className={classes.addNew}
        variant="contained"
        color="primary"
        onClick={handleOpenModal}
      >
        {t('orderTypeWorkflows.add_new')}
      </PrimaryButton>
      <Dialog
        fullWidth
        fullScreen={isMobile}
        onClose={isLoading ? () => {} : handleCloseModal}
        aria-labelledby={`{add-new-order-type-dialog-title}`}
        open={openModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{add-new-order-type-dialog-title}`}
          onClose={handleCloseModal}
        >
          {t('orderTypeWorkflows.add_new_order_type')}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box marginBottom={12}>
              <Typography className={classes.descriptionText}>
                {t('orderTypeWorkflows.order_type_name')}
              </Typography>
              {loadingGlobalOrderTypes ? (
                <Skeleton height={35} />
              ) : (
                <Select
                  IconComponent={ExpandMoreIcon}
                  displayEmpty
                  fullWidth
                  id="orderType"
                  name="orderType"
                  className={classes.selectOptionInput}
                  defaultValue=""
                  value={values['orderType']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    Boolean(errors['orderType']) &&
                    touched['orderType']
                  }
                  inputProps={{
                    'aria-label': 'Without label',
                  }}
                >
                  {(globalOrderTypesData || []).map(
                    (option, index) => {
                      return (
                        <MenuItem
                          key={`${option.id}-${index}`}
                          value={option}
                        >
                          {option.name}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              )}
              {Boolean(errors['orderType']) &&
                touched['orderType'] && (
                  <FormHelperText
                    error={
                      Boolean(errors['orderType']) &&
                      touched['orderType']
                    }
                  >
                    {errors['orderType']?.id}
                  </FormHelperText>
                )}
            </Box>

            <Box marginBottom={12}>
              <Typography className={classes.descriptionText}>
                {t('orderTypeWorkflows.price_type')}
              </Typography>
              {loadingPriceType ? (
                <Skeleton height={35} />
              ) : (
                <Select
                  IconComponent={ExpandMoreIcon}
                  displayEmpty
                  fullWidth
                  id="priceType"
                  name="priceType"
                  className={classes.selectOptionInput}
                  defaultValue=""
                  value={priceTypeData?.find(
                    (item) => item.name === values['priceType']?.name
                  )}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    Boolean(errors['priceType']) &&
                    touched['priceType']
                  }
                  inputProps={{
                    'aria-label': 'Without label',
                  }}
                >
                  {(priceTypeData || []).map((option, index) => {
                    return (
                      <MenuItem
                        key={`${option.id}-${index}`}
                        value={option}
                      >
                        {option.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              {Boolean(errors['priceType']) &&
                touched['priceType'] && (
                  <FormHelperText
                    error={
                      Boolean(errors['priceType']) &&
                      touched['priceType']
                    }
                  >
                    {errors['priceType']?.id}
                  </FormHelperText>
                )}
            </Box>

            <Box marginBottom={12}>
              <Typography className={classes.descriptionText}>
                {t('orderTypeWorkflows.workflow')}
              </Typography>
              {loadingWorkflows ? (
                <Skeleton height={35} />
              ) : (
                <Select
                  IconComponent={ExpandMoreIcon}
                  displayEmpty
                  fullWidth
                  id="workflows"
                  name="workflows"
                  className={classes.selectOptionInput}
                  defaultValue={values['workflows']}
                  value={values['workflows']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    Boolean(errors['workflows']) &&
                    touched['workflows']
                  }
                  inputProps={{
                    'aria-label': 'Without label',
                  }}
                >
                  {(workflowsData || []).map((option, index) => {
                    return (
                      <MenuItem
                        key={`${option.id}-${index}`}
                        value={option}
                      >
                        {capitalizeEachLetter(option.name)}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              {Boolean(errors['workflows']) &&
                touched['workflows'] && (
                  <FormHelperText
                    error={
                      Boolean(errors['workflows']) &&
                      touched['workflows']
                    }
                  >
                    {errors['workflows']?.id}
                  </FormHelperText>
                )}
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={!isValid || isDeepEmpty(values)}
            onClick={onAdd}
            type="submit"
            color="primary"
          >
            {t('settings.add')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default AddNewOrderTypes;
