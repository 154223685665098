import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Box, Select, MenuItem } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import useStyles from './styles'

const Selector = (props) => {
  const classes = useStyles()

  const {
    title,
    items,
    getItemValue,
    getItemLabel,
    selectedItem,
    onChangeValue
  } = props

  return (
    <Box>
      <Typography className={classes.titleText}>
        {title}
      </Typography>

      <Box className={classes.selectContainer}>
        <Select
          disableUnderline
          IconComponent={ExpandMoreIcon}
          displayEmpty
          renderValue={() => getItemLabel(selectedItem)}
          fullWidth
          className={classes.filterInput}
          value={getItemValue(selectedItem)}
          onChange={(event) => onChangeValue(event.target.value)}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {items.map((item, id) => (
            <MenuItem key={id} value={getItemValue(item)}>
              {getItemLabel(item)}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  )
}


Selector.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  getItemValue: PropTypes.func,
  getItemLabel: PropTypes.func,
  selectedItem: PropTypes.any,
  onChangeValue: PropTypes.func
}

export default Selector