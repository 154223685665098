import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors, CommonFonts } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    label: {
      ...CommonFonts.darkFont12
    },
    errorLabel: {
      ...CommonFonts.darkFont12,
      color: Colors.RED
    },
    inputClass: {
      width: '100% !important',
      height: '40px !important',
      border: 'none !important',
      borderBottom: `1px solid ${Colors.GRAY_BORDER1} !important`,
      borderRadius: '0px !important',
      fontFamily: 'Montserrat',
      fontSize: '18px !important',
      '&:hover': {
        borderBottom: `1px solid ${Colors.BLUE} !important`,
        transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
      }
    },
    dropDownClass: {
      backgroundColor: 'transparent !important',
      border: 'none !important',
      '& .selected-flag:hover': {
        backgroundColor: 'transparent !important'
      },
      '& .selected-flag': {
        backgroundColor: 'transparent !important'
      }
    }
  })
)

export default useStyles
