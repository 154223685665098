import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import BackButton from '@/components/backButton';

export default function SpacingGrid({ onArrowClick }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center">
      <BackButton onClick={onArrowClick} fontSize={'small'} />
      <div className={classes.centerNavigation}>
        <div className={classes.headerTitle}>
          {t('addStore.adding_new_store')}
        </div>
      </div>
    </Grid>
  );
}
