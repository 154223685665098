import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.grayFont16,
    },
    value: {
      ...CommonFonts.grayFont18,
    },
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    box: {
      marginBottom: theme.spacing(10),
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(25),
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(7),
      },
    },
    boxWithDescription: {
      marginBottom: theme.spacing(10),
      cursor: 'pointer',
    },
    addAction: {
      textTransform: 'capitalize',
      cursor: 'pointer',
      color: '#1174F2',
      fontSize: 18,
    },
    editIcon: {
      padding: theme.spacing(1),
    },
    titleText: {
      ...CommonFonts.darkFont18,
    },
    descriptionText: {
      ...CommonFonts.grayFont14,
    },
  })
);

export default useStyles;
