import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, Button, Box } from '@material-ui/core'
import NumberInput from '@/components/numberInput'

import { MEASURE_STR } from './helper'
import useStyles from './styles'

const Ingredient = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    ingredient,
    updateIngredient,
    removeIngredient,
  } = props

  return (
    <Grid container alignItems='center' className={classes.ingredientWrapper}>
      <Grid item xs={12} md={11}>
        <Grid container alignItems='center'>
          <Grid item xs={12} md={6} lg={4}>
            <Typography className={classes.titleText}>
              {ingredient.name}
            </Typography>
            <Typography className={classes.barcodeText}>
              {ingredient.ean_upc}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <Box style={{ paddingRight: 24, maxWidth: 200 }}>
              <NumberInput
                title={MEASURE_STR[ingredient.measure.type || 'qty']}
                value={ingredient.ingredient_measure_amount}
                onChange={(value) => updateIngredient({ ...ingredient, ingredient_measure_amount: value })}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={1}>
        <Box display='flex' justifyContent='center'>
          <Button
            color="primary"
            onClick={() => removeIngredient(ingredient)}
            className={classes.removeButton}
          >
            {t('addons.remove')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Ingredient