import React, {
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { DayOfWeek } from '@/constants/dateAndTime';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';

const DayPicker = forwardRef(
  ({ name, label, value, onChange }, ref) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const defaultValue = DayOfWeek?.find((item) => item.id === value)
      ?.name;

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const lowerCaseTitle = label.toLowerCase();
    const [selectValue, setSelectValue] = useState(defaultValue);

    useImperativeHandle(ref, () => ({
      handleOpenEditModal: () => setOpenEditModal(true),
    }));

    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    const onSave = async () => {
      try {
        setIsLoading(true);
        await onChange(
          DayOfWeek.find((item) => item.name === selectValue).id
        );
        handleCloseEditModal();
        enqueueSnackbar(
          `${capitalizeEachLetter(label)} ${
            value ? t('settings.updated') : t('settings.added')
          }`
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <>
        <Box className={classes.box}>
          <Typography className={classes.title}>{label}</Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {defaultValue ? (
              <Typography className={classes.value}>
                {defaultValue}
              </Typography>
            ) : (
              <Typography
                onClick={handleOpenEditModal}
                className={classes.addAction}
              >
                {t('settings.add')}
              </Typography>
            )}
            <IconButton
              className={classes.editIcon}
              aria-label={`edit-${lowerCaseTitle}`}
              onClick={handleOpenEditModal}
            >
              <CreateIcon />
            </IconButton>
          </Box>
        </Box>
        <Dialog
          fullWidth
          fullScreen={fullScreen}
          onClose={isLoading ? () => {} : handleCloseEditModal}
          aria-labelledby={`{edit-${lowerCaseTitle}-dialog-title}`}
          open={openEditModal}
        >
          <DialogTitle
            id={`{edit-${lowerCaseTitle}-dialog-title}`}
            onClose={handleCloseEditModal}
          >
            {`Update ${lowerCaseTitle}`}
          </DialogTitle>
          <DialogContent>
            <Select
              className={classes.dayInput}
              fullWidth
              displayEmpty
              id={'dayOfWeek'}
              name={'dayOfWeek'}
              value={selectValue}
              onChange={(event) => setSelectValue(event.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {(DayOfWeek || []).map((option, index) => {
                return (
                  <MenuItem
                    key={`${option.name}-${index}`}
                    value={option.name}
                  >
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditModal}>
              {t('settings.cancel')}
            </Button>
            <Button onClick={onSave} type="submit" color="primary">
              {t('settings.save')}
            </Button>
          </DialogActions>
          {isLoading && <PreLoader size={25} />}
        </Dialog>
      </>
    );
  }
);

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default DayPicker;
