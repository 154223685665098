import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '@/pages/notFoundPage';
import AccessMerchantAccept from '@/pages/invitation/accessMerchant/accept';
import AccessMerchantExpired from '@/pages/invitation/accessMerchant/expried';
import AccessMerchantDecline from '@/pages/invitation/accessMerchant/decline';

const Routes = () => (
  <Switch>
    <Route
      path="/invitation/access-merchant/accept/:inviteId"
      component={AccessMerchantAccept}
    />
    <Route
      path="/invitation/access-merchant/decline/:inviteId"
      component={AccessMerchantDecline}
    />
    <Route
      path="/invitation/access-merchant/expired"
      component={AccessMerchantExpired}
    />
    {/* Show Not Found page when url does not match */}
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
