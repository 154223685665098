import React from 'react'

import {
  Paper,
  Table,
  TableBody,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';

import TableHeader from './tableHeader'
import TableRow from './tableRow'

import useStyles from './styles'

const TableComponent = (props) => {
  const classes = useStyles()
  const { 
    orders, 
    isLoadingOrders, 
    currentPage, 
    onVoidOrder, 
    onRefundOrder 
  } = props

  return (
    <Table>
      {isLoadingOrders && currentPage === 1
        ?
        <div>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </div>
        :
        <>
          <TableHeader />
          <TableBody>
            {orders.map((item) => (
              <TableRow
                key={item.id}
                item={item}
                onVoidOrder={onVoidOrder}
                onRefundOrder={onRefundOrder}
              />
            ))}
          </TableBody>
        </>
      }
    </Table>
  )
}

export default TableComponent