import I18n from '@/i18n';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { transformFloat } from '@/utils/localisationUtil';
import { getTaxName } from '@/utils/taxUtil';

const getNetSale = (item) => {
  return item.total_sale.net + item.total_sale.promotion;
};

const getGrossSale = (item) => {
  return (
    item.total_sale.net +
    item.total_sale.promotion +
    item.total_sale.tax +
    item.total_sale.promotion_tax
  );
};

const hasAccounts = (childrenType) => {
  return !isEmpty(
    (childrenType.accounts || []).filter(
      (account) => account.display_in_report
    )
  );
};

export const getCSVData = (
  store,
  reportTime,
  insights,
  incomesAccountType,
  paymentsAccountType,
  salesChildrenTypes,
  paymentTypeSales,
  categories,
  totalTaxAmount,
  taxesByName,
  taxesById,
  totalExpenseTaxAmount,
  expenseTaxesByName,
  expenseTaxesById,
  localisation
) => {
  let data = [
    [
      '',
      '',
      '',
      '',
      I18n.t('workboard.run_reports.business_snapshot'),
      '',
      '',
      '',
      '',
    ],
    [],
    ['', '', '', '', store.name, '', '', '', ''],
    [],
    [
      '',
      '',
      '',
      '',
      moment(reportTime.start).format('DD/MM/YYYY HH:mm:ss') +
        ' - ' +
        moment(reportTime.end).format('DD/MM/YYYY HH:mm:ss'),
      '',
      '',
      '',
      '',
    ],
    [],
    [],
  ];

  data.push([
    I18n.t('workboard.run_reports.transactions'),
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    insights?.performances?.no_of_transactions,
  ]);

  data.push([
    I18n.t('workboard.run_reports.no_of_orders'),
    '',
    '',
    '',
    '',
    '',
    '',
    insights?.performances?.no_of_transactions,
    '',
  ]);

  data.push([
    I18n.t('workboard.run_reports.atv'),
    '',
    '',
    '',
    '',
    '',
    '',
    transformFloat(
      insights?.performances?.average_transaction_value || 0,
      localisation
    ),
    '',
  ]);

  data.push([
    I18n.t('workboard.run_reports.sales_gross'),
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    transformFloat(paymentTypeSales?.total || 0, localisation),
  ]);

  paymentTypeSales?.payment_types?.forEach((paymentType) => {
    data.push([
      paymentType.name,
      '',
      '',
      '',
      '',
      '',
      '',
      transformFloat(paymentType.amount, localisation),
      '',
    ]);
  });

  data.push([
    I18n.t('workboard.run_reports.expenses_gross'),
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    transformFloat(
      paymentsAccountType?.performance?.performance || 0,
      localisation
    ),
  ]);

  paymentsAccountType?.children_types
    ?.filter((childrenType) => hasAccounts(childrenType))
    ?.forEach((childrenType) => {
      data.push([
        childrenType.name,
        '',
        '',
        '',
        '',
        '',
        '',
        transformFloat(
          childrenType.performance.performance,
          localisation
        ),
        '',
      ]);
      childrenType.accounts
        .filter((account) => account.display_in_report)
        .forEach((account) => {
          data.push([
            '',
            account.name,
            '',
            '',
            '',
            '',
            transformFloat(
              account.performance.performance,
              localisation
            ),
            '',
            '',
          ]);
        });
    });

  data.push([
    I18n.t('workboard.run_reports.tax_summary'),
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    transformFloat(
      totalTaxAmount - totalExpenseTaxAmount,
      localisation
    ),
  ]);

  if (!isEmpty(taxesByName)) {
    data.push([
      '',
      '',
      '',
      '',
      '',
      I18n.t('workboard.run_reports.net'),
      I18n.t('workboard.run_reports.tax'),
      '',
      '',
    ]);
  }
  taxesByName.forEach((group) => {
    data.push([
      I18n.t('workboard.run_reports.tax_on_sales', {
        name: group.tax_name,
      }),
      '',
      '',
      '',
      '',
      transformFloat(group.tax.sold, localisation),
      transformFloat(group.tax.amount, localisation),
      transformFloat(group.tax.amount, localisation),
      '',
    ]);
    taxesById
      .filter(
        (tax) =>
          tax.tax_name === group.tax_name && tax.tax_rate != null
      )
      .sort((a, b) => b.tax_rate - a.tax_rate)
      .forEach((tax) => {
        data.push([
          '',
          getTaxName({ name: tax.tax_name, rate: tax.tax_rate }),
          '',
          '',
          '',
          transformFloat(tax.tax.sold, localisation),
          transformFloat(tax.tax.amount, localisation),
          '',
          '',
        ]);
      });
  });

  expenseTaxesByName.forEach((group) => {
    data.push([
      I18n.t('workboard.run_reports.tax_on_expenses', {
        name: group.tax_name,
      }),
      '',
      '',
      '',
      '',
      transformFloat(group.tax.sold, localisation),
      transformFloat(group.tax.amount, localisation),
      transformFloat(group.tax.amount, localisation),
      '',
    ]);
    expenseTaxesById
      .filter(
        (tax) =>
          tax.tax_name === group.tax_name && tax.tax_rate != null
      )
      .sort((a, b) => b.tax_rate - a.tax_rate)
      .forEach((tax) => {
        data.push([
          '',
          getTaxName({ name: tax.tax_name, rate: tax.tax_rate }),
          '',
          '',
          '',
          transformFloat(tax.tax.sold, localisation),
          transformFloat(tax.tax.amount, localisation),
          '',
          '',
        ]);
      });
  });

  data.push([
    I18n.t('workboard.run_reports.product_summary'),
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    transformFloat(
      insights?.performances?.total_value || 0,
      localisation
    ),
  ]);

  if (!isEmpty(categories)) {
    data.push([
      '',
      '',
      '',
      '',
      '',
      I18n.t('workboard.run_reports.no_of_items'),
      I18n.t('workboard.run_reports.net'),
      I18n.t('workboard.run_reports.gross'),
      '',
    ]);
  }
  categories.forEach((category) => {
    data.push([
      category.name,
      '',
      '',
      '',
      '',
      category.item_sold,
      transformFloat(getNetSale(category), localisation),
      transformFloat(getGrossSale(category), localisation),
      '',
    ]);
  });
  return data;
};
