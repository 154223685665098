import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    headerText: {
      ...CommonFonts.darkBoldFont18,
      textAlign: 'center'
    },
    closeButton: {
      padding: theme.spacing(1.5),
      backgroundColor: Colors.GRAY_LIGHT,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    disabled: {
      pointerEvents: 'none',
      opacity: 0.4
    },
    submit: {
      letterSpacing: '0.11px',
      margin: '2vh',
      marginRight: 40,
      paddingLeft: 20
    },
  })
)

export default useStyles
