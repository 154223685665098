import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress } from '@material-ui/core'

import useStyles from './styles'

const PreLoader = ({ size }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.progressWrapper}>
        <CircularProgress size={size} />
      </div>
    </div>
  )
}

PreLoader.propTypes = {
  size: PropTypes.number
}

export default PreLoader