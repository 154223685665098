import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@material-ui/core';
import SingleCheckboxInput from '@/components/singleCheckbox';
import useStyles from './styles';
import Input from '../../components/input';
import LocationItem from './locationItem';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

const StockInformation = (props) => {
  const { ingredientData, updateIngredientData, shelvesData } = props;
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const addNewLocation = () => {
    const stocks = [...ingredientData?.stocks];
    if (stocks[0]?.stock_locations) {
      stocks[0].stock_locations.push({
        id: uuid(),
        aisle: '',
        shelf: '',
      });
    } else {
      stocks[0].stock_locations = [
        {
          id: uuid(),
          aisle: '',
          shelf: '',
        },
      ];
    }
    updateIngredientData('stocks', stocks);
  };

  const updateLocation = (index, item) => {
    const stocks = [...ingredientData.stocks];
    const location = stocks[0].stock_locations;
    location[index] = {
      id: item.id,
      aisle: item.aisle,
      shelf: item.shelf,
    };
    updateIngredientData('stocks', stocks);
  };

  return (
    <Box>
      <Box marginTop={7} marginBottom={5}>
        <Typography className={classes.title}>
          {t('add_product.general_information')}
        </Typography>
      </Box>

      <Grid container spacing={10}>
        <Grid item xs={12} sm={6} md={4}>
          <Input
            name="sku_no"
            title={t('add_product.sku_number')}
            emptyLabel={t('add_product.add')}
            value={ingredientData?.sku_no}
            onChange={(value) => updateIngredientData('sku_no', value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SingleCheckboxInput
            style={{ paddingBottom: 0 }}
            isShowToast={false}
            value={ingredientData?.expires || false}
            label={t('add_product.item_expires')}
            onChange={(value) => updateIngredientData('expires', value)}
          />
        </Grid>
      </Grid>

      <Box marginY={4}>
        <Typography className={classes.title}>
          {t('add_product.locations')}
        </Typography>
      </Box>
      <Box marginY={6} display="flex" alignItems="center">
        <Typography className={classes.redirectModule}>
          {t('add_product.manage_floor_shelf')}
        </Typography>
        <Typography
          onClick={() => history.push('/modules')}
          className={classes.redirectModuleLink}
        >
          {t('add_product.modules')}
        </Typography>
      </Box>
      {ingredientData?.stocks[0]?.stock_locations?.map(
        (location, index) => (
          <LocationItem
            key={index}
            location={location}
            shelves={shelvesData?.shelves}
            updateLocation={(value) => updateLocation(index, value)}
          />
        )
      )}
      <Typography
        onClick={addNewLocation}
        className={classes.addNewLocation}
      >
        {t('add_product.add_new_location')}
      </Typography>
    </Box>
  );
};

export default StockInformation;
