import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSnackbar } from 'notistack';
import { Grid, Typography, Box } from '@material-ui/core'
import Selector from '../../components/selector'
import Input from '../../components/input'
import DateInput from '../../components/dateInput'
import PrimaryButton from '@/components/button'

import { getPaymentDue } from '../helper'
import { getLocalisationVal, transformInput } from '@/utils/localisationUtil';
import { isMoneyLessThanZero } from '@/utils/mathUtil'
import useStyles from './styles'

const Payment = ({ expense, accountsPaidTo, localisation, onAddPayment }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar();

  const paymentDue = getPaymentDue(expense)

  const [account, setAccount] = useState(null)
  const [amount, setAmount] = useState('0')
  const [paymentDate, setPaymentDate] = useState(new Date())

  const onClickPayment = () => {
    var message = ''
    if (isMoneyLessThanZero(parseFloat(amount))) {
      message = t('expenses.add_payment_amount')
    }
    if (!account) {
      message = t('expenses.select_account')
    }
    if (parseFloat(amount) > paymentDue) {
      message = t('expenses.payment_amount_exceeded')
    }
    if (message) {
      enqueueSnackbar(message, { variant: 'error' });
      return
    }

    const payment = {
      account_id: account.id,
      amount: parseFloat(amount),
      creation: {
        time: moment().format()
      },
      status: 'paid'
    }
    onAddPayment(expense.status?.name, payment)
  }

  return (
    <Grid container justify='space-between'>
      <Grid item sm={6} md={2}>
        <Typography
          className={classes.titleText}
        >
          {t('expenses.payment_outstanding')}
        </Typography>
        <Typography
          className={classes.selectorLabel}
        >
          {getLocalisationVal(localisation, paymentDue)}
        </Typography>
      </Grid>
      <Grid item sm={6} md={2}>
        <Input
          isCurrencyInput={true}
          localisation={localisation}
          title={t('expenses.add_payment_amount')}
          emptyLabel={t('add_product.add')}
          value={getLocalisationVal(localisation, amount)}
          onChange={(value) => {
            setAmount(transformInput(localisation, value))
          }}
          onBlur={() => { }}
        />
      </Grid>
      <Grid item sm={6} md={2}>
        <Selector
          title={t('expenses.paid_to')}
          emptyLabel={t('add_product.add')}
          items={accountsPaidTo}
          getItemLabel={(account) => account.name}
          selectedItem={account || {}}
          onChangeValue={setAccount}
          onBlur={() => { }}
        />
      </Grid>
      <Grid item sm={6} md={2}>
        <DateInput
          title={t('expenses.payment_date')}
          emptyLabel={t('expenses.select')}
          value={paymentDate}
          onChange={setPaymentDate}
        />
      </Grid>
      <Grid item sm={6} md={2}>
        <Box display='flex' justifyContent='flex-end'>
          <PrimaryButton
            className={classes.addButton}
            color="primary"
            variant="contained"
            onClick={onClickPayment}
          >
            {t('expenses.add_payment')}
          </PrimaryButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Payment