export const TRIGGERS = {
  TRIGGER_MANUAL_START: 'TriggerManualStart',
  TRIGGER_START: 'TriggerStart',
  TRIGGER_STORE_PROFILE: 'TriggerStoreProfile',
  TRIGGER_USER_MANAGEMENT: 'TriggerUserManagement',
  TRIGGER_RUN_REPORTS: 'TriggerRunReports',
  TRIGGER_TOUCHPOINTS: 'TriggerTouchpoints'
}

export const TUTORIAL_TARGET_CLASSES = {
  START: 'tutorial_start',
  STORE_PROFILE: 'tutorial_store_profile',
  INVENTORY: 'tutorial_inventory',
  ORDERS: 'tutorial_orders',
  EXPENSES: 'tutorial_expenses',
  CUSTOMERS: 'tutorial_customers',
  USER_MANAGEMENT: 'tutorial_user_management',
  TEAMS: 'tutorial_teams',
  SETTINGS: 'tutorial_settings',
  TOUCHPOINTS: 'tutorial_touchpoints',
  RUN_REPORT: 'tutorial_run_report',
  ADD_WIDGETS: 'tutorial_add_widgets',
  STORE_SELECT: 'tutorial_store_select'
}