import React from 'react';
import {
  FormControlLabel,
  Typography,
  Switch,
  withStyles
} from '@material-ui/core';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { capitalizeEachLetter } from '@/utils/stringUtil';

const SingleSwitchInput = ({
                             name,
                             label,
                             notificationMessage,
                             style,
                             description,
                             value,
                             disabled = false,
                             onChange
                           }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleChange = async (valueSwitch) => {
    try {
      await onChange(valueSwitch);
      enqueueSnackbar(
        `${capitalizeEachLetter(label || notificationMessage)} ${t('settings.updated')}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.boxSwitch} style={style}>
      <FormControlLabel
        className={classes.titleText}
        control={
          <CustomSwitch
            checked={value}
            onChange={(event) => handleChange(event.target.checked)}
            name={name}
            disabled = {disabled}
          />
        }
        label={label}
      />
      {description && (
        <Typography className={classes.descriptionText}>
          {description}
        </Typography>
      )}
    </div>
  );
};

const CustomSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: '#55CC66'
    },
    '&$checked + $track': {
      backgroundColor: '#55CC66',
      opacity: 0.4
    }
  },
  checked: {},
  track: {}
})(Switch);

export default SingleSwitchInput;
