import React from 'react'
import { useTranslation } from 'react-i18next';

import { Box, Typography, Grid, Button } from '@material-ui/core'

import useStyles from './styles'

const OptionItem = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    label1,
    label2,
    onRemove
  } = props

  return (
    <Grid container alignItems='center' className={classes.container}>
      <Grid item xs={6}>
        <Typography className={classes.labelText}>
          {label1}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography className={classes.labelText}>
          {label2}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Box display='flex' justifyContent='flex-end'>
          <Button
            color="primary"
            onClick={onRemove}
            style={{ textTransform: 'none' }}
          >
            {t('button_group.remove')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default OptionItem
