import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { find, isEmpty } from 'lodash';

import Joyride, { STATUS, ACTIONS, EVENTS } from 'react-joyride';

import WelcomeModal from './welcomeModal';

import { selectTutorials } from '@/store/modules/tutorial/selectors';
import { selectAccessToken } from '@/store/modules/auth/selectors';

import TutorialActions from '@/store/modules/tutorial/actions';
import { TRIGGERS, TUTORIAL_TARGET_CLASSES } from './helper';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { CURRENT_USER } from '@/services/userService';
import { MUTATE_USER_DETAIL } from '@/services/userManagementService';

const JoyRider = () => {
  const { t } = useTranslation();

  const accessToken = useSelector(selectAccessToken);
  const tutorials = useSelector(selectTutorials);
  const manualStart = useSelector(state => state.tutorial?.[TRIGGERS.TRIGGER_MANUAL_START])

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [openWelcomeModal, setOpenWelcomeModal] = useState(false);
  const [userId, setUserId] = useState('');
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const [getCurrentUser] = useLazyQuery(
    gql`
      ${CURRENT_USER}
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setUserId(data.currentUser.id);
        const bmTutorial = find(
          data?.currentUser?.config?.tutorials,
          { type: 'business_manager' }
        );

        dispatch(
          TutorialActions.setTutorials(bmTutorial?.tutorials || [])
        );
      },
    }
  );

  const [updateUserMutation] = useMutation(
    gql`
      ${MUTATE_USER_DETAIL}
    `,
    {
      onCompleted: () => {
        const newTutorials = tutorials.map(tutorial => ({
          ...tutorial,
          status: true
        }))
        dispatch(TutorialActions.setTutorials(newTutorials));
      },
    }
  );

  const steps = tutorials.filter(tutorial => !tutorial.status).map((tutorial) => ({
    disableBeacon: true,
    disableOverlayClose: true,
    title: <p>{t(`joy_rider.${tutorial.feature}.title`)}</p>,
    content: <div>{t(`joy_rider.${tutorial.feature}.content`)}</div>,
    placement: 'right',
    target: `.tutorial_${tutorial.feature}`,
  }));

  useEffect(() => {
    if (accessToken) {
      getCurrentUser(); // fetch BM tutorials
    }
  }, [accessToken]);

  useEffect(() => {
    if (manualStart) {
      if (location.pathname !== '/workboard') {
        history.push('/workboard')
      }

      const newTutorials = tutorials.map(tutorial => ({
        ...tutorial,
        status: false
      }))
      dispatch(TutorialActions.setTutorials(newTutorials));

      setTimeout(() => {
        setRun(true)
      }, 600);
    }
  }, [manualStart])

  useEffect(() => {
    if (location.pathname === '/workboard') {
      if (!isEmpty(steps) && !manualStart) {
        if (steps[0].target === `.${TUTORIAL_TARGET_CLASSES.START}`) {
          setOpenWelcomeModal(true);
        } else {
          setRun(true);
        }
      }
    }
  }, [location]);

  const updateTutorialStatus = () => {
    dispatch(TutorialActions.initState(TRIGGERS.TRIGGER_MANUAL_START, false))
    updateUserMutation({
      variables: {
        input: {
          id: userId,
          config: {
            tutorials: [
              {
                type: 'business_manager',
                tutorials: tutorials.map((tutorial) => ({
                  feature: tutorial.feature,
                  order: tutorial.order,
                  status: true,
                })),
              },
            ],
          },
        },
      },
    });
  };

  const onStartTutorial = () => {
    setOpenWelcomeModal(false);
    setRun(true);
  };

  const onSkipTutorial = () => {
    setOpenWelcomeModal(false);
    updateTutorialStatus();
  };

  const handleJoyrideCallback = (data) => {
    const { status, type, index, action, step } = data;

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      action === ACTIONS.CLOSE
    ) {
      setRun(false);
      setStepIndex(0);
      updateTutorialStatus();
    } else if (
      [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)
    ) {
      const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (step.target === `.${TUTORIAL_TARGET_CLASSES.START}`) {
        dispatch(
          TutorialActions.runTrigger(TRIGGERS.TRIGGER_STORE_PROFILE)
        );
      }

      if (
        step.target === `.${TUTORIAL_TARGET_CLASSES.STORE_PROFILE}`
      ) {
        if (action === ACTIONS.PREV) {
          dispatch(
            TutorialActions.runTrigger(TRIGGERS.TRIGGER_START)
          );
        }
      }

      if (step.target === `.${TUTORIAL_TARGET_CLASSES.INVENTORY}`) {
        if (action === ACTIONS.NEXT) {
          dispatch(
            TutorialActions.runTrigger(
              TRIGGERS.TRIGGER_USER_MANAGEMENT
            )
          );
        }
      }

      if (step.target === `.${TUTORIAL_TARGET_CLASSES.TOUCHPOINTS}`) {
        if (action === ACTIONS.NEXT) {
          dispatch(
            TutorialActions.runTrigger(TRIGGERS.TRIGGER_RUN_REPORTS)
          );
        }
      }

      if (step.target === `.${TUTORIAL_TARGET_CLASSES.RUN_REPORT}`) {
        if (action === ACTIONS.PREV) {
          dispatch(
            TutorialActions.runTrigger(TRIGGERS.TRIGGER_TOUCHPOINTS)
          );
        }
      }

      setTimeout(() => {
        setStepIndex(nextIndex);
      }, 400);
    }

    // console.log('tutorialData:', data)
  };

  return (
    <React.Fragment>
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        run={run}
        scrollToFirstStep={true}
        showProgress={false}
        showSkipButton={true}
        steps={steps}
        stepIndex={stepIndex}
        locale={{
          back: t('joy_rider.back'),
          last: t('joy_rider.close'),
          next: t('joy_rider.next'),
          skip: t('joy_rider.skip'),
        }}
        styles={{
          tooltipTitle: {
            textAlign: 'start',
          },
          tooltipContent: {
            textAlign: 'start',
            padding: 0,
          },
          options: {
            arrowColor: '#BD10E0',
            backgroundColor: '#BD10E0',
            primaryColor: '#BD10E0',
            textColor: '#fff',
            zIndex: 10000,
          },
          buttonBack: {
            color: '#fff',
            fontFamily: 'Montserrat',
          },
          buttonNext: {
            fontFamily: 'Montserrat',
            backgroundColor: '#770C8D',
          },
          buttonSkip: {
            fontFamily: 'Montserrat',
          },
          buttonClose: {
            display: 'none',
          },
          beaconOuter: {
            borderWidth: 0,
          },
        }}
      />

      <WelcomeModal
        open={openWelcomeModal}
        onClose={onSkipTutorial}
        onStart={onStartTutorial}
        onSkip={onSkipTutorial}
      />
    </React.Fragment>
  );
};

export default JoyRider;
