import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setCurrentMerchant: ['data'],
  updateMerchant: ['merchant'],
  setSelectedStore: ['data'],
  setSelectedRegion: ['selectedRegion'],
  addStore: ['data'],
  updateSelectedStore: ['data'],
  setMerchantSettings: ['merchantSettings']
});

export const MerchantTypes = Types;
export default Creators;
