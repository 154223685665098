import React, { useEffect, useState } from 'react';

import {
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import MappingSelectPicker from '../mappingSelectPicker';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { MappingProperties } from '@/constants/import';

const MappingColumnProperty = ({ mappingData, setMappingData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [mappingProperties, setMappingProperties] =
    useState(mappingData);

  useEffect(() => {
    setMappingData(mappingProperties);
  }, [mappingProperties]);

  const generateExcludeMapping = (mapTo) =>
    mappingData
      ?.filter(
        (item) =>
          item.mapTo !== '' &&
          item.mapTo !== mapTo &&
          item.mapTo !== MappingProperties[0]?.id
      )
      ?.map((item) => item.mapTo);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography className={classes.title}>
        {t('import.map_column_property')}
      </Typography>
      <Box marginTop={7}>
        <Typography className={classes.description}>
          {t('import.map_column_property_description')}
        </Typography>
        <Typography className={classes.description}>
          {t('import.change_manually')}
        </Typography>
      </Box>

      <Box className={classes.boxTable}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: '40%' }}>
                <Typography className={classes.headerTable}>
                  {t('import.data_from_your_file')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell />
              <StyledTableCell style={{ width: '40%' }}>
                <Typography className={classes.headerTable}>
                  {t('import.properties_in_myda')}
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappingProperties?.map((row, index) => (
              <TableRow hover key={row.id}>
                <StyledTableCell>
                  <div className={classes.boxFullName}>
                    <Typography className={classes.mappingHeaderText}>
                      {row.header}
                    </Typography>
                    <Typography
                      className={classes.mappingFirstRowText}
                    >
                      {row.firstRow}
                    </Typography>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <ArrowForwardIcon />
                </StyledTableCell>
                <StyledTableCell>
                  <MappingSelectPicker
                    key={row.header}
                    name={row.header}
                    value={row.mapTo}
                    onChange={(value) => {
                      const list = [...mappingProperties];
                      list[index].mapTo = value;
                      setMappingProperties(list);
                    }}
                    excludingMappingData={generateExcludeMapping(
                      row.mapTo
                    )}
                  />
                </StyledTableCell>
              </TableRow>
            ))}

            <TableRow>
              <StyledTableCell colSpan={3}>
                <Box display="flex" alignItems="center">
                  <CustomCheckbox checked disabled />
                  <Typography
                    className={classes.description}
                    style={{ marginLeft: 10 }}
                  >
                    {t('import.do_not_import_first_row')}
                  </Typography>
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: 16,
  },
  body: {
    borderBottom: 'none',
    fontSize: 16,
  },
}))(TableCell);

const CustomCheckbox = withStyles({
  root: {
    '&$disabled': {
      color: '#bdbdbd',
    },
  },
  checked: {
    color: '#55CC66',
  },
})((props) => <Checkbox color="default" {...props} />);

export default MappingColumnProperty;
