import React, { useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import PreLoader from '@/components/preLoader';
import { useFormik } from 'formik';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import * as Yup from 'yup';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useTranslation } from 'react-i18next';
import useBusinessPicker from '@/hooks/useBusinessPicker';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';
import _ from 'lodash';

const filter = createFilterOptions();

const businessTypeSchema = Yup.object().shape({
  businessType: Yup.object({
    __typename: Yup.string().required(),
    id: Yup.string().required(),
    name: Yup.string().required(),
  }).required('Business type is required!'),
});

const SettingBusinessType = ({ defaultTypeId }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    query: {
      loading,
      error,
      data: businessTypeResponse,
      previousData,
    },
    updateBusinessType,
    addBusinessType,
  } = useBusinessPicker();

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    handleReset();
  };

  const title = t(
    'settings.business_and_finance.input.business_type'
  );
  const lowerCaseTitle = title.toLowerCase();

  const businessTypeList =
    businessTypeResponse?.globalDatasets?.business_types ||
    previousData?.globalDatasets?.business_types;

  const defaultType =
    businessTypeList?.find((type) => type.id === defaultTypeId) || {};

  const {
    values,
    handleSubmit,
    handleReset,
    setFieldError,
    setFieldValue,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      businessType: defaultType,
    },
    validationSchema: businessTypeSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await updateBusinessType(values.businessType);
        handleCloseEditModal();
        enqueueSnackbar(
          `${capitalizeEachLetter(title)} ${
            !_.isEmpty(defaultType)
              ? t('settings.updated')
              : t('settings.added')
          }`
        );
      } catch (error) {
        setFieldError('businessType', t('settings.error.update'));
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <Box className={classes.box}>
        <Typography className={classes.title}>{title}</Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {!_.isEmpty(defaultType) ? (
            <Typography className={classes.value}>
              {defaultType.name}
            </Typography>
          ) : (
            <Typography
              onClick={handleOpenEditModal}
              className={classes.addAction}
            >
              {t('settings.select')}
            </Typography>
          )}
          <IconButton
            className={classes.editIcon}
            aria-label={`edit-${lowerCaseTitle}`}
            onClick={handleOpenEditModal}
          >
            <CreateIcon />
          </IconButton>
        </Box>
      </Box>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        onClose={isLoading ? () => {} : handleCloseEditModal}
        aria-labelledby={`{edit-${lowerCaseTitle}-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{edit-${lowerCaseTitle}-dialog-title}`}
          onClose={handleCloseEditModal}
        >
          {`Update ${lowerCaseTitle}`}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Autocomplete
              closeIcon={
                !_.isEmpty(values.businessType) ? (
                  <CloseIcon fontSize="small" />
                ) : null
              }
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              loading={loading}
              id={'businessType'}
              options={businessTypeList}
              value={values['businessType']}
              onChange={(event, value) => {
                setFieldValue('businessType', value);
              }}
              classes={{
                inputRoot: classes.input,
              }}
              renderInput={(params) => <TextField {...params} />}
              getOptionLabel={(option) => option.name}
              renderOption={(option, { inputValue }) => {
                const matches = match(option.name, inputValue);
                const parts = parse(option.name, matches);

                if (!option.isNewButton) {
                  return (
                    <Box paddingX={2} paddingY={3}>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 600 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      className={classes.addButtonWrapper}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                      paddingY={3}
                    >
                      <span
                        style={{ fontWeight: 600, marginLeft: 16 }}
                      >
                        {inputValue}
                      </span>
                      <Button
                        color="primary"
                        className={classes.addButton}
                        onClick={() => addBusinessType(inputValue)}
                      >
                        + Add new
                      </Button>
                    </Box>
                  );
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (
                  params.inputValue !== '' &&
                  !filtered.includes(params.inputValue)
                ) {
                  filtered.push({
                    name: params.inputValue,
                    isNewButton: true,
                  });
                }
                return filtered;
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>Cancel</Button>
          <Button
            disabled={!isValid}
            onClick={handleSubmit}
            type="submit"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default SettingBusinessType;
