import React, { useRef, useEffect } from 'react';
import { Dialog, Popover } from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Modal = ({
  children,
  maxWidth,
  openDialog,
  handleClose,
  anchorEl,
}) => {
  const classes = useStyles();

  return (
    <Popover 
      open={openDialog} 
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      anchorEl={anchorEl}
      className={classes.root}
      PaperProps={{
        elevation: 0,
        variant: 'outlined',
      }}
    >
      {children}
    </Popover>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  openDialog: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default (Modal)
