import React, { useState } from 'react';
import { isEmpty, some } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { ADD_NEW_SECRET, REVOKE_SECRET } from '@/services/secretService';
import TextField from '@/components/textField';
import useStyles from './styles';

const ConfirmDialog = ({
                         secretsList,
                         dialogTitle,
                         selectedItem = {},
                         open,
                         handleClose,
                         actionType,
                         dialogDescription,
                         setRefetchSelects
                       }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [confirmedValue, setConfirmedValue] = useState('');

  const [RevokeSecret, { loading: deletingSegment }] =
    useMutation(REVOKE_SECRET, {
      onCompleted: (response) => {
        enqueueSnackbar(confirmedValue + ' ' + t('developer.secret_revoked_successfully'));
        handleClose();
        setRefetchSelects();
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    });

  const [AddNewSecret, { loading: loadingSaveCustomer }] =
    useMutation(ADD_NEW_SECRET, {
      onCompleted: (response) => {
        enqueueSnackbar(confirmedValue + ' ' + t('developer.secret_created_successfully'));
        handleClose();
        setRefetchSelects();
      },

      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    });

  // Save new segment created
  const addNewSecret = () => {
    const payload = {
      variables: {
        input: {
          name: confirmedValue
        }
      }
    };

    return AddNewSecret(payload);
  };

  // Save updated secret
  const revokeSecret = () => {
    const payload = {
      variables: {
        id: selectedItem?.id
      }
    };

    return RevokeSecret(payload);
  };

  const disable = some(secretsList, ['name', confirmedValue]);

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            alignContent="center"
          >
            <Grid className={classes.headerText} item xs={11}>
              <Typography variant='subtitle2' gutterBottom={'true'}>
                {dialogTitle}
              </Typography>
              <Typography variant='body2' gutterBottom={'true'}>
                {dialogDescription}
              </Typography>
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" alignContent="center">
            <Grid xs={12} item row className={classes.gridItem}>
              <TextField
                id="standard-basic"
                name="name"
                fullWidth
                error={disable}
                placeholder={selectedItem.name || t('developer.default_label')}
                onChange={(e) => setConfirmedValue(e.target.value)}
                helperText={disable && t('developer.already_exist')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: 30 }}>
          <Button
            color="primary"
            onClick={handleClose}
            className={classes.cancelButton}
          >
            {t('customers.cancel')}
          </Button>
          {actionType === 'revoke' && <Button
            disabled={confirmedValue !== selectedItem?.name}
            color="secondary"
            onClick={revokeSecret}
          >
            {t('developer.revoke')}
          </Button>}
          {actionType === 'add' && <Button
            color="primary"
            disabled={isEmpty(confirmedValue) || disable}
            onClick={addNewSecret}
          >
            {t('developer.add')}
          </Button>
          }
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;