import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  findProductText: {
    ...CommonFonts.darkFont14,
    fontWeight: 500,
    textAlign: 'center'
  },
  productSearchWrapper: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing(7),
  },
  ingredientWrapper: {
    padding: theme.spacing(3, 0),
    borderBottom: `1px solid #939da833`,
  },
  removeButton: {
    ...CommonFonts.blueFont14,
    textTransform: 'none'
  },
  titleText: {
    ...CommonFonts.darkFont14,
  },
  barcodeText: {
    ...CommonFonts.darkFont12,
  },
}));

export default useStyles;
