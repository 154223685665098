import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { find } from 'lodash'
import classNames from 'classnames';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  DialogActions,
  Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import InputWithLabel from './components/input'
import Selector from './components/selector'

import useStyles from './styles'

const AdjustStockSection = ({
  sectionType,
  onChangeSectionType,
  reasons,
  selectedProduct,
  onRemoveProduct,
  onUpdateStock
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const SECTION_TYPE = {
    ADJUST_STOCK: 'ADJUST_STOCK',
    ADD_NEW_STOCK: 'ADD_NEW_STOCK'
  }

  const [currentStock, setCurrentStock] = useState(selectedProduct?.stocks?.[0]?.current_stock || '0')
  const [newStock, setNewStock] = useState('0')
  const [stockAdjustReason, setStockAdjustReason] = useState(null)

  const onClickAddOrAdjust = () => {
    let variables = {}
    if (sectionType === SECTION_TYPE.ADJUST_STOCK) {
      variables = {
        product: {
          name: selectedProduct.name, // mandatory input field for product update
          bar_code: selectedProduct.bar_code, // mandatory input field for product update
          category_id: selectedProduct.category_id, // mandatory input field for product update
          stocks: {
            stock_infos: {
              stock: (+currentStock) - (+selectedProduct?.stocks?.[0]?.current_stock), // we send adjusted stock amount
              reason_id: stockAdjustReason.id
            }
          }
        }
      }
    } else {
      variables = {
        product: {
          name: selectedProduct.name, // mandatory input field for product update
          bar_code: selectedProduct.bar_code, // mandatory input field for product update
          category_id: selectedProduct.category_id, // mandatory input field for product update
          stocks: [
            {
              stock: +newStock
            }
          ]
        }
      }
    }
    onUpdateStock(variables)
  }

  return (
    <Box>
      <Box paddingX={10} paddingBottom={10} minHeight={300}>
        <Box className={classes.productContainer}>
          <Box
            margin='auto'
            maxWidth={300}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box>
              <Typography className={classes.productDescriptionText}>
                {selectedProduct.name}
              </Typography>
              <Typography className={classes.productDescriptionText}>
                {`${t('workboard.add_stock.barcode')}: ${selectedProduct.bar_code}`}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={onRemoveProduct} className={classes.closeButton}>
                <CloseIcon fontSize='default' />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Grid
          container
          spacing={10}
          style={{ marginTop: 36 }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            className={classNames({ [classes.disabled]: sectionType === SECTION_TYPE.ADD_NEW_STOCK })}
          >
            <InputWithLabel
              type="number"
              title={t('workboard.add_stock.current_stock')}
              emptyLabel={t('button_group.add')}
              value={currentStock}
              onChange={setCurrentStock}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            {sectionType === SECTION_TYPE.ADD_NEW_STOCK
              ?
              <InputWithLabel
                type="number"
                title={t('workboard.add_stock.new_stock_to_add', { measure_type: selectedProduct.measure.type })}
                emptyLabel={t('button_group.add')}
                value={newStock}
                onChange={setNewStock}
              />
              :
              <Selector
                title={t('workboard.add_stock.reason')}
                emptyLabel={t('workboard.add_stock.select_reason')}
                items={reasons}
                getItemLabel={(reason) => reason.name}
                selectedItem={find(reasons, { id: stockAdjustReason?.id }) || {}}
                onChangeValue={setStockAdjustReason}
              />
            }

          </Grid>
        </Grid>

        <Box marginTop={9} className={classes.enterBarcodeText}>
          <Trans
            i18nKey={sectionType === SECTION_TYPE.ADD_NEW_STOCK
              ? 'workboard.add_stock.click_here_to_adjust_current_stock'
              : 'workboard.add_stock.click_herer_to_add_new_stock'
            }
            components={{
              tag: <span
                className={classes.clickHereText}
                onClick={() => {
                  setCurrentStock(selectedProduct?.stocks?.[0]?.current_stock || '0')
                  onChangeSectionType()
                }}
              />
            }}
          />
        </Box>
      </Box>


      <DialogActions>
        <Button
          color="primary"
          onClick={onRemoveProduct}
          className={classes.cancelButton}
        >
          {t('button_group.cancel')}
        </Button>
        <Button
          disabled={
            sectionType === SECTION_TYPE.ADD_NEW_STOCK && newStock === '0' ||
            sectionType === SECTION_TYPE.ADJUST_STOCK && !stockAdjustReason
          }
          color="primary"
          onClick={onClickAddOrAdjust}
        >
          {sectionType === SECTION_TYPE.ADD_NEW_STOCK
            ? t('button_group.add')
            : t('button_group.adjust')
          }
        </Button>
      </DialogActions>
    </Box>
  )
}

export default AdjustStockSection