export const GET_SUBSCRIPTION = `
query getSubscriptions{
    subscriptions{
        id
        next_bill_amount
        next_payment_date
        items {
            id
            type
            item
            item_type
            package_id
            store{
                id
            }
            user{
                id
            }
        }
        plan{
            id
            group
            name
            description
            position
            status
        
            packages{
            id
            type
            prices{
                gross_amount
                gross_pro_rated
                next_payment_date
                type
            }
            }
            currency
        }
    }
  }
`;

export const GET_RESOURCE = `
query getResource{
    resources {
        id
        provider
        source
        is_default
        card {
            last4
            brand
            exp_month
            exp_year
        }
    }
  }
`;

export const ADD_SUBSCRIPTION = `
mutation addSubscription($input: [SubscriptionInput!]) {
    addSubscriptions(input: $input) {
      id
    }
  }
`;

export const UPDATE_SUBSCRIPTION = `
mutation updateSubscription($input: [UpdateSubscriptionInput!]) {
    updateSubscriptions(input: $input) {
      id
    }
  }
`;

export const ADD_RESOURCE = `
mutation addResource($input: ResourceInput!) {
  addResource(input: $input) {
    id
  }
}
`;
