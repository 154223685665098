import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import useStyles from './styles'

const Phone = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    phone,
    onChange,
    onBlur,
    error,
    helperText,
    ...restProps
  } = props

  return (
    <React.Fragment>
      <label className={classes.label}>{t('signup.mobile_number')}</label>
      <PhoneInput
        country={phone.country}
        value={phone.number}
        onChange={onChange}
        onBlur={onBlur}
        inputClass={classes.inputClass}
        buttonClass={classes.dropDownClass}
        {...restProps}
      />
      {error &&
        <label className={classes.errorLabel}>{helperText}</label>
      }
    </React.Fragment>
  )
}

Phone.propTypes = {
  phone: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string
}

export default Phone