import React, { useState } from 'react';

import {
  Box,
  Grid,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import GoogleMapsPlaces from '@/components/addressAutocomplete';
import { getFullAddressOfStore } from '@/utils/stringUtil';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countries } from '@/pages/store/addStore/components/helper';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';

const RegisteredAddressPicker = ({
  subTitle,
  name,
  label,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object({
    line1: yup
      .string(
        t('settings.business_and_finance.address.stress_name_fill')
      )
      .required(
        t(
          'settings.business_and_finance.address.street_name_required'
        )
      ),
    door_number: yup
      .string(
        t('settings.business_and_finance.address.street_number_fill')
      )
      .required(
        t(
          'settings.business_and_finance.address.street_number_required'
        )
      ),

    city_town: yup
      .string(t('settings.business_and_finance.address.city_fill'))
      .required(
        t('settings.business_and_finance.address.city_required')
      ),
    postcode: yup
      .string(
        t('settings.business_and_finance.address.postcode_fill')
      )
      .required(
        t('settings.business_and_finance.address.postcode_required')
      ),
    country: yup
      .string(t('settings.business_and_finance.address.country_fill'))
      .required(
        t('settings.business_and_finance.address.country_required')
      ),

    building_name: yup
      .string(t('settings.business_and_finance.address.building_name_fill'))
      .nullable(),
  });

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const country = localStorage.getItem('country');

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isManualSetting, setIsManualSetting] = useState(false);
  const lowerCaseTitle = label.toLowerCase();

  const addressValue = value ? getFullAddressOfStore(value) : '';

  const [address, setAddress] = useState({
    door_number: '',
    building_name: '',
    line1: '',
    line2: '',
    city_town: '',
    postcode: '',
    county: '',
    country: country,
  });

  const [buildingName, setBuildingName] = useState('');

  const {
    handleSubmit,
    handleChange,
    errors,
    touched,
    values,
    isValid,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      door_number: value?.door_number,
      building_name: value?.building_name,
      line1: value?.line1,
      line2: value?.line2,
      city_town: value?.city_town,
      postcode: value?.postcode,
      floor: value?.floor,
      unit: value?.unit,
      country: country,
    },
    validationSchema: isManualSetting ? validationSchema : null,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await onChange(isManualSetting ? values : { ...address });
        handleCloseEditModal();
        enqueueSnackbar(
          `${capitalizeEachLetter(label)} ${value ? t('settings.updated') : t('settings.added')
          }`
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
    setFieldValue('door_number', value?.door_number)
    setFieldValue('building_name', value?.building_name)
    setFieldValue('line1', value?.line1)
    setFieldValue('line2', value?.line2)
    setFieldValue('city_town', value?.city_town)
    setFieldValue('postcode', value?.postcode)
    setFieldValue('country', country)
    setFieldValue('floor', value?.floor)
    setFieldValue('unit', value?.unit)
  }

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setIsManualSetting(false)
  }

  return (
    <>
      <Box className={classes.box}>
        <Typography className={classes.title}>{label}</Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {addressValue ? (
            <Typography className={classes.value}>
              {addressValue}
            </Typography>
          ) : (
            <Typography
              onClick={handleOpenEditModal}
              className={classes.addAction}
            >
              {t('settings.add')}
            </Typography>
          )}
          <IconButton
            className={classes.editIcon}
            aria-label={`edit-${lowerCaseTitle}`}
            onClick={handleOpenEditModal}
          >
            <CreateIcon />
          </IconButton>
        </Box>
      </Box>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        onClose={isLoading
          ? () => { }
          : () => {
            handleCloseEditModal()
          }
        }
        aria-labelledby={`{edit-${lowerCaseTitle}-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{edit-${lowerCaseTitle}-dialog-title}`}
          onClose={handleCloseEditModal}
        >
          {`Update ${lowerCaseTitle}`}
        </DialogTitle>
        <DialogContent>
          {!isManualSetting ? (
            <Box>
              <Box>
                <Typography className={classes.subTitle}>
                  {subTitle}
                </Typography>
              </Box>
              <GoogleMapsPlaces
                icon="location"
                getValues={(value) => {
                  setAddress({
                    door_number: value.door_number || '',
                    building_name: value.building_name || '',
                    line1: value.line1 || '',
                    line2: value.line2 || '',
                    city_town: value.city_town || '',
                    postcode: value.postcode || '',
                    county: value.county || '',
                    country: country,
                  });
                }}
                // setBusinessName={(value) => setBuildingName(value)}
                getFullAdress={() => { }}
                noOptionsButton={() => setIsManualSetting(true)}
              />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Box>
                <div>
                  <Grid
                    container
                    className={classes.root}
                    spacing={4}
                  >
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        required
                        id="door_number"
                        label={t('addStore.street_number')}
                        name="door_number"
                        onChange={handleChange}
                        value={values['door_number']}
                        error={
                          touched['door_number'] &&
                          Boolean(errors['door_number'])
                        }
                        helperText={
                          touched['door_number'] &&
                          errors['door_number']
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        fullWidth
                        name="building_name"
                        value={values.building_name}
                        onChange={handleChange}
                        id="building_name"
                        label={t('addStore.building_name')}
                        error={
                          touched['building_name'] &&
                          Boolean(errors['building_name'])
                        }
                        helperText={
                          touched['building_name'] &&
                          errors['building_name']
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="floor"
                        label={t('addStore.floor_number')}
                        name="floor"
                        value={values.floor}
                        onChange={handleChange}
                        error={
                          touched['floor'] && Boolean(errors['floor'])
                        }
                        helperText={
                          touched['floor'] && errors['floor']
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="unit"
                        label={t('addStore.unit')}
                        name="unit"
                        value={values.unit}
                        onChange={handleChange}
                        error={
                          touched['unit'] && Boolean(errors['unit'])
                        }
                        helperText={touched['unit'] && errors['unit']}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        required
                        id="line1"
                        label={t('addStore.street_name')}
                        name="line1"
                        value={values.line1}
                        onChange={handleChange}
                        error={
                          touched['line1'] && Boolean(errors['line1'])
                        }
                        helperText={
                          touched['line1'] && errors['line1']
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        id="city_town"
                        required
                        label={t('addStore.city')}
                        name="city_town"
                        value={values.city_town}
                        onChange={handleChange}
                        error={
                          touched['city_town'] &&
                          Boolean(errors['city_town'])
                        }
                        helperText={
                          touched['city_town'] && errors['city_town']
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        id="postcode"
                        label={t('addStore.postcode')}
                        name="postcode"
                        value={values.postcode}
                        onChange={handleChange}
                        error={
                          touched['postcode'] &&
                          Boolean(errors['postcode'])
                        }
                        helperText={
                          touched['postcode'] && errors['postcode']
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        name="country"
                        required
                        options={countries}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            label={t('addStore.country')}
                          />
                        )}
                        value={values['country']}
                        onChange={(e, value) => {
                          setFieldValue('country', value);
                        }}
                        autoComplete={false}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseEditModal}
          >
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={!isValid}
            onClick={handleSubmit}
            type="submit"
            color="primary"
          >
            {t('settings.save')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default RegisteredAddressPicker;
