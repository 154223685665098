import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import EnterMobileStep from './enterMobileStep';
import EnterOTPStep from './enterOTPStep';
import PreLoader from '@/components/preLoader';

import AuthActions, { AuthTypes } from '@/store/modules/auth/actions';
import { selectIsApiProgress } from '@/store/modules/apiLoopState';

import useStyles from './styles';

const LOGIN_STEP = {
  ENTER_MOBILE_STEP: 'ENTER_MOBILE_STEP',
  ENTER_OTP_STEP: 'ENTER_OTP_STEP',
};

const MobileLoginLayout = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    onChangeLoginLayout,
    sendPhoneOtp,
    mobileLogin,
    isSendingPhoneOtp,
    isMobileLoggingin,
  } = props;

  const [currentStep, setCurrentStep] = useState(
    LOGIN_STEP.ENTER_MOBILE_STEP
  );
  const [phone, setPhone] = useState({ number: '', country: '' });
  const [checkMobileError, setCheckMobileError] = useState(false);
  const [checkOTPError, setCheckOTPError] = useState(false);

  // Detect default country using IP
  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        setPhone({ number: '', country: data.country.toLowerCase() });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onNext = (number) => {
    sendPhoneOtp({
      number,
      onSuccess: () => setCurrentStep(LOGIN_STEP.ENTER_OTP_STEP),
      onFailed: () => setCheckMobileError(true),
    });
  };

  const onCheckOTP = (number, otp) => {
    mobileLogin({
      username: number,
      password: otp,
      onFailed: () => setCheckOTPError(true),
    });
  };

  return (
    <div className={classes.root}>
      {currentStep === LOGIN_STEP.ENTER_MOBILE_STEP && (
        <EnterMobileStep
          onChangeLoginLayout={onChangeLoginLayout}
          phone={phone}
          onChangePhone={(number, country) => {
            // let temp = number;
            // if (number.length > country.dialCode.length) {
            //   // Remove leading zeros
            //   temp = temp.substring(
            //     country.dialCode.length,
            //     temp.length
            //   );
            //   temp = temp.replace(/^0+/, '');
            //   temp = country.dialCode + temp;
            // }
            setPhone({ number });
          }}
          onNext={onNext}
          checkMobileError={checkMobileError}
        />
      )}
      {currentStep === LOGIN_STEP.ENTER_OTP_STEP && (
        <EnterOTPStep
          phone={phone}
          checkOTPError={checkOTPError}
          onBack={() => setCurrentStep(LOGIN_STEP.ENTER_MOBILE_STEP)}
          onCheckOTP={onCheckOTP}
          onResend={onNext}
        />
      )}

      {(isSendingPhoneOtp || isMobileLoggingin) && (
        <PreLoader size={59} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isSendingPhoneOtp: selectIsApiProgress(
    state,
    AuthTypes.SEND_PHONE_OTP
  ),
  isMobileLoggingin: selectIsApiProgress(
    state,
    AuthTypes.MOBILE_LOGIN
  ),
});

const actions = { ...AuthActions };

MobileLoginLayout.propTypes = {
  onChangeLoginLayout: PropTypes.func,
};

export default connect(mapStateToProps, actions)(MobileLoginLayout);
