import React, { useEffect, useState } from 'react';

import {
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

const UpdateTeam = ({
  isVisible,
  handleCloseUpdateTeamModal,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const updateTeamsSchema = Yup.object().shape({
    updateTeam: Yup.string().required(
      t('userManagement.teams.teams_name_required')
    ),
  });

  useEffect(() => {
    if (value?.name !== '') {
      setInputValue(value?.name);
    }
  }, [value?.name]);

  const {
    handleSubmit,
    handleBlur,
    errors,
    setFieldError,
    setFieldValue,
    touched,
    isValid,
  } = useFormik({
    initialValues: {
      updateTeam: inputValue,
    },
    validationSchema: updateTeamsSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await onChange({
          id: value?.id,
          name: values['updateTeam'],
        });
        handleCloseUpdateTeamModal();
        enqueueSnackbar(t('userManagement.teams.team_updated'));
      } catch (error) {
        setFieldError('updateTeam', t('settings.error.update'));
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      onClose={isLoading ? () => {} : handleCloseUpdateTeamModal}
      aria-labelledby={`{add-new-team-dialog-title}`}
      open={isVisible}
      disableBackdropClick
    >
      <DialogTitle
        id={`{add-new-team-dialog-title}`}
        onClose={handleCloseUpdateTeamModal}
      >
        {t('userManagement.teams.add_teams_dialog_title')}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            id={'updateTeam'}
            name={'updateTeam'}
            fullWidth
            value={inputValue}
            onChange={(event) => {
              const value = event.target.value;
              setInputValue(value);
              setFieldValue('updateTeam', value);
            }}
            error={
              touched['updateTeam'] && Boolean(errors['updateTeam'])
            }
            helperText={touched['updateTeam'] && errors['updateTeam']}
            autoFocus
            required
            onBlur={handleBlur}
            InputProps={{
              className: classes.input,
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseUpdateTeamModal}>
          {t('settings.cancel')}
        </Button>
        <Button
          disabled={!isValid}
          onClick={handleSubmit}
          type="submit"
          color="primary"
        >
          {t('settings.update')}
        </Button>
      </DialogActions>
      {isLoading && <PreLoader size={25} />}
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default UpdateTeam;
