import { Grid, Typography, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SettingAccordion from '.';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import {
  MERCHANT_SETTINGS,
  PRICE_TYPE_LIST,
  MERCHANT_PRICE_TYPE,
  MUTATE_MERCHANT_SETTINGS,
  MUTATE_PRICE_TYPE,
  GET_MEASURES,
  GET_GLOBAL_MEASURES,
  MUTATE_MEASURES,
} from '@/services/storeService';
import PreLoader from '@/components/preLoader';
import SingleCheckboxInput from '@/components/singleCheckbox';
import SingleSwitchInput from '@/components/singleSwitch';
import { capitalizeFirstLetter } from '@/utils/stringUtil';
import { GET_ORDER_TYPE } from '@/services/orderTypesWorkflowsService';
import UpdatingOrderTypeDialog from '../updatingOrderTypeDialog';
import UpdateStyleOptionsDialog from '../updateStyleOptionsDialog'

const InventoryAndPricing = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState(false);
  const [updateOrderTypeModal, setUpdateOrderTypeModal] = useState({
    isVisible: false,
    priceTypeName: '',
  });
  const [openStyleManagement, setOpenStyleManagement] = useState(false)

  const onExpand = (value) => setIsExpand(value);

  const merchantSettingsQuery = gql`
    ${MERCHANT_SETTINGS}
  `;

  const priceTypesQuery = gql`
    ${PRICE_TYPE_LIST}
  `;

  const merchantPriceTypeQuery = gql`
    ${MERCHANT_PRICE_TYPE}
  `;

  const measuresQuery = gql`
    ${GET_MEASURES}
  `;

  const globalMeasuresQuery = gql`
    ${GET_GLOBAL_MEASURES}
  `;

  const orderTypeQuery = gql`
    ${GET_ORDER_TYPE}
  `;

  const [
    getMerchantSetting,
    { loading: loadingMerchantSetting, data: settings },
  ] = useLazyQuery(merchantSettingsQuery);
  const merchantSettings = settings?.merchantSettings;

  const [
    getPriceType,
    { loading: loadingPriceType, data: priceTypes },
  ] = useLazyQuery(priceTypesQuery);
  const [
    getMerchantPriceType,
    { loading: loadingMerchantPriceType, data: merchantPriceType },
  ] = useLazyQuery(merchantPriceTypeQuery);

  const [
    getMeasures,
    { loading: loadingMeasures, data: measuresData },
  ] = useLazyQuery(measuresQuery);
  const measures = measuresData?.measures;

  const [
    getGlobalMeasures,
    { loading: loadingGlobalMeasures, data: globalMeasuresData },
  ] = useLazyQuery(globalMeasuresQuery);
  const globalMeasures = globalMeasuresData?.globalDatasets.measures;

  const [
    getOrderType,
    { loading: loadingOrderType, data: orderTypeData },
  ] = useLazyQuery(orderTypeQuery, { fetchPolicy: 'no-cache' });
  const orderTypes = orderTypeData?.orderTypes;

  const isLoading =
    loadingMerchantSetting ||
    loadingPriceType ||
    loadingMerchantPriceType ||
    loadingMeasures ||
    loadingGlobalMeasures ||
    loadingOrderType;

  useEffect(() => {
    if (isExpand) {
      getMerchantSetting();
      getPriceType();
      getMerchantPriceType();
      getMeasures();
      getGlobalMeasures();
      getOrderType();
    }
  }, [isExpand]);

  const updateMerchantProductSettings = (key, value) => {
    const payload = {
      variables: {
        merchantSettings: {
          products: {
            [key]: value,
          },
        },
      },
      update: (store, { data: { merchantSettings } }) => {
        const cache = store.readQuery({
          query: merchantSettingsQuery,
        });

        const updatedData = {
          ...cache,
          merchantSettings: {
            ...cache.merchantSettings,
            products: {
              ...cache.merchantSettings.products,
              [key]: value,
            },
          },
        };
        store.writeQuery({
          query: merchantSettingsQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateMerchantSettings: {
          merchantSettings: {
            products: {
              [key]: value,
            },
          },
        },
      },
    };
    return mutateMerchantSettings(payload);
  };

  const updateMerchantPricingSettings = (key, value) => {
    const payload = {
      variables: {
        merchantSettings: {
          pricings: {
            store: {
              [key]: value,
            },
          },
        },
      },
      update: (store, { data: { merchantSettings } }) => {
        const cache = store.readQuery({
          query: merchantSettingsQuery,
        });

        const updatedData = {
          ...cache,
          merchantSettings: {
            ...cache.merchantSettings,
            pricings: {
              ...cache.merchantSettings.pricings,
              store: {
                ...cache.merchantSettings.pricings.store,
                [key]: value,
              },
            },
          },
        };
        store.writeQuery({
          query: merchantSettingsQuery,
          data: updatedData,
        });
      },
      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        updateMerchantSettings: {
          merchantSettings: {
            pricings: {
              store: { [key]: value },
            },
          },
        },
      },
    };
    return mutateMerchantSettings(payload);
  };

  const isPriceTypeLinked = (type) =>
    !_.isEmpty(
      orderTypes?.find((item) => _.isEqual(item.pricing_type, type))
    );

  const updatePriceType = (value) => {
    const { id, name, type, isChecked } = value;
    const isLinked = isPriceTypeLinked(type);
    if (isLinked && !isChecked) {
      setUpdateOrderTypeModal({
        isVisible: true,
        priceTypeName: name,
      });
    } else {
      const payload = {
        variables: {
          priceTypes: {
            id: id,
            name: name,
            flag: isChecked ? '' : 'delete',
          },
        },

        update: (store, { data: { addPriceTypes } }) => {
          const cache = store.readQuery({
            query: merchantPriceTypeQuery,
          });

          const data = isChecked
            ? [
              ...cache.priceTypes,
              {
                id: id,
                name: name,
                type: type,
                __typename: 'PriceType',
              },
            ]
            : cache.priceTypes.filter(
              (item) => !_.isEqual(item.type, type)
            );
          store.writeQuery({
            query: merchantPriceTypeQuery,
            data: { ...cache, priceTypes: data },
          });
        },
        onError: (error) => {
          throw new Error(error);
        },
        optimisticResponse: {
          addPriceTypes: [
            {
              id: id,
              __typename: 'PriceType',
            },
          ],
        },
      };
      return mutatePriceType(payload);
    }
  };

  const updateMeasure = (value) => {
    const { measure, isChecked } = value;
    const payload = {
      variables: {
        input: {
          id: measure.id,
          flag: isChecked ? 'add' : 'delete',
        },
      },

      update: (store, { data: { addMeasures } }) => {
        const cache = store.readQuery({
          query: measuresQuery,
        });

        const data = isChecked
          ? [
            ...cache.measures,
            { id: measure.id, __typename: 'Measure' },
          ]
          : cache.measures.filter((item) => item.id !== measure.id);

        store.writeQuery({
          query: measuresQuery,
          data: { ...cache, measures: data },
        });
      },

      onError: (error) => {
        throw new Error(error);
      },
      optimisticResponse: {
        addMeasures: [
          {
            id: measure.id,
            __typename: 'Measure',
          },
        ],
      },
    };
    return mutateMeasures(payload);
  };

  const [mutateMerchantSettings] = useMutation(
    gql`
      ${MUTATE_MERCHANT_SETTINGS}
    `
  );

  const [mutatePriceType] = useMutation(
    gql`
      ${MUTATE_PRICE_TYPE}
    `
  );

  const [mutateMeasures] = useMutation(
    gql`
      ${MUTATE_MEASURES}
    `
  );

  return (
    <SettingAccordion
      id="inventoryAndPricing"
      title={t('settings.inventory.title')}
      onExpand={onExpand}
    >
      <>
        {isLoading ? (
          <PreLoader size={30} />
        ) : (
          <Grid container>
            {/* Pricing Section */}
            <Typography
              style={{ paddingTop: 0 }}
              className={classes.inventorySubTitle}
            >
              {t('settings.inventory.pricing')}
            </Typography>
            <Grid container spacing={10}>
              <Grid item xs={12} sm={12}>
                <SingleSwitchInput
                  name="netPrice"
                  label={t(
                    'settings.inventory.price_exclusive_taxes'
                  )}
                  description={t(
                    'settings.inventory.price_exclusive_taxes_description'
                  )}
                  value={!!merchantSettings?.products?.net_price}
                  onChange={(value) =>
                    updateMerchantProductSettings('net_price', value)
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={12} sm={12}>
                <SingleSwitchInput
                  name="samePrice"
                  label={t('settings.inventory.product_price_across')}
                  description={t(
                    'settings.inventory.product_price_across_description'
                  )}
                  value={
                    merchantSettings?.pricings?.store?.same_price ||
                    false
                  }
                  onChange={(value) =>
                    updateMerchantPricingSettings('same_price', value)
                  }
                />
              </Grid>
            </Grid>

            <Typography className={classes.inventorySubTitle}>
              {t('settings.inventory.price_types')}
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <div className={classes.boxPriceType}>
                  {priceTypes?.globalPriceTypes?.map((item) => {
                    const priceTypeExist =
                      merchantPriceType?.priceTypes?.find(
                        (priceType) =>
                          _.isEqual(priceType.type, item.type)
                      );

                    return (
                      <SingleCheckboxInput
                        isShowToast={
                          !isPriceTypeLinked(priceTypeExist?.type)
                        }
                        style={{ marginRight: '40px' }}
                        key={item.name}
                        value={!_.isEmpty(priceTypeExist)}
                        label={capitalizeFirstLetter(item.name)}
                        name={item.name}
                        onChange={(value) =>
                          updatePriceType({
                            ...item,
                            type: priceTypeExist?.type,
                            isChecked: value,
                          })
                        }
                      />
                    );
                  })}
                </div>
                <Typography className={classes.descriptionText}>
                  {t('settings.inventory.price_types_description')}
                </Typography>
              </Grid>
            </Grid>

            <Typography className={classes.inventorySubTitle}>
              {t('settings.inventory.measures')}
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <div className={classes.boxPriceType}>
                  {globalMeasures?.map((item) => (
                    <SingleCheckboxInput
                      style={{ marginRight: '40px' }}
                      key={item.name}
                      value={
                        measures?.findIndex(
                          (measure) => measure.id === item.id
                        ) !== -1
                      }
                      label={capitalizeFirstLetter(item.name)}
                      name={item.name}
                      onChange={(value) =>
                        updateMeasure({
                          measure: item,
                          isChecked: value,
                        })
                      }
                    />
                  ))}
                </div>
                <Typography className={classes.descriptionText}>
                  {t('settings.inventory.measures_description')}
                </Typography>
              </Grid>
            </Grid>

            <Typography className={classes.inventorySubTitle}>
              {t('settings.inventory.select_modules')}
            </Typography>

            <Grid container justify="space-between" spacing={3}>
              <Grid item xs={12} sm={5}>
                <SingleCheckboxInput
                  name="rawMaterials"
                  label={t('settings.inventory.raw_materials')}
                  description={t(
                    'settings.inventory.raw_materials_description'
                  )}
                  value={
                    merchantSettings?.products?.ingredient || false
                  }
                  onChange={(value) =>
                    updateMerchantProductSettings('ingredient', value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <SingleCheckboxInput
                  name="addOns"
                  label={t('settings.inventory.add_ons')}
                  description={t(
                    'settings.inventory.add_ons_description'
                  )}
                  value={merchantSettings?.products?.addon || false}
                  onChange={(value) =>
                    updateMerchantProductSettings('addon', value)
                  }
                />
              </Grid>
            </Grid>

            <Grid container justify="space-between" spacing={3}>
              <Grid item xs={12} sm={5}>
                <SingleCheckboxInput
                  name="recipeManagement"
                  label={t('settings.inventory.recipe_management')}
                  description={t(
                    'settings.inventory.recipe_manegement_description'
                  )}
                  value={merchantSettings?.products?.recipe || false}
                  onChange={(value) =>
                    updateMerchantProductSettings('recipe', value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <SingleCheckboxInput
                  name="allergyManagement"
                  label={t('settings.inventory.allergy_management')}
                  description={t(
                    'settings.inventory.allergy_management_description'
                  )}
                  value={merchantSettings?.products?.allergy || false}
                  onChange={(value) =>
                    updateMerchantProductSettings('allergy', value)
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={5}>
                <Box display='flex' justifyContent='space-between'>
                  <SingleCheckboxInput
                    name="styleManagement"
                    label={t('settings.inventory.style_management')}
                    description={t(
                      'settings.inventory.style_management_description'
                    )}
                    value={merchantSettings?.products?.style || false}
                    onChange={(value) =>
                      updateMerchantProductSettings('style', value)
                    }
                  />
                  {!!merchantSettings?.products?.style &&
                    <Typography
                      onClick={() => setOpenStyleManagement(true)}
                      className={classes.addAction}
                    >
                      {t('settings.manage')}
                    </Typography>
                  }
                </Box>
              </Grid>
            </Grid>
            {!settings && <PreLoader size={30} />}
          </Grid>
        )}
        <UpdatingOrderTypeDialog
          isVisible={updateOrderTypeModal.isVisible}
          onCloseDialog={() =>
            setUpdateOrderTypeModal({
              ...updateOrderTypeModal,
              isVisible: false,
            })
          }
          priceTypeName={updateOrderTypeModal.priceTypeName}
        />
        {openStyleManagement &&
          <UpdateStyleOptionsDialog
            open={openStyleManagement}
            handleClose={() => setOpenStyleManagement(false)}
          />
        }
      </>
    </SettingAccordion>
  );
};

export default InventoryAndPricing;
