import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
      backgroundColor: '#eeeeee',
      maxWidth: 600,
    },
    stackFullHeight: {
      minHeight: '100vh',
    },
    whiteBox: {
      backgroundColor: 'white',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(3, 2),
      marginTop: theme.spacing(0.5),
    },
    centered: {
      display: 'flex',
      justifyContent: 'center',
    },
    spaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    downloadButton: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'white',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      height: 50,
      width: "100%",
    },
    itemBox: {
      marginTop: theme.spacing(0.5),
      width: "100%",
    }
  })
);

export default useStyles;
