import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    titleText: {
      ...CommonFonts.darkFont16,
    },
    selectOptionInput: {
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
  })
);

export default useStyles;
