import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import TextField from '@/components/textField'
import Button from '@/components/button'

import { getFullAddressOfStore } from '@/utils/stringUtil'
import useStyles from './styles'

const EnterStoreName = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    address,
    storeName,
    onChangeStoreName,
    onAddStore
  } = props

  return (
    <div>
      <Box marginTop={5}>
        <Typography
          className={classes.darkFont16}
          style={{ textAlign: 'center' }}
        >
          {getFullAddressOfStore(address)}
        </Typography>
      </Box>
      <Box marginTop={20}>
        <Typography
          className={classes.darkFont16}
          style={{ textAlign: 'center' }}
        >
          {t('stores.enter_store_nickname')}
        </Typography>
        <Typography
          className={classes.darkFont14}
          style={{ textAlign: 'center' }}
        >
          {t('stores.nickname_not_visible')}
        </Typography>
      </Box>
      <Box marginTop={20}>
        <TextField
          label={t('stores.store_nickname')}
          value={storeName}
          onChange={(e) =>
            onChangeStoreName(e.target.value)
          }
        />
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        marginY={25}
      >
        <Button
          color="primary"
          className={classes.addStoreButton}
          variant="contained"
          disabled={!storeName}
          onClick={onAddStore}
        >
          {t('stores.add_store')}
        </Button>
      </Box>
    </div>
  )
}

export default EnterStoreName