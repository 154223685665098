import React from 'react'
import { useTranslation } from 'react-i18next';

import {
  Box,
  Typography,
  Grid,
} from '@material-ui/core'
import ProductSearchInput from '@/components/productSearchInput'

import useStyles from '../styles'

const ProductSelect = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { promotion, onChangePromotion } = props

  const onChangeProducts = (optionIndex, products) => {
    onChangePromotion({
      ...promotion,
      options: promotion.options.map((option, index) => index === optionIndex
        ? { ...option, products }
        : option
      )
    })
  }

  const onAddProduct = (product) => {
    onChangeProducts(
      0,
      [
        ...promotion.options[0].products,
        product
      ]
    )
  }

  return (
    <Box>
      <Typography className={classes.subTitleText} align='center'>
        {t('promotions.enter_product_name')}
      </Typography>
      <Box marginTop={12} marginBottom={40}>
        <ProductSearchInput
          onSelectProduct={onAddProduct}
        />
      </Box>
    </Box>
  )
}

export default ProductSelect