import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Typography,
  Button
} from '@material-ui/core'
import AddressAutocomplete from '@/components/addressAutocomplete';

import useStyles from '../styles'

const SearchStore = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { onSelectAddress, onClickManual } = props

  const country = localStorage.getItem('country')

  return (
    <div>
      <Box marginTop={5}>
        <Typography
          className={classes.darkFont16}
          style={{ textAlign: 'center' }}
        >
          {t('warehouses.find_your_store')}
        </Typography>
      </Box>
      <Box marginTop={20}>
        <AddressAutocomplete
          icon="search"
          getValues={(value) => {
            onSelectAddress({
              door_number: value.door_number || '',
              building_name: value.building_name || '',
              line1: value.line1 || '',
              line2: value.line2 || '',
              city_town: value.city_town || '',
              postcode: value.postcode || '',
              county: value.county || '',
              country: country,
            });
          }}
          getFullAdress={() => { }}
        />
      </Box>
      <Box marginTop={40}>
        <Typography
          className={classes.darkFont18}
          style={{ textAlign: 'center' }}
        >
          {t('warehouses.cant_find_store')}
        </Typography>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        marginTop={10}
        marginBottom={30}
      >
        <Button
          color="primary"
          onClick={onClickManual}
          style={{ textTransform: 'none' }}
        >
          {t('warehouses.add_store_manually')}
        </Button>
      </Box>
    </div>
  )
}

export default SearchStore