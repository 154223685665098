import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    minWidth: 400,
    maxWidth: 600
  },
  titleText: {
    ...CommonFonts.darkBoldFont16
  },
  labelText: {
    ...CommonFonts.darkFont16
  },
  fileNameText: {
    ...CommonFonts.grayFont16,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  addButton: {
    textTransform: 'none'
  },
  attachmentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 5, 3, 0),
    borderBottomWidth: 1,
    borderBottomColor: '#E2E2E2',
    borderBottomStyle: 'solid',
  },
  closeButton: {
    backgroundColor: Colors.GRAY_LIGHT,
    padding: 5,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}));

export default useStyles;
