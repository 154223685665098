export const MUTATE_DECLINE_INVITATION = `
mutation declineInvitation($id: String!){
    declineInvitation(input: $id)
  }
`;

export const CHECK_INVITATION = `
query checkInvitation($id: UUID!){
    checkInvitation(id: $id) {
        id
        merchant_name
        email
    }
}
`;

export const GET_INVITATIONS = `
query getInvitations($role_id: String, $team_id: String){
    invitations(role_id: $role_id, team_id: $team_id){
        created_at
        email
        expired
        id
        merchant_name
        stores{
            id
            permissions
            role_id
        }
        teams{
            id
            name
        }
        valid_to
    }
}
`;

export const CANCEL_INVITATION = `
mutation cancelInvitation($id: String!){
    cancelInvitation(input: $id)
  }
`;
