import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { last } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import {
  Grid,
  Box,
  Button,
  IconButton,
  Popover,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Calendar from 'react-calendar';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DragableWidget from './components/dragableWidget';

import AppLayout from '@/components/appLayout';
import GlobalWidgetsModal from '@/components/globalWidgetsModal';
import PreLoader from '@/components/preLoader';
import QuickAccess from './quickAccess';
import Trends from './trends';
import ProductSalesAnalysis from './productSalesAnalysis';
import CategorySalesAnalysis from './categorySalesAnalysis';
import PerformanceOverview from './performanceOverview';
import ProductIntelligence from './productIntelligence';

import {
  gql,
  useQuery,
  useMutation,
  useApolloClient,
} from '@apollo/client';
import {
  GET_WORKBOARD_WIDGETS,
  MUTATE_ADD_DASHBOARD,
} from '@/services/widgetService';

import { selectSelectedStore } from '@/store/modules/store/selectors';
import { TUTORIAL_TARGET_CLASSES } from '@/components/joyRider/helper';

import { DATE_FILTERS, getStartTime, getEndTime } from './helper';
import { widgetIds } from '@/constants/widgets';
import useStyles from './styles';
import ManageStore from '@/pages/workboard/manageStore';
import DebtsWidget from '@/pages/workboard/DebtsWidget';

const WorkboardPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const selectedStore = useSelector(selectSelectedStore);

  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentDateFilter, setCurrentDateFilter] = useState(
    DATE_FILTERS.TODAY
  );
  const [openGlobalWidgets, setOpenGlobalWidgets] = useState(false);

  const { data: workboardData, loading: isLoadingWorkboardWidgets } =
    useQuery(
      gql`
        ${GET_WORKBOARD_WIDGETS}
      `
    );
  const workboardWidgets = workboardData?.workboard?.widgets || [];

  const isWidgetAvailable = (widget) => {
    if (
      widget.id === widgetIds.TRENDS ||
      widget.id === widgetIds.PRODUCT_SALES_ANALYSIS ||
      widget.id === widgetIds.PERFORMANCE_OVERVIEW ||
      widget.id === widgetIds.CATEGORY_SALES_ANALYSIS ||
      widget.id === widgetIds.PRODUCT_INTELLIGENCE ||
      widget.id === widgetIds.DEBTS_WIDGET
    ) {
      return true;
    }
    return false;
  };

  const sortedWidgets = useMemo(() => {
    let temp = [...workboardWidgets];
    return temp
      .sort((a, b) => a.position - b.position)
      .filter((item) => isWidgetAvailable(item.widget));
  }, [workboardWidgets]);

  const [dateRange, setDateRange] = useState([
    new Date(),
    new Date(),
  ]);
  const [datePickerAnchor, setDatePickerAnchor] = useState(null);

  const [addWidgetMutate, { loading: isAdding }] = useMutation(
    gql`
      ${MUTATE_ADD_DASHBOARD}
    `,
    {
      onCompleted: () => setOpenGlobalWidgets(false),
      onError: (error) => {
        throw new Error(error);
      },
    }
  );

  const onAddWidget = (widget) => {
    const newPosition = (last(sortedWidgets)?.position || 0) + 1;
    addWidgetMutate({
      variables: {
        input: {
          id: workboardData?.workboard?.id,
          name: workboardData?.workboard?.name,
          store_id: selectedStore.id,
          period: '',
          widgets: {
            id: widget.id,
            position: newPosition,
          },
        },
      },
      update: (cache) => {
        enqueueSnackbar(
          t('workboard.widget_added', { name: widget.name })
        );
        const data = cache.readQuery({
          query: gql`
            ${GET_WORKBOARD_WIDGETS}
          `,
        });
        const newAddedWidget = {
          __typename: 'DardboardWidget',
          position: newPosition,
          widget: { __typename: 'Widget', ...widget },
        };
        const updatedData = {
          ...data,
          workboard: {
            ...data.workboard,
            widgets: [...data.workboard.widgets, newAddedWidget],
          },
        };
        cache.writeQuery({
          query: gql`
            ${GET_WORKBOARD_WIDGETS}
          `,
          data: updatedData,
        });
      },
    });
  };

  const onRemoveWidget = (widget) => {
    addWidgetMutate({
      variables: {
        input: {
          id: workboardData?.workboard?.id,
          name: workboardData?.workboard?.name,
          store_id: selectedStore.id,
          period: '',
          widgets: {
            id: widget.id,
            flag: 'delete',
          },
        },
      },
      update: (cache) => {
        enqueueSnackbar(
          t('workboard.widget_removed', { name: widget.name })
        );
        const data = cache.readQuery({
          query: gql`
            ${GET_WORKBOARD_WIDGETS}
          `,
        });

        const updatedData = {
          ...data,
          workboard: {
            ...data.workboard,
            widgets: data.workboard.widgets.filter(
              (item) => item.widget.id !== widget.id
            ),
          },
        };
        cache.writeQuery({
          query: gql`
            ${GET_WORKBOARD_WIDGETS}
          `,
          data: updatedData,
        });
      },
    });
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;

    addWidgetMutate({
      variables: {
        input: {
          id: workboardData?.workboard?.id,
          name: workboardData?.workboard?.name,
          store_id: selectedStore.id,
          period: '',
          widgets: [
            {
              id: sortedWidgets[sourceIndex].widget.id,
              position: sortedWidgets[destinationIndex].position,
            },
            {
              id: sortedWidgets[destinationIndex].widget.id,
              position: sortedWidgets[sourceIndex].position,
            },
          ],
        },
      },
    });

    const data = client.readQuery({
      query: gql`
        ${GET_WORKBOARD_WIDGETS}
      `,
    });
    var updatedWidgets = [...sortedWidgets];
    const sourcePosition = updatedWidgets[sourceIndex].position;
    const destinationPosition =
      updatedWidgets[destinationIndex].position;
    updatedWidgets = updatedWidgets.map((widget, index) => {
      if (index === sourceIndex) {
        return { ...widget, position: destinationPosition };
      }
      if (index === destinationIndex) {
        return { ...widget, position: sourcePosition };
      }
      return widget;
    });

    const updatedData = {
      ...data,
      workboard: {
        ...data.workboard,
        widgets: updatedWidgets,
      },
    };
    client.writeQuery({
      query: gql`
        ${GET_WORKBOARD_WIDGETS}
      `,
      data: updatedData,
    });
  };

  return (
    <AppLayout
      className={classes.appLayout}
      withFooter
      header
      withAllStoresOption
    >
      <Grid container>
        <Box className={classes.widgetContainer}>
          <QuickAccess />
          <ManageStore />
        </Box>
        <Grid
          container
          justify="flex-end"
          className={classes.dateFiltersContainer}
        >
          <Box display="flex" className={classes.dateFiltersWrapper}>
            {Object.keys(DATE_FILTERS).map((key, index, keyArray) => (
              <Button
                key={key}
                fullWidth
                onClick={(event) => {
                  if (DATE_FILTERS[key] === DATE_FILTERS.SELECT_DATES)
                    setDatePickerAnchor(event.currentTarget);
                  else setCurrentDateFilter(DATE_FILTERS[key]);
                }}
                className={classNames(classes.greyButton, {
                  [classes.greyFocusedButton]:
                    DATE_FILTERS[key] === currentDateFilter,
                })}
                style={{
                  borderTopLeftRadius: index === 0 ? 5 : 0,
                  borderBottomLeftRadius: index === 0 ? 5 : 0,
                  borderTopRightRadius:
                    index === keyArray.length - 1 ? 5 : 0,
                  borderBottomRightRadius:
                    index === keyArray.length - 1 ? 5 : 0,
                }}
              >
                {currentDateFilter === DATE_FILTERS.SELECT_DATES &&
                DATE_FILTERS[key] === DATE_FILTERS.SELECT_DATES
                  ? moment(dateRange[0]).format('DD MMM YY') +
                    ' - ' +
                    moment(dateRange[1]).format('DD MMM YY')
                  : DATE_FILTERS[key]}
              </Button>
            ))}
            <IconButton
              className={classNames(
                classes.closeButton,
                TUTORIAL_TARGET_CLASSES.ADD_WIDGETS
              )}
              onClick={() => setOpenGlobalWidgets(true)}
            >
              <Add fontSize="default" className={classes.closeIcon} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.widgetsContainer}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="widget-droppable">
            {(provided) => (
              <Grid
                container
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {sortedWidgets.map((item, idx) => (
                  <Grid
                    item
                    xs={item.widget.width === 1 || isDownSm ? 12 : 12}
                    key={item.widget.id}
                    className={classes.widgetContainer}
                  >
                    <DragableWidget
                      keyValue={item.widget.id}
                      draggableId={item.widget.id}
                      index={idx}
                      widget={item.widget}
                      onRemoveWidget={(widget) =>
                        onRemoveWidget(widget)
                      }
                    >
                      {item.widget.id === widgetIds.TRENDS && (
                        <Trends
                          startTime={getStartTime(
                            currentDateFilter,
                            dateRange
                          )}
                          endTime={getEndTime(
                            currentDateFilter,
                            dateRange
                          )}
                          dateFilter={currentDateFilter}
                        />
                      )}
                      {item.widget.id ===
                        widgetIds.PRODUCT_SALES_ANALYSIS && (
                        <ProductSalesAnalysis
                          startTime={getStartTime(
                            currentDateFilter,
                            dateRange
                          )}
                          endTime={getEndTime(
                            currentDateFilter,
                            dateRange
                          )}
                          dateFilter={currentDateFilter}
                        />
                      )}
                      {item.widget.id ===
                        widgetIds.PERFORMANCE_OVERVIEW && (
                        <PerformanceOverview
                          startTime={getStartTime(
                            currentDateFilter,
                            dateRange
                          )}
                          endTime={getEndTime(
                            currentDateFilter,
                            dateRange
                          )}
                          dateFilter={currentDateFilter}
                        />
                      )}
                      {item.widget.id ===
                        widgetIds.CATEGORY_SALES_ANALYSIS && (
                        <CategorySalesAnalysis
                          startTime={getStartTime(
                            currentDateFilter,
                            dateRange
                          )}
                          endTime={getEndTime(
                            currentDateFilter,
                            dateRange
                          )}
                          dateFilter={currentDateFilter}
                        />
                      )}
                      {item.widget.id ===
                        widgetIds.DEBTS_WIDGET && (
                          <DebtsWidget />
                        )}
                      {item.widget.id ===
                        widgetIds.PRODUCT_INTELLIGENCE && (
                        <ProductIntelligence
                          startTime={getStartTime(
                            currentDateFilter,
                            dateRange
                          )}
                          endTime={getEndTime(
                            currentDateFilter,
                            dateRange
                          )}
                        />
                      )}
                    </DragableWidget>
                  </Grid>
                ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      {openGlobalWidgets && (
        <GlobalWidgetsModal
          open={openGlobalWidgets}
          onClose={() => setOpenGlobalWidgets(false)}
          addedWidgets={workboardWidgets}
          onAddWidget={onAddWidget}
          isAdding={isAdding}
        />
      )}

      <Popover
        id="simple-popover"
        open={Boolean(datePickerAnchor)}
        anchorEl={datePickerAnchor}
        onClose={() => setDatePickerAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Calendar
          value={dateRange}
          onChange={(value) => {
            setDateRange(value);
            setDatePickerAnchor(null);
            setCurrentDateFilter(DATE_FILTERS.SELECT_DATES);
          }}
          showDoubleView
          selectRange
        />
      </Popover>

      {isLoadingWorkboardWidgets && <PreLoader size={50} />}
    </AppLayout>
  );
};

export default WorkboardPage;
