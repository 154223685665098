import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      width: 140,
      color: '#20272FDE',
      fontSize: 14
    },
  })
);

export default useStyles;
