import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  paperContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
    },
  },
  searchInput: {
    '& .MuiInput-root': {
      padding: theme.spacing(2),
      borderRadius: 5,
      ...CommonFonts.darkFont18,
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
  },
  addButton: {
    padding: theme.spacing(2.5, 0),
    background: '#1174F2 0% 0% no-repeat padding-box',
    textTransform: 'Uppercase',
    ...CommonFonts.whiteFont14,
  },
  emptyAddonsText: {
    ...CommonFonts.darkFont18,
  },
  disableTextTransform: {
    textTransform: 'none'
  },
  menuItem: { 
    height: 60, 
    color: '#20272FDE', 
    fontSize: 18 
  },
}));

export default useStyles;
