import { createSelector } from 'reselect'
import { INITIAL_STATE } from './initialState'

export const selectStore = (state) => state.store

export const selectCurrentMerchant = createSelector(
  selectStore,
  (store) => store.currentMerchant
)

export const selectSelectedStore = createSelector(
  selectStore,
  (store) => store.selectedStore
)

export const selectSelectedRegion = createSelector(
  selectStore,
  (store) => store.selectedRegion
)

export const selectLocalisation = createSelector(selectSelectedStore, (selectedStore) => {
  if (selectedStore) {
    return selectedStore.localisation
  } else {
    return INITIAL_STATE.localisation
  }
})

export const selectMerchantSettings = createSelector(
  selectStore,
  (store) => store.merchantSettings
)

export const selectNetPrice = createSelector(selectMerchantSettings,
  (merchantSettings) => !!merchantSettings?.products?.net_price
)
