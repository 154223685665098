import React, { useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';

// import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import * as Yup from 'yup';

const AddingFloor = ({
  isVisible,
  onCloseModal,
  onChange,
  onFetchFloor,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseAddingFloorModal = () => {
    onCloseModal();
    handleReset();
  };

  const validationSchema = Yup.object().shape({
    floor: Yup.string().required(
      t('modules.floor_and_shelf.floor_name_required')
    ),
  });

  const {
    handleSubmit,
    handleReset,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      floor: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await onChange(values['floor']);
        await onFetchFloor();
        handleCloseAddingFloorModal();
        enqueueSnackbar(t('modules.floor_and_shelf.floor_added'));
      } catch (error) {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      onClose={isLoading ? () => {} : handleCloseAddingFloorModal}
      aria-labelledby={`adding-floor-dialog-title`}
      open={isVisible}
      disableBackdropClick
    >
      <DialogTitle
        id={`adding-floor-dialog-title`}
        onClose={handleCloseAddingFloorModal}
      >
        {t('modules.floor_and_shelf.add_floor')}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            id={'floor'}
            name={'floor'}
            fullWidth
            value={values['floor']}
            onChange={handleChange}
            error={touched['floor'] && Boolean(errors['floor'])}
            helperText={touched['floor'] && errors['floor']}
            autoFocus
            required
            onBlur={handleBlur}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAddingFloorModal}>
          {t('settings.cancel')}
        </Button>
        <Button
          disabled={!isValid || _.isEmpty(values['floor'])}
          onClick={handleSubmit}
          type="submit"
          color="primary"
        >
          {t('settings.add')}
        </Button>
      </DialogActions>
      {isLoading && <PreLoader size={25} />}
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default AddingFloor;
