import { sumBy } from 'lodash'

export const getTaxName = (tax) => {
  let fullText;
  if (tax.rate < 0) {
    fullText = 'VAT Exempt';
  } else {
    fullText = `${tax.rate}% ${tax.name} `;
  }
  return fullText;
}

export const calculateTaxesSumma = (amount, taxes) => {
  let totalTax = 0
  taxes.forEach(tax => {
    if (tax.rate > 0) totalTax += amount * (tax.rate || 0) / 100
  })
  return totalTax
}

export function taxesSum(taxes) {
  return sumBy(taxes, tax => (tax.rate > 0 ? tax.rate : 0))
}