import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors, CommonFonts } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(20),
      padding: theme.spacing(0, 5),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    container: {
      padding: theme.spacing(12),
      borderWidth: 1,
      borderColor: Colors.GRAY_BORDER1,
      borderRadius: 10,
      borderStyle: 'solid',
    },
    nameText: {
      ...CommonFonts.darkFont24
    },
    descriptionText: {
      ...CommonFonts.darkFont18,
      marginTop: theme.spacing(5)
    },  
    addButton: {
      width: 133,
      height: 48,
      textTransform: 'none',
      fontSize: 14,
    },
    widgetImg: {
      width: '100%',
      marginTop: theme.spacing(15)
    },
    pendingClassname: {
      color: Colors.WHITE
    },
  })
)

export default useStyles
