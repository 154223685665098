import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { Box, Grid, Typography } from '@material-ui/core'
import Selectors from './selectors'
import BusinessOverview from './reports/businessOverview'
import BusinessSnapshot from './reports/businessSnapshot'

import {
  selectSelectedStore,
  selectCurrentMerchant
} from '@/store/modules/store/selectors'

import {
  REPORT,
  REPORT_TYPE,
  WEEKS,
  MONTHS,
  QUARTERS,
  getReportTime
} from './helper'
import images from '@/assets/images'
import useStyles from './styles'

import { getWeekDateRange, getQuarterDateRange } from '@/utils/momentUtil'

const RunReport = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const currentMerchant = useSelector(selectCurrentMerchant)
  const currentStore = useSelector(selectSelectedStore)
  const weekStartDay = currentMerchant.week_start_day
  const financialYearEnd = currentMerchant.financial_year_end

  const [store, setStore] = useState({ id: currentStore.id, name: currentStore.name })
  const [report, setReport] = useState(REPORT.BUSINESS_OVERVIEW)
  const [reportType, setReportType] = useState(REPORT_TYPE.DAILY_REPORT)
  const [year, setYear] = useState(moment().year())
  const [date, setDate] = useState(new Date())
  const [customRange, setCustomRange] = useState([new Date(), new Date()])
  const [period, setPeriod] = useState(MONTHS[0])

  const [reportStore, setReportStore] = useState(null)
  const [reportTime, setReportTime] = useState(null)
  const [hasFutureTimePicked, setHasFutureTimePicked] = useState(false)

  const reportCotent = {
    [REPORT.BUSINESS_OVERVIEW]: (
      <BusinessOverview
        store={reportStore}
        reportTime={reportTime}
      />
    ),
    [REPORT.BUSINESS_SNAPSHOT]: (
      <BusinessSnapshot
        store={reportStore}
        reportTime={reportTime}
      />
    )
  }

  const periodItems = useMemo(() => ({
    [REPORT_TYPE.WEEKLY_REPORT]: WEEKS(year),
    [REPORT_TYPE.MONTHLY_REPORT]: MONTHS,
    [REPORT_TYPE.QUARTERLY_REPORT]: QUARTERS
  }), [year])

  const onChangeReportType = (report_type) => {
    setReportType(report_type)
    if (report_type === REPORT_TYPE.WEEKLY_REPORT ||
      report_type === REPORT_TYPE.MONTHLY_REPORT ||
      report_type === REPORT_TYPE.QUARTERLY_REPORT
    ) {
      if (moment().year() === year) {
        if (report_type === REPORT_TYPE.MONTHLY_REPORT) {
          setPeriod(periodItems[report_type][moment().month()])
        } else {
          let items = periodItems[report_type]
          let dateRange
          for (let item of items) {
            dateRange = report_type === REPORT_TYPE.WEEKLY_REPORT
              ? getWeekDateRange(year, item.id, financialYearEnd, weekStartDay)
              : getQuarterDateRange(year, item.id, financialYearEnd);
            if (moment().startOf('day').isBetween(dateRange.start, dateRange.end)) {
              setPeriod(periodItems[report_type][item.id - 1])
              break
            }
          }
        }
      } else {
        setPeriod(periodItems[report_type][0])
      }
    }
  }

  const onClickReport = () => {
    setHasFutureTimePicked(false)
    const time = getReportTime(
      reportType,
      year,
      period.id,
      date,
      customRange,
      weekStartDay,
      financialYearEnd
    )

    if (time.start > time.end) {
      setHasFutureTimePicked(true)
      return
    }
    setReportStore(store)
    setReportTime(time)
  }

  return (
    <Box className={classes.root}>
      <Grid container justify='center'>
        <img src={images.logo} width={150} height={60} alt='logo' />
      </Grid>
      <Typography className={classes.titleText}>
        {t('workboard.run_reports.reports')}
      </Typography>
      <Typography className={classes.descriptionText}>
        {t('workboard.run_reports.reports_description')}
      </Typography>

      <Selectors
        store={store}
        report={report}
        reportType={reportType}
        year={year}
        period={period}
        date={date}
        customRange={customRange}
        onChangeStore={(value) => setStore(value)}
        onChangeReport={(value) => setReport(value)}
        onChangeReportType={onChangeReportType}
        onChangeYear={(value) => setYear(value)}
        onChangePeriod={(value) => setPeriod(periodItems[reportType][value - 1])}
        onChangeDate={(value) => setDate(value)}
        onChangeCustomRange={(value) => setCustomRange(value)}
        onClickReport={onClickReport}
      />

      {hasFutureTimePicked &&
        <Typography className={classes.futureTimeText}>
          {t('workboard.run_reports.future_time_alert')}
        </Typography>
      }

      <Box className={classes.reportContainer}>
        {reportTime &&
          reportCotent[report]
        }
      </Box>
    </Box>
  )
}

export default RunReport