import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { selectCurrentUser } from '@/store/modules/auth';
import Grid from '@material-ui/core/Grid';
import Button from '@/components/button';
import BussinessTypePicker from '@/components/businessTypePicker';
import useStyles from '../styles';

const StoresLayout = ({
  storeNameValue,
  storeAddressValue,
  addMerchant,
  setBusinessTypeId,
  businessTypeId,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.addNewStore}
      spacing={10}
      justify="center"
    >
      <Grid
        container
        justify="center"
        spacing={4}
        style={{ paddingTop: 10 }}
        xs={10}
        direction="column"
        alignContent="center"
        alignItems="center"
      >
        <Grid item>
          <div className={classes.businessName}>{storeNameValue}</div>
        </Grid>
        <Grid item>{storeAddressValue}</Grid>
      </Grid>
      <Grid
        container
        justify="flex-start"
        spacing={4}
        style={{ paddingTop: 30 }}
      >
        <Grid item>
          <div className={classes.findYourBussinessTitle}>
            {t('addStore.enter_bussiness_type')}
          </div>
        </Grid>
        <Grid item>
          <div className={classes.descriptionSecondStep}>
            {t('addStore.enter_bussiness_type_information')}
          </div>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ paddingTop: 50 }}>
        <Grid item xs={12}>
          <BussinessTypePicker
            getIdOfBusinessType={(value) =>
              setBusinessTypeId(value?.id)
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        xs={10}
        style={{ paddingTop: 50 }}
      >
        <Grid item>
          <div style={{ textAlign: 'center' }}>
            By continuing you agree to KRL{' '}
            <span className={classes.addStoreLink}>
              Terms of Service
            </span>{' '}
            and{' '}
            <span className={classes.addStoreLink}>
              Privacy Policy.
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ padding: '20px 0' }}>
        <Button
          color="primary"
          className={classes.addStoreButton}
          variant="contained"
          onClick={addMerchant}
          disabled={businessTypeId === undefined}
        >
          Add Store
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

export default connect(mapStateToProps)(StoresLayout);
