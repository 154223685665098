import React from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from '@material-ui/core/Divider';
import images from '@/assets/images';
import {
  applicationsFirstRowIcons,
  applicationsSecondRowIcons,
} from './constants';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

export default function ApplicationsCard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const matchesSmallScreen = useMediaQuery('(max-width:860px)');

  return (
    <Card className={classes.cardContent}>
      <CardHeader
        title={<div className={classes.cardTitle}>Applications</div>}
      />
      <CardContent style={{ paddingTop: 24, paddingBottom: 60 }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid container direction="column" spacing={10}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                alignItems="center"
                // alignContent="flex-start"
                spacing={10}
                justify="flex-start"
              >
                {applicationsFirstRowIcons.map((item, index) => (
                  <Grid key={`${item.label}-${index}`} item xs={4} md={3} lg={2}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      spacing={2}
                      className={classes.disabled}
                    >
                      <Grid item>
                        <img
                          width={40}
                          height={40}
                          src={images[item.icon]}
                          alt={'...'}
                        />
                      </Grid>

                      <Grid item>
                        {t(`businessManager.${item.label}`)}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                // alignContent="flex-start"
                spacing={10}
                justify="flex-start"
              >
                {applicationsSecondRowIcons.map((item, index) => (
                  <Grid key={`${item.label}-${index}`} item xs={4} md={3} lg={2}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      spacing={2}
                      className={classes.disabled}
                    >
                      <Grid item>
                        <img
                          width={40}
                          height={40}
                          src={images[item.icon]}
                          alt={'...'}
                        />
                      </Grid>

                      <Grid item>
                        <div style={{ textAlign: 'center' }}>
                          {t(`businessManager.${item.label}`)}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
