import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@/components/button';
import Grid from '@material-ui/core/Grid';
import BussinessTypePicker from '@/components/businessTypePicker';
import NavigationHeader from './NavigationHeader';
import useStyles from './styles';

const StoresLayout = ({
  businessName,
  selectedStore,
  addMerchant,
  setStep,
  setBusinessTypeId,
  businessTypeId,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <NavigationHeader onArrowClick={() => setStep(0)} />
      <Grid
        container
        className={classes.addNewStore}
        spacing={10}
        justify="center"
      >
        <Grid
          container
          justify="center"
          spacing={4}
          style={{ paddingTop: 10 }}
          xs={10}
          direction="column"
          alignContent="center"
          alignItems="center"
        >
          <Grid item>
            <div className={classes.businessName}>{businessName}</div>
          </Grid>
          <Grid item>{selectedStore}</Grid>
        </Grid>
        <Grid
          container
          justify="flex-start"
          spacing={4}
          style={{ paddingTop: 50 }}
        >
          <Grid item>
            <div className={classes.findYourBussinessTitle}>
              {t('addStore.enter_bussiness_type')}
            </div>
          </Grid>
          <Grid item>
            <div className={classes.descriptionSecondStep}>
              {t('addStore.enter_bussiness_type_information')}
            </div>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ paddingTop: 50 }}>
          <Grid item xs={12}>
            <BussinessTypePicker
              getIdOfBusinessType={(value) =>
                setBusinessTypeId(value?.id)
              }
            />
            {/* <TextField
            id="standard-basic"
            style={{ width: '90%' }}
            defaultValue={storeNameValue}
            onChange={(e) => setstoreNameValue(e.target.value)}
          /> */}
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          xs={10}
          style={{ paddingTop: 50 }}
        >
          <Grid item>
            <div style={{ textAlign: 'center' }}>
              By continuing you agree to KRL{' '}
              <span className={classes.addStoreLink}>
                Terms of Service
              </span>{' '}
              and{' '}
              <span className={classes.addStoreLink}>
                Privacy Policy.
              </span>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          style={{ paddingTop: 40, paddingBottom: 30 }}
        >
          <Button
            color="primary"
            className={classes.addStoreButton}
            variant="contained"
            onClick={addMerchant}
            disabled={businessTypeId === undefined}
          >
            Add Store
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default StoresLayout;
