import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.darkFont20,
      fontWeight: '600',
    },
    description: {
      textAlign: 'center',
      ...CommonFonts.darkFont18,
    },
    boxTable: {
      marginTop: theme.spacing(15),
      width: '50%',
    },
    headerTable: {
      ...CommonFonts.darkFont16,
      fontWeight: '600',
    },
    mappingHeaderText: {
      ...CommonFonts.darkFont18,
    },
    mappingFirstRowText: {
      ...CommonFonts.darkFont16,
    },
  })
);

export default useStyles;
