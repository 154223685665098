import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  rootDialog: {
    '&:first-child': {
      padding: theme.spacing(15, 20),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(5, 5)
      },
    },
  },
  welcomeText: {
    ...CommonFonts.grayFont26,
    fontWeight: 500,
    marginTop: theme.spacing(12),
    textAlign: 'center'
  },
  startConnectText: {
    ...CommonFonts.grayFont18,
    marginTop: theme.spacing(4),
    textAlign: 'center'
  },
  simpleStartText: {
    ...CommonFonts.grayFont20,
    marginTop: theme.spacing(12),
    fontWeight: 500,
    textAlign: 'center'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6)
  },
  skipButton: {
    ...CommonFonts.grayFont18,
    letterSpacing: '0.11px',
    color: Colors.GRAY_BORDER3,
    opacity: 1,
    textTransform: 'none',
  },
  startButton: {
    ...CommonFonts.blueFont24,
    fontWeight: 500,
    letterSpacing: '0.11px',
    opacity: 1,
    textTransform: 'none',
  },
}));

export default useStyles;
