import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors, CommonFonts } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: theme.spacing(3, 0),
      borderBottom: `1px solid ${Colors.GRAY_BORDER1_40}`,
    },
    labelText: {
      ...CommonFonts.darkFont14,
    },
  })
)

export default useStyles
