import { gql } from '@apollo/client';

export const GET_TOUCHPOINTS = `
query getTouchpoints{
    getTouchpoints{
      id
      name
      type
      store{
        id
        name
      }
    }
  }
`;

export const ADD_TOUCHPOINT = `
mutation addTouchpoint($input: TouchpointInput!){
  addTouchpoint(input: $input){
    id
  }
}
`;

export const UPDATE_PRODUCTS_ON_TOUCHPOINTS = gql`
  mutation changeProductsListOnTouchpoints($store: StoreInput!) {
    updateStore(input: $store) {
      id
      settings {
        touchpoint_settings {
          digital_front {
            product_view {
              products {
                id
              }
            }
          }
          pos {
            product_view {
              products {
                id
              }
            }
          }
          self_order_kiosk {
            product_view {
              products {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS_WITH_TOUCHPOINTS = gql`
  query getProducts($filter: Filter, $ignore_categories: Boolean) {
    products(filter: $filter, ignore_categories: $ignore_categories) {
      id
      touchpoints {
        type
      }
      images {
        id
        thumbnail
        url
        created_at
      }
      name
      bar_code
      category {
        id
        name
      }
    }
  }
`;
