import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts, THEME } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingRight: theme.spacing(8),
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: 400,
      minWidth: 353,
      [theme.breakpoints.up('lg')]: {
        paddingRight: 50,
      },
    },
    help: {
      width: 107,
      height: 60,
      backgroundColor: Colors.WHITE,
      textTransform: 'uppercase',
      fontSize: 18,
      marginTop: 5,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    link: {
      color: Colors.DIM_GREY,
    },
    notification: {
      marginRight: 30,
    },
    drawerContent: {
      width: 435,
      padding: theme.spacing(6),
    },
    titleNotification: {
      ...CommonFonts.grayFont20,
      fontWeight: '500',
    },
    closeIcon: {
      padding: theme.spacing(1.5),
      backgroundColor: Colors.GRAY_LIGHT,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    notificationCard: {
      padding: theme.spacing(6),
      borderRadius: theme.spacing(1.5),
      borderWidth: '1px',
      borderColor: '#BAC3C9',
      borderStyle: 'solid',
      marginTop: theme.spacing(6),
    },
    contentTitle: {
      ...CommonFonts.darkFont20,
      fontWeight: '600',
      paddingBottom: theme.spacing(2),
    },
    contentDescription: {
      ...CommonFonts.darkFont18,
    },
    noNotifications: {
      marginTop: theme.spacing(10),
      ...CommonFonts.darkFont18,
    },
    actionButton: {
      ...CommonFonts.whiteFont14,
      maxWidth: theme.spacing(30),
      maxHeight: theme.spacing(9),
      padding: THEME.spacing(2, 7),
    },
    badge: {
      '& .MuiBadge-badge': {
        backgroundColor: '#FFC624',
        color: '#1174F2',
        fontWeight: '600',
        fontSize: theme.spacing(3.5),
        top: theme.spacing(2),
        right: theme.spacing(1),
      },
    },
    helpContent: {
      minHeight: '85vh',
      display: 'flex',
      alignItems:'center',
      justifyContent: 'center',      
    },
    startTutorialButton: {
      textTransform: 'none',
    }
  })
);

export default useStyles;
