import React, { useState } from 'react';

import { Dialog } from '@material-ui/core'
import PreLoader from '@/components/preLoader'
import SelectPromotionType from './selectPromotionType'
import MainSection from './mainSection'

import { useQuery, gql } from '@apollo/client'
import { GET_STORE_ORDER_TYPES } from '@/services/storeService'

import { PROMOTION_TYPE } from '@/constants/promotion'
import { getPromotionType } from './helper'
import useStyles from './styles';

export default function ResponsiveDialog({
  open,
  handleClose,
  defaultPromotion = null,
  onSuccess
}) {

  const [promotionType, setPromotionType] = useState(getPromotionType(defaultPromotion))

  const MAX_WIDTH = {
    [PROMOTION_TYPE.MANUAL]: 'md',
    [PROMOTION_TYPE.REDUCED_PRICE]: 'sm',
    [PROMOTION_TYPE.MEAL_DEAL]: 'md',
    [PROMOTION_TYPE.BOGOF]: 'md',
    [PROMOTION_TYPE.MULTI_BUY]: 'md',
  }

  const { data, loading: isLoadingOrderTypes } = useQuery(gql`${GET_STORE_ORDER_TYPES}`)
  const orderTypes = data?.orderTypes || []

  return (
    <Dialog
      fullWidth
      maxWidth={MAX_WIDTH[promotionType || PROMOTION_TYPE.REDUCED_PRICE]}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {promotionType
        ?
        <MainSection
          promotionType={promotionType}
          defaultPromotion={defaultPromotion}
          storeOrderTypes={orderTypes}
          handleClose={handleClose}
          onSuccess={onSuccess}
        />
        :
        <SelectPromotionType
          handleClose={handleClose}
          onSelectPromotionType={setPromotionType}
        />
      }

      {isLoadingOrderTypes && <PreLoader size={35} />}
    </Dialog>
  )
}