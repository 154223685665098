export const Directions = [
  {
    name: 'orderTypes',
    path: '/order-types-workflows-management/order-types',
  },
  {
    name: 'workflows',
    path: '/order-types-workflows-management/workflows',
  },
];
