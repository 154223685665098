import React, { useState, useCallback, useEffect } from 'react';
import useStyles from './styles';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import {
  Box,
  Typography,
  Tab,
  Tabs,
  IconButton,
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  LinearProgress,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { MUTATE_GENERATE_UPLOAD_URL } from '@/services/storeService';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Colors, CommonFonts } from '@/theme';
import { useDropzone } from 'react-dropzone';
import ImageCrop from 'react-image-crop';
import PreLoader from '../preLoader';
import { useSnackbar } from 'notistack';

const ImagePicker = ({
  id = null,
  title,
  thumbnail,
  onChange,
  cropOption = 'rectangle',
  isShowDelete = false,
  onDelete,
  widthCrop = 508,
  heightCrop = 286,
  imageSizeRequired = false,
  ignoreCrop = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [imageProperty, setImageProperty] = useState({
    file: null,
    imageRef: null,
    aspect: widthCrop / heightCrop,
    isCircle: false,
    preview: thumbnail,
    contentType: '',
  });
  const [crop, setCrop] = useState({
    aspect: imageProperty.aspect,
    x: 0,
    y: 0,
    unit: 'px',
    width: widthCrop,
    height: heightCrop,
  });

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = (generateUploadUrlResponse) => {
    setOpenEditModal(false);
    setIsError(false);
    setImageProperty({
      ...imageProperty,
      preview: generateUploadUrlResponse
        ? URL.createObjectURL(imageProperty.file)
        : thumbnail,
      file: null,
    });
  };

  const [mutateGenerateURL] = useMutation(
    gql`
      ${MUTATE_GENERATE_UPLOAD_URL}
    `
  );

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    () => {
      switch (cropOption) {
        case 'circle':
          return setImageProperty({
            ...imageProperty,
            isCircle: true,
          });
        case 'square':
          return setImageProperty({
            ...imageProperty,
            isCircle: false,
          });
        case 'rectangle':
          return setImageProperty({
            ...imageProperty,
            isCircle: false,
            aspect: widthCrop / heightCrop,
          });
        default:
          return;
      }
    };
  }, [imageProperty]);

  const getCroppedImage = async (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        const file = new File([blob], 'crop-image');
        resolve(file);
        reject((error) => console.log(error));
      }, imageProperty.contentType);
    });
  };

  const onCropComplete = async (crop) => {
    if (imageProperty.imageRef && crop.width && crop.height) {
      const file = await getCroppedImage(
        imageProperty.imageRef,
        crop
      );
      setImageProperty({ ...imageProperty, file });
    }
  };

  const onSave = async () => {
    setIsLoading(true);
    try {
      const result = await mutateGenerateURL({
        variables: {
          input: {
            id: id && id,
            name: imageProperty.file.name,
            content_type: imageProperty.contentType,
          },
        },
        ignoreResults: false,
      });
      const generateUploadUrlResponse =
        result?.data?.generateUploadUrl[0];
      if (generateUploadUrlResponse) {
        const url = generateUploadUrlResponse?.upload_url;
        await fetch(
          new Request(url, {
            method: 'PUT',
            body: imageProperty.file,
            headers: new Headers({
              'Content-Type': imageProperty.contentType,
            }),
          })
        );

        setImageProperty({
          ...imageProperty,
          preview: URL.createObjectURL(imageProperty.file),
        });

        await onChange(
          generateUploadUrlResponse,
          URL.createObjectURL(imageProperty.file)
        );
        handleCloseEditModal(generateUploadUrlResponse);
      }
    } catch (error) {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const onDrop = useCallback((value) => {
    const file = value[0];
    setIsError(false);
    setProgressLoading(true);
    const objURL = URL.createObjectURL(file);

    const image = new Image();
    image.src = objURL;
    image.onload = function () {
      if (
        this.width < widthCrop &&
        this.height < heightCrop &&
        this.width * this.height < widthCrop * heightCrop
      ) {
        if (imageSizeRequired) {
          setIsError(true);
        } else {
          setImageProperty({
            ...imageProperty,
            file,
            preview: objURL,
            contentType: file.type,
          });
          setCrop({
            ...crop,
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
          });
        }
      } else {
        setImageProperty({
          ...imageProperty,
          file,
          preview: objURL,
          contentType: file.type,
        });
      }
    };
    setProgressLoading(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const onDeleteImage = (event) => {
    event.stopPropagation();
    onDelete?.();
  };

  return (
    <>
      {imageProperty.preview ? (
        <Button onClick={handleOpenEditModal}>
          <div className={classes.box}>
            {isShowDelete && (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                size="small"
                onClick={onDeleteImage}
              >
                <CloseIcon
                  className={classes.closeIcon}
                  fontSize="small"
                />
              </IconButton>
            )}
            <img
              className={classes.boxImage}
              src={imageProperty.preview}
              alt="No thumbnail"
            />
            <div className={classes.boxUpdateImage}>
              <CameraAltIcon
                style={{ color: '#BAC3C9' }}
                fontSize="small"
              />
            </div>
          </div>
        </Button>
      ) : (
        <Button onClick={handleOpenEditModal}>
          <div className={classes.boxWithoutImage}>
            <AddAPhotoIcon fontSize="small" />
          </div>
        </Button>
      )}
      <Dialog
        maxWidth={'xl'}
        fullWidth
        fullScreen={fullScreen}
        onClose={isLoading ? () => {} : handleCloseEditModal}
        aria-labelledby={`{edit-${title}-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{edit-${title}-dialog-title}`}
          onClose={() => handleCloseEditModal()}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <div>
            <Tabs
              className={classes.tabs}
              value={value}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                className={classes.tab}
                style={{ fontSize: 18 }}
                key="upload"
                label={t('image.upload')}
              />
              <Tab
                className={classes.tab}
                style={{ fontSize: 18 }}
                key="library"
                label={t('image.library')}
                disabled
              />
              <Tab
                className={classes.tab}
                style={{ fontSize: 18 }}
                key="instagram"
                label={t('image.instagram')}
                disabled
              />
              <Tab
                className={classes.tab}
                style={{ fontSize: 18 }}
                key="dropbox"
                label={t('image.dropbox')}
                disabled
              />
            </Tabs>
          </div>
          <div>
            <TabPanel
              value={value}
              index={0}
              className={classes.tabPanel}
            >
              <>
                {isError && (
                  <Typography className={classes.errorText}>
                    {t('image.dimension_error', {
                      width: widthCrop,
                      height: heightCrop,
                    })}
                  </Typography>
                )}
                {!imageProperty.preview ? (
                  <div className={classes.boxImageUpload}>
                    <Typography className={classes.titleUploadImage}>
                      {t('image.upload_image')}
                    </Typography>
                    {!progressLoading ? (
                      <div
                        {...getRootProps()}
                        className={classes.dropZone}
                      >
                        <input {...getInputProps()} />
                        <Typography className={classes.titleDrop}>
                          {t('image.drop_title')}
                        </Typography>
                        <Typography className={classes.titleBrowse}>
                          {t('image.browse')}
                        </Typography>
                      </div>
                    ) : (
                      <div className={classes.progressBar}>
                        <LinearProgress />
                      </div>
                    )}
                    {isLoading && <PreLoader size={30} />}
                  </div>
                ) : (
                  <div className={classes.boxImagePreview}>
                    {!imageProperty.file ? (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <img
                          className={classes.imagePreview}
                          src={imageProperty.preview}
                        />
                      </div>
                    ) : (
                      <ImageCrop
                        circularCrop={imageProperty.isCircle}
                        src={imageProperty.preview}
                        crop={ignoreCrop ? null : crop}
                        // locked
                        onChange={(newCrop) => setCrop(newCrop)}
                        onComplete={onCropComplete}
                        onImageLoaded={(image) =>
                          setImageProperty({
                            ...imageProperty,
                            imageRef: image,
                          })
                        }
                      />
                    )}
                  </div>
                )}
                {imageProperty.preview && (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Typography
                      className={classes.changeImage}
                      onClick={() => {
                        setImageProperty({
                          ...imageProperty,
                          file: null,
                          preview: null,
                        });
                      }}
                    >
                      {t('image.change_image')}
                    </Typography>
                  </div>
                )}

                {isLoading && <PreLoader size={30} />}
              </>
            </TabPanel>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseEditModal()}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={isLoading || isError}
            onClick={onSave}
            type="submit"
            color="primary"
          >
            {t('settings.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(13),
    paddingRight: theme.spacing(13),
    paddingTop: theme.spacing(5),
    paddingBottom: 0,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

export default ImagePicker;
