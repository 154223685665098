import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  pdfDocument: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pdfPage: {
    boxShadow: '0 0 8px rgba(0, 0, 0, .05)',
    marginBottom: theme.spacing(6),
    '& canvas': {
      maxWidth: '100%',
      height: 'auto !important'
    }
  },

}));

export default useStyles;
