import React from 'react'
import PropTypes from 'prop-types'

import {
  Paper,
  Table,
  TableBody,
} from '@material-ui/core'
import TableHeader from './tableHeader'
import TableRow from './tableRow'

import useStyles from './styles'

const AnalysisTable = (props) => {
  const classes = useStyles();

  const {
    items,
    sortType,
    sortDirection,
    onPressSort
  } = props

  return (
    <Paper elevation={0} className={classes.paper}>
      <Table className={classes.table}>
        <TableHeader
          sortType={sortType}
          sortDirection={sortDirection}
          onPressSort={onPressSort}
        />
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={`${index}-${item?.name}`} item={item} />
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

AnalysisTable.propTypes = {
  items: PropTypes.array,
  sortType: PropTypes.string,
  sortDirection: PropTypes.object,
  onPressSort: PropTypes.func,
}

export default React.memo(AnalysisTable)