import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce, find } from 'lodash'
import moment from 'moment'

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Popover from '@material-ui/core/Popover'
import MenuItem from '@material-ui/core/MenuItem'
import { Search, ExpandMore } from '@material-ui/icons';
import TextFiled from '@/components/textField';
import { Colors } from '@/theme';
import Button from '@/components/button';
import Paper from '@material-ui/core/Paper';
import Calendar from 'react-calendar'

import { ORDER_STATES, ORDER_PAYMENT_STATES } from '../helper'
import useStyles from './styles';

const FormHeader = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    orderStates,
    orderPaymentStates,
    employees,
    selectedUsers,
    date,
    onChangeOrderStates,
    onChangeOrderPaymentStates,
    onChangeUsers,
    onChangeDate,
    onChangeSearch,
    onAdd
  } = props

  const [datePickerAnchor, setDatePickerAnchor] = useState(null)

  const getUserName = (user) => {
    return [user.first_name, user.last_name]
      .filter(name => !!name)
      .join(' ') ||
      user.email
  }

  return (
    <Grid item xs={12}>
      <Paper style={{ width: '100%' }}>
        <Grid
          container
          alignItems='center'
          spacing={2}
          className={classes.paperContent}
        >
          <Grid item xs={12} sm={4} lg={3}>
            <TextFiled
              placeholder={t('orders.enter_order_no')}
              className={classes.searchInput}
              onChange={debounce((e) => onChangeSearch(e.target.value), 1000)}
              startAdornment={
                <Search
                  style={{ color: Colors.GRAY_BORDER1 }}
                  width={18}
                  height={18}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={2} style={{ padding: '4px 12px' }}>
            <Select
              multiple
              disableUnderline
              IconComponent={ExpandMore}
              displayEmpty
              renderValue={() => orderStates.length === ORDER_STATES.length
                ? t('orders.all_orders_states')
                : orderStates.map(item => item.name).join(', ')
              }
              fullWidth
              className={classes.filterInput}
              value={orderStates}
              onChange={(event) => onChangeOrderStates(event.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {ORDER_STATES.map((item, id) => (
                <MenuItem key={`order-state-${id}`} value={item}>
                  <GreenCheckbox
                    color='default'
                    checked={!!find(orderStates, { state: item.state })}
                    size='small'
                  />
                  <Typography className={classes.titleText}>
                    {item.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} lg={2} style={{ padding: '4px 12px' }}>
            <Select
              multiple
              disableUnderline
              IconComponent={ExpandMore}
              displayEmpty
              renderValue={() => orderPaymentStates.length === ORDER_PAYMENT_STATES.length
                ? t('orders.all_orders')
                : orderPaymentStates.map(item => item.name).join(', ')
              }
              fullWidth
              className={classes.filterInput}
              value={orderPaymentStates}
              onChange={(event) => onChangeOrderPaymentStates(event.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {ORDER_PAYMENT_STATES.map((item, id) => (
                <MenuItem key={`order-payment-state-${id}`} value={item}>
                  <GreenCheckbox
                    color='default'
                    checked={!!find(orderPaymentStates, { state: item.state })}
                    size='small'
                  />
                  <Typography className={classes.titleText}>
                    {item.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} lg={2} style={{ padding: '4px 12px' }}>
            <Select
              multiple
              disableUnderline
              IconComponent={ExpandMore}
              displayEmpty
              renderValue={() => selectedUsers.length === employees.length
                ? t('orders.all_users')
                : selectedUsers.map(item => getUserName(item)).join(', ')
              }
              fullWidth
              className={classes.filterInput}
              value={selectedUsers}
              onChange={(event) => onChangeUsers(event.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {employees.map((item, id) => (
                <MenuItem key={`users-${id}`} value={item}>
                  <GreenCheckbox
                    color='default'
                    checked={!!find(selectedUsers, { id: item.id })}
                    size='small'
                  />
                  <Typography className={classes.titleText}>
                    {getUserName(item)}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} lg={2} style={{ padding: '4px 12px' }}>
            <Box
              className={classes.selectContainer}
              onClick={(e) => setDatePickerAnchor(e.currentTarget)}
            >
              <Typography className={classes.filterInput}>
                {
                  moment().isSame(moment(date), 'day')
                    ?
                    t('orders.today')
                    :
                    moment(date).format('DD/MM/YY')
                }
              </Typography>
              <ExpandMore fontSize='default' className={classes.expandIcon} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} lg={1}>
            <Grid container justify="flex-end">
              <Button
                className={classes.addButton}
                color="primary"
                variant="contained"
                onClick={onAdd}
                disabled
              >
                {t('button_group.add_new')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Popover
        id='simple-popover'
        open={Boolean(datePickerAnchor)}
        anchorEl={datePickerAnchor}
        onClose={() => setDatePickerAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Calendar
          value={date}
          onChange={(value) => {
            onChangeDate(value)
            setDatePickerAnchor(null)
          }}
        />
      </Popover>
    </Grid>
  );
};

const GreenCheckbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default FormHeader;
