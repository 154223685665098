import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { debounce, isEmpty } from 'lodash'
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Grid,
  Typography,
  Box,
  TextField,
  InputAdornment
} from '@material-ui/core';

import { useLazyQuery, gql } from '@apollo/client'
import { GET_ADDON_GROUP_BY_NAME } from '@/services/inventoryService'

import images from '@/assets/images';
import { Colors } from '@/theme';
import useStyles from './styles';


const AddonGroupSearchInput = ({ onSelectGroup, ...restProps }) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const [groups, setGroups] = useState([])

  const [getAddonGroupByName, { loading: isLoadingAddonGroups }] = useLazyQuery(gql`${GET_ADDON_GROUP_BY_NAME}`, {
    onCompleted: (data) => {
      if (isEmpty(data.addonGroups)) {
        setGroups([{ empty: true }])
      } else {
        setGroups(data.addonGroups)
      }
    },
    onError: () => {
      setGroups([{ empty: true }])
    },
    fetchPolicy: 'cache-and-network'
  })

  const searchAddonGroups = (input) => {
    setGroups([])
    if (input) {
      getAddonGroupByName({
        variables: {
          filter: {
            count: true,
            page: 1,
            limit: 10,
            group: `%${input.toLowerCase()}%`,
          }
        },
      })
    }
  }

  return (
    <Autocomplete
      id="product-search-input"
      freeSolo
      options={groups}
      renderInput={(params) => (
        <Box style={{ position: 'relative' }}>
          <TextField
            {...params}
            {...restProps}
            placeholder={t('add_product.enter_addon_group_name')}
            className={classes.searchInput}
          />
          <SearchIcon
            className={classes.searchIcon}
            fontSize='small'
          />
        </Box>
      )}
      getOptionLabel={(option) => option?.group || ''}
      renderOption={(option, { inputValue }) => {
        if (option?.empty) {
          return (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <Grid item>
                <img src={images.noProducts} width={90} height={90} />
              </Grid>
              <Grid item>
                <Typography className={classes.noMatchingText}>
                  {t('add_product.no_addon_groups_added')}
                </Typography>
              </Grid>
            </Grid>
          )
        } else {
          const nameMatches = match(option.group, inputValue);
          const nameParts = parse(option.group, nameMatches);

          return (
            <Box paddingX={2} paddingY={3}>
              <Box>
                {nameParts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight && 600,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </Box>
              <Box>
                <Typography className={classes.addonsLenText}>
                  {`${option.addons?.length} ${t('add_product.addons')}`}
                </Typography>
              </Box>
            </Box>
          )
        }
      }}
      onInputChange={debounce((e, newInputValue) => searchAddonGroups(newInputValue), 1000)}
      filterOptions={(x) => x}
      loading={isLoadingAddonGroups}
      loadingText={t('workboard.product_intelligence.loading_products')}
      onChange={(event, addonGroup) => {
        if (addonGroup?.id) {
          onSelectGroup(addonGroup)
        } else {
          return
        }
      }}
    />
  )
}

export default AddonGroupSearchInput