import { CommonFonts } from '@/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    filterInput: {
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
      '& .MuiSelect-select.MuiSelect-select': {
        ...CommonFonts.darkFont18,
        fontWeight: '500',
      },
      '& .MuiMenuItem-root': {
        ...CommonFonts.darkFont18,
      },
    },
  })
);

export default useStyles;
