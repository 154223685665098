import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(20)
    },
    itemContainer: {
      padding: theme.spacing(0, 6),
      marginTop: theme.spacing(5)
    },
    button: {
      height: 54,
      textTransform: 'none',
      fontSize: 14,
    },
    filterInput: {
      ...CommonFonts.darkFont18,
    },
    titleText: {
      ...CommonFonts.darkFont14,
    },
    selectContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    },
    expandIcon: {
      color: 'rgb(0, 0, 0, 0.45)'
    }
  })
)

export default useStyles
