import moment from 'moment'

const DEFAULT_PROMOTION_VALID_FROM = '1970-01-01T00:00:00.000Z'
const DEFAULT_PROMOTION_VALID_TO = '2050-12-31T23:59:59.000Z'
const DEFAULT_PROMOTION_START_TIME = '00:00:00'
const DEFAULT_PROMOTION_END_TIME = '23:59:59'

export const isPromotionExpired = (promotion) => {
  const valid_from = promotion.valid_from || DEFAULT_PROMOTION_VALID_FROM
  const valid_to = promotion.valid_to || DEFAULT_PROMOTION_VALID_TO
  const start_time = promotion.start_time || DEFAULT_PROMOTION_START_TIME
  const end_time = promotion.end_time || DEFAULT_PROMOTION_END_TIME

  const today = moment().utc()

  return !(
    today.isBetween(valid_from, valid_to) &&
    today.isBetween(today.format('YYYY-MM-DD') + `T${start_time}Z`, today.format('YYYY-MM-DD') + `T${end_time}Z`)
  )
}

export const isPromotionActive = (promotion) => {
  return !isPromotionExpired(promotion) && !!promotion.active
}