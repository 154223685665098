import 'regenerator-runtime/runtime';
import 'intl';
import 'intl/locale-data/jsonp/id';
import 'intl/locale-data/jsonp/en';

import React from 'react';
import ReactDOM from 'react-dom';
import Routes from '@/routes';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { THEME } from '@/theme';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import createStore from '@/store';
import { ApolloProvider } from '@apollo/client/react';
import MydaApolloClient from './components/apolloClient';
import SentryWrapper from './components/sentryWrapper';
import NotifyStackProvider from './components/notifyStack';
import NotificationProvider from './components/notification';
import 'react-image-crop/dist/ReactCrop.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-calendar/dist/Calendar.css';
import { requestFirebaseNotificationPermission } from '../firebase';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from '@/constants/env';
import { CookiesProvider } from 'react-cookie';

import DateFnsUtils from '@date-io/date-fns';

import { MuiPickersUtilsProvider } from '@material-ui/pickers'

const { store, persistor } = createStore();
const history = createBrowserHistory();

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={THEME}>
          <SentryWrapper>
            <CookiesProvider>
              <Provider store={store}>
                <NotifyStackProvider>
                  <MydaApolloClient>
                    {(client) => (
                      <ApolloProvider client={client}>
                        <NotificationProvider
                          history={history}
                          getFirebaseToken={
                            requestFirebaseNotificationPermission
                          }
                        >
                          <Elements stripe={stripePromise}>
                            <PersistGate
                              loading={null}
                              persistor={persistor}
                            >
                              <Router history={history}>
                                <Routes />
                              </Router>
                            </PersistGate>
                          </Elements>
                        </NotificationProvider>
                      </ApolloProvider>
                    )}
                  </MydaApolloClient>
                </NotifyStackProvider>
              </Provider>
            </CookiesProvider>
          </SentryWrapper>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </I18nextProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));

module.hot.accept();
