import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { Months } from '@/constants/dateAndTime';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';

const DayMonthPicker = forwardRef(
  ({ name, label, value, onChange, subTitle }, ref) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const separateTime =
      moment(value).format('MM/DD').split('/') || '';
    const lowerCaseTitle = label.toLowerCase();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [day, setDay] = useState(separateTime[1] || '');
    const [month, setMonth] = useState(
      Months.find((item) => item.id === separateTime[0])?.name || ''
    );

    useImperativeHandle(ref, () => ({
      handleOpenEditModal: () => setOpenEditModal(true),
    }));

    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    const handleChangeMonth = (value) => {
      const newMonth = Months.find((item) => item.name === value);
      const newDay = day > newMonth.maxDay ? newMonth.maxDay : day;
      setDay(newDay);
      setMonth(newMonth.name);
    };

    const concatTime = () => {
      const selectMonth = Months.find((item) => item.name === month)
        .id;
      return `${selectMonth}/${day}`;
    };

    const onSave = async () => {
      try {
        setIsLoading(true);
        await onChange(concatTime());
        handleCloseEditModal();
        enqueueSnackbar(
          `${capitalizeEachLetter(label)} ${
            value ? t('settings.updated') : t('settings.added')
          }`
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    const maxDay =
      Months.find((item) => item.name === month)?.maxDay || [];
    const days = Array(maxDay)
      .fill(null)
      .map((u, i) => i + 1);

    return (
      <>
        <Box className={classes.box}>
          <Typography className={classes.title}>{label}</Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {value ? (
              <Typography className={classes.value}>
                {day} {month}
              </Typography>
            ) : (
              <Typography
                onClick={handleOpenEditModal}
                className={classes.addAction}
              >
                {t('settings.add')}
              </Typography>
            )}
            <IconButton
              className={classes.editIcon}
              aria-label={`edit-${lowerCaseTitle}`}
              onClick={handleOpenEditModal}
            >
              <CreateIcon />
            </IconButton>
          </Box>
        </Box>
        <Dialog
          fullWidth
          fullScreen={fullScreen}
          onClose={isLoading ? () => {} : handleCloseEditModal}
          aria-labelledby={`{edit-${lowerCaseTitle}-dialog-title}`}
          open={openEditModal}
        >
          <DialogTitle
            id={`{edit-${lowerCaseTitle}-dialog-title}`}
            onClose={handleCloseEditModal}
          >
            {`Update ${lowerCaseTitle}`}
          </DialogTitle>
          <DialogContent>
            {subTitle && (
              <Box>
                <Typography className={classes.subTitle}>
                  {subTitle}
                </Typography>
              </Box>
            )}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Select
                className={classes.monthInput}
                displayEmpty
                id={'month'}
                name={'month'}
                value={month}
                onChange={(event) =>
                  handleChangeMonth(event.target.value)
                }
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {(Months || []).map((option, index) => {
                  return (
                    <MenuItem
                      key={`${option.name}-${index}`}
                      value={option.name}
                    >
                      {option.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                className={classes.dateInput}
                displayEmpty
                id={'day'}
                name={'day'}
                value={day}
                onChange={(event) => setDay(event.target.value)}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {days?.map((option, index) => {
                  return (
                    <MenuItem
                      key={`${option}-${index}`}
                      value={option}
                    >
                      {option < 10 ? `0${option}` : option}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditModal}>
              {t('settings.cancel')}
            </Button>
            <Button onClick={onSave} type="submit" color="primary">
              {t('settings.save')}
            </Button>
          </DialogActions>
          {isLoading && <PreLoader size={25} />}
        </Dialog>
      </>
    );
  }
);

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default DayMonthPicker;
