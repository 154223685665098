import I18n from '@/i18n'

const widgets = {
  '1': {
    id: '1',
    type: 'performance_overview',
    name: I18n.t('globalWidgets.performance_overview'),
    description: I18n.t('globalWidgets.performance_overview_description'),
  },
  '2': {
    id: '2',
    type: 'product_sales_analysis',
    name: I18n.t('globalWidgets.product_sales_analysis'),
    description: I18n.t('globalWidgets.product_sales_analysis_description'),
  },
  '3': {
    id: '3',
    type: 'category_sales_analysis',
    name: I18n.t('globalWidgets.category_sales_analysis'),
    description: I18n.t('globalWidgets.category_sales_analysis_description'),
  },
  '4': {
    id: '4',
    type: 'trends',
    name: I18n.t('globalWidgets.trends'),
    description: I18n.t('globalWidgets.trends_description'),
  },
  '5': {
    id: '5',
    type: 'stockout_prediction',
    name: I18n.t('globalWidgets.stockout_prediction'),
    description: I18n.t('globalWidgets.stockout_prediction_description'),
  },
  '6': {
    id: '6',
    type: 'employee_kpis',
    name: I18n.t('globalWidgets.employee_kpis'),
    description: I18n.t('globalWidgets.employee_kpis_description'),
  },
  '7': {
    id: '7',
    type: 'chart_of_accounts',
    name: I18n.t('globalWidgets.chart_of_accounts'),
    description: I18n.t('globalWidgets.chart_of_accounts_description'),
  },
  '8': {
    id: '8',
    type: 'product_intelligence',
    name: I18n.t('globalWidgets.product_intelligence'),
    description: I18n.t('globalWidgets.product_intelligence_description'),
  },
}

export const widgetIds = {
  PERFORMANCE_OVERVIEW: '1',
  PRODUCT_SALES_ANALYSIS: '2',
  CATEGORY_SALES_ANALYSIS: '3',
  TRENDS: '4',
  STOCKOUT_PREDICTION: '5',
  EMPLOYEE_KPIS: '6',
  CHART_OF_ACCOUNTS: '7',
  PRODUCT_INTELLIGENCE: '8'
}

export default widgets