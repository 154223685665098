import { gql } from '@apollo/client'

export const STORE_DETAIL = `
    query getStoreDetail{
        merchant{
            id
            lname
            tname
            address{
                door_number
                building_name
                line1
                line2
                city_town
                postcode
                floor
                unit
                country
            }
            business_type
            business_type_id
            registrations{
              entities{
                id
                parent
                name
                reg_required
                reg_number
              }
              taxes{
                id
                parent
                name
                reg_number
                reg_required
              }
            }
            financial_year_end
            week_start_day
            day_end_time
        }
      }
`

export const MUTATE_REGISTER_NAME = `
mutation updateMerchant($merchant: MerchantInput!) {
    updateMerchant(input: $merchant) {
      id
      lname
    }
  }
`

export const MUTATE_TRADING_NAME = `
mutation updateMerchant($merchant: MerchantInput!) {
    updateMerchant(input: $merchant) {
      id
      tname
    }
  }
`

export const BUSINESS_TYPE_LIST = `
  query getBusinessType{
      globalDatasets {
        business_types {
          id
          name
        }
      }
  }
`

export const REGISTRATION_TYPE_LIST = `
  query getRegistrationTypes {
    registrationTypes {
      entities {
          id
          name
          reg_required
      }
    }
  }
`

export const REGISTRATION_TAXES_LIST = `
  query getTaxRegistration {
    registrationTypes{
        taxes {
            id
            name
            options {
                id
                name
                reg_required
                taxes {
                    id
                    name
                    rate
                }
            }
        }
    }
}
`

export const MUTATE_BUSINESS_TYPE = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
    business_type
    business_type_id
  }
}
`

export const MUTATE_CREATE_BUSINESS_TYPE = `
mutation($input: [BusinessTypeInput]!) {
  addBusinessTypes(input: $input) {
    id
    name
  }
}
`

export const MUTATE_REGISTRATION_NUMBER = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
    registrations{
      entities{
        id
        name
        reg_number
      }
      taxes{
        id
        name
        reg_number
        reg_required
      }
    }
  }
}
`

export const MUTATE_REGISTRATION_TYPE = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
  }
}
`

export const MUTATE_MERCHANT_LOGO = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
  }
}
`

export const MUTATE_REGISTRATION_TAX = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
  }
}
`

export const MUTATE_VAT_NUMBER = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
    registrations{
      entities{
        id
        name
        reg_number
      }
      taxes{
        id
        name
        reg_number
        reg_required
      }
    }
  }
}
`

export const MUTATE_FINANCIAL_YEAR_END = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
  }
}
`

export const MUTATE_WEEK_COMMENCING_DAY = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
  }
}
`

export const MUTATE_DAY_END_TIME = `
mutation updateMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
  }
}
`

export const ADD_MERCHANT = `
mutation addMerchant($merchant: MerchantInput!) {
  addMerchant(input: $merchant) {
    id,
    mid,
    tname,
    lname,
    mid,
    week_start_day
    financial_year_end
    logo{
      id
      url
      thumbnail
    }
    stores {
      id,
      merchant_id,
      sid,
      name,
      email,
      type,
      phone,
      address_id,
      localisation{
        digit_separator
        currency_symbol
        currency_decimal
        currency_position
      }
      region{
        id
        region_name
      }
      address {
        door_number,
        building_name,
        line1,
        line2,
        city_town,
        country,
        postcode,
        county,
      },
      settings{
        touchpoint_settings{
          digital_front{
            url
            banner{
              id
              url
            }
          }
        }
      }
    },
  }
}`

export const ADD_STORE = `
mutation addStore($input: StoreInput!) {
  addStore(input: $input) {
    id,
    merchant_id,
    sid,
    name,
    email,
    type,
    phone,
    address_id,
    localisation{
      digit_separator
      currency_symbol
      currency_decimal
      currency_position
    }
    region{
      id
      region_name
    }
    address {
      door_number,
      building_name,
      line1,
      line2,
      city_town,
      country,
      postcode,
      county,
    },
  }
}`

export const MERCHANT_SETTINGS = `
  query getMerchantSettings{
    merchantSettings{
      products{
        net_price
        ingredient
        addon
        recipe
        allergy
        style
      }
      pricings{
        store{
          same_price
        }
      }
      branding{
        customize{
          primary
          secondary
          third
        }
      }
    }
  }
`

export const MUTATE_MERCHANT_SETTINGS = `
  mutation merchantSettings($merchantSettings: MerchantSettingsInput!){
    merchantSettings(input: $merchantSettings){
        products{
          net_price
          ingredient
          addon
          recipe
          allergy
          style
        }
      }
    }
`

export const PRICE_TYPE_LIST = `
    query getPriceTypeList{
      globalPriceTypes{
        id
        name
        type
      }
    }
`

export const MERCHANT_PRICE_TYPE = `
    query getPriceType{
      priceTypes{
        id
        name
        type
      }
    }
`

export const MUTATE_PRICE_TYPE = `
  mutation addPriceTypes($priceTypes: [PriceTypeInput]!) {
    addPriceTypes(input: $priceTypes) {
      id
    }
  } 
`

export const UPDATE_STORE = `
  mutation ($store: StoreInput!) {
   updateStore(input: $store) {
    id
    name
    merchant_id
  }
 }`

export const MUTATE_ADDRESS = `
 mutation updateAddressMerchant($merchant: MerchantInput!) {
  updateMerchant(input: $merchant) {
    id
    address{
      door_number
      building_name
      line1
      line2
      city_town
      postcode
      floor
      unit
      country
    }
  }
}
 `

export const ACCOUNT_TYPES = `
  query getAccoutTypes($id: String){
    accountTypes(id: $id){
      id
      name
      children_types{
        id
        name
        accounts{
          id
          name
          description
          starting_balance
          archived
          display_in_report
          account_type{
            id
            name
          }
        }
      }
    }
  }
 `

export const ACCOUNT_LIST = `
  query accounts{
    accounts{
      id
      name
      description
      account_type{
        id
        name
      }
      starting_balance
    }
  }
`

export const TOUCHPOINT_POS_SETTING = `
  query getTouchpointPosSetting{
    store{
      settings{
        touchpoint_settings{
          pos{
            auto_receipt_print
              #Print receipt automatically
            auto_expense_print
             #Print expenses receipt automatically
             auto_chef_print
            float{
              status
              amount
            }
             #Float
            manual_amount{
              status
              amount
            }
              #Manual amount authorization 
            scren_lock{
              status
              time
            }
              #Auto lock 
            cashdrawer_alarm{
              status
              time
            }
               #Cash drawer alarm
            cart{
              group_by_items
            }
               #Grop items in cart 
            payment_types
              #Payment types 
            banner{
              id
              url
              thumbnail
            }
            customer_display_images{
              id
              url
              thumbnail
            }
            product_view{
              sort_by_name
                #Sort by alphabetical order: 
            }
            receipts{
              logo
              tax_summary
              phone
              cashier_name
              address_type
              registration_number
              barcode
              website
              custom_message
              promotions
            }
            product_view{
              sort_by_name
                #Sort by alphabetical order: 
              categories{
                id
                position
              }
            }
          }
        }
      }
       payment_types{
        id
        name
      }
    }
  }
`

export const TOUCHPOINT_SOK_SETTING = `
  query getTouchpointKioskSetting{
    store{
      settings{
        touchpoint_settings{
          self_order_kiosk {
            auto_receipt_print,
            vat_receipt
            call_to_action_text
            call_to_action_support_text
            call_to_action_button_text
            order_types
            payment_types
            product_view{
              sort_by_name
              by_category
              show_selected
              number_of_products
              short_description
              products{
                id
                position
              }
              categories{
                id
                position
              }
            }
            receipts{
              logo
              phone
              address_type
              registration_number
              barcode
              website
              custom_message
              promotions
            }
            banner {
              id
              url
              content_type
              thumbnail
            }
          }
        }
      }
      payment_types{
        id
        name
      }
      order_types{
        id
        name
      }
    }
  }
`

export const TOUCHPOINT_STOREFRONT_SETTING = `
  query getTouchpointStorefrontSetting{
    store{
      settings{
        touchpoint_settings{
          digital_front{
            url
            order_types
            product_view{
              sort_by_name
              by_category
              show_selected
              number_of_products
              short_description
              products{
                id
                position
              }
              categories{
                id
                position
              }
            }
            receipts{
              logo
              phone
              address_type
              registration_number
              barcode
              website
              custom_message
              promotions
            }
          }
        }
      }
      order_types{
        id
        name
      }
    }
  }
`

export const MUTATE_TOUCHPOINT_POS_SETTING = `
mutation updateStore($store: StoreInput!) {
  updateStore(input: $store) {
    id
    settings{
      touchpoint_settings{
        pos{
          auto_receipt_print
            #Print receipt automatically
          auto_expense_print
           #Print expenses receipt automatically
           auto_chef_print
          float{
            status
            amount
          }
           #Float
          manual_amount{
            status
            amount
          }
            #Manual amount authorization 
          scren_lock{
            status
            time
          }
            #Auto lock 
          cashdrawer_alarm{
            status
            time
          }
             #Cash drawer alarm
          cart{
            group_by_items
          }
             #Grop items in cart 
          payment_types
            #Payment types 
          banner{
            id
            url
            thumbnail
          }
          customer_display_images{
            id
            url
            thumbnail
          }
          receipts{
            logo
            tax_summary
            phone
            cashier_name
            address_type
            registration_number
            barcode
            website
            custom_message
            promotions
          }
          product_view{
            sort_by_name
              #Sort by alphabetical order: 
            categories{
              id
              position
            }
          }
        }
      }
    }
  }
 }
`

export const MUTATE_GENERATE_UPLOAD_URL = `
mutation generateUploadUrl($input: [UploadInput!]) {
  generateUploadUrl(input: $input) {
    id
    name
    upload_url
  }
}
`

export const PROMOTION_LIST = `
  query getPromotion($name: String, $filter: Filter){
    promotions(name: $name, filter: $filter){
      id
      promo_name
      options{
        products{
          id
          name
        }
      }
    }
  }
`

export const CATEGORY_LIST = `
query getCategory{
  categories(store_id:"-1"){
    id
    name
  }
}
`

export const MUTATE_TOUCHPOINT_STOREFRONT_SETTING = `
mutation updateStore($store: StoreInput!) {
  updateStore(input: $store) {
    id
    settings{
      touchpoint_settings{
        digital_front{
          url
          order_types
          product_view{
            sort_by_name
            by_category
            show_selected
            number_of_products
            short_description
            products{
              id
              position
            }
            categories{
              id
              position
            }
          }
          receipts{
            logo
            phone
            address_type
            registration_number
            barcode
            website
            custom_message
            promotions
          }
        }
      }
    }
  }
 }
`

export const MUTATE_TOUCHPOINT_KIOSK_SETTING = `
mutation updateStore($store: StoreInput!) {
  updateStore(input: $store) {
    id
    settings{
      touchpoint_settings{
        self_order_kiosk {
          auto_receipt_print,
          vat_receipt
          call_to_action_text
          call_to_action_support_text
          call_to_action_button_text
          order_types
          payment_types
          product_view{
            sort_by_name
            by_category
            show_selected
            number_of_products
            short_description
            products{
              id
              position
            }
            categories{
              id
              position
            }
          }
          receipts{
            logo
            phone
            address_type
            registration_number
            barcode
            website
            custom_message
            promotions
          }
          banner {
            id
            url
            thumbnail
            content_type
          }
        }
      }
    }
  }
 }
`

export const PRODUCT_IN_STOREFRONT = `
query getProducts($filter: Filter){
  products(touchpoint_type: "digital_front", not_in_touchpoint_type: false, ignore_categories: true, filter: $filter){
    id
    name
    category_id
    bar_code
  }
}
`

export const ALL_PRODUCT_STOREFRONT = `
query getProducts($filter: Filter){
  products(touchpoint_type: "digital_front", not_in_touchpoint_type: true, ignore_categories: true, filter: $filter){
    id
    name
    category_id
    bar_code
  }
}
`

export const PRODUCT_IN_SOK = `
query getProducts($filter: Filter){
  products(touchpoint_type: "self_order_kiosk", not_in_touchpoint_type: false, ignore_categories: true, filter: $filter){
    id
    name
    category_id
    bar_code
  }
}
`

export const ALL_PRODUCT_SOK = `
query getProducts($filter: Filter){
  products(touchpoint_type: "self_order_kiosk", not_in_touchpoint_type: true, ignore_categories: true, filter: $filter){
    id
    name
    category_id
    bar_code
  }
}
`

export const GET_MEASURES = `
query getMeasures{
  measures {
    id
    type
    name
  }
}
`

export const GET_ACCOUNT_TYPES = `
query getAccountTypes {
  accountTypes{
    id
    name
     children_types{
      id
      name
    }
  }
}
`

export const ADD_ACCOUNT = `
mutation($input: [AccountInput]!) {
  addAccounts(input: $input) {
    id
  }
}
`

export const GET_GLOBAL_MEASURES = `
query getGlobalMeasures{
  globalDatasets{
    measures {
      id
      type
      name
    }
  }
}
`

export const GET_GLOBAL_PAYMENT_TYPES = `
query getGlobalPaymentTypes($country: String){
  globalDatasets{
    payment_types(country: $country){
      id
      name
    }
  }
}
`

export const MUTATE_MEASURES = `
mutation addMeasures($input: [MeasureInput]!){
  addMeasures(input: $input){
    id
  }
}
`

export const TOUCHPOINT_SETTING = `
  query getTouchpointSetting{
    store{
      settings{
        touchpoint_settings{
          pos{
            auto_receipt_print
              #Print receipt automatically
            auto_expense_print
             #Print expenses receipt automatically
             auto_chef_print
            float{
              status
              amount
            }
             #Float
            manual_amount{
              status
              amount
            }
              #Manual amount authorization 
            scren_lock{
              status
              time
            }
              #Auto lock 
            cashdrawer_alarm{
              status
              time
            }
               #Cash drawer alarm
            cart{
              group_by_items
            }
               #Grop items in cart 
            payment_types
              #Payment types 
            banner{
              id
              url
              thumbnail
            }
            customer_display_images{
              id
              url
              thumbnail
            }
            receipts{
              logo
              tax_summary
              phone
              cashier_name
              address_type
              registration_number
              barcode
              website
              custom_message
              promotions
            }
            product_view{
              hidden_products
              show_selected
              sort_by_name
                #Sort by alphabetical order: 
              categories{
                id
                position
              }
            }
          }
          self_order_kiosk {
            auto_receipt_print,
            vat_receipt
            call_to_action_text
            call_to_action_support_text
            call_to_action_button_text
            order_types
            payment_types
            product_view{
              hidden_products
              sort_by_name
              by_category
              show_selected
              number_of_products
              short_description
              products{
                id
                position
              }
              categories{
                id
                position
              }
            }
            receipts{
              logo
              phone
              address_type
              registration_number
              barcode
              website
              custom_message
              promotions
            }
            banner {
              id
              url
              content_type
              thumbnail
            }
          }
          digital_front{
            url
            order_types
            payment_types
            product_view{
              hidden_products
              sort_by_name
              by_category
              show_selected
              number_of_products
              short_description
              products{
                id
                position
              }
              categories{
                id
                position
              }
            }
            receipts{
              logo
              phone
              address_type
              registration_number
              barcode
              website
              custom_message
              promotions
            }
          }
        }
      }
      payment_types{
        id
        name
      }
      order_types{
        id
        name
      }
    }
  }
`

export const GET_STORE_ORDER_TYPES = `
query getStoreOrderTypes{
  orderTypes {
    id
    name
    type
    pricing_type
    is_default
    workflow {
      stages {
        id
        name
        order
        actions{
          id
          type
          name
        }
        triggers{
          id
          type
          name
        }
      }
    }
  }
}
`

export const GET_STORE_OPENING = gql`
  query getStoreOpening {
    store {
      store_openings {
        id
        date
        day
        closed
        opening_times {
          open
          close
        }
      }
    }
  }
`

export const GET_STORE_PAYMENT_TYPES = gql`
  query getStorepPayments {
    store {
      payment_types {
        id
        name
        type
        image {
          id
          url
          thumbnail
          name
          content_type
        }
      }
    }
  }
`

export const UPDATE_STORE_OPENINGS = gql`
  mutation ($store: StoreInput!) {
    updateStore(input: $store) {
      id
    }
  }
`
