import useStorePaymentTypes from '@/pages/workboard/DebtsWidget/modals/PayDebtModal/lib/useStorePaymentTypes'
import { gql, useMutation } from '@apollo/client'
import { GET_SHOPPERS } from '@/services/shopperService'
import { v4 as uuid } from 'uuid'
import { omit } from 'lodash'

export const usePaymentButtons = onClose => {
  const paymentTypes = useStorePaymentTypes()
  const [addPaymentToOrder, { isLoading }] = useMutation(
    gql`
      mutation ($input: [OrderInput]!) {
        addOrders(input: $input) {
          id
          order_series
        }
      }
    `,
    {
      refetchQueries: [{ query: GET_SHOPPERS }],
      onCompleted: () => {
        onClose()
      },
      onError: e => {
        console.log(e, 'ADD_PAYMENT_ERROR')
        onClose()
      },
    }
  )

  const onPressPay = ({ order, paymentType, paymentValue = 0 }) => {
    const payments = [
      {
        status: 'paid',
        payment_type: {
          id: paymentType.id,
          name: paymentType.name,
          type: paymentType.type,
        },
        change_due: 0,
        amount: paymentValue,
      },
    ]

    const variables = {
      input: {
        id: uuid(),
        order_id: order.id,
        order_no: order.order_no,
        state: 'completed',
        payments,
        total_price: omit(
          {
            ...order.total_price,
            taxes: order.total_price.taxes
              ?.map(el => omit(el, '__typename'))
              .filter(el => !!el.amount),
          },
          'gross_amount',
          'tax_amount',
          'gross_price',
          '__typename'
        ),
        device: {
          device_id: 'krl-business-manager',
          device_name: 'krl-business-manager',
          device_type: 'krl-business-manager',
        },
      },
    }
    console.log(variables)

    addPaymentToOrder({
      variables,
    })
  }

  return { paymentTypes, onPressPay }
}
