import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '@/theme';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),

    minWidth: 220,
    '& .MuiSelect-select:focus': {
      backgroundColor: Colors.WHITE,
    },
  },

  select: {
    borderTop: '2px solid #1174F2',
    borderRadius: 0,
    '& .MuiSelect-root ': {
      paddingTop: 0,
    },

    marginTop: 9,
    '& li': {
      fontSize: 18,
      padding: 16,
      letterSpacing: '0.56px',
      color: Colors.GRAY_DARK,
      opacity: '1',
    },
  },
  icon: {
    fill: '#939DA8',
    marginTop: '-5px',
  },
  inputColor: {
    color: Colors.DIM_GREY,
  },
  link: {
    color: Colors.DIM_GREY,
    width: '100%',
  },
}));

export default useStyles;
