import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
    },
    titleText: {
      ...CommonFonts.darkFont18,
    },
    closeButton: {
      backgroundColor: Colors.GRAY_LIGHT,
      '&:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      }
    },
  })
)

export default useStyles
