import { createStyles, makeStyles } from '@material-ui/core/styles'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      width: '100%',
      overflow: 'auto',
      borderRadius: 0,
      maxHeight: isMobile ? 200 : 400,
    },
    table: {
      minWidth: 1200,
    },
  })
)

export default useStyles
