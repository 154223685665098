import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { find } from 'lodash'

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  Chip,
  Grid,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import SingleCheckboxInput from '@/components/singleCheckbox';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { selectSelectedStore } from '@/store/modules/store/selectors';

import { gql, useMutation, useQuery } from '@apollo/client';
import {
  GET_GLOBAL_PAYMENT_TYPES,
  TOUCHPOINT_SETTING,
  UPDATE_STORE
} from '@/services/storeService';

import { capitalizeFirstLetter } from '@/utils/stringUtil';
import { Colors, CommonFonts } from '@/theme';
import useStyles from './styles';

const globalPaymentTypesQueries = gql`
  ${GET_GLOBAL_PAYMENT_TYPES}
`;

const storeQuery = gql`
  ${TOUCHPOINT_SETTING}
`;

const updateStoreQuery = gql`
  ${UPDATE_STORE}
`;

const Payment = ({ isVisible, onCloseModal }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const selectedStore = useSelector(selectSelectedStore)

  const [paymentTypes, setPaymentTypes] = useState([])

  const { data: globalPaymentTypesData, loading: isLoadingGlobalPaymentTypes } = useQuery(globalPaymentTypesQueries, {
    variables: {
      country: selectedStore.address?.country,
    },
    fetchPolicy: 'no-cache',
  });

  const globalPaymentTypes =
    globalPaymentTypesData?.globalDatasets?.payment_types || [];

  const { data: storeData, loading: isLoadingStoreData, refetch: refetchStore } = useQuery(storeQuery, {
    fetchPolicy: 'network-only',
  });

  const storePaymentTypes =
    storeData?.store?.payment_types || [];

  const [updateStoreMutation, { loading: isUpdatingStore }] = useMutation(updateStoreQuery, {
    onCompleted: () => {
      refetchStore()
      enqueueSnackbar(t('modules.payment.payment_types_updated'));
      onCloseModal()
    },
    onError: (error) => {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  })

  useEffect(() => {
    setPaymentTypes(
      globalPaymentTypes.map(paymentType => ({
        ...paymentType,
        isChecked: !!find(storePaymentTypes, { id: paymentType.id }),
      }))
    )
  }, [globalPaymentTypes.length, storePaymentTypes.length])

  const onChangePaymentType = (paymentType) => {
    setPaymentTypes(
      paymentTypes.map(item => item.id === paymentType.id ? paymentType : item)
    )
  }

  const onUpdatePaymentTypes = () => {
    const checkedPaymentTypes = paymentTypes
      .filter(item => item.isChecked)
      .map(item => ({ id: item.id }))
    const deletedPaymentTypes = storePaymentTypes
      .filter(item => !find(checkedPaymentTypes, { id: item.id }))
      .map(item => ({ id: item.id, flag: 'delete' }))

    updateStoreMutation({
      variables: {
        store: {
          id: selectedStore.id,
          payment_types: checkedPaymentTypes.concat(deletedPaymentTypes)
        }
      }
    })
  }

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      maxWidth="lg"
      onClose={onCloseModal}
      aria-labelledby={`payment`}
      open={isVisible}
      disableBackdropClick
    >
      <DialogTitle
        id={`payment`}
        onClose={onCloseModal}
      >
        {t('modules.payment.payment_types_accepted')}
      </DialogTitle>
      <DialogContent>
        <Box marginTop={10}>
          <Typography className={classes.title}>
            {t('modules.payment.select_payment_types')}
          </Typography>
        </Box>
        <Box className={classes.boxPaymentTypes}>
          {paymentTypes.map(item => (
            <SingleCheckboxInput
              isShowToast={false}
              style={{ marginRight: '40px' }}
              key={item.id}
              value={item.isChecked}
              label={capitalizeFirstLetter(item.name)}
              name={item.name}
              onChange={(value) => onChangePaymentType({ ...item, isChecked: value })}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>
          {t('settings.cancel')}
        </Button>
        <Button onClick={onUpdatePaymentTypes} color="primary">
          {t('settings.update')}
        </Button>
      </DialogActions>
      {(isUpdatingStore || isLoadingGlobalPaymentTypes || isLoadingStoreData) && <PreLoader size={25} />}
    </Dialog>
  )
}

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    minHeight: 500
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default Payment