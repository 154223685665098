import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Colors, CommonFonts } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({ 
    primaryButton: {
      color: Colors.WHITE,
      backgroundColor: Colors.BLUE,
      '&:active': {
        backgroundColor: Colors.BLUE,
      },
      '&:hover': {
        backgroundColor: Colors.BLUE,
      },
      '&:disabled': {
        backgroundColor: Colors.DISABLED,
        color: Colors.GRAY_BORDER3,
      },
    },
  })
)

export default useStyles
