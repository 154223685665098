import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    boxChecking: {
      paddingBottom: theme.spacing(7),
    },
    titleText: {
      '& .MuiFormControlLabel-label': {
        ...CommonFonts.darkFont18,
      },
    },
    descriptionText: {
      ...CommonFonts.grayFont14,
    },
  })
);

export default useStyles;
