export const SHOPPERS_SORT_TYPE = {
  no_of_invoices: 'no_of_invoices',
  outstanding: 'outstanding',
}
export const SORT_DIRECTION = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING'
}

export const SORT_FUNCTION = {
  ASCENDING: (a=0, b=0)=> a - b,
  DESCENDING: (a=0, b=0) => b - a
}

