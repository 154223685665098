import React, { useState, useEffect } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import ErrorPage from '@/pages/errorPage';
import { GET_ORDER } from '@/services/orderService'
import { useQuery } from '@apollo/client';
import PreLoader from '@/components/preLoader';
import Iconify from '@/components/iconify';
import _ from 'lodash';
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import dayjs from "dayjs";
import { getLocalisationVal, transformFloat } from '@/utils/localisationUtil';

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const ReceiptPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { merchantId, storeId, orderId } = useParams();
  const [localLogoUrl, setLocalLogoUrl] = useState(null);
  const [isConvertingLogo, setIsConvertingLogo] = useState(false);
  const { data, loading: isLoading, error } = useQuery(GET_ORDER, {
    variables: {
      id: orderId
    },
    context: {
      headers: {
        merchantId,
        storeId
      }
    }
  })

  const order = data?.order;
  const localization = order?.store?.localisation;

  const creatLocalLogo = async (url) => {
    setIsConvertingLogo(true);
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blogToLocalURL = URL.createObjectURL(blob);
      setLocalLogoUrl(blogToLocalURL);
      setIsConvertingLogo(false);
    } catch (error) {
      console.error("Error fetching pdf image:", error);
      setIsConvertingLogo(false);
    }
  };

  useEffect(() => {
    if (order?.store?.merchant?.logo?.thumbnail) {
      creatLocalLogo(order?.store?.merchant?.logo?.thumbnail);
    }
  }, [order])

  const filename =
    "Receipt" + "_" + dayjs().format("YYYY-MM-DD HH:mm") + ".pdf";

  const options = {
    filename,
    method: "save",
    resolution: Resolution.MEDIUM,
    page: {
      margin: Margin.NONE,
    },
    overrides: {
      pdf: {
        format: [100, 250],
      },
      canvas: {
        useCORS: true,
        imageTimeout: 20000,
        ignoreElements: (e) => e == document.getElementById("download"),
      },
    },
  };

  const getTargetElement = () => document.getElementById("content-id");

  if (!!error) return (<ErrorPage />)
  if (isLoading || isConvertingLogo) return (<PreLoader size={25} />)

  const getAmount = value => {
    return transformFloat(value, localization, { showCurrency: true })
  }

  return (
    <Container id="content-id" component="main" className={classes.container}>
      <div className={classes.stackFullHeight}>
        <div>
          <Box className={`${classes.whiteBox}`}>
            {!!localLogoUrl && (
              <Grid container spacing={12} style={{ justifyContent: 'center' }}>
                <Grid item>
                  <Box width={100} height={100}>
                    <img
                      src={localLogoUrl}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              spacing={2}
              className={classes.spaceBetween}
              style={{ marginTop: '16px' }}
            >
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {t("receipt_page.store", { name: order.store.name })}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t("receipt_page.touchpoint", { no: order.device?.device_name })}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {dayjs.utc(order.creation.time).local().format("DD MMM YYYY HH:mm")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {t("receipt_page.order_no")}
                </Typography>
                <Typography variant="h3" align="center">
                  {order.order_series}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.whiteBox}>
            <Grid container spacing={2} className={classes.spaceBetween}>
              <Grid item>
                <Typography variant="subtitle2" align="center">
                  {!_.isEmpty(order.payments) ? t("receipt_page.total_paid") : t("receipt_page.total")}
                </Typography>
                <Typography variant="h4" align="center">
                  {!_.isEmpty(order.payments)
                    ? getAmount(_.sumBy(order.payments, payment => payment.amount))
                    : getAmount(order.total_price.gross_amount)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {t("receipt_page.no_of_items")}
                </Typography>
                <Typography variant="h4" align="center">
                  {_.sumBy(order.items, item => parseFloat(item.price.quantity))}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.whiteBox} style={{ justifyContent: 'center' }}>
            {order.items?.map((item, index) => (
              <Grid container key={item.id} spacing={2}>
                <Grid item xs={1}>
                  <Typography variant="h7" align="left">
                    {item.price?.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h7" align="left">
                    {item.name}
                  </Typography>
                  {item.addons?.map(addon => (
                    <div>
                      <Typography key={addon.addon_id} variant="caption" color="textSecondary" className={classes.itemBox}>
                        {addon.name}
                      </Typography>
                    <br />
                    </div>
                  ))}
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h7" align="right">
                    {getAmount(item.price.total_gross_amount)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Box>
        </div>

        <Button
          id="download"
          className={classes.downloadButton}
          color="common"
          onClick={() => generatePDF(getTargetElement, options)}
          variant="contained"
          startIcon={<Iconify icon="bi:download" />}
        >
          {t('receipt_page.get_pdf_receipt')}
        </Button>
      </div>
    </Container>
  );
};

export default ReceiptPage;
