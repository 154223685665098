import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    addNewUserButton: {
      ...CommonFonts.darkFont14,
      color: Colors.WHITE,
      padding: `${theme.spacing(5)}px ${theme.spacing(10)}px`,
      maxWidth: theme.spacing(50),
      maxHeight: theme.spacing(13),
      textAlign: 'center',
    },
    title: {
      ...CommonFonts.darkFont14,
      fontWeight: '600',
      marginTop: theme.spacing(7),
    },
    emailTitle: {
      ...CommonFonts.darkFont14,
      fontWeight: '500',
      marginTop: theme.spacing(5),
    },
    subTitle: {
      ...CommonFonts.darkFont12,
      fontWeight: '600',
      marginTop: theme.spacing(5),
    },
    tableText: {
      ...CommonFonts.darkFont18,
    },
    tab: {
      '& .MuiTab-wrapper': {
        textTransform: 'none',
      },
    },
    tabs: {
      '& .MuiTabs-flexContainer': {
        borderBottomWidth: 2,
        borderBottomColor: '#E2E2E2',
        borderBottomStyle: 'solid',
      },
    },
    boxDialog: {
      '& .MuiDialog-paperWidthFalse': {
        maxWidth: '1250px',
        height: '700px',
        [theme.breakpoints.down('sm')]: {
          height: '100%',
        },
      },
    },
    boxRoleGroup: {
      flexDirection: 'row',
      marginTop: theme.spacing(2),
    },
    boxSelectGroup: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    licenseTitle: {
      ...CommonFonts.darkFont16,
      fontWeight: '600',
      marginTop: theme.spacing(10),
    },
    licenseDesription: {
      ...CommonFonts.darkFont16,
      marginTop: theme.spacing(3),
    },
    teamOptionInput: {
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    boxSelectStore: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: theme.spacing(6),
    },
    selectPaymentOption: {
      ...CommonFonts.darkFont14,
      fontWeight: '500',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(4),
    },
    newSubscription: {
      ...CommonFonts.darkFont18,
      marginTop: theme.spacing(10),
    },
  })
);

export default useStyles;
