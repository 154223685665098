import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommonFonts, Colors } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      marginBottom: theme.spacing(8),
    },
    boxAccordionTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    summary: {
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
      '&.MuiAccordionSummary-expandIcon': {
        '&:hover': {
          backgroundColor: Colors.GRAY_BORDER1_25,
        },
      },
    },
    summaryContent: {
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(7),
    },
    summaryText: {
      ...CommonFonts.darkFont16,
      fontWeight: 500,
    },
    descriptionText: {
      ...CommonFonts.grayFont16,
      color: '#505C69',
    },
    actionText: {
      ...CommonFonts.darkFont16,
      color: '#1174F2',
      cursor: 'pointer',
    },
    boxText: {
      marginTop: 0,
      [theme.breakpoints.down('sm')]: {
        marginTop: -theme.spacing(5),
        marginBottom: theme.spacing(8),
      },
    },
    details: {
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
    subTitle: {
      ...CommonFonts.darkBoldFont14,
      fontWeight: 600,
      marginBottom: theme.spacing(10),
    },
    boxChecking: {
      paddingBottom: theme.spacing(7),
    },
    inventorySubTitle: {
      ...CommonFonts.darkFont14,
      fontWeight: 500,
      paddingBottom: theme.spacing(7),
      paddingTop: theme.spacing(7),
    },
    inventoryTitle: {
      '& .MuiFormControlLabel-label': {
        ...CommonFonts.darkFont18,
      },
    },
    boxSwitch: {
      marginTop: -theme.spacing(3),
    },
    boxBankAccount: {
      display: 'flex',
      paddingLeft: theme.spacing(10),
    },
    addBankAccount: {
      textTransform: 'capitalize',
      ...CommonFonts.darkFont16,
      color: '#1174F2',
    },
    tab: {
      '& .MuiTab-wrapper': {
        textTransform: 'none',
      },
    },
    tabs: {
      '& .MuiTabs-flexContainer': {
        borderBottomWidth: 2,
        borderBottomColor: '#E2E2E2',
        borderBottomStyle: 'solid',
      },
    },
    tableText: {
      ...CommonFonts.darkFont18,
    },
    tableDescriptionText: {
      ...CommonFonts.grayFont14,
    },
    moreIcon: {
      padding: theme.spacing(1),
    },
    tabPanel: {
      '& .MuiBox-root': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    menuOption: {
      '& .MuiMenu-paper': {
        boxShadow:
          '0px 0px 0px 0px rgb(166 166 166 / 10%), 0 0px 0px 0px rgb(136 136 136 / 14%), 0 1px 4px 2px rgb(160 160 160 / 12%)',
      },
    },
    titleText: {
      ...CommonFonts.darkFont18,
    },
    descriptionText: {
      ...CommonFonts.grayFont14,
    },
    switchHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    boxSwitchPOS: {
      paddingBottom: theme.spacing(10),
    },
    optionsSwitchPOS: {
      ...CommonFonts.darkFont14,
      fontWeight: 600,
      paddingRight: theme.spacing(12),
      cursor: 'pointer',
    },
    boxImage: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(10),
    },
    title: {
      ...CommonFonts.grayFont16,
    },
    value: {
      ...CommonFonts.grayFont18,
    },
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    boxPriceType: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    expandIcon: {
      '&.MuiAccordionSummary-expandIcon:hover': {
        backgroundColor: Colors.GRAY_BORDER1_25,
      },
    },
    noAccountFound: {
      ...CommonFonts.darkFont16,
    },
    boxColorGroup: {
      margin: theme.spacing(12, 0),
    },
    addAction: {
      textTransform: 'capitalize',
      cursor: 'pointer',
      color: '#1174F2',
      fontSize: 18,
    },
  })
);

export default useStyles;
