import React, { useEffect, useState } from 'react';

import useStyles from './styles';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Paper,
  Menu,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';
import TextField from '@/components/textField';
import SearchIcon from '@material-ui/icons/Search';
import { Colors } from '@/theme';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { ADD_TOUCHPOINT } from '@/services/touchpointsManagementService';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import useDebounce from '@/hooks/useDebounce';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { selectCurrentMerchant } from '@/store/modules/store/selectors';
import { useSnackbar } from 'notistack';
import AddNewTouchpoints from '../addNewTouchpoints';
import TouchpointsFilter from '../touchpointsFilter';
import { TouchpointsType } from '@/constants/touchpointsManagement';
import UpdateTouchpoints from '../updateTouchpoints';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from '@material-ui/lab/Skeleton';

const TouchpointsPage = () => {
  const currentMerchant = useSelector(selectCurrentMerchant);
  const stores = currentMerchant?.stores?.map((store) => ({
    id: store.id,
    name: store.name,
  }));
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [searchText, setSearchText] = useState('');
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [loadingTouchpointUpdate, setLoadingTouchpointUpdate] =
    useState(false);
  const [touchpoints, setTouchpoints] = useState([]);
  const [isAllTouchpointLoaded, setIsAllTouchpointLoaded] =
    useState(false);
  const [filter, setFilter] = useState({
    store: '',
    type: '',
    option: {
      count: true,
      page: 1,
      limit: 10,
    },
  });

  const [updateData, setUpdateData] = useState({
    isOpenTouchpointModal: false,
    touchpointsDetail: {},
  });

  const setUpdateTouchpointsModal = (value) => {
    setUpdateData({ ...updateData, isOpenTouchpointModal: value });
  };

  const debouncedSearchTerm = useDebounce(searchText, 500);

  useEffect(() => {
    getTouchpoints({
      variables: {
        filter: filter.option,
      },
    });
  }, []);

  useEffect(() => {
    getTouchpoints({
      variables: {
        filter: filter.option,
      },
    });
  }, [filter.option.page]);

  useEffect(() => {
    setFilter({ ...filter, option: { ...filter.option, page: 1 } });
    setTouchpoints([]);
  }, [debouncedSearchTerm, filter.store, filter.type]);

  const refetch = () => {
    setTouchpoints([]);
    getTouchpoints({
      variables: {
        filter: {
          count: true,
          page: 1,
          limit: 10,
        },
      },
    });
  };

  const [getTouchpoints, { loading: loadingTouchpoints }] =
    useLazyQuery(
      gql`query getTouchpoints($filter: Filter){ getTouchpoints(name: "*${debouncedSearchTerm}*", ${
        filter.store !== '' ? '' : 'all_stores: true'
      }, ${
        filter.type !== '' ? `type: "${filter.type}"` : ''
      }, archived: false, filter: $filter){
        id
        name
        archived
        type
        store{
          id
          name
        }
      }
    }`,
      {
        context: {
          headers: {
            storeId: filter.store,
          },
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          const responseData = data.getTouchpoints;
          if (responseData.length < 10) {
            setIsAllTouchpointLoaded(true);
          } else {
            setIsAllTouchpointLoaded(false);
          }
          setTouchpoints(touchpoints.concat(responseData));
        },
      }
    );

  // change current page when user reaches bottom
  const handleLoadMore = () => {
    if (_.isEmpty(touchpoints)) return;
    setFilter({
      ...filter,
      option: { ...filter.option, page: filter.option.page + 1 },
    });
  };

  const [onUpdateTouchpoint] = useMutation(
    gql`
      ${ADD_TOUCHPOINT}
    `,
    {
      variables: {
        input: {
          id: updateData.touchpointsDetail.id,
          archived: true,
        },
      },
      context: {
        headers: {
          storeId: updateData.touchpointsDetail.store?.id,
        },
      },
      onCompleted: () => {
        enqueueSnackbar(
          t('touchpointsManagement.touchpoints.touchpoint_archived', {
            name: updateData.touchpointsDetail.name,
          })
        );
        refetch();
        setLoadingTouchpointUpdate(false);
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        setLoadingTouchpointUpdate(false);
        enqueueSnackbar(message, { variant: 'error' });
      },
    }
  );

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <Box>
        <div className={classes.boxFilter}>
          <Grid
            justify="space-between"
            alignItems="center"
            container
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <TextField
                placeholder={t(
                  'touchpointsManagement.touchpoints.search_placeholder'
                )}
                id="userSearch"
                name="userSearch"
                fullWidth
                value={searchText}
                onChange={(event) =>
                  setSearchText(event.target.value)
                }
                InputProps={{
                  className: classes.input,
                }}
                startAdornment={
                  <SearchIcon
                    style={{ color: Colors.GRAY_BORDER1 }}
                    width={18}
                    height={18}
                  />
                }
              />
            </Grid>

            {!isMobile && (
              <Grid item sm={12} md={6} lg={5}>
                <Grid container spacing={10}>
                  <Grid item xs={12} sm={6}>
                    <TouchpointsFilter
                      options={
                        currentMerchant?.stores?.map(
                          (item) => item.name
                        ) || []
                      }
                      name="storesFilter"
                      emptyLabel={t(
                        'touchpointsManagement.touchpoints.all_stores'
                      )}
                      onChange={(value) =>
                        setFilter({
                          ...filter,
                          store:
                            currentMerchant?.stores?.find(
                              (item) => item.name === value
                            )?.id || '',
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TouchpointsFilter
                      options={TouchpointsType?.map(
                        (item) => item.name
                      )}
                      name="typesFilter"
                      emptyLabel={t(
                        'touchpointsManagement.touchpoints.all_types'
                      )}
                      onChange={(value) =>
                        setFilter({
                          ...filter,
                          type:
                            TouchpointsType?.find(
                              (item) => item.name === value
                            )?.id || '',
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={12} lg={2}>
              <div style={{ textAlign: 'center' }}>
                <AddNewTouchpoints
                  touchpointsType={TouchpointsType}
                  stores={stores}
                  onFetchTouchpoints={refetch}
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={classes.boxUserManagement}>
          <Typography className={classes.title}>
            {t('touchpointsManagement.touchpoints.title')}
          </Typography>
          <Paper elevation={0} style={{ overflowX: 'auto' }}>
            <InfiniteScroll
              dataLength={touchpoints.length}
              next={handleLoadMore}
              hasMore={!isAllTouchpointLoaded}
              loader={
                loadingTouchpoints &&
                filter.option.page !== 1 && (
                  <div>
                    <Skeleton height={80} />
                    <Skeleton height={80} />
                    <Skeleton height={80} />
                  </div>
                )
              }
            >
              <Table className={classes.boxTable}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      className={classes.headerTableText}
                      style={{ width: '35%', borderBottom: 'none' }}
                    >
                      {t('touchpointsManagement.touchpoints.name')}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.headerTableText}
                      style={{ width: '20%', borderBottom: 'none' }}
                    >
                      {t('touchpointsManagement.touchpoints.type')}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.headerTableText}
                      style={{ width: '35%', borderBottom: 'none' }}
                    >
                      {t('touchpointsManagement.touchpoints.store')}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.headerTableText}
                      style={{ borderBottom: 'none' }}
                    />
                  </TableRow>
                </TableHead>

                {(loadingTouchpoints && filter.option.page === 1) ||
                loadingTouchpointUpdate ? (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: 'none', padding: 0 }}
                        colSpan={4}
                      >
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {touchpoints?.map((touchpoint) => (
                      <TableRow hover key={touchpoint.id}>
                        <StyledTableCell>
                          <div className={classes.boxFullName}>
                            <Typography className={classes.tableText}>
                              {touchpoint.name}
                            </Typography>
                          </div>
                        </StyledTableCell>

                        <StyledTableCell>
                          <div className={classes.boxFullName}>
                            <Typography className={classes.tableText}>
                              {TouchpointsType?.find(
                                (item) => item.id === touchpoint.type
                              )?.name || ''}
                            </Typography>
                          </div>
                        </StyledTableCell>

                        <StyledTableCell>
                          <div className={classes.boxFullName}>
                            <Typography className={classes.tableText}>
                              {touchpoint.store?.name || ''}
                            </Typography>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <IconButton
                            className={classes.moreIcon}
                            onClick={(event) => {
                              setAnchorMenu(event.currentTarget);
                              setUpdateData({
                                ...updateData,
                                touchpointsDetail: touchpoint,
                              });
                            }}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                          <Menu
                            key={touchpoint.id}
                            id="menu-options-user-detail"
                            className={classes.menuOption}
                            anchorEl={anchorMenu}
                            keepMounted
                            open={
                              Boolean(anchorMenu) &&
                              _.isEqual(
                                updateData.touchpointsDetail,
                                touchpoint
                              )
                            }
                            onClose={() => setAnchorMenu(null)}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => {
                                setAnchorMenu(null);
                                setUpdateData({
                                  ...updateData,
                                  isOpenTouchpointModal: true,
                                  touchpointsDetail: touchpoint,
                                });
                              }}
                            >
                              <Typography
                                className={classes.menuItemText}
                              >
                                {t(
                                  'touchpointsManagement.touchpoints.update'
                                )}
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={async () => {
                                setLoadingTouchpointUpdate(true);
                                setAnchorMenu(null);
                                await onUpdateTouchpoint();
                              }}
                            >
                              <Typography
                                className={classes.menuItemText}
                              >
                                {t(
                                  'touchpointsManagement.touchpoints.archive'
                                )}
                              </Typography>
                            </MenuItem>
                          </Menu>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </InfiniteScroll>
            {!loadingTouchpoints && touchpoints?.length === 0 && (
              <Box
                marginTop={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.noTouchpointsFound}>
                  {t(
                    'touchpointsManagement.touchpoints.no_touchpoints_found'
                  )}
                </Typography>
              </Box>
            )}
          </Paper>
        </div>
        <UpdateTouchpoints
          key={updateData.touchpointsDetail.id}
          isVisible={updateData.isOpenTouchpointModal}
          setUpdateTouchpointsModal={setUpdateTouchpointsModal}
          touchpointsDetail={updateData.touchpointsDetail}
          onFetchTouchpoints={refetch}
        />
      </Box>
    </ErrorBoundary>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default TouchpointsPage;
