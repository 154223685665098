import React from 'react'
import { Box, Typography, IconButton } from '@material-ui/core'
import TextField from '@/components/textField';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp'
import ArrowDown from '@material-ui/icons/KeyboardArrowDown'

import useStyles from './styles'

const NumberInput = (props) => {
  const classes = useStyles()

  const { title, value, onChange } = props

  return (
    <Box>
      <Typography className={classes.titleText}>
        {title}
      </Typography>

      <Box style={{ position: 'relative' }}>
        <TextField
          type='number'
          className={classes.textField}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <Box className={classes.arrowsWrapper}>
          <Box>
            <IconButton
              className={classes.iconButton}
              onClick={() => onChange(+value + 1)}
            >
              <ArrowUp style={{ fontSize: 20 }} />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              className={classes.iconButton}
              onClick={() => onChange(value - 1)}
            >
              <ArrowDown style={{ fontSize: 20 }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NumberInput