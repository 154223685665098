import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

const AllergyItem = ({ allergy, onDelete }) => {
  const classes = useStyles();
  const [allergyId, setAllergyId] = useState('');

  const compactString = (value) =>
    value.length >= 13 ? `${value.substring(0, 12)}...` : value;

  //dispay tooltip with allergy name
  const onMouseEnter = (id) => {
    setAllergyId(id);
  };

  //hide tooltip with allergy name
  const onMouseLeave = () => {
    setAllergyId('');
  };

  return (
    <Box className={classes.boxAllergy}>
      <Grid container justify="flex-end">
        <IconButton
          onClick={onDelete}
          size="small"
          className={classes.iconButton}
        >
          <CloseIcon fontSize="small" className={classes.closeIcon} />
        </IconButton>
      </Grid>

      <Grid container justify="center">
        <Box margin={1}>
          {allergy?.image?.thumbnail ? (
            <img
              height={80}
              width={80}
              src={allergy.image.thumbnail}
            />
          ) : (
            <div className={classes.nonImage} />
          )}
        </Box>
      </Grid>

      <Grid container justify="center">
        <Tooltip
          arrow
          title={allergy.name}
          open={_.isEqual(allergyId, allergy.id)}
        >
          <Typography
            className={classes.allergyName}
            onMouseEnter={(e) =>
              allergy?.name?.length >= 13
                ? onMouseEnter(allergy.id)
                : {}
            }
            onMouseLeave={onMouseLeave}
          >
            {compactString(allergy.name)}
          </Typography>
        </Tooltip>
      </Grid>
    </Box>
  );
};

export default AllergyItem;
