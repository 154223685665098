import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  TableRow,
  TableCell,
  withStyles,
} from '@material-ui/core'
import ActionMenu from '@/components/actionMenu'


import { Colors, CommonFonts } from '@/theme'

import { PROMOTION_TYPES, getExpiryDate } from '../../helper'
import { isPromotionActive, isPromotionExpired } from '@/utils/promotionUtil'
import useStyles from './styles'

const Row = ({ item, onUpdateDetail, onPauseActivate, onDelete }) => {
  const { t } = useTranslation()

  const rowCells = [
    {
      id: 'promotion_name',
      label: item.promo_name,
    },
    {
      id: 'promotion_type',
      label: PROMOTION_TYPES.find(promotionType => promotionType.type === item.type)?.name,
    },
    {
      id: 'expiry_date',
      label: getExpiryDate(item),
    },
    {
      id: 'status',
      label: isPromotionActive(item) ? t('promotions.active') : t('promotions.inactive'),
    },
  ];

  const actionMenus = [
    {
      name: t('promotions.update_detail'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        onUpdateDetail(data)
      },
      enabled: true
    },
    {
      name: item.active ? t('promotions.pause') : t('promotions.activate'),
      color: Colors.GRAY_DARK,
      onClick: (data) => {
        onPauseActivate(data)
      },
      enabled: !isPromotionExpired(item)
    },
    {
      name: t('button_group.delete'),
      color: Colors.RED,
      onClick: (data) => {
        onDelete(data)
      },
      enabled: true
    },
  ]

  return (
    <TableRow hover style={{ position: 'relative' }}>
      {rowCells.map(rowCell => (
        <StyledTableCell key={rowCell.id}>
          {rowCell.label}
        </StyledTableCell>
      ))}
      <StyledTableCell align='right'>
        <ActionMenu
          data={item}
          actionMenus={actionMenus.filter(menu => menu.enabled)}
        />
      </StyledTableCell>
    </TableRow>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    ...CommonFonts.darkFont16,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont16
  },
}))(TableCell);

Row.propTypes = {
  item: PropTypes.object,
}

export default Row