import I18n from '@/i18n'

export const getPerformanceData = (storePerformanceData, compareStorePerformanceData, selectedStore, selectedRegion, compareStore) => {
  return [
    {
      title: I18n.t('workboard.performance_overview.total_sales'),
      isCurrency: true,
      store: {
        name: selectedRegion?.name || selectedStore.name,
        amount: storePerformanceData?.insights?.performances?.total_value || 0
      },
      compareStore: compareStorePerformanceData
        ?
        {
          name: compareStore?.name,
          amount: compareStorePerformanceData?.insights?.performances?.total_value || 0
        }
        :
        null
    },
    {
      title: I18n.t('workboard.performance_overview.atv'),
      isCurrency: true,
      store: {
        name: selectedRegion?.name || selectedStore.name,
        amount: storePerformanceData?.insights?.performances?.average_transaction_value || 0
      },
      compareStore: compareStorePerformanceData
        ?
        {
          name: compareStore?.name,
          amount: compareStorePerformanceData?.insights?.performances?.average_transaction_value || 0
        }
        :
        null
    },
    {
      title: I18n.t('workboard.performance_overview.avg_basket_size'),
      isCurrency: false,
      store: {
        name: selectedRegion?.name || selectedStore.name,
        amount: storePerformanceData?.insights?.performances?.average_basket_size || 0
      },
      compareStore: compareStorePerformanceData
        ?
        {
          name: compareStore?.name,
          amount: compareStorePerformanceData?.insights?.performances?.average_basket_size || 0
        }
        :
        null
    },
    {
      title: I18n.t('workboard.performance_overview.not'),
      isCurrency: false,
      store: {
        name: selectedRegion?.name || selectedStore.name,
        amount: storePerformanceData?.insights?.performances?.no_of_transactions || 0
      },
      compareStore: compareStorePerformanceData
        ?
        {
          name: compareStore?.name,
          amount: compareStorePerformanceData?.insights?.performances?.no_of_transactions || 0
        }
        :
        null
    },
    {
      title: I18n.t('workboard.performance_overview.items_sold'),
      isCurrency: false,
      store: {
        name: selectedRegion?.name || selectedStore.name,
        amount: storePerformanceData?.insights?.performances?.basket_size || 0
      },
      compareStore: compareStorePerformanceData
        ?
        {
          name: compareStore?.name,
          amount: compareStorePerformanceData?.insights?.performances?.basket_size || 0
        }
        :
        null
    }
  ]
}