export const CURRENT_USER = `
  {
    currentUser{
      id
      first_name
      last_name
      email
      mobile
      verified
      merchants{
        id
        mid
        tname
        lname
        week_start_day
        financial_year_end
        logo{
          url
          thumbnail
        }
        stores{
          id
          merchant_id
          sid
          name
          email
          type
          phone
          address_id
          localisation{
            digit_separator
            currency_symbol
            currency_decimal
            currency_position
          }
          region{
            id
            region_name
          }
          address{
            door_number
            building_name
            line1
            line2
            city_town
            country
            postcode
            county
          }
          settings{
            touchpoint_settings{
              digital_front{
                url
                banner{
                  id
                  url
                }
              }
            }
          }
        }
      }
      invitations{
        id
        invite_id
        expired
        merchant_name
        created_at
        stores{
            id
            permissions
            role_id
        }
        teams{
            id
            name
        }
      }
      config{
        language
        communication{
          marketing
          product_update
        }
        tutorials{
          type
          tutorials{
            feature
            status
            order
          }
        }
      }
      profile{
        id
        url
      }
    }
  }
`;

export const GET_EMPLOYEES = `
  {
    employee {
      id,
      email,
      first_name,
      last_name
    }
  }
`;
