import { gql } from '@apollo/client';

export const GET_STYLES = gql`
  query getStyles{
    styles {
      id
      gender
      code
      name
      tags
      variants{
        id
        code
        name
        values{
          id
          values{
            name
            value
          }
        }
      }
      options{
        id
        name
        fields{
          name
          type
          display
        }
        values{
          id
          values{
            name
            value
          }
        }
      }
    }
  }
`;

export const GET_STYLE_OPTIONS = gql`
  query getStyleOptions{
    styleOptions{
      id
      name
      fields {
        type
        name
        display
      }
      values {
        id
        values {
          name
          value
        }
      }
    }
  }
`;

export const MUTATE_ADD_STYLE = gql`
  mutation($input: StyleInput!) {
    addStyle(input: $input) {
      id
    }
  }
`;

export const MUTATE_ADD_STYLE_OPTION = gql`
  mutation($input: StyleOptionInput!) {
    addStyleOption(input: $input) {
      id
    }
  }
`;