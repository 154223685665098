import React from 'react';
import useStyles from './styles';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ErrorPage from '@/pages/errorPage';
import { ErrorBoundary } from 'react-error-boundary';
import { gql, useQuery } from '@apollo/client';
import { GET_RESOURCE } from '@/services/subscriptionService';
import { GET_PLANS } from '@/services/userManagementService';
import _ from 'lodash';
import PlanItem from './planItem';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectLocalisation } from '@/store/modules/store/selectors';

const planQueries = gql`
  ${GET_PLANS}
`;

const resourceQueries = gql`
  ${GET_RESOURCE}
`;

const PlansPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const localisation = useSelector(selectLocalisation);

  const { loading: loadingPlans, data } = useQuery(planQueries, {
    fetchPolicy: 'no-cache',
  });
  const plans = data?.plans?.reduce((acc, arg) => {
    return acc.concat(arg.plans.map((plan) => plan));
  }, []);

  const { loading: loadingResource, data: resourceData } = useQuery(
    resourceQueries,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const resources = resourceData?.resources;

  const subscriptionByPaymentDate = plans
    ?.map((plan) => ({
      next_bill_amount: plan?.subscription?.next_bill_amount || 0,
      next_payment_date: plan?.subscription?.next_payment_date,
      currency: plan.currency,
    }))
    .sort(
      (a, b) =>
        new Date(a.next_payment_date) - new Date(b.next_payment_date)
    );

  const getNextBillAndPaymentDate = () => {
    const filtered = subscriptionByPaymentDate?.filter((sub) =>
      _.isEqual(
        new Date(subscriptionByPaymentDate[0]?.next_payment_date),
        new Date(sub.next_payment_date)
      )
    );

    return {
      nextBillAmount: !_.isEmpty(filtered)
        ? `${filtered[0].currency}${filtered
            ?.reduce((acc, arg) => {
              return Number(acc) + Number(arg.next_bill_amount);
            }, 0)
            .toFixed(localisation?.currency_decimal ? 2 : 0)}`
        : '',
      nextPaymentDate:
        !_.isEmpty(filtered) &&
        !_.isEmpty(filtered[0].next_payment_date)
          ? moment(new Date(filtered[0].next_payment_date)).format(
              'DD/MM/YYYY'
            )
          : '',
    };
  };

  const getDefaultPaymentMethods = () => {
    const paymentMethod = resources?.find((item) => item.is_default);
    return paymentMethod
      ? `${paymentMethod?.card?.brand} ${t(
          'subscriptions.ending_with',
          {
            number: paymentMethod?.card?.last4,
          }
        )}`
      : '';
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => console.log('ErrorBoundary: ', error)}
    >
      <Box className={classes.boxPlans}>
        <Box className={classes.boxOverview}>
          <Typography className={classes.tableText}>
            {t('subscriptions.plan_overview')}
          </Typography>

          <Box
            marginTop={5}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box marginRight={15} display="flex" alignItems="center">
              <Typography className={classes.tableText}>
                {t('subscriptions.next_bill')}
              </Typography>
              {!loadingPlans ? (
                <Typography className={classes.overviewText}>
                  {getNextBillAndPaymentDate().nextBillAmount}
                </Typography>
              ) : (
                <CircularProgress
                  style={{ marginLeft: 10 }}
                  size={20}
                />
              )}
            </Box>
            <Box marginRight={15} display="flex" alignItems="center">
              <Typography className={classes.tableText}>
                {t('subscriptions.next_payment_date')}
              </Typography>
              {!loadingPlans ? (
                <Typography className={classes.overviewText}>
                  {getNextBillAndPaymentDate().nextPaymentDate}
                </Typography>
              ) : (
                <CircularProgress
                  style={{ marginLeft: 10 }}
                  size={20}
                />
              )}
            </Box>
            <Box marginRight={15} display="flex" alignItems="center">
              <Typography className={classes.tableText}>
                {t('subscriptions.payment_methods')}
              </Typography>
              {!loadingResource ? (
                <Typography className={classes.overviewText}>
                  {getDefaultPaymentMethods()}
                </Typography>
              ) : (
                <CircularProgress
                  style={{ marginLeft: 10 }}
                  size={20}
                />
              )}
            </Box>
          </Box>
        </Box>

        {!loadingPlans ? (
          <Box>
            {plans?.map((plan) => (
              <PlanItem key={plan?.id} plan={plan} />
            ))}
          </Box>
        ) : (
          <Box className={classes.boxLoading}>
            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />
          </Box>
        )}
        {!loadingPlans && _.isEmpty(plans) && (
          <Box
            marginTop={5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography className={classes.noDataFound}>
              {t('subscriptions.no_subscription_found')}
            </Typography>
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default PlansPage;
