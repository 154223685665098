import React from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash'

import Grid from '@material-ui/core/Grid';
import { Search } from '@material-ui/icons';
import TextFiled from '@/components/textField';
import { Colors } from '@/theme';
import Button from '@/components/button';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import useStyles from './styles';

const FormHeader = ({ onChangeSearch }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={12}>
        <TextFiled
          fullWidth
          placeholder={t(
            'userManagement.applications.search_placeholder'
          )}
          className={classes.searchInput}
          onChange={debounce((e) => onChangeSearch(e.target.value), 1000)}
          startAdornment={
            <Search
              style={{ color: Colors.GRAY_BORDER1 }}
              width={18}
              height={18}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default FormHeader;
