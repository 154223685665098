import { PROMOTION_TYPE } from '@/constants/promotion'
import { v4 as uuid } from 'uuid';
import shortid from 'shortid'
import moment from 'moment'
import { find, isEmpty } from 'lodash'
import { calculatePrice } from '@/utils/priceUtil'
import { roundToTwo } from '@/utils/mathUtil'

const getReducedPrice = (promotion, orderTypes, netPrice) => {
  const product = promotion.options[0].products[0]
  const priceInfos = (product?.prices?.[0]?.price_infos || [])
    .filter(priceInfo => !!find(orderTypes, { pricing_type: priceInfo?.price_type?.type }))
  const priceInfo = priceInfos?.[0]
  const price = calculatePrice(priceInfo?.price || 0, priceInfo?.taxes || [], { includeTax: !netPrice })
  return roundToTwo(price * (1 - promotion.promo_value / 100))
}

const getTransformedDate = (time) => {
  return `1970-01-01T${time}`
}

export const getPromotionType = (promotion) => {
  if (!promotion) return null
  let promotionType = promotion.type
  if (promotionType === PROMOTION_TYPE.MANUAL) {
    if (promotion.options.length === 1 &&
      promotion.options[0].products.length === 1 &&
      promotion.options[0].categories.length === 0
    ) { // it means manual promotion created by reduced price
      promotionType = PROMOTION_TYPE.REDUCED_PRICE
    }
  }
  return promotionType
}

export const initPromotion = (promotionType, promotion, orderTypes, netPrice) => {
  let options = []

  if (promotion) {
    if (isEmpty(promotion.options)) {
      options = [{
        id: uuid(),
        group_name: '1',
        products: [],
        categories: []
      }]
    } else {
      options = promotion.options
    }
  } else {
    if (promotionType === PROMOTION_TYPE.MEAL_DEAL) {
      options = [
        {
          id: uuid(),
          group_name: '1',
          products: [],
          categories: []
        },
        {
          id: uuid(),
          group_name: '2',
          products: [],
          categories: []
        },
        {
          id: uuid(),
          group_name: '3',
          products: [],
          categories: []
        }
      ]
    } else {
      options = [{
        id: uuid(),
        group_name: '1',
        products: [],
        categories: []
      }]
    }
  }

  return {
    id: promotion ? promotion.id : uuid(),
    promo_no: promotion ? promotion.promo_no : shortid.generate(),
    type: promotionType,
    item_numbers: promotion ? promotion.item_numbers?.toString() : 0,
    promo_name: promotion ? promotion.promo_name : '',
    valid_from: promotion?.valid_from ? moment(promotion.valid_from).toDate() : moment().toDate(),
    valid_to: promotion?.valid_to ? moment(promotion.valid_to).toDate() : null,
    start_time: promotion?.start_time ? moment(getTransformedDate(promotion.start_time)).toDate() : moment().toDate(),
    end_time: promotion?.end_time ? moment(getTransformedDate(promotion.end_time)).toDate() : moment().toDate(),
    promo_value: promotion ? promotion.promo_value?.toString() : 0,
    trigger_amount: promotion ? promotion.trigger_amount?.toString() : 0,
    fixed_price: promotion ? promotion.fixed_price : 0,
    active: promotion ? promotion.active : true,
    mix_match: promotion ? promotion.mix_match : true,
    options,
    further_notice: !!(promotion && !promotion.valid_to),
    add_happy_hour: !!(promotion && promotion.start_time),
    reduced_price: (promotion && promotionType === PROMOTION_TYPE.REDUCED_PRICE) ? getReducedPrice(promotion, orderTypes, netPrice) : 0
  }
}

export const generateVariablesToAddPromotion = (promotion, storeId, orderTypes) => {
  let variables = {
    promotion: {
      id: promotion.id,
      store_id: storeId,
      type: promotion.type,
      item_numbers: parseFloat(promotion.item_numbers || '0'),
      promo_name: promotion.promo_name,
      valid_from: promotion.valid_from ? moment(promotion.valid_from).utc() : null,
      valid_to: promotion.valid_to ? moment(promotion.valid_to).utc() : null,
      start_time: promotion.add_happy_hour ? moment(promotion.start_time).format('HH:mm') : null,
      end_time: promotion.add_happy_hour ? moment(promotion.end_time).format('HH:mm') : null,
      active: promotion.active,
      fixed_price: parseFloat(promotion.fixed_price || '0'),
      promo_value: parseFloat(promotion.promo_value || '0'),
      trigger_amount: parseFloat(promotion.trigger_amount || '0'),
      mix_match: promotion.mix_match,
      options: promotion.options.map(option => ({
        inventory_id: option.products.map(product => product.id),
        category_id: option.categories.map(category => category.id)
      })),
      order_type: orderTypes?.map(orderType => orderType.id)?.join(',')
    }
  }
  return variables
}