import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { Bar } from 'react-chartjs-2'
import { Box } from '@material-ui/core'

import { selectLocalisation } from '@/store/modules/store/selectors'

import { Colors } from '@/theme'
import { getOmissionString } from '@/utils/stringUtil'
import { transformFloat } from '@/utils/localisationUtil'

import I18n from '@/i18n'
import useStyles from './styles'

const Chart = (props) => {
  const classes = useStyles();

  const { items } = props;

  const localisation = useSelector(selectLocalisation);

  const getLabels = () => {
    return items.map(item => item?.name)
  }

  const getData = (type) => {
    return items.map(item => {
      if (type === 'bar') {
        return (+item?.total_sale?.net) + (+item?.total_sale?.tax) + (+item?.total_sale?.promotion) + (+item?.total_sale?.promotion_tax)
      }
      else return item.item_sold
    })
  }

  const dataLine = {
    labels: getLabels(),
    datasets: [
      {
        type: 'line',
        label: I18n.t('workboard.product_sales_analysis.no_items'),
        fill: false,
        lineTension: 0,
        borderColor: "#FFC624",
        borderCapStyle: "butt",
        borderDash: [],
        borderWidth: isMobile ? 1 : 3,
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        data: getData('line'),
        yAxisID: 'y-axis-2',
      },
      {
        type: 'bar',
        label: I18n.t('workboard.product_sales_analysis.gross_sales'),
        backgroundColor: "#0091ff",
        borderColor: "#0091ff",
        data: getData('bar'),
        borderWidth: 1,
        yAxisID: 'y-axis-1',
      },
    ]
  }

  const calculateWidth = () => {
    return 50 * items.length
  }

  const getTooltipAmount = (amount, isCurrencyAmount) => {
    if (isCurrencyAmount) {
      return transformFloat(amount, localisation, { showCurrency: true })
    } else {
      return amount
    }
  }

  return (
    <Box className={classes.chartContainer}>
      <Bar
        data={dataLine}
        // width={calculateWidth()}
        height={isMobile ? 200 : 70}
        options={{
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                return I18n.t('workboard.product_sales_analysis.gross_sales') + ': ' + getTooltipAmount(data['datasets'][1]['data'][tooltipItem['index']], true);
              },
              afterLabel: (tooltipItem, data) => {
                return I18n.t('workboard.product_sales_analysis.no_items') + ': ' + getTooltipAmount(data['datasets'][0]['data'][tooltipItem['index']], false);
              },
              title: (tooltipItem, data) => {
                return data['labels'][tooltipItem[0]['index']];
              },
            },
            displayColors: false,
            backgroundColor: '#dceafd',
            titleFontColor: Colors.GRAY_DARK,
            bodyFontColor: Colors.GRAY_DARK,
          },
          responsive: true,
          scales: {
            xAxes: [{
              ticks: {
                userCallback: (label) => {
                  return getOmissionString(label, 8)
                }
              },
              gridLines: { display: false },
              // barPercentage: 0.1
            }],
            yAxes: [
              {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                ticks: {
                  beginAtZero: true,
                  userCallback: function (label) {
                    if (Math.floor(label) === label) {
                      return label;
                    }
                  },
                },
                gridLines: { display: false }
              },
              {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                ticks: {
                  beginAtZero: true,
                  userCallback: function (label) {
                    if (Math.floor(label) === label) {
                      return label;
                    }
                  },
                },
                gridLines: { display: false }
              },
            ],
          }
        }}
      />
    </Box>
  )
}

Chart.propTypes = {
  items: PropTypes.array,
}

export default React.memo(Chart)