import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import moment from 'moment'

import { Box, Grid, Typography } from '@material-ui/core'
import PreLoader from '@/components/preLoader'
import PerformanceSelect from './performanceSelect'
import LineChart from './lineChart'

import { gql, useQuery } from '@apollo/client'
import {
  GET_ACCOUNT_TYPES_FOR_TRENDS,
  GET_INSIGHTS_PERFORMANCE_FOR_TRENDS
} from '@/services/widgetService'

import { selectLocalisation } from '@/store/modules/store/selectors'
import { selectSelectedRegion, selectSelectedStore } from '@/store/modules/store/selectors'

import {
  PERFORMANCE,
  getPerformanceTotal,
  getChartData
} from './helper'

import useStyles from './styles';

const Trends = (props) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const { startTime, endTime, dateFilter } = props
  const localisation = useSelector(selectLocalisation);
  const selectedRegion = useSelector(selectSelectedRegion);
  const selectedStore = useSelector(selectSelectedStore)

  const { data: accountTypesData, loading: isLoadingAccountTypes } = useQuery(gql`${GET_ACCOUNT_TYPES_FOR_TRENDS}`, {
    variables: {
      start: moment(startTime).format('YYYY-MM-DD'),
      end: moment(endTime).format('YYYY-MM-DD')
    },
    context: {
      headers: {
        storeId: selectedRegion ? selectedRegion.id : selectedStore.id
      },
    },
    fetchPolicy: 'network-only'
  })

  const { data: insightsData, loading: isLoadingInsightsPerformance } = useQuery(gql`${GET_INSIGHTS_PERFORMANCE_FOR_TRENDS}`, {
    variables: {
      start: moment(startTime).format('YYYY-MM-DD'),
      end: moment(endTime).format('YYYY-MM-DD')
    },
    context: {
      headers: {
        storeId: selectedRegion ? selectedRegion.id : selectedStore.id
      },
    },
    fetchPolicy: 'network-only'
  })

  const [firstPerformance, setFirstPerformance] = useState(PERFORMANCE.REVENUE)
  const [secondPerformance, setSecondPerformance] = useState(PERFORMANCE.EXPENSES)

  const firstChartData = getChartData(startTime, endTime, dateFilter, firstPerformance, accountTypesData, insightsData)
  const secondChartData = getChartData(startTime, endTime, dateFilter, secondPerformance, accountTypesData, insightsData)

  return (
    <Box className={classes.root}>
      <Typography className={classes.titleText}>{t('globalWidgets.trends')}</Typography>
      <Box
        display='flex'
        justifyContent={isMobile ? 'center' : 'flex-end'}
        className={classes.selectsContainer}
      >
        <Box>
          <Grid container>
            <Grid item className={classes.firstSelectContainer}>
              <PerformanceSelect
                selectedPerformance={firstPerformance}
                disabledPerformance={secondPerformance}
                onChangePerformance={(value) => setFirstPerformance(value)}
                performanceTotal={getPerformanceTotal(firstPerformance, accountTypesData, insightsData, localisation)}
              />
            </Grid>
            <Grid item className={classes.secondSelectContainer}>
              <PerformanceSelect
                selectedPerformance={secondPerformance}
                disabledPerformance={firstPerformance}
                onChangePerformance={(value) => setSecondPerformance(value)}
                performanceTotal={getPerformanceTotal(secondPerformance, accountTypesData, insightsData, localisation)}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box>
        <LineChart
          firstPerformance={firstPerformance}
          secondPerformance={secondPerformance}
          firstChartData={firstChartData}
          secondChartData={secondChartData}
          localisation={localisation}
        />
      </Box>

      {(isLoadingAccountTypes || isLoadingInsightsPerformance) && <PreLoader size={30} />}
    </Box>
  )
}

Trends.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  dateFilter: PropTypes.string
}

export default React.memo(Trends)