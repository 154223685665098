import React from 'react';
import { Button } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import useStyles from './styles';

const NotifyStackProvider = ({ children }) => {
  const classes = useStyles();

  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      ref={notistackRef}
      className={classes.contentRoot}
      autoHideDuration={5000}
      variant="default"
      preventDuplicate
      hideIconVariant
      dense={false}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      TransitionComponent={Slide}
      action={(key) => (
        <Button
          className={classes.actionButton}
          onClick={onClickDismiss(key)}
        >
          OK
        </Button>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotifyStackProvider;
