import {
  Grid,
  Hidden,
  Box,
  Breadcrumbs,
  Link,
} from '@material-ui/core';
import React from 'react';
import {
  Route,
  Switch,
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import NotFoundPage from '@/pages/notFoundPage';
import UsersPage from '@/pages/userManagement/users';
import ApplicationsPage from './applications'
import TeamsPage from '@/pages/userManagement/teams';
import AppLayout from '@/components/appLayout';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import {
  Directions,
  UsersManagementBreadcrumbs,
} from '@/constants/userManagement';
import ArchivedUserPage from './archivedUsers';

const UserManagementPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const urlName = location.pathname;

  return (
    <AppLayout className={classes.appLayout} withFooter header>
      <Box marginY={10}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className={classes.breadcrumbsText}
            color="primary"
            component={RouterLink}
            to="/business-manager/store-profile"
          >
            {t('userManagement.users.business_manager')}
          </Link>
          <Link
            className={classes.breadcrumbsText}
            color="textPrimary"
            component={RouterLink}
            to="/user-management/users"
          >
            {t('userManagement.users.user_management')}
          </Link>
          <Link
            className={classes.breadcrumbsText}
            color="textPrimary"
            component={RouterLink}
            to={
              UsersManagementBreadcrumbs.find(
                (item) => item.path === urlName
              )?.path
            }
          >
            {t(
              `menu.${UsersManagementBreadcrumbs.find(
                (item) => item.path === urlName
              )?.name
              }`
            )}
          </Link>
        </Breadcrumbs>
      </Box>
      <Grid container spacing={6}>
        <Hidden smDown>
          <Grid item md={2}>
            <div className={classes.boxSidebar}>
              {Directions?.map((item) => (
                <div
                  key={item.name}
                  className={
                    item.path === urlName
                      ? classes.boxLinkActive
                      : classes.boxLink
                  }
                >
                  <Link
                    underline="none"
                    component={RouterLink}
                    className={classes.linkText}
                    color="textPrimary"
                    to={item.path}
                  >
                    {t(`menu.${item.name}`)}
                  </Link>
                </div>
              ))}
            </div>
          </Grid>
        </Hidden>
        <Grid item md={10}>
          <Switch>
            <Route
              exact
              path="/user-management/users"
              component={UsersPage}
            />
            <Route
              exact
              path="/user-management/applications"
              component={ApplicationsPage}
            />
            <Route
              exact
              path="/user-management/archived-users"
              component={ArchivedUserPage}
            />
            <Route
              exact
              path="/user-management/teams"
              component={TeamsPage}
            />
            {/* Show Not Found page when url does not match */}
            <Route component={NotFoundPage} />
          </Switch>
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default UserManagementPage;
