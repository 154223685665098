import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../constants/colors';
import { CommonFonts } from '../../constants/fonts';

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      padding: theme.spacing(12, 15),
    },
    headerDrawer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      justifyContent: 'space-between',
    },
    buttonLang: {
      textTransform: 'initial',
      letterSpacing: 0.29,
      fontWeight: 'normal',
      ...CommonFonts.darkFont16,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    button: {
      letterSpacing: 0.29,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    buttonTab: {
      backgroundColor: Colors.GRAY_BORDER1,
    },
    title: {
      letterSpacing: 0.18,
      marginBottom: theme.spacing(10),
      ...CommonFonts.darkFont24,
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      minWidth: 'max-content',
    },
  })
);

export default useStyles;
