import { CommonFonts } from "@/theme";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      position: "relative",
      borderRadius: "4px",
      backgroundColor: "#ffffff",
      padding: theme.spacing(6),
    },
    actionButton: {
      position: 'absolute',
      top: "5px",
      right: "5px",
    },
    dragButton: {
      position: "absolute",
      top: "0px",
      right: 'calc(50% - 88px)',
      height: "20px",
      width: '180px',
      borderBottomLeftRadius: '35px',
      borderBottomRightRadius: '35px',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: '#bac3c9d1',
      transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      "&:hover": {
        backgroundColor: "#BAC3C9",
        cursor: "grab",
      },
      "&:active": {
        backgroundColor: '#939DA8'
      },
    },
    dragDropText: {
      ...CommonFonts.darkFont10
    }
  })
);

export default useStyles;
