import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    chartContainer: {
      // overflowX: 'scroll'
    },
  })
)

export default useStyles
