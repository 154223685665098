import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  TableRow,
  TableCell,
  withStyles
} from '@material-ui/core'

import { selectLocalisation } from '@/store/modules/store/selectors'

import { Colors, CommonFonts } from '@/theme'

import { transformFloat } from '@/utils/localisationUtil'

const Row = ({ item }) => {

  const localisation = useSelector(selectLocalisation);

  const getCellValue = (value) => {
    return transformFloat(value, localisation)
  }

  const rowCells = [
    {
      id: 'product',
      label: item.name,
    },
    {
      id: 'barcode',
      label: item.bar_code,
    },
    {
      id: 'no_items_sold',
      label: item.item_sold,
    },
    {
      id: 'net_sales',
      label: getCellValue(item?.total_sale?.net + (+item?.total_sale?.promotion)),
    },
    {
      id: 'taxes',
      label: getCellValue(item?.total_sale?.tax + (+item?.total_sale?.promotion_tax)),
    },
    {
      id: 'gross_sales',
      label: getCellValue((+item?.total_sale?.net) + (+item?.total_sale?.tax) + (+item?.total_sale?.promotion) + (+item?.total_sale?.promotion_tax)),
    },
  ];

  return (
    <TableRow hover>
      {rowCells.map(rowCell => (
        <StyledTableCell key={rowCell.id}>
          {rowCell.label}
        </StyledTableCell>
      ))}
    </TableRow>
  )
}

const StyledTableCell = withStyles(() => ({
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    ...CommonFonts.darkFont16,
    fontWeight: 'normal',
    borderBottomColor: Colors.GRAY_BORDER1_25
  },
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    ...CommonFonts.darkFont16
  },
}))(TableCell);

Row.propTypes = {
  item: PropTypes.object,
}

export default Row