import React from 'react';
import { useTranslation } from 'react-i18next';
import { debounce, find } from 'lodash';

import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { Search, ExpandMore } from '@material-ui/icons';
import TextFiled from '@/components/textField';
import { Colors } from '@/theme';
import Button from '@/components/button';
import Paper from '@material-ui/core/Paper';

import useStyles from './styles';

const FormHeader = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    segmentTypes,
    onChangeSegmentTypes,
    onChangeSearch,
    onAdd,
    disabled = false,
  } = props;

  return (
    <Grid item xs={12}>
      <Paper style={{ width: '100%' }}>
        <Grid
          container
          alignItems="center"
          spacing={2}
          className={classes.paperContent}
        >
          <Grid item xs={12} sm={6}>
            <TextFiled
              disabled={disabled}
              placeholder={t('customers.enter_customer_details')}
              className={classes.searchInput}
              onChange={debounce(
                (e) => onChangeSearch(e.target.value),
                1000
              )}
              startAdornment={
                <Search
                  style={{ color: Colors.GRAY_BORDER1 }}
                  width={18}
                  height={18}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={4} style={{ padding: '4px 32px' }}>
            <Select
              multiple
              disableUnderline
              IconComponent={ExpandMore}
              displayEmpty
              renderValue={() => t('customers.all_segments')}
              fullWidth
              className={classes.filterInput}
              value={segmentTypes}
              onChange={(event) =>
                onChangeSegmentTypes(event.target.value)
              }
              inputProps={{ 'aria-label': 'Without label' }}
              disabled
            >
              {/*// to be realised*/}
              {/*{SEGMENT_TYPES.map((item, id) => (*/}
              {/*  <MenuItem key={`order-payment-state-${id}`} value={item} disabled>*/}
              {/*    <GreenCheckbox*/}
              {/*      color='default'*/}
              {/*      checked={!!find(segmentTypes, { type: item.type })}*/}
              {/*      size='small'*/}
              {/*    />*/}
              {/*    <Typography className={classes.titleText}>*/}
              {/*      {item.name}*/}
              {/*    </Typography>*/}
              {/*  </MenuItem>*/}
              {/*))}*/}
            </Select>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Grid container justify="flex-end">
              <Button
                className={classes.addButton}
                color="primary"
                variant="contained"
                onClick={onAdd}
              >
                {t('button_group.add_new')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const GreenCheckbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default FormHeader;
