export const GET_MODULES = `
query getModules{
    modules{
      id
      name
      description
      status
      type
    }
  }
`;

export const UPDATE_MODULE = `
mutation addModules($input: [ModuleInput!]!) {
  addModules(input: $input) {
    id
  }
 }
`;

export const GET_FLOOR = `
query getFloor{
  floors{
    id
    name
    type
  }
}
`;

export const MUTATE_FLOOR = `
mutation addFloors($input: [FloorInput]!) {
  addFloors(input: $input) {
      id
  }
}
`;

export const GET_AREA = `
query getArea{
  areas{
    floor{
      id
      name
      type
    }
    id
    name
    shelves{
      id
      aisle
      shelf
    }
  }
}
`;

export const GET_SHELVES = `
query getShelves{
  shelves{
    id
    aisle
    shelf
    customer_access
    area{
      id
      name
    }
  }
}
`;

export const MUTATE_AREAS = `
mutation addAreas($input: [AreaInput]!) {
  addAreas(input: $input) {
      id
  }
}
`;

export const MUTATE_SHELVES = `
mutation addShelves($input: [ShelfInput]!) {
  addShelves(input: $input) {
      id
  }
}
`;
