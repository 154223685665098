import React, { useEffect, useMemo } from 'react';

import {
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  TextField,
  MenuItem,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  ADD_ACCOUNT,
  GET_ACCOUNT_TYPES,
} from '@/services/storeService';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';

const getAccountTypesQuery = gql`
  ${GET_ACCOUNT_TYPES}
`;

const SettingAccountForm = ({
  onFetchAccounts,
  open = false,
  account,
  ...dialogProps
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const action = open;

  const { data } = useQuery(getAccountTypesQuery);

  const accountTypeOptions = useMemo(
    () =>
      data?.accountTypes?.reduce((acc, parentType) => {
        const parentOption = {
          id: parentType.id,
          name: parentType.name,
          isParent: true,
        };
        const childOptions = parentType.children_types.map(
          ({ name, id }) => ({ id, name, isParent: false })
        );
        return acc.concat(parentOption).concat(childOptions);
      }, []),
    [data]
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(
        t('settings.chart_of_accounts.add_account.name_required')
      )
      .max(150, t('settings.chart_of_accounts.add_account.name_max')),
    description: Yup.string().max(
      400,
      t('settings.chart_of_accounts.add_account.description_max')
    ),
    type: Yup.object()
      .shape({
        id: Yup.string(),
        name: Yup.string(),
      })
      .required(
        t('settings.chart_of_accounts.add_account.type_required')
      ),
    showInPerformanceReview: Yup.boolean(),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    isValid,
    resetForm,
    getFieldProps,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: action === 'ADD' ? '' : account?.name,
      description: action === 'ADD' ? '' : account?.description,
      type:
        action === 'ADD'
          ? null
          : accountTypeOptions?.find(
              (option) => option.id === account?.account_type_id
            ),
      showInPerformanceReview:
        action === 'ADD' ? false : account?.display_in_report,
    },
    validationSchema: validationSchema,
    onSubmit: ({
      type,
      name,
      description,
      showInPerformanceReview,
    }) => {
      const payload = {
        account_type_id: type.id,
        name,
        description,
        display_in_report: showInPerformanceReview,
      };
      return onMutateAccount({
        variables: {
          input:
            action === 'ADD'
              ? payload
              : { id: account?.id, ...payload },
        },
      });
    },
  });

  const [onMutateAccount, { loading: mutationLoading }] = useMutation(
    gql`
      ${ADD_ACCOUNT}
    `,
    {
      onCompleted: async () => {
        enqueueSnackbar(
          t(
            action === 'ADD'
              ? 'settings.chart_of_accounts.add_account.new_account_added'
              : 'settings.chart_of_accounts.add_account.account_updated',
            { name: values.name }
          )
        );
        await onFetchAccounts?.();
        resetForm();
        dialogProps.onClose();
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0].message;
        enqueueSnackbar(message, { variant: 'error' });
      },
    }
  );

  return (
    // open can be ADD, EDIT, or false, when it's ADD or EDIT, we open the dialog.
    <Dialog open={Boolean(open)} {...dialogProps}>
      <DialogTitle
        id="chartOfAccount-addAccount"
        onClose={dialogProps.onClose}
      >
        {t(
          action === 'ADD'
            ? 'settings.chart_of_accounts.add_account.modal_title_add'
            : 'settings.chart_of_accounts.add_account.modal_title_edit'
        )}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box marginBottom={8}>
            <TextField
              id="name"
              name="name"
              label={t(
                'settings.chart_of_accounts.add_account.account_name'
              )}
              fullWidth
              value={values.name}
              onChange={handleChange}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              autoFocus
              onBlur={handleBlur}
              InputProps={{
                className: classes.input,
              }}
            />
          </Box>
          <Box marginBottom={8}>
            <TextField
              id="description"
              name="description"
              label={t(
                'settings.chart_of_accounts.add_account.account_description'
              )}
              fullWidth
              multiline
              rows={3}
              rowsMax={3}
              value={values.description}
              onChange={handleChange}
              error={
                touched.description && Boolean(errors.description)
              }
              helperText={touched.description && errors.description}
              onBlur={handleBlur}
              InputProps={{
                className: classes.input,
              }}
            />
          </Box>
          <Box marginBottom={8}>
            <TextField
              id="type"
              name="type"
              select
              label={t(
                'settings.chart_of_accounts.add_account.account_type'
              )}
              className={classes.textField}
              value={
                accountTypeOptions?.find(
                  (option) => values.type?.id === option.id
                ) || values.type
              }
              onChange={handleChange}
              margin="normal"
              fullWidth
              error={touched.type && Boolean(errors.type)}
              helperText={touched.type && errors.type}
              InputProps={{
                className: classes.input,
              }}
            >
              {accountTypeOptions?.map((option) => (
                <MenuItem
                  style={{ opacity: 1 }}
                  className={
                    option.isParent
                      ? classes.parentItem
                      : classes.childItem
                  }
                  key={option.id}
                  value={option}
                  disabled={option.isParent}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box marginBottom={8}>
            <Typography>
              {t('settings.chart_of_accounts.add_account.reports')}
            </Typography>
            <FormControlLabel
              name="showInPerformanceReview"
              control={
                <Checkbox
                  className={classes.checkbox}
                  size="small"
                  color="default"
                  {...getFieldProps('showInPerformanceReview')}
                  checked={values.showInPerformanceReview}
                />
              }
              label={t(
                'settings.chart_of_accounts.add_account.account_show_in_performance_report'
              )}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogProps.onClose}>
          {t('settings.cancel')}
        </Button>
        <Button
          disabled={!isValid}
          onClick={handleSubmit}
          type="submit"
          color="primary"
        >
          {action === 'ADD'
            ? t('settings.chart_of_accounts.add_account.add')
            : t('settings.chart_of_accounts.add_account.update')}
        </Button>
      </DialogActions>
      {mutationLoading && <PreLoader size={25} />}
    </Dialog>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default SettingAccountForm;
