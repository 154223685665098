import React, { useState } from 'react';

import { Table, TableBody } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { forEach, some } from 'lodash';
import TableHeader from './tableHeader';
import TableRow from './tableRow';

import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import SingleSwitchInput from '@/components/singleSwitch';
import { useMutation } from '@apollo/client';
import { UPDATE_STORE_OPENINGS } from '@/services/storeService';
import { useSelector } from 'react-redux';
import { selectSelectedStore } from '@/store/modules/store';
import { useSnackbar } from 'notistack';
import UpdateDialog from '../updateDialog';

const TableComponent = ({
                          slots,
                          isLoadingSlots,
                          setRefetch,
                          dataSlots
                        }) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const currentStore = useSelector(selectSelectedStore);
  const { enqueueSnackbar } = useSnackbar();
  const [updateDialog, setUpdateDialog] = useState(false);
  const [dataForEdit, setDataForEdit] = useState(0);

  const handleChangeMaxOrder = (index) => {
    setUpdateDialog(true);
    setDataForEdit(index);
  };

  const [UpdateData, { loading: updating }] =
    useMutation(UPDATE_STORE_OPENINGS, {
      onCompleted: (response) => {
        setRefetch();
        setIsLoading(false);
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    });

  const handleChangeAllDeliverySwitch = (e) => {
    setIsLoading(true);
    const isNeedRewrite = some(dataSlots, ['date', slots.date]);
    let payloadData = dataSlots.map((item) => {
      delete item.__typename;
      forEach(item.slots, function(value) {
        delete value.__typename;
      });
      forEach(slots?.slots, function(value) {
        delete value.__typename;
        if (item.date === slots.date) {
          value.disabled = !e;
        }
      });
      if (item.date === slots.date) {
        item.disabled = !e;
      }
      return item;
    });
    payloadData = isNeedRewrite ? payloadData : [...payloadData, ...[{
      date: slots.date,
      disabled: !e,
      slots: forEach(slots?.slots, function(value, key) {
        value.disabled = !e;
      })
    }]];

    const payload = {
      variables: {
        store: {
          id: currentStore.id,
          settings: {
            delivery: {
              day_slots: payloadData
            }
          }
        }
      }
    };
    return UpdateData(payload);
  };
  const handleChangeSwitch = (switchValue, index) => {
    const isNeedRewrite = some(dataSlots, ['date', slots.date]);
    let payloadData = dataSlots.map((item) => {
      delete item.__typename;
      item.slots = forEach(slots?.slots, function(value, key) {
        delete value.__typename;
        if (item.date === slots.date && key === index) {
          value.disabled = switchValue;
        }
      });
      return item;
    });
    payloadData = isNeedRewrite ? payloadData : [...payloadData, ...[{
      date: slots.date,
      disabled: slots.disabled,
      slots: forEach(slots?.slots, function(value, key) {
        delete value.__typename;
        if (key === index) {
          value.disabled = switchValue;
        }
      })
    }]];
    const payload = {
      variables: {
        store: {
          id: currentStore.id,
          settings: {
            delivery: {
              day_slots: payloadData
            }
          }
        }
      }
    };
    return UpdateData(payload);
  };

  return (
    <>
      <div className={classes.deliveringBlock}>
        <div className={classes.delivering}><Typography paragraph gutterBottom variant='subtitle2'>
          {t('workboard.manage_store.delivery_slots.delivering')}
        </Typography>
          <Typography paragraph gutterBottom variant='body2'>
            {t('workboard.manage_store.delivery_slots.delivering_description')}
          </Typography>
        </div>
        <div className={classes.switch}>
          <SingleSwitchInput notificationMessage={t('workboard.manage_store.delivery_slots.delivering_status')}
                             value={!slots?.disabled} onChange={(e) => handleChangeAllDeliverySwitch(e)} />
        </div>
      </div>
      <Typography paragraph gutterBottom variant='subtitle2' style={{ width: 100 }}>
        {t('workboard.manage_store.delivery_slots.slots_title')}
      </Typography>
      <Typography paragraph gutterBottom variant='body2'>
        {t('workboard.manage_store.delivery_slots.slots_description')}
      </Typography>
      <Table>
        {isLoadingSlots || isLoading ? (
          <div>
            <Skeleton height={80} />
            <Skeleton height={80} />
            <Skeleton height={80} />
          </div>
        ) : (
          <>
            <TableHeader />
            <TableBody>
              {slots?.slots?.map((item, index) => (
                <TableRow
                  key={index + 1}
                  item={item}
                  index={index}
                  disabled={slots.disabled}
                  updateSlotSwitch={(switchValue, index) => handleChangeSwitch(switchValue, index)}
                  onEdit={(index) => handleChangeMaxOrder(index)}
                />
              ))}
            </TableBody>
          </>
        )}
      </Table>
      {updateDialog &&
      <UpdateDialog open={updateDialog}
                    handleClose={() => setUpdateDialog(false)}
                    index={dataForEdit}
                    slotsData={slots}
                    dataSlots={dataSlots}
                    setRefetch={setRefetch}
      />
      }
    </>
  );
};

export default TableComponent;
