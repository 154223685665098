import React from 'react'

import {
  Table,
  TableBody,
} from '@material-ui/core'

import TableHeader from './tableHeader'
import TableRow from './tableRow'

const TableComponent = (props) => {
  const {
    option,
    onSelectUpdate,
    onSelectDelete
  } = props

  return (
    <Table>
      <TableHeader fields={option.fields} />
      <TableBody>
        {option.values.map((value) => (
          <TableRow
            key={value.id}
            fields={option.fields}
            item={value}
            onSelectDelete={onSelectDelete}
            onSelectUpdate={onSelectUpdate}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default TableComponent