import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: 290,
      paddingRight: theme.spacing(10),
    },
    sliderContainer: {
      padding: theme.spacing(7, 2),
      borderWidth: 1,
      borderColor: Colors.GRAY_BORDER1,
      borderStyle: 'solid',
      borderRadius: 6,
    },
    titleText: {
      ...CommonFonts.darkFont16,
      textAlign: 'center',
      fontWeight: 500,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    storenameText: {
      ...CommonFonts.darkFont16,
      textAlign: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginTop: theme.spacing(6)
    },
    amountText: {
      ...CommonFonts.blueBoldFont30,
      lineHeight: '30px',
      textAlign: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginTop: theme.spacing(6),
      fontWeight: 500
    },
    divider: {
      width: '50%',
      margin: 'auto',
      marginTop: theme.spacing(6),
    }
  })
)

export default useStyles
