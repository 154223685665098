import { makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) => ({
  boxAllergy: {
    border: `${theme.spacing(0.25)}px solid ${Colors.GRAY_BORDER1}`,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    width: theme.spacing(33),
    height: theme.spacing(33),
  },
  allergyName: {
    ...CommonFonts.darkFont18,
    wordBreak: 'break-word',
  },
  iconButton: {
    backgroundColor: Colors.GRAY_LIGHT,
  },
  closeIcon: {
    fontSize: '14px',
  },
  nonImage: {
    backgroundColor: Colors.GRAY_LIGHT,
    width: theme.spacing(20),
    height: theme.spacing(20),
    borderRadius: '50%',
  },
}));

export default useStyles;
