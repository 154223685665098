import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommonFonts, Colors } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLayout: {
      backgroundColor: Colors.GRAY_LIGHT,
    },
    firstStepRoot: {
      backgroundColor: Colors.WHITE,
      border: `1px solid ${Colors.GRAY_BORDER1}`,
      borderRadius: 5,
      maxWidth: 930,
      margin: 'auto',
      marginBottom: theme.spacing(7),
      padding: theme.spacing(15, 10),
    },

    root: {
      backgroundColor: Colors.WHITE,
      border: `1px solid ${Colors.GRAY_BORDER1}`,
      borderRadius: 5,
      maxWidth: 630,
      margin: 'auto',
      marginBottom: theme.spacing(7),
      padding: theme.spacing(8, 10),
    },
    logo: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(3),
    },
    selectStoreText: {
      letterSpacing: 0.18,
      ...CommonFonts.darkFont24,
      marginBottom: theme.spacing(10),
    },
    storesLayout: {
      marginTop: 0,
    },
  })
);

export default useStyles;
