import { gql } from '@apollo/client'

// Get 1 order
export const GET_ORDER = gql`
  query getOrder($id: UUID!){
    order(id: $id){
      id
      order_no
      order_series
      creation{
        time
      }
      store {
        name
        localisation {
          digit_separator
          currency_position
          currency_decimal
          currency_symbol
          currency_code
          distance_unit
        }
        merchant {
          logo {
            thumbnail
          }
        }
      }
      device {
        device_name
      }
      payments{
        amount
        change_due
      }
      total_price{
        gross_amount
      }
      items{
        id
        name
        price{
          quantity
          total_gross_amount
        }
        addons {
          addon_id
          name
          price {
            quantity
            total_gross_amount
          }
        }
      }
    }
  }
`

// Get orders
export const GET_ORDERS = gql`
  query getOrders($filter: Filter, $start: Date, $end: Date, $order_no: String){
    orders(filter: $filter, start: $start, end: $end, order_no: $order_no){
      id
      order_no
      shopper{
        id
        first_name
        last_name
        mobile
      }
      user {
        id
        first_name
        last_name
      }
      creation{
        time
        user_id
      }
      payments{
        id
        status
        payment_type{
          id
          name
          type
        }
        amount
        creation {
            time
        }
      }
      completion_time
      state
      order_type{
        id
        type
        name
      }
      status{
        id
        name
        order
      }
      total_price{
        amount
        tax_amount
        gross_amount
        taxes{
          id
          name
          rate
          amount
        }
      }
      promotions{
        id
        type
        name
        amount
        tax_amount
      }
      items{
        product_id
        bar_code
        category{
          id
          name
        }
        is_manual
        name
        price{
          quantity
          amount
          taxes{
            id
            rate
            name
            amount
          }
        }
        addons{
          id
          name
          addon_id
          group{
            id
            name
          }
          price{
            quantity
            amount
            taxes{
              id
              rate
              name
              amount
            }
          }
        }
        promotions{
          id
          type
          name
          amount
        }
      }
    }
  }
`

export const CREATE_ORDER = gql`
  mutation ($input: [OrderInput]!) {
    addOrders(input: $input) {
      id
      order_no
      shopper{
        id
        first_name
        last_name
        mobile
      }
      user {
        id
        first_name
        last_name
      }
      creation{
        time
        user_id
      }
      payments{
        id
        status
        payment_type{
          id
          name
        }
        amount
        creation {
            time
        }
      }
      completion_time
      state
      order_type{
        id
        type
        name
      }
      status{
        id
        name
        order
      }
      total_price{
        amount
        tax_amount
        gross_amount
        taxes{
          id
          name
          rate
          amount
        }
      }
      promotions{
        id
        type
        name
        amount
        tax_amount
      }
      items{
        product_id
        bar_code
        category{
          id
          name
        }
        is_manual
        name
        price{
          quantity
          amount
          taxes{
            id
            rate
            name
            amount
          }
        }
        addons{
          id
          name
          addon_id
          group{
            id
            name
          }
          price{
            quantity
            amount
            taxes{
              id
              rate
              name
              amount
            }
          }
        }
        promotions{
          id
          type
          name
          amount
        }
      }
    }
  }
`
