import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import useStyles from './styles';

const SettingAccordion = ({
  id,
  title,
  children,
  options,
  onExpand,
}) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Accordion
      className={classes.root}
      onChange={(event, expanded) => {
        setIsExpanded(expanded);
        onExpand?.(expanded);
      }}
    >
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        classes={{
          content: classes.summaryContent,
        }}
      >
        <div className={classes.boxAccordionTitle}>
          <Typography className={classes.summaryText}>
            {title}
          </Typography>
          {isExpanded && options && (
            <div onClick={(e) => e.stopPropagation()}>{options}</div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

SettingAccordion.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  options: PropTypes.node,
};

export default SettingAccordion;
