import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { isEmpty } from 'lodash';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { useMutation, useQuery } from '@apollo/client';
import TextField from '@/components/textField';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { ADD_NEW_SEGMENT, GET_GLOBAL_DATASETS, UPDATE_SEGMENT } from '@/services/audienceService';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';

const ActionDialog = ({
                        dialogTitle,
                        segment = {},
                        actionType,
                        open,
                        handleClose,
                        setRefetchSegments
                      }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [mainCustomerInput, setMainCustomerInput] = useState(segment);
  const [count, setCount] = useState(actionType === 'update' ? 200 - segment?.description?.length : 200);
  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState({
    frequency: !isEmpty(segment?.value?.frequency),
    gender: !isEmpty(segment?.value?.gender)
  });
  const [openMenu, setOpenMenu] = useState({});
  const [ruleValue, setRuleValue] = useState({
    frequency: segment?.value?.frequency,
    gender: segment?.value?.gender
  });

  const handleClickListItem = (event, checkedName) => {
    if (checked[checkedName]) {
      setChecked({ ...checked, [checkedName]: false });
      setRuleValue({ ...ruleValue, [checkedName]: '' });
    } else {
      setAnchorEl(event.currentTarget);
      setOpenMenu({ ...openMenu, [checkedName]: true });
      setChecked({ ...checked, [checkedName]: true });
    }
  };

  const handleMenuItemClick = (event, index, checkedName, option) => {
    setRuleValue({ ...ruleValue, [checkedName]: option });
    setOpenMenu({ ...openMenu, [checkedName]: false });
    setChecked({ ...checked, [checkedName]: true });
    setAnchorEl(null);
  };

  const handleCloseMenu = (checkedName) => {
    setAnchorEl(null);
    setOpenMenu({ ...openMenu, [checkedName]: false });
    setChecked({ ...checked, [checkedName]: false });
    setRuleValue({ ...ruleValue, [checkedName]: '' });
  };

  const { data, loading } = useQuery(GET_GLOBAL_DATASETS, {
    fetchPolicy: 'network-only'
  });

  const [AddNewSegment, { loading: loadingSaveCustomer }] =
    useMutation(ADD_NEW_SEGMENT, {
      onCompleted: (response) => {
        enqueueSnackbar(mainCustomerInput?.name + ' ' + t('modules.customers.segment_created_successfully'));
        handleClose();
        setRefetchSegments();
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    });

  const [UpdateSegment, { loading: updateCustomer }] =
    useMutation(UPDATE_SEGMENT, {
      onCompleted: (response) => {
        enqueueSnackbar(mainCustomerInput?.name + ' ' + t('modules.customers.segment_updated_successfully'));
        handleClose();
        setRefetchSegments();
      },
      onError: (error) => {
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      }
    });

  // Save new segment created
  const addNewSegment = () => {
    const payload = {
      variables: {
        input: {
          name: mainCustomerInput?.name,
          description: mainCustomerInput?.description,
          value: {
            frequency: ruleValue.frequency,
            gender: ruleValue.gender
          }
        }
      }
    };
    return AddNewSegment(payload);
  };

  // Save updated segment
  const updateSegment = () => {
    const payload = {
      variables: {
        input: {
          id: segment.id,
          name: mainCustomerInput?.name,
          description: mainCustomerInput?.description,
          value: {
            frequency: ruleValue.frequency,
            gender: ruleValue.gender
          }
        }
      }
    };
    return UpdateSegment(payload);
  };
  const handleChangeFormData = (e) => {
    const { name, value } = e.target;

    if (name === 'description') {
      setCount(200 - value.length);
    }

    setMainCustomerInput((s) => ({
      ...s,
      [name]: value
    }));
  };
  const globalData = data?.globalDatasets.audience_rules;

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            alignContent="center"
          >
            <Grid className={classes.headerText} item xs={11}>
              <span style={{ fontSize: 18 }}>
                {dialogTitle}
              </span>
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" alignContent="center">
            <Grid xs={12} item row className={classes.gridItem}>
              <TextField
                id="standard-basic"
                label={t('modules.customers.name')}
                name="name"
                value={mainCustomerInput.name}
                fullWidth
                onChange={(e) => handleChangeFormData(e)}
                isEdit={true}
              />
            </Grid>
            <Grid xs={12} item row className={classes.gridItem}>
              <label className={classes.descriptionLabel} color='primary'>{t('modules.customers.description')}
                <label className={classes.descriptionCounter}>{count} {t('modules.customers.characters')} </label>
              </label>
              <TextField
                id="standard-basic"
                name="description"
                className={classes.descriptionInput}
                fullWidth
                outlined
                multiline
                rows={4}
                variant="outlined"
                value={mainCustomerInput.description}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => handleChangeFormData(e)}
              />
            </Grid>
            <Grid xs={12} item row>
              <Typography variant='subtitle2'> {t('modules.customers.rules')} </Typography>
              <Typography variant='body2'> {t('modules.customers.rulesDescription')} </Typography>
            </Grid>
            <Grid item xs={12}>
              <List component="nav" aria-label="Device settings">
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="lock-menu"
                  onClick={(event) => handleClickListItem(event, 'gender')}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      color="primary"
                      checked={checked.gender}
                      disableRipple
                      inputProps={{ 'aria-labelledby': ruleValue.gender }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t('modules.customers.gender')} />
                  <ListItemSecondaryAction>{ruleValue.gender}</ListItemSecondaryAction>
                </ListItem>
              </List>
              <Menu
                id="lock-menu2"
                anchorEl={anchorEl}
                open={openMenu.gender}
                onClose={() => handleCloseMenu('gender')}
                autoFocus={false}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                {globalData?.gender.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={option === ruleValue.gender}
                    onClick={(event) => handleMenuItemClick(event, index, 'gender', option)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>

            <Grid item xs={12}>
              <List component="nav" aria-label="Device settings">
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="lock-menu"
                  onClick={(event) => handleClickListItem(event, 'frequency')}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      color="primary"
                      checked={checked.frequency}
                      disableRipple
                      inputProps={{ 'aria-labelledby': ruleValue.frequency }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t('modules.customers.frequency')} />
                  <ListItemSecondaryAction>{ruleValue.frequency}</ListItemSecondaryAction>
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={openMenu.frequency}
                onClose={() => handleCloseMenu('frequency')}
                autoFocus={false}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                {globalData?.frequency.frequency.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={option === ruleValue.frequency}
                    onClick={(event) => handleMenuItemClick(event, index, 'frequency', option)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: 30 }}>
          <Button
            color="primary"
            onClick={handleClose}
            className={classes.cancelButton}
          >
            {t('customers.cancel')}
          </Button>
          {actionType === 'add' && (<Button
            disabled={
              !(
                mainCustomerInput.name &&
                mainCustomerInput.description
              )
            }
            color="primary"
            onClick={addNewSegment}
          >
            {t('customers.add')}
          </Button>)
          }
          {actionType === 'update' && (<Button
            disabled={ isEqual(mainCustomerInput, segment) &&
            isEqual(ruleValue, segment.value) || !(
              mainCustomerInput.name &&
              mainCustomerInput.description
            )}
            color="primary"
            onClick={updateSegment}
          >
            {t('modules.customers.update')}
          </Button>)
          }
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActionDialog;