export const roundToTwo = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export const roundToFour = (num) => {
  return Math.round((num + Number.EPSILON) * 10000) / 10000
}

export function isMoneyLessThanZero(moneyNumber) {
  return moneyNumber < 0.01
}
