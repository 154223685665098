import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Button,
  IconButton,
  Grid,
  Hidden,
  useMediaQuery,
  useTheme,
  Drawer,
  Badge,
} from '@material-ui/core';
import Notifications from './drawerContents/Notifications';
import Help from './drawerContents/Help';

import images from '@/assets/images';
import { Colors } from '@/theme';
import Avatar from '@/components/header/avatar';
import { selectCurrentUser } from '@/store/modules/auth';
import { getFirstLetter } from '@/utils/stringUtil';

import useStyles from './styles';
import { useSnackbar } from 'notistack';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { MUTATE_DECLINE_INVITATION } from '@/services/invitationService';
import { CURRENT_USER } from '@/services/userService';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContext } from '@/components/notification';
import AuthActions from '@/store/modules/auth/actions';
import MerchantActions from '@/store/modules/store/actions';
import { selectCurrentMerchant } from '@/store/modules/store';
import _ from 'lodash';

const currentUserQuery = gql`
  ${CURRENT_USER}
`;

const DRAWER_TYPES = {
  HELP: 'HELP',
  NOTIFICATION: 'NOTIFICATION',
};

const UserInfo = (props) => {
  const {
    handleOpenDialog,
    currentUser: {
      first_name,
      last_name,
      config: { background_colour },
      email,
    },
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const currentMerchants = useSelector(selectCurrentMerchant);
  const { createUser, setMerchants } = AuthActions;
  const { setCurrentMerchant } = MerchantActions;
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { count, setCount } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [drawerType, setDrawerType] = useState(DRAWER_TYPES.HELP);

  const [getCurrentUser, { loading, data }] = useLazyQuery(
    currentUserQuery,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (response) => {
        dispatch(setMerchants(response?.currentUser?.merchants));
        dispatch(
          setCurrentMerchant(
            response?.currentUser?.merchants?.find(
              (item) => item.id === currentMerchants?.id
            )
          )
        );
      },
    }
  );
  const { invitations: userInvitationData } = data?.currentUser || {};

  const getInvitationFromStorage = () => {
    const invitationIds = localStorage.getItem('invitationIds');
    return _.isEmpty(invitationIds) ? [] : invitationIds?.split(',');
  };

  useEffect(() => {
    if (menuVisible) {
      getCurrentUser();
    }
  }, [menuVisible]);

  const [declineInvitation] = useMutation(
    gql`
      ${MUTATE_DECLINE_INVITATION}
    `
  );

  const onDecline = (item) => {
    const { invite_id } = item;
    setIsLoading(true);
    const payload = {
      variables: {
        id: item.invite_id,
      },
    };
    declineInvitation(payload)
      .then((res) => {
        if (res) {
          const removeCurrentInvitation =
            getInvitationFromStorage()?.filter(
              (item) => item !== invite_id
            );
          setMenuVisible(false);
          getCurrentUser();
          setCount(removeCurrentInvitation?.length);
          localStorage.setItem(
            'invitationIds',
            _.isEmpty(removeCurrentInvitation)
              ? []
              : removeCurrentInvitation.toString()
          );
          enqueueSnackbar(
            t('invitation.invite.decline_invitation_message')
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const message = error?.message || error?.errors[0]?.message;
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  const onAccept = (value) => {
    const { invite_id, merchant_name } = value;
    setIsLoading(true);
    dispatch(
      createUser({
        email: email,
        password: null,
        invite_id: invite_id,
        redirect_url: '',
        onSuccess: (response) => {
          const removeCurrentInvitation =
            getInvitationFromStorage()?.filter(
              (item) => item !== invite_id
            );
          setIsLoading(false);
          setMenuVisible(false);
          setCount(removeCurrentInvitation?.length);
          localStorage.setItem(
            'invitationIds',
            _.isEmpty(removeCurrentInvitation)
              ? []
              : removeCurrentInvitation.toString()
          );
          enqueueSnackbar(
            t('invitation.invite.accept_invitation_message', {
              name: merchant_name,
            })
          );
          getCurrentUser();
        },
        onFailed: (error) => {
          setIsLoading(false);
          const message = error?.message || error?.errors[0]?.message;
          enqueueSnackbar(message, { variant: 'error' });
        },
      })
    );
  };

  const drawerContents = {
    [DRAWER_TYPES.NOTIFICATION]: (
      <Notifications
        loading={loading}
        isLoading={isLoading}
        userInvitationData={userInvitationData}
        onClose={() => setMenuVisible(false)}
        onDecline={onDecline}
        onAccept={onAccept}
      />
    ),
    [DRAWER_TYPES.HELP]: (
      <Help onClose={() => setMenuVisible(false)} />
    ),
  };
  const thumbnail = localStorage.getItem('user_thumbnail');

  return (
    <Grid
      container
      justify={isMobile ? 'flex-end' : 'space-between'}
      alignItems="center"
      spacing={2}
      className={classes.root}
    >
      <Hidden smDown>
        <Grid item>
          <Button
            className={classes.help}
            onClick={() => {
              setMenuVisible(true);
              setDrawerType(DRAWER_TYPES.HELP);
            }}
          >
            {t('header.help')}
          </Button>
        </Grid>
        <Grid item>
          <IconButton
            className={classes.notification}
            onClick={() => {
              setMenuVisible(true);
              setDrawerType(DRAWER_TYPES.NOTIFICATION);
            }}
          >
            <Badge
              className={classes.badge}
              badgeContent={count}
              color="primary"
            >
              <img
                src={images.notification}
                width={32}
                height={35}
                alt="notification"
                color={Colors.DIM_GREY}
              />
            </Badge>
          </IconButton>

          <Drawer
            anchor={'right'}
            open={menuVisible}
            onClose={() => setMenuVisible(false)}
          >
            {drawerContents[drawerType]}
          </Drawer>
        </Grid>
      </Hidden>
      <Grid item>
        <Avatar
          url={thumbnail}
          firstLetter={getFirstLetter(first_name)}
          secondLetter={getFirstLetter(last_name)}
          handleOpenDialog={handleOpenDialog}
          backgroundColor={background_colour}
          smallSize
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

export default connect(mapStateToProps)(UserInfo);
