import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(6, 0, 14, 6),
      borderRadius: 4,
      backgroundColor: Colors.WHITE,
    },
    titleText: {
      ...CommonFonts.grayBoldFont20,
      color: Colors.DIM_GREY,
      fontWeight: 500
    },
    accessesContainer: {
      flexWrap: 'wrap',
      paddingLeft: theme.spacing(2),
    },
    accessContainer: {
      marginTop: '2vw',
      marginRight: '2vw',
      cursor: 'pointer',
      minWidth: 180,
      minHeight: 110,
      border: '1px solid #dddee1',
      padding: 10,
      borderRadius: 5,
      '&:hover': {
        backgroundColor: '#dddee1',
      }
    },
    disabled: {
      opacity: 0.4,
      cursor: 'auto',
    },
    labelText: {
      textAlign: 'start',
      fontWeight: 500,
      maxWidth: 113,
      marginTop: theme.spacing(10)
    }
  })
)

export default useStyles
