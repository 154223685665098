import I18n from '@/i18n'

export const COMPARISON = {
  STORE: I18n.t('workboard.product_intelligence.store'),
  PRODUCT: I18n.t('workboard.product_intelligence.product')
}

export const getIntelligenceData = (
  comparison,
  insightsData,
  stockData,
  comparedInsightsData,
  comparedStockData,
  selectedStore,
  selectedRegion,
  product,
  comparedStore,
  comparedProduct
) => {
  return [
    {
      title: I18n.t('workboard.product_intelligence.gross_sales'),
      isCurrency: true,
      store: {
        name: comparison === COMPARISON.STORE ? (selectedRegion?.name || selectedStore.name) : product.name,
        amount: (insightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.net || 0) +
          (insightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.tax || 0) +
          (insightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.promotion || 0) +
          (insightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.promotion_tax || 0)
      },
      compareStore: (comparison === COMPARISON.STORE && comparedStore) || (comparison === COMPARISON.PRODUCT && comparedProduct)
        ?
        {
          name: comparison === COMPARISON.STORE ? comparedStore?.name : comparedProduct.name,
          amount: (comparedInsightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.net || 0) +
            (comparedInsightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.tax || 0) +
            (comparedInsightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.promotion || 0) +
            (comparedInsightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.promotion_tax || 0)
        }
        :
        null
    },
    {
      title: I18n.t('workboard.product_intelligence.net_sales'),
      isCurrency: true,
      store: {
        name: comparison === COMPARISON.STORE ? (selectedRegion?.name || selectedStore.name) : product.name,
        amount: (insightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.net || 0) +
          (insightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.promotion || 0)
      },
      compareStore: (comparison === COMPARISON.STORE && comparedStore) || (comparison === COMPARISON.PRODUCT && comparedProduct)
        ?
        {
          name: comparison === COMPARISON.STORE ? comparedStore?.name : comparedProduct.name,
          amount: (comparedInsightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.net || 0) +
            (comparedInsightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.promotion || 0)
        }
        :
        null
    },
    {
      title: I18n.t('workboard.product_intelligence.tax_sales'),
      isCurrency: true,
      store: {
        name: comparison === COMPARISON.STORE ? (selectedRegion?.name || selectedStore.name) : product.name,
        amount: (insightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.tax || 0) +
          (insightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.promotion_tax || 0)
      },
      compareStore: (comparison === COMPARISON.STORE && comparedStore) || (comparison === COMPARISON.PRODUCT && comparedProduct)
        ?
        {
          name: comparison === COMPARISON.STORE ? comparedStore?.name : comparedProduct.name,
          amount: (comparedInsightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.tax || 0) +
            (comparedInsightsData?.insights?.best_sellers?.products?.[0]?.total_sale?.promotion_tax || 0)
        }
        :
        null
    },
    {
      title: I18n.t('workboard.product_intelligence.no_items'),
      isCurrency: false,
      store: {
        name: comparison === COMPARISON.STORE ? (selectedRegion?.name || selectedStore.name) : product.name,
        amount: insightsData?.insights?.best_sellers?.products?.[0]?.item_sold || 0
      },
      compareStore: (comparison === COMPARISON.STORE && comparedStore) || (comparison === COMPARISON.PRODUCT && comparedProduct)
        ?
        {
          name: comparison === COMPARISON.STORE ? comparedStore?.name : comparedProduct.name,
          amount: comparedInsightsData?.insights?.best_sellers?.products?.[0]?.item_sold || 0
        }
        :
        null
    },
    {
      title: I18n.t('workboard.product_intelligence.not'),
      isCurrency: false,
      store: {
        name: comparison === COMPARISON.STORE ? (selectedRegion?.name || selectedStore.name) : product.name,
        amount: insightsData?.insights?.best_sellers?.products?.[0]?.no_of_transactions || 0
      },
      compareStore: (comparison === COMPARISON.STORE && comparedStore) || (comparison === COMPARISON.PRODUCT && comparedProduct)
        ?
        {
          name: comparison === COMPARISON.STORE ? comparedStore?.name : comparedProduct.name,
          amount: comparedInsightsData?.insights?.best_sellers?.products?.[0]?.no_of_transactions || 0
        }
        :
        null
    },
    {
      title: I18n.t('workboard.product_intelligence.atv'),
      isCurrency: true,
      store: {
        name: comparison === COMPARISON.STORE ? (selectedRegion?.name || selectedStore.name) : product.name,
        amount: insightsData?.insights?.best_sellers?.products?.[0]?.average_transaction_value || 0
      },
      compareStore: (comparison === COMPARISON.STORE && comparedStore) || (comparison === COMPARISON.PRODUCT && comparedProduct)
        ?
        {
          name: comparison === COMPARISON.STORE ? comparedStore?.name : comparedProduct.name,
          amount: comparedInsightsData?.insights?.best_sellers?.products?.[0]?.average_transaction_value || 0
        }
        :
        null
    },
    {
      title: I18n.t('workboard.product_intelligence.current_stock'),
      isCurrency: false,
      store: {
        name: comparison === COMPARISON.STORE ? (selectedRegion?.name || selectedStore.name) : product.name,
        amount: stockData?.stock?.[0]?.current_stock || 0
      },
      compareStore: (comparison === COMPARISON.STORE && comparedStore) || (comparison === COMPARISON.PRODUCT && comparedProduct)
        ?
        {
          name: comparison === COMPARISON.STORE ? comparedStore?.name : comparedProduct.name,
          amount: comparedStockData?.stock?.[0]?.current_stock || 0
        }
        :
        null
    },
    {
      title: I18n.t('workboard.product_intelligence.stockout'),
      isCurrency: false,
      store: {
        name: comparison === COMPARISON.STORE ? (selectedRegion?.name || selectedStore.name) : product.name,
        amount: stockData?.stock?.[0]?.stockout || 0
      },
      compareStore: (comparison === COMPARISON.STORE && comparedStore) || (comparison === COMPARISON.PRODUCT && comparedProduct)
        ?
        {
          name: comparison === COMPARISON.STORE ? comparedStore?.name : comparedProduct.name,
          amount: comparedStockData?.stock?.[0]?.stockout || 0
        }
        :
        null
    },
  ]
}