import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.grayFont16,
    },
    value: {
      ...CommonFonts.grayFont18,
    },
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    box: {
      marginBottom: theme.spacing(9),
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(25),
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
    addAction: {
      textTransform: 'capitalize',
      cursor: 'pointer',
      color: '#1174F2',
    },
    editIcon: {
      padding: theme.spacing(1),
    },
    dateInput: {
      width: '30%',
      marginLeft: theme.spacing(6),
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    monthInput: {
      width: '70%',
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    subTitle: {
      ...CommonFonts.grayFont16,
      marginBottom: theme.spacing(12),
      textAlign: 'center',
    },
  })
);

export default useStyles;
