import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Box, FormHelperText, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { Edit, Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { CommonFonts } from '@/theme';
import classNames from 'classnames';
import CssTextField from './styles';


const useStyles = makeStyles({
  timePicker: {
    '& input[type="time" i]::-webkit-calendar-picker-indicator': { display: 'none' }
  },
  editIcon: {
    color: 'gray',
    '&:hover': {
      color: 'black'
    }
  },
  hoverIcon: {
    color: 'black'
  },
  onEdit: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    }
  },
  emptyLabel: {
    ...CommonFonts.blueFont18,
    cursor: 'pointer',
    borderBottom: '1px solid rgba(0, 0, 0, 0)',
    '&:hover': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
    }
  }
});

const Input = (props) => {
  const {
    className,
    isPassword = false,
    startAdornment = null,
    endAdornment = null,
    autofocus,
    value,
    onBlur,
    onFocus,
    emptyLabel = 'Add',
    helperText,
    isEdit = false,
    label,
    error,
    ...restProps
  } = props;

  const classes = useStyles(props);
  let textInput = useRef(null);

  const [showPassword, setShowPassword] = useState(!isPassword);
  const [showEdit, setShowEdit] = useState(value);
  const [hover, setHover] = useState(false);
  const [hasEntered, setHasEntered] = useState(false);

  const renderEndAdornment = () => {
    if (isPassword) {
      return (
        <IconButton
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      );
    } else if (isEdit) {
      return (
        <IconButton style={{ marginBottom: 5, fontSize: 20 }}>
          {showEdit &&
          <Edit className={hover ? classes.hoverIcon : classes.editIcon}
                fontSize='inherit'
                onClick={() => textInput.current.focus()}
          />}
        </IconButton>
      );
    }
    return endAdornment;
  };

  return (
    <Box>
      {isEdit && <Typography
        className={classNames(classes.titleText, {
          [classes.errorTitleText]: error
        })}
      >
        {label}
      </Typography>}
      {!hasEntered && !value && isEdit && (
        <Box>
          <Box onClick={() => setHasEntered(true)}>
            <Typography className={classes.emptyLabel}>
              {emptyLabel}
            </Typography>
          </Box>
          {error && (
            <FormHelperText error>{helperText}</FormHelperText>
          )}
        </Box>
      )}
      {(hasEntered || isEdit === false || value) &&
      <CssTextField
        fullWidth
        type={showPassword ? 'text' : 'password'}
        {...restProps}
        autoFocus={(isEdit && !value )|| autofocus}
        className={classnames([isEdit && classes.onEdit, classes.timePicker, className])}
        value={value}
        inputRef={textInput}
        error={error}
        helperText={error && helperText}
        onBlur={(e) => {
          if (value) {
            setShowEdit(true);
          } else {
            setHasEntered(false)
          }
          onBlur?.(e);
        }}
        onFocus={() => {
          setShowEdit(false);
        }}
        onMouseOver={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }
        }
        InputProps={{
          startAdornment: startAdornment
            ? <InputAdornment position="start">{startAdornment}</InputAdornment>
            : null,
          endAdornment: (endAdornment || isPassword || isEdit) ? renderEndAdornment() : null
        }}
      />}
    </Box>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  isPassword: PropTypes.any,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any
};

export default Input;
