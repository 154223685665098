import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: Colors.GRAY_LIGHT
    },
    container: {
      width: 400,
      minHeight: 465,
      maxWidth: '70%',
      backgroundColor: Colors.WHITE,
      padding: theme.spacing(10),
      border: '1px solid #939DA8',
      borderRadius: 10,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4)
      },
    },
    changePasswordSuccessText: {
      ...CommonFonts.grayFont18,
      marginTop: theme.spacing(20),
      textAlign: 'center'
    },
    passwordContainer: {
      marginTop: theme.spacing(12),
    },
    passwordField: {
      '&>div': {
        marginTop: '7px',
      },
    },
    resetButtonWrapper: {
      marginTop: theme.spacing(15),
      padding: theme.spacing(0, 5)
    },
    changePasswordText: {
      ...CommonFonts.darkFont20,
      fontWeight: 500,
      marginTop: theme.spacing(8),
      textAlign: 'center'
    },
    loginButtonWrapper: {
      marginTop: theme.spacing(17),
      padding: theme.spacing(0, 8)
    },
    button: {
      height: 50,
      fontWeight: 300,
      fontSize: 14
    },
    errorContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(5)
    }
  })
);

export default useStyles;
