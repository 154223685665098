import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    contentRoot: {
      backgroundColor: '#20272F',
      opacity: 0.8,
      color: Colors.WHITE,
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
    },
    actionButton: {
      color: Colors.WHITE,
    },
  })
);

export default useStyles;
