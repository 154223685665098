import React from 'react';
import classNames from 'classnames'

import { useSelector } from 'react-redux';
import { selectLocalisation } from '@/store/modules/store/selectors';
import useStyles from './styles';

import Input from 'react-currency-masked-input'
import { FormHelperText } from '@material-ui/core'

const CurrencyInput = ({ name, onChange, className, error, helperText, ...restProps }) => {
  const classes = useStyles();
  const localisation = useSelector(selectLocalisation);

  return (
    <>
      <Input
        name={name}
        className={classNames(classes.currencyInput, className)}
        type=''
        onChange={(e, value) => onChange(value)}
        separator={localisation.digit_separator === ',' ? '.' : ','}
        {...restProps}
      />
      {error &&
        <FormHelperText error={true}>
          {helperText}
        </FormHelperText>
      }
    </>
  );
};

export default CurrencyInput;
