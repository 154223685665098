import React, { useEffect, useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { PROMOTION_LIST } from '@/services/storeService';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import useDebounce from '@/hooks/useDebounce';
import TextField from '@/components/textField';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

const promotionListQuery = gql`
  ${PROMOTION_LIST}
`;

const PosPrintPromotion = ({
  name,
  label,
  value,
  onChange,
  description,
  actionText,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const lowerCaseTitle = label.toLowerCase();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [page, setPage] = useState(1);
  const [promotionLoaded, setPromotionLoaded] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const debouncedSearchTerm = useDebounce(searchText, 500);

  useEffect(() => {
    if (openEditModal) {
      setPromotions([]);
      getPromotion();
    }
  }, [openEditModal]);

  useEffect(() => {
    if (page !== 1 && openEditModal) {
      getPromotion();
    }
  }, [page]);

  useEffect(() => {
    if (openEditModal) {
      setPage(1);
      setPromotions([]);
      getPromotion();
    }
  }, [debouncedSearchTerm]);

  const [getPromotion, { loading }] = useLazyQuery(
    promotionListQuery,
    {
      fetchPolicy: 'no-cache',
      variables: {
        name: `*${debouncedSearchTerm}*`,
        filter: { count: true, limit: 15, page: page },
      },
      onCompleted: (data) => {
        if (data?.promotions?.length < 15) {
          setPromotionLoaded(true);
        } else {
          setPromotionLoaded(false);
        }

        setPromotions([...promotions, ...data?.promotions]);
      },
    }
  );

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setPage(1);
    setSearchText('');
    setPromotions([]);
  };

  const handleLoadMore = () => {
    if (_.isEmpty(promotions)) return;
    setPage(page + 1);
  };

  const onSave = async () => {
    setIsLoading(true);
    try {
      await onChange(selectedValue);
      handleCloseEditModal();
      enqueueSnackbar(
        `${capitalizeEachLetter(label)} ${
          value?.length > 0
            ? t('settings.updated')
            : t('settings.added')
        }`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box className={classes.boxWithDescription}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography className={classes.titleText}>
            {label}
          </Typography>
          <Typography
            onClick={handleOpenEditModal}
            className={classes.addAction}
          >
            {actionText ? actionText : t('settings.update')}
          </Typography>
        </Box>
        <Typography
          style={{ width: '90%' }}
          className={classes.descriptionText}
        >
          {description}
        </Typography>
      </Box>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        onClose={isLoading ? () => {} : handleCloseEditModal}
        aria-labelledby={`{edit-${lowerCaseTitle}-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{edit-${lowerCaseTitle}-dialog-title}`}
          onClose={handleCloseEditModal}
        >
          {t('settings.touchpoint_pos.select_a_promotion')}
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder={t(
              'settings.touchpoint_pos.print_promotion_placeholder'
            )}
            id={name}
            name={name}
            fullWidth
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            autoFocus
            required
            InputProps={{
              className: classes.input,
            }}
            startAdornment={
              <SearchIcon
                style={{ color: Colors.GRAY_BORDER1 }}
                width={18}
                height={18}
              />
            }
          />
          {loading && page === 1 ? (
            <Box
              marginTop={10}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={30} />
            </Box>
          ) : !_.isEmpty(promotions) ? (
            <InfiniteScroll
              style={{ marginTop: 10 }}
              scrollThreshold={'100px'}
              height={600}
              dataLength={promotions?.length}
              next={handleLoadMore}
              hasMore={!promotionLoaded}
              loader={
                loading &&
                page !== 1 && (
                  <Box paddingX={2}>
                    <Skeleton height={80} />
                    <Skeleton height={80} />
                    <Skeleton height={80} />
                  </Box>
                )
              }
            >
              {promotions
                ?.filter((item) => !value?.includes(item.id))
                .map((item) => (
                  <div
                    key={item.id}
                    className={classes.boxPromotionItem}
                  >
                    <Typography>{item.promo_name}</Typography>
                    <CustomRadio
                      value={item.id}
                      checked={item.id === selectedValue}
                      onChange={(event) =>
                        setSelectedValue(event.target.value)
                      }
                    />
                  </div>
                ))}
            </InfiniteScroll>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={selectedValue === ''}
            onClick={onSave}
            type="submit"
            color="primary"
          >
            {t('settings.save')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

const CustomRadio = withStyles({
  root: {
    color: '#55CC66',
    '&$checked': {
      color: '#55CC66',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default PosPrintPromotion;
