import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      ...CommonFonts.grayFont16,
    },
    value: {
      ...CommonFonts.grayFont18,
    },
    input: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    descriptionText: {
      ...CommonFonts.grayFont14,
      marginBottom: theme.spacing(2),
    },
    addNew: {
      ...CommonFonts.darkFont14,
      color: Colors.WHITE,
      padding: `${theme.spacing(4)}px ${theme.spacing(10)}px`,
      maxWidth: theme.spacing(40),
      maxHeight: theme.spacing(16),
      textAlign: 'center',
    },
    selectOptionInput: {
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
  })
);

export default useStyles;
