import React, { useEffect, useState } from 'react';

import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from '@material-ui/core';
import RootRef from '@material-ui/core/RootRef';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { CATEGORY_LIST } from '@/services/storeService';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';

const categoryQuery = gql`
  ${CATEGORY_LIST}
`;

const PosArrangeCategory = ({
  description,
  label,
  value,
  onChange,
  isSortByName,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lowerCaseTitle = label.toLowerCase();
  const [categoryList, setCategoryList] = useState([]);

  const [getCategoryData, { loading, data }] = useLazyQuery(
    categoryQuery,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const categories = data?.categories;
        const list = categories?.map((category) => ({
          id: category.id,
          position:
            value?.findIndex((item) => item.id === category.id) > -1
              ? value?.find((item) => item.id === category.id)
                  ?.position
              : null,
        }));
        setCategoryList(list);
      },
    }
  );
  const categories = data?.categories;

  useEffect(() => {
    if (openEditModal) {
      getCategoryData();
    }
  }, [openEditModal]);

  const getCategoryName = (id) =>
    categories?.find((item) => item.id === id)?.name;

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => {
    setCategoryList([]);
    setOpenEditModal(false);
  };

  const onShowHide = (val) => {
    const { id, position } = val;
    const list = categoryList?.map((item, index) => ({
      ...item,
      position:
        (item.id === id && position) ||
        (item.id !== id && item.position === null)
          ? null
          : item.id === id
          ? categoryList.length + 1
          : index,
    }));
    const result = list
      ?.sort(
        (first, second) =>
          (first.position === null) - (second.position === null) ||
          +(first.position > second.position) ||
          -(first.position < second.position)
      )
      ?.map((item, index) => ({
        ...item,
        position: item.position === null ? null : index + 1,
      }));

    setCategoryList(result);
  };

  const onDragEnd = (result) => {
    if (result.destination) {
      const currentIndex = result.source.index;
      const targetIndex = result.destination.index;

      const list = categoryList?.map((item, index) =>
        currentIndex + 1 === targetIndex
          ? {
              ...item,
              position:
                index === currentIndex
                  ? categoryList[targetIndex].position
                  : index === targetIndex
                  ? categoryList[currentIndex].position
                  : item.position,
            }
          : currentIndex < targetIndex
          ? {
              ...item,
              position:
                index === currentIndex
                  ? categoryList[targetIndex].position
                  : index <= targetIndex && index > currentIndex
                  ? item.position - 1
                  : item.position,
            }
          : {
              ...item,
              position:
                index === currentIndex
                  ? categoryList[targetIndex].position
                  : index > currentIndex || index < targetIndex
                  ? item.position
                  : index < currentIndex && item.position !== null
                  ? item.position + 1
                  : null,
            }
      );
      setCategoryList(list);
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      backgroundColor: '#F3F5F7',
      display: 'flex',
    }),
  });

  const onSave = async () => {
    setIsLoading(true);
    try {
      await onChange(
        categoryList.filter((item) => item.position !== null)
      );
      handleCloseEditModal();
      enqueueSnackbar(
        `${capitalizeEachLetter(label)} ${
          value?.length > 0
            ? t('settings.updated')
            : t('settings.added')
        }`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box className={classes.boxWithDescription}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography className={classes.titleText}>
            {label}
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              onClick={handleOpenEditModal}
              className={classes.addAction}
            >
              {t('settings.update')}
            </Typography>
          </div>
        </Box>
        <Typography
          style={{ width: '90%' }}
          className={classes.descriptionText}
        >
          {description}
        </Typography>
      </Box>
      <Dialog
        className={classes.boxDialog}
        maxWidth={false}
        fullWidth
        fullScreen={fullScreen}
        onClose={isLoading ? () => {} : handleCloseEditModal}
        aria-labelledby={`{edit-${lowerCaseTitle}-dialog-title}`}
        open={openEditModal}
        disableBackdropClick
      >
        <DialogTitle
          id={`{edit-${lowerCaseTitle}-dialog-title}`}
          onClose={handleCloseEditModal}
        >
          {t('settings.touchpoint_pos.arrange_category_visibility')}
        </DialogTitle>
        <DialogContent>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <RootRef rootRef={provided.innerRef}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            width: isSortByName ? '75%' : '50%',
                          }}
                        >
                          {t('settings.touchpoint_pos.category_name')}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: '25%' }}
                        >
                          {t('settings.touchpoint_pos.show_hide')}
                        </StyledTableCell>
                        {!isSortByName && (
                          <StyledTableCell
                            align="center"
                            style={{ width: '25%' }}
                          >
                            {t('settings.touchpoint_pos.position')}
                          </StyledTableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    {!_.isEmpty(categoryList) ? (
                      <TableBody>
                        {categoryList
                          ?.sort(
                            (first, second) =>
                              (first.position || 1000) -
                              (second.position || 1000) // 1000 max category
                          )
                          ?.map((row, index) => (
                            <Draggable
                              key={row.id}
                              draggableId={row.id}
                              index={index}
                              isDragDisabled={
                                !row.position || isSortByName
                              }
                            >
                              {(provided, snapshot) => (
                                <TableRow
                                  ref={provided.innerRef}
                                  key={row.id}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <StyledTableCell
                                    style={{
                                      width: isSortByName
                                        ? '75%'
                                        : '50%',
                                    }}
                                  >
                                    <Typography
                                      className={classes.tableText}
                                    >
                                      {getCategoryName(row.id)}
                                    </Typography>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    style={{ width: '25%' }}
                                  >
                                    <Typography
                                      className={classes.tableText}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          onShowHide(row)
                                        }
                                      >
                                        {row.position ? (
                                          <VisibilityIcon />
                                        ) : (
                                          <VisibilityOffIcon
                                            style={{
                                              color: '#939DA8',
                                            }}
                                          />
                                        )}
                                      </IconButton>
                                    </Typography>
                                  </StyledTableCell>
                                  {!isSortByName && (
                                    <StyledTableCell
                                      align="center"
                                      style={{ width: '25%' }}
                                    >
                                      <Typography
                                        style={{ fontWeight: '600' }}
                                        className={classes.tableText}
                                      >
                                        {row.position || ''}
                                      </Typography>
                                    </StyledTableCell>
                                  )}
                                </TableRow>
                              )}
                            </Draggable>
                          ))}
                      </TableBody>
                    ) : loading ? (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={3}
                            style={{ border: 'none' }}
                          >
                            <Box
                              display="flex"
                              justifyContent="center"
                            >
                              <CircularProgress size={30} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : null}
                    {provided.placeholder}
                  </Table>
                </RootRef>
              )}
            </Droppable>
          </DragDropContext>
          {!categoryList && <PreLoader size={25} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>
            {t('settings.cancel')}
          </Button>
          <Button onClick={onSave} type="submit" color="primary">
            {t('settings.save')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F3F5F7',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default PosArrangeCategory;
