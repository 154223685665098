import React from 'react';
import PrimaryButton from '@/components/button';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const AddNewWorkflows = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <PrimaryButton
        className={classes.addNew}
        variant="contained"
        color="primary"
      >
        {t('orderTypeWorkflows.add_new')}
      </PrimaryButton>
    </>
  );
};

export default AddNewWorkflows;
