import React from 'react';
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';

export default function SelectMerchantTitle({ changeHeader }) {
  const { t } = useTranslation()

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      alignContent="center"
      style={{ height: '100%' }}
    >
      <Grid item xs={1}>
        <ArrowBackIcon
          onClick={changeHeader}
          style={{ cursor: 'pointer' }}
        />
      </Grid>
      <Grid item xs={11} style={{ textAlign: 'center' }}>
        <span style={{ marginLeft: '-30px' }}>
          {t('header.select_business')}
        </span>
      </Grid>
    </Grid>
  );
}

SelectMerchantTitle.propTypes = {
  changeHeader: PropTypes.func,
};
