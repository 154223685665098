import React from 'react';

import { Table, TableBody } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import TableHeader from './tableHeader';
import TableRow from './tableRow';

const TableComponent = (props) => {
  let {
    products,
    isLoadingProducts,
    changedData,
    onChangeHeaderCheckbox,
  } = props;

  return (
    <Table>
      {isLoadingProducts ? (
        <div>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </div>
      ) : (
        <>
          <TableHeader onChange={onChangeHeaderCheckbox} />
          <TableBody>
            {products.map((item, index) => (
              <TableRow item={item} changedData={changedData} />
            ))}
          </TableBody>
        </>
      )}
    </Table>
  );
};

export default TableComponent;
