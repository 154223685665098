import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import PreLoader from '@/components/preLoader'
import SearchStore from './SearchStore'
import FillAddress from './FillAddress'
import EnterStoreName from './EnterStoreName'

import { gql, useMutation } from '@apollo/client'
import { ADD_STORE } from '@/services/storeService'

import { selectCurrentMerchant } from '@/store/modules/store/selectors'
import MerchantActions from '@/store/modules/store/actions';
import AuthActions from '@/store/modules/auth/actions';

import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';

import useStyles from './styles'

const STEPS = {
  SEARCH_STORE: 'SearchStore',
  FILL_ADDRESS: 'FillAddress',
  ENTER_STORE_NAME: 'EnterStoreName'
}

const AddNewStore = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar();
  const country = localStorage.getItem('country')

  const {
    open,
    handleClose
  } = props

  const dispatch = useDispatch()
  const currentMerchant = useSelector(selectCurrentMerchant)

  const [currentStep, setCurrentStep] = useState(STEPS.SEARCH_STORE)

  const [address, setAddress] = useState({
    door_number: '',
    building_name: '',
    line1: '',
    line2: '',
    city_town: '',
    postcode: '',
    county: '',
    country,
  });

  const [storeName, setStoreName] = useState('')

  const [addStoreMutation, { loading: isAddingStore }] = useMutation(gql`${ADD_STORE}`, {
    onCompleted: (data) => {
      dispatch(MerchantActions.addStore(data.addStore));
      dispatch(AuthActions.addNewStore(currentMerchant, data.addStore));
      enqueueSnackbar(t('stores.store_added'))
      handleClose()
    },
    onError: (error) => {
      const message = error?.message || error?.errors[0]?.message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  })

  const onAddStore = () => {
    addStoreMutation({
      variables: {
        input: {
          name: storeName,
          type: 'store',
          address: address,
        }
      }
    })
  }

  const content = {
    [STEPS.SEARCH_STORE]: (
      <SearchStore
        onSelectAddress={(value) => {
          setAddress(value)
          setCurrentStep(STEPS.FILL_ADDRESS)
        }}
        onClickManual={() => setCurrentStep(STEPS.FILL_ADDRESS)}
      />
    ),
    [STEPS.FILL_ADDRESS]: (
      <FillAddress
        address={address}
        onAddressFilled={(value) => {
          setAddress(value)
          setCurrentStep(STEPS.ENTER_STORE_NAME)
        }}
        onBack={() => setCurrentStep(STEPS.SEARCH_STORE)}
      />
    ),
    [STEPS.ENTER_STORE_NAME]: (
      <EnterStoreName
        address={address}
        storeName={storeName}
        onChangeStoreName={setStoreName}
        onAddStore={onAddStore}
      />
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Typography className={classes.titleText}>
          {t('stores.add_new_store')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.rootDialog}>
          {content[currentStep]}
        </div>
      </DialogContent>

      {isAddingStore && <PreLoader size={35} />}
    </Dialog>
  )
}

export default AddNewStore