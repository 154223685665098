import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import SingleCheckboxInput from '@/components/singleCheckbox'
import InventorySearchInput from '@/components/inventorySearchInput'
import NumberInput from '@/components/numberInput'
import Addon from './Addon'

import { selectLocalisation } from '@/store/modules/store/selectors'

import { generateNewAddon } from './helper'
import useStyles from '../styles'

const RulesOptions = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    groupData,
    addons,
    updateGroupData,
    updateAddons,
    addAddons,
    removeAddons
  } = props

  const localisation = useSelector(selectLocalisation)

  const rules = [
    {
      key: 'mandatory',
      label: t('addons.make_it_mandatory')
    },
    {
      key: 'default_all',
      label: t('addons.select_all_default')
    },
    {
      key: 'multi_selection',
      label: t('addons.allow_multi_select')
    },
    {
      key: 'limit_free_options',
      label: t('addons.limit_free_options')
    },
  ]

  const onSearchProduct = (product) => {
    const newAddon = generateNewAddon(product)
    addAddons(newAddon)
  }

  return (
    <Box>
      <Box className={classes.boldLabelContainer}>
        <Typography className={classes.boldLabel}>
          {t('addons.rules')}
        </Typography>
      </Box>
      <Box className={classes.rulesContainer}>
        {rules.map((rule, id) => (
          <Box key={`rule-${id}`} className={classes.ruleWrapper}>
            <SingleCheckboxInput
              style={{ paddingBottom: 0 }}
              isShowToast={false}
              value={groupData[rule.key]}
              label={rule.label}
              onChange={(value) => {
                updateGroupData(rule.key, value)
              }}
            />
          </Box>
        ))}
      </Box>

      <Box className={classes.freeOptionsContainer}>
        {groupData.limit_free_options &&
          <Box style={{ width: 200 }}>
            <NumberInput
              title={t('addons.no_of_free_options')}
              value={groupData.allow_free}
              onChange={(value) => updateGroupData('allow_free', value)}
            />
          </Box>
        }
      </Box>

      <Box style={{ marginTop: 20 }}>
        <Typography className={classes.findProductText}>
          {t('addons.find_product')}
        </Typography>
      </Box>
      <Box className={classes.productSearchWrapper}>
        <InventorySearchInput onSelectInventory={onSearchProduct} />
      </Box>

      <Box className={classes.addonsContainer}>
        {addons.map((addon, index) => (
          <Addon
            key={`addon-${index}`}
            addon={addon}
            addonIndex={index}
            updateAddons={updateAddons}
            removeAddons={removeAddons}
            localisation={localisation}
          />
        ))}
      </Box>
    </Box>
  )
}

export default RulesOptions