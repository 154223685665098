import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack';

import { Box } from '@material-ui/core'
import PreLoader from '@/components/preLoader'
import ErrorPage from '@/pages/errorPage'

import { gql, useMutation } from '@apollo/client'
import { VERIFY_USER } from '@/services/authService'

import useStyles from './styles'

const VerifyEmail = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const { code } = useParams()
  const history = useHistory()

  const [error, setError] = useState(false)

  const [verifyUserMutation, { loading }] = useMutation(gql`${VERIFY_USER}`, {
    onCompleted: () => {
      enqueueSnackbar(t('signup.email_verify_success'))
      history.push('/login')
    },
    onError: () => {
      setError(true)
    }
  })

  useEffect(() => {
    verifyUserMutation({
      variables: {
        input: {
          type: 'email',
          code
        }
      }
    })
  }, [])

  return (
    <Box className={classes.root}>
      {error && <ErrorPage />}
      {loading && <PreLoader size={50} />}
    </Box>
  )
}

export default VerifyEmail