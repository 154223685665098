import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '@/components/preLoader';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import { Colors, CommonFonts } from '@/theme';
import SettingSwitch from '@/components/settingSwitch';
import { useSnackbar } from 'notistack';
import { capitalizeEachLetter } from '@/utils/stringUtil';

const PosTypesPicker = ({
  name,
  title,
  label,
  value,
  options,
  onChange,
  description,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lowerCaseTitle = label.toLowerCase();

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(
      options?.map((item) => ({
        id: item.id,
        name: item.name,
        isChecked: value?.includes(item.id),
      }))
    )
  }, [options])

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  const handleChange = async () => {
    setIsLoading(true);
    const listSelected = list
      ?.filter((item) => item.isChecked)
      ?.map((item) => item.id);
    try {
      await onChange(listSelected);
      handleCloseEditModal();
      enqueueSnackbar(
        `${capitalizeEachLetter(label)} ${value?.length > 0
          ? t('settings.updated')
          : t('settings.added')
        }`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box className={classes.boxWithDescription}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography className={classes.titleText}>
            {label}
          </Typography>
          <Typography
            onClick={handleOpenEditModal}
            className={classes.addAction}
          >
            {t('settings.update')}
          </Typography>
        </Box>
        <Typography
          style={{ width: '90%' }}
          className={classes.descriptionText}
        >
          {description}
        </Typography>
      </Box>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        onClose={isLoading ? () => { } : handleCloseEditModal}
        aria-labelledby={`{edit-${lowerCaseTitle}-dialog-title}`}
        open={openEditModal}
      >
        <DialogTitle
          id={`{edit-${lowerCaseTitle}-dialog-title}`}
          onClose={handleCloseEditModal}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          {(list || []).map((type, index) => (
            <SettingSwitch
              disableNotification
              key={type.id}
              label={type.name}
              value={type.isChecked}
              onChange={(value) => {
                const paymentTypeList = [...list];
                paymentTypeList[index].isChecked = value;
                setList(paymentTypeList);
              }}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>
            {t('settings.cancel')}
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleChange}
            type="submit"
            color="primary"
          >
            {t('settings.save')}
          </Button>
        </DialogActions>
        {isLoading && <PreLoader size={25} />}
      </Dialog>
    </>
  );
};

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: `0 auto`,
    padding: theme.spacing(2),
    ...CommonFonts.grayBoldFont20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    backgroundColor: Colors.GRAY_LIGHT,
    '&:hover': {
      backgroundColor: Colors.GRAY_BORDER1_25,
    },
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(13),
    paddingRight: theme.spacing(13),
    paddingTop: theme.spacing(5),
    paddingBottom: 0,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

export default PosTypesPicker;
