import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, CommonFonts } from '@/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    boxOutter: {
      backgroundColor: Colors.GRAY_LIGHT,
      maxWidth: '100%',
      minHeight: '100vh',
    },
    boxInner: {
      backgroundColor: Colors.WHITE,
      maxWidth: '582px',
      borderRadius: theme.spacing(2),
      borderWidth: '1px',
      borderColor: '#939DA8',
      borderStyle: 'solid',
      padding: theme.spacing(12),
      [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
      },
    },
    description: {
      ...CommonFonts.darkFont18,
      textAlign: 'center',
    },
    logo: {
      paddingBottom: theme.spacing(5),
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      textAlign: 'center',
      letterSpacing: 0.3,
      paddingBottom: theme.spacing(12),
    },
    bottomText: {
      ...CommonFonts.darkFont18,
    },
    bottomBlueText: {
      ...CommonFonts.blueFont18,
    },
    boxFooter: {
      width: '100%',
      textAlign: 'center',
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(6),
    },
    textFooter: {
      ...CommonFonts.darkFont16,
    },
  })
);

export default useStyles;
