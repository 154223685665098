import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CommonFonts, Colors } from '@/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(17, 0, 80, 0),
    },
    findText: {
      ...CommonFonts.darkFont16,
      fontWeight: 500,
      textAlign: 'center',
    },
    inputContainer: {
      maxWidth: 790,
      margin: 'auto',
      marginTop: theme.spacing(13)
    }
  })
)

export default useStyles
