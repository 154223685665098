const applicationsFirstRowIcons = [
  {
    label: 'orders',
    icon: 'orders'
  },
  {
    label: 'expenses',
    icon: 'expenses'
  },
  {
    label: 'inventory',
    icon: 'inventory'
  },
  {
    label: 'promotions',
    icon: 'promotions'
  },
  {
    label: 'customers',
    icon: 'customers'
  }
];

const applicationsSecondRowIcons = [
  {
    label: 'add_order',
    icon: 'addOrder'
  },
  {
    label: 'add_expense',
    icon: 'addExpense'
  },
  {
    label: 'add_product',
    icon: 'addProduct'
  },
  {
    label: 'add_stock',
    icon: 'addStock'
  },
  {
    label: 'add_promotion',
    icon: 'addPromotion'
  },
  {
    label: 'add_customer',
    icon: 'addCustomer'
  }
];

const manageIcons = [
  {
    label: 'settings',
    icon: 'settings'
  },
  {
    label: 'touchpoints',
    icon: 'touchPoints'
  },
  {
    label: 'workflows',
    icon: 'workflows'
  },
  {
    label: 'users',
    icon: 'users'
  },
  {
    label: 'subscriptions',
    icon: 'subscriptions'
  },
  {
    label: 'integrations',
    icon: 'settings'
  },
  {
    label: 'import_data',
    icon: 'settings'
  }
];

export {
  applicationsFirstRowIcons,
  applicationsSecondRowIcons,
  manageIcons
};
